import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '16px',
  },
  root: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('xl')]: {
      paddingLeft: theme.spacing(2.5),
      paddingRight: theme.spacing(2.5),
    },
    padding: theme.spacing(1.5),
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '4px',
  },
}));

export type SettingsOptionContainerProps = {
  title: string;
  children: ReactElement;
};

export function SettingsOptionContainer({
  title,
  children,
}: SettingsOptionContainerProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      {children}
    </div>
  );
}
