import { Tooltip, type TooltipProps } from '@mui/material';
import { forwardRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

type CopyToClipboardProps = {
  children: React.ReactElement;
  copiedText: string;
  onCopy?: () => void;
  tooltipPlacement?: TooltipProps['placement'];
};

const Clipboard = forwardRef<CopyToClipboard, CopyToClipboardProps>(
  ({ children, copiedText, onCopy, tooltipPlacement = 'top' }, ref) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    return (
      <CopyToClipboard
        ref={ref}
        text={copiedText}
        onCopy={() => {
          setIsTooltipOpen(true);

          // Optional additional onCopy logic
          if (onCopy) {
            onCopy();
          }

          // Close the tooltip after 3 seconds
          setTimeout(() => {
            setIsTooltipOpen(false);
          }, 1000);
        }}
      >
        <Tooltip
          open={isTooltipOpen}
          title="Copied!"
          placement={tooltipPlacement}
        >
          {children}
        </Tooltip>
      </CopyToClipboard>
    );
  }
);
Clipboard.displayName = 'CopyToClipboard';

export default Clipboard;
