import { useQuery } from '@apollo/client';
import { Assignment, LabelOff, People } from '@mui/icons-material';
import { Grid, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { CollaborativeAssignmentsCountDocument } from '../../../../gql/queries/__generated__/assignment.generated';
import { QuestionsForStandardDocument } from '../../../../gql/queries/__generated__/question.generated';
import { Team } from '../../../../gql/types';
import StyledDialog from '../../../shared/Layout/StyledDialog';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import SubjectTeachersList from '../../Subject/SubjectTeachersList';
import SharedAssignments from './SharedAssignments';
import { STANDARD_QUESTIONS_PAGINATION_COUNT } from './StandardQuestionsModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 1232,
    height: 106,
    color: theme.palette.common.white,
    borderRadius: '4px',
    margin: '0 auto',
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.grays.main}`,
    borderRadius: 4,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      opacity: 0.8,
    },
  },
  container: {
    display: 'flex',
    padding: theme.spacing(3),
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexFlow: 'column',
    marginLeft: theme.spacing(2),
  },
  number: {
    marginBottom: theme.spacing(0.2),
  },
  caption: {
    color: theme.palette.grays.main,
  },
  button: {
    width: '100%',
    background: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',
    display: 'block',
    textAlign: 'left',
    color: 'inherit',
    height: '100%',
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

type SubjectGroupInfoProps = {
  setSelectedStandardId: React.Dispatch<React.SetStateAction<string>>;
  team?: Team;
};

type DialogType = 'teachers' | 'assignments';

export function SubjectGroupInfo({
  setSelectedStandardId,
  team,
}: SubjectGroupInfoProps) {
  const classes = useStyles();
  const [dialogType, setDialogType] = useState<DialogType>();
  const { data: collaborativeAssignmentsCountData } = useQuery(
    CollaborativeAssignmentsCountDocument,
    {
      skip: !team?.id,
      variables: { teamId: team?.id ?? '' },
    }
  );
  const { data } = useQuery(QuestionsForStandardDocument, {
    skip: !team?.id,
    variables: {
      first: STANDARD_QUESTIONS_PAGINATION_COUNT,
      teamId: team?.id ?? '',
    },
  });
  const onClick = () => {
    setSelectedStandardId('untagged');
    setDialogType(undefined);
  };
  const openTeachers = () => {
    setDialogType('teachers');
    setSelectedStandardId('');
  };

  const openAssignments = () => {
    setDialogType('assignments');
    setSelectedStandardId('');
  };
  const numUntagged = data?.questionsForStandard.totalCount || 0;
  const numAssignments =
    collaborativeAssignmentsCountData?.collaborativeAssignmentsCount || 0;

  const numTeachers = team?.teachers.length || 0;
  const handleClose = () => setDialogType(undefined);

  const dialogContent = () => {
    if (dialogType === 'assignments' && team) {
      return <SharedAssignments teamId={team?.id || ''} />;
    }
    if (dialogType === 'teachers') {
      return (
        <div>
          <Typography
            variant="h2"
            color="primary"
            className={classes.heading}
          >{`Teachers:`}</Typography>
          {team ? (
            <SubjectTeachersList teamId={team?.id || ''} />
          ) : (
            <LoadingSkeletons num={12} />
          )}
        </div>
      );
    }

    return null;
  };
  return (
    <>
      <Grid container className={classes.root} spacing={0}>
        <Grid item className={classes.section} xs={4}>
          <button className={classes.button} onClick={onClick}>
            <div className={classes.container}>
              <div>
                <LabelOff color="secondary" fontSize="large" />
              </div>
              <div className={classes.textContainer}>
                <Typography className={classes.number} variant="h3">
                  {numUntagged}
                </Typography>
                <Typography variant="caption" className={classes.caption}>
                  {`Untagged Question${numUntagged === 1 ? '' : 's'}`}
                </Typography>
              </div>
            </div>
          </button>
        </Grid>
        <Grid item className={classes.section} xs={4}>
          <button className={classes.button} onClick={openAssignments}>
            <div className={classes.container}>
              <div>
                <Assignment color="secondary" fontSize="large" />
              </div>
              <div className={classes.textContainer}>
                <Typography className={classes.number} variant="h3">
                  {numAssignments}
                </Typography>
                <Typography variant="caption" className={classes.caption}>
                  Shared Assignments
                </Typography>
              </div>
            </div>
          </button>
        </Grid>

        <Grid item className={classes.section} xs={4}>
          <button className={classes.button} onClick={() => {}}>
            <button className={classes.button} onClick={openTeachers}>
              <div className={classes.container}>
                <div>
                  <People color="secondary" fontSize="large" />
                </div>

                <div className={classes.textContainer}>
                  <Typography className={classes.number} variant="h3">
                    {numTeachers}
                  </Typography>
                  <Typography variant="caption" className={classes.caption}>
                    Teachers
                  </Typography>
                </div>
              </div>
            </button>
          </button>
        </Grid>
      </Grid>

      <StyledDialog
        open={dialogType !== undefined}
        title={
          dialogType === 'teachers'
            ? 'Teachers in this Subject Group'
            : 'Shared Assignments'
        }
        handleClose={handleClose}
      >
        {dialogContent()}
      </StyledDialog>
    </>
  );
}

export default SubjectGroupInfo;
