import { isUnknownRecord } from '@podsie/utils/object.js';
import { QuestionAttributesFragment } from '../gql/fragments/__generated__/question.generated';
import type {
  FreeResponseAttributesFragment,
  MultipleChoiceResponseAttributesFragment,
  ShortAnswerResponseAttributesFragment,
} from '../gql/fragments/__generated__/response.generated';
import {
  AssessmentValueEnum,
  FreeResponseAnswerInput,
  MultipleChoiceAnswerChoiceInput,
  QuestionInput,
  QuestionTypeEnum,
  ShortAnswerAnswerInput,
  SupplementInput,
} from '../gql/types';
import { isDeltaOperation, type AnyOperation } from './quillHelper';

type QuestionResponses = {
  freeResponseResponses?: FreeResponseAttributesFragment[] | null;
  shortAnswerResponses?: ShortAnswerResponseAttributesFragment[] | null;
  multipleChoiceResponses?: MultipleChoiceResponseAttributesFragment[] | null;
};

export const questionAnswerString = (question: QuestionAttributesFragment) => {
  switch (question.questionType) {
    case QuestionTypeEnum.FreeResponse:
      return (
        question.freeResponseAnswer?.text ||
        question.freeResponseAnswer?.plainText ||
        ''
      );
    case QuestionTypeEnum.ShortAnswer:
      return (
        question.shortAnswerAnswers?.map((answer) => answer.text).join(', ') ||
        ''
      );
    case QuestionTypeEnum.SelectOneMultipleChoice:
    case QuestionTypeEnum.SelectAllMultipleChoice:
      // return all the choices that are correct:
      return (
        question.multipleChoiceAnswerChoices
          ?.filter((choice) => choice.isCorrect)
          .map((choice) => choice.text)
          .join(', ') || ''
      );

    default:
      throw new Error('Invalid question type');
  }
};

export const countTotalAttempts = ({
  freeResponseResponses,
  shortAnswerResponses,
  multipleChoiceResponses,
}: QuestionResponses) =>
  (freeResponseResponses ? freeResponseResponses.length : 0) +
  (shortAnswerResponses ? shortAnswerResponses.length : 0) +
  (multipleChoiceResponses ? multipleChoiceResponses.length : 0);

export const questionComplete = ({
  freeResponseResponses,
  shortAnswerResponses,
  multipleChoiceResponses,
}: QuestionResponses) => {
  if (freeResponseResponses && freeResponseResponses.length > 0) {
    return freeResponseResponses.some((response) => {
      if (response === undefined) {
        return false;
      }

      return (
        response.selfAssessment === AssessmentValueEnum.Three ||
        response.selfAssessment === AssessmentValueEnum.Four ||
        response.selfAssessment === AssessmentValueEnum.Five
      );
    });
  }
  if (multipleChoiceResponses && multipleChoiceResponses.length > 0) {
    return multipleChoiceResponses.some((mcr) => mcr.isCorrect);
  }

  if (shortAnswerResponses && shortAnswerResponses.length > 0) {
    return shortAnswerResponses.some((sar) => sar.isCorrect);
  }
  return false;
};

/**
 * This type is similar to Quill's `DeltaStatic` except that `ops` are required
 * so an empty object will not be sufficient.
 */
export type RichText = {
  ops: AnyOperation[];
};

export const isRichText = (value?: unknown): value is RichText =>
  isUnknownRecord(value) &&
  Array.isArray(value.ops) &&
  value.ops.every(isDeltaOperation);

export function mapQuestionToQuestionInput(
  question: QuestionAttributesFragment
): QuestionInput {
  const mapFreeResponseAnswer = (
    answer: NonNullable<typeof question.freeResponseAnswer>
  ): FreeResponseAnswerInput => ({
    comparable: answer.comparable,
    id: answer.id,
    requiredKeywords: answer.fraRequiredKeywords?.map(
      (keyword) => keyword.keyword
    ),
    richText: answer.richText,
    text: answer.text || answer.plainText,
  });

  const mapMultipleChoiceAnswerChoice = (
    choice: NonNullable<typeof question.multipleChoiceAnswerChoices>[number]
  ): MultipleChoiceAnswerChoiceInput => ({
    id: choice.id,
    isCorrect: choice.isCorrect,
    richText: choice.richText,
    text: choice.text,
  });

  const mapShortAnswerAnswer = (
    answer: NonNullable<typeof question.shortAnswerAnswers>[number]
  ): ShortAnswerAnswerInput => ({
    hidden: answer.hidden,
    id: answer.id,
    ignoreWhitespaces: answer.ignoreWhitespaces,
    isCaseSensitive: answer.isCaseSensitive,
    richText: answer.richText,
    text: answer.text,
  });

  const mapSupplement = (
    supplement: NonNullable<typeof question.supplement>
  ): SupplementInput => ({
    plainText: supplement.plainText,
    richText: supplement.richText,
  });

  return {
    freeResponseAnswer: question.freeResponseAnswer
      ? mapFreeResponseAnswer(question.freeResponseAnswer)
      : undefined,
    id: question.id,
    multipleChoiceAnswerChoices: question.multipleChoiceAnswerChoices?.map(
      mapMultipleChoiceAnswerChoice
    ),
    numAttemptsUntilAnswerShown: question.numAttemptsUntilAnswerShown,
    parentId: question.copiedFromId ?? undefined,
    plainText: question.plainText,
    questionType: question.questionType,
    richText: question.richText,
    shortAnswerAnswers: question.shortAnswerAnswers?.map(mapShortAnswerAnswer),
    standardIds: question.standards?.map((standard) => standard.id),
    supplement: question.supplement
      ? mapSupplement(question.supplement)
      : undefined,
  };
}
