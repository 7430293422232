import { useMutation, useQuery } from '@apollo/client';
import { Button, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import {
  BulkUploadFreeResponseDocument,
  BulkUploadMultipleChoiceDocument,
  BulkUploadSelectAllDocument,
  BulkUploadShortAnswerDocument,
} from '../../../gql/mutations/__generated__/question.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import type {
  BulkFreeResponseInput,
  BulkMultipleChoiceInput,
  BulkSelectAllInput,
  BulkShortAnswerInput,
} from '../../../gql/types';
import { onError } from '../../../utils/apollo/apolloHelper';
import { replaceDashWithSpaceAndUpcaseFirstLetter } from '../../../utils/string';
import { QuillDeltaAsHtml } from '../../shared/QuillDeltaAsHtml';
import { AlertsContext } from '../Alerts/context';
import { pushSnack } from '../Alerts/context/actions';
import type { BulkInputTypes } from './QuestionTypeBulkUpload';
import type { BulkUploadType } from './QuestionsBulkUpload';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  previewSection: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: 4,
    backgroundColor: theme.palette.mint.light,
    maxHeight: '60vh',
    overflow: 'auto',
  },
  questionPreviewCard: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: 4,
    border: `1px solid ${theme.palette.mint.dark}`,
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  buttonsContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

type QuestionsBulkUploadPreviewProps = {
  questionsPreview: BulkInputTypes;
  questionType: BulkUploadType;
  setNumUploaded: React.Dispatch<React.SetStateAction<number | undefined>>;
  setQuestionsPreview: React.Dispatch<React.SetStateAction<BulkInputTypes>>;
  setBulkUploadType: React.Dispatch<
    React.SetStateAction<BulkUploadType | undefined>
  >;
};

export function QuestionsBulkUploadPreview({
  questionsPreview,
  questionType,
  setNumUploaded,
  setQuestionsPreview,
  setBulkUploadType,
}: QuestionsBulkUploadPreviewProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data: teacherData } = useQuery(TeacherDocument, {});
  const activeCourseId = teacherData?.teacher.activeCourse?.id || '';
  const [bulkUploadFreeResponse] = useMutation(BulkUploadFreeResponseDocument, {
    onCompleted: (res) => setNumUploaded(res.bulkUploadFreeResponse),
    onError: onError(dispatch),
  });

  const [bulkUploadShortAnswer] = useMutation(BulkUploadShortAnswerDocument, {
    onCompleted: (res) => setNumUploaded(res.bulkUploadShortAnswer),
    onError: onError(dispatch),
  });

  const [bulkUploadMultipleChoice] = useMutation(
    BulkUploadMultipleChoiceDocument,
    {
      onCompleted: (res) => setNumUploaded(res.bulkUploadMultipleChoice),
      onError: onError(dispatch),
    }
  );

  const [bulkUploadSelectAll] = useMutation(BulkUploadSelectAllDocument, {
    onCompleted: (res) => setNumUploaded(res.bulkUploadSelectAll),
    onError: onError(dispatch),
  });

  const handleCancel = () => {
    setQuestionsPreview([]);
    setBulkUploadType(undefined);
  };

  const handleBulkUpload = () => {
    if (!activeCourseId) {
      dispatch(
        pushSnack({
          message: 'Something went wrong please refresh the page and try again',
          severity: 'error',
        })
      );
    }

    if (questionType === 'free-response') {
      bulkUploadFreeResponse({
        variables: {
          questions: questionsPreview as BulkFreeResponseInput[],
          courseId: activeCourseId,
        },
      });
      return;
    }

    if (questionType === 'short-answer') {
      bulkUploadShortAnswer({
        variables: {
          questions: questionsPreview as BulkShortAnswerInput[],
          courseId: activeCourseId,
        },
      });

      return;
    }

    if (questionType === 'multiple-choice') {
      bulkUploadMultipleChoice({
        variables: {
          questions: questionsPreview as BulkMultipleChoiceInput[],
          courseId: activeCourseId,
        },
      });

      return;
    }

    if (questionType === 'select-all') {
      bulkUploadSelectAll({
        variables: {
          questions: questionsPreview as BulkSelectAllInput[],
          courseId: activeCourseId,
        },
      });
      return;
    }
  };
  const renderPreview = () => {
    if (questionType === 'free-response') {
      return (questionsPreview as BulkFreeResponseInput[]).map((question) => {
        return (
          <div
            key={question.questionText}
            className={classes.questionPreviewCard}
          >
            <div className={classes.container}>
              <Typography variant="h6" color="primary">
                Free Response Question:
              </Typography>
              <QuillDeltaAsHtml delta={[{ insert: question.questionText }]} />
            </div>
            <div className={classes.container}>
              <Typography variant="h6" color="primary">
                Exemplar Answer:
              </Typography>
              <QuillDeltaAsHtml delta={[{ insert: question.exemplar }]} />
            </div>
            {question.deeperLearning && (
              <div className={classes.container}>
                <Typography variant="h6" color="primary">
                  Explanation:
                </Typography>
                <QuillDeltaAsHtml
                  delta={[{ insert: question.deeperLearning }]}
                />
              </div>
            )}
          </div>
        );
      });
    }

    if (questionType === 'short-answer') {
      return (questionsPreview as BulkShortAnswerInput[]).map((question) => {
        return (
          <div
            key={question.questionText}
            className={classes.questionPreviewCard}
          >
            <div className={classes.container}>
              <Typography variant="h6" color="primary">
                Short Answer Question:
              </Typography>
              <QuillDeltaAsHtml delta={[{ insert: question.questionText }]} />
            </div>
            <div className={classes.container}>
              <Typography variant="h6" color="primary">
                Acceptable Short Answer(s):
              </Typography>
              <ol>
                {question.acceptableAnswers.map((answer) => (
                  <li key={answer}>{answer}</li>
                ))}
              </ol>
            </div>
            {question.deeperLearning && (
              <div className={classes.container}>
                <Typography variant="h6" color="primary">
                  Explanation:
                </Typography>
                <QuillDeltaAsHtml
                  delta={[{ insert: question.deeperLearning }]}
                />
              </div>
            )}
          </div>
        );
      });
    }

    if (questionType === 'multiple-choice') {
      return (questionsPreview as BulkMultipleChoiceInput[]).map((question) => {
        return (
          <div
            key={question.questionText}
            className={classes.questionPreviewCard}
          >
            <div className={classes.container}>
              <Typography variant="h6" color="primary">
                Multiple Choice Question:
              </Typography>
              <QuillDeltaAsHtml delta={[{ insert: question.questionText }]} />
            </div>
            <div className={classes.container}>
              <Typography variant="h6" color="primary">
                Multiple Choice Answer Choices:
              </Typography>
              <ul>
                {question.answerChoices.map((choice, i) => (
                  <li key={choice}>
                    <strong>{choice}</strong>
                    <span>{`${i === 0 ? ' (correct)' : ' (wrong)'}`}</span>
                    {/* <span>{`${
                      question.correctChoiceRefs.some((ref) => ref === i)
                        ? ' (correct)'
                        : ' (wrong)'
                    }`}</span> */}
                  </li>
                ))}
              </ul>
            </div>
            {question.deeperLearning && (
              <div className={classes.container}>
                <Typography variant="h6" color="primary">
                  Explanation:
                </Typography>
                <QuillDeltaAsHtml
                  delta={[{ insert: question.deeperLearning }]}
                />
              </div>
            )}
          </div>
        );
      });
    }

    if (questionType === 'select-all') {
      return (questionsPreview as BulkSelectAllInput[]).map((question) => {
        return (
          <div
            key={question.questionText}
            className={classes.questionPreviewCard}
          >
            <div className={classes.container}>
              <Typography variant="h6" color="primary">
                Select All That Apply Question:
              </Typography>
              <QuillDeltaAsHtml delta={[{ insert: question.questionText }]} />
            </div>
            <div className={classes.container}>
              <Typography variant="h6" color="primary">
                Select All That Apply Answer Choices:
              </Typography>
              <ul>
                {question.answerChoices.map((choice, i) => (
                  <li key={choice}>
                    <strong>{choice}</strong>
                    <span>{`${
                      question.correctChoiceRefs.some((ref) => ref === i)
                        ? ' (correct)'
                        : ' (wrong)'
                    }`}</span>
                  </li>
                ))}
              </ul>
            </div>
            {question.deeperLearning && (
              <div className={classes.container}>
                <Typography variant="h6" color="primary">
                  Explanation:
                </Typography>
                <QuillDeltaAsHtml
                  delta={[{ insert: question.deeperLearning }]}
                />
              </div>
            )}
          </div>
        );
      });
    }

    return null;
  };

  return (
    <section>
      <div>
        <Typography variant="h6" color="primary">
          {`Are you sure you want to bulk upload the following ${replaceDashWithSpaceAndUpcaseFirstLetter(
            questionType
          )} questions?`}
        </Typography>
        <div className={classes.buttonsContainer}>
          <Button
            onClick={handleCancel}
            className={classes.cancelButton}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleBulkUpload}
            color="secondary"
            variant="outlined"
          >
            Confirm
          </Button>
        </div>
      </div>
      <section className={classes.previewSection}>{renderPreview()}</section>
    </section>
  );
}

export default QuestionsBulkUploadPreview;
