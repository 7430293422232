import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BulkAddAssignmentsQuestionDocument } from '../../../../gql/mutations/__generated__/assignmentsQuestion.generated';
import { ToggleQuestionActiveDocument } from '../../../../gql/mutations/__generated__/question.generated';
import { GeneratedQuestionsQuery } from '../../../../gql/queries/__generated__/question.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';
import { AssignmentEditorContext } from '../../Assignments/AssignmentEditor/context';
import { GeneratedQuestion } from './GeneratedQuestion';

type GeneratedQuestionsProps = {
  generatedQuestions: GeneratedQuestionsQuery['generatedQuestions'];
  discardQuestions: (questionIds: string[]) => void;
};

export function GeneratedQuestions({
  generatedQuestions,
  discardQuestions,
}: GeneratedQuestionsProps) {
  const theme = useTheme();
  const [questionIdsToAdd, setQuestionIdsToAdd] = React.useState<string[]>(
    generatedQuestions.map((question) => question.id)
  );
  const { dispatch } = useContext(AlertsContext);
  const history = useHistory();
  const {
    assignmentEditor: { id: assignmentId, questions },
  } = useContext(AssignmentEditorContext);
  const handleDiscardAll = () => {
    // discard all generated questions:
    discardQuestions(generatedQuestions.map((question) => question.id));
  };
  const [addQuestionsToAssignment, { loading }] = useMutation(
    BulkAddAssignmentsQuestionDocument,
    {
      onError: onError(dispatch),
      onCompleted: () => {
        // route to current url except without the /ai-create at the end:
        const updatedRoute = window.location.pathname.replace('/ai-create', '');
        history.push(updatedRoute);
        // clear the generated questions:
        handleDiscardAll();
        dispatch(
          pushSnack({
            message: `${questionIdsToAdd.length} questions added to assignment!`,
          })
        );
      },
    }
  );

  useEffect(() => {
    // if the generated questions no longer has a specific question, then remove it from the list of questions to add:
    setQuestionIdsToAdd((prev) =>
      prev.filter((id) => generatedQuestions.map((q) => q.id).includes(id))
    );
  }, [generatedQuestions]);
  const [toggleActive, { loading: stateLoading }] = useMutation(
    ToggleQuestionActiveDocument
  );
  const handleAddQuestionsToAssignment = async () => {
    const promises = questionIdsToAdd.map((questionId) => {
      return new Promise((resolve, reject) => {
        toggleActive({
          variables: {
            questionId,
          },
          onCompleted: resolve,
          onError: reject,
        });
      });
    });
    await Promise.all(promises);
    addQuestionsToAssignment({
      variables: {
        questionIds: questionIdsToAdd,
        assignmentId,
      },
    });
  };
  const isLoading = loading || stateLoading;
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          mb: 3,
        }}
      >
        <Typography
          variant="h3"
          sx={{ fontWeight: 400, mb: 1.5 }}
          color="primary"
        >
          Step 2 - Review and Select
        </Typography>
      </Box>
      <Box>
        {generatedQuestions.map((question, index) => {
          return (
            <GeneratedQuestion
              key={index}
              question={question}
              questionIdsToAdd={questionIdsToAdd}
              setQuestionIdsToAdd={setQuestionIdsToAdd}
              discardQuestions={discardQuestions}
            />
          );
        })}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          sx={{
            mr: 1,
            borderColor: theme.palette.grays.dark,
            backgroundColor: theme.palette.common.white,
          }}
          color="inherit"
          onClick={handleDiscardAll}
        >
          Discard Questions
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={
            isLoading ? (
              <CircularProgress size={12} color="primary" />
            ) : undefined
          }
          disabled={isLoading || questionIdsToAdd.length === 0}
          sx={{ color: theme.palette.common.white }}
          onClick={handleAddQuestionsToAssignment}
        >
          Add Questions to Assignment
        </Button>
      </Box>
    </Box>
  );
}
