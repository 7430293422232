import { useQuery } from '@apollo/client';
import type { MaterialTableProps, Query } from '@material-table/core';
import { Block, Edit, QuestionAnswer } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  bubbleGray,
  bubbleGreen,
  bubbleRed,
  bubbleYellow,
} from '../../../assets/shared-styles/Bubble';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { QuestionsStatsDocument } from '../../../gql/queries/__generated__/questionsStats.generated';
import { ReviewSessionsQuestionsDocument } from '../../../gql/queries/__generated__/reviewSessionsQuestion.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import {
  QuestionStateEnum,
  SortDirectionEnum,
  type Question,
} from '../../../gql/types';
import { dateFormatWithoutTime } from '../../../utils/dates';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import type { PageInfo, PaginationArgs } from '../../../utils/pagination';
import { CustomTooltip } from '../../shared/CustomTooltip';
import { LastUpdated } from '../../shared/LastUpdated';
import { QuillDeltaAsHtml } from '../../shared/QuillDeltaAsHtml';
import { CustomTable } from '../../shared/Table';
import HelpKitQuestionsProgressColumns from '../HelpKitArticles/HelpKitQuestionsProgressColumns';
import HelpKitReviewQuestions from '../HelpKitArticles/HelpKitReviewQuestions';
import { QuestionStatsModal } from './QuestionStatsModal';
import { QuestionsStatsFilter } from './QuestionsStatsFilter';

const reviewSessionsDrawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: `${theme.spacing(4)} ${theme.spacing(6)}`,
  },
  tableContainer: {},
  questionContainer: {},
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  questionText: {},
  blockIcon: {
    position: 'relative',
    top: '3.5px',
    marginRight: theme.spacing(0.5),
    color: theme.palette.error.main,
    fontSize: '18px',
  },
  buttonLink: {
    ...buttonLink(theme),
    color: theme.palette.primary.main,
  },
  reviewSessionsButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
  },
  reviewSessions: {
    width: reviewSessionsDrawerWidth,
  },
  drawerPaper: {
    background: theme.palette.background.paper,
    width: reviewSessionsDrawerWidth,
  },
  bubbleGray: {
    ...bubbleGray(theme),
    marginLeft: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.85',
    },
  },
  bubbleGreen: {
    ...bubbleGreen(),
    marginLeft: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.85',
    },
  },

  bubbleYellow: {
    ...bubbleYellow(),
    marginLeft: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.85',
    },
  },
  bubbleRed: {
    ...bubbleRed(),
    marginLeft: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.85',
    },
  },
}));

const PAGE_SIZE = 50;

const SORT_FIELD_KEY: { [key: string]: string } = {
  firstSeen: 'first_seen',
  overall: 'percent_correct',
  recent: 'recent_percent_correct',
  strugglingStudents: 'struggling_students',
};

export type QuestionsStatsFilters = {
  plainText: string;
  onlyShowQuestionsWithData: boolean;
  onlyShowActiveQuestions: boolean;
  groupId?: string;
};

type RowData = {
  firstSeen: Date | null;
  question: {
    id: string;
    plainText: string;
    richText: Question['richText'];
    state: QuestionStateEnum;
  } | null;
  overall: string | null;
  recent: string | null;
  strugglingStudents: number;
  variations: number;
};

export enum Mode {
  EDITING = 'editing',
  PROGRESS = 'progress',
  DETAILS = 'details',
}

export function QuestionsStats() {
  useTrackVisit({
    section: 'student-progress',
    page: 'questions',
  });
  const { selectedQuestionId } = useParams<{
    selectedQuestionId: string | undefined;
  }>();
  const classes = useStyles();
  const [mode, setMode] = useState<Mode>(Mode.PROGRESS);
  const { data: teacherData } = useQuery(TeacherDocument);
  const courseId = teacherData?.teacher.activeCourse?.id || '';
  const { data: reviewQuestionsData, refetch: refetchRQ } = useQuery(
    ReviewSessionsQuestionsDocument,
    {
      skip: !courseId,
      variables: { courseId },
    }
  );
  const refetchReviewQuestions = () => refetchRQ();
  const numReviewQuestions =
    reviewQuestionsData?.reviewSessionsQuestions.length;
  const tableRef = useRef<MaterialTableProps<{
    firstSeen: Date | null;
    question: string;
    overall: string;
    recent: string;
  }> | null>();
  const [questionId, setQuestionId] = useState<string | undefined>(
    selectedQuestionId
  );
  const [filters, setFilters] = useState<QuestionsStatsFilters>({
    plainText: '',
    onlyShowQuestionsWithData: true,
    onlyShowActiveQuestions: true,
    groupId: undefined,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    totalCount: 0,
    endCursor: '',
    startCursor: '',
  });
  const { loading, refetch } = useQuery(QuestionsStatsDocument, {
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      if (res.questionsStats.pageInfo && res.questionsStats.totalCount) {
        setPageInfo({
          totalCount: res.questionsStats.totalCount,
          endCursor: res.questionsStats.pageInfo.endCursor,
          startCursor: res.questionsStats.pageInfo.startCursor,
        });
      }
    },
    variables: {
      first: PAGE_SIZE,
      sortDirection: SortDirectionEnum.Desc,
      sortField: SORT_FIELD_KEY['strugglingStudents'],
      ...filters,
    },
  });

  useEffect(() => {
    if (tableRef?.current?.onQueryChange) {
      (tableRef.current as any).onQueryChange(null);
    }
  }, [filters]);

  const openQuestionStats = (questionId: string, mode: Mode) => {
    return () => {
      setMode(mode);
      setQuestionId(questionId);
    };
  };

  const refetchAfterUpdate = () => {
    if (tableRef?.current?.onQueryChange) {
      (tableRef.current as any).onQueryChange(null);
    }
  };
  const pullNewData = () => refetch();
  const closeModal = () => {
    setQuestionId(undefined);
  };

  const remoteData = (query: Query<RowData>) => {
    // need to set all args as undefined first
    // https://github.com/apollographql/react-apollo/issues/2300
    let newArgs: PaginationArgs = {
      first: undefined,
      last: undefined,
      before: undefined,
      after: undefined,
    };
    if (query.page === 0) {
      // go to very first page:
      newArgs = {
        ...newArgs,
        first: PAGE_SIZE,
        sortDirection:
          query.orderDirection === 'asc'
            ? SortDirectionEnum.Asc
            : SortDirectionEnum.Desc,
        sortField:
          SORT_FIELD_KEY[
            typeof query?.orderBy?.field === 'string'
              ? query.orderBy.field
              : 'strugglingStudents'
          ],
      };
    } else if (query.page === currentPage) {
      // page did not change and is just refetching after an update, so we want
      // to keep the same args that the last fetch used:
      newArgs = {};
    } else if (Math.floor(pageInfo.totalCount / PAGE_SIZE) === query.page) {
      // go to last page:
      newArgs = {
        ...newArgs,
        last: pageInfo.totalCount % PAGE_SIZE,
      };
    } else if (query.page > currentPage) {
      // navigate one page forward:
      newArgs = {
        ...newArgs,
        first: PAGE_SIZE,
        after: pageInfo.endCursor,
      };
    } else {
      // navigate one page back
      newArgs = {
        ...newArgs,
        last: PAGE_SIZE,
        before: pageInfo.startCursor,
      };
    }
    setCurrentPage(query.page);
    return refetch({
      ...newArgs,
      ...filters,
    }).then((res) => {
      setPageInfo({
        totalCount: res.data.questionsStats.totalCount || 0,
        startCursor: res.data.questionsStats.pageInfo.startCursor,
        endCursor: res.data.questionsStats.pageInfo.endCursor,
      });
      const data = res.data?.questionsStats.edges?.map((edge) => {
        const totalCorrectPercent =
          edge?.node?.totalAttempts && edge?.node?.totalCorrect
            ? Math.round(
                (edge.node.totalCorrect / edge.node.totalAttempts) * 100
              )
            : 0;
        const recentCorrectPercent =
          edge?.node?.numStudentsAttempted && edge?.node?.numRecentCorrect
            ? Math.round(
                (edge.node.numRecentCorrect / edge.node.numStudentsAttempted) *
                  100
              )
            : 0;
        return {
          firstSeen: edge?.node?.firstSeen
            ? new Date(edge?.node?.firstSeen)
            : null,
          question: !edge?.node
            ? null
            : {
                id: edge.node.id,
                plainText: `${edge?.node?.plainText.substring(0, 100).trim()}${
                  edge.node.plainText.length > 100 ? '...' : ''
                }`,
                richText: edge?.node?.richText,
                state: edge?.node?.state,
              },
          overall: edge?.node?.totalAttempts
            ? `${edge?.node?.totalCorrect} / ${edge?.node?.totalAttempts} (${totalCorrectPercent}%)`
            : null,
          recent: edge?.node?.numStudentsAttempted
            ? `${edge?.node?.numRecentCorrect} / ${edge?.node?.numStudentsAttempted} (${recentCorrectPercent}%)`
            : null,
          strugglingStudents:
            edge?.node?.strugglingStudents === undefined
              ? 0
              : edge.node.strugglingStudents,
          variations: 1,
        };
      });
      return {
        data: data || [],
        page: query.page,
        totalCount: res.data.questionsStats.totalCount,
      };
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.reviewSessionsButtonContainer}>
        <Link to={'/progress/questions/review'}>
          <Button variant="contained" color="primary">
            {`Review Mode${
              numReviewQuestions ? ` (${numReviewQuestions})` : ''
            }`}
          </Button>
        </Link>
      </div>

      <QuestionsStatsFilter setFilters={setFilters} filters={filters} />
      <div className={classes.headingContainer}>
        <div>
          <div>
            <HelpKitQuestionsProgressColumns />
          </div>
          <div>
            <HelpKitReviewQuestions />
          </div>
        </div>
        <LastUpdated refetch={pullNewData} />
      </div>
      <div className={classes.tableContainer}>
        <CustomTable
          tableRef={tableRef}
          style={{ height: '100%' }}
          data={remoteData}
          title=""
          isLoading={loading}
          localization={{
            header: {
              actions: '',
            },
          }}
          options={{
            headerStyle: {
              position: 'sticky',
              top: 0,
            },
            thirdSortClick: false,
            minBodyHeight: '68vh',
            maxBodyHeight: '68vh',
            pageSize: PAGE_SIZE,
            pageSizeOptions: [PAGE_SIZE],
          }}
          components={{
            Toolbar: () => null,
          }}
          actions={[
            {
              icon: () => <Edit color="primary" />,
              tooltip: 'Edit Question',
              onClick: (_, data: RowData | RowData[]) => {
                if (Array.isArray(data)) {
                  return;
                }
                if (!data.question) {
                  return;
                }
                setMode(Mode.EDITING);
                setQuestionId(data.question.id);
              },
            },
            {
              icon: () => <QuestionAnswer color="primary" />,
              tooltip: 'See Responses',
              onClick: (_, data: RowData | RowData[]) => {
                if (Array.isArray(data)) {
                  return;
                }
                if (!data.question) {
                  return;
                }
                openQuestionStats(data.question.id, Mode.DETAILS)();
              },
            },
          ]}
          columns={[
            {
              title: 'QUESTION',
              field: 'question',
              sorting: false,
              render: ({ question }) => {
                if (!question) {
                  return '';
                }

                return (
                  <div className={classes.questionContainer}>
                    <CustomTooltip
                      placement="top-start"
                      title={<QuillDeltaAsHtml delta={question.richText.ops} />}
                    >
                      <Typography className={classes.questionText}>
                        {question.state === QuestionStateEnum.Inactive ? (
                          <Block className={classes.blockIcon} />
                        ) : null}
                        {question.plainText}
                      </Typography>
                    </CustomTooltip>
                  </div>
                );
              },
            },
            {
              title: 'Struggling',
              field: 'strugglingStudents',
              defaultSort: 'desc',
              render: ({ strugglingStudents, question }) => {
                if (!question) {
                  return '';
                }
                let style;
                if (strugglingStudents === 0) {
                  style = classes.bubbleGreen;
                } else if (strugglingStudents < 5) {
                  style = classes.bubbleYellow;
                } else {
                  style = classes.bubbleRed;
                }
                return (
                  <div>
                    <button
                      className={style}
                      onClick={openQuestionStats(question.id, Mode.PROGRESS)}
                    >
                      {strugglingStudents}
                    </button>
                  </div>
                );
              },
            },

            {
              title: 'RECENT PROGRESS',
              field: 'recent',
              render: ({ recent, question }) => {
                if (!question) {
                  return '';
                }
                return recent;
              },
            },
            {
              title: 'OVERALL PROGRESS',
              field: 'overall',
              render: ({ overall }) => {
                if (!overall) {
                  return '';
                }
                return overall;
              },
            },
            {
              title: 'FIRST ATTEMPTED',
              field: 'firstSeen',
              render: ({ firstSeen }) => {
                if (!firstSeen) {
                  return '';
                }
                return format(firstSeen, dateFormatWithoutTime);
              },
            },
          ]}
        />
      </div>
      <QuestionStatsModal
        filters={filters}
        setFilters={setFilters}
        open={!!questionId}
        mode={mode}
        setMode={setMode}
        questionId={questionId}
        refetch={refetchAfterUpdate}
        handleClose={closeModal}
        refetchReviewQuestions={refetchReviewQuestions}
      />
    </div>
  );
}
