import React, { useState } from 'react';
import { OwnershipEnum, QuestionTypeEnum } from '../../../../../gql/types';
import { LS_QUESTION_OWNERSHIP_KEY } from '../../../../../utils/localStorageKeys';
import { StandardsQueryStandard } from '../../Form/MultipleStandardsAutocomplete';

export type QuestionsSearchQuestionType = QuestionTypeEnum | '';

type QuestionsSearchParams = {
  text: string;
  questionType: QuestionsSearchQuestionType;
  ownership: OwnershipEnum;
  standardsChartId: string;
  standards: StandardsQueryStandard[];
  unused: boolean;
};

const ownership = localStorage.getItem(LS_QUESTION_OWNERSHIP_KEY);

const processedOwnership = ownership ? (ownership as OwnershipEnum) : '';

const initialState = {
  text: '',
  standardsChartId: '',
  ownership: processedOwnership ? processedOwnership : OwnershipEnum.Others,
  questionType: '' as QuestionsSearchQuestionType,
  standards: [] as StandardsQueryStandard[],
  unused: false,
};

export const QuestionsSearchContext = React.createContext<{
  questionsSearchParams: QuestionsSearchParams;
  setQuestionsSearchParams: React.Dispatch<
    React.SetStateAction<QuestionsSearchParams>
  >;
}>({
  questionsSearchParams: initialState,
  setQuestionsSearchParams: () => null,
});

type QuestionsSearchProviderProps = {
  children: React.ReactNode;
};

export function QuestionsSearchProvider({
  children,
}: QuestionsSearchProviderProps) {
  const [questionsSearchParams, setQuestionsSearchParams] =
    useState(initialState);
  return (
    <QuestionsSearchContext.Provider
      value={{ questionsSearchParams, setQuestionsSearchParams }}
    >
      {children}
    </QuestionsSearchContext.Provider>
  );
}

// function to convert searchParams to a shape that `QuestionsQuery` accepts:
export const searchParamsToQueryParams = (params: QuestionsSearchParams) => {
  const { text, standards, questionType, ownership, unused } = params;

  return {
    plainText: text || undefined,
    ownership,
    standardIds: standards.length ? standards.map((s) => s.id) : undefined,
    questionType: questionType === '' ? undefined : questionType,
    unused: unused,
  };
};
