import { useContext, useEffect } from 'react';
import { Route, Switch, type RouteComponentProps } from 'react-router-dom';
import { NotFound } from '../../components/application/ErrorPages/NotFound';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import { SettingsIndex } from './SettingsIndex';

export function Settings({ match }: RouteComponentProps) {
  const { updateTitle } = useContext(HeaderTitleContext);
  useEffect(() => updateTitle('Settings'), [updateTitle]);

  return (
    <Switch>
      <Route path={`${match.path}`}>
        <SettingsIndex />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
}
