import { Button } from '@mui/material';
import { SideDrawer } from '../../../../../shared/Layout/SideDrawer';
import { UpdateQuestion } from '../../../../Questions/Form/UpdateQuestion';

export type UpdateAssignmentQuestionDrawerProps = {
  questionId?: string;
  editingOpen: boolean;
  handleClose: () => void;
  triggerUpdateFormSubmit: boolean;
  setTriggerUpdateFormSubmit:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
};

export function UpdateAssignmentQuestionDrawer({
  questionId,
  editingOpen,
  handleClose,
  triggerUpdateFormSubmit,
  setTriggerUpdateFormSubmit,
}: UpdateAssignmentQuestionDrawerProps) {
  return (
    <SideDrawer
      isOpen={editingOpen}
      title={'Update Question'}
      onClose={handleClose}
      footerContent={
        <Button
          variant="contained"
          onClick={() => {
            if (setTriggerUpdateFormSubmit) {
              setTriggerUpdateFormSubmit(true);
            }
          }}
        >
          Save changes
        </Button>
      }
    >
      {questionId && (
        <UpdateQuestion
          editingOpen={editingOpen}
          questionId={questionId}
          postUpdateCallback={handleClose}
          triggerUpdateFormSubmit={triggerUpdateFormSubmit}
          setTriggerUpdateFormSubmit={setTriggerUpdateFormSubmit}
        />
      )}
    </SideDrawer>
  );
}
