import { ChevronRight } from '@mui/icons-material';
import {
  Divider,
  Drawer,
  IconButton,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ReactNode } from 'react';

const drawerWidth = 800;

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  root: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  closeDrawerButton: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    marginRight: theme.spacing(1),
  },
  title: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  header: {},
  content: {
    overflow: 'hidden',
  },
  footer: {
    flexShrink: 0,
    backgroundColor: theme.palette.background.paper,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
}));

type SideDrawerProps = {
  children?: ReactNode;
  footerContent?: ReactNode;
  extraHeaderContent?: ReactNode;
  onClose: () => void;
  isOpen: boolean;
  title?: string;
};

export function SideDrawer({
  children,
  onClose,
  isOpen,
  title,
  footerContent,
  extraHeaderContent,
}: SideDrawerProps) {
  const classes = useStyles();

  return (
    <Drawer
      classes={{
        paper: classes.root,
      }}
      className={classes.drawer}
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.header}>
        <IconButton
          className={classes.closeDrawerButton}
          color="secondary"
          onClick={onClose}
          size="large"
        >
          <ChevronRight />
        </IconButton>
        {title && (
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        )}
        {extraHeaderContent}
        <Divider className={classes.divider} />
      </div>
      <div className={classes.content}>{children}</div>
      {footerContent && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.footer}>{footerContent}</div>
        </>
      )}
    </Drawer>
  );
}
