import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuestionTypeEnum } from '../../gql/types';
import { ASSESSMENT_VALUE_TO_EMOJI } from '../../utils/assessmentValueMappings';
import type { AttemptsProps } from '../application/Assignments/AssignmentEditor/Results/AssignmentQuestions/Attempts';
import Emoji from './Emoji';
import { QuillDeltaAsHtml } from './QuillDeltaAsHtml';
import { ResponseTimestamp } from './ResponseTimestamp';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  list: {
    paddingLeft: theme.spacing(1),
  },
  noBullets: {
    listStyleType: 'none',
    marginBottom: theme.spacing(1),
  },
  freeResponseLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selfRatingLabel: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  emojiLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  selectAllContainer: {
    // matching the styles of the other question types, which have a ul element
    // as a container:
    paddingLeft: theme.spacing(1),
    margin: '14px 0',
  },
  richTextContainer: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));
const useAttemptTypographyStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
}));

function AttemptTypography({
  text,
  createdAt,
}: {
  text: string;
  createdAt: string;
}) {
  const classes = useAttemptTypographyStyles();
  return (
    <Typography variant="h4" className={classes.root}>
      (<ResponseTimestamp timestamp={createdAt} />) {text}
    </Typography>
  );
}

function AttemptsDetails({
  question,
  freeResponseResponses,
  shortAnswerResponses,
  multipleChoiceResponses,
}: AttemptsProps) {
  const classes = useStyles();
  switch (question.questionType) {
    case QuestionTypeEnum.FreeResponse:
      return (
        <ul className={classes.list}>
          {freeResponseResponses
            ?.slice()
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
            .map(
              (
                {
                  selfAssessment,
                  richText,
                  teacherAssessment,
                  autograded,
                  createdAt,
                },
                i
              ) => {
                let emoji, label;
                if (selfAssessment) {
                  ({ emoji, label } =
                    ASSESSMENT_VALUE_TO_EMOJI(selfAssessment));
                }
                let teacherEmoji, teacherLabel;
                if (teacherAssessment) {
                  ({ emoji: teacherEmoji, label: teacherLabel } =
                    ASSESSMENT_VALUE_TO_EMOJI(teacherAssessment));
                }
                return (
                  <li
                    className={classes.noBullets}
                    key={`freeResponseAttempt-${i}`}
                  >
                    <div className={classes.freeResponseLabelContainer}>
                      <ResponseTimestamp timestamp={createdAt} />{' '}
                      <Typography variant="h4">{`ATTEMPT ${i + 1}`}</Typography>
                      {emoji && (
                        <Typography className={classes.emojiLabelContainer}>
                          <span className={classes.selfRatingLabel}>
                            {`(${autograded ? 'Auto-Graded' : 'Self-Rating'}:`}
                          </span>
                          <Emoji symbol={emoji} label={label} />
                          <span>)</span>
                        </Typography>
                      )}
                      {teacherEmoji && (
                        <Typography className={classes.emojiLabelContainer}>
                          <span className={classes.selfRatingLabel}>
                            (Override-Rating:
                          </span>
                          <Emoji symbol={teacherEmoji} label={teacherLabel} />
                          <span>)</span>
                        </Typography>
                      )}
                    </div>
                    <div className={classes.richTextContainer}>
                      <QuillDeltaAsHtml delta={richText.ops} />
                    </div>
                  </li>
                );
              }
            )}
        </ul>
      );
    case QuestionTypeEnum.ShortAnswer:
      return (
        <ul className={classes.list}>
          {shortAnswerResponses
            ?.slice()
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
            .map(({ answer, createdAt }, i) => {
              return (
                <li
                  className={classes.noBullets}
                  key={`shortAnswerAttempt-${i}`}
                >
                  <AttemptTypography
                    text={`ATTEMPT ${i + 1}: `}
                    createdAt={createdAt}
                  />
                  {answer}
                </li>
              );
            })}
        </ul>
      );

    case QuestionTypeEnum.SelectAllMultipleChoice:
      return (
        <div className={classes.selectAllContainer}>
          {multipleChoiceResponses
            ?.slice()
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            )
            .map(({ selectedMultipleChoiceAnswerChoices, createdAt }, i) => {
              return (
                <div key={`mcAttempt-${i}`}>
                  <AttemptTypography
                    text={`ATTEMPT ${i + 1} - selected choice(s):`}
                    createdAt={createdAt}
                  />
                  <ul>
                    {selectedMultipleChoiceAnswerChoices.length ? (
                      selectedMultipleChoiceAnswerChoices.map(
                        ({
                          multipleChoiceAnswerChoice: { id, richText, text },
                        }) => {
                          return (
                            <li key={`choice-${id}`}>
                              {richText ? (
                                <QuillDeltaAsHtml
                                  multipleChoice
                                  delta={richText.ops}
                                />
                              ) : (
                                <div>{text}</div>
                              )}
                            </li>
                          );
                        }
                      )
                    ) : (
                      <li>
                        <strong>None of the above</strong>
                      </li>
                    )}
                  </ul>
                </div>
              );
            })}
        </div>
      );
    case QuestionTypeEnum.SelectOneMultipleChoice:
      return (
        <ul className={classes.list}>
          {multipleChoiceResponses?.map(
            ({ selectedMultipleChoiceAnswerChoices, createdAt }, i) => {
              if (selectedMultipleChoiceAnswerChoices.length === 0) {
                return null;
              }
              return (
                <li className={classes.noBullets} key={`singleMcAttempt-${i}`}>
                  <AttemptTypography
                    text={`ATTEMPT ${i + 1}:`}
                    createdAt={createdAt}
                  />
                  <span>
                    {selectedMultipleChoiceAnswerChoices[0]
                      .multipleChoiceAnswerChoice.richText ? (
                      <QuillDeltaAsHtml
                        multipleChoice
                        delta={
                          selectedMultipleChoiceAnswerChoices[0]
                            .multipleChoiceAnswerChoice.richText.ops
                        }
                      />
                    ) : (
                      <div>
                        {
                          selectedMultipleChoiceAnswerChoices[0]
                            .multipleChoiceAnswerChoice.text
                        }
                      </div>
                    )}
                  </span>
                </li>
              );
            }
          )}
        </ul>
      );

    default:
      return null;
  }
}

export default AttemptsDetails;
