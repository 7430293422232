import { useMutation, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import { UpdateQuestionDocument } from '../../../../gql/mutations/__generated__/question.generated';
import { GeneratedQuestionsQuery } from '../../../../gql/queries/__generated__/question.generated';
import { StandardsDocument } from '../../../../gql/queries/__generated__/standard.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import theme from '../../../../theme';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import {
  MultipleStandardsAutocomplete,
  StandardsQueryStandard,
} from '../../Questions/Form/MultipleStandardsAutocomplete';
import { updateQuestionFields } from './questions';

export function StandardsEdit({
  question,
}: {
  question: GeneratedQuestionsQuery['generatedQuestions'][0];
}) {
  const { data: teacherData } = useQuery(TeacherDocument);
  const { dispatch } = useContext(AlertsContext);
  const standardsChartId =
    teacherData?.teacher.activeCourse?.standardsChart?.id || '';

  const { data: standardsData, loading: standardsLoading } = useQuery(
    StandardsDocument,
    {
      skip: !standardsChartId,
      variables: { standardsChartId },
    }
  );
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });

  const options = useMemo(() => {
    if (standardsData?.standards) {
      return standardsData.standards;
    }
    return [];
  }, [standardsData]);
  const handleStandardUpdate = (
    _: unknown,
    newValue: StandardsQueryStandard[] | null
  ) => {
    const update = newValue || [];
    const changes: Partial<
      GeneratedQuestionsQuery['generatedQuestions'][number]
    > = {
      standards: newValue,
    };
    const questionnput = updateQuestionFields(question, changes);

    updateQuestion({ variables: { question: questionnput } });
  };

  return (
    <Box>
      <Typography sx={{ fontWeight: theme.typography.fontWeightBold, mb: 1 }}>
        Standard
      </Typography>
      <MultipleStandardsAutocomplete
        onChange={handleStandardUpdate}
        value={question.standards || []}
        options={options}
      />
    </Box>
  );
}
