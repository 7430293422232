import { useQuery } from '@apollo/client';
import { FileCopyOutlined } from '@mui/icons-material';
import { IconButton, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, type Dispatch, type SetStateAction } from 'react';
import { GetUpdateQuestionDocument } from '../../../../../../gql/queries/__generated__/question.generated';
import { QuestionFormContext } from '../../../../Questions/Form/context';
import { resetQuestionForm } from '../../../../Questions/Form/context/actions';
import { AssignmentEditorModes } from '../NormalAssignment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 30,
    padding: 0,
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

type CopyButtonProps = {
  setMode: Dispatch<SetStateAction<AssignmentEditorModes | undefined>>;
  questionId: string;
};

export function CopyButton({ questionId, setMode }: CopyButtonProps) {
  const classes = useStyles();
  const { data } = useQuery(GetUpdateQuestionDocument, {
    skip: !questionId,
    variables: { questionId: questionId || '' },
  });
  const { dispatch } = useContext(QuestionFormContext);
  const handleCopy = () => {
    if (dispatch && data) {
      dispatch(resetQuestionForm({ question: data.question }));
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
    setMode(AssignmentEditorModes.CREATE);
  };

  return (
    <IconButton
      aria-label="Copy Question"
      title="Copy Question"
      onClick={handleCopy}
      className={classes.root}
      size="large"
    >
      <FileCopyOutlined />
    </IconButton>
  );
}

export default CopyButton;
