import { useMutation, useQuery } from '@apollo/client';
import { Button, ButtonGroup, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { UpdateAssignmentDocument } from '../../../../../gql/mutations/__generated__/assignment.generated';
import { AssignmentDocument } from '../../../../../gql/queries/__generated__/assignment.generated';
import {
  AssignmentMethodEnum,
  AssignmentStatusEnum,
} from '../../../../../gql/types';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../../Alerts/context';
import { AssignmentEditorContext } from '../context';
import {
  onAssignmentEditorSaveSuccess,
  startAssignmentEditorLoading,
  updateAssignmentMethod,
} from '../context/actions';
import { StandardAssignGroups } from './StandardAssignGroups';
import { StandardAssignIndividuals } from './StandardAssignIndividuals';

const useStyles = makeStyles(() => ({
  individualAssignmentToggle: {
    marginTop: '12px',
    marginBottom: '12px',
  },
}));

export function StandardAssign() {
  const classes = useStyles();
  const {
    dispatch: assignmentDispatch,
    assignmentEditor: { id: assignmentId, assignmentMethod },
  } = useContext(AssignmentEditorContext);

  const { dispatch } = useContext(AlertsContext);

  const { data: assignmentData } = useQuery(AssignmentDocument, {
    onError: onError(dispatch),
    variables: { assignmentId },
  });

  const [updateAssignment] = useMutation(UpdateAssignmentDocument, {
    onCompleted: (data) => {
      onAssignmentEditorSaveSuccess(assignmentDispatch);
      assignmentDispatch(
        updateAssignmentMethod({
          method: data.updateAssignment.assignmentMethod,
        })
      );
    },
    onError: onError(dispatch),
  });

  const handleClassAssignment = () => {
    updateAssignment({
      variables: {
        assignmentId: assignmentId,
        assignmentMethod: AssignmentMethodEnum.Group,
      },
    });
    startAssignmentEditorLoading(assignmentDispatch);
  };
  const handleIndividualAssignment = () => {
    updateAssignment({
      variables: {
        assignmentId: assignmentId,
        assignmentMethod: AssignmentMethodEnum.Individual,
      },
    });
    startAssignmentEditorLoading(assignmentDispatch);
  };

  const classAssignmentVariant =
    assignmentMethod !== AssignmentMethodEnum.Individual
      ? 'contained'
      : 'outlined';
  const individualAssignmentVariant =
    assignmentMethod === AssignmentMethodEnum.Individual
      ? 'contained'
      : 'outlined';
  const assignComponent =
    assignmentMethod === AssignmentMethodEnum.Individual ? (
      <StandardAssignIndividuals />
    ) : (
      <StandardAssignGroups />
    );

  const editable =
    assignmentData?.assignment.assignmentStatus ===
    AssignmentStatusEnum.Pending;

  return (
    <div>
      <Typography variant="h4">Assign</Typography>
      <ButtonGroup className={classes.individualAssignmentToggle}>
        <Button
          disabled={!editable}
          variant={classAssignmentVariant}
          onClick={handleClassAssignment}
        >
          Class Assignment
        </Button>
        <Button
          disabled={!editable}
          variant={individualAssignmentVariant}
          onClick={handleIndividualAssignment}
        >
          Individual Assignment
        </Button>
      </ButtonGroup>
      {assignComponent}
    </div>
  );
}
