import { useQuery } from '@apollo/client';
import { Alert, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { GroupLiteDocument } from '../../../gql/queries/__generated__/group.generated';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  alert: {
    backgroundColor: theme.palette.warning.light,
    border: `1px solid ${theme.palette.warning.dark}`,
  },
  alertContainer: {
    margin: '0 auto 16px auto',
    textAlign: 'center',
    maxWidth: 1000,
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
}));

export function InfoAboutFrozenPersonalDeck({ groupId }: { groupId: string }) {
  const classes = useStyles();
  const { data } = useQuery(GroupLiteDocument, {
    skip: !groupId,
    variables: { groupId },
  });

  const personalDeckFrozen =
    data?.group.freezePersonalDeck || data?.group.unfreezeInProgress;
  if (!personalDeckFrozen) {
    return null;
  }

  return (
    <div className={classes.alertContainer}>
      <Alert severity="warning" variant="outlined" className={classes.alert}>
        Personal Deck is currently frozen for this class, so the data below may
        not accurately reflect the current state of this class&apos; Personal
        Decks. Navigate to{' '}
        <Link to="/settings" className={classes.link}>
          settings
        </Link>{' '}
        to unfreeze.
      </Alert>
    </div>
  );
}

export default InfoAboutFrozenPersonalDeck;
