import { Stack, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuestionTypeEnum, type Question } from '../../../../gql/types';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { QuestionShowMultipleChoiceAnswer } from './QuestionShowMultipleChoiceAnswer';

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    listStyle: 'none',
    paddingLeft: theme.spacing(1),
  },
  listItem: {
    display: 'flex',
  },
  bullet: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
  },
  meta: {
    marginLeft: theme.spacing(1),
  },
  darkTitle: {
    color: theme.palette.primary.main,
  },
  mcAnswersContainer: {
    display: 'flex',
  },
  mcList: {
    width: '50%',
  },
  listHeader: {
    marginLeft: '-8px',
    fontWeight: theme.typography.fontWeightBold,
  },
  freeResponseAnswer: {
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(1),
  },
}));

type QuestionShowAnswersProps = {
  question: Question;
};

export function QuestionShowAnswers({ question }: QuestionShowAnswersProps) {
  const classes = useStyles();
  switch (question.questionType) {
    case QuestionTypeEnum.FreeResponse:
      return (
        <>
          <Typography
            className={classes.darkTitle}
            variant="h5"
            color="primary"
          >
            EXEMPLAR ANSWER
          </Typography>
          <div className={classes.freeResponseAnswer}>
            <QuillDeltaAsHtml
              delta={question.freeResponseAnswer?.richText.ops}
            />
          </div>
        </>
      );
    case QuestionTypeEnum.ShortAnswer:
      return (
        <>
          <Typography
            className={classes.darkTitle}
            variant="h5"
            color="primary"
          >
            ANSWER OPTIONS
            <span>{` (Shown after ${question.numAttemptsUntilAnswerShown} attempt(s))`}</span>
          </Typography>
          <ul className={classes.list}>
            {question?.shortAnswerAnswers?.map((answer) => {
              return (
                <li
                  className={classes.listItem}
                  key={`short-answer-${answer.id}`}
                >
                  <Stack direction="row" style={{ alignItems: 'center' }}>
                    <span className={classes.bullet}>•</span>
                    <QuillDeltaAsHtml
                      delta={answer.richText.ops}
                      style={{ backgroundColor: 'transparent' }}
                    />
                    {answer.isCaseSensitive && (
                      <div className={classes.meta}>Case sensive</div>
                    )}
                  </Stack>
                </li>
              );
            })}
          </ul>
        </>
      );
    case QuestionTypeEnum.SelectAllMultipleChoice:
    case QuestionTypeEnum.SelectOneMultipleChoice:
      return (
        <>
          <Typography
            className={classes.darkTitle}
            variant="h5"
            color="primary"
          >
            ANSWER OPTIONS
          </Typography>
          <div className={classes.mcAnswersContainer}>
            <ul className={`${classes.list} ${classes.mcList}`}>
              <Typography
                color="primary"
                variant="body1"
                className={classes.listHeader}
              >
                Correct
                <span>{` (Shown after ${question.numAttemptsUntilAnswerShown} attempt(s))`}</span>
              </Typography>
              {question?.multipleChoiceAnswerChoices
                ?.filter(({ isCorrect }) => {
                  return isCorrect;
                })
                .map((answer) => (
                  <QuestionShowMultipleChoiceAnswer
                    key={`${answer.id}-mc`}
                    answer={answer}
                  />
                ))}
            </ul>
            <ul className={`${classes.list} ${classes.mcList}`}>
              <Typography
                color="primary"
                variant="body1"
                className={classes.listHeader}
              >
                Incorrect
              </Typography>
              {question?.multipleChoiceAnswerChoices
                ?.filter(({ isCorrect }) => {
                  return !isCorrect;
                })
                .map((answer) => (
                  <QuestionShowMultipleChoiceAnswer
                    key={`${answer.id}-mc`}
                    answer={answer}
                  />
                ))}
            </ul>
          </div>
        </>
      );

    default:
      return null;
  }
}
