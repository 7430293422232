import { useMutation, useQuery } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { CollaborationCloneAssignmentDocument } from '../../../../../gql/mutations/__generated__/assignment.generated';
import { AssignmentsConnectionDocument } from '../../../../../gql/queries/__generated__/assignment.generated';
import { StandardsDocument } from '../../../../../gql/queries/__generated__/standard.generated';
import { CollaborativeStandardsChartsDocument } from '../../../../../gql/queries/__generated__/standardsChart.generated';
import { OwnershipEnum } from '../../../../../gql/types';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { HeaderBackButton } from '../../../../shared/HeaderBackButton';
import { SideDrawer } from '../../../../shared/Layout/SideDrawer';
import { AlertsContext } from '../../../Alerts/context';
import { pushSnack } from '../../../Alerts/context/actions';
import type { StandardsQueryStandard } from '../../../Questions/Form/MultipleStandardsAutocomplete';
import { ASSIGNMENTS_INDEX_PAGINATION_COUNT } from '../../AssignmentsIndex/index';
import FirstPage from './FirstPage';
import FooterContent from './FooterContent';
import SecondPage from './SecondPage';

type AssignmentCopyFormProps = {
  open: boolean;
  handleClose: () => void;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
}));

export function AssignmentCopyForm({
  handleClose,
  open,
}: AssignmentCopyFormProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const [page, setPage] = useState(1);
  const [selectedAssignment, setSelectedAssignment] = useState<string>('');
  const [currentAuthor, setCurrentAuthor] = useState<OwnershipEnum>(
    OwnershipEnum.Own
  );
  const [currentStandardsChartId, setCurrentStandardsChartId] =
    useState<string>('all');
  const [currentStandards, setCurrentStandards] = useState<
    StandardsQueryStandard[]
  >([]);
  const [currentNameSearchString, setCurrentNameSearchString] =
    useState<string>('');
  const [debouncedCurrentNameSearchString] = useDebounce(
    currentNameSearchString,
    500
  );
  const [refetching, setRefetching] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const {
    data: filteredAssignmentsData,
    loading,
    error,
    fetchMore,
  } = useQuery(AssignmentsConnectionDocument, {
    variables: {
      first: ASSIGNMENTS_INDEX_PAGINATION_COUNT,
      ownership: currentAuthor,
      standardsChartId:
        currentStandardsChartId !== 'all' ? currentStandardsChartId : null,
      standardIds:
        currentStandards.length !== 0 ? [currentStandards[0].id] : null,
      name: debouncedCurrentNameSearchString,
    },
  });
  const { data: standardsChartsData } = useQuery(
    CollaborativeStandardsChartsDocument,
    {
      variables: {
        ownership: currentAuthor,
      },
      onCompleted: (data) => {
        if (data.collaborativeStandardsCharts.length > 0) {
          setCurrentStandardsChartId(data.collaborativeStandardsCharts[0].id);
        }
      },
    }
  );
  const { data: standardsData } = useQuery(StandardsDocument, {
    variables: {
      standardsChartId: currentStandardsChartId,
    },
  });
  const filteredAssignments =
    filteredAssignmentsData &&
    filteredAssignmentsData.assignmentsConnection &&
    filteredAssignmentsData.assignmentsConnection.edges;

  const fullSelectedAssignment =
    filteredAssignments &&
    filteredAssignments.find((a) => {
      if (a && a.node && a.node.id) {
        return a.node.id === selectedAssignment;
      }
      return 0;
    });

  const [cloneAssignment] = useMutation(CollaborationCloneAssignmentDocument, {
    variables: { assignmentId: selectedAssignment },
    onError: (error) => onError(dispatch)(error),
    refetchQueries: ['assignments'],
    onCompleted: () => {
      dispatch(
        pushSnack({
          message: `Assignment ${fullSelectedAssignment?.node?.name} successfully copied and added to your draft assignments.`,
        })
      );
      resetState();
      handleClose();
    },
  });

  const fetchNextBatch = () => {
    setRefetching(true);
    fetchMore({
      variables: {
        first: ASSIGNMENTS_INDEX_PAGINATION_COUNT,
        after:
          filteredAssignmentsData?.assignmentsConnection.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        fetchMoreResult.assignmentsConnection.edges = [
          ...(previousResult?.assignmentsConnection.edges || []),
          ...(fetchMoreResult.assignmentsConnection.edges || []),
        ];
        setRefetching(false);
        return fetchMoreResult;
      },
    });
  };

  const resetState = () => {
    setPage(1);
    setSelectedAssignment('');
    setCurrentAuthor(OwnershipEnum.Own);
    setCurrentStandardsChartId('all');
    setCurrentStandards([]);
    setCurrentNameSearchString('');
    setRefetching(false);
    fetchMore({});
    setScrollPosition(0);
  };

  const returnToFirstPageAndUnselectAssignment = () => {
    setPage(1);
    setSelectedAssignment('');
  };

  const returnToFirstPageOrHandleClose = () => {
    if (page === 1) {
      handleClose();
    } else {
      returnToFirstPageAndUnselectAssignment();
    }
  };

  return (
    <SideDrawer
      isOpen={open}
      onClose={() => {
        resetState();
        handleClose();
      }}
      title={'Copy and Add Assignment'}
      footerContent={
        <FooterContent
          page={page}
          onCancel={returnToFirstPageOrHandleClose}
          onCopyAssignment={() => {
            if (selectedAssignment) {
              cloneAssignment();
            } else {
              dispatch(
                pushSnack({
                  severity: 'error',
                  message: 'You did not select an assignment to copy.',
                })
              );
            }
          }}
        />
      }
      extraHeaderContent={
        page !== 1 ? (
          <HeaderBackButton
            onClickBack={() => {
              setPage(1);
              setSelectedAssignment('');
            }}
          />
        ) : null
      }
    >
      <div className={classes.root}>
        {page === 1 && (
          <FirstPage
            state={{
              currentAuthor,
              currentStandardsChartId,
              currentStandards,
              currentNameSearchString,
              page,
              selectedAssignment,
              scrollPosition,
            }}
            stateSetters={{
              setCurrentAuthor,
              setCurrentStandards,
              setCurrentStandardsChartId,
              setCurrentNameSearchString,
              setPage,
              setSelectedAssignment,
              setScrollPosition,
            }}
            filteredAssignments={filteredAssignments}
            loading={loading && !filteredAssignmentsData}
            error={error}
            standardsChartsData={standardsChartsData}
            standardsData={standardsData}
            fetchNextBatch={fetchNextBatch}
            totalAssignments={
              filteredAssignmentsData?.assignmentsConnection?.totalCount || 0
            }
            hasNextPage={
              filteredAssignmentsData?.assignmentsConnection?.pageInfo
                ?.hasNextPage || false
            }
            refetching={refetching}
          />
        )}
        {page === 2 && fullSelectedAssignment && (
          <SecondPage assignment={fullSelectedAssignment} />
        )}
      </div>
    </SideDrawer>
  );
}

export default AssignmentCopyForm;
