import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import theme from '../../../theme';
import { FlaggableQuestion } from './FlaggableQuestion';
import { StandardHolder } from './utils';

export type Dict = {
  [key: number]: boolean;
};

export function StandardDisplay({
  standard,
  userID,
}: {
  standard: StandardHolder;
  userID: number;
}) {
  const [flags, setFlags] = useState<Dict>({});
  const [approvals, setApprovals] = useState<Dict>({});
  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    const tempFlags: Dict = {};
    const tempApprovals: Dict = {};
    standard.concepts.forEach((conceptHolder) => {
      if (conceptHolder.concept.flagged) {
        tempFlags[conceptHolder.concept.id] = true;
      }
      if (conceptHolder.concept.approved) {
        tempApprovals[conceptHolder.concept.id] = true;
      }
    });
    setFlags(tempFlags);
    setApprovals(tempApprovals);
  }, [standard]);
  const render = () => {
    const sortedConcepts = standard.concepts.sort(
      (c1, c2) => c1.concept.id - c2.concept.id
    );

    return (
      <Box
        sx={{
          margin: '0 auto',
        }}
      >
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            border: `1px solid ${theme.palette.mint.main}`,
            backgroundColor: theme.palette.primary.main,
          }}
          expanded={expanded}
          onChange={(event: React.SyntheticEvent, newExpanded: boolean) =>
            setExpanded(newExpanded)
          }
        >
          <AccordionSummary
            sx={{
              color: theme.palette.mint.main,
              backgroundColor: theme.palette.primary.main,
              padding: theme.spacing(3),
              borderRadius: '4px',
              '.MuiAccordionSummary-expandIconWrapper': {
                color: theme.palette.secondary.main,
              },
            }}
            expandIcon={<ExpandMore />}
            aria-controls="standards-category"
            id="standards-category"
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: theme.spacing(1.5),
                }}
              >
                <h3>{standard.standard.name}</h3>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'flex',
              flexFlow: 'column',
              paddingTop: theme.spacing(3),
              paddingBottom: theme.spacing(3),
              backgroundColor: theme.palette.primary.main,
            }}
          >
            {sortedConcepts.map((concept) => (
              <FlaggableQuestion
                concept={concept}
                key={`concept-${concept.concept.id}`}
                userID={userID}
                conceptID={concept.concept.id}
                isApproved={approvals[concept.concept.id] === true}
                setApprovals={setApprovals}
                isFlagged={flags[concept.concept.id] === true}
                setFlags={setFlags}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        borderRadius: 4,
        marginBottom: theme.spacing(3),
        padding: theme.spacing(3),
        color: theme.palette.common.white,
      }}
    >
      {render()}
    </Box>
  );
}

export default StandardDisplay;
