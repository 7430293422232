import { Typography, useTheme } from '@mui/material';

export function RequiredFieldAsterisk() {
  const theme = useTheme();
  return (
    <Typography
      sx={{ color: theme.palette.error.main, display: 'inline', ml: 0 }}
    >
      *
    </Typography>
  );
}
