import makeStyles from '@mui/styles/makeStyles';
import type { Sources } from 'quill';
import { useContext, useState } from 'react';
import { UnprivilegedEditor, type Value } from 'react-quill';
import { getPlainTextFromDelta } from '../../../../../utils/quillHelper';
import Editor from '../../../../shared/Editor';
import { QuestionFormContext } from '../context';
import { updateFreeResponseAnswer } from '../context/actions';
import TextLimit from './TextLimit';

export const FREE_RESPONSE_CHARACTER_LIMIT = 400;

const useStyles = makeStyles(() => ({
  root: {},
}));

export function FreeResponseAnswer() {
  const classes = useStyles();
  const { questionForm, dispatch } = useContext(QuestionFormContext);
  const [limitExceeded, setLimitExceeded] = useState(false);
  const { text } = questionForm.freeResponseAnswer;
  const [textLength, setTextLength] = useState(
    getPlainTextFromDelta(text).length
  );
  // const textLength = () => {
  //   return getPlainTextFromDelta(text).length;
  // };
  const handleChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    const newValue = editor.getContents();
    const plainText = getPlainTextFromDelta(newValue);
    if (plainText.length > FREE_RESPONSE_CHARACTER_LIMIT) {
      setLimitExceeded(true);
    } else {
      setLimitExceeded(false);
    }
    dispatch(
      updateFreeResponseAnswer({
        comparable: questionForm.freeResponseAnswer.comparable,
        value: newValue,
      })
    );
    setTextLength(plainText.length);
  };
  return (
    <div className={classes.root}>
      <Editor value={text} onChange={handleChange} />
      <TextLimit limitExceeded={limitExceeded} textLength={textLength} />
    </div>
  );
}
