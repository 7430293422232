import {
  MenuItem,
  Select,
  Typography,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isString } from '@podsie/utils/string.js';
import type { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { format, parse, startOfDay, subDays } from 'date-fns';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useAuth0 } from '../../../Auth0Context';
import { config } from '../../../podsie-config.js';
import { stringToColor } from '../../../utils/color';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { LS_PDQ_DATA_OVER_TIME_TYPE_KEY } from '../../../utils/localStorageKeys';
import { LoadingOverlay } from '../../shared/Layout/LoadingOverlay';
import InfoAboutFrozenPersonalDeck from './InfoAboutFrozenPersonalDeck';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  graphContainer: {
    padding: theme.spacing(6),
    width: '95vw',
    [theme.breakpoints.up('md')]: {
      width: '85vw',
    },
    [theme.breakpoints.up('lg')]: {
      width: '80vw',
    },
    [theme.breakpoints.up('xl')]: {
      width: '65vw',
    },
    maxWidth: 1400,
    margin: '0 auto',
  },
  select: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
}));

const lastWeek = startOfDay(subDays(new Date(), 7));
const thirtyDaysAgo = startOfDay(subDays(new Date(), 30));
const ninetyDaysAgo = startOfDay(subDays(new Date(), 90));

enum DataTypes {
  NUM_DUE = 'NUM_DUE',
  COMPLETED = 'COMPLETED',
  REMAINING = 'REMAINING',
  MISSED = 'MISSED',
}

interface ChartDataSetsWithMeta extends ChartDataSets {
  _meta?: {
    [key: number]: {
      hidden: boolean;
    };
  };
}

type PdqCache = { [key: string]: { numAttempted: number } };

type PersonalDeckData = {
  date: string;
  enrollment_id: number;
  full_name: string;
  num_attempted: number;
  num_completed: number;
  num_correct: number;
  num_due_at_start_of_day: number;
};

type PersonalDeckOverTimeProps = {
  groupId: string;
};

export function PersonalDeckOverTime({ groupId }: PersonalDeckOverTimeProps) {
  useTrackVisit({
    section: 'personal-deck',
    page: 'progress-over-time',
  });
  const classes = useStyles();
  const { user } = useAuth0();

  const SERVER_URL =
    user &&
    isString(user.email) &&
    (user.email.endsWith('lexrich5.org') || user.email === 'kyobs@hotmail.com')
      ? config.serverBackupRaw
      : config.serverRaw;

  const [dataType, setDataType] = useState(
    localStorage.getItem(LS_PDQ_DATA_OVER_TIME_TYPE_KEY) || DataTypes.NUM_DUE
  );
  const [timeFilter, setTimeFilter] = useState(lastWeek);
  const [cache, setCache] = useState<PdqCache>({});

  const [personalDeckData, setPersonalDeckData] = useState([]);
  const [personalDeckDataLoading, setPersonalDeckDataLoading] = useState(false);
  useEffect(() => {
    const queryString = `${SERVER_URL}/api/v1/personal_deck_data?group_id=${groupId}&start_date=${timeFilter}`;
    setPersonalDeckDataLoading(true);
    if (!groupId) {
      return;
    }
    fetch(queryString)
      .then((res) => res.json())
      .then((res) => {
        setPersonalDeckData(res);
        setPersonalDeckDataLoading(false);
      });
  }, [groupId, timeFilter, SERVER_URL]);

  const [hideAll, setHideAll] = useState<boolean>(false);
  const chartRef = useRef<Line>(null);

  const lineData: ChartData = useMemo(() => {
    const tempCache: PdqCache = {};
    const processedData: {
      [key: string]: {
        data: Array<{ x: string; y: number }>;
        label: string;
      };
    } = {};

    personalDeckData
      .slice()
      .sort(
        (a: PersonalDeckData, b: PersonalDeckData) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      )
      .forEach((d: PersonalDeckData) => {
        let yData: number = 0;
        const label = d.full_name || '';
        switch (dataType) {
          case DataTypes.NUM_DUE:
            yData = d.num_due_at_start_of_day;
            break;
          case DataTypes.COMPLETED:
            yData = d.num_completed;
            break;
          case DataTypes.REMAINING:
            yData =
              d.num_due_at_start_of_day - d.num_completed < 0
                ? 0
                : d.num_due_at_start_of_day - d.num_completed;
            break;
          case DataTypes.MISSED:
            yData = d.num_attempted ? d.num_attempted - d.num_correct : 0;
            break;
          default:
            break;
        }
        if (processedData[d.enrollment_id]) {
          processedData[d.enrollment_id].data.push({
            x: d.date,
            y: yData,
          });
        } else {
          processedData[d.enrollment_id] = {
            data: [
              {
                x: d.date,
                y: yData,
              },
            ],
            label,
          };
        }
        const parsedDate = parse(d.date, 'yyyy-MM-dd', new Date());
        tempCache[`${d.enrollment_id}-${format(parsedDate, 'MMM dd')}`] = {
          numAttempted: d.num_attempted,
        };
      });
    setCache(tempCache);
    return {
      labels: [timeFilter],
      datasets: Object.keys(processedData)
        .map((enrollmentId) => {
          const dataPoint = processedData[enrollmentId];
          const colorString = enrollmentId + dataPoint.label + enrollmentId;
          return {
            label: dataPoint.label,
            data: dataPoint.data,
            fill: false,
            backgroundColor: stringToColor(colorString),
            borderColor: stringToColor(colorString),
            key: enrollmentId,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)),
    };
  }, [personalDeckData, timeFilter, dataType, setCache]);

  const handleTimeFilterChange = (e: SelectChangeEvent<string>) => {
    const dateValue = new Date(e.target.value as string) as Date;
    setTimeFilter(dateValue);
  };

  const handleDataTypeChange = (e: SelectChangeEvent<string>) => {
    const v = e.target.value as DataTypes;
    setDataType(v);
    localStorage.setItem(LS_PDQ_DATA_OVER_TIME_TYPE_KEY, v);
  };

  let label = '';
  switch (dataType) {
    case DataTypes.NUM_DUE:
      label = 'Number of Questions Due at Start of Day';
      break;
    case DataTypes.COMPLETED:
      label = 'Number of Questions Completed';
      break;
    case DataTypes.REMAINING:
      label =
        'Number of Questions Due at Start of Day - Number of Questions Completed';
      break;
    case DataTypes.MISSED:
      label = 'Number of Questions Missed';
      break;
    default:
      break;
  }
  const options: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: label,
          },
          ticks: {
            stepSize: 5,
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day' as const,
            tooltipFormat: 'MMM DD',
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const datapoint: (ChartDataSets & { key?: string }) | undefined =
            data.datasets && tooltipItem.datasetIndex !== undefined
              ? data.datasets[tooltipItem.datasetIndex]
              : undefined;
          if (
            datapoint === undefined ||
            tooltipItem.datasetIndex === undefined
          ) {
            return '';
          }
          let label = datapoint.label || '';
          label = `${label}: ${tooltipItem.yLabel}`;
          const enrollmentId = datapoint.key;
          if (dataType === DataTypes.MISSED && enrollmentId) {
            const key = `${enrollmentId}-${tooltipItem.label}`;
            if (cache[key]) {
              const { numAttempted } = cache[key];
              label = `${label} Missed (${numAttempted} Questions Attempted)`;
            }
          }
          return label;
        },
      },
    },
    legend: {
      onClick: (e, legendItem) => {
        const ci = chartRef.current?.chartInstance;
        const i = legendItem.datasetIndex;
        if (ci && ci.data.datasets && i !== undefined) {
          const meta = ci.getDatasetMeta(i);
          meta.hidden =
            meta.hidden === undefined
              ? !ci.data.datasets[i].hidden
              : !meta.hidden;

          ci.update();
        }
      },
    },
  };

  const handleToggle = () => {
    const chartInstance = chartRef.current?.chartInstance;
    if (chartInstance && chartInstance.data.datasets) {
      chartInstance.data.datasets.forEach(function (ds: ChartDataSetsWithMeta) {
        ds.hidden = !hideAll;
        if (ds._meta !== undefined) {
          for (const key in ds._meta) {
            const m = ds._meta[key];
            m.hidden = !hideAll;
          }
        }
      });
      chartInstance.update();
      setHideAll(!hideAll);
    }
  };

  return (
    <div className={classes.root}>
      <InfoAboutFrozenPersonalDeck groupId={groupId} />
      <div className={classes.header}>
        <Typography variant="h4"># of Questions</Typography>
        <Select
          onChange={handleDataTypeChange}
          className={classes.select}
          value={dataType}
        >
          <MenuItem value={DataTypes.NUM_DUE}>Due at Start of Day</MenuItem>
          <MenuItem value={DataTypes.COMPLETED}>Completed</MenuItem>
          <MenuItem value={DataTypes.REMAINING}>Remaining</MenuItem>
          <MenuItem value={DataTypes.MISSED}>Missed</MenuItem>
        </Select>
        <Typography variant="h4">Over the Past</Typography>
        <Select
          onChange={handleTimeFilterChange}
          className={classes.select}
          value={timeFilter.toISOString()}
        >
          <MenuItem value={lastWeek.toISOString()}>7 Days</MenuItem>
          <MenuItem value={thirtyDaysAgo.toISOString()}>30 Days</MenuItem>
          <MenuItem value={ninetyDaysAgo.toISOString()}>90 Days</MenuItem>
        </Select>
      </div>
      <div className={classes.graphContainer}>
        <div className={classes.buttonContainer}>
          <button onClick={handleToggle}>{`${
            hideAll ? 'Show' : 'Hide'
          } All Students`}</button>
        </div>
        <div>
          <LoadingOverlay loading={personalDeckDataLoading}>
            <Line
              datasetKeyProvider={(d) => d.key}
              data={lineData}
              options={options}
              ref={chartRef}
            />
          </LoadingOverlay>
        </div>
      </div>
    </div>
  );
}
