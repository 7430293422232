import { CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ReactElement } from 'react';

export type LoadingOverlayProps = {
  children: ReactElement;
  loading: boolean;
};

const useStyles = makeStyles(() => ({
  spinner: {
    position: 'absolute' as const,
    left: '50%',
    top: '50%',
    height: 60,
    width: 60,
    margin: '0px auto',
  },
  loader: {
    backgroundColor: 'rgba(0,0,0,.5)',
    position: 'relative' as const,
  },
  '@keyframes rotation': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: { transform: 'rotate(359deg)' },
  },
}));

export function LoadingOverlay({ children, loading }: LoadingOverlayProps) {
  const classes = useStyles();
  return (
    <div className={loading ? classes.loader : undefined}>
      {loading && (
        <CircularProgress
          key={`${loading}-loading-overlay`}
          className={classes.spinner}
          color="primary"
        />
      )}
      {children}
    </div>
  );
}

export default LoadingOverlay;
