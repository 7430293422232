import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type JurisdictionsQueryVariables = Types.Exact<{
  country: Types.Scalars['String']['input'];
}>;

export type JurisdictionsQuery = {
  __typename?: 'Query';
  jurisdictions: Array<{
    __typename?: 'Jurisdiction';
    id: string;
    title: string;
    countryCode: string;
  }>;
};

export const JurisdictionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'jurisdictions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'jurisdictions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<JurisdictionsQuery, JurisdictionsQueryVariables>;
