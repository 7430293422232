import React, { useReducer } from 'react';
import { QuestionFormAction } from './actions';
import initialState, { QuestionFormState } from './initialState';
import reducer from './reducer';

const QuestionFormContext = React.createContext<{
  questionForm: QuestionFormState;
  dispatch: React.Dispatch<QuestionFormAction>;
}>({
  questionForm: initialState,
  dispatch: () => null,
});

type QuestionFormProviderProps = {
  children: React.ReactElement;
  preloadedState?: QuestionFormState;
};

function QuestionFormProvider({
  children,
  preloadedState = initialState,
}: QuestionFormProviderProps) {
  const processedPreloadedState = {
    ...initialState,
    ...preloadedState,
  };
  const [questionForm, dispatch] = useReducer(reducer, processedPreloadedState);
  return (
    <QuestionFormContext.Provider value={{ questionForm, dispatch }}>
      {children}
    </QuestionFormContext.Provider>
  );
}
export { QuestionFormContext, QuestionFormProvider };
