import { ChevronRight, type SvgIconComponent } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { forwardRef, useMemo, type ComponentProps } from 'react';

type IconProps = ComponentProps<SvgIconComponent>;

export const DetailPanelIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => {
    // This was extracted from an inline definition of this component. We should
    // consider using the `makeStyles` and `className` convention established
    // everywhere else in this project.
    const theme = useTheme();
    const style = useMemo(
      () => ({
        ...(props.style ?? {}),
        color: theme.palette.secondary.main,
      }),
      [props.style, theme.palette.secondary.main]
    );

    return <ChevronRight {...props} ref={ref} style={style} />;
  }
);

DetailPanelIcon.displayName = 'DetailPanelIcon';
