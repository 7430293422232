import { useMutation, useQuery } from '@apollo/client';
import { ExpandMore, FileCopy } from '@mui/icons-material';
import {
  Collapse,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { asHumanText } from '@podsie/utils/string.js';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory } from 'react-router-dom';
import userflow from 'userflow.js';
import { CollaborationCloneAssignmentDocument } from '../../../../gql/mutations/__generated__/assignment.generated';
import { CollaborationAssignmentsDocument } from '../../../../gql/queries/__generated__/assignment.generated';
import { dateFormatWithoutTime } from '../../../../utils/dates';
import HelpKitSharedAssignments from '../../HelpKitArticles/HelpKitSharedAssignments';
import SharedAssignmentsQuestions from './SharedAssignmentsQuestions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.mint.light,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    borderRadius: 4,
  },
  expandedContainer: {},
  expandButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    marginTop: theme.spacing(1),
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    color: theme.palette.secondary.main,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.secondary.main,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexWithoutSpaceBetween: {
    display: 'flex',
    alignItems: 'center',
  },
  disabled: {
    color: theme.palette.grays.main,
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  createdAtDate: {
    marginLeft: theme.spacing(1),
  },
  helpkitContainer: {
    marginTop: theme.spacing(1),
  },
}));

type SharedAssignmentsProps = {
  teamId: string;
};

const SHARED_ASSIGNMENTS_PAGINATION_COUNT = 20;

export function SharedAssignments({ teamId }: SharedAssignmentsProps) {
  const classes = useStyles();
  const { data, loading, fetchMore } = useQuery(
    CollaborationAssignmentsDocument,
    {
      variables: {
        first: SHARED_ASSIGNMENTS_PAGINATION_COUNT,
        teamId,
      },
    }
  );
  const history = useHistory();
  const [cloneCollaborationAssignment] = useMutation(
    CollaborationCloneAssignmentDocument,
    {
      onCompleted: (res) => {
        userflow.track('userflow_assignment_cloned', {
          assignment_id: res.collaborationCloneAssignment.id,
          assignment_name: res.collaborationCloneAssignment.name,
        });
        history.push(`/assignments/${res.collaborationCloneAssignment.id}`);
      },
    }
  );
  const [expandedAssignmentIds, setExpandedAssignmentIds] = useState(new Set());
  const paginatedResults = data?.collaborationAssignments.edges || [];
  const totalAssignments = data?.collaborationAssignments.totalCount || 0;

  const fetchNextBatch = () => {
    fetchMore({
      variables: {
        first: SHARED_ASSIGNMENTS_PAGINATION_COUNT,
        after: data?.collaborationAssignments.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        fetchMoreResult.collaborationAssignments.edges = [
          ...(previousResult?.collaborationAssignments.edges || []),
          ...(fetchMoreResult.collaborationAssignments.edges || []),
        ];
        return fetchMoreResult;
      },
    });
  };

  const toggle = (assignmentId: string) => {
    return () => {
      const updated = new Set(expandedAssignmentIds);
      if (expandedAssignmentIds.has(assignmentId)) {
        updated.delete(assignmentId);
      } else {
        updated.add(assignmentId);
      }
      setExpandedAssignmentIds(updated);
    };
  };

  const cloneAssignment = (assignmentId: string) => {
    return () => {
      cloneCollaborationAssignment({
        variables: {
          assignmentId,
        },
      });
    };
  };

  return (
    <InfiniteScroll
      scrollThreshold={0.7}
      dataLength={paginatedResults.length}
      next={fetchNextBatch}
      hasMore={paginatedResults.length < totalAssignments}
      scrollableTarget="scrollable-dialog"
      loader={loading ? <Typography>Loading...</Typography> : null}
    >
      <div className={classes.root}>
        <div className={classes.helpkitContainer}>
          <HelpKitSharedAssignments />
        </div>
        {data?.collaborationAssignments.edges?.map((edge) => {
          const numQuestions = edge?.node?.numQuestions || 0;
          const assignment = edge?.node;
          const assignmentId = assignment?.id || '';
          const questionsExpanded = expandedAssignmentIds.has(assignmentId);
          const teacher = assignment?.teacher;
          const createdAt = edge?.node?.createdAt;
          const createdAtFormatted = createdAt
            ? format(new Date(createdAt), dateFormatWithoutTime)
            : '';
          const author =
            asHumanText(teacher?.fullName) ?? asHumanText(teacher?.email);
          return (
            <div
              className={classes.container}
              key={`collaborative-assignment-node-${assignment?.id}`}
            >
              <div className={classes.flex}>
                <div className={classes.flexWithoutSpaceBetween}>
                  <Tooltip title="Clone Assignment">
                    <IconButton
                      color="primary"
                      onClick={cloneAssignment(assignmentId)}
                      size="large"
                    >
                      <FileCopy />
                    </IconButton>
                  </Tooltip>
                  <div>
                    <Typography variant="h4" color="primary">
                      {assignment?.name}
                    </Typography>
                    <div className={classes.flexWithoutSpaceBetween}>
                      <Typography>{author}</Typography>
                      <Typography className={classes.createdAtDate}>
                        ({createdAtFormatted})
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={classes.flexWithoutSpaceBetween}>
                  <Typography variant="body2" color="primary">
                    {`${numQuestions} question${numQuestions === 1 ? '' : 's'}`}
                  </Typography>

                  <button
                    disabled={numQuestions === 0}
                    className={classes.expandButton}
                    onClick={toggle(assignmentId)}
                  >
                    <ExpandMore
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: questionsExpanded,
                        [classes.disabled]: numQuestions === 0,
                      })}
                    />
                  </button>
                </div>
              </div>
              <Collapse
                in={questionsExpanded}
                className={classes.expandedContainer}
              >
                {questionsExpanded && (
                  <SharedAssignmentsQuestions assignmentId={assignmentId} />
                )}
              </Collapse>
            </div>
          );
        })}
        {loading && <Typography>Loading...</Typography>}
      </div>
    </InfiniteScroll>
  );
}

export default SharedAssignments;
