import { useQuery } from '@apollo/client';
import { FormatListBulleted } from '@mui/icons-material';
import {
  Box,
  Card,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { CurriculumAssignmentsDocument } from '../../../gql/queries/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../gql/types';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';

const headerHeight = 64;

const useStyles = makeStyles((theme: Theme) => ({
  itemsList: {
    borderRight: '1px solid black',
    height: `calc(100vh - ${headerHeight}px)`,
    overflow: 'auto',
  },
  assignmentCard: {
    borderBottom: `1px solid ${theme.palette.grays.light}`,
    padding: theme.spacing(2.5),
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.grays.light,
      cursor: 'pointer',
    },
  },
  cardActionArea: {
    '&:hover': {
      // disable hover effect:
      backgroundColor: 'transparent',
    },
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
  },
  leftContainer: {},
  noResultsMessage: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  searchContainer: {
    padding: theme.spacing(3),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  searchField: {
    backgroundColor: theme.palette.common.white,
  },
  infoContainer: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
  },
  listIcon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
  },
  typography: {
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  reviewTypography: {
    marginLeft: theme.spacing(0.5),
  },
  needReview: {
    color: theme.palette.error.main,
  },
  noReview: {
    color: theme.palette.secondary.main,
  },
  selectedAssignment: {
    backgroundColor: theme.palette.mint.light,
  },
  flexRow: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    marginLeft: theme.spacing(0.5),
  },
}));

const ASSIGNMENTS_INDEX_PAGINATION_COUNT = 12;

type CurriculumAssignmentsProps = {
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  searchText: string;
  setSearchText: (text: string) => void;
};

export function CurriculumAssignments({
  selectedIndex,
  setSelectedIndex,
  searchText,
  setSearchText,
}: CurriculumAssignmentsProps) {
  useTrackVisit({
    section: 'notifications',
    page: 'notifications',
  });
  const classes = useStyles();
  const [searchValue] = useDebounce(searchText, 700);
  const statuses: AssignmentStatusEnum[] = [
    AssignmentStatusEnum.Active,
    AssignmentStatusEnum.Pending,
    AssignmentStatusEnum.PastDue,
  ];

  const {
    data: assignmentsData,
    loading,
    fetchMore,
  } = useQuery(CurriculumAssignmentsDocument, {
    variables: {
      statuses,
      filter: searchValue,
      sortByName: true,
      first: ASSIGNMENTS_INDEX_PAGINATION_COUNT,
    },
    fetchPolicy: 'cache-and-network',
  });
  const paginatedResults = assignmentsData?.assignments.edges || [];
  const totalAssignments = assignmentsData?.assignments.totalCount || 0;
  const fetchNextBatch = () => {
    fetchMore({
      variables: {
        first: ASSIGNMENTS_INDEX_PAGINATION_COUNT,
        after: assignmentsData?.assignments.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        fetchMoreResult.assignments.edges = [
          ...(previousResult?.assignments.edges || []),
          ...(fetchMoreResult.assignments.edges || []),
        ];
        return fetchMoreResult;
      },
    });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleAssignmentClick = (indexToSelect: number) => () =>
    setSelectedIndex(indexToSelect);

  return (
    <Grid
      xs={4}
      item
      className={classes.itemsList}
      id="assignments-index-scroll"
    >
      <Box className={classes.searchContainer}>
        <TextField
          className={classes.searchField}
          variant="outlined"
          label="Search Assignment Name"
          value={searchText}
          onChange={handleTextChange}
          fullWidth
        />
      </Box>
      {loading && <LinearProgress />}
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={paginatedResults.length}
        next={fetchNextBatch}
        hasMore={paginatedResults.length < totalAssignments}
        scrollableTarget="assignments-index-scroll"
        loader={loading ? <Typography>Loading...</Typography> : null}
      >
        {loading ? (
          <LoadingSkeletons num={28} />
        ) : (
          paginatedResults.map((edge, i) => {
            const assignment = edge?.node;
            if (!assignment) return null;
            const assignmentName = assignment.name;
            return (
              <Link
                to={`#${assignment.id}`}
                key={`${assignment.id}-assignment-id-key`}
                onClick={handleAssignmentClick(i)}
              >
                <Card
                  className={clsx(classes.assignmentCard, {
                    [classes.selectedAssignment]: selectedIndex === i,
                  })}
                  id={assignment.id}
                >
                  <div className={classes.leftContainer}>
                    <Typography variant="h4" className={classes.title}>
                      {assignmentName}
                    </Typography>
                    <div className={classes.flexRow}>
                      <FormatListBulleted
                        fontSize="small"
                        className={classes.listIcon}
                      />
                      <Typography
                        color="primary"
                        variant="body2"
                        className={classes.typography}
                      >
                        {`${assignment.numCurriculumQuestions} questions`}
                      </Typography>
                    </div>
                    <div className={classes.infoContainer}>
                      <div className={classes.flexRow}>
                        <Typography
                          variant="body2"
                          className={clsx(classes.reviewTypography, {
                            [classes.needReview]:
                              assignment.numQuestionsNeedReview > 0,
                            [classes.noReview]:
                              assignment.numQuestionsNeedReview === 0,
                          })}
                        >
                          {`${assignment.numQuestionsNeedReview} need(s) review`}
                        </Typography>
                        <span className={classes.divider}>|</span>
                        <Typography
                          variant="body2"
                          className={clsx(classes.reviewTypography, {
                            [classes.needReview]:
                              assignment.numQuestionsNeedRevisit > 0,
                            [classes.noReview]:
                              assignment.numQuestionsNeedRevisit === 0,
                          })}
                        >
                          {`${assignment.numQuestionsNeedRevisit} need(s) revisit`}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </Card>
              </Link>
            );
          })
        )}
      </InfiniteScroll>
    </Grid>
  );
}
