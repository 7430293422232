import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ct from 'countries-and-timezones';
import { SchoolsQuery } from '../../../../gql/queries/__generated__/school.generated';

const useStyles = makeStyles((theme: Theme) => ({
  schoolname: {
    marginBottom: theme.spacing(0.5),
  },
}));

type SchoolDisplayProps = {
  school: SchoolsQuery['schools'][0];
};

export function SchoolDisplay({ school }: SchoolDisplayProps) {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h5" className={classes.schoolname}>
        {school.name}
      </Typography>
      <Typography>{`${school.address}, ${school.city}${
        school.country === 'US' ? `, ${school.stateCode} ${school.zip}` : ''
      }`}</Typography>
      <Typography>{ct.getCountry(school.country)?.name}</Typography>
      <Typography>
        <strong>{`Timezone: ${school.timezone}`}</strong>
      </Typography>
    </>
  );
}

export default SchoolDisplay;
