import { useMutation, useQuery } from '@apollo/client';
import {
  Autocomplete,
  Button,
  LinearProgress,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useState } from 'react';
import { CreateCourseDocument } from '../../../../gql/mutations/__generated__/course.generated';
import { JurisdictionsDocument } from '../../../../gql/queries/__generated__/jurisdiction.generated';
import { LevelsDocument } from '../../../../gql/queries/__generated__/level.generated';
import { SubjectsDocument } from '../../../../gql/queries/__generated__/subject.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { US, countries } from '../../../../utils/data/countries';
import { AlertsContext } from '../../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2),
  },
  input: {
    margin: theme.spacing(2),
  },
  inputLeft: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputRight: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  label: {
    fontSize: theme.typography.h6.fontSize,
  },
  caption: {
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.spacing(0.25),
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  buttonsContainer: {
    display: 'flex',
  },
  courseNameContainer: {
    marginBottom: theme.spacing(3),
  },
}));

export type JurisdictionInputType = {
  id?: string;
  title: string;
  countryCode: string;
  inputValue?: string;
};

export type LevelInputType = {
  name: string;
  inputValue?: string;
};

export type SubjectInputType = {
  name: string;
  inputValue?: string;
};

export const jurisdictionFilter = createFilterOptions<JurisdictionInputType>();
export const levelFilter = createFilterOptions<LevelInputType>();
export const subjectFilter = createFilterOptions<SubjectInputType>();

type CreateCourseFormProps = {
  handleModalClose?: () => void;
};

export function CreateCourseForm({ handleModalClose }: CreateCourseFormProps) {
  const { dispatch } = useContext(AlertsContext);
  const classes = useStyles();

  const [courseName, setCourseName] = useState<string>();
  const [levelName, setLevelName] = useState<LevelInputType | null>(null);
  const [jurisdiction, setJurisdiction] =
    useState<JurisdictionInputType | null>(null);
  const [subjectName, setSubjectName] = useState<SubjectInputType | null>(null);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  const [country, setCountry] = useState(US);
  const { data: jurisdictionData } = useQuery(JurisdictionsDocument, {
    variables: { country: country.key },
  });
  useQuery(TeacherDocument, {
    onCompleted: (res) => {
      if (res.teacher.school?.country) {
        const teacherCountry = countries.find(
          (country) => country.key === res.teacher.school?.country
        );
        if (teacherCountry) {
          setCountry(teacherCountry);
        }
      }
    },
  });

  const [createCourse, { loading }] = useMutation(CreateCourseDocument, {
    onError: onError(dispatch),
    onCompleted: () => {
      window.location.reload();
    },
  });

  const { data: levelData } = useQuery(LevelsDocument, {
    onError: onError(dispatch),
  });

  const { data: subjectData } = useQuery(SubjectsDocument, {
    onError: onError(dispatch),
  });

  const handleCreate = () => {
    setSubmitAttempted(true);

    if (!courseName || !levelName?.name || !subjectName?.name) {
      return;
    }

    // if jurisdiction id missing AND user didn't submit a new
    // jurisdiction title, then raise an error:
    if (!jurisdiction?.id && !jurisdiction?.title) {
      return;
    }

    createCourse({
      variables: {
        name: courseName || '',
        level: levelName?.name || '',
        subject: subjectName?.name || '',
        jurisdictionId: jurisdiction?.id,
        jurisdictionTitle: jurisdiction?.title,
        jurisdictionCountry: country.key,
      },
    });
  };

  return (
    <form className={classes.form}>
      <div className={classes.courseNameContainer}>
        <DialogContentText variant="h4">Course Name</DialogContentText>
        <br />
        <TextField
          className={classes.textField}
          value={courseName}
          error={submitAttempted && !courseName}
          onChange={(data) => {
            setCourseName(data.target.value);
          }}
          required={true}
          helperText="Name of the course that you're teaching"
          fullWidth
          variant="outlined"
          label="Enter Course Name"
        />
      </div>
      <DialogContentText variant="h4">Course Information</DialogContentText>
      <Typography variant="caption" className={classes.caption}>
        If you don&apos;t see your jurisdiction, level, or subject, then please
        add your own!
      </Typography>
      <Autocomplete
        value={country}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        fullWidth
        id="countries"
        onChange={(e, newValue) => {
          if (newValue) {
            setCountry(newValue);
          }
        }}
        className={classes.input}
        options={countries}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Country" variant="outlined" required />
        )}
      />
      <Autocomplete
        fullWidth
        value={jurisdiction}
        freeSolo
        id="jurisdictions"
        selectOnFocus
        openOnFocus
        clearOnBlur
        handleHomeEndKeys
        autoHighlight
        filterOptions={(options, params) => {
          const filtered = jurisdictionFilter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              countryCode: country.key,
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        onChange={(e, newValue) => {
          if (typeof newValue === 'string') {
            setJurisdiction({
              title: newValue,
              countryCode: country.key,
            });
          } else if (newValue && newValue.inputValue) {
            setJurisdiction({
              title: newValue.inputValue,
              countryCode: country.key,
            });
          } else {
            setJurisdiction(newValue);
          }
        }}
        className={classes.input}
        options={
          jurisdictionData?.jurisdictions.map(
            (j): JurisdictionInputType => ({
              id: j.id,
              title: j.title,
              countryCode: j.countryCode,
            })
          ) || []
        }
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.title;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={submitAttempted && !jurisdiction?.id && !jurisdiction?.title}
            label="Jurisdiction"
            helperText="What governing body determines the learning standards that you're teaching? (e.g. Common Core, California, Texas, etc)"
            variant="outlined"
          />
        )}
      />

      <Autocomplete
        fullWidth
        freeSolo
        id="level-autocomplete"
        value={levelName}
        selectOnFocus
        openOnFocus
        clearOnBlur
        autoHighlight
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = levelFilter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        onChange={(e, newValue) => {
          if (typeof newValue === 'string') {
            setLevelName({
              name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            setLevelName({
              name: newValue.inputValue,
            });
          } else {
            setLevelName(newValue);
          }
        }}
        className={classes.input}
        options={
          levelData?.levels
            .slice()
            .sort((a, b) =>
              a.name.localeCompare(b.name, 'en', { numeric: true })
            )
            .map((level): LevelInputType => ({ name: level.name })) || []
        }
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name;
        }}
        renderInput={(params) => (
          <TextField
            required={true}
            {...params}
            error={submitAttempted && !levelName?.name}
            label="Level"
            variant="outlined"
          />
        )}
      />
      <Autocomplete
        fullWidth
        freeSolo
        id="combo-box-demo"
        value={subjectName}
        selectOnFocus
        openOnFocus
        clearOnBlur
        autoHighlight
        handleHomeEndKeys
        filterOptions={(options, params) => {
          const filtered = subjectFilter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        onChange={(e, newValue) => {
          if (typeof newValue === 'string') {
            setSubjectName({
              name: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            setSubjectName({
              name: newValue.inputValue,
            });
          } else {
            setSubjectName(newValue);
          }
        }}
        className={classes.input}
        options={
          subjectData?.subjects
            .slice()
            .sort((a, b) =>
              a.name.localeCompare(b.name, 'en', { numeric: true })
            )
            .map((sub): SubjectInputType => ({ name: sub.name })) || []
        }
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return option.name;
        }}
        renderInput={(params) => (
          <TextField
            required={true}
            {...params}
            error={submitAttempted && !subjectName?.name}
            label="Subject"
            variant="outlined"
          />
        )}
      />

      <div
        className={classes.buttonsContainer}
        style={{
          justifyContent: handleModalClose ? 'flex-end' : 'center',
        }}
      >
        {handleModalClose && (
          <Button
            className={classes.inputLeft}
            variant="outlined"
            size="medium"
            type="button"
            color="primary"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        )}
        <Button
          className={classes.inputRight}
          variant="contained"
          size="medium"
          type="button"
          disabled={loading}
          onClick={handleCreate}
          color="primary"
        >
          Create
        </Button>
      </div>
      {loading && <LinearProgress />}
    </form>
  );
}

export default CreateCourseForm;
