import { useState } from 'react';
import { PageInfo, PaginationArgs } from '../pagination';

export function usePagination({ pageSize }: { pageSize: number }) {
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    totalCount: 0,
    endCursor: '',
    startCursor: '',
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [args, setArgs] = useState<PaginationArgs>({
    first: pageSize,
  });

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    if (page === 0) {
      // navigating to very first page
      setArgs({
        first: pageSize,
      });
    } else if (Math.floor(pageInfo.totalCount / pageSize) === page) {
      // navigating to very last page
      setArgs({
        last: pageInfo.totalCount % pageSize,
      });
    } else if (page > currentPage) {
      // navigating one page forward
      setArgs({
        first: pageSize,
        after: pageInfo.endCursor,
      });
    } else {
      // navigating one page back
      setArgs({
        last: pageSize,
        before: pageInfo.startCursor,
      });
    }
  };

  const onPageChange = (
    _: React.MouseEvent<HTMLButtonElement>,
    page: number
  ) => {
    handleChangePage(page);
  };
  return {
    setPageInfo,
    args,
    onPageChange,
    currentPage,
    pageInfo,
    handleChangePage,
  };
}
