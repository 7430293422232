import { useMutation, useQuery } from '@apollo/client';
import { Close, FileCopy } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Tooltip,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import userflow from 'userflow.js';
import { UpdateAssignmentDocument } from '../../../../../../gql/mutations/__generated__/assignment.generated';
import { AssignmentDocument } from '../../../../../../gql/queries/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../../../../gql/types';
import { onError } from '../../../../../../utils/apollo/apolloHelper';
import CopyToClipboard from '../../../../../shared/CopyToClipboard';
import { RocketIcon } from '../../../../../shared/CustomIcons/RocketIcon';
import { AlertsContext } from '../../../../Alerts/context';
import { pushSnack } from '../../../../Alerts/context/actions';
import { AssignmentEditorContext } from '../../context';
import { LaunchForm } from './LaunchForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 15px',
  },
  title: {
    color: theme.palette.primary.main,
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  progressBarContainer: {
    height: theme.spacing(1),
    width: 150,
  },
  launchButton: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
  icon: {
    fill: theme.palette.common.white,
  },
  actions: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(3),
  },
  content: {
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
  copyButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  copyButtonContainer: {
    marginLeft: theme.spacing(1),
  },
}));

type LaunchDialogProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export function LaunchDialog({ open, setOpen }: LaunchDialogProps) {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const {
    assignmentEditor: { groupsAssignments, enrollmentsAssignments },
  } = useContext(AssignmentEditorContext);
  const { data, loading } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });
  const [launchAssignment, { loading: mutationLoading }] = useMutation(
    UpdateAssignmentDocument,
    {
      onError: onError(dispatch),
      variables: {
        assignmentId,
        assignmentStatus: AssignmentStatusEnum.Active,
      },
      onCompleted: () => {
        userflow.track('userflow_assignment_launched', {
          assignment_id: assignmentId,
        });
        dispatch(
          pushSnack({
            message: 'Assignment status updated!',
          })
        );
      },
    }
  );
  const handleClose = () => setOpen(false);
  const handleLaunch = () =>
    launchAssignment({
      variables: {
        assignmentId,
        assignmentStatus: AssignmentStatusEnum.Active,
      },
    });
  if (!data) {
    return null;
  }

  if (loading) {
    return (
      <div className={classes.progressBarContainer}>{<LinearProgress />}</div>
    );
  }
  const {
    assignmentStatus,
    assignmentType,
    assignmentMethod,
    questions,
    numSpiraledQuestions,
  } = data.assignment;
  let tooltipText = '';
  const pending = assignmentStatus === AssignmentStatusEnum.Pending;
  let validGroupsAssignments = Object.keys(groupsAssignments).length > 0;
  if (validGroupsAssignments) {
    Object.keys(groupsAssignments).forEach((key) => {
      const dueDate = groupsAssignments[key].dueDate;
      if (!dueDate || isNaN(dueDate.getTime())) {
        validGroupsAssignments = false;
      }
    });
  }

  let validEnrollmentsAssignments =
    Object.keys(enrollmentsAssignments).length > 0;
  if (validEnrollmentsAssignments) {
    Object.values(enrollmentsAssignments).forEach((value) => {
      const dueDate = value.dueDate;
      if (!dueDate || isNaN(dueDate.getTime())) {
        validEnrollmentsAssignments = false;
      }
    });
  }
  const validQuestions = questions.length > 0 || numSpiraledQuestions > 0;

  const disabled =
    mutationLoading ||
    (!validGroupsAssignments && !validEnrollmentsAssignments) ||
    (!validQuestions && assignmentType === 'standard');

  if (disabled) {
    if (assignmentType === 'standard') {
      if (assignmentMethod === 'group') {
        tooltipText =
          "You must assign at least one class and have at least one question. Please do this in the 'SETUP' tab.";
      } else {
        tooltipText =
          "You must assign at least one student and have at least one question. Please do this in the 'SETUP' tab.";
      }
    } else {
      tooltipText =
        "You must assign to at least one class. Please do this in the 'SETUP' tab.";
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="md" className={classes.root}>
      <DialogTitle className={classes.header} variant="h2">
        {pending ? 'Edit Settings & Launch Assignment' : 'Edit Settings'}
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <LaunchForm />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
        {pending ? (
          <Tooltip title={tooltipText}>
            <span>
              <Button
                startIcon={<RocketIcon className={classes.icon} />}
                disabled={disabled}
                type="submit"
                className={classes.launchButton}
                variant="contained"
                color="secondary"
                onClick={handleLaunch}
              >
                Launch
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Copy student assignment link">
            <div className={classes.copyButtonContainer}>
              <CopyToClipboard
                copiedText={`https://student.podsie.org/assignments/${assignmentId}`}
              >
                <Button className={classes.copyButton} startIcon={<FileCopy />}>
                  COPY LINK
                </Button>
              </CopyToClipboard>
            </div>
          </Tooltip>
        )}
      </DialogActions>
    </Dialog>
  );
}
