import { Autocomplete, Checkbox, TextField, type Theme } from '@mui/material';
import { createFilterOptions } from '@mui/material/useAutocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { truncate } from '@podsie/utils/string.js';
import React from 'react';
import type { StandardsQuery } from '../../../../gql/queries/__generated__/standard.generated';

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '4px',
    '& .MuiChip-deleteIcon': {
      color: theme.palette.secondary.main,
    },
  },
}));

export type StandardsQueryStandard = StandardsQuery['standards'][0];

type MultipleStandardsAutocompleteProps = {
  onChange: (
    event: React.SyntheticEvent,
    values: StandardsQueryStandard[]
  ) => void;
  value: StandardsQueryStandard[];
  options: StandardsQueryStandard[];
  limitTags?: number;
  disabled?: boolean;
};

const filterOptions = createFilterOptions({
  stringify: (option: StandardsQueryStandard) =>
    `${option.title} ${option.description}`,
});

export function MultipleStandardsAutocomplete({
  onChange,
  value,
  options,
  limitTags,
  disabled,
}: MultipleStandardsAutocompleteProps) {
  const classes = useStyles();
  return (
    <Autocomplete
      classes={{
        tag: classes.tag,
      }}
      disabled={disabled}
      limitTags={limitTags}
      filterOptions={filterOptions}
      multiple
      autoHighlight
      disableCloseOnSelect
      id="spiraled-questions-standards"
      options={options}
      selectOnFocus
      openOnFocus
      groupBy={(option) => {
        return option.standardsCategory
          ? option.standardsCategory.category
          : '';
      }}
      getOptionLabel={(option: StandardsQueryStandard) =>
        truncate(option.title)
      }
      isOptionEqualToValue={(option, value) => {
        return value.id === option.id;
      }}
      value={value}
      onChange={onChange}
      renderOption={(props, option, { selected }) => {
        const desc = option.description && `: ${option.description}`;
        return (
          <li {...props}>
            <Checkbox checked={selected} />
            {`${option.title}${desc}`}
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField {...params} variant="outlined" placeholder="Standards" />
        );
      }}
    />
  );
}
