import React from 'react';
import type { QuestionAttributesFragment } from '../../../../../gql/fragments/__generated__/question.generated';
import { AssignmentMethodEnum, type Standard } from '../../../../../gql/types';

export enum AssignmentEditorActionTypes {
  UPDATE_ASSIGNMENT_NAME = 'UPDATE_ASSIGNMENT_NAME',
  UPDATE_ASSIGNMENT_LAUNCH_AT = 'UPDATE_ASSIGNMENT_LAUNCH_AT',
  START_LOADING = 'START_LOADING',
  STOP_LOADING = 'STOP_LOADING',
  CLEAR_SAVE_UI = 'CLEAR_SAVE_UI',
  CREATE_GROUPS_ASSIGNMENT = 'CREATE_GROUPS_ASSIGNMENT',
  DELETE_GROUPS_ASSIGNMENT = 'DELETE_GROUPS_ASSIGNMENT',
  UPDATE_GROUPS_ASSIGNMENT = 'UPDATE_GROUPS_ASSIGNMENT',
  UPDATE_SPIRALED_STANDARDS = 'UPDATE_SPIRALED_STANDARDS',
  UPDATE_NUM_SPIRALED_STANDARDS = 'UPDATE_NUM_SPIRALED_STANDARDS',
  UPDATE_TARGET_DISTRIBUTED_POINTS = 'UPDATE_TARGET_DISTRIBUTED_POINTS',
  UPDATE_ASSIGNMENT_METHOD = 'UPDATE_ASSIGNMENT_METHOD',
  CREATE_ENROLLMENTS_ASSIGNMENT = 'CREATE_ENROLLMENTS_ASSIGNMENT',
  DELETE_ENROLLMENTS_ASSIGNMENT = 'DELETE_ENROLLMENTS_ASSIGNMENT',
  UPDATE_ENROLLMENTS_ASSIGNMENT = 'UPDATE_ENROLLMENTS_ASSIGNMENT',
  UPDATE_ASSIGNMENT_SHAREABLE = 'UPDATE_ASSIGNMENT_SHAREABLE',
  UPDATE_ASSIGNMENT_OPTIONAL = 'UPDATE_ASSIGNMENT_OPTIONAL',
  ADD_ASSIGNMENT_QUESTION = 'ADD_ASSIGNMENT_QUESTION',
  REMOVE_ASSIGNMENT_QUESTION = 'REMOVE_ASSIGNMENT_QUESTION',
}

type UpdateAssignmentNamePayload = {
  name: string;
};
export const updateAssignmentName = (
  payload: UpdateAssignmentNamePayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_NAME,
});

type UpdateAssignmentShareablePayload = {
  shareable: boolean;
};

export const updateAssignmentShareable = (
  payload: UpdateAssignmentShareablePayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_SHAREABLE,
});

type UpdateAssignmentOptionalPayload = {
  optional: boolean;
};

export const updateAssignmentOptional = (
  payload: UpdateAssignmentOptionalPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_OPTIONAL,
});

type UpdateAssignmentLaunchAtPayload = {
  launchAt: Date | null;
};
export const updateAssignmentLaunchAt = (
  payload: UpdateAssignmentLaunchAtPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_LAUNCH_AT,
});

type CreateGroupsAssignmentPayload = {
  groupsAssignmentId: string;
  groupId: string;
  dueDate?: Date;
  launchDate?: Date;
};

export const createGroupsAssignmentAction = (
  payload: CreateGroupsAssignmentPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.CREATE_GROUPS_ASSIGNMENT,
});

type DeleteGroupsAssignmentPayload = {
  groupId: string;
};

export const deleteGroupsAssignmentAction = (
  payload: DeleteGroupsAssignmentPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.DELETE_GROUPS_ASSIGNMENT,
});

type UpdateGroupsAssignmentPayload = {
  groupId: string;
  dueDate?: Date;
  launchDate?: Date;
  id?: string;
};

type CreateEnrollmentsAssignmentPayload = {
  enrollmentsAssignmentId: string;
  enrollmentId: string;
  dueDate: Date;
  studentName?: string | null;
  studentEmail: string;
  class: string;
};

type UpdateEnrollmentsAssignmentPayload = {
  enrollmentId: string;
  dueDate: Date;
};

type DeleteEnrollmentsAssignmentPayload = {
  enrollmentId: string;
};

export const createEnrollmentsAssignmentAction = (
  payload: CreateEnrollmentsAssignmentPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.CREATE_ENROLLMENTS_ASSIGNMENT,
});

export const updateEnrollmentsAssignmentAction = (
  payload: UpdateEnrollmentsAssignmentPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_ENROLLMENTS_ASSIGNMENT,
});

export const deleteEnrollmentsAssignmentAction = (
  payload: DeleteEnrollmentsAssignmentPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.DELETE_ENROLLMENTS_ASSIGNMENT,
});

export const updateGroupsAssignmentAction = (
  payload: UpdateGroupsAssignmentPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_GROUPS_ASSIGNMENT,
});

type UpdateSpiraledStandardsPayload = {
  standards: Standard[];
};

export const updateSpiraledStandards = (
  payload: UpdateSpiraledStandardsPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_SPIRALED_STANDARDS,
});

type UpdateTargetDistributedPointsPayload = {
  targetDistributedPoints: number;
};

export const updateTargetDistributedPoints = (
  payload: UpdateTargetDistributedPointsPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_TARGET_DISTRIBUTED_POINTS,
});

type UpdateNumSpiraledQuestionsPayload = {
  numSpiraledQuestions: number;
};

type UpdateAssignmentMethodPayload = {
  method: AssignmentMethodEnum;
};

export const updateNumSpiraledQuestions = (
  payload: UpdateNumSpiraledQuestionsPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_NUM_SPIRALED_STANDARDS,
});

export const updateAssignmentMethod = (
  payload: UpdateAssignmentMethodPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_METHOD,
});

let timer: NodeJS.Timeout | undefined;

export const startAssignmentEditorLoading = (
  dispatch: React.Dispatch<AssignmentEditorAction>
) => {
  dispatch({ type: AssignmentEditorActionTypes.START_LOADING });
  // if a new update happens cancel the old timer that was going to wipe away
  // the save status:
  if (timer) {
    clearTimeout(timer);
  }
};

export const onAssignmentEditorSaveSuccess = (
  dispatch: React.Dispatch<AssignmentEditorAction>
) => {
  dispatch({ type: AssignmentEditorActionTypes.STOP_LOADING });
  timer = setTimeout(
    () => dispatch({ type: AssignmentEditorActionTypes.CLEAR_SAVE_UI }),
    5000
  );
};

type AddAssignmentQuestionPayload = {
  question: QuestionAttributesFragment;
};

export const addAssignmentQuestion = (
  payload: AddAssignmentQuestionPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.ADD_ASSIGNMENT_QUESTION,
});

type RemoveAssignmentQuestionPayload = {
  questionId: string;
};

export const removeAssignmentQuestion = (
  payload: RemoveAssignmentQuestionPayload
): AssignmentEditorAction => ({
  payload,
  type: AssignmentEditorActionTypes.REMOVE_ASSIGNMENT_QUESTION,
});

export type AssignmentEditorAction =
  | {
      type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_NAME;
      payload: UpdateAssignmentNamePayload;
    }
  | { type: AssignmentEditorActionTypes.START_LOADING }
  | { type: AssignmentEditorActionTypes.STOP_LOADING }
  | { type: AssignmentEditorActionTypes.CLEAR_SAVE_UI }
  | {
      type: AssignmentEditorActionTypes.CREATE_GROUPS_ASSIGNMENT;
      payload: CreateGroupsAssignmentPayload;
    }
  | {
      type: AssignmentEditorActionTypes.DELETE_GROUPS_ASSIGNMENT;
      payload: DeleteGroupsAssignmentPayload;
    }
  | {
      type: AssignmentEditorActionTypes.UPDATE_GROUPS_ASSIGNMENT;
      payload: UpdateGroupsAssignmentPayload;
    }
  | {
      type: AssignmentEditorActionTypes.UPDATE_SPIRALED_STANDARDS;
      payload: UpdateSpiraledStandardsPayload;
    }
  | {
      type: AssignmentEditorActionTypes.UPDATE_NUM_SPIRALED_STANDARDS;
      payload: UpdateNumSpiraledQuestionsPayload;
    }
  | {
      type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_METHOD;
      payload: UpdateAssignmentMethodPayload;
    }
  | {
      type: AssignmentEditorActionTypes.CREATE_ENROLLMENTS_ASSIGNMENT;
      payload: CreateEnrollmentsAssignmentPayload;
    }
  | {
      type: AssignmentEditorActionTypes.UPDATE_ENROLLMENTS_ASSIGNMENT;
      payload: UpdateEnrollmentsAssignmentPayload;
    }
  | {
      type: AssignmentEditorActionTypes.DELETE_ENROLLMENTS_ASSIGNMENT;
      payload: DeleteEnrollmentsAssignmentPayload;
    }
  | {
      type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_LAUNCH_AT;
      payload: UpdateAssignmentLaunchAtPayload;
    }
  | {
      type: AssignmentEditorActionTypes.UPDATE_TARGET_DISTRIBUTED_POINTS;
      payload: UpdateTargetDistributedPointsPayload;
    }
  | {
      type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_SHAREABLE;
      payload: UpdateAssignmentShareablePayload;
    }
  | {
      type: AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_OPTIONAL;
      payload: UpdateAssignmentOptionalPayload;
    }
  | {
      type: AssignmentEditorActionTypes.ADD_ASSIGNMENT_QUESTION;
      payload: AddAssignmentQuestionPayload;
    }
  | {
      type: AssignmentEditorActionTypes.REMOVE_ASSIGNMENT_QUESTION;
      payload: RemoveAssignmentQuestionPayload;
    };
