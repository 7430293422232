import HelpKitButton from './HelpKitButton';

export function HelpKitReviewQuestions() {
  return (
    <HelpKitButton
      label="How to review struggling questions with your students"
      articleId="pjbcjsn7qnzpHpVcj5Gjhf"
    />
  );
}
export default HelpKitReviewQuestions;
