import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format, parseISO } from 'date-fns';
import { Question } from '../../../../../gql/types';
import QuestionCard from './QuestionCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 'calc(100vh - 220px)',
  },
  assignmentItemHeaderTitle: {
    color: '#666666',
  },
  questionsContainer: {
    margin: theme.spacing(1, 0),
    height: 'calc(100vh - 220px - 70px)',
    overflow: 'auto',
  },
  emptyQuestionsContainer: {
    padding: theme.spacing(3, 0),
    width: '100%',
    textAlign: 'center',
  },
}));

export function SecondPage({ assignment }: { assignment: any }) {
  const classes = useStyles();
  if (!assignment || !assignment.node) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.assignmentItemHeaderTitle}>
        {`Last updated on ${format(
          parseISO(assignment.node.updatedAt),
          'MM/dd/yyyy HH:mm'
        )}`}
      </Typography>
      <Typography variant="h3">{assignment.node.name}</Typography>
      <div className={classes.questionsContainer}>
        {assignment.node?.questions.map((question: Question, index: number) => (
          <QuestionCard
            key={question.id}
            questionNumber={index + 1}
            question={question}
          />
        ))}
        {assignment.node.questions.length === 0 && (
          <Typography className={classes.emptyQuestionsContainer}>
            This assignment has no questions.
          </Typography>
        )}
      </div>
    </div>
  );
}

export default SecondPage;
