import { useQuery } from '@apollo/client';
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { LeaderboardDocument } from '../../../gql/queries/__generated__/leaderboard.generated';
import { renderLeaderboardSetting } from '../../../utils/enumMappings';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import HelpKitLeaderboard from '../HelpKitArticles/HelpKitLeaderboard';
import ResetButton from './ResetButton';
import XpDetailsModal from './XpDetailsModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgb(239,249,244, 0.3)',
    maxHeight: '80vh',
  },
  titleContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grays.main}`,
    color: theme.palette.primary.main,
  },
  table: {
    overflowX: 'scroll',
  },
  row: {
    '& td': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      borderBottom: `1px solid ${theme.palette.grays.main}`,
      maxWidth: '40vw',
    },
  },
  tableHead: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.primary.main,
    },
    '& th': {
      color: theme.palette.common.white,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
  },
  noDataContainer: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
  settingVisibility: {
    fontWeight: theme.typography.fontWeightBold,
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonLink: {
    ...buttonLink(theme),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type LeaderboardProps = {
  groupId: string;
};

export function Leaderboard({ groupId }: LeaderboardProps) {
  useTrackVisit({
    section: 'class',
    page: 'leaderboard',
  });
  const classes = useStyles();
  const [selectedEnrollmentIndex, setSelectedEnrollmentIndex] =
    useState<number>();
  const { data, loading } = useQuery(LeaderboardDocument, {
    variables: { groupId },
  });
  const renderLoading = () => {
    if (loading) {
      return (
        <div>
          <LinearProgress />
        </div>
      );
    }
    return null;
  };

  const handleOpenXpDetails = (index: number) => {
    return () => {
      setSelectedEnrollmentIndex(index);
    };
  };
  const leaderboardVisibility = data?.teacher.activeCourse?.leaderboardSetting;
  return (
    <div className={classes.root}>
      <TableContainer className={classes.tableContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.flex}>
            <Typography variant="h4">Leaderboard</Typography>
            <ResetButton groupId={groupId} />
          </div>
          <Typography variant="caption">
            Students earn experience (XP) by consistently completing Personal
            Deck Questions. <HelpKitLeaderboard />
          </Typography>
          <Typography variant="caption">
            <br /> Set whether and how students see class leaderboards in{' '}
            <Link to="/settings" className={classes.link}>
              settings
            </Link>
            .{' '}
            {leaderboardVisibility && (
              <span className={classes.settingVisibility}>
                Current visibility:{' '}
                {renderLeaderboardSetting(leaderboardVisibility)}
              </span>
            )}
          </Typography>
        </div>
        {data?.leaderboard.length ? (
          <Table stickyHeader className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>XP</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.leaderboard.map((enrollment, i) => {
                return (
                  <TableRow
                    key={`leaderboard-key-${enrollment.id}`}
                    className={classes.row}
                  >
                    <TableCell>
                      <Link
                        className={classes.link}
                        to={`/classes/${groupId}/enrollments/${enrollment.id}`}
                      >
                        {enrollment.student.fullName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <button
                        className={classes.buttonLink}
                        onClick={handleOpenXpDetails(i)}
                      >
                        {enrollment.points ?? 0}
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          renderLoading()
        )}
      </TableContainer>
      <XpDetailsModal
        selectedEnrollmentIndex={selectedEnrollmentIndex}
        setSelectedEnrollmentIndex={setSelectedEnrollmentIndex}
        leaderboardEnrollments={data?.leaderboard ?? []}
      />
    </div>
  );
}

export default Leaderboard;
