import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Question } from '../utils';
import FillBlankBreakdown from './FillBlankBreakdown';
import MultipleChoiceBreakdown from './MultipleChoiceBreakdown';
import ShortAnswerBreakdown from './ShortAnswerBreakdown';
import TrueFalseBreakdown from './TrueFalseBreakdown';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(0, 0, 1, 0),
  },
}));

type AnswersBreakdownProps = {
  question: Question;
  style?: React.CSSProperties;
};

export function AnswersBreakdown({ question, style }: AnswersBreakdownProps) {
  const classes = useStyles();

  let content = null;

  switch (question.question_type) {
    case 'short_ans':
      content = <ShortAnswerBreakdown question={question} />;
      break;

    case 'mult_choice':
      content = <MultipleChoiceBreakdown question={question} />;
      break;
    case 'fill_blank':
      content = <FillBlankBreakdown question={question} />;
      break;
    case 'tf':
      content = <TrueFalseBreakdown question={question} />;
      break;
    default:
      content = null;
  }

  return (
    <div className={classes.container} style={style}>
      {content}
    </div>
  );
}
