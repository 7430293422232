import { useMutation } from '@apollo/client';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, type SyntheticEvent } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { UpdateAssignmentDocument } from '../../../../../gql/mutations/__generated__/assignment.generated';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../../Alerts/context';
import HelpKitLatePDA from '../../../HelpKitArticles/HelpKitLatePDA';
import HelpKitOptionalAssignment from '../../../HelpKitArticles/HelpKitOptionalAssignment';
import { AssignmentEditorContext } from '../context';
import {
  onAssignmentEditorSaveSuccess,
  startAssignmentEditorLoading,
  updateAssignmentOptional,
} from '../context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    marginTop: theme.spacing(1),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    display: 'flex',
  },
}));

type AssignmentSettingsProps = {
  handleShareableChange?: (
    event: SyntheticEvent<Element, Event>,
    checked: boolean
  ) => void;
};

export function AssignmentSettings({
  handleShareableChange,
}: AssignmentSettingsProps) {
  const classes = useStyles();
  const {
    assignmentEditor: { optional, id, shareable },
    dispatch,
  } = useContext(AssignmentEditorContext);
  const { dispatch: alertsDispatch } = useContext(AlertsContext);

  const personalDeckAssignment = !handleShareableChange;

  const [updateAssignment] = useMutation(UpdateAssignmentDocument, {
    onCompleted: () => onAssignmentEditorSaveSuccess(dispatch),
    onError: onError(alertsDispatch),
  });

  const debouncedOptionalUpdate = useDebouncedCallback(
    (updatedOptional: boolean) => {
      updateAssignment({
        variables: {
          assignmentId: id,
          optional: updatedOptional,
        },
      });
      startAssignmentEditorLoading(dispatch);
    },
    750
  );

  const handleOptionalChange = (event: unknown, checked: boolean) => {
    dispatch(updateAssignmentOptional({ optional: checked }));
    debouncedOptionalUpdate(checked);
  };

  const latePDALabel = () => (
    <Typography className={classes.typography}>
      <span>
        Assignment is not available after due date. (<HelpKitLatePDA />)
      </span>
    </Typography>
  );

  const optionalAssignmentLabel = () => (
    <Typography className={classes.typography}>
      <span>
        Assignment is optional. <HelpKitOptionalAssignment />
      </span>
    </Typography>
  );

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Typography variant="h4">Settings</Typography>
      </div>
      <FormControl className={classes.form}>
        <FormGroup>
          <FormControlLabel
            label={
              personalDeckAssignment
                ? latePDALabel()
                : optionalAssignmentLabel()
            }
            control={<Checkbox color="secondary" checked={optional} />}
            onChange={handleOptionalChange}
          />
        </FormGroup>
        {!personalDeckAssignment && (
          <FormControl className={classes.form}>
            <FormGroup>
              <FormControlLabel
                label="Allow Subject Group collaborators to see this assignment (not recommended if you cloned it from another teacher)"
                control={<Checkbox color="secondary" checked={shareable} />}
                onChange={handleShareableChange}
              />
            </FormGroup>
          </FormControl>
        )}
      </FormControl>
    </div>
  );
}
