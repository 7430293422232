import { Close } from '@mui/icons-material';
import {
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ScheduledFreezesQuery } from '../../gql/queries/__generated__/scheduledFreeze.generated';
import FreezeForm from './FreezeForm';

const useStyles = makeStyles(() => ({
  root: {},
  appBar: {
    position: 'sticky',
    top: 0,
    paddingRight: 0,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

type FreezeSchedulerProps = {
  open: boolean;
  handleClose: () => void;
  editFreeze?: ScheduledFreezesQuery['scheduledFreezes'][0];
};

export function FreezeScheduler({
  open,
  handleClose,
  editFreeze,
}: FreezeSchedulerProps) {
  const classes = useStyles();

  const creating = !editFreeze;
  const title = creating
    ? 'Schedule Personal Deck Freeze'
    : 'Edit Personal Deck Freeze';
  return (
    <Dialog open={open} onClose={handleClose}>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6">{title}</Typography>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            size="large"
          >
            <Close onClick={handleClose} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <FreezeForm editFreeze={editFreeze} />
      </DialogContent>
    </Dialog>
  );
}
export default FreezeScheduler;
