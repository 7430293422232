import { Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { useContext } from 'react';
import { GroupsAssignmentsTableSearchContext } from './GroupsAssignmentsTableSearchContext';

export function CustomGroupsAssignmentsTableSearch() {
  const { value, setValue } = useContext(GroupsAssignmentsTableSearchContext);

  return (
    <div>
      <TextField
        id="filled-search"
        placeholder="Search"
        value={value}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          if (e.target.value != null) {
            setValue(e.target.value);
          }
        }}
      ></TextField>
    </div>
  );
}
