import { useMutation } from '@apollo/client';
import { FormControlLabel, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AlertsContext } from '../../components/application/Alerts/context';
import { pushSnack } from '../../components/application/Alerts/context/actions';
import type { GroupSettingsFragment } from '../../gql/fragments/__generated__/group.generated';
import { UpdateGroupDocument } from '../../gql/mutations/__generated__/group.generated';
import { onError } from '../../utils/apollo/apolloHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  flex: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1.5),
  },
  loaderContainer: {
    width: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  numberField: {
    width: 40,
    marginRight: theme.spacing(0.5),
  },
  checkIcon: {
    fontSize: theme.spacing(1.5),
    fill: theme.palette.secondary.main,
  },
}));

type StudySessionMaxSettingProps = {
  group: GroupSettingsFragment;
};

export function StudySessionMaxSetting({ group }: StudySessionMaxSettingProps) {
  const classes = useStyles();
  const [value, setValue] = useState<number>();
  const { dispatch } = useContext(AlertsContext);
  const [updateGroup] = useMutation(UpdateGroupDocument, {
    onCompleted: (data) => {
      const groupName = data.updateGroup.name;
      const max = data.updateGroup.studySessionMax;
      dispatch(
        pushSnack({
          message: (
            <span>
              {`Max questions set to ${max} for `}
              <strong>{groupName}</strong>
            </span>
          ),
        })
      );
    },
    onError: onError(dispatch),
  });
  useEffect(() => {
    setValue(group.studySessionMax);
  }, [group.studySessionMax]);

  const debouncedUpdateGroup = useDebouncedCallback((updatedNumber: number) => {
    updateGroup({
      variables: {
        groupId: group.id,
        studySessionMax: updatedNumber,
      },
    });
  }, 750);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updated = parseInt(e.target.value);
    setValue(updated);
    if (!updated) {
      return;
    }
    debouncedUpdateGroup(updated);
  };
  return (
    <div className={classes.flex}>
      <FormControlLabel
        control={
          <input
            name={group.id}
            value={value}
            min={1}
            className={classes.numberField}
            onChange={handleChange}
            type="number"
          />
        }
        label={group.name}
      />
    </div>
  );
}

export default StudySessionMaxSetting;
