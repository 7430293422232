import { MTableToolbar } from '@material-table/core';
import { FormControlLabel, Switch, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  checkbox: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 10,
      zIndex: 2,
    },
  },
}));

type StandardsSearchAndFilterProps = {
  checked: boolean;
  toggleChecked: () => void;
};

export function StandardsTableToolbar({
  checked,
  toggleChecked,
  ...props
}: StandardsSearchAndFilterProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Switch
            checked={checked}
            onChange={toggleChecked}
            name="showNonEmptyStandards"
          />
        }
        label="Only show standards with data"
      />
      <div>
        <MTableToolbar {...props} />
      </div>
    </div>
  );
}
