import HelpKitButton from './HelpKitButton';

export function HelpKitArchiveAssignment() {
  return (
    <HelpKitButton
      label="Archiving assignment vs deleting questions"
      articleId="3VKWBToVmbiN1EpdB3p1ev"
    />
  );
}
export default HelpKitArchiveAssignment;
