import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type DeleteAssignmentMutationVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type DeleteAssignmentMutation = {
  __typename?: 'Mutation';
  deleteAssignment: boolean;
};

export type CreateAssignmentMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  assignmentType: Types.AssignmentTypeEnum;
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreateAssignmentMutation = {
  __typename?: 'Mutation';
  createAssignment: {
    __typename?: 'Assignment';
    id: string;
    createdAt: any;
    name: string;
    description?: string | null;
    numSpiraledQuestions: number;
    targetDistributedPoints?: number | null;
    assignmentType: Types.AssignmentTypeEnum;
    assignmentMethod: Types.AssignmentMethodEnum;
    assignmentStatus: Types.AssignmentStatusEnum;
    unassignable: boolean;
    shareable: boolean;
    optional: boolean;
    launchAt?: any | null;
    standards: Array<{
      __typename?: 'Standard';
      title: string;
      description: string;
      id: string;
    }>;
    enrollmentsAssignments?: Array<{
      __typename?: 'EnrollmentsAssignment';
      id: string;
      dueDate: any;
      enrollment: {
        __typename?: 'Enrollment';
        id: string;
        enrollmentStatus: Types.EnrollmentStatusEnum;
        student: {
          __typename?: 'Student';
          id: string;
          fullName?: string | null;
          sortName?: string | null;
          email: string;
        };
        group?: { __typename?: 'Group'; name: string } | null;
      };
      studentsAssignmentsDatum?: {
        __typename?: 'StudentsAssignmentsDatum';
        id: string;
        groupId: string;
        questionsCompleted: number;
        completedAt?: any | null;
        questionsCorrect: number;
        questionsTotal: number;
      } | null;
    }> | null;
    groupsAssignments?: Array<{
      __typename?: 'GroupsAssignment';
      id: string;
      dueDate?: any | null;
      launchDate?: any | null;
      studentsAssignmentsData?: Array<{
        __typename?: 'StudentsAssignmentsDatum';
        id: string;
        groupId: string;
        questionsCompleted: number;
        distributedPointsEarned?: number | null;
        completedAt?: any | null;
        questionsCorrect: number;
        questionsTotal: number;
        student: {
          __typename?: 'Student';
          id: string;
          fullName?: string | null;
          sortName?: string | null;
        };
        enrollment: {
          __typename?: 'Enrollment';
          enrollmentStatus: Types.EnrollmentStatusEnum;
        };
      }> | null;
      group: { __typename?: 'Group'; name: string; id: string };
    }> | null;
    questions: Array<{
      __typename?: 'Question';
      richText: any;
      numAttemptsUntilAnswerShown: number;
      id: string;
      state: Types.QuestionStateEnum;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      copiedFromId?: string | null;
      variations?: Array<{ __typename?: 'Question'; id: string }> | null;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        plainText: string;
        richText: any;
        comparable: boolean;
        fraRequiredKeywords: Array<{
          __typename?: 'FraRequiredKeyword';
          id: string;
          keyword: string;
        }>;
      } | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        id: string;
        isCaseSensitive: boolean;
        ignoreWhitespaces: boolean;
        text: string;
        plainText: string;
        richText: any;
        hidden: boolean;
      }> | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        text: string;
        plainText: string;
        richText: any;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        id: string;
        title: string;
        description: string;
      }> | null;
    }>;
  };
};

export type CloneAssignmentMutationVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type CloneAssignmentMutation = {
  __typename?: 'Mutation';
  cloneAssignment: { __typename?: 'Assignment'; id: string; name: string };
};

export type CollaborationCloneAssignmentMutationVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type CollaborationCloneAssignmentMutation = {
  __typename?: 'Mutation';
  collaborationCloneAssignment: {
    __typename?: 'Assignment';
    id: string;
    name: string;
  };
};

export type UpdateAssignmentMutationVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  numSpiraledQuestions?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  targetDistributedPoints?: Types.InputMaybe<Types.Scalars['Float']['input']>;
  assignmentType?: Types.InputMaybe<Types.AssignmentTypeEnum>;
  assignmentMethod?: Types.InputMaybe<Types.AssignmentMethodEnum>;
  description?: Types.InputMaybe<Types.Scalars['String']['input']>;
  standardIds?: Types.InputMaybe<
    Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
  >;
  assignmentStatus?: Types.InputMaybe<Types.AssignmentStatusEnum>;
  launchAt?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
  shareable?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  optional?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UpdateAssignmentMutation = {
  __typename?: 'Mutation';
  updateAssignment: {
    __typename?: 'Assignment';
    id: string;
    createdAt: any;
    name: string;
    description?: string | null;
    numSpiraledQuestions: number;
    targetDistributedPoints?: number | null;
    assignmentType: Types.AssignmentTypeEnum;
    assignmentMethod: Types.AssignmentMethodEnum;
    assignmentStatus: Types.AssignmentStatusEnum;
    unassignable: boolean;
    shareable: boolean;
    optional: boolean;
    launchAt?: any | null;
    standards: Array<{
      __typename?: 'Standard';
      title: string;
      description: string;
      id: string;
    }>;
    enrollmentsAssignments?: Array<{
      __typename?: 'EnrollmentsAssignment';
      id: string;
      dueDate: any;
      enrollment: {
        __typename?: 'Enrollment';
        id: string;
        enrollmentStatus: Types.EnrollmentStatusEnum;
        student: {
          __typename?: 'Student';
          id: string;
          fullName?: string | null;
          sortName?: string | null;
          email: string;
        };
        group?: { __typename?: 'Group'; name: string } | null;
      };
      studentsAssignmentsDatum?: {
        __typename?: 'StudentsAssignmentsDatum';
        id: string;
        groupId: string;
        questionsCompleted: number;
        completedAt?: any | null;
        questionsCorrect: number;
        questionsTotal: number;
      } | null;
    }> | null;
    groupsAssignments?: Array<{
      __typename?: 'GroupsAssignment';
      id: string;
      dueDate?: any | null;
      launchDate?: any | null;
      studentsAssignmentsData?: Array<{
        __typename?: 'StudentsAssignmentsDatum';
        id: string;
        groupId: string;
        questionsCompleted: number;
        distributedPointsEarned?: number | null;
        completedAt?: any | null;
        questionsCorrect: number;
        questionsTotal: number;
        student: {
          __typename?: 'Student';
          id: string;
          fullName?: string | null;
          sortName?: string | null;
        };
        enrollment: {
          __typename?: 'Enrollment';
          enrollmentStatus: Types.EnrollmentStatusEnum;
        };
      }> | null;
      group: { __typename?: 'Group'; name: string; id: string };
    }> | null;
    questions: Array<{
      __typename?: 'Question';
      richText: any;
      numAttemptsUntilAnswerShown: number;
      id: string;
      state: Types.QuestionStateEnum;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      copiedFromId?: string | null;
      variations?: Array<{ __typename?: 'Question'; id: string }> | null;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        plainText: string;
        richText: any;
        comparable: boolean;
        fraRequiredKeywords: Array<{
          __typename?: 'FraRequiredKeyword';
          id: string;
          keyword: string;
        }>;
      } | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        id: string;
        isCaseSensitive: boolean;
        ignoreWhitespaces: boolean;
        text: string;
        plainText: string;
        richText: any;
        hidden: boolean;
      }> | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        text: string;
        plainText: string;
        richText: any;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        id: string;
        title: string;
        description: string;
      }> | null;
    }>;
  };
};

export const DeleteAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteAssignmentMutation,
  DeleteAssignmentMutationVariables
>;
export const CreateAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentType' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'AssignmentTypeEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'description' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'assignmentShowAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'assignmentShowAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Assignment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numSpiraledQuestions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetDistributedPoints' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unassignable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentsAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentsAssignmentsDatum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCorrect' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupsAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentsAssignmentsData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'distributedPointsEarned',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCorrect' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enrollmentStatus' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'questionAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables
>;
export const CloneAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'cloneAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cloneAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CloneAssignmentMutation,
  CloneAssignmentMutationVariables
>;
export const CollaborationCloneAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'collaborationCloneAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborationCloneAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CollaborationCloneAssignmentMutation,
  CollaborationCloneAssignmentMutationVariables
>;
export const UpdateAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'numSpiraledQuestions' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'targetDistributedPoints' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'AssignmentTypeEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentMethod' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'AssignmentMethodEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'description' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentStatus' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'AssignmentStatusEnum' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'launchAt' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'shareable' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'optional' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'numSpiraledQuestions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'numSpiraledQuestions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetDistributedPoints' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'targetDistributedPoints' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentType' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentType' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentMethod' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentMethod' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'description' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentStatus' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentStatus' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'launchAt' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'launchAt' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shareable' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'shareable' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'optional' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'optional' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'assignmentShowAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'assignmentShowAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Assignment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numSpiraledQuestions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetDistributedPoints' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unassignable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentsAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentsAssignmentsDatum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCorrect' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupsAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentsAssignmentsData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'distributedPointsEarned',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCorrect' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enrollmentStatus' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'questionAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
>;
