import {
  Card,
  LinearProgress,
  Typography,
  useTheme,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import {
  questionTypeIcons,
  renderQuestionType,
} from '../../../../utils/renderQuestionType';
import { AnswersBreakdown } from '../../../shared/Answers/AnswersBreakdown';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import AddToReviewSessionButton from '../../StudentProgress/ReviewSession/AddToReviewSessionButton';
import { BreakdownDetails } from './BreakdownDetails';
import CompletionStats from './CompletionStats';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 900,
    maxWidth: '90%',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    color: theme.palette.mint.dark,
    marginRight: theme.spacing(1),
  },
  questionTypeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  questionType: {
    marginBottom: theme.spacing(1),
  },
  question: {
    display: 'flex',
    alignItems: 'baseline',
  },
  questionNum: {
    fontWeight: theme.typography.fontWeightBold,
  },
  questionText: {
    padding: theme.spacing(0, 1),
  },
  reviewButton: {
    marginBottom: theme.spacing(2),
  },
}));

type QuestionBreakdownCardProps = {
  question: QuestionAttributesFragment;
  breakdownLoading: boolean;
  questionNumber: number;
  breakdownOpen?: boolean;
  reviewable?: boolean;
};

export function QuestionBreakdownCard({
  question,
  reviewable,
  questionNumber,
  breakdownOpen,
  breakdownLoading,
}: QuestionBreakdownCardProps) {
  const theme = useTheme();
  const classes = useStyles();
  const { richText, questionType, id } = question;
  return (
    <Card className={classes.root}>
      {reviewable && (
        <div className={classes.reviewButton}>
          <AddToReviewSessionButton questionId={id} />
        </div>
      )}
      <div className={classes.topContainer}>
        <div className={classes.questionTypeContainer}>
          <span className={classes.icon}>
            {questionTypeIcons[questionType]}
          </span>
          <span className={classes.questionType}>
            <Typography variant="body1">
              {renderQuestionType(questionType)}
            </Typography>
          </span>
        </div>
        <div>
          {breakdownLoading ? (
            <LinearProgress />
          ) : (
            <CompletionStats question={question} />
          )}
        </div>
      </div>
      <div className={classes.question}>
        <span className={classes.questionNum}>{questionNumber}.</span>
        <span className={classes.questionText}>
          <QuillDeltaAsHtml
            delta={richText.ops}
            style={{ padding: 0, marginBottom: theme.spacing(1) }}
          />
          <AnswersBreakdown question={question} />
        </span>
      </div>

      <BreakdownDetails question={question} defaultOpen={!!breakdownOpen} />
    </Card>
  );
}
