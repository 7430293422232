import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import type { StandardsQuery } from '../../../../../gql/queries/__generated__/standard.generated';
import { OwnershipEnum, type AssignmentEdge } from '../../../../../gql/types';
import { LoadingSkeletons } from '../../../../shared/Loaders/LoadingSkeletons';
import {
  MultipleStandardsAutocomplete,
  type StandardsQueryStandard,
} from '../../../Questions/Form/MultipleStandardsAutocomplete';
import AssignmentItem from './AssignmentItem';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'calc(100vh - 175px)',
  },
  filterContainer: {},
  filterTitle: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  firstFilterRow: { display: 'flex' },
  authorFilter: {
    width: '50%',
    paddingRight: theme.spacing(0.5),
  },
  courseFilter: {
    width: '50%',
    paddingLeft: theme.spacing(0.5),
  },
  filter: {
    margin: theme.spacing(0.5, 0),
  },
  outerResultsContainer: {},
  resultsContainer: {
    margin: theme.spacing(1, 0),
    height: 'calc(100vh - 175px - 260px)',
    overflowY: 'auto',
  },
  resultsContainerExpanded: {
    height: 'calc(100vh - 175px - 320px)',
  },
  bolded: {
    color: theme.palette.primary.main,
  },
}));

export function FirstPage({
  state,
  stateSetters,
  filteredAssignments,
  loading,
  error,
  standardsData,
  standardsChartsData,
  fetchNextBatch,
  totalAssignments,
  hasNextPage,
  refetching,
}: {
  state: {
    page: number;
    selectedAssignment: string;
    currentAuthor: string;
    currentStandardsChartId: string;
    currentStandards: StandardsQueryStandard[];
    currentNameSearchString: string;
    scrollPosition: number;
  };
  stateSetters: {
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setSelectedAssignment: React.Dispatch<React.SetStateAction<string>>;
    setCurrentAuthor: React.Dispatch<React.SetStateAction<OwnershipEnum>>;
    setCurrentStandardsChartId: React.Dispatch<React.SetStateAction<string>>;
    setCurrentStandards: React.Dispatch<
      React.SetStateAction<StandardsQueryStandard[]>
    >;
    setCurrentNameSearchString: React.Dispatch<React.SetStateAction<string>>;
    setScrollPosition: React.Dispatch<React.SetStateAction<number>>;
  };
  filteredAssignments: any;
  loading: boolean;
  error: any;
  standardsData?: StandardsQuery;
  standardsChartsData: any;
  fetchNextBatch: () => void;
  totalAssignments: number;
  hasNextPage: boolean;
  refetching: boolean;
}) {
  const classes = useStyles();
  const {
    selectedAssignment,
    currentAuthor,
    currentStandardsChartId,
    currentStandards,
    currentNameSearchString,
    scrollPosition,
  } = state;
  const {
    setCurrentAuthor,
    setCurrentStandardsChartId,
    setCurrentStandards,
    setCurrentNameSearchString,
    setSelectedAssignment,
    setPage,
    setScrollPosition,
  } = stateSetters;

  const infiniteScroller = useRef<InfiniteScroll>(null);
  const onClickCopy = (id: string) => {
    if (selectedAssignment === id) {
      setSelectedAssignment('');
    } else {
      setSelectedAssignment(id);
    }
  };
  const onClickMore = (id: string) => {
    setSelectedAssignment(id);
    const scroller = infiniteScroller.current?.getScrollableTarget();
    if (scroller) {
      setScrollPosition(scroller.scrollTop);
    }
    setPage(2);
  };
  const handleStandardUpdate = (
    event: any,
    newValue: StandardsQueryStandard[] | null
  ) => {
    const update = newValue || [];
    setCurrentStandards(update);
  };

  return (
    <div className={classes.root}>
      <div className={classes.filterContainer}>
        <Typography className={classes.filterTitle}>
          Use filters or search to locate existing assignments. Switch the
          &lsquo;Author&rsquo; filter to &lsquo;Podsie&rsquo; to see official
          Podsie content.{' '}
          <strong className={classes.bolded}>
            Please note that currently, we only have content from Illustrative
            Math Algebra I (aligned to Common Core standards).
          </strong>
        </Typography>

        <div className={classes.firstFilterRow}>
          <FormControl className={`${classes.authorFilter} ${classes.filter}`}>
            <InputLabel>Filter by Author</InputLabel>
            <Select
              label="Filter by Author"
              value={currentAuthor}
              onChange={(e) => {
                setCurrentAuthor(e.target.value as OwnershipEnum);
                setSelectedAssignment('');
              }}
            >
              <MenuItem value={OwnershipEnum.Own}>Myself</MenuItem>
              <MenuItem value={OwnershipEnum.Others}>Others</MenuItem>
              <MenuItem value={OwnershipEnum.Podsie}>Podsie</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={`${classes.courseFilter} ${classes.filter}`}>
            <InputLabel>Filter by Course</InputLabel>
            <Select
              label="Filter by Course"
              value={currentStandardsChartId}
              onChange={(e) => {
                setCurrentStandardsChartId(e.target.value as string);
                setSelectedAssignment('');
              }}
            >
              <MenuItem key="null" value="all">
                All Courses
              </MenuItem>
              {standardsChartsData &&
                standardsChartsData.collaborativeStandardsCharts &&
                [...standardsChartsData.collaborativeStandardsCharts]
                  .sort((a, b) => {
                    if (a.title && b.title)
                      return a.title.localeCompare(b.title);
                    return 0;
                  })
                  .map((stdChart) => {
                    if (stdChart.id && stdChart.title) {
                      return (
                        <MenuItem key={stdChart.id} value={stdChart.id}>
                          {stdChart.title}
                        </MenuItem>
                      );
                    }
                    return null;
                  })}
            </Select>
          </FormControl>
        </div>
        {currentStandardsChartId !== 'all' && (
          <FormControl
            fullWidth
            className={classes.filter}
            disabled={currentStandardsChartId === 'all'}
          >
            <MultipleStandardsAutocomplete
              limitTags={3}
              value={currentStandards}
              onChange={(event, values) => handleStandardUpdate(event, values)}
              options={standardsData?.standards || []}
            />
          </FormControl>
        )}

        <TextField
          fullWidth
          label="Search By Name"
          className={classes.filter}
          value={currentNameSearchString}
          onChange={(e) => {
            setSelectedAssignment('');
            setCurrentNameSearchString(e.target.value as string);
          }}
        />
      </div>
      <Divider className={classes.divider} />
      <div className={classes.outerResultsContainer}>
        {filteredAssignments && (
          <Typography variant="h3">{`${totalAssignments} Result${
            totalAssignments === 1 ? '' : '(s)'
          } Found`}</Typography>
        )}
        <InfiniteScroll
          scrollThreshold={0.6}
          dataLength={filteredAssignments?.length || 0}
          ref={infiniteScroller}
          next={fetchNextBatch}
          hasMore={hasNextPage}
          loader={refetching ? <LoadingSkeletons num={1} /> : null}
          scrollableTarget="scrollable-filtered-assignment-items"
          initialScrollY={scrollPosition}
        >
          <div
            className={clsx(classes.resultsContainer, {
              [classes.resultsContainerExpanded]:
                currentStandardsChartId !== 'all',
            })}
            id="scrollable-filtered-assignment-items"
          >
            {loading && <LoadingSkeletons num={3} />}
            {error && <Typography>{error.message}</Typography>}
            {filteredAssignments &&
              filteredAssignments.map((edge: AssignmentEdge) => {
                const { node } = edge;
                if (node?.id) {
                  return (
                    <AssignmentItem
                      key={node.id}
                      assignment={node}
                      onClickCopy={() => onClickCopy(node.id)}
                      onClickMore={() => onClickMore(node.id)}
                      checked={selectedAssignment === node.id}
                    />
                  );
                }
                return null;
              })}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default FirstPage;
