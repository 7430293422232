import { DeleteForever } from '@mui/icons-material';
import { IconButton, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { CustomTooltip } from '../../../../../shared/CustomTooltip';
import { DeleteQuestionConfirmation } from '../../../../Questions/Show/DeleteQuestionConfirmation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  iconButton: {
    height: 30,
    padding: 0,
    marginLeft: theme.spacing(0.8),
    color: theme.palette.primary.main,
  },
}));

type DeleteForeverButtonProps = {
  questionId: string;
};

export function DeleteForeverAssignmentButton({
  questionId,
}: DeleteForeverButtonProps) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const openDelete = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <div className={classes.root}>
      <CustomTooltip
        open={open}
        title={
          <DeleteQuestionConfirmation
            parentQuestion
            questionId={questionId}
            afterDelete={onClose}
            closeDelete={onClose}
          />
        }
      >
        <IconButton
          aria-label="Delete Question"
          className={classes.iconButton}
          onClick={openDelete}
          size="large"
        >
          <DeleteForever />
        </IconButton>
      </CustomTooltip>
    </div>
  );
}
