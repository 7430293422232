import HelpKitButton from './HelpKitButton';

export function HelpKitDesirableDifficulty() {
  return (
    <HelpKitButton
      label="What is 'Desirable Difficulty'?"
      articleId="wBMP44uss6KoUTPiYYGsfT"
    />
  );
}
export default HelpKitDesirableDifficulty;
