import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useContext } from 'react';
import { AlertsContext } from './context';
import { closeSnack, updateSnackQueue } from './context/actions';

function AlertsSnackbar() {
  const {
    alerts: {
      snacks: { open, snack },
    },
    dispatch,
  } = useContext(AlertsContext);

  const {
    message = '',
    severity = 'success',
    vertical = 'bottom',
    horizontal = 'center',
    key,
  } = snack || {};

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(closeSnack());
  };

  const handleExited = () => dispatch(updateSnackQueue());

  return (
    <Snackbar
      key={key}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default AlertsSnackbar;
