import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { Typography, useTheme, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Question } from '../utils';

type StyleProps = {
  reviewable: boolean | undefined;
  anonymized: boolean;
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  answerRow: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: ({ reviewable }: StyleProps) =>
      reviewable ? 'calc(12px + 1.1vw)' : 'inherit',
    lineHeight: 'normal',
  },
  mc: {
    fontSize: 'inherit',
  },
}));

function Icon({ isCorrect }: { isCorrect: boolean }) {
  const theme = useTheme();

  const style = {
    fontSize: false ? 'calc(12px + 1.1vw)' : 'small',
    marginRight: theme.spacing(1),
    fill: isCorrect ? theme.palette.secondary.main : theme.palette.grays.dark,
  };

  if (isCorrect) {
    return <RadioButtonChecked fontSize="small" style={style} />;
  } else {
    return <RadioButtonUnchecked fontSize="small" style={style} />;
  }
}

type AnswerRowProps = {
  id: number;
  choice: string;
  isCorrect: boolean;
};

export function AnswerRow({ choice, isCorrect, id }: AnswerRowProps) {
  const classes = useStyles({ anonymized: false, reviewable: false });

  return (
    <div key={`select-all${id}`} className={classes.answerRow}>
      <Icon isCorrect={isCorrect} />

      <div className={classes.mc}>
        <Typography variant="body1" style={{ padding: 0 }}>
          {choice}
        </Typography>
      </div>
    </div>
  );
}

type MultipleChoiceBreakdownProps = {
  question: Question;
};

function MultipleChoiceBreakdown({ question }: MultipleChoiceBreakdownProps) {
  const classes = useStyles({ anonymized: true, reviewable: false });

  return (
    <div className={classes.root}>
      {question.options?.map((choice, i) => {
        return (
          <AnswerRow
            id={i}
            choice={choice}
            isCorrect={choice === question.question_answer}
          />
        );
      })}
    </div>
  );
}

export default MultipleChoiceBreakdown;
