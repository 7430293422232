import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  FormControl,
  LinearProgress,
  Select,
  Typography,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import React, { useContext, useState } from 'react';
import { TransferEnrollmentDocument } from '../../../gql/mutations/__generated__/enrollment.generated';
import { GroupDocument } from '../../../gql/queries/__generated__/group.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import type { StudentRow } from '../../../views/Groups/GroupShow';
import { AlertsContext } from '../Alerts/context';
import { pushSnack } from '../Alerts/context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(9),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
  },
  confirmButton: {
    color: theme.palette.common.white,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

type TransferGroupProps = {
  groupId: string;
  closeTransfer: () => void;
  selectedStudents: StudentRow[];
  setSelectedRows: React.Dispatch<React.SetStateAction<StudentRow[]>>;
};

export function TransferGroup({
  groupId,
  closeTransfer,
  selectedStudents,
  setSelectedRows,
}: TransferGroupProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const [transferEnrollment, { loading }] = useMutation(
    TransferEnrollmentDocument,
    {
      onError: onError(dispatch),
      update: (cache, res) => {
        const queryResult = cache.readQuery({
          query: GroupDocument,
          variables: { groupId },
        });

        if (!res.data || !queryResult) {
          return;
        }

        const { group } = queryResult;
        const updatedGroup = produce(group, (groupDraft) => {
          groupDraft.enrollments = groupDraft.enrollments?.filter(
            (enrollment) => enrollment.id !== res.data?.transferEnrollment.id
          );
        });

        cache.writeQuery({
          data: { group: updatedGroup },
          query: GroupDocument,
          variables: { groupId },
        });
      },
    }
  );

  const [transferGroupId, setTransferGroupId] = useState('');
  const { data: teacherData, loading: teacherLoading } = useQuery(
    TeacherDocument,
    { onError: onError(dispatch) }
  );
  const eligibleClasses = teacherData?.teacher.activeCourse?.groups || [];

  const handleTransferGroupUpdate = (e: SelectChangeEvent<string>) => {
    setTransferGroupId(e.target.value);
  };

  const handleTransfer = () => {
    const promises: Promise<unknown>[] = [];
    selectedStudents.forEach((enrollment) => {
      promises.push(
        new Promise<void>((resolve) => {
          transferEnrollment({
            variables: {
              enrollmentId: enrollment.id,
              groupId: transferGroupId,
            },
          }).then(() => {
            resolve();
          });
        })
      );
    });
    const transferredTo = eligibleClasses.find(
      (group) => group.id === transferGroupId
    );
    Promise.all(promises).then(() => {
      dispatch(
        pushSnack({
          message: `${selectedStudents.length} student(s) transferred to ${transferredTo?.name}.`,
        })
      );
      setSelectedRows([]);
      setTransferGroupId('');
    });
  };
  const isLoading = teacherLoading || loading;

  return (
    <div className={classes.root}>
      {isLoading && <LinearProgress />}
      <Typography className={classes.label} variant="h4" color="primary">
        Select a class to transfer your student(s) to:
      </Typography>
      <FormControl fullWidth>
        <Select
          native
          inputProps={{
            name: 'questionTypeSelect',
            id: 'question-type-select',
          }}
          required
          variant="outlined"
          value={transferGroupId}
          onChange={handleTransferGroupUpdate}
        >
          <option key="unselected" value={''}>
            Select a Class
          </option>
          {eligibleClasses.map(({ name, id }) => (
            <option key={`option-${id}`} value={id}>
              {name}
            </option>
          ))}
        </Select>
      </FormControl>

      <div className={classes.actionsContainer}>
        <Button
          className={classes.cancelButton}
          variant="outlined"
          onClick={closeTransfer}
          color="secondary"
        >
          CANCEL
        </Button>
        <Button
          onClick={handleTransfer}
          className={classes.confirmButton}
          disabled={transferGroupId === ''}
          color="secondary"
          variant="contained"
        >
          SAVE CHANGES
        </Button>
      </div>
    </div>
  );
}
