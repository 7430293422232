import { Refresh } from '@mui/icons-material';
import { IconButton, Tooltip, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formatDistance } from 'date-fns';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {},
  refreshIcon: {
    color: theme.palette.primary.main,
  },
}));

type LastUpdatedProps = {
  refetch: () => void;
};

export function LastUpdated({ refetch }: LastUpdatedProps) {
  const classes = useStyles();
  const [timestamp, setTimestamp] = useState(new Date());
  const [now, setNow] = useState(new Date());
  const fetchUpdates = () => {
    refetch();
    setTimestamp(new Date());
  };
  const updateNow = () => setNow(new Date());
  useEffect(() => {
    setInterval(() => {});
    const refreshIntervalId = setInterval(updateNow, 30000);

    /* cleanup */
    return () => clearInterval(refreshIntervalId);
  }, []);
  return (
    <div className={classes.root}>
      <Tooltip title="Fetch latest updates">
        <IconButton
          onClick={fetchUpdates}
          aria-label="Fetch Latest Updates"
          size="large"
        >
          <Refresh className={classes.refreshIcon} />
        </IconButton>
      </Tooltip>
      <Typography
        variant="caption"
        className={classes.text}
      >{`Updated ${formatDistance(timestamp, now)} ago`}</Typography>
    </div>
  );
}
