import { useQuery } from '@apollo/client';
import type { Column } from '@material-table/core';
import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { buttonLink } from '../../../../../../assets/shared-styles/Button-Link';
import { AssignmentDocument } from '../../../../../../gql/queries/__generated__/assignment.generated';
import {
  StudySessionByIdDocument,
  StudySessionResponsesDocument,
} from '../../../../../../gql/queries/__generated__/enrollmentPersonalDeck.generated';
import { GroupDocument } from '../../../../../../gql/queries/__generated__/group.generated';
import {
  DistributedAssignmentResultsDocument,
  GroupsAssignmentDocument,
} from '../../../../../../gql/queries/__generated__/groupsAssignment.generated';
import { CustomTable } from '../../../../../shared/Table';
import HelpKitPersonalDeckAssignment from '../../../../HelpKitArticles/HelpKitPersonalDeckAssignment';
import { ResultsModal } from '../ResultsModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  buttonLink: {
    ...buttonLink(theme),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
}));

interface Row {
  student: string;
  enrollmentId: string;
  total: number;
  [k: string]: any;
}

export function PersonalDeckAssignmentResults() {
  const classes = useStyles();
  const { assignmentId, groupId } = useParams<{
    assignmentId: string;
    groupId: string;
  }>();
  const { data, loading } = useQuery(DistributedAssignmentResultsDocument, {
    variables: {
      assignmentId,
      groupId,
    },
  });

  const { data: groupData } = useQuery(GroupDocument, {
    variables: {
      groupId,
    },
  });

  const { data: assignmentData, loading: assignmentLoading } = useQuery(
    AssignmentDocument,
    {
      variables: {
        assignmentId,
      },
    }
  );

  const { data: groupsAssignmentData } = useQuery(GroupsAssignmentDocument, {
    variables: {
      assignmentId,
      groupId,
    },
  });
  const [personalDeckStudySessionId, setPersonalDeckStudySessionId] = useState<
    undefined | string
  >();

  const { data: studySessionData, loading: studySessionLoading } = useQuery(
    StudySessionResponsesDocument,
    {
      skip: !personalDeckStudySessionId,
      variables: {
        personalDeckStudySessionId: personalDeckStudySessionId || '',
      },
    }
  );
  const { data: studySessionInfo } = useQuery(StudySessionByIdDocument, {
    skip: !personalDeckStudySessionId,
    variables: {
      personalDeckStudySessionId: personalDeckStudySessionId || '',
    },
  });

  const tableData = useMemo(() => {
    const dateSet: Set<string> = new Set();
    if (!data?.distributedAssignmentResults) {
      return { calculatedData: [], dateSet };
    }

    const calculatedData = Object.keys(data.distributedAssignmentResults).map(
      (enrollmentId) => {
        const { student, total_points, ...dates } =
          data.distributedAssignmentResults[enrollmentId];
        Object.keys(dates).forEach((dateStr) => {
          dateSet.add(dateStr);
        });
        return {
          student,
          enrollmentId,
          total: total_points,
          ...dates,
        };
      }
    );
    return { calculatedData, dateSet };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.distributedAssignmentResults]);

  const handleClose = () => setPersonalDeckStudySessionId(undefined);
  const handleOpen = (sessionId: string) => {
    return () => {
      setPersonalDeckStudySessionId(sessionId);
    };
  };
  const columns: Column<Row>[] = useMemo(() => {
    const baseColumns: Column<Row>[] = [
      {
        title: 'STUDENT',
        field: 'student',
        defaultSort: 'asc',
        render: (rowData) => {
          return (
            <Link
              to={`/classes/${groupId}/enrollments/${rowData.enrollmentId}`}
            >
              <button className={classes.buttonLink}>{rowData.student}</button>
            </Link>
          );
        },
      },
      {
        title: 'TOTAL POINTS EARNED',
        field: 'total',
        render: (rowData) => {
          if (!assignmentData?.assignment.targetDistributedPoints) {
            return '';
          }

          const percentage = Math.round(
            (rowData.total /
              assignmentData.assignment.targetDistributedPoints) *
              100
          );
          return (
            <div>
              {`${rowData.total}/${assignmentData?.assignment.targetDistributedPoints} (${percentage}%)`}
            </div>
          );
        },
      },
    ];

    if (
      !assignmentData?.assignment.launchAt ||
      !groupsAssignmentData?.groupsAssignment.dueDate
    ) {
      return baseColumns;
    }

    Array.from(tableData.dateSet)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      .forEach((t) => {
        const dateString = t;
        baseColumns.push({
          title: dateString,
          field: dateString,
          render: (rowData) => {
            if (!rowData[dateString]) {
              return '';
            }
            return (
              <div>
                {rowData[dateString].map(
                  (
                    session: {
                      id: string;
                      total_correct: number;
                      total_questions: number;
                    },
                    index: number
                  ) => {
                    return (
                      <div key={session.id}>
                        <button
                          className={classes.buttonLink}
                          onClick={handleOpen(session.id)}
                        >
                          {`${
                            session.total_questions === 0
                              ? 'Free Session'
                              : `${session.total_correct} / ${session.total_questions}`
                          } (${index === 0 ? '1 point' : '0.5 points'})`}
                        </button>
                      </div>
                    );
                  }
                )}
              </div>
            );
          },
        });
      });

    return baseColumns;
  }, [
    assignmentData?.assignment.launchAt,
    assignmentData?.assignment.targetDistributedPoints,
    classes.buttonLink,
    groupId,
    groupsAssignmentData?.groupsAssignment.dueDate,
    tableData.dateSet,
  ]);

  return (
    <div className={classes.root}>
      <HelpKitPersonalDeckAssignment />
      <div className={classes.tableContainer}>
        <CustomTable
          options={{
            draggable: false,
            pageSize: 1000,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '85vh',
          }}
          isLoading={loading || assignmentLoading}
          data={tableData.calculatedData}
          columns={columns}
          components={{ Toolbar: () => null, Pagination: () => null }}
        />
      </div>
      <ResultsModal
        open={!!personalDeckStudySessionId}
        loading={studySessionLoading}
        assignmentLoading={studySessionLoading}
        handleClose={handleClose}
        data={
          studySessionData
            ? {
                studentsAssignmentsQuestions:
                  studySessionData.studySessionResponses,
              }
            : undefined
        }
        assignmentData={
          studySessionInfo
            ? {
                studentsAssignmentsDatum: {
                  student: {
                    fullName:
                      studySessionInfo.studySessionById.enrollment.student
                        .fullName || '',
                  },
                  groupsAssignment: {
                    assignment: { name: 'Personal Deck Session' },
                    dueDate: studySessionInfo.studySessionById.finishedAt,
                    group: { name: groupData?.group.name || '' },
                  },
                  questionsTotal:
                    studySessionInfo.studySessionById.totalQuestions,
                  questionsCompleted:
                    studySessionInfo.studySessionById.numCompleted,
                  questionsCorrect:
                    studySessionInfo.studySessionById.totalCorrect,
                  questionsAttempted:
                    studySessionInfo.studySessionById.totalQuestions,
                },
              }
            : undefined
        }
      />
    </div>
  );
}
