import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { OpenedContent } from '.';
import privacy from '../../../../utils/data/privacy-policy.md';
import tos from '../../../../utils/data/terms-of-service.md';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    height: '85vh',
    '& a': {
      color: 'blue',
    },
  },
  header: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3),
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
}));

type PolicyModalProps = {
  content: OpenedContent;
  handleClose: () => void;
};

export function PolicyModal({ content, handleClose }: PolicyModalProps) {
  const classes = useStyles();
  const [privacyContent, setPrivacyContent] = useState('');
  const [tosContent, setTosContent] = useState('');
  useEffect(() => {
    fetch(privacy)
      .then((response) => response.text())
      .then((text) => {
        setPrivacyContent(text);
      });
    fetch(tos)
      .then((response) => response.text())
      .then((text) => {
        setTosContent(text);
      });
  }, []);
  const renderContent = () => {
    if (content === 'privacy') {
      return privacyContent;
    }

    if (content === 'tos') {
      return tosContent;
    }

    return '';
  };
  const title = content === 'privacy' ? 'Privacy Policy' : 'Terms of Service';

  return (
    <Dialog
      open={!!content}
      maxWidth="lg"
      fullWidth
      scroll="paper"
      onClose={handleClose}
    >
      <DialogTitle className={classes.header} variant="h4">
        {title}
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ReactMarkdown>{renderContent()}</ReactMarkdown>
      </DialogContent>
    </Dialog>
  );
}
export default PolicyModal;
