import { useMutation, useQuery } from '@apollo/client';
import {
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useContext } from 'react';
import { curriculumDashboardHeaderHeight } from '.';
import { UpdateQuestionReviewSeverityDocument } from '../../../gql/mutations/__generated__/question.generated';
import { AssignmentForCurriculumReviewDocument } from '../../../gql/queries/__generated__/assignment.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../Alerts/context';
import CurriculumQuestion from './CurriculumQuestion';

const useStyles = makeStyles((theme: Theme) => ({
  detailsPanel: {
    height: `calc(100vh - ${curriculumDashboardHeaderHeight}px)`,
    padding: theme.spacing(5),
    overflow: 'auto',
  },
  metadata: {
    padding: theme.spacing(2),
  },
  questionCard: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  questionContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    overflow: 'auto',
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  severity0: {
    border: `2px solid green`,
    boxShadow: `0 0 5px green`,
  },
  severity1: {
    border: `2px solid ${theme.palette.warning.main}`,
    boxShadow: `0 0 5px ${theme.palette.warning.light}`,
  },
  severity2: {
    border: `2px solid ${theme.palette.error.main}`,
    boxShadow: `0 0 5px ${theme.palette.error.light}`,
  },
  severity3: {
    backgroundColor: theme.palette.grays.main,
  },
  severity4: {
    backgroundColor: '#FFF9E6',
  },
}));

type AssignmentDisplayProps = {
  selectedAssignmentId: string;
};

export function AssignmentDisplay({
  selectedAssignmentId,
}: AssignmentDisplayProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(AssignmentForCurriculumReviewDocument, {
    skip: !selectedAssignmentId,
    variables: { assignmentId: selectedAssignmentId },
  });

  const [updateSeverity] = useMutation(UpdateQuestionReviewSeverityDocument, {
    onError: onError(dispatch),
    update: (cache, { data }) => {
      const questionId = data?.updateQuestionReviewSeverity?.id;
      const reviewSeverity =
        data?.updateQuestionReviewSeverity?.reviewSeverity ?? 0;

      const cachedData = cache.readQuery({
        query: AssignmentForCurriculumReviewDocument,
        variables: { assignmentId: selectedAssignmentId },
      });

      if (!cachedData) return;

      const updatedQuestions = cachedData?.assignment.curriculumQuestions.map(
        (question) => {
          return question.id === questionId
            ? { ...question, reviewSeverity }
            : question;
        }
      );

      // calculate the updated numQuestionsNeedReview
      const updatedNumQuestionsNeedReview = updatedQuestions?.reduce(
        (count, question) => (question.reviewSeverity > 0 ? count + 1 : count),
        0
      );

      cache.writeQuery({
        data: {
          assignment: {
            ...cachedData.assignment,
            curriculumQuestions: updatedQuestions,
            numQuestionsNeedReview: updatedNumQuestionsNeedReview,
          },
        },
        query: AssignmentForCurriculumReviewDocument,
        variables: { assignmentId: selectedAssignmentId },
      });
    },
  });

  const handleReviewSeverityChange = (
    questionId: string,
    newValue: number | string
  ) => {
    updateSeverity({
      variables: {
        questionId,
        reviewSeverity: newValue as number,
      },
    });
  };

  if (loading) {
    return (
      <Grid xs={8} item className={classes.detailsPanel}>
        <div>loading...</div>
      </Grid>
    );
  }

  if (!selectedAssignmentId) {
    return (
      <Grid xs={8} item className={classes.detailsPanel}>
        <div>no assignment selected</div>
      </Grid>
    );
  }
  return (
    <Grid xs={8} item className={classes.detailsPanel}>
      {(data?.assignment.curriculumQuestions || []).map((question) => {
        return (
          <Card
            key={question.id}
            className={clsx(classes.questionCard, {
              [classes.severity0]: question.reviewSeverity === 0,
              [classes.severity1]: question.reviewSeverity === 1,
              [classes.severity2]: question.reviewSeverity === 2,
              [classes.severity3]: question.reviewSeverity === 3,
              [classes.severity4]: question.reviewSeverity === 4,
            })}
          >
            <div className={classes.metadata}>
              <div>
                <strong>Import ID: </strong>
                {question.importId}
              </div>

              <div>
                <strong>Question Part: </strong>
                {question.questionPart}
              </div>
            </div>
            <div className={classes.questionContainer}>
              <CurriculumQuestion question={question} />
            </div>

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="review-severity-label">
                Review Severity
              </InputLabel>
              <Select
                variant="outlined"
                labelId="review-severity-label"
                label="Review Severity"
                value={question.reviewSeverity}
                onChange={(e) =>
                  handleReviewSeverityChange(question.id, e.target.value)
                }
              >
                <MenuItem value={4}>Will Revisit</MenuItem>
                <MenuItem value={3}>Should Delete</MenuItem>
                <MenuItem value={2}>Needs Edit</MenuItem>
                <MenuItem value={1}>Needs Review</MenuItem>
                <MenuItem value={0}>Good to go!</MenuItem>
              </Select>
            </FormControl>
          </Card>
        );
      })}
    </Grid>
  );
}
