import { ApolloError } from '@apollo/client';
import {
  openDialog,
  pushSnack,
} from '../../components/application/Alerts/context/actions';
import { AlertAction } from '../../components/application/Alerts/context/reducer';

export const onError = (
  dispatch: (action: AlertAction) => void,
  reloadOnClose: boolean = false,
  snack: boolean = false
) => {
  return (e: ApolloError) => {
    if (snack) {
      dispatch(
        pushSnack({
          message: e.message,
          severity: 'error',
        })
      );
      return;
    }
    dispatch(
      openDialog({
        title: 'Error occurred.',
        message: e.message,
        error: true,
        reloadOnClose: reloadOnClose,
      })
    );
  };
};

export const stripTypename = <Type extends { __typename?: string }>(
  obj: Type
): Omit<Type, '__typename'> => {
  const { __typename, ...strippedObj } = obj;
  return strippedObj;
};
