import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuestionAttributesFragment } from '../../../gql/fragments/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../gql/types';
import { FreeResponseBreakdown } from './FreeResponseBreakdown';
import MultipleChoiceBreakdown from './MultipleChoiceBreakdown';
import ShortAnswerBreakdown from './ShortAnswerBreakdown';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(2, 0, 1, 0),
  },
}));

type AnswersBreakdownProps = {
  question: QuestionAttributesFragment;
  reviewable?: boolean;
  style?: React.CSSProperties;
  questionsBreakdown?: any;
  hideAnswers?: boolean;
  hideStats?: boolean;
};

export function AnswersBreakdown({
  question,
  reviewable,
  style,
  questionsBreakdown,
  hideAnswers,
  hideStats,
}: AnswersBreakdownProps) {
  const classes = useStyles();

  let content = null;

  switch (question.questionType) {
    case QuestionTypeEnum.FreeResponse:
      content = (
        <FreeResponseBreakdown
          question={question}
          hideAnswers={hideAnswers}
          reviewable={reviewable}
        />
      );
      break;
    case QuestionTypeEnum.ShortAnswer:
      content = (
        <ShortAnswerBreakdown
          question={question}
          hideAnswers={hideAnswers}
          reviewable={reviewable}
        />
      );
      break;

    case QuestionTypeEnum.SelectAllMultipleChoice:
    case QuestionTypeEnum.SelectOneMultipleChoice:
      content = (
        <MultipleChoiceBreakdown
          question={question}
          questionsBreakdown={questionsBreakdown}
          hideStats={hideStats}
          hideAnswers={hideAnswers}
          reviewable={reviewable}
        />
      );
      break;
    default:
      content = null;
  }

  return (
    <div className={classes.container} style={style}>
      {content}
    </div>
  );
}
