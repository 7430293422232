import { Close } from '@mui/icons-material';
import {
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Dialog from './Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dialogContent: {
    backgroundColor: theme.palette.background.default,
    minHeight: '80vh',
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
}));

type StyledDialogProps = {
  open: boolean;
  title: string;
  handleClose: () => void;
  children: React.ReactNode;
};

export function StyledDialog({
  open,
  title,
  children,
  handleClose,
}: StyledDialogProps & DialogProps) {
  const classes = useStyles();
  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle} variant="h3">
        {title}
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} id="scrollable-dialog">
        {children}
      </DialogContent>
    </Dialog>
  );
}
export default StyledDialog;
