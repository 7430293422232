import { OpenInNew } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  LinearProgress,
  Select,
  TextField,
  Tooltip,
  Typography,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Sources } from 'quill';
import React, {
  ChangeEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { UnprivilegedEditor, Value } from 'react-quill';
import { Link } from 'react-router-dom';
import userflow from 'userflow.js';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import type {
  CreateQuestionMutationVariables,
  UpdateQuestionMutationVariables,
} from '../../../../gql/mutations/__generated__/question.generated';
import type { StandardsQuery } from '../../../../gql/queries/__generated__/standard.generated';
import {
  QuestionTypeEnum,
  ShortAnswerAnswer,
  type QuestionInput,
} from '../../../../gql/types';
import { stripTypename } from '../../../../utils/apollo/apolloHelper';
import { questionTypeOptions } from '../../../../utils/options/questionTypeOptions';
import {
  containsImages,
  extractPlainAndRichText,
} from '../../../../utils/quillHelper';
import Editor from '../../../shared/Editor';
import { AlertsContext } from '../../Alerts/context';
import { openConfirmation, pushSnack } from '../../Alerts/context/actions';
import HelpKitDesirableDifficulty from '../../HelpKitArticles/HelpKitDesirableDifficulty';
import HelpKitQuestionTypes from '../../HelpKitArticles/HelpKitQuestionTypes';
import HelpKitRequiredKeywords from '../../HelpKitArticles/HelpKitRequiredKeywords';
import { Answers } from './Answers';
import { AnswerTitleSubtitle } from './Answers/AnswerTitleSubtitle';
import QuestionFormExplanation from './QuestionFormExplanation';
import RequiredKeywordsContainer from './RequiredKeywordsContainer';
import { StandardsAutocomplete } from './StandardsAutocomplete';
import { QuestionFormContext } from './context';
import {
  resetQuestionForm,
  updateFreeResponseAnswer,
  updateNumAttempts,
  updateQuestion,
  updateQuestionType,
  updateStandard,
} from './context/actions';

type StyleProps = {
  existingQuestion: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  textEditorContainer: {
    marginBottom: theme.spacing(1),
  },
  submitButton: {
    color: theme.palette.common.white,
    margin: ({ existingQuestion }: StyleProps) =>
      existingQuestion ? '0 auto' : 0,
    display: 'block',
  },
  submitButtonContainer: {
    paddingTop: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: ({ existingQuestion }: StyleProps) =>
      existingQuestion ? 'center' : 'flex-start',
    alignItems: 'center',
  },
  required: {
    color: theme.palette.accent.main,
    marginLeft: theme.spacing(0.5),
  },
  fieldList: {
    position: 'relative',
    listStyle: 'none',
    counterReset: 'custom-counter',
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
    margin: 0,
  },
  wrapper: {
    marginLeft: theme.spacing(1),
    width: '100%',
  },
  listItem: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    counterIncrement: 'custom-counter',
    '&:before': {
      paddingTop: theme.spacing(0.25),
      content: 'counter(custom-counter) ". "',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 'large',
    },
  },
  buttonLink: {
    ...buttonLink(theme),
    marginLeft: theme.spacing(1),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  notFullWidth: {
    width: '70%',
    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  label: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  },
  caption: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  checkbox: {
    marginLeft: theme.spacing(1),
  },
  externalLink: {
    ...buttonLink(theme),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  launchIcon: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'underline',
  },
  helperButton: {
    padding: 0,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  openInNewIcon: {
    marginRight: theme.spacing(0.5),
  },
}));

type QuestionFormProps = {
  existingQuestion?: Value;
  handleSubmit: (
    question: CreateQuestionMutationVariables | UpdateQuestionMutationVariables
  ) => Promise<
    ({ __typename?: 'Question' } & QuestionAttributesFragment) | void
  >;
  submitLoading: boolean;
  children?: ReactNode;
  defaultKeepExisting?: boolean;
  hideStandards?: boolean;
  hideSubmitButton?: boolean;
  triggerSubmit?: boolean;
  postSubmitCallback?: (data: QuestionAttributesFragment) => void;
};

export function QuestionsForm({
  existingQuestion,
  handleSubmit,
  submitLoading,
  defaultKeepExisting,
  hideStandards,
  hideSubmitButton = false,
  triggerSubmit,
  children,
  postSubmitCallback,
}: QuestionFormProps) {
  const classes = useStyles({ existingQuestion: !!existingQuestion });
  const { questionForm, dispatch } = useContext(QuestionFormContext);
  const { dispatch: alertsDispatch } = useContext(AlertsContext);
  const [keepExisting, setKeepExisting] = useState(
    defaultKeepExisting || false
  );
  const listRef = useRef<HTMLOListElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const {
    questionType,
    standards,
    question,
    numAttemptsUntilAnswerShown,
    supplement,
    freeResponseAnswer,
    shortAnswerAnswers,
    hiddenShortAnswers,
    multipleChoiceAnswerChoices,
  } = questionForm;

  const numAttemptsNumber =
    typeof numAttemptsUntilAnswerShown === 'number'
      ? numAttemptsUntilAnswerShown
      : parseInt(numAttemptsUntilAnswerShown);

  const toggleFlagFreeResponseAnswer = () =>
    dispatch(
      updateFreeResponseAnswer({
        value: questionForm.freeResponseAnswer.text,
        comparable: !questionForm.freeResponseAnswer.comparable,
      })
    );

  const onSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      if (e) {
        e.preventDefault();
      }

      const { text: questionPlainText = '', richText: questionRichText = {} } =
        extractPlainAndRichText(question);

      const {
        text: supplementPlainText = '',
        richText: supplementRichText = {},
      } = extractPlainAndRichText(supplement);

      const {
        text: freeResponseAnswerText,
        richText: freeResponseAnswerRichText,
      } = extractPlainAndRichText(freeResponseAnswer.text);

      const submittedQuestion: QuestionInput = {
        ...(questionForm?.id && { id: questionForm?.id }),
        richText: questionRichText,
        plainText: questionPlainText,
        numAttemptsUntilAnswerShown: numAttemptsNumber,
        supplement: {
          plainText: supplementPlainText,
          richText: supplementRichText,
        },
        questionType: questionType as QuestionTypeEnum,
        standardIds: standards.length
          ? standards.map((standard) => standard.id.toString())
          : [],
        freeResponseAnswer: {
          text: freeResponseAnswerText,
          richText: freeResponseAnswerRichText,
          comparable: freeResponseAnswer.comparable,
          requiredKeywords: freeResponseAnswer.requiredKeywords
            .map((word) => word.trim())
            .filter((word) => word !== ''),
        },
        shortAnswerAnswers: [...shortAnswerAnswers, ...hiddenShortAnswers].map(
          (answer) => stripTypename(answer.data as ShortAnswerAnswer)
        ) as ShortAnswerAnswer[],
        multipleChoiceAnswerChoices: multipleChoiceAnswerChoices.map(
          ({ isCorrect, text, id }) => {
            const { text: plainText, richText } = extractPlainAndRichText(text);
            return {
              ...(id && { id }),
              isCorrect,
              text: plainText,
              richText,
            };
          }
        ),
      };

      const resetForm = (
        question?: { __typename?: 'Question' } & QuestionAttributesFragment
      ) => {
        const payload: {
          [key: string]: {
            __typename?: 'Question';
          } & QuestionAttributesFragment;
        } = {};
        if (question) {
          payload.question = question;
        }
        dispatch(resetQuestionForm(payload));
      };

      const processSubmit = (submittedQuestion: QuestionInput) => {
        handleSubmit({ question: submittedQuestion }).then((data) => {
          if (data && !existingQuestion) {
            // reset form if creating new question (and not just editing old question)
            if (keepExisting) {
              resetForm(data);
            } else {
              resetForm();
            }
            alertsDispatch(
              pushSnack({
                message: 'Question created!',
              })
            );
            listRef.current?.scroll({
              top: 0,
              behavior: 'smooth',
            });
            userflow.track('userflow_question_created', {
              question_text: submittedQuestion.plainText,
              question_type: submittedQuestion.questionType,
            });
            window.scroll({
              top: 0,
              behavior: 'smooth',
            });
          }
          if (data && postSubmitCallback) {
            postSubmitCallback(data);
          }
        });
      };

      const dissuadeTrueFalseAndSubmit = (submittedQuestion: QuestionInput) => {
        // for any question that doesn't have multiple choice or has more than 2 choices
        // no need to validate that the user actually wants to create the question:
        if (
          !submittedQuestion.multipleChoiceAnswerChoices?.length ||
          submittedQuestion.multipleChoiceAnswerChoices.length > 2
        ) {
          processSubmit(submittedQuestion);
          return true;
        }

        alertsDispatch(
          openConfirmation({
            confirmFunc: () => {
              processSubmit(submittedQuestion);
            },
            confirmButtonText: 'Create Question',
            message: (
              <div>
                <p>
                  <strong>
                    Are you sure you want to create a question with only 2
                    answer choices?
                  </strong>
                </p>
                <p>
                  Questions that only have 2 answer choices may lack the
                  &lsquo;desirable difficulty&rsquo; necessary for a good Podsie
                  question. Furthermore, when students later review this
                  question in their Personal Deck, they may start to memorize
                  the answer choice rather truly understanding the underlying
                  content. <HelpKitDesirableDifficulty />
                </p>
              </div>
            ),
          })
        );
      };

      dissuadeTrueFalseAndSubmit(submittedQuestion);
    },
    [
      alertsDispatch,
      dispatch,
      existingQuestion,
      freeResponseAnswer.comparable,
      freeResponseAnswer.requiredKeywords,
      freeResponseAnswer.text,
      handleSubmit,
      hiddenShortAnswers,
      keepExisting,
      multipleChoiceAnswerChoices,
      numAttemptsNumber,
      question,
      questionForm.id,
      questionType,
      shortAnswerAnswers,
      standards,
      supplement,
      postSubmitCallback,
    ]
  );

  const handleStandardUpdate = (
    _event: unknown,
    newValue: StandardsQuery['standards'][0][] | null
  ) => {
    const update = newValue || [];
    dispatch(updateStandard({ value: update }));
  };

  const handleQuestionChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    dispatch(updateQuestion({ question: editor.getContents() }));
  };

  const handleNumAttemptsChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(updateNumAttempts({ value: event.target.value }));
  };

  const handleQuestionTypeUpdate = (e: SelectChangeEvent<QuestionTypeEnum>) => {
    dispatch(updateQuestionType({ value: e.target.value as QuestionTypeEnum }));
  };

  const freeResponseAnswerHasImage = containsImages(freeResponseAnswer.text);
  const editing = !!existingQuestion;
  const numAttemptsError = numAttemptsNumber < 1 || numAttemptsNumber > 5;

  const submitButton = submitLoading ? (
    <LinearProgress />
  ) : (
    <div>
      <Button
        color="secondary"
        variant="contained"
        aria-label={existingQuestion ? 'Update Question' : 'Create Question'}
        className={classes.submitButton}
        type="submit"
      >
        {existingQuestion ? 'Update Question' : 'Create Question'}
      </Button>
    </div>
  );

  // If the triggerSubmit prop changes, submit the form
  useEffect(() => {
    if (triggerSubmit && hideSubmitButton) {
      onSubmit();
    }
  }, [triggerSubmit, onSubmit, hideSubmitButton]);

  return (
    <form onSubmit={onSubmit} className={classes.root} ref={formRef}>
      <ol ref={listRef} className={classes.fieldList}>
        {children}
        <li className={`${classes.listItem}`}>
          <div className={classes.wrapper}>
            <label className={classes.label} htmlFor="question-type-select">
              <Typography variant="h4" color="primary">
                Select Question Type
              </Typography>
              <span className={classes.required}>*</span>
              <div className={`${classes.flex} ${classes.marginLeft}`}>
                <HelpKitQuestionTypes />
              </div>
            </label>
            <FormControl fullWidth>
              <Select
                disabled={editing}
                native
                inputProps={{
                  name: 'questionTypeSelect',
                  id: 'question-type-select',
                }}
                required
                variant="outlined"
                value={questionType}
                onChange={handleQuestionTypeUpdate}
              >
                {questionTypeOptions.map(({ value, text }) => (
                  <option key={`option-${value}`} value={value}>
                    {text}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
        </li>
        {!hideStandards && (
          <li className={`${classes.listItem}`}>
            <div className={classes.wrapper}>
              <label className={classes.label}>
                <Typography variant="h4" color="primary">
                  Select a Standard (optional)
                </Typography>
                <div className={classes.flex}>
                  <Link
                    to="/subject"
                    target="_blank"
                    className={`${classes.buttonLink} ${classes.flex}`}
                    rel="noopener noreferrer"
                  >
                    <OpenInNew
                      fontSize="small"
                      className={classes.openInNewIcon}
                    />
                    <span>Edit Standards</span>
                  </Link>
                </div>
              </label>
              <StandardsAutocomplete
                requiredStandards={false}
                handleStandardUpdate={handleStandardUpdate}
                standards={standards}
              />
            </div>
          </li>
        )}

        <li className={classes.listItem}>
          <div className={classes.wrapper}>
            <label className={classes.label}>
              <Typography variant="h4" color="primary">
                Enter Question
              </Typography>
              <span className={classes.required}>*</span>
            </label>
            <Editor value={question} onChange={handleQuestionChange} />
          </div>
        </li>
        <li className={classes.listItem}>
          <div className={classes.wrapper}>
            <AnswerTitleSubtitle />
            <Answers />
          </div>
        </li>
        {questionType === QuestionTypeEnum.FreeResponse ? (
          <>
            <li className={classes.listItem}>
              <div className={classes.wrapper}>
                <label className={classes.label}>
                  <Typography
                    variant="h4"
                    color="primary"
                    className={classes.marginRight}
                  >
                    Check for Required Keywords (optional){' '}
                    <HelpKitRequiredKeywords />
                  </Typography>
                </label>
                <Typography variant="caption" className={classes.caption}>
                  Students&apos; answers will be automatically marked incorrect
                  if answers do not have ALL of the required keywords. If the
                  answer has all of the required keywords, then students will be
                  able to self-assess their own answer. If preferred, you can
                  leave this section blank.
                </Typography>
                <RequiredKeywordsContainer />
              </div>
            </li>
            <li className={classes.listItem}>
              <div className={classes.wrapper}>
                <label className={classes.label}>
                  <Typography variant="h4" color="primary">
                    Flag Self-Assessments
                  </Typography>
                  <span className={classes.required}>*</span>
                </label>
                <Typography variant="caption" className={classes.caption}>
                  If students self-assess their free response answers as
                  correct, and our system detects a large enough discrepancy
                  between their response and your exemplar response, our system
                  will notify you. Uncheck this if you do not want notifications
                  for this question.
                </Typography>
                <Tooltip
                  title={
                    freeResponseAnswerHasImage
                      ? 'Answers with images does not allow for self-assessment flagging.'
                      : ''
                  }
                >
                  <FormControlLabel
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={
                          !freeResponseAnswerHasImage &&
                          freeResponseAnswer.comparable
                        }
                        onChange={toggleFlagFreeResponseAnswer}
                        disabled={freeResponseAnswerHasImage}
                        name="flag-responses"
                        color="primary"
                      />
                    }
                    label="Flag Responses"
                  />
                </Tooltip>
              </div>
            </li>
          </>
        ) : (
          <li className={classes.listItem}>
            <div className={classes.wrapper}>
              <label className={classes.label}>
                <Typography variant="h4" color="primary">
                  Number of Attempts until Answer Shown
                </Typography>
                <span className={classes.required}>*</span>
              </label>
              <Typography variant="caption" className={classes.caption}>
                Explanation is REQUIRED if number of attempts is greater than 1
                because students will rely on Explanation when the correct
                answer is hidden. By default, this field is set to 1, which
                means that the correct answer will be shown right away after the
                first attempt.
              </Typography>
              <TextField
                variant="outlined"
                type="number"
                helperText={
                  numAttemptsError ? 'Select a number between 1 and 5.' : ''
                }
                error={numAttemptsError}
                onChange={handleNumAttemptsChange}
                value={numAttemptsUntilAnswerShown}
                required
                InputProps={{
                  inputProps: {
                    max: 5,
                    min: 1,
                  },
                }}
              />
            </div>
          </li>
        )}
        <li className={classes.listItem}>
          <QuestionFormExplanation numAttemptsNumber={numAttemptsNumber} />
        </li>
      </ol>
      <div className={classes.submitButtonContainer}>
        {!hideSubmitButton && submitButton}

        {!submitLoading && !existingQuestion ? (
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={keepExisting}
                onChange={() => setKeepExisting(!keepExisting)}
                name="keep-existing"
                color="primary"
              />
            }
            label="Keep Question Content"
          />
        ) : null}
      </div>
    </form>
  );
}

export default QuestionsForm;
