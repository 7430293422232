import { useMutation } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import { useContext } from 'react';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import {
  CreateQuestionDocument,
  type CreateQuestionMutationVariables,
} from '../../../../gql/mutations/__generated__/question.generated';
import {
  GetUpdateQuestionDocument,
  QuestionsDocument,
  type GetUpdateQuestionQuery,
  type QuestionsQuery,
} from '../../../../gql/queries/__generated__/question.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import {
  QuestionsSearchContext,
  searchParamsToQueryParams,
} from '../Search/context/QuestionsSearchContext';
import Form from './Form';
import { QuestionFormProvider } from './context';
import { extractQuestionFormState } from './context/util';

const useStyles = makeStyles(() => ({
  formContainer: {
    overflow: 'scroll',
    height: '100%',
  },
}));

type CreateQuestionProps = {
  handleClose: () => void;
  parentQuestionId?: string;
  prefilledQuestion: GetUpdateQuestionQuery['question'];
  triggerCreateFormSubmit: boolean;
  setTriggerCreateFormSubmit: (trigger: boolean) => void;
};

export function CreateQuestion({
  handleClose,
  parentQuestionId,
  prefilledQuestion,
  triggerCreateFormSubmit,
  setTriggerCreateFormSubmit,
}: CreateQuestionProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { questionsSearchParams } = useContext(QuestionsSearchContext);
  const [createQuestion, { loading }] = useMutation(CreateQuestionDocument, {
    onError: onError(dispatch),
    update: (cache, res) => {
      let data;
      try {
        data = cache.readQuery({
          query: QuestionsDocument,
          variables: searchParamsToQueryParams(questionsSearchParams),
        });
      } catch (e) {
        // https://github.com/apollographql/apollo-feature-requests/issues/1
        console.log('questions query does not exist in cache.', e);
      }
      if (res.data?.createQuestion.parent) {
        const questionData = cache.readQuery({
          query: GetUpdateQuestionDocument,
          // @ts-expect-error parentQuestionId should not be `undefined`
          variables: { questionId: parentQuestionId },
        });
        const updatedQuestion = produce(
          questionData?.question,
          (questionDraft) => {
            if (res.data?.createQuestion) {
              questionDraft?.variations?.unshift(res.data?.createQuestion);
            }
          }
        );
        if (updatedQuestion) {
          cache.writeQuery({
            data: { question: updatedQuestion },
            query: GetUpdateQuestionDocument,
            // @ts-expect-error parentQuestionId should not be `undefined`
            variables: { questionId: parentQuestionId },
          });
        }
      }

      handleClose();
      if (!data) {
        return;
      }

      const updatedQuestions = produce(
        data?.questions,
        (questionsDraft: QuestionsQuery['questions']) => {
          if (res.data?.createQuestion.parent) {
            questionsDraft.forEach((question) => {
              if (!res.data?.createQuestion.parent) {
                return;
              }
              if (
                question.id === res.data?.createQuestion.parent.id &&
                question.variations
              ) {
                question.variations.unshift(res.data.createQuestion);
              }
            });
          } else if (res.data) {
            questionsDraft.unshift(res.data.createQuestion);
          }
        }
      );

      cache.writeQuery({
        data: { questions: updatedQuestions },
        query: QuestionsDocument,
        variables: searchParamsToQueryParams(questionsSearchParams),
      });
    },
  });
  const handleSubmit = (
    question: CreateQuestionMutationVariables
  ): Promise<{ __typename?: 'Question' } & QuestionAttributesFragment> => {
    // Prevents resubmitting form
    setTriggerCreateFormSubmit(false);

    return new Promise((resolve) => {
      const variables: CreateQuestionMutationVariables = question;
      if (parentQuestionId) {
        variables.question.parentId = parentQuestionId;
      }
      createQuestion({ variables }).then((data) => {
        if (data?.data?.createQuestion) {
          resolve(data.data.createQuestion);
        }
      });
    });
  };
  const preloadedState = extractQuestionFormState(prefilledQuestion, true);

  return (
    <div className={classes.formContainer}>
      <QuestionFormProvider preloadedState={preloadedState}>
        <Form
          hideStandards
          submitLoading={loading}
          handleSubmit={handleSubmit}
          defaultKeepExisting
          triggerSubmit={triggerCreateFormSubmit}
          hideSubmitButton
        />
      </QuestionFormProvider>
    </div>
  );
}
