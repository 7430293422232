import { Info, OpenInNewRounded } from '@mui/icons-material';
import {
  Card,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import type { QuestionIndexAttributesFragment } from '../../../../../gql/fragments/__generated__/question.generated';
import { OwnershipEnum } from '../../../../../gql/types';
import { isRichText } from '../../../../../utils/question';
import { CustomChip } from '../../../../shared/CustomChip';
import { CustomTooltip } from '../../../../shared/CustomTooltip';
import { QuillDeltaAsHtml } from '../../../../shared/QuillDeltaAsHtml';
import { QuestionsSearchContext } from '../../../Questions/Search/context/QuestionsSearchContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  questionCard: {
    flexBasis: '100%',
    [theme.breakpoints.up('sm')]: {
      flexBasis: '45%',
    },
    margin: theme.spacing(1),
    padding: 5,
  },
  upperContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px 9px 9px',
  },
  innerContainer: {
    padding: '5px 9px 9px',
  },
  questionText: {
    height: 50,
    lineClamp: 2,
    overflow: 'hidden',
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflowWrap: 'break-word',
    maxWidth: '100%',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    marginBottom: 9,
  },
  standardChips: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: '90%',
    overflow: 'hidden',
  },
  chip: {
    maxWidth: '100%',
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  chipAndVariationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  variation: {
    marginTop: theme.spacing(1),
    width: 25,
    height: 25,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    textAlign: 'center',
    paddingTop: 2,
  },
  icon: {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    backgroundColor: theme.palette.mint.main,

    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.mint.main,
      opacity: '0.7',
    },
  },
}));

type ExistingQuestionCardProps = {
  question: QuestionIndexAttributesFragment;
  allQuestionsChecked: boolean;
  onChangeQuestionList: (selectedQuestion: string, checked: boolean) => void;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<string>>;
};

export function ExistingQuestionCard({
  question,
  setSelectedQuestionId,
  onChangeQuestionList,
  allQuestionsChecked,
}: ExistingQuestionCardProps) {
  const classes = useStyles();
  const [questionChecked, setQuestionChecked] =
    useState<boolean>(allQuestionsChecked);
  const { questionsSearchParams } = useContext(QuestionsSearchContext);
  const selectQuestion = (checked: boolean) => {
    onChangeQuestionList(question.id, checked);
  };

  useEffect(() => {
    setQuestionChecked(allQuestionsChecked);
    selectQuestion(allQuestionsChecked);
    //reason: useEffect gets triggered by selectQuestion on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQuestionsChecked]);
  const ownQuestion = questionsSearchParams.ownership === OwnershipEnum.Own;

  const { richText } = question;

  return (
    <Card className={classes.questionCard} key={`question-${question.id}`}>
      <div className={classes.upperContainer}>
        <div className={classes.actionContainer}>
          <Checkbox
            checked={questionChecked}
            onChange={(e) => {
              setQuestionChecked(e.target.checked);
              selectQuestion(e.target.checked);
            }}
          />
          {!ownQuestion && (
            <Tooltip title="Because this question was created by another teacher, clicking this button will first make a copy of this question for your course and then add that copy to this assignment.">
              <Info
                color="secondary"
                className={classes.icon}
                fontSize="small"
              />
            </Tooltip>
          )}
        </div>
        <div>
          {/* there is some sort of race condition when question is evicted from the cache after delete that this tries to render the deleted question: */}
          {isRichText(richText) && richText.ops.length > 0 && (
            <CustomTooltip
              placement="top-start"
              title={<QuillDeltaAsHtml delta={richText.ops} />}
            >
              <IconButton
                className={classes.iconButton}
                size="small"
                onClick={() => setSelectedQuestionId(question.id)}
              >
                <OpenInNewRounded color="primary" />
              </IconButton>
            </CustomTooltip>
          )}
        </div>
      </div>
      <div className={classes.innerContainer}>
        <Typography
          color="primary"
          className={classes.questionText}
          variant="h4"
        >
          {question.plainText}
        </Typography>
        <CustomChip label={question.questionType} />
        <div className={classes.chipAndVariationContainer}>
          <div className={classes.standardChips}>
            {question.standards &&
              question.standards.map(({ title, description, id }) => (
                <CustomChip
                  className={classes.chip}
                  label={title}
                  tooltip={description}
                  key={`standard-${id}`}
                />
              ))}
          </div>
          {question.variations?.length !== undefined && (
            <Tooltip
              title={`This question has ${question.variations.length} variation(s).`}
            >
              <div className={classes.variation}>
                {question.variations.length}
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </Card>
  );
}
