/**
 * Coerce the given `err` into an {@link Error}. If `err` is already an instance
 * of the {@link Error} class, it is returned as is. Otherwise, the `err` is
 * stringified as the message of a new {@link Error}.
 *
 * @example
 * function throwTheAnswer() { throw 42 }
 *
 * try {
 *   throwTheAnswer()
 * } catch (someError) {
 *   asError(someError) // new Error("42")
 * }
 *
 * @param {unknown} err
 * @returns {Error}
 */
export function asError(err) {
  return err instanceof Error ? err : new Error(String(err));
}
