import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TeachersQueryVariables = Types.Exact<{
  filter: Types.Scalars['String']['input'];
}>;

export type TeachersQuery = {
  __typename?: 'Query';
  teachers: Array<{
    __typename?: 'Teacher';
    id: string;
    firstName?: string | null;
    fullName?: string | null;
    sortName?: string | null;
    lastName?: string | null;
    email: string;
    school?: { __typename?: 'School'; id: string; name: string } | null;
  }>;
};

export type TeacherLightQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TeacherLightQuery = {
  __typename?: 'Query';
  teacher: {
    __typename?: 'Teacher';
    id: string;
    uuid: string;
    email: string;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    createdAt: any;
    acceptedTosAt?: any | null;
    activeCourse?: {
      __typename?: 'Course';
      id: string;
      level: {
        __typename?: 'Level';
        id: string;
        name: string;
        visibility: Types.VisibilityEnum;
      };
      subject: { __typename?: 'Subject'; id: string; name: string };
    } | null;
    school?: { __typename?: 'School'; name: string; id: string } | null;
  };
};

export type TeacherCoursesQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type TeacherCoursesQuery = {
  __typename?: 'Query';
  teacher: {
    __typename?: 'Teacher';
    id: string;
    coursesTeachers: Array<{
      __typename?: 'CoursesTeacher';
      id: string;
      active: boolean;
      archived: boolean;
      createdAt: any;
      course: {
        __typename?: 'Course';
        id: string;
        name: string;
        createdAt: any;
        standardsChart?: {
          __typename?: 'StandardsChart';
          title: string;
        } | null;
        level: { __typename?: 'Level'; name: string };
        jurisdiction?: {
          __typename?: 'Jurisdiction';
          id: string;
          title: string;
          countryCode: string;
        } | null;
        subject: { __typename?: 'Subject'; name: string };
      };
    }>;
  };
};

export type TeacherQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TeacherQuery = {
  __typename?: 'Query';
  teacher: {
    __typename?: 'Teacher';
    acceptedPrivacyAt?: any | null;
    acceptedTosAt?: any | null;
    id: string;
    email: string;
    school?: {
      __typename?: 'School';
      id: string;
      timezone: string;
      country: string;
      name: string;
    } | null;
    activeCourse?: {
      __typename?: 'Course';
      name: string;
      id: string;
      groups: Array<{
        __typename?: 'Group';
        numEnrollments: number;
        id: string;
        name: string;
        enableRelaxedSpacing: boolean;
        enableImmediatelyDue: boolean;
        freezePersonalDeck: boolean;
        freezeDate?: any | null;
        unfreezeInProgress: boolean;
        numPersonalDecksQuestions: number;
        numShifted: number;
        receivesEmails: boolean;
        studySessionMax: number;
      }>;
      team?: {
        __typename?: 'Team';
        id: string;
        groupCode: string;
        teachers: Array<{
          __typename?: 'Teacher';
          id: string;
          firstName?: string | null;
          fullName?: string | null;
          sortName?: string | null;
          lastName?: string | null;
          email: string;
          school?: { __typename?: 'School'; id: string; name: string } | null;
        }>;
      } | null;
      standardsChart?: {
        __typename?: 'StandardsChart';
        groupCode?: string | null;
        title: string;
        id: string;
      } | null;
    } | null;
    assignments: Array<{ __typename?: 'Assignment'; id: string }>;
    standardsCharts?: Array<{
      __typename?: 'StandardsChart';
      id: string;
      title: string;
    }> | null;
  };
};

export type TeacherSettingsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type TeacherSettingsQuery = {
  __typename?: 'Query';
  teacher: {
    __typename?: 'Teacher';
    school?: {
      __typename?: 'School';
      id: string;
      name: string;
      address: string;
      city: string;
      zip?: string | null;
      stateCode?: string | null;
      timezone: string;
      country: string;
    } | null;
    activeCourse?: {
      __typename?: 'Course';
      id: string;
      recurring0: boolean;
      recurring1: boolean;
      recurring2: boolean;
      recurring3: boolean;
      recurring4: boolean;
      recurring5: boolean;
      recurring6: boolean;
      recurringLateAssignmentNotification: boolean;
      recurringPdqNotification: boolean;
      assignmentAlmostDueNotification: boolean;
      leaderboardSetting: Types.LeaderboardSettingEnum;
      studentFacingAiEnabled: boolean;
      groups: Array<{
        __typename?: 'Group';
        id: string;
        name: string;
        enableRelaxedSpacing: boolean;
        enableImmediatelyDue: boolean;
        freezePersonalDeck: boolean;
        freezeDate?: any | null;
        unfreezeInProgress: boolean;
        numPersonalDecksQuestions: number;
        numShifted: number;
        receivesEmails: boolean;
        studySessionMax: number;
      }>;
    } | null;
  };
};

export const TeachersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teachers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teachers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'teacherLightAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'teacherLightAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Teacher' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeachersQuery, TeachersQueryVariables>;
export const TeacherLightDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teacherLight' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teacher' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptedTosAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeCourse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'level' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'visibility' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'subject' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeacherLightQuery, TeacherLightQueryVariables>;
export const TeacherCoursesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teacherCourses' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teacher' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'coursesTeachers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'active' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'archived' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'course' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'standardsChart' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'level' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'jurisdiction' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'countryCode',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'subject' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeacherCoursesQuery, TeacherCoursesQueryVariables>;
export const TeacherDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teacher' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teacher' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptedPrivacyAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptedTosAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timezone' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeCourse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'groupSettings' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'numEnrollments' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'team' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'groupCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'teachers' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: {
                                      kind: 'Name',
                                      value: 'teacherLightAttributes',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'standardsChart' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'groupCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'title' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'standardsCharts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'groupSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableRelaxedSpacing' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableImmediatelyDue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freezePersonalDeck' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'freezeDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unfreezeInProgress' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numPersonalDecksQuestions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numShifted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivesEmails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studySessionMax' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'teacherLightAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Teacher' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TeacherQuery, TeacherQueryVariables>;
export const TeacherSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teacherSettings' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teacher' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'school' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'schoolAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeCourse' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring0' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring1' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring2' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring3' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring4' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring5' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'recurring6' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'recurringLateAssignmentNotification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'recurringPdqNotification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'assignmentAlmostDueNotification',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'leaderboardSetting' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groups' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'groupSettings' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'studentFacingAiEnabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'schoolAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'School' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'city' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'timezone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'groupSettings' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Group' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableRelaxedSpacing' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enableImmediatelyDue' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freezePersonalDeck' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'freezeDate' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unfreezeInProgress' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numPersonalDecksQuestions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numShifted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'receivesEmails' } },
          { kind: 'Field', name: { kind: 'Name', value: 'studySessionMax' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TeacherSettingsQuery,
  TeacherSettingsQueryVariables
>;
