import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext } from 'react';
import { AlertsContext } from './context';
import { closeConfirmation } from './context/actions';

export function AlertsConfirmation() {
  const {
    alerts: { confirmation },
    dispatch,
  } = useContext(AlertsContext);
  const {
    message,
    maxWidth = 'lg',
    confirmButtonText = 'OK',
    confirmFunc,
    cancelFunc = () => console.log('Action cancelled.'),
  } = confirmation;

  const handleClose = () => {
    cancelFunc();
    dispatch(closeConfirmation());
  };

  const handleConfirm = () => {
    confirmFunc();
    dispatch(closeConfirmation());
  };

  return (
    <Dialog
      maxWidth={maxWidth}
      disableScrollLock
      fullWidth={true}
      open={!!message}
      onClose={handleClose}
    >
      <DialogTitle>Confirm</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertsConfirmation;
