import { useMutation, useQuery } from '@apollo/client';
import { Check, Close, Delete, Edit } from '@mui/icons-material';
import { IconButton, TextField, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import type { StandardsCategoryAttributesFragment } from '../../../../gql/fragments/__generated__/standard.generated';
import {
  DeleteStandardsCategoryDocument,
  EditStandardsCategoryDocument,
} from '../../../../gql/mutations/__generated__/standard.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { openConfirmation } from '../../Alerts/context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1.75),
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  textField: {},
  iconButton: {
    color: theme.palette.secondary.main,
    padding: 4,
    '&:hover': {
      opacity: 0.8,
    },
  },
  icon: {
    width: 20,
  },
  closeIcon: {
    width: 20,
    color: theme.palette.error.dark,
  },
  checkIcon: {
    width: 20,
    color: theme.palette.secondary.main,
  },
  editField: {
    marginRight: theme.spacing(1),
  },
}));

type CategoryUpdaterProps = {
  category: StandardsCategoryAttributesFragment;
  amAdmin: boolean;
  refetchData: () => void;
};

export function CategoryUpdater({
  category,
  amAdmin,
  refetchData,
}: CategoryUpdaterProps) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [editedCategory, setEditedCategory] = useState(category.category);
  const { data: teacherData } = useQuery(TeacherDocument);
  const { dispatch } = useContext(AlertsContext);
  const standardsChartId =
    teacherData?.teacher.activeCourse?.standardsChart?.id || '';
  const [deleteCategory] = useMutation(DeleteStandardsCategoryDocument, {
    onError: onError(dispatch),
    variables: { standardsCategoryId: category.id },
    update: () => {
      refetchData();
    },
  });
  const [editCategory] = useMutation(EditStandardsCategoryDocument, {
    variables: {
      category: editedCategory,
      standardsCategoryId: category.id,
    },
    onCompleted: () => setEditMode(false),
    onError: onError(dispatch),
    update: () => {
      refetchData();
    },
  });

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(
      openConfirmation({
        message: (
          <div>
            <p>
              Are you sure you want to delete{' '}
              <strong>{category.category}</strong>?
            </p>
            <p>
              This would delete the category and ALL of the standards in the
              category. This <strong>CANNOT</strong> be undone.
            </p>
          </div>
        ),
        confirmButtonText: 'Confirm',
        confirmFunc: deleteCategory,
        maxWidth: 'md',
      })
    );
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    editCategory();
  };

  const handleEdit = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEditedCategory(e.target.value);

  const enterEditMode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setEditMode(true);
  };

  const leaveEditMode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setEditMode(false);
  };

  const renderContent = () => {
    if (editMode) {
      return (
        <form onSubmit={handleSubmit}>
          <TextField
            required
            InputProps={{
              className: classes.textField,
            }}
            value={editedCategory}
            className={classes.editField}
            onChange={handleEdit}
            onFocus={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          />
          <IconButton
            className={classes.iconButton}
            onClick={leaveEditMode}
            onFocus={(e) => e.stopPropagation()}
            size="large"
          >
            <Close className={classes.closeIcon} />
          </IconButton>
          <IconButton
            className={classes.iconButton}
            type="submit"
            onClick={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
            size="large"
          >
            <Check className={classes.checkIcon} />
          </IconButton>
        </form>
      );
    }

    return (
      <div className={classes.editContainer}>
        <Typography variant="h6" className={classes.title}>
          {category.category}
        </Typography>
        {amAdmin && (
          <IconButton
            onClick={enterEditMode}
            onFocus={(e) => e.stopPropagation()}
            aria-label="Update Category"
            className={classes.iconButton}
            size="large"
          >
            <Edit className={classes.icon} />
          </IconButton>
        )}
      </div>
    );
  };

  const deleteButton = () => {
    if (!editMode && amAdmin) {
      return (
        <IconButton
          aria-label="Delete Category"
          onClick={handleDelete}
          className={classes.iconButton}
          onFocus={(e) => e.stopPropagation()}
          size="large"
        >
          <Delete className={classes.icon} />
        </IconButton>
      );
    }
  };

  return (
    <div className={classes.root}>
      {renderContent()}
      {deleteButton()}
    </div>
  );
}

export default CategoryUpdater;
