import { useMutation, useQuery } from '@apollo/client';
import { Archive, Edit, FileCopy } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  LinearProgress,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { AlertsContext } from '../../components/application/Alerts/context';
import { openConfirmation } from '../../components/application/Alerts/context/actions';
import { CreateCourseModal } from '../../components/application/Setup/CourseSetup/CreateCourseModal';
import { EditCourseModal } from '../../components/application/Setup/CourseSetup/EditCourseModal';
import {
  CreateFromOldCourseDocument,
  UpdateCourseDocument,
} from '../../gql/mutations/__generated__/course.generated';
import {
  TeacherCoursesDocument,
  type TeacherCoursesQuery,
} from '../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../utils/apollo/apolloHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    minWidth: '80%',
    marginTop: theme.spacing(3),
  },
  setActive: {
    marginLeft: theme.spacing(1),
  },
  addCourse: {
    alignSelf: 'flex-end',
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  chip: {
    marginRight: theme.spacing(2),
  },
  listItem: {
    display: 'flex',
  },
  optionsButton: {
    color: theme.palette.common.white,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    paddingTop: '4px',
  },
  loadingContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function CustomMenu({
  coursesTeacher,
  handleEditCourse,
  copyCourseFromOld,
  handleArchive,
}: {
  coursesTeacher: TeacherCoursesQuery['teacher']['coursesTeachers'][0];
  handleEditCourse: (
    ct: TeacherCoursesQuery['teacher']['coursesTeachers'][0] | undefined
  ) => void;
  copyCourseFromOld: (id: string) => () => void;
  handleArchive: (courseId: string) => void;
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const archive = (courseId: string) => () => handleArchive(courseId);

  const open = Boolean(anchorEl);
  return (
    <>
      <Button
        aria-label="course options"
        aria-controls="course-options"
        aria-haspopup="true"
        onClick={handleMenu}
        variant="contained"
        size="small"
        color="secondary"
        className={classes.optionsButton}
      >
        Options
      </Button>
      <Menu
        id={`menu-courses-teacher-${coursesTeacher.id}`}
        key={`menu-courses-teacher-${coursesTeacher.id}`}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          key={coursesTeacher.id}
          onClick={() => handleEditCourse(coursesTeacher)}
        >
          <div className={classes.container}>
            <span className={classes.icon}>
              <Edit />
            </span>
            <span>Edit</span>
          </div>
        </MenuItem>
        <MenuItem onClick={copyCourseFromOld(coursesTeacher.course.id)}>
          <div className={classes.container}>
            <span className={classes.icon}>
              <FileCopy />
            </span>
            <span>Copy</span>
          </div>
        </MenuItem>
        <Tooltip
          title={
            coursesTeacher.active ? 'Active courses cannot be archived.' : ''
          }
        >
          <span>
            <MenuItem
              disabled={coursesTeacher.active}
              onClick={archive(coursesTeacher.course.id)}
            >
              <div className={classes.container}>
                <span className={classes.icon}>
                  <Archive />
                </span>
                <span>Archive</span>
              </div>
            </MenuItem>
          </span>
        </Tooltip>
      </Menu>
    </>
  );
}

export function CourseListActive() {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const [openCreateCourse, setOpenCreateCourse] = useState(false);

  const [selectedCoursesTeacher, setCoursesTeacher] = useState<
    undefined | TeacherCoursesQuery['teacher']['coursesTeachers'][0]
  >();

  const [createCourseFromOld, { loading }] = useMutation(
    CreateFromOldCourseDocument,
    {
      onCompleted: () => window.location.reload(),
      onError: onError(dispatch, true),
    }
  );

  const { data, refetch } = useQuery(TeacherCoursesDocument, {
    onError: onError(dispatch),
  });

  const [updateCourse] = useMutation(UpdateCourseDocument, {
    onError: onError(dispatch),
    // Refetch teacher courses if we successfully updated a course.
    refetchQueries: ({ data }) => (data ? [TeacherCoursesDocument] : []),
  });

  const handleArchive = (courseId: string) => {
    updateCourse({
      variables: {
        courseId,
        archived: true,
      },
    });
  };

  const copyCourseFromOld = (courseId: string) => {
    return () => {
      dispatch(
        openConfirmation({
          message: (
            <div>
              <p>Are you sure you want to make a copy of this course?</p>
              <p>
                Doing this will create a fresh copy of the existing course,
                along with the same standards chart and a copy of all
                un-archived assignments and questions. Classes and students WILL
                NOT be copied over.
              </p>
              <p>
                <strong>
                  Please Note: Copying over the assignments and questions could
                  potentially take several minutes. If the assignments and
                  questions are still not showing up in the new course after 10
                  minutes, please reach out to hello@podsie.org.
                </strong>
              </p>
            </div>
          ),
          confirmFunc: () =>
            createCourseFromOld({
              variables: {
                courseId: parseInt(courseId, 10),
              },
            }),
          confirmButtonText: 'Copy Course',
        })
      );
    };
  };

  const handleCreateCourse = () => {
    setOpenCreateCourse(true);
  };

  const handleEditCourse = (
    coursesTeacher:
      | TeacherCoursesQuery['teacher']['coursesTeachers'][0]
      | undefined
  ) => {
    if (coursesTeacher) {
      setCoursesTeacher(coursesTeacher);
    }
  };

  const closeEditCourse = () => setCoursesTeacher(undefined);

  const handleSetCourseActive = (courseId: string) => {
    updateCourse({
      variables: {
        courseId: courseId,
        active: true,
      },
    }).then((result) => {
      window.location.reload();
      return result;
    });
  };

  return (
    <div className={classes.root}>
      {data?.teacher.coursesTeachers
        .filter((coursesTeacher) => !coursesTeacher.archived)
        .sort((elem, otherElem) => {
          if (elem.id < otherElem.id) {
            return -1;
          }
          if (elem.id === otherElem.id) {
            return 0;
          }
          return 1;
        })
        .map((coursesTeacher) => {
          const course = coursesTeacher.course;
          return (
            <Card
              key={`course-list-${coursesTeacher.id}`}
              className={classes.card}
            >
              <CardContent className={classes.cardContent}>
                <ListItemText
                  primary={course.name}
                  secondary={`${course.level.name} - ${course.subject.name}`}
                />
                {coursesTeacher.active ? (
                  <Tooltip title="'Active' means the course that is currently being edited by the teacher. Even if a current course is not 'active', it is still accessible by students.">
                    <Chip
                      className={classes.chip}
                      variant="outlined"
                      color="primary"
                      size="small"
                      label="Active"
                    />
                  </Tooltip>
                ) : null}
                <CardActions>
                  <CustomMenu
                    coursesTeacher={coursesTeacher}
                    handleEditCourse={handleEditCourse}
                    handleArchive={handleArchive}
                    copyCourseFromOld={copyCourseFromOld}
                  />
                  <Button
                    disabled={coursesTeacher.active}
                    className={classes.setActive}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => handleSetCourseActive(course.id)}
                  >
                    Set Active
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          );
        })}
      <Button
        disabled={loading}
        className={classes.addCourse}
        variant="contained"
        size="large"
        color="secondary"
        startIcon={<AddIcon />}
        onClick={handleCreateCourse}
      >
        Add Course
      </Button>
      <CreateCourseModal
        open={openCreateCourse}
        setOpenCreateCourse={setOpenCreateCourse}
      />
      {selectedCoursesTeacher && (
        <EditCourseModal
          coursesTeacher={selectedCoursesTeacher}
          refetch={refetch}
          handleModalClose={closeEditCourse}
        />
      )}
      {loading && (
        <div className={classes.loadingContainer}>
          <LinearProgress />
          <span>Creating new course...</span>
        </div>
      )}
    </div>
  );
}
