import type { ActionItemQuery } from '../../../../gql/queries/__generated__/actionItem.generated';
import { HandleFlaggedFreeResponse } from './HandleFlaggedFreeResponse';
import { HandleFlaggedShortAnswerResponse } from './HandleFlaggedShortAnswerResponse';

export type HandleFlaggedResponseProps = {
  response: ActionItemQuery['actionItem']['actionable'];
  actionItemId: string;
};

export function HandleFlaggedResponse({
  response,
  actionItemId,
}: HandleFlaggedResponseProps) {
  switch (response.response.__typename) {
    case 'FreeResponseResponse':
      return (
        <HandleFlaggedFreeResponse
          response={response}
          actionItemId={actionItemId}
        />
      );

    case 'ShortAnswerResponse':
      return (
        <HandleFlaggedShortAnswerResponse
          actionItemId={actionItemId}
          response={response}
        />
      );

    default:
      console.debug(
        `Given a flagged response for unsupported question type: `,
        response
      );
      return null;
  }
}
