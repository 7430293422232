import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import mathquill4quill from 'mathquill4quill';
import { StringMap } from 'quill';
import React, { useEffect, useRef } from 'react';
import ReactQuill, { Quill, ReactQuillProps, Value } from 'react-quill';

const useStyles = makeStyles((theme: Theme) => ({
  editorContainer: { minWidth: '150px' },
  errorContainer: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.spacing(0.5),
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: theme.spacing(1.75),
    margin: `${theme.spacing(0.5)} ${theme.spacing(1.75)}`,
  },
}));

type EditorProps = {
  existingValue?: Value;
  disableImages?: boolean;
  // have to name it something other than `ref` since `ref` is already
  // taken as a prop by ReactQuillProps and would cause issues:
  customRef?: React.RefObject<ReactQuill>;
  modules?: StringMap;
  errorMessage?: string;
};

const formatsWithoutMedia = [
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula',
  // 'image'
  // 'video'
];

export function Editor(props: EditorProps & ReactQuillProps) {
  const classes = useStyles();
  const internalRef = useRef<ReactQuill>(null);
  const qRef = props.customRef || internalRef;

  useEffect(() => {
    if (!qRef?.current?.editor) {
      return;
    }
    const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill });
    enableMathQuillFormulaAuthoring(qRef.current.editor, {
      // show fractions, pi, delta, radical, and integral:
      operators: [
        ['\\frac{x}{y}', '\\frac'],
        ['{x}^{2}', 'x^{}'],
        ['{x}_{2}', 'x_{}'],
        ['\\sqrt{x}', '\\sqrt'],
        ['\\int_{x}^{y}', '\\int'],
        ['\\pm', '\\pm'],
        ['\\pi', '\\pi'],
        ['\\infty', '\\infty'],
        ['\\circ', '\\circ'],
        ['<', '<'],
        ['>', '>'],
        ['\\le', '\\le'],
        ['\\ge', '\\ge'],
        ['|x|', '|{x}|'],
        ['(x)', '({x})'],
        ['[x]', '[{x}]'],
        ['\\Delta', '\\Delta'],
      ],
    });
  }, [qRef]);

  const lastModule = ['blockquote', 'code-block', 'formula'];
  const defaultModules = {
    formula: true,
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      lastModule,
    ],
  };

  if (!props.disableImages) {
    lastModule.push('image');
  }

  return (
    <div className={classes.editorContainer}>
      <div
        className={props?.errorMessage?.length ? classes.errorContainer : ''}
      >
        <ReactQuill
          style={props.style}
          value={props.value}
          onChange={props.onChange}
          ref={qRef}
          modules={props.modules || defaultModules}
          theme={props.theme || 'snow'}
          // be default all formats are allowed (if formats is undefined):
          formats={props.disableImages ? formatsWithoutMedia : undefined}
          defaultValue={props.existingValue || props.defaultValue}
          placeholder={props.placeholder}
        />
      </div>
      {props?.errorMessage?.length ? (
        <span
          className={props?.errorMessage?.length ? classes.errorMessage : ''}
        >
          {props.errorMessage}
        </span>
      ) : null}
    </div>
  );
}

export default Editor;
