import { useQuery } from '@apollo/client';
import { Card, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { AssignmentsDocument } from '../../../../gql/queries/__generated__/assignment.generated';
import {
  AssignmentStatusEnum,
  AssignmentTypeEnum,
} from '../../../../gql/types';
import { determineStatuses } from '../../../../utils/assignmentHelper';
import { dateFormat } from '../../../../utils/dates';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import AssignmentAction from './AssignmentAction';
import AssignmentLaunchState from './AssignmentLaunchState';
import AssignmentStatus from './AssignmentStatus';
import AssignmentsIndexInfo from './AssignmentsIndexInfo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
  },
  assignmentCard: {
    maxWidth: '100%',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '60vw',
  },
  assignmentType: {
    color: theme.palette.grays.dark,
  },
  leftContainer: {
    maxWidth: '50%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '70%',
    },
  },
  rightContainer: {
    maxWidth: '50%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '30%',
    },
  },
  statusAndActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  link: {
    '&:hover': {
      textDecorationColor: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  noResultsMessage: {
    marginTop: theme.spacing(5),
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: '1.2rem',
  },
  filtersMessage: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
}));

type AssignmentsIndexProps = {
  assignmentStatus: AssignmentStatusEnum;
  searchValue: string;
};

export const ASSIGNMENTS_INDEX_PAGINATION_COUNT = 12;

const simplify = (str: string) => {
  switch (str) {
    case 'active':
      return 'active';
    case 'pending':
      return 'draft';
    case 'past_due':
      return 'past due';
    default:
      return str;
  }
};

export function AssignmentsIndex({
  assignmentStatus,
  searchValue,
}: AssignmentsIndexProps) {
  const classes = useStyles();
  const statuses = determineStatuses(assignmentStatus);
  const {
    data: assignmentsData,
    loading,
    fetchMore,
  } = useQuery(AssignmentsDocument, {
    variables: {
      filter: searchValue,
      first: ASSIGNMENTS_INDEX_PAGINATION_COUNT,
      statuses,
    },
    fetchPolicy: 'cache-and-network',
  });
  const paginatedResults = assignmentsData?.assignments.edges || [];
  const totalAssignments = assignmentsData?.assignments.totalCount || 0;
  const fetchNextBatch = () => {
    fetchMore({
      variables: {
        first: ASSIGNMENTS_INDEX_PAGINATION_COUNT,
        after: assignmentsData?.assignments.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        fetchMoreResult.assignments.edges = [
          ...(previousResult?.assignments.edges || []),
          ...(fetchMoreResult.assignments.edges || []),
        ];
        return fetchMoreResult;
      },
    });
  };

  if (!loading && totalAssignments === 0) {
    return (
      <div className={classes.root}>
        <Typography className={classes.noResultsMessage} variant="h5">
          {`No ${simplify(assignmentStatus)} assignments found`}
        </Typography>
        <Typography className={classes.filtersMessage}>
          (Please make sure you have the correct assignment status filter
          selected. Status filter is located on the top right of this page.)
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <InfiniteScroll
        scrollThreshold={0.7}
        dataLength={paginatedResults.length}
        next={fetchNextBatch}
        hasMore={paginatedResults.length < totalAssignments}
        scrollableTarget="assignments-index-scroll"
        loader={loading ? <Typography>Loading...</Typography> : null}
      >
        {loading ? (
          <LoadingSkeletons num={28} />
        ) : (
          paginatedResults.map((edge) => {
            const assignment = edge?.node;
            if (!assignment) return null;
            const assignmentName = assignment.name;
            const assignmentType =
              assignment.assignmentType === AssignmentTypeEnum.Standard
                ? 'Standard'
                : 'Personal Deck';
            return (
              <Card
                key={`${assignment.id}-assignment-id-key`}
                className={classes.assignmentCard}
              >
                <div className={classes.leftContainer}>
                  <Typography
                    variant="caption"
                    className={classes.assignmentType}
                  >
                    {assignmentType}
                  </Typography>
                  <Link
                    to={`/assignments/${assignment.id}`}
                    className={classes.link}
                  >
                    <Typography variant="h2" className={classes.title}>
                      {assignmentName}
                    </Typography>
                  </Link>
                  <AssignmentsIndexInfo assignment={assignment} />
                </div>
                <div className={classes.rightContainer}>
                  <div className={classes.statusAndActionContainer}>
                    <AssignmentStatus
                      assignmentStatus={assignment.assignmentStatus}
                    />
                    <AssignmentLaunchState assignment={assignment} />
                    <AssignmentAction
                      assignmentId={assignment.id}
                      assignmentName={assignment.name}
                    />
                  </div>
                  <Typography variant="body2">
                    {`Last updated on ${format(
                      new Date(assignment.updatedAt),
                      dateFormat
                    )}`}
                  </Typography>
                </div>
              </Card>
            );
          })
        )}
      </InfiniteScroll>
    </div>
  );
}

export default AssignmentsIndex;
