import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ShortAnswerVariationsQueryVariables = Types.Exact<{
  currentAnswers:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
  question: Types.Scalars['String']['input'];
}>;

export type ShortAnswerVariationsQuery = {
  __typename?: 'Query';
  shortAnswerVariations: Array<any>;
};

export const ShortAnswerVariationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'shortAnswerVariations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'currentAnswers' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'question' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerVariations' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentAnswers' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'currentAnswers' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'question' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ShortAnswerVariationsQuery,
  ShortAnswerVariationsQueryVariables
>;
