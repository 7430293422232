import { useMutation, useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  LinearProgress,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useContext, useState } from 'react';
import { GenerateQuestionVariationDocument } from '../../../../../../gql/mutations/__generated__/question.generated';
import { GetUpdateQuestionDocument } from '../../../../../../gql/queries/__generated__/question.generated';
import { onError } from '../../../../../../utils/apollo/apolloHelper';
import { containsImages } from '../../../../../../utils/quillHelper';
import { HeaderBackButton } from '../../../../../shared/HeaderBackButton';
import { SideDrawer } from '../../../../../shared/Layout/SideDrawer';
import { AlertsContext } from '../../../../Alerts/context';
import { CreateQuestion } from '../../../../Questions/Form/CreateQuestion';
import { UpdateQuestionVariation } from '../../../../Questions/Form/UpdateQuestionVariation';
import { ShowQuestionVariations } from './ShowQuestionVariations';

const useStyles = makeStyles((theme: Theme) => ({
  missingData: {
    padding: theme.spacing(3),
  },
  footerButton: {
    border: `2px solid ${theme.palette.grays.dark}`,
    borderRadius: 4,
    padding: `${theme.spacing(1)}`,
  },
  generateButton: {
    width: 275,
  },
  disabledFooterButton: {
    borderColor: theme.palette.grays.light,
  },
  inlineProgressSuffix: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  questionGenerationFunctionality: {
    marginRight: theme.spacing(2),
  },
  footer: {
    display: 'flex',
  },
  createManualVariationButton: {
    marginRight: theme.spacing(2),
  },
}));

export type UpdateAssignmentQuestionDrawerProps = {
  questionId: string;
  editingOpen: boolean;
  handleClose: () => void;
};

export function UpdateAssignmentQuestionVariationDrawer({
  questionId,
  editingOpen,
  handleClose,
}: UpdateAssignmentQuestionDrawerProps) {
  const classes = useStyles();
  const [createMode, setCreateMode] = useState(false);
  const [selectedVariation, setSelectedVariation] = useState<
    string | undefined
  >(undefined);
  const [triggerUpdateFormSubmit, setTriggerUpdateFormSubmit] = useState(false);
  const [triggerCreateFormSubmit, setTriggerCreateFormSubmit] = useState(false);
  const { dispatch } = useContext(AlertsContext);

  const { data, loading: questionQueryLoading } = useQuery(
    GetUpdateQuestionDocument,
    {
      onError: onError(dispatch),
      pollInterval: 10000,
      variables: { questionId },
    }
  );

  const fullSelectedVariation =
    selectedVariation &&
    data &&
    data?.question?.variations &&
    data.question.variations.find(
      (variation) => variation.id === selectedVariation
    );

  const [generateVariation, generateVariationMutation] = useMutation(
    GenerateQuestionVariationDocument,
    {
      refetchQueries: ['assignment', 'getUpdateQuestion'],
      variables: { baseQuestionId: questionId },
    }
  );

  function generateRequestedVariations() {
    let index = 0;
    const count = 1;
    let promise: Promise<unknown> | undefined;
    while (index < count) {
      promise = promise
        ? promise.then(() => generateVariation())
        : generateVariation();
      index += 1;
    }
  }

  const questionContainsImages =
    data && data.question && containsImages(data.question.richText);

  const isQuestionGenerationDisabled =
    (generateVariationMutation && generateVariationMutation.loading) ||
    questionContainsImages;

  const questionGenerationFunctionality = () => {
    return (
      <div className={classes.questionGenerationFunctionality}>
        <Button
          className={clsx(classes.footerButton, classes.generateButton, {
            [classes.disabledFooterButton]: isQuestionGenerationDisabled,
          })}
          disabled={isQuestionGenerationDisabled}
          onClick={generateRequestedVariations}
          startIcon={
            generateVariationMutation.loading ? (
              <CircularProgress
                className={classes.inlineProgressSuffix}
                size={16}
              />
            ) : (
              <Add />
            )
          }
        >
          <Typography color="primary" variant="h5">
            {generateVariationMutation.loading
              ? 'Generating variation...'
              : 'Generate variation'}
          </Typography>
        </Button>
      </div>
    );
  };

  const getFooterContent = () => {
    if (!selectedVariation && !createMode) {
      return (
        <div className={classes.footer}>
          {questionId && questionContainsImages ? (
            <Tooltip
              title="Your question contains images, which this feature does not support."
              placement="top-start"
            >
              <div>{questionGenerationFunctionality()}</div>
            </Tooltip>
          ) : (
            questionGenerationFunctionality()
          )}
          <Button
            className={
              createMode
                ? `${classes.footerButton} ${classes.disabledFooterButton}`
                : classes.footerButton
            }
            disabled={createMode}
            onClick={() => {
              setCreateMode(true);
            }}
            startIcon={<Add />}
          >
            <Typography variant="h5" color="primary">
              Add variation
            </Typography>
          </Button>
        </div>
      );
    }

    if (fullSelectedVariation && !createMode) {
      return (
        <div className={classes.footer}>
          <Button
            variant="contained"
            onClick={() => setTriggerUpdateFormSubmit(true)}
          >
            Update Variation
          </Button>
        </div>
      );
    }

    if (createMode) {
      return (
        <div className={classes.footer}>
          <Button
            variant="contained"
            onClick={() => setTriggerCreateFormSubmit(true)}
            className={classes.createManualVariationButton}
          >
            Create Variation
          </Button>
          <Button
            onClick={() => {
              setCreateMode(false);
              setSelectedVariation(undefined);
            }}
            className={classes.footerButton}
          >
            Cancel
          </Button>
        </div>
      );
    }

    return null;
  };

  const getHeaderTitle = () => {
    if (selectedVariation && fullSelectedVariation) {
      return 'Edit Question Variation';
    }

    if (createMode) {
      return 'Create Question Variation';
    }

    return 'Create Variations';
  };

  if (!data?.question) {
    return (
      <div className={classes.missingData}>
        <Typography>Missing Data: Question could not be found.</Typography>
      </div>
    );
  }

  return (
    <SideDrawer
      isOpen={editingOpen}
      title={getHeaderTitle()}
      onClose={handleClose}
      footerContent={getFooterContent()}
      extraHeaderContent={
        selectedVariation || createMode ? (
          <HeaderBackButton
            onClickBack={() => {
              if (selectedVariation) {
                setSelectedVariation(undefined);
              } else if (createMode) {
                setCreateMode(false);
              }
            }}
          />
        ) : null
      }
    >
      {!data && questionQueryLoading && <LinearProgress />}

      {data.question && !selectedVariation && !createMode && (
        <ShowQuestionVariations
          editingOpen={editingOpen}
          question={data.question}
          questionId={questionId}
          aiResponseLoading={
            generateVariationMutation && generateVariationMutation.loading
          }
          setSelectedVariation={setSelectedVariation}
        />
      )}

      {selectedVariation && data.question && fullSelectedVariation && (
        <UpdateQuestionVariation
          question={fullSelectedVariation}
          setTriggerUpdateFormSubmit={setTriggerUpdateFormSubmit}
          triggerUpdateFormSubmit={triggerUpdateFormSubmit}
          postUpdateCallback={() => setSelectedVariation(undefined)}
        />
      )}

      {createMode && (
        <CreateQuestion
          prefilledQuestion={data.question}
          parentQuestionId={questionId}
          handleClose={() => {
            setCreateMode(false);
          }}
          triggerCreateFormSubmit={triggerCreateFormSubmit}
          setTriggerCreateFormSubmit={setTriggerCreateFormSubmit}
        />
      )}
    </SideDrawer>
  );
}
