import { MenuItem, Select, SelectChangeEvent, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { AssignmentQuery } from '../../../../../gql/queries/__generated__/assignment.generated';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
}));

type GroupFilterProps = {
  groupsAssignments: AssignmentQuery['assignment']['groupsAssignments'];
  selectedGroupId: string;
  setSelectedGroupId: React.Dispatch<React.SetStateAction<string>>;
};

export function GroupFilter({
  groupsAssignments,
  selectedGroupId,
  setSelectedGroupId,
}: GroupFilterProps) {
  const classes = useStyles();
  const handleChange = (e: SelectChangeEvent<string>) => {
    const v = e.target.value as string;
    setSelectedGroupId(v);
  };
  return (
    <div className={classes.root}>
      <Select
        onChange={handleChange}
        variant="outlined"
        value={selectedGroupId}
      >
        <MenuItem value="all">All Classes</MenuItem>
        {groupsAssignments?.map((groupsAssignment) => {
          return (
            <MenuItem
              value={groupsAssignment.group.id}
              key={groupsAssignment.id}
            >
              {groupsAssignment.group.name}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}

export default GroupFilter;
