import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type ReviewSessionsQuestionsQueryVariables = Types.Exact<{
  courseId: Types.Scalars['ID']['input'];
}>;

export type ReviewSessionsQuestionsQuery = {
  __typename?: 'Query';
  reviewSessionsQuestions: Array<{
    __typename?: 'ReviewSessionsQuestion';
    id: string;
    question: {
      __typename?: 'Question';
      id: string;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      richText: any;
    };
  }>;
};

export type ReviewSessionsQuestionQueryVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
  courseId: Types.Scalars['ID']['input'];
}>;

export type ReviewSessionsQuestionQuery = {
  __typename?: 'Query';
  reviewSessionsQuestion?: {
    __typename?: 'ReviewSessionsQuestion';
    id: string;
  } | null;
};

export const ReviewSessionsQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'reviewSessionsQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewSessionsQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plainText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionType' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'richText' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReviewSessionsQuestionsQuery,
  ReviewSessionsQuestionsQueryVariables
>;
export const ReviewSessionsQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'reviewSessionsQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewSessionsQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReviewSessionsQuestionQuery,
  ReviewSessionsQuestionQueryVariables
>;
