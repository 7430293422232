import { useMutation, useQuery } from '@apollo/client';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { UpdateAssignmentDocument } from '../../../../../gql/mutations/__generated__/assignment.generated';
import {
  CreateEnrollmentsAssignmentDocument,
  DeleteEnrollmentsAssignmentDocument,
  UpdateEnrollmentsAssignmentDocument,
} from '../../../../../gql/mutations/__generated__/enrollmentsAssignment.generated';
import { AssignmentDocument } from '../../../../../gql/queries/__generated__/assignment.generated';
import {
  GroupDocument,
  GroupsDocument,
} from '../../../../../gql/queries/__generated__/group.generated';
import { TeacherDocument } from '../../../../../gql/queries/__generated__/teacher.generated';
import {
  AssignmentStatusEnum,
  EnrollmentStatusEnum,
} from '../../../../../gql/types';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { getTomorrowAtMidnight } from '../../../../../utils/dates';
import { AlertsContext } from '../../../Alerts/context';
import { openDialog } from '../../../Alerts/context/actions';
import { AssignmentEditorContext } from '../context';
import {
  createEnrollmentsAssignmentAction,
  deleteEnrollmentsAssignmentAction,
  onAssignmentEditorSaveSuccess,
  startAssignmentEditorLoading,
  updateAssignmentLaunchAt,
  updateEnrollmentsAssignmentAction,
} from '../context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  selectGroup: {
    display: 'flex',
    marginTop: '12px',
    marginBottom: '12px',
  },
  classFilter: {
    minWidth: 240,
  },
  studentFilter: {
    marginLeft: '20px',
    minWidth: 240,
  },
  launchDate: {
    marginRight: '36px',
  },
  timeFilters: {
    marginBottom: '24px',
  },
  listItem: {
    fontSize: theme.typography.h6.fontSize,
    marginBottom: theme.spacing(3),
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  picker: {
    marginTop: theme.spacing(2),
  },
}));

type StudentInfo = {
  name: string;
  class: string;
  enrollmentId: string;
};

const tomorrowAtMidnight = getTomorrowAtMidnight();

export function StandardAssignIndividuals() {
  // Context and state
  const classes = useStyles();
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const {
    dispatch: assignmentDispatch,
    assignmentEditor: { id: assignmentId, enrollmentsAssignments, launchAt },
  } = useContext(AssignmentEditorContext);
  const { dispatch } = useContext(AlertsContext);
  const [updateAssignment] = useMutation(UpdateAssignmentDocument, {
    onError: onError(dispatch),
    onCompleted: () => onAssignmentEditorSaveSuccess(assignmentDispatch),
  });

  // Data fetching and processing
  const { data, loading: teacherLoading } = useQuery(TeacherDocument, {
    onError: onError(dispatch),
  });
  const { data: assignmentData, loading: assignmentLoading } = useQuery(
    AssignmentDocument,
    {
      variables: { assignmentId },
      onError: onError(dispatch),
    }
  );

  if (!assignmentData && !assignmentLoading) {
    dispatch(
      openDialog({
        title: 'Error occurred.',
        message: 'Failed to load assignment data.',
        error: true,
        reloadOnClose: true,
      })
    );
  }

  // Filter the active groups
  const groups = data?.teacher.activeCourse?.groups || [];

  // Query some more data
  const { data: groupsData } = useQuery(GroupsDocument, {
    skip: groups.length === 0 || teacherLoading,
    variables: { groupIds: groups.map((elem) => elem.id) },
  });

  const { data: groupData } = useQuery(GroupDocument, {
    skip: selectedGroupId === '',
    variables: { groupId: selectedGroupId },
  });

  const [createEnrollmentsAssignment] = useMutation(
    CreateEnrollmentsAssignmentDocument,
    {
      onError: onError(dispatch),
      onCompleted: (data) => {
        const response = data.createEnrollmentsAssignment;
        assignmentDispatch(
          createEnrollmentsAssignmentAction({
            enrollmentsAssignmentId: response.id,
            enrollmentId: response.enrollment.id,
            dueDate: new Date(response.dueDate),
            studentName: response.enrollment.student.fullName,
            studentEmail: response.enrollment.student.email,
            class: response.enrollment.group?.name ?? '',
          })
        );
        onAssignmentEditorSaveSuccess(assignmentDispatch);
      },
    }
  );

  const [updateEnrollmentsAssignment] = useMutation(
    UpdateEnrollmentsAssignmentDocument,
    {
      onError: onError(dispatch),
      onCompleted: (data) => {
        const response = data.updateEnrollmentsAssignment;
        assignmentDispatch(
          updateEnrollmentsAssignmentAction({
            enrollmentId: response.enrollment.id,
            dueDate: new Date(response.dueDate),
          })
        );
        onAssignmentEditorSaveSuccess(assignmentDispatch);
      },
    }
  );

  const [deleteEnrollmentsAssignment] = useMutation(
    DeleteEnrollmentsAssignmentDocument,
    {
      onError: onError(dispatch),
      onCompleted: (data) => {
        const response = data.deleteEnrollmentsAssignment;
        assignmentDispatch(
          deleteEnrollmentsAssignmentAction({
            enrollmentId: response.enrollment.id,
          })
        );
        onAssignmentEditorSaveSuccess(assignmentDispatch);
      },
    }
  );

  // Due date logic
  const [dueDate, setDueDate] = useState(tomorrowAtMidnight);

  useEffect(() => {
    if (dueDate.getTime() !== tomorrowAtMidnight.getTime()) {
      // if due date has already been set to match groups assignment, return:
      return;
    }
    if (assignmentData?.assignment.enrollmentsAssignments) {
      assignmentData.assignment.enrollmentsAssignments.some((ea) => {
        if (ea.dueDate) {
          // set due date to match groups assignments due date
          setDueDate(new Date(ea.dueDate));
          return true;
        }
        return false;
      });
    }
  }, [assignmentData, dueDate]);

  const debouncedUpdateEnrollmentsAssignment = useDebouncedCallback(() => {
    Object.keys(enrollmentsAssignments).forEach((enrollmentId) => {
      const enrollmentsAssignmentId = enrollmentsAssignments[enrollmentId].id;
      updateEnrollmentsAssignment({
        variables: {
          enrollmentsAssignmentId: enrollmentsAssignmentId,
          dueDate: dueDate.toISOString(),
        },
      });
    });
  }, 750);

  const debouncedUpdateAssignmentLaunchAt = useDebouncedCallback(
    (updatedDateTime: Date | null) => {
      updateAssignment({
        variables: {
          assignmentId: assignmentId,
          launchAt: updatedDateTime || null,
        },
      });
      startAssignmentEditorLoading(assignmentDispatch);
    },
    750
  );

  const handleLaunchAtDateChange = (launchAt: Date | null) => {
    assignmentDispatch(updateAssignmentLaunchAt({ launchAt }));
    debouncedUpdateAssignmentLaunchAt(launchAt);
  };

  const handleDueDateChange = (dueDate: Date | null) => {
    if (!dueDate) {
      return;
    }
    setDueDate(dueDate);

    if (isNaN(dueDate.getTime())) {
      return;
    }
    startAssignmentEditorLoading(assignmentDispatch);
    debouncedUpdateEnrollmentsAssignment();
  };

  // Figure out which students have been selected
  const selectedStudents: StudentInfo[] = [];
  for (const v of Object.values(enrollmentsAssignments)) {
    selectedStudents.push({
      name: v.studentName
        ? v.studentName
        : `No Name - ${v.studentEmail} (${v.class})`,
      enrollmentId: v.enrollmentId,
      class: v.class,
    });
  }

  // Figure out the pool of students the teacher can filter from.
  // If they've selected a class, then it's narrowed down to a single group
  const allStudents: StudentInfo[] = [];
  if (selectedGroupId === '') {
    groupsData?.groups.forEach((group) => {
      group.enrollments?.forEach((enrollment) => {
        if (enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled) {
          allStudents.push({
            name: enrollment.student.fullName
              ? enrollment.student.fullName
              : `No Name - ${enrollment.student.email} (${group.name})`,
            class: group.name,
            enrollmentId: enrollment.id,
          });
        }
      });
    });
  } else {
    groupData?.group?.enrollments?.forEach((enrollment) => {
      if (enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled) {
        allStudents.push({
          name: enrollment.student.fullName
            ? enrollment.student.fullName
            : `No Name - ${enrollment.student.email} (${groupData.group.name})`,
          class: groupData.group.name,
          enrollmentId: enrollment.id,
        });
      }
    });
  }

  // On change handlers
  const onChangeGroup = (event: SelectChangeEvent<string>) => {
    if (event.target.value === 'All Classes') {
      setSelectedGroupId('');
    } else {
      setSelectedGroupId(event.target.value as string);
    }
  };

  const onChangeStudent = (event: unknown, value: StudentInfo[]) => {
    const enrollmentIdMap: { [key: string]: StudentInfo } = {};
    value.forEach((elem) => {
      if (elem.enrollmentId in enrollmentIdMap) {
        // we found a duplicate, so let's delete these
        deleteEnrollmentsAssignment({
          variables: {
            enrollmentsAssignmentId:
              enrollmentsAssignments[elem.enrollmentId].id,
          },
        });
        return;
      }
      enrollmentIdMap[elem.enrollmentId] = elem;
      if (!(elem.enrollmentId in enrollmentsAssignments) && assignmentData) {
        createEnrollmentsAssignment({
          variables: {
            assignmentId: assignmentData.assignment.id,
            dueDate: dueDate.toISOString(),
            enrollmentId: elem.enrollmentId,
          },
        });
      }
    });

    for (const [k, v] of Object.entries(enrollmentsAssignments)) {
      if (!(k in enrollmentIdMap)) {
        deleteEnrollmentsAssignment({
          variables: {
            enrollmentsAssignmentId: v.id,
          },
        });
      }
    }
  };

  const checkboxIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const editable =
    assignmentData?.assignment.assignmentStatus ===
    AssignmentStatusEnum.Pending;
  return (
    <div>
      <Typography variant="body1">
        Select a launch date, due date, and individuals to assign to. Students
        will not see this assignment until the launch date/time. Leave launch
        date blank if you&apos;d like for the assignment to launch right away.
      </Typography>
      <div className={classes.timeFilters}>
        <DateTimePicker
          ampmInClock
          className={clsx(classes.launchDate, classes.picker)}
          disabled={
            assignmentData?.assignment.assignmentStatus !==
            AssignmentStatusEnum.Pending
          }
          disablePast={
            assignmentData?.assignment.assignmentStatus !==
            AssignmentStatusEnum.PastDue
          }
          label="Launch Date"
          value={launchAt}
          onChange={handleLaunchAtDateChange}
          slotProps={{
            tabs: { hidden: false },
            textField: {
              InputProps: { name: 'launch-at' },
              size: 'small',
              variant: 'standard',
            },
          }}
        />

        <DateTimePicker
          ampmInClock
          className={classes.picker}
          disablePast={
            assignmentData?.assignment.assignmentStatus !==
            AssignmentStatusEnum.PastDue
          }
          label="Due Date"
          onChange={handleDueDateChange}
          slotProps={{
            tabs: { hidden: false },
            textField: {
              size: 'small',
              variant: 'standard',
            },
          }}
          value={dueDate}
        />
      </div>

      <div className={classes.selectGroup}>
        <FormControl variant="outlined" className={classes.classFilter}>
          <InputLabel>Class</InputLabel>
          <Select
            disabled={!editable}
            defaultValue={'All Classes'}
            onChange={onChangeGroup}
            label="Class"
            autoWidth={true}
          >
            <MenuItem key="all" value={'All Classes'}>
              <em>All Classes</em>
            </MenuItem>
            {groups.map((elem) => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Autocomplete
          disabled={!editable}
          className={classes.studentFilter}
          disableCloseOnSelect
          multiple
          onChange={onChangeStudent}
          value={selectedStudents}
          options={allStudents}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <li {...props}>
              <Checkbox
                icon={checkboxIcon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={option.enrollmentId in enrollmentsAssignments}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label="Students" />
          )}
        />
      </div>
    </div>
  );
}
