import { useQuery } from '@apollo/client';
import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { AssignmentDocument } from '../../../../../gql/queries/__generated__/assignment.generated';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../../Alerts/context';
import { AssignmentEditorContext } from '../context';
import { TabTitle } from '../shared/TabTitle';
import { PersonalDeckAssignGroups } from './PersonalDeckAssignGroups';
import { getTabTitleDescription } from './common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  },
}));

export function PersonalDeckSetup() {
  const {
    assignmentEditor: { id },
  } = useContext(AssignmentEditorContext);
  const { dispatch } = useContext(AlertsContext);
  const { data } = useQuery(AssignmentDocument, {
    variables: { assignmentId: id },
    onError: onError(dispatch),
  });

  const classes = useStyles();

  const tabTitleDescription: string = getTabTitleDescription(
    data?.assignment.assignmentStatus,
    data?.assignment.assignmentType,
    data?.assignment.unassignable
  );

  return (
    <div className={classes.root}>
      <div>
        <TabTitle
          title="Assignment Setup"
          description={tabTitleDescription}
          personalDeckAssignment
        />

        <PersonalDeckAssignGroups />
      </div>
    </div>
  );
}
