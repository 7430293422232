import HelpKitButton from './HelpKitButton';

export function HelpKitQuestionsProgressColumns() {
  return (
    <HelpKitButton
      label="Understanding the Questions Progress Table"
      articleId="3vELmzDU41bExLSnFUj5UD"
    />
  );
}
export default HelpKitQuestionsProgressColumns;
