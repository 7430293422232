import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StudentsAssignmentsDatumQueryVariables = Types.Exact<{
  groupsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  enrollmentsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  studentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type StudentsAssignmentsDatumQuery = {
  __typename?: 'Query';
  studentsAssignmentsDatum?: {
    __typename?: 'StudentsAssignmentsDatum';
    id: string;
    completedAt?: any | null;
    questionsCompleted: number;
    questionsTotal: number;
    questionsCorrect: number;
    distributedPointsEarned?: number | null;
    student: {
      __typename?: 'Student';
      id: string;
      fullName?: string | null;
      sortName?: string | null;
    };
    groupsAssignment?: {
      __typename?: 'GroupsAssignment';
      id: string;
      dueDate?: any | null;
      assignment: {
        __typename?: 'Assignment';
        id: string;
        targetDistributedPoints?: number | null;
        name: string;
        assignmentType: Types.AssignmentTypeEnum;
      };
      group: { __typename?: 'Group'; id: string; name: string };
    } | null;
  } | null;
};

export type StudentsAssignmentsDataForAssignmentQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
  groupId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  showAllGroups?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type StudentsAssignmentsDataForAssignmentQuery = {
  __typename?: 'Query';
  studentsAssignmentsDataForAssignment: Array<{
    __typename?: 'StudentsAssignmentsDatum';
    updatedAt: any;
    completedAt?: any | null;
    questionsCompleted: number;
    questionsCorrect: number;
    questionsTotal: number;
    student: {
      __typename?: 'Student';
      id: string;
      fullName?: string | null;
      sortName?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      email: string;
    };
    groupsAssignment?: {
      __typename?: 'GroupsAssignment';
      id: string;
      dueDate?: any | null;
    } | null;
    enrollmentsAssignment?: {
      __typename?: 'EnrollmentsAssignment';
      id: string;
      dueDate: any;
    } | null;
    enrollment: {
      __typename?: 'Enrollment';
      enrollmentStatus: Types.EnrollmentStatusEnum;
    };
  }>;
};

export const StudentsAssignmentsDatumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'studentsAssignmentsDatum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'studentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentsAssignmentsDatum' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'studentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'studentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsTotal' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsCorrect' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'distributedPointsEarned' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sortName' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupsAssignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'assignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'targetDistributedPoints',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignmentType' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StudentsAssignmentsDatumQuery,
  StudentsAssignmentsDatumQueryVariables
>;
export const StudentsAssignmentsDataForAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'studentsAssignmentsDataForAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showAllGroups' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'studentsAssignmentsDataForAssignment',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showAllGroups' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showAllGroups' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sortName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupsAssignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollmentsAssignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dueDate' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentStatus' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'completedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsCompleted' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsCorrect' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questionsTotal' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StudentsAssignmentsDataForAssignmentQuery,
  StudentsAssignmentsDataForAssignmentQueryVariables
>;
