import HelpKitButton from './HelpKitButton';

export function HelpKitEditingCourse() {
  return (
    <HelpKitButton
      label="How do I edit a course?"
      articleId="oxey6YnM4Q46d6Eo43Aaow"
    />
  );
}
export default HelpKitEditingCourse;
