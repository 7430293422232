import { useQuery } from '@apollo/client';
import { AppBar, Toolbar, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../../Auth0Context';
import { buttonLink } from '../../assets/shared-styles/Button-Link';
import Welcome from '../../components/application/Setup/Welcome';
import { LoadingSkeletons } from '../../components/shared/Loaders/LoadingSkeletons';
import { TeacherDocument } from '../../gql/queries/__generated__/teacher.generated';
import { DemoSetup } from './Demo';

export const STEPPER_HEIGHT = 72;
export const MOBILE_STEPPER_HEIGHT = 100;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  header: {
    height: MOBILE_STEPPER_HEIGHT,
    [theme.breakpoints.up('sm')]: {
      height: STEPPER_HEIGHT,
    },
  },
  icon: {
    color: `${theme.palette.common.white} !important`,
    '&.MuiStepIcon-active': {
      color: `${theme.palette.mint.main} !important`,
      fontWeight: theme.typography.fontWeightBold,
    },
    '& .MuiStepIcon-text': {
      fill: theme.palette.primary.main,
    },
    '&.MuiStepIcon-completed': {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  step: {
    '& .MuiStepLabel-label': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  stepper: {
    height: STEPPER_HEIGHT,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.mint.main}`,
    minWidth: '50%',
    maxWidth: 1000,
  },
  main: {
    overflow: 'auto',
    height: `calc(100vh - ${MOBILE_STEPPER_HEIGHT}px)`,
    [theme.breakpoints.up('sm')]: {
      height: `calc(100vh - ${STEPPER_HEIGHT}px)`,
    },
  },
  toolBar: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexFlow: 'row',
    },
  },
  signOutButton: {
    ...buttonLink(theme),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      margin: 0,
      right: 24,
    },
  },
}));

export function Setup() {
  const classes = useStyles();
  const { data, loading } = useQuery(TeacherDocument);
  const { logout } = useAuth0();
  const history = useHistory();

  const acceptedPrivacyAndTos =
    data?.teacher.acceptedPrivacyAt && data?.teacher.acceptedTosAt;
  const hasAssignments = data?.teacher.assignments.length;

  useEffect(() => {
    if (acceptedPrivacyAndTos && hasAssignments) {
      history.push('/');
    }
  }, [acceptedPrivacyAndTos, hasAssignments, history]);

  const component = () => {
    if (loading) return <LoadingSkeletons num={16} />;
    if (!acceptedPrivacyAndTos) {
      return <Welcome />;
    } else if (!hasAssignments) {
      return <DemoSetup />;
    }
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.header} position="relative">
        <Toolbar className={classes.toolBar}>
          <button onClick={() => logout()} className={classes.signOutButton}>
            Sign Out
          </button>
        </Toolbar>
      </AppBar>
      <main className={classes.main}>{component()}</main>
    </div>
  );
}
