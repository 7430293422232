import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as Sentry from '@sentry/react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../../../Auth0Context';
import { AlertsContext } from './context';
import { closeDialog } from './context/actions';

export function AlertsDialog() {
  const {
    alerts: { dialog },
    dispatch,
  } = useContext(AlertsContext);
  const { title, message, maxWidth, error } = dialog;
  const { user } = useAuth0();
  const history = useHistory();
  const handleClose = () => {
    dispatch(closeDialog());
    if (dialog.reloadOnClose) {
      if (dialog.reloadToHome) {
        history.push('/');
      }
      window.location.reload();
    }
  };
  if (error) {
    Sentry.captureMessage(message, { user });
  }
  return (
    <Dialog
      maxWidth={maxWidth || 'lg'}
      disableScrollLock
      fullWidth={true}
      open={message.length > 0}
      onClose={handleClose}
    >
      <DialogTitle color="primary" variant="h4">
        {title}
      </DialogTitle>
      <DialogContent>
        {message.split('\n').map((individualMessage) => {
          return <div key={individualMessage}>{individualMessage}</div>;
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertsDialog;
