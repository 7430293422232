import { Info } from '@mui/icons-material';
import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...buttonLink(theme),
    display: 'inline-flex',
    alignItems: 'baseline',
  },
  icon: {
    fontSize: 14,
  },
  label: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  iconContainer: {
    display: 'flex',
    marginRight: theme.spacing(0.3),
    position: 'relative',
    height: 20,
    bottom: -2,
  },
}));

type HelpKitButtonProps = {
  view?: 'popover' | 'sidebar';
  articleId: string;
  label: string;
};

export function HelpKitButton({ view, articleId, label }: HelpKitButtonProps) {
  const classes = useStyles();
  const openHelper = () => {
    window.Helpkit.show(view || 'popover', 'article', articleId);
  };
  return (
    <button type="button" onClick={openHelper} className={classes.root}>
      <div className={classes.iconContainer}>
        <Info className={classes.icon} />
      </div>
      <Typography variant="caption" className={classes.label}>
        {label}
      </Typography>
    </button>
  );
}

export default HelpKitButton;
