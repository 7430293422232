import { AssignmentStatusEnum, LeaderboardSettingEnum } from '../gql/types';

export const renderAssignmentStatus = (status: AssignmentStatusEnum) => {
  switch (status) {
    case AssignmentStatusEnum.Pending:
      return 'Draft';
    case AssignmentStatusEnum.Active:
      return 'Active';
    case AssignmentStatusEnum.InProgress:
      return 'In Progress';
    case AssignmentStatusEnum.PastDue:
      return 'Previous';
    default:
      return '';
  }
};

export const renderLeaderboardSetting = (status: LeaderboardSettingEnum) => {
  switch (status) {
    case LeaderboardSettingEnum.ShowAll:
      return 'Show All Students';
    case LeaderboardSettingEnum.Show_5:
      return 'Show Top 5 Students';
    case LeaderboardSettingEnum.ShowNone:
      return 'Leaderboard Off';
    default:
      return '';
  }
};
