import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Topic } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1.75),
  },
  editContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(0.5),
  },
  textField: {},
  iconButton: {
    color: theme.palette.secondary.main,
    padding: 4,
    '&:hover': {
      opacity: 0.8,
    },
  },
  icon: {
    width: 20,
  },
  closeIcon: {
    width: 20,
    color: theme.palette.error.dark,
  },
  checkIcon: {
    width: 20,
    color: theme.palette.secondary.main,
  },
  editField: {
    marginRight: theme.spacing(1),
  },
}));

export function TopicDisplay({ topic }: { topic?: Topic }) {
  const classes = useStyles();

  if (!topic) return null;

  const renderContent = () => {
    return (
      <div className={classes.editContainer}>
        <Typography variant="h3" className={classes.title}>
          {topic.name}
        </Typography>
      </div>
    );
  };

  return <div className={classes.root}>{renderContent()}</div>;
}

export default TopicDisplay;
