import { Tabs, TabsProps, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import {
  Route,
  RouteComponentProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import { a11yProps } from '../../../../utils/tabA11yProps';
import LinkTab from '../LinkTab';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    backgroundColor: theme.palette.common.white,
    flex: '1 1 auto',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

type TabProps<ComponentProps extends object = object> = {
  path: string;
  label: string;
  pathValue?: string;
  component:
    | React.ComponentType<RouteComponentProps<ComponentProps>>
    | React.ComponentType<ComponentProps>;
};

function CustomTabs<ComponentProps extends object>({
  tabs,
  variant,
  ...props
}: { tabs: TabProps<ComponentProps>[] } & TabsProps) {
  const classes = useStyles();
  const location = useLocation();
  return (
    <div className={classes.root}>
      <Tabs
        {...props}
        variant={variant ? variant : 'scrollable'}
        value={location.pathname}
        textColor="primary"
        indicatorColor="secondary"
        className={`${classes.tabs} ${props.className}`}
        aria-label="tabs"
      >
        {tabs.map(({ label, path, pathValue }) => {
          return (
            <LinkTab
              label={label}
              key={path}
              href={pathValue || path}
              value={pathValue || path}
              {...a11yProps(path)}
            />
          );
        })}
      </Tabs>
      <div className={classes.content}>
        <Switch>
          {[...tabs].reverse().map(({ component, path }) => {
            return (
              <Route key={`${path}-route`} path={path} component={component} />
            );
          })}
        </Switch>
      </div>
    </div>
  );
}

export default CustomTabs;
