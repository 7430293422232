import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  TextField,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import React, { useContext, useState } from 'react';
import { UpdateStudentDocument } from '../../../gql/mutations/__generated__/students.generated';
import {
  EnrollmentDocument,
  type EnrollmentQuery,
} from '../../../gql/queries/__generated__/enrollment.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../Alerts/context';
import { pushSnack } from '../Alerts/context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  firstTextField: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  actionsContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '0 20px',
    padding: '15px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  inputContainer: {
    padding: '32px 0',
  },
  required: {
    color: theme.palette.error.main,
  },
  checkbox: {
    marginLeft: theme.spacing(1.5),
  },
}));

type EditProfileModalProps = {
  open: boolean;
  handleClose: () => void;
  enrollment: EnrollmentQuery['enrollment'];
};

export function EditProfileModal({
  open,
  handleClose,
  enrollment,
}: EditProfileModalProps) {
  const classes = useStyles();
  const { student } = enrollment;
  const [firstName, setFirstName] = useState(student.firstName || '');
  const [lastName, setLastName] = useState(student.lastName || '');
  const [allowNameChange, setAllowNameChange] = useState(
    student.allowNameChange
  );
  const { dispatch } = useContext(AlertsContext);
  const [updateStudent, { loading }] = useMutation(UpdateStudentDocument, {
    variables: {
      firstName,
      lastName,
      studentId: student.id,
      allowNameChange,
    },
    onError: onError(dispatch, false, true),
    update: (cache, res) => {
      const data = cache.readQuery({
        query: EnrollmentDocument,
        variables: { id: enrollment.id },
      });
      if (!data) return;

      const response = res.data?.updateStudent;
      if (!response) return;

      const updatedEnrollment = produce(data.enrollment, (enrollmentDraft) => {
        enrollmentDraft.student.firstName = response.firstName;
        enrollmentDraft.student.lastName = response.lastName;
        enrollmentDraft.student.fullName = response.fullName;
      });

      cache.writeQuery({
        data: { enrollment: updatedEnrollment },
        query: EnrollmentDocument,
        variables: { id: enrollment.id },
      });

      dispatch(
        pushSnack({
          message: 'Updated information successfully!',
          severity: 'success',
        })
      );
    },
  });

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setAllowNameChange(checked);
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateStudent();
  };

  const fieldsChanged =
    firstName !== student.firstName ||
    lastName !== student.lastName ||
    allowNameChange !== student.allowNameChange;
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      {loading ? <LinearProgress /> : null}
      <DialogTitle id="form-dialog-title">Edit Student Info</DialogTitle>
      <form onSubmit={handleSave}>
        <DialogContent>
          <DialogContentText color="primary" variant="body1">
            {`Update ${student.firstName}'s personal information`}
          </DialogContentText>
          <div className={classes.inputContainer}>
            <DialogContentText color="primary" variant="h4">
              Name
              <span className={classes.required}> *</span>
            </DialogContentText>
            <br />
            <TextField
              className={classes.firstTextField}
              id="firstName"
              label="First Name"
              type="firstName"
              variant="outlined"
              size="small"
              value={firstName}
              onChange={handleFirstNameChange}
            />
            <TextField
              id="lastName"
              label="Last Name"
              type="lastName"
              variant="outlined"
              size="small"
              value={lastName}
              onChange={handleLastNameChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={allowNameChange}
                  className={classes.checkbox}
                  name="Allow Student to Edit Own Name"
                />
              }
              label="Allow Student to Edit Own Name"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className={classes.actionsContainer}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              type="submit"
              disabled={!fieldsChanged}
              color="secondary"
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
}
