import { useQuery } from '@apollo/client';
import { Tooltip, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useContext, useMemo, useState } from 'react';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { EnrollmentDocument } from '../../../gql/queries/__generated__/enrollment.generated';
import { AssignmentMethodEnum } from '../../../gql/types';
import { onError } from '../../../utils/apollo/apolloHelper';
import { assignmentTypeNameMapping } from '../../../utils/assignmentHelper';
import { dateFormat } from '../../../utils/dates';
import { renderAssignmentStatus } from '../../../utils/enumMappings';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { CustomTable } from '../../shared/Table';
import { AlertsContext } from '../Alerts/context';
import { openDialog } from '../Alerts/context/actions';
import { AssignmentResult } from '../Assignments/AssignmentEditor/Results/AssignmentResult';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
  },
  buttonLink: buttonLink(theme),
  score: {
    color: theme.palette.error.main,
  },
  okScore: {
    color: theme.palette.warning.dark,
  },
  goodScore: {
    color: theme.palette.secondary.dark,
  },
}));

type StudentAssignmentTableProps = {
  enrollmentId: string;
};

export function StudentAssignmentTable({
  enrollmentId,
}: StudentAssignmentTableProps) {
  useTrackVisit({
    section: 'student-profile',
    page: 'assignments',
  });
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(EnrollmentDocument, {
    variables: { id: enrollmentId },
    onError: onError(dispatch),
  });
  const [selectedGroupsAssignmentId, setSelectedGroupsAssignmentId] =
    useState('');
  const [selectedEnrollmentsAssignmentId, setSelectedEnrollmentsAssignmentId] =
    useState('');

  const assignmentsData = useMemo(() => {
    if (!data?.enrollment.assignmentsData) {
      return [];
    }

    return data.enrollment.assignmentsData.map(
      ({
        questionsCompleted,
        questionsCorrect,
        questionsTotal,
        distributedPointsEarned,
        groupsAssignment,
        enrollmentsAssignment,
      }) => {
        let groupsAssignmentId,
          enrollmentsAssignmentId,
          dueDate,
          assignmentName,
          assignmentStatus,
          assignmentType,
          targetDistributedPoints,
          assignmentMethod;
        if (groupsAssignment) {
          ({
            id: groupsAssignmentId,
            dueDate,
            assignment: {
              name: assignmentName,
              assignmentStatus,
              targetDistributedPoints,
              assignmentType,
              assignmentMethod,
            },
          } = groupsAssignment);
        } else if (enrollmentsAssignment) {
          ({
            id: enrollmentsAssignmentId,
            dueDate,
            assignment: {
              name: assignmentName,
              assignmentStatus,
              assignmentType,
              assignmentMethod,
            },
          } = enrollmentsAssignment);
        } else {
          dispatch(
            openDialog({
              title: 'Error occurred.',
              message: 'Failed load student assignment data.',
              error: true,
              reloadOnClose: true,
            })
          );
        }
        const score =
          questionsTotal === 0
            ? 100
            : Math.round((questionsCorrect / questionsTotal) * 100);
        return {
          groupsAssignmentId,
          enrollmentsAssignmentId,
          assignmentType,
          dueDate: new Date(dueDate),
          assignmentName,
          assignmentStatus,
          assignmentMethod,
          questionsCompleted,
          targetDistributedPoints,
          distributedPointsEarned,
          score: score,
          questionsTotal,
          questionsCorrect,
        };
      }
    );
  }, [data, dispatch]);

  const studentId = data?.enrollment.student.id || '';
  const handleClose = () => {
    setSelectedGroupsAssignmentId('');
    setSelectedEnrollmentsAssignmentId('');
  };

  return (
    <div className={classes.root}>
      <CustomTable
        title="ASSIGNMENT RESULTS"
        isLoading={loading}
        data={assignmentsData}
        columns={[
          {
            title: 'ASSIGNMENT TYPE',
            field: 'assignmentType',
            render: ({ assignmentType, assignmentMethod }) => {
              if (assignmentType && assignmentMethod) {
                if (assignmentMethod === AssignmentMethodEnum.Individual) {
                  return `Individual`;
                }
                return `${assignmentTypeNameMapping[assignmentType]}`;
              }
            },
          },
          {
            title: 'ASSIGNMENT NAME',
            field: 'assignmentName',
            render: ({
              assignmentName,
              assignmentMethod,
              groupsAssignmentId,
              enrollmentsAssignmentId,
            }) => {
              return (
                <button
                  className={classes.buttonLink}
                  onClick={() => {
                    if (
                      assignmentMethod === AssignmentMethodEnum.Group &&
                      groupsAssignmentId
                    ) {
                      setSelectedGroupsAssignmentId(groupsAssignmentId);
                    }

                    if (
                      assignmentMethod === AssignmentMethodEnum.Individual &&
                      enrollmentsAssignmentId
                    ) {
                      setSelectedEnrollmentsAssignmentId(
                        enrollmentsAssignmentId
                      );
                    }
                  }}
                >
                  {assignmentName}
                </button>
              );
            },
          },
          {
            title: 'ASSIGNMENT STATE',
            field: 'assignmentStatus',
            // The case where the assignment status is missing is when no
            // student assignment data was fetched which is handled above.
            // TODO: We should handle the _missing student assignment data_ case
            //       differently so this check and empty cell are not needed
            //       This could be done by filtering out entries that don't have
            //       student data or have each row datum be a union of
            //       assignment data or meaningful error message.
            render: ({ assignmentStatus }) =>
              assignmentStatus && renderAssignmentStatus(assignmentStatus),
          },
          {
            title: 'COMPLETION',
            field: 'questionsCompleted',
            render: ({
              questionsCompleted,
              questionsTotal,
              targetDistributedPoints,
              distributedPointsEarned,
            }) => {
              if (targetDistributedPoints) {
                return `${distributedPointsEarned} of ${targetDistributedPoints} points`;
              }
              return `${questionsCompleted} of ${questionsTotal} questions`;
            },
          },
          {
            title: 'SCORE',
            field: 'score',
            render: ({
              questionsCorrect,
              questionsTotal,
              score,
              targetDistributedPoints,
              distributedPointsEarned,
            }) => {
              const calculated = targetDistributedPoints
                ? Math.round(
                    ((distributedPointsEarned || 0) / targetDistributedPoints) *
                      100
                  )
                : score;
              return (
                <Tooltip title={`${questionsCorrect} / ${questionsTotal}`}>
                  <Typography
                    className={clsx(classes.score, {
                      [classes.goodScore]: score > 84,
                      [classes.okScore]: score > 70,
                    })}
                  >
                    {`${calculated}%`}
                  </Typography>
                </Tooltip>
              );
            },
          },
          {
            title: 'DUE DATE',
            field: 'dueDate',
            render: ({ dueDate }) => {
              return `${format(dueDate, dateFormat)}`;
            },
          },
        ]}
      />
      <AssignmentResult
        groupsAssignmentId={selectedGroupsAssignmentId}
        enrollmentsAssignmentId={selectedEnrollmentsAssignmentId}
        studentId={studentId}
        open={!!selectedGroupsAssignmentId || !!selectedEnrollmentsAssignmentId}
        handleClose={handleClose}
      />
    </div>
  );
}
