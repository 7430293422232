import { Box, Typography } from '@mui/material';
import { GeneratedQuestionsQuery } from '../../../../gql/queries/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../gql/types';
import theme from '../../../../theme';

type GeneratedQuestionDisplayProps = {
  question: GeneratedQuestionsQuery['generatedQuestions'][0];
};
export function GeneratedQuestionDisplay({
  question,
}: GeneratedQuestionDisplayProps) {
  const answer = () => {
    switch (question.questionType) {
      case QuestionTypeEnum.FreeResponse:
        return (
          <Typography
            sx={{ color: theme.palette.darks.dark }}
          >{`A: ${question.freeResponseAnswer?.plainText}`}</Typography>
        );
      case QuestionTypeEnum.ShortAnswer:
        return (
          <Box>
            <Typography
              sx={{ fontWeight: theme.typography.fontWeightBold, mb: 1 }}
            >
              Acceptable Answer(s):
            </Typography>
            {question.shortAnswerAnswers?.map((answer, i) => (
              <Typography
                sx={{ color: theme.palette.darks.dark, ml: 2 }}
                key={`${answer.text}-${i}`}
              >
                - {answer.plainText}
              </Typography>
            ))}
          </Box>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontWeight: theme.typography.fontWeightBold,
          mb: 2.5,
          color: theme.palette.darks.dark,
        }}
      >
        {`Q: ${question.plainText}`}
      </Typography>
      {answer()}
    </>
  );
}
