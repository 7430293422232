import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type NewEnrollmentSubscriptionVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
}>;

export type NewEnrollmentSubscription = {
  __typename?: 'Subscription';
  newEnrollment: {
    __typename?: 'NewEnrollmentPayload';
    enrollment?: {
      __typename?: 'Enrollment';
      id: string;
      enrollmentStatus: Types.EnrollmentStatusEnum;
      lastActivity?: any | null;
      student: {
        __typename?: 'Student';
        id: string;
        email: string;
        fullName?: string | null;
        sortName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      };
    } | null;
  };
};

export const NewEnrollmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'newEnrollment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newEnrollment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'groupViewEnrollmentAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'groupViewEnrollmentAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Enrollment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'student' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  NewEnrollmentSubscription,
  NewEnrollmentSubscriptionVariables
>;
