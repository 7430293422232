import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import theme from '../../../theme';
import StandardDisplay from './StandardDisplay';
import TopicDisplay from './TopicDisplay';
import { TopicHolder } from './utils';

export function TopicAccordion({
  topic,
  userID,
}: {
  topic: TopicHolder;
  userID: number;
}) {
  if (!topic) return null;
  return (
    <Box
      sx={{
        margin: '0 auto',
        marginBottom: (theme) => theme.spacing(3),
      }}
    >
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        sx={{
          border: `1px solid ${theme.palette.mint.main}`,
        }}
      >
        <AccordionSummary
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(3),
            borderRadius: '4px',
            '.MuiAccordionSummary-expandIconWrapper': {
              color: theme.palette.secondary.main,
            },
          }}
          expandIcon={<ExpandMore />}
          aria-controls="standards-category"
          id="standards-category"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <TopicDisplay topic={topic?.topic} />
            </Box>
            <Typography>{`${topic.standards.length} ${
              topic.standards.length === 1 ? 'Standard' : 'Standards'
            }`}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            display: 'flex',
            flexFlow: 'column',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {topic.standards.map((standard, innerIndex) => (
            <Box key={innerIndex}>
              <StandardDisplay standard={standard} userID={userID} />
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
