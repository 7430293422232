import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useRef, useState } from 'react';
import { UpdateAssignmentDocument } from '../../../../gql/mutations/__generated__/assignment.generated';
import { AssignmentDocument } from '../../../../gql/queries/__generated__/assignment.generated';
import {
  AssignmentStatusEnum,
  AssignmentTypeEnum,
} from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import CopyToClipboard from '../../../shared/CopyToClipboard';
import { RocketIcon } from '../../../shared/CustomIcons/RocketIcon';
import { ShareIcon } from '../../../shared/CustomIcons/ShareIcon';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';
import { AssignmentEditorContext } from './context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  icon: {
    fill: theme.palette.common.white,
  },
  content: {
    marginTop: '20px',
    paddingBottom: 0,
  },
  textField: {
    marginBottom: '56px',
    minWidth: '80%',
  },
  actions: {
    padding: '15px 24px',
  },
  button: {
    color: theme.palette.common.white,
  },
  cancelButton: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    marginRight: '20px',
  },
  copyButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  progressBarContainer: {
    height: theme.spacing(1),
    width: 150,
  },
  personalDeckContainer: {
    padding: theme.spacing(2),
  },
  explanationImage: {
    width: '100%',
    height: '100%',
  },
  paragraph: {
    marginBottom: theme.spacing(2),
  },
}));

type PrimaryActionsButtonProps = {
  launchProgress: number;
  resetLaunchProgressStates: () => void;
  startLaunch: () => void;
  totalAssigned: number;
};

export function PrimaryActionsButton({
  startLaunch,
}: PrimaryActionsButtonProps) {
  const classes = useStyles();
  const [primaryActionsOpen, setPrimaryActionsOpen] = useState(false);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const {
    assignmentEditor: {
      id: assignmentId,
      groupsAssignments,
      enrollmentsAssignments,
    },
  } = useContext(AssignmentEditorContext);
  const { dispatch } = useContext(AlertsContext);
  const { data } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });
  const [launchAssignment, { loading }] = useMutation(
    UpdateAssignmentDocument,
    {
      onError: onError(dispatch),
      variables: {
        assignmentId,
        assignmentStatus: AssignmentStatusEnum.Active,
      },
      onCompleted: () => {
        dispatch(
          pushSnack({
            message: 'Assignment status updated!',
          })
        );
      },
    }
  );
  const openPrimaryActions = () => setPrimaryActionsOpen(true);
  const closePrimaryActions = () => setPrimaryActionsOpen(false);

  const link = `https://student.podsie.org/assignments/${assignmentId}`;

  if (!data) {
    return null;
  }

  if (loading) {
    return (
      <div className={classes.progressBarContainer}>{<LinearProgress />}</div>
    );
  }

  const {
    assignmentStatus,
    assignmentType,
    assignmentMethod,
    questions,
    numSpiraledQuestions,
  } = data.assignment;
  let tooltipText = '';
  let validGroupsAssignments = Object.keys(groupsAssignments).length > 0;
  if (validGroupsAssignments) {
    Object.keys(groupsAssignments).forEach((key) => {
      const dueDate = groupsAssignments[key].dueDate;
      if (!dueDate || isNaN(dueDate.getTime())) {
        validGroupsAssignments = false;
      }
    });
  }

  let validEnrollmentsAssignments =
    Object.keys(enrollmentsAssignments).length > 0;
  if (validEnrollmentsAssignments) {
    Object.values(enrollmentsAssignments).forEach((value) => {
      const dueDate = value.dueDate;
      if (!dueDate || isNaN(dueDate.getTime())) {
        validEnrollmentsAssignments = false;
      }
    });
  }
  const validQuestions = questions.length > 0 || numSpiraledQuestions > 0;

  const disabled =
    (!validGroupsAssignments && !validEnrollmentsAssignments) ||
    (!validQuestions && assignmentType === 'standard');

  if (disabled) {
    if (assignmentType === 'standard') {
      if (assignmentMethod === 'group') {
        tooltipText =
          "You must assign at least one class and have at least one question. Please do this in the 'SETUP' tab.";
      } else {
        tooltipText =
          "You must assign at least one student and have at least one question. Please do this in the 'SETUP' tab.";
      }
    } else {
      tooltipText =
        "You must assign to at least one class. Please do this in the 'SETUP' tab.";
    }
  }

  const pdAssignment = assignmentType === AssignmentTypeEnum.Distributed;

  return (
    <div className={classes.root}>
      {assignmentStatus !== AssignmentStatusEnum.Pending ? (
        <Button
          onClick={openPrimaryActions}
          color="secondary"
          variant="contained"
          className={classes.button}
          startIcon={<ShareIcon className={classes.icon} />}
        >
          SHARE LINK
        </Button>
      ) : (
        <Tooltip title={tooltipText}>
          <span>
            <Button
              disabled={disabled}
              onClick={() => {
                startLaunch();
                launchAssignment({
                  variables: {
                    assignmentId,
                    assignmentStatus: AssignmentStatusEnum.Active,
                  },
                });
              }}
              startIcon={<RocketIcon className={classes.icon} />}
              variant="contained"
              color="secondary"
              aria-label="Launch Assignment"
              className={classes.button}
            >
              Launch
            </Button>
          </span>
        </Tooltip>
      )}
      <Dialog
        fullWidth
        maxWidth={pdAssignment ? 'md' : 'sm'}
        open={primaryActionsOpen}
        onClose={closePrimaryActions}
      >
        {pdAssignment ? (
          <div className={classes.personalDeckContainer}>
            <DialogTitle color="primary" variant="h2">
              How to access the Personal Deck Assignment
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1" className={classes.paragraph}>
                Personal Deck Assignments do not have an assignment link. When
                Personal Deck Assignments are active, students can make progress
                on this assignment by doing their regular Personal Deck
                practice.
              </Typography>
              <Typography variant="body1" className={classes.paragraph}>
                They can also track their progress under the Current Assignments
                tab:
              </Typography>
              <img
                alt="Shows the section on the student side where students can track their Personal Deck Assignment progress."
                className={classes.explanationImage}
                src="https://github.com/podsie/images/blob/main/app/teacher/student_personal_deck_assignment.png?raw=true"
              />
            </DialogContent>
          </div>
        ) : (
          <>
            <DialogTitle color="primary" variant="h2">
              SHARE ASSIGNMENT LINK
            </DialogTitle>
            <DialogContent className={classes.content}>
              <TextField
                className={classes.textField}
                value={link}
                inputRef={textFieldRef}
                variant="outlined"
              />
              <Divider />
            </DialogContent>
            <DialogActions className={classes.actions}>
              <Button
                onClick={closePrimaryActions}
                variant="outlined"
                className={classes.cancelButton}
              >
                CANCEL
              </Button>
              {/* TODO: REMOVE */}
              <CopyToClipboard
                copiedText={textFieldRef?.current?.innerText || ''}
              >
                <Button className={classes.copyButton}>
                  COPY LINK TO CLIPBOARD
                </Button>
              </CopyToClipboard>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
