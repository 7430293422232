import { useQuery } from '@apollo/client';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import {
  Alert,
  Button,
  MobileStepper,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import {
  LatestFirstResponsesDocument,
  QuestionDocument,
} from '../../../../gql/queries/__generated__/question.generated';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { QuestionBreakdownCard } from '../../Groups/Assignments/QuestionBreakdownCard';
import { QuestionsBreakdownProvider } from '../../Groups/Assignments/QuestionsBreakdownContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '80vh',
  },
  loadingContainer: {
    padding: `0 ${theme.spacing(1.5)}`,
    width: '100%',
  },
  buttonIcon: {
    backgroundColor: theme.palette.grays.main,
    padding: 6,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.grays.dark,
    },
  },
  firstPanel: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main} 24%, #0c6d7e 123%)`,
    paddingTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      maxWidth: 'auto',
      flexFlow: 'column',
    },
  },
  questionCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2.5),
  },
  title: {
    marginTop: theme.spacing(2),
  },
  alert: {
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
}));

type QuestionDetailsProps = {
  questionId: string;
  groupId?: string;
};

export function QuestionDetails({ questionId, groupId }: QuestionDetailsProps) {
  const classes = useStyles();
  const [questionIds, setQuestionIds] = useState([questionId]);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const { data: questionBreakdownData, loading: breakdownLoading } = useQuery(
    LatestFirstResponsesDocument,
    {
      variables: {
        groupId,
        questionId: questionIds[activeQuestionIndex],
      },
    }
  );

  const { data, loading } = useQuery(QuestionDocument, {
    variables: {
      questionId: questionIds[activeQuestionIndex],
    },
    onCompleted: (res) => {
      if (res.question.id === questionId && res.question.variations) {
        const updated = [questionId];
        res.question.variations.forEach((variation) => {
          updated.push(variation.id);
        });
        setQuestionIds(updated);
      }
    },
  });

  const moveForward = () => {
    setActiveQuestionIndex(activeQuestionIndex + 1);
  };

  const moveBackwards = () => {
    setActiveQuestionIndex(activeQuestionIndex - 1);
  };

  return (
    <div className={classes.root}>
      <MobileStepper
        steps={questionIds.length}
        variant="text"
        position="static"
        activeStep={activeQuestionIndex}
        nextButton={
          <Button
            size="small"
            onClick={moveForward}
            disabled={activeQuestionIndex >= questionIds.length - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button
            size="small"
            onClick={moveBackwards}
            disabled={activeQuestionIndex === 0}
          >
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
      <div className={classes.questionCardContainer}>
        <Typography variant="h4" color="primary" className={classes.title}>
          {activeQuestionIndex === 0 ? 'Base Question' : 'Question Variation'}
        </Typography>
        <div className={classes.alert}>
          <Alert severity="info">
            <strong>Note: </strong>Only the most recent &ldquo;first
            attempt&rdquo; is shown below.
          </Alert>
        </div>
        {data?.question && (
          <QuestionsBreakdownProvider
            preloadedState={questionBreakdownData?.latestFirstResponses}
          >
            {loading ? (
              <LoadingSkeletons num={6} />
            ) : (
              <QuestionBreakdownCard
                reviewable
                breakdownLoading={breakdownLoading}
                breakdownOpen
                question={data.question}
                questionNumber={1}
              />
            )}
          </QuestionsBreakdownProvider>
        )}
      </div>
    </div>
  );
}
