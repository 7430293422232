import HelpKitButton from './HelpKitButton';

export function HelpKitLatePDA() {
  return (
    <HelpKitButton
      label="Learn more about availability of late Personal Deck Assignments."
      articleId="qPkRb993rrMREpt8RRTZ31"
    />
  );
}
export default HelpKitLatePDA;
