import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';
import CreateCourseForm from './CreateCourseForm';

type CreateCourseModalProps = {
  open: boolean;
  setOpenCreateCourse: Dispatch<SetStateAction<boolean>>;
};

export function CreateCourseModal({
  open,
  setOpenCreateCourse,
}: CreateCourseModalProps) {
  const handleClose = () => setOpenCreateCourse(false);

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose} scroll="body">
      <DialogTitle id="customized-dialog-title">Create a Course</DialogTitle>
      <DialogContent>
        <CreateCourseForm handleModalClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
}
