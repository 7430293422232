import HelpKitButton from './HelpKitButton';

export function HelpKitNewSchoolYear() {
  return (
    <HelpKitButton
      label="How to transition courses from the previous school year"
      articleId="tAejsgZtgz8Tr9vK7mrQiq"
    />
  );
}
export default HelpKitNewSchoolYear;
