import { useQuery } from '@apollo/client';
import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { useContext, useMemo } from 'react';
import { Link, type RouteComponentProps } from 'react-router-dom';
import { tableRoot } from '../../../../../assets/shared-styles/Table';
import { AssignmentDocument } from '../../../../../gql/queries/__generated__/assignment.generated';
import {
  Assignment,
  EnrollmentStatusEnum,
  StudentsAssignmentsDatum,
} from '../../../../../gql/types';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { calculateAssignmentStats } from '../../../../../utils/assignmentHelper';
import { dateFormat } from '../../../../../utils/dates';
import { CustomTable } from '../../../../shared/Table';
import { AlertsContext } from '../../../Alerts/context';
import { AssignmentEditorContext } from '../context';
import { TabTitle } from '../shared/TabTitle';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    ...tableRoot(theme),
  },
  linkButton: {
    border: 'none',
    backgroundColor: 'transparent',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tabTitle: {
    marginBottom: theme.spacing(4),
  },
}));

type ResultsTableData = {
  id: string;
  groupCompletion?: { numFinishedStudents: number; numStudents: number };
  groupName?: string;
  due: Date;
  averageScore?: number;
  launchAt?: Date;
};

export function Results({ match }: RouteComponentProps) {
  const classes = useStyles();
  const {
    assignmentEditor: { id: assignmentId },
  } = useContext(AssignmentEditorContext);
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });

  const tableData = useMemo(() => {
    const parsedData: ResultsTableData[] = [];
    if (
      !data?.assignment.groupsAssignments &&
      !data?.assignment.enrollmentsAssignments
    ) {
      return parsedData;
    }

    // Calculate table data for enrollments assignments
    if (
      data?.assignment.enrollmentsAssignments &&
      data?.assignment.enrollmentsAssignments.length > 0
    ) {
      let numStudents = 0;
      let numFinishedStudents = 0;
      let numFinishedStudentsNonZeroQuestions = 0;
      let totalPoints = 0;
      data?.assignment.enrollmentsAssignments
        .filter(
          (ea) =>
            ea.enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled
        )
        .forEach((ea) => {
          if (!ea.studentsAssignmentsDatum) {
            return;
          }
          const {
            updatedNumStudents,
            updatedNumFinishedStudents,
            updatedNumFinishedStudentsNonZeroQuestions,
            updatedTotalPoints,
          } = calculateAssignmentStats({
            studentsAssignmentsDatum:
              ea.studentsAssignmentsDatum as StudentsAssignmentsDatum,
            numStudents,
            numFinishedStudents,
            numFinishedStudentsNonZeroQuestions,
            totalPoints,
            assignment: data.assignment as Assignment,
          });
          numStudents = updatedNumStudents;
          numFinishedStudents = updatedNumFinishedStudents;
          numFinishedStudentsNonZeroQuestions =
            updatedNumFinishedStudentsNonZeroQuestions;
          totalPoints = updatedTotalPoints;
        });
      const averageScore = Math.round(
        totalPoints / numFinishedStudentsNonZeroQuestions
      );
      parsedData.unshift({
        id: '',
        groupCompletion: { numFinishedStudents, numStudents },
        groupName: 'Individual Assignment',
        averageScore: isNaN(averageScore) ? undefined : averageScore,
        launchAt: data.assignment.launchAt,
        due: new Date(data.assignment.enrollmentsAssignments[0].dueDate),
      });
      return parsedData;
    } else if (
      data?.assignment.groupsAssignments &&
      data?.assignment.groupsAssignments.length > 0
    ) {
      // Calculate table data for groups assignments
      data?.assignment.groupsAssignments?.forEach(
        ({ dueDate: due, group, studentsAssignmentsData }) => {
          let numStudents = 0;
          let numFinishedStudents = 0;
          let numFinishedStudentsNonZeroQuestions = 0;
          let totalPoints = 0;
          const dueDate = new Date(due);
          studentsAssignmentsData &&
            studentsAssignmentsData.forEach((studentsAssignmentsDatum) => {
              if (!studentsAssignmentsDatum) {
                return;
              }
              const {
                updatedNumStudents,
                updatedNumFinishedStudents,
                updatedNumFinishedStudentsNonZeroQuestions,
                updatedTotalPoints,
              } = calculateAssignmentStats({
                studentsAssignmentsDatum:
                  studentsAssignmentsDatum as StudentsAssignmentsDatum,
                numStudents,
                numFinishedStudents,
                numFinishedStudentsNonZeroQuestions,
                totalPoints,
                assignment: data.assignment as Assignment,
              });
              numStudents = updatedNumStudents;
              numFinishedStudents = updatedNumFinishedStudents;
              numFinishedStudentsNonZeroQuestions =
                updatedNumFinishedStudentsNonZeroQuestions;
              totalPoints = updatedTotalPoints;
            });
          const averageScore = Math.round(
            totalPoints / numFinishedStudentsNonZeroQuestions
          );
          parsedData.unshift({
            id: group.id,
            groupCompletion: { numFinishedStudents, numStudents },
            groupName: group.name,
            launchAt: data.assignment.launchAt,
            averageScore: isNaN(averageScore) ? undefined : averageScore,
            due: dueDate,
          });
        }
      );
      return parsedData;
    } else {
      return parsedData;
    }
  }, [data]);

  return (
    <div className={classes.root}>
      <TabTitle
        title="ASSIGNMENT RESULTS"
        description="Data for Group & Student outcomes for the assignment will be listed here after students complete the assignment."
        className={classes.tabTitle}
      />
      <CustomTable
        isLoading={loading}
        data={tableData}
        components={{
          Toolbar: () => null,
        }}
        columns={[
          {
            title: 'CLASS',
            field: 'groupName',
            render: ({ id, groupName }) => {
              const routeId = id || 'individuals';
              return (
                <Link to={`${match.url}/${routeId}`}>
                  <button className={`link ${classes.linkButton}`}>
                    {groupName}
                  </button>
                </Link>
              );
            },
          },
          {
            title: 'Completion Rate',
            field: 'groupCompletion',
            customSort: (a, b) => {
              if (!a.groupCompletion || !b.groupCompletion) {
                return 0;
              }
              // doing checks for 0 to account for divide by 0 issues:
              const completionRateA =
                a.groupCompletion.numStudents === 0
                  ? 0
                  : a.groupCompletion.numFinishedStudents /
                    a.groupCompletion.numStudents;
              const completionRateB =
                b.groupCompletion.numStudents === 0
                  ? 0
                  : b.groupCompletion.numFinishedStudents /
                    b.groupCompletion.numStudents;
              return completionRateB - completionRateA;
            },
            render: ({ groupCompletion, launchAt }) => {
              const launchDate = launchAt ? new Date(launchAt) : undefined;
              const notLaunchedYet = launchDate && launchDate > new Date();
              if (notLaunchedYet) {
                return 'Not Launched Yet';
              }
              if (!groupCompletion) {
                return;
              }
              const { numFinishedStudents, numStudents } = groupCompletion;
              return `${numFinishedStudents} of ${numStudents} students`;
            },
          },
          {
            title: 'AVG SCORE',
            field: 'averageScore',
            render: (rowData: ResultsTableData) => {
              if (rowData.averageScore !== undefined) {
                return `${rowData.averageScore}%`;
              }

              return '';
            },
          },
          {
            title: 'DUE DATE',
            field: 'dueDate',
            sorting: false,
            render: (rowData: ResultsTableData) => {
              if (!rowData.due) {
                return '';
              }

              return format(rowData.due, dateFormat);
            },
          },
        ]}
      />
    </div>
  );
}
