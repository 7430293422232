import { useMutation } from '@apollo/client';
import { Button, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import type { QuestionAttributesFragment } from '../../../../../../gql/fragments/__generated__/question.generated';
import { CreateAssignmentsQuestionDocument } from '../../../../../../gql/mutations/__generated__/assignmentsQuestion.generated';
import {
  CreateQuestionDocument,
  type CreateQuestionMutationVariables,
} from '../../../../../../gql/mutations/__generated__/question.generated';
import { onError } from '../../../../../../utils/apollo/apolloHelper';
import { updateGetAssignmentCache } from '../../../../../../utils/apollo/updateGetAssignmentCache';
import { SideDrawer } from '../../../../../shared/Layout/SideDrawer';
import { AlertsContext } from '../../../../Alerts/context';
import Form from '../../../../Questions/Form/Form';
import { ExistingQuestions } from '../../Questions/ExistingQuestions';
import { AssignmentEditorModes } from '../NormalAssignment';

const drawerWidth = 800;

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: { marginRight: theme.spacing(1) },
  drawerContentContainer: {
    maxWidth: '800px',
    overflow: 'auto',
    maxHeight: '100%',
  },
  drawer: {
    width: drawerWidth,
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
  },
  closeDrawerButton: {
    width: 20,
    height: 20,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  flex: {
    display: 'flex',
  },
  createAndAddButton: {
    marginLeft: theme.spacing(1),
  },
}));

type SearchOrCreateProps = {
  mode?: AssignmentEditorModes;
  setMode: React.Dispatch<
    React.SetStateAction<AssignmentEditorModes | undefined>
  >;
  setQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export function SearchOrCreate({
  mode,
  setMode,
  setQuestionId,
}: SearchOrCreateProps) {
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const classes = useStyles();
  const match = useRouteMatch();
  const { dispatch } = useContext(AlertsContext);
  const [createAssignmentsQuestion] = useMutation(
    CreateAssignmentsQuestionDocument,
    {
      onError: onError(dispatch),
      update: updateGetAssignmentCache(assignmentId),
    }
  );
  const [createQuestion] = useMutation(CreateQuestionDocument, {
    onCompleted: (data) => {
      const { id: questionId } = data.createQuestion;
      createAssignmentsQuestion({ variables: { questionId, assignmentId } });
    },
    onError: onError(dispatch),
  });

  const handleSubmit = (
    question: CreateQuestionMutationVariables
  ): Promise<{ __typename?: 'Question' } & QuestionAttributesFragment> => {
    // Prevents the form from submitting twice
    setTriggerSubmit(false);
    return new Promise((resolve) => {
      createQuestion({ variables: question }).then((data) => {
        if (data?.data?.createQuestion) {
          resolve(data.data.createQuestion);
        }
      });
    });
  };

  const renderDrawerContent = () => {
    switch (mode) {
      case AssignmentEditorModes.CREATE:
        return (
          <div className={classes.drawerContentContainer}>
            <Form
              handleSubmit={handleSubmit}
              submitLoading={false}
              hideSubmitButton
              triggerSubmit={triggerSubmit}
            />
          </div>
        );

      case AssignmentEditorModes.CREATE_VARIATIONS:
        return (
          <div className={classes.drawerContentContainer}>
            <Form
              handleSubmit={handleSubmit}
              submitLoading={false}
              hideSubmitButton
              triggerSubmit={triggerSubmit}
              postSubmitCallback={(data: any) => {
                if (data && data.id) {
                  setQuestionId(data.id);
                }
              }}
            />
          </div>
        );

      case AssignmentEditorModes.SEARCH:
        return (
          <div className={classes.drawerContentContainer}>
            <ExistingQuestions />
          </div>
        );

      default:
        return null;
    }
  };

  const handleDrawerClose = () => {
    setMode(undefined);
  };

  const handleCreate = () => setMode(AssignmentEditorModes.CREATE);
  const handleSearch = () => setMode(AssignmentEditorModes.SEARCH);
  const handleCreateVariations = () => {
    setMode(AssignmentEditorModes.CREATE_VARIATIONS);
    setTriggerSubmit(true);
  };

  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          className={classes.button}
        >
          Search
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          className={classes.button}
        >
          Create
        </Button>
        <Link to={`${match.url}/ai-create`}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
          >
            AI Create
          </Button>
        </Link>
      </div>

      <SideDrawer
        isOpen={
          (mode &&
            [
              AssignmentEditorModes.SEARCH,
              AssignmentEditorModes.CREATE,
            ].includes(mode)) ||
          false
        }
        onClose={handleDrawerClose}
        title={
          mode === AssignmentEditorModes.CREATE
            ? 'Create Question'
            : 'Search and Copy Question'
        }
        footerContent={
          mode === AssignmentEditorModes.CREATE ? (
            <>
              <Button
                variant="contained"
                onClick={() => setTriggerSubmit(true)}
              >
                Create{' '}
              </Button>
              <Button
                variant="outlined"
                onClick={handleCreateVariations}
                className={classes.createAndAddButton}
              >
                Create and Add Variations{' '}
              </Button>
            </>
          ) : null
        }
      >
        {renderDrawerContent()}
      </SideDrawer>
    </div>
  );
}
