import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GroupLiteQueryVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
}>;

export type GroupLiteQuery = {
  __typename?: 'Query';
  group: {
    __typename?: 'Group';
    id: string;
    name: string;
    groupCode: string;
    freezePersonalDeck: boolean;
    unfreezeInProgress: boolean;
    enrollments?: Array<{
      __typename?: 'Enrollment';
      id: string;
      enrollmentStatus: Types.EnrollmentStatusEnum;
      lastActivity?: any | null;
      student: {
        __typename?: 'Student';
        id: string;
        email: string;
        fullName?: string | null;
        sortName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
      };
    }> | null;
  };
};

export type GroupQueryVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
}>;

export type GroupQuery = {
  __typename?: 'Query';
  group: {
    __typename?: 'Group';
    id: string;
    name: string;
    groupCode: string;
    lateAssignmentsData?: any | null;
    personalDeckCounts?: any | null;
    freezePersonalDeck: boolean;
    unfreezeInProgress: boolean;
    freezeDate?: any | null;
    enrollments?: Array<{
      __typename?: 'Enrollment';
      id: string;
      enrollmentStatus: Types.EnrollmentStatusEnum;
      lastActivity?: any | null;
      unfreezePersonalDeck: boolean;
      student: {
        __typename?: 'Student';
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        fullName?: string | null;
        email: string;
        sortName?: string | null;
      };
    }> | null;
  };
};

export type GroupsQueryVariables = Types.Exact<{
  groupIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type GroupsQuery = {
  __typename?: 'Query';
  groups: Array<{
    __typename?: 'Group';
    id: string;
    name: string;
    enrollments?: Array<{
      __typename?: 'Enrollment';
      enrollmentStatus: Types.EnrollmentStatusEnum;
      id: string;
      student: {
        __typename?: 'Student';
        id: string;
        fullName?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        email: string;
      };
    }> | null;
  }>;
};

export const GroupLiteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groupLite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freezePersonalDeck' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unfreezeInProgress' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'groupViewEnrollmentAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'groupViewEnrollmentAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Enrollment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'student' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortName' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupLiteQuery, GroupLiteQueryVariables>;
export const GroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'group' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'group' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lateAssignmentsData' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'personalDeckCounts' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freezePersonalDeck' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unfreezeInProgress' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'freezeDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastActivity' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unfreezePersonalDeck' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupQuery, GroupQueryVariables>;
export const GroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'groups' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentStatus' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'lastName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GroupsQuery, GroupsQueryVariables>;
