import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateQuestionMutationVariables = Types.Exact<{
  question: Types.QuestionInput;
  fromSmartCreate?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type CreateQuestionMutation = {
  __typename?: 'Mutation';
  createQuestion: {
    __typename?: 'Question';
    richText: any;
    numAttemptsUntilAnswerShown: number;
    id: string;
    state: Types.QuestionStateEnum;
    plainText: string;
    questionType: Types.QuestionTypeEnum;
    copiedFromId?: string | null;
    parent?: { __typename?: 'Question'; id: string } | null;
    variations?: Array<{ __typename?: 'Question'; id: string }> | null;
    freeResponseAnswer?: {
      __typename?: 'FreeResponseAnswer';
      id: string;
      text: string;
      plainText: string;
      richText: any;
      comparable: boolean;
      fraRequiredKeywords: Array<{
        __typename?: 'FraRequiredKeyword';
        id: string;
        keyword: string;
      }>;
    } | null;
    shortAnswerAnswers?: Array<{
      __typename?: 'ShortAnswerAnswer';
      id: string;
      isCaseSensitive: boolean;
      ignoreWhitespaces: boolean;
      text: string;
      plainText: string;
      richText: any;
      hidden: boolean;
    }> | null;
    multipleChoiceAnswerChoices?: Array<{
      __typename?: 'MultipleChoiceAnswerChoice';
      id: string;
      isCorrect: boolean;
      text: string;
      plainText: string;
      richText: any;
    }> | null;
    supplement?: {
      __typename?: 'Supplement';
      id: string;
      plainText: string;
      richText: any;
    } | null;
    standards?: Array<{
      __typename?: 'Standard';
      id: string;
      title: string;
      description: string;
    }> | null;
  };
};

export type UpdateQuestionMutationVariables = Types.Exact<{
  question: Types.QuestionInput;
}>;

export type UpdateQuestionMutation = {
  __typename?: 'Mutation';
  updateQuestion: {
    __typename?: 'Question';
    richText: any;
    numAttemptsUntilAnswerShown: number;
    id: string;
    state: Types.QuestionStateEnum;
    plainText: string;
    questionType: Types.QuestionTypeEnum;
    copiedFromId?: string | null;
    variations?: Array<{ __typename?: 'Question'; id: string }> | null;
    freeResponseAnswer?: {
      __typename?: 'FreeResponseAnswer';
      id: string;
      text: string;
      plainText: string;
      richText: any;
      comparable: boolean;
      fraRequiredKeywords: Array<{
        __typename?: 'FraRequiredKeyword';
        id: string;
        keyword: string;
      }>;
    } | null;
    shortAnswerAnswers?: Array<{
      __typename?: 'ShortAnswerAnswer';
      id: string;
      isCaseSensitive: boolean;
      ignoreWhitespaces: boolean;
      text: string;
      plainText: string;
      richText: any;
      hidden: boolean;
    }> | null;
    multipleChoiceAnswerChoices?: Array<{
      __typename?: 'MultipleChoiceAnswerChoice';
      id: string;
      isCorrect: boolean;
      text: string;
      plainText: string;
      richText: any;
    }> | null;
    supplement?: {
      __typename?: 'Supplement';
      id: string;
      plainText: string;
      richText: any;
    } | null;
    standards?: Array<{
      __typename?: 'Standard';
      id: string;
      title: string;
      description: string;
    }> | null;
  };
};

export type DeleteQuestionMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
}>;

export type DeleteQuestionMutation = {
  __typename?: 'Mutation';
  deleteQuestion: boolean;
};

export type ToggleQuestionActiveMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
}>;

export type ToggleQuestionActiveMutation = {
  __typename?: 'Mutation';
  toggleQuestionActive: {
    __typename?: 'Question';
    id: string;
    state: Types.QuestionStateEnum;
  };
};

export type UpdateQuestionStandardsMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
  standardIds:
    | Array<Types.Scalars['ID']['input']>
    | Types.Scalars['ID']['input'];
}>;

export type UpdateQuestionStandardsMutation = {
  __typename?: 'Mutation';
  updateQuestionStandards: Array<{
    __typename?: 'Standard';
    id: string;
    title: string;
    description: string;
  }>;
};

export type BulkUploadFreeResponseMutationVariables = Types.Exact<{
  questions: Array<Types.BulkFreeResponseInput> | Types.BulkFreeResponseInput;
  courseId: Types.Scalars['ID']['input'];
}>;

export type BulkUploadFreeResponseMutation = {
  __typename?: 'Mutation';
  bulkUploadFreeResponse: number;
};

export type BulkUploadShortAnswerMutationVariables = Types.Exact<{
  questions: Array<Types.BulkShortAnswerInput> | Types.BulkShortAnswerInput;
  courseId: Types.Scalars['ID']['input'];
}>;

export type BulkUploadShortAnswerMutation = {
  __typename?: 'Mutation';
  bulkUploadShortAnswer: number;
};

export type BulkUploadMultipleChoiceMutationVariables = Types.Exact<{
  questions:
    | Array<Types.BulkMultipleChoiceInput>
    | Types.BulkMultipleChoiceInput;
  courseId: Types.Scalars['ID']['input'];
}>;

export type BulkUploadMultipleChoiceMutation = {
  __typename?: 'Mutation';
  bulkUploadMultipleChoice: number;
};

export type BulkUploadSelectAllMutationVariables = Types.Exact<{
  questions: Array<Types.BulkSelectAllInput> | Types.BulkSelectAllInput;
  courseId: Types.Scalars['ID']['input'];
}>;

export type BulkUploadSelectAllMutation = {
  __typename?: 'Mutation';
  bulkUploadSelectAll: number;
};

export type UpdateQuestionSupplementMutationVariables = Types.Exact<{
  richText?: Types.InputMaybe<Types.Scalars['JSON']['input']>;
  plainText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  questionId: Types.Scalars['ID']['input'];
}>;

export type UpdateQuestionSupplementMutation = {
  __typename?: 'Mutation';
  updateQuestionSupplement: { __typename?: 'Question'; id: string };
};

export type GenerateQuestionVariationMutationVariables = Types.Exact<{
  baseQuestionId: Types.Scalars['ID']['input'];
}>;

export type GenerateQuestionVariationMutation = {
  __typename?: 'Mutation';
  generateQuestionVariation: { __typename?: 'Question'; id: string };
};

export type UpdateQuestionReviewSeverityMutationVariables = Types.Exact<{
  questionId: Types.Scalars['ID']['input'];
  reviewSeverity: Types.Scalars['Int']['input'];
}>;

export type UpdateQuestionReviewSeverityMutation = {
  __typename?: 'Mutation';
  updateQuestionReviewSeverity: {
    __typename?: 'Question';
    id: string;
    reviewSeverity: number;
  };
};

export const CreateQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'question' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QuestionInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'fromSmartCreate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'question' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fromSmartCreate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'fromSmartCreate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'parent' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'questionAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateQuestionMutation,
  CreateQuestionMutationVariables
>;
export const UpdateQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'question' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'QuestionInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'question' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'question' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'questionAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateQuestionMutation,
  UpdateQuestionMutationVariables
>;
export const DeleteQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteQuestionMutation,
  DeleteQuestionMutationVariables
>;
export const ToggleQuestionActiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'toggleQuestionActive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'toggleQuestionActive' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'state' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ToggleQuestionActiveMutation,
  ToggleQuestionActiveMutationVariables
>;
export const UpdateQuestionStandardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateQuestionStandards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateQuestionStandards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateQuestionStandardsMutation,
  UpdateQuestionStandardsMutationVariables
>;
export const BulkUploadFreeResponseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'bulkUploadFreeResponse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'BulkFreeResponseInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUploadFreeResponse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BulkUploadFreeResponseMutation,
  BulkUploadFreeResponseMutationVariables
>;
export const BulkUploadShortAnswerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BulkUploadShortAnswer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'BulkShortAnswerInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUploadShortAnswer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BulkUploadShortAnswerMutation,
  BulkUploadShortAnswerMutationVariables
>;
export const BulkUploadMultipleChoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BulkUploadMultipleChoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'BulkMultipleChoiceInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUploadMultipleChoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BulkUploadMultipleChoiceMutation,
  BulkUploadMultipleChoiceMutationVariables
>;
export const BulkUploadSelectAllDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BulkUploadSelectAll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questions' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'BulkSelectAllInput' },
                },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bulkUploadSelectAll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BulkUploadSelectAllMutation,
  BulkUploadSelectAllMutationVariables
>;
export const UpdateQuestionSupplementDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateQuestionSupplement' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'richText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'plainText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateQuestionSupplement' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'richText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'richText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plainText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'plainText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateQuestionSupplementMutation,
  UpdateQuestionSupplementMutationVariables
>;
export const GenerateQuestionVariationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateQuestionVariation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'baseQuestionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'generateQuestionVariation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'baseQuestionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'baseQuestionId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateQuestionVariationMutation,
  GenerateQuestionVariationMutationVariables
>;
export const UpdateQuestionReviewSeverityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateQuestionReviewSeverity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'reviewSeverity' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateQuestionReviewSeverity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'reviewSeverity' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'reviewSeverity' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewSeverity' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateQuestionReviewSeverityMutation,
  UpdateQuestionReviewSeverityMutationVariables
>;
