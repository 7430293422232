import { useSubscription } from '@apollo/client';
import { produce } from 'immer';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { QuestionBreakdownDocument } from '../../../../../../gql/subscriptions/__generated__/assignment.generated';
import { QuestionsBreakdownContext } from '../../../../Groups/Assignments/QuestionsBreakdownContext';

type QuestionsBreakdownSubscriptionProps = {
  selectedGroupId: string;
};

export function QuestionsBreakdownSubscription({
  selectedGroupId,
}: QuestionsBreakdownSubscriptionProps) {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const { setQuestionsBreakdown, questionsBreakdown } = useContext(
    QuestionsBreakdownContext
  );
  const { data: questionBreakdownData, error } = useSubscription(
    QuestionBreakdownDocument,
    {
      variables: { assignmentId },
    }
  );

  useEffect(() => {
    if (
      !questionBreakdownData?.questionBreakdown?.results ||
      !questionsBreakdown
    ) {
      return;
    }
    const { results } = questionBreakdownData.questionBreakdown;
    if (
      selectedGroupId !== 'all' &&
      selectedGroupId.toString() !== results.groupId.toString()
    ) {
      // account for group filter when determining what results to show in live
      // update
      return;
    }
    const updated = produce(questionsBreakdown, (draft: any) => {
      const questionType = Object.keys(results)[0];
      const questionId = Object.keys(results[questionType])[0];
      const payload = results[questionType][questionId];
      switch (questionType) {
        case 'freeResponseBreakdown': {
          const selfAssessment = Object.keys(payload)[0];
          const studentId = Object.keys(payload[selfAssessment])[0];
          if (!draft[questionType][questionId]) {
            draft[questionType][questionId] = {};
          }
          if (!draft[questionType][questionId][selfAssessment]) {
            draft[questionType][questionId][selfAssessment] = {};
          }
          draft[questionType][questionId][selfAssessment][studentId] =
            payload[selfAssessment][studentId];
          break;
        }
        case 'mcBreakdown': {
          if (!draft[questionType][questionId]) {
            draft[questionType][questionId] = {};
          }
          Object.keys(payload).forEach((choiceId) => {
            Object.keys(payload[choiceId]).forEach((studentId) => {
              const studentPayload = payload[choiceId][studentId];
              if (!draft[questionType][questionId][choiceId]) {
                draft[questionType][questionId][choiceId] = {};
              }
              draft[questionType][questionId][choiceId][studentId] =
                studentPayload;
            });
          });
          break;
        }
        case 'shortAnswerBreakdown': {
          const shortAnswerText = Object.keys(payload)[0];
          const studentId = Object.keys(payload[shortAnswerText])[0];
          const studentPayload = payload[shortAnswerText][studentId];
          if (!draft[questionType][questionId]) {
            draft[questionType][questionId] = {};
          }
          if (!draft[questionType][questionId][shortAnswerText]) {
            draft[questionType][questionId][shortAnswerText] = {};
          }
          draft[questionType][questionId][shortAnswerText][studentId] =
            studentPayload;
          break;
        }
        default:
          break;
      }
    });
    setQuestionsBreakdown(updated);
  }, [
    questionBreakdownData,
    setQuestionsBreakdown,
    questionsBreakdown,
    error,
    selectedGroupId,
  ]);

  return null;
}
