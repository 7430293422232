import { useQuery } from '@apollo/client';
import {
  ArrowDropDown,
  ExitToApp,
  Forum,
  Help,
  Menu as MenuIcon,
  Settings,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { asHumanText } from '@podsie/utils/string.js';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../../../../Auth0Context';
import { TeacherLightDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { ResourceCenterButton } from '../../Userflow';
import { drawerWidth } from '../Nav';
import { HeaderTitle } from './HeaderTitle';

export const APP_HEADER_HEIGHT = 64;

type StyleProps = {
  drawerOpen: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: APP_HEADER_HEIGHT,
  },
  icon: {
    marginRight: theme.spacing(1),
    paddingTop: '4px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    border: `1px solid ${theme.palette.secondary.main}`,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  appBar: {
    height: APP_HEADER_HEIGHT,
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
    [theme.breakpoints.up('lg')]: {
      width: ({ drawerOpen }: StyleProps) =>
        drawerOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
      marginLeft: ({ drawerOpen }: StyleProps) =>
        drawerOpen ? `${drawerWidth}px` : 0,
    },

    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('lg')]: {
    //   display: 'none',
    // },
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  username: {
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '80vw',
    },
  },
  right: {
    display: 'flex',
  },
  features: {
    alignItems: 'center',
    display: 'flex',
    gap: theme.spacing(3),
    '& div': {
      display: 'flex !important',
      alignItems: 'center',
    },
  },
  button: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
  },
}));

type HeaderProps = {
  handleDrawerToggle: () => void;
  drawerOpen: boolean;
};

export function Header({ handleDrawerToggle, drawerOpen }: HeaderProps) {
  const history = useHistory();
  const classes = useStyles({ drawerOpen });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { dispatch } = useContext(AlertsContext);
  const teacherQuery = useQuery(TeacherLightDocument, {
    onError: onError(dispatch),
  });
  const teacherName = asHumanText(teacherQuery.data?.teacher.fullName);

  const open = Boolean(anchorEl);
  const { logout, user } = useAuth0();
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSettings = () => {
    history.push('/settings');
    setAnchorEl(null);
  };

  const handleEditProfile = () => {
    history.push('/profile');
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const isMicrosoft = user['https://podsie.org/microsoft-auth'];
    const params = {
      federated: !!isMicrosoft,
    };
    logout({ logoutParams: params });
  };

  return (
    <AppBar position="fixed" className={classes.root}>
      <Toolbar className={classes.appBar}>
        <div className={classes.leftContainer}>
          <IconButton
            color="inherit"
            aria-label="open menu"
            edge="start"
            className={classes.menuButton}
            onClick={handleDrawerToggle}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <HeaderTitle />
        </div>
        <div className={classes.right}>
          <div className={classes.features}>
            <ResourceCenterButton />
            <Button
              aria-label="User Menu Button"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              endIcon={<ArrowDropDown />}
              onClick={handleMenu}
              startIcon={
                <Avatar
                  className={classes.avatar}
                  alt={teacherName}
                  src={user.picture}
                />
              }
            >
              {teacherName && (
                <span className={classes.username}>{teacherName}</span>
              )}
            </Button>
          </div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={handleSettings}>
              <div className={classes.container}>
                <span className={classes.icon}>
                  <Settings />
                </span>
                <span>Settings</span>
              </div>
            </MenuItem>
            <MenuItem onClick={handleEditProfile}>
              <div className={classes.container}>
                <span className={classes.icon}>
                  <AccountCircleIcon />
                </span>
                <span>Edit Profile</span>
              </div>
            </MenuItem>
            <a
              href="https://support.podsie.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem>
                <div className={classes.container}>
                  <span className={classes.icon}>
                    <Help />
                  </span>
                  <span>Help/Resources</span>
                </div>
              </MenuItem>
            </a>
            <a
              href="https://community.podsie.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MenuItem>
                <div className={classes.container}>
                  <span className={classes.icon}>
                    <Forum />
                  </span>
                  <span>Community</span>
                </div>
              </MenuItem>
            </a>
            <MenuItem onClick={handleLogout}>
              <div className={classes.container}>
                <span className={classes.icon}>
                  <ExitToApp />
                </span>
                <span>Log Out</span>
              </div>
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
