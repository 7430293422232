import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
  type Theme,
} from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import userflow from 'userflow.js';
import { CreateAssignmentDocument } from '../../../../gql/mutations/__generated__/assignment.generated';
import { AssignmentTypeEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    width: 100,
  },
  nextButton: {
    width: 100,
    color: theme.palette.common.white,
  },
  content: {
    width: '90%',
    marginBottom: theme.spacing(10),
    paddingTop: '0px !important',
  },
  instruction: {
    color: theme.palette.primary.main,
  },
  helperText: {
    fontSize: 12,
    paddingLeft: '32px',
    paddingBottom: '12px',
  },
  radioButton: {
    marginBottom: '-12px',
  },
  assignmentName: {
    paddingLeft: '32px',
  },
  inputLabel: {
    fontSize: 12,
    paddingLeft: '32px',
  },
}));

type AssignmentCreateFormProps = {
  open: boolean;
  handleClose: () => void;
};

export function AssignmentCreateForm({
  handleClose,
  open,
}: AssignmentCreateFormProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);

  const [name, setName] = useState('');
  const [radioError, setRadioError] = useState(false);
  const [assignmentType, setAssignmentType] = useState('standard');
  const history = useHistory();

  const assignmentName =
    assignmentType === AssignmentTypeEnum.Standard
      ? name
      : 'Personal Deck Assignment';
  const inputAssignmentType =
    assignmentType === 'standard'
      ? AssignmentTypeEnum.Standard
      : AssignmentTypeEnum.Distributed;
  const [createAssignment, { loading }] = useMutation(
    CreateAssignmentDocument,
    {
      variables: {
        name: assignmentName,
        assignmentType: inputAssignmentType,
      },
      onError: onError(dispatch),
      onCompleted: (data) => {
        userflow.track('userflow_assignment_created', {
          assignment_name: assignmentName,
          from_page: 'assignments',
          assignment_type: inputAssignmentType,
        });
        history.push(`/assignments/${data.createAssignment.id}`);
      },
    }
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (assignmentType === '') {
      setRadioError(true);
      return;
    }
    createAssignment();
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRadioError(false);
    setName(e.target.value);
  };

  const handleAssignmentTypeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRadioError(false);
    setAssignmentType(e.target.value);
  };

  const errorHelper = radioError ? (
    <FormHelperText error={radioError} className={classes.helperText}>
      Please select an assignment type.
    </FormHelperText>
  ) : null;
  const isLoading = loading;

  return (
    <Dialog onClose={handleClose} fullWidth maxWidth="xs" open={open}>
      <DialogTitle className={classes.title}>CREATE ASSIGNMENT</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent className={classes.content}>
          <DialogContentText>Select Assignment Type</DialogContentText>
          <FormControl>
            <RadioGroup
              value={assignmentType}
              aria-label="assignment"
              onChange={handleAssignmentTypeChange}
            >
              <FormControlLabel
                className={classes.radioButton}
                value="standard"
                control={<Radio />}
                label="Standardized Assignment"
              />
              <FormHelperText className={classes.helperText}>
                Assignments where teachers create or select specific questions
                for students to answer.
              </FormHelperText>
              <div className={classes.assignmentName}>
                <TextField
                  value={name}
                  onChange={handleNameChange}
                  size="small"
                  required={assignmentType === 'standard'}
                  fullWidth
                  InputProps={{
                    name: 'assignment-name',
                  }}
                  variant="outlined"
                  label="Enter Assignment Name"
                />
              </div>
              <FormControlLabel
                className={classes.radioButton}
                value={AssignmentTypeEnum.Distributed}
                control={<Radio />}
                label="Personal Deck Assignment"
              />
              <FormHelperText className={classes.helperText}>
                Personalized assignments that focus on keeping students
                accountable to regularly completing their Personal Deck
                Questions.
              </FormHelperText>
              {errorHelper}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={classes.cancelButton}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className={classes.nextButton}
            variant="contained"
            color="secondary"
          >
            Next
          </Button>
        </DialogActions>
      </form>
      {isLoading && <LinearProgress />}
    </Dialog>
  );
}
