import { useQuery } from '@apollo/client';
import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { asHumanText } from '@podsie/utils/string.js';
import { useContext } from 'react';
import { TeamDocument } from '../../../gql/queries/__generated__/team.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../Alerts/context';
import ListDisplay from './ListDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    marginBottom: theme.spacing(2),
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  itemEl: {
    marginLeft: theme.spacing(1),
  },
  helpkitContainer: {
    marginBottom: theme.spacing(1),
  },
}));

type SubjectTeachersListProps = {
  teamId?: string;
  groupCode?: string;
};

export function SubjectTeachersList({
  teamId,
  groupCode,
}: SubjectTeachersListProps) {
  const classes = useStyles();

  const { dispatch } = useContext(AlertsContext);
  const { data: teamData } = useQuery(TeamDocument, {
    onError: onError(dispatch),
    variables: {
      groupCode,
      id: teamId,
    },
  });

  return (
    <div>
      <ListDisplay>
        <ol>
          {teamData?.team.teachers.map((teacher) => {
            const teacherIdentifier = teacher.fullName ?? teacher.email;

            const entryText = teacher.school
              ? `${teacherIdentifier} - ${
                  asHumanText(teacher.school.name) ?? '(unnamed school)'
                }`
              : teacherIdentifier;

            return (
              <li key={teacher.email} className={classes.listItem}>
                <div className={classes.listItemContainer}>
                  <Typography>{entryText}</Typography>
                </div>
              </li>
            );
          })}
        </ol>
      </ListDisplay>
    </div>
  );
}

export default SubjectTeachersList;
