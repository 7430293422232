import { Tab } from '@mui/material';
import { Link } from 'react-router-dom';

type LinkTabProps = {
  label: string;
  href: string;
  value: string;
};

function LinkTab({ label, href, value }: LinkTabProps) {
  return <Tab component={Link} to={href} label={label} value={value} />;
}

export default LinkTab;
