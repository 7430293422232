import { Alert, Button, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { asError } from '@podsie/utils/error.js';
import React from 'react';
import {
  BulkFreeResponseInput,
  BulkMultipleChoiceInput,
  BulkSelectAllInput,
  BulkShortAnswerInput,
} from '../../../gql/types';
import {
  parseBulkFreeResponseUpload,
  parseBulkMultipleChoiceUpload,
  parseBulkSelectAllUpload,
  parseBulkShortAnswerUpload,
} from '../../../utils/questions/bulkUpload';
import { replaceDashWithSpaceAndUpcaseFirstLetter } from '../../../utils/string';
import { BulkUploadType } from './QuestionsBulkUpload';
import QuestionsBulkUploadPreview from './QuestionsBulkUploadPreview';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  fileInput: {
    border: `1px solid ${theme.palette.mint.dark}`,
    borderRadius: 4,
    padding: theme.spacing(2),
  },
  buttonsContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  errorContainer: {
    marginTop: theme.spacing(2),
  },
  clarification: {
    marginTop: theme.spacing(2),
  },
}));

type QuestionTypeBulkUploadProps = {
  questionType: BulkUploadType;
  setQuestionType: React.Dispatch<
    React.SetStateAction<BulkUploadType | undefined>
  >;
  handleClose: () => void;
  setBulkUploadType: React.Dispatch<
    React.SetStateAction<BulkUploadType | undefined>
  >;
};

export type BulkInputTypes = Array<
  | BulkFreeResponseInput
  | BulkShortAnswerInput
  | BulkMultipleChoiceInput
  | BulkSelectAllInput
>;

export function QuestionTypeBulkUpload({
  questionType,
  setBulkUploadType,
  setQuestionType,
}: QuestionTypeBulkUploadProps) {
  const classes = useStyles();
  const [numUploaded, setNumUploaded] = React.useState<number | undefined>();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [questionsPreview, setQuestionsPreview] =
    React.useState<BulkInputTypes>([]);
  const resetQuestionType = () => {
    setErrorMessage('');
    setQuestionType(undefined);
  };
  const parseAndDoBulkUpload = (csv: string | Buffer) => {
    try {
      if (questionType === 'free-response') {
        const bulkFreeResponseQuestions = parseBulkFreeResponseUpload(csv);

        if (bulkFreeResponseQuestions.length) {
          // display preview of questions:
          setQuestionsPreview(bulkFreeResponseQuestions);
        }
      } else if (questionType === 'short-answer') {
        const bulkShortAnswerQuestions = parseBulkShortAnswerUpload(csv);
        if (bulkShortAnswerQuestions.length) {
          // display preview of questions:
          setQuestionsPreview(bulkShortAnswerQuestions);
        }
      } else if (questionType === 'multiple-choice') {
        const bulkMultipleChoiceQuestions = parseBulkMultipleChoiceUpload(csv);
        if (bulkMultipleChoiceQuestions.length) {
          // display preview of questions:
          setQuestionsPreview(bulkMultipleChoiceQuestions);
        }
      } else if (questionType === 'select-all') {
        const bulkSelectAllQuestions = parseBulkSelectAllUpload(csv);
        if (bulkSelectAllQuestions.length) {
          // display preview of questions:
          setQuestionsPreview(bulkSelectAllQuestions);
        }
      }
    } catch (e) {
      setErrorMessage(asError(e).message);
    }
  };
  const handleFileChosen = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    setErrorMessage('');
    fileReader.onloadend = () => {
      if (fileReader.result) {
        parseAndDoBulkUpload(fileReader.result as Buffer);
      }
    };
    const files = e?.target?.files;
    if (files && files[0]) {
      fileReader.readAsText(files[0]);
    }

    e.target.value = '';
  };
  if (numUploaded) {
    return (
      <div>
        <Typography variant="h6" color="primary">
          {`${numUploaded} questions are being uploaded right now. Check back here in a couple minutes by refreshing your page!`}
        </Typography>
        <Typography variant="body2" className={classes.clarification}>
          If your questions are not showing up in the &lsquo;Questions not
          tagged to learning standards&rsquo; section after 5 minutes, please
          contact hello@podsie.org for support.
        </Typography>
      </div>
    );
  }
  if (questionsPreview.length) {
    return (
      <QuestionsBulkUploadPreview
        questionsPreview={questionsPreview}
        questionType={questionType}
        setQuestionsPreview={setQuestionsPreview}
        setNumUploaded={setNumUploaded}
        setBulkUploadType={setBulkUploadType}
      />
    );
  }
  return (
    <div className={classes.root}>
      <Typography
        variant="h6"
        color="primary"
      >{`Bulk Upload ${replaceDashWithSpaceAndUpcaseFirstLetter(
        questionType
      )} Questions`}</Typography>

      <section className={classes.buttonsContainer}>
        <Button
          variant="outlined"
          onClick={resetQuestionType}
          className={classes.backButton}
        >
          Go Back
        </Button>
        <input
          className={classes.fileInput}
          type="file"
          onChange={handleFileChosen}
          accept="text/plain,.csv"
        />
      </section>

      <div className={classes.errorContainer}>
        {errorMessage && (
          <div>
            <Alert severity="error">{errorMessage}</Alert>
          </div>
        )}
      </div>
    </div>
  );
}

export default QuestionTypeBulkUpload;
