import {
  ExpandMore,
  HelpOutline,
  SentimentSatisfied,
} from '@mui/icons-material';
import {
  Button,
  Collapse,
  Tooltip,
  Typography,
  useTheme,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useState } from 'react';
import type { QuestionAttributesFragment } from '../../../../../../gql/fragments/__generated__/question.generated';
import type {
  FreeResponseAttributesFragment,
  MultipleChoiceResponseAttributesFragment,
  ShortAnswerResponseAttributesFragment,
} from '../../../../../../gql/fragments/__generated__/response.generated';
import { ASSESSMENT_VALUE_IS_CORRECT } from '../../../../../../utils/assessmentValueMappings';
import {
  countTotalAttempts,
  questionComplete,
} from '../../../../../../utils/question';
import AttemptsDetails from '../../../../../shared/AttemptsDetails';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  collapseOpener: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)}`,
    },
  },
  collapseSection: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.mint.main,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.secondary.main,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: '',
  },
  attemptLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  incompleteLabel: {
    color: theme.palette.error.main,
  },
  incompleteSpan: {
    display: 'flex',
    alignItems: 'center',
  },
  helpIcon: {
    color: theme.palette.secondary.main,
    fontSize: 16,
  },
  overrideIcon: {
    fontSize: '1.2rem',
    color: theme.palette.error.main,
  },
}));

export type AttemptsProps = {
  multidayFilter?: boolean;
  question: QuestionAttributesFragment;
  freeResponseResponses?: FreeResponseAttributesFragment[] | null;
  shortAnswerResponses?: ShortAnswerResponseAttributesFragment[] | null;
  multipleChoiceResponses?: MultipleChoiceResponseAttributesFragment[] | null;
};

export function Attempts({
  multidayFilter,
  question,
  freeResponseResponses,
  shortAnswerResponses,
  multipleChoiceResponses,
}: AttemptsProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  if (
    !freeResponseResponses ||
    !shortAnswerResponses ||
    !multipleChoiceResponses
  ) {
    return null;
  }
  const responses = {
    freeResponseResponses,
    shortAnswerResponses,
    multipleChoiceResponses,
  };
  const complete = questionComplete(responses);
  const numAttempts = countTotalAttempts(responses);
  const teacherOverride = freeResponseResponses.some((response) => {
    return (
      response.teacherAssessment &&
      !ASSESSMENT_VALUE_IS_CORRECT(response.teacherAssessment) &&
      response.teacherAssessment !== response.selfAssessment
    );
  });
  const labelStyle = {
    color:
      teacherOverride || numAttempts > 1 || (numAttempts === 1 && !complete)
        ? theme.palette.error.main
        : theme.palette.success.main,
  };

  if (multidayFilter) {
    labelStyle.color = theme.palette.primary.main;
  }

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <Button onClick={() => setOpen(!open)} className={classes.expandButton}>
          <span className={classes.attemptLabelContainer}>
            <Typography
              color="secondary"
              variant="body1"
              className={classes.collapseOpener}
              style={labelStyle}
            >
              {`Num Attempts: ${countTotalAttempts({
                freeResponseResponses,
                shortAnswerResponses,
                multipleChoiceResponses,
              })}`}
            </Typography>
            {numAttempts > 0 && (
              <ExpandMore
                className={clsx(classes.expand, {
                  [classes.expandOpen]: open,
                })}
                style={labelStyle}
              />
            )}
            {teacherOverride && (
              <Tooltip title="Teacher gave a different rating than student.">
                <SentimentSatisfied className={classes.overrideIcon} />
              </Tooltip>
            )}
          </span>
          {!complete && (
            <Tooltip
              enterTouchDelay={0}
              title="A question is not complete until it has been answered correctly."
            >
              <span className={classes.incompleteSpan}>
                <HelpOutline className={classes.helpIcon} />
                <Typography variant="body1" className={classes.incompleteLabel}>
                  Incomplete
                </Typography>
              </span>
            </Tooltip>
          )}
        </Button>
      </div>
      {numAttempts > 0 && (
        <Collapse className={classes.collapseSection} in={open}>
          <AttemptsDetails
            question={question}
            freeResponseResponses={freeResponseResponses}
            shortAnswerResponses={shortAnswerResponses}
            multipleChoiceResponses={multipleChoiceResponses}
          />
        </Collapse>
      )}
    </div>
  );
}
