import { useMutation } from '@apollo/client';
import { Button, Stack, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useContext } from 'react';
import { HandleFlaggedResponseDocument } from '../../../../gql/mutations/__generated__/flaggedResponse.generated';
import {
  ActionItemsDocument,
  NotificationsCountDocument,
  type ActionItemQuery,
} from '../../../../gql/queries/__generated__/actionItem.generated';
import { ActionItemStateEnum } from '../../../../gql/types';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';

export type HandleFlaggedShortAnswerResponseProps = {
  response: ActionItemQuery['actionItem']['actionable'];
  actionItemId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function HandleFlaggedShortAnswerResponse({
  response,
  actionItemId,
}: HandleFlaggedShortAnswerResponseProps) {
  const { dispatch } = useContext(AlertsContext);
  const classes = useStyles();
  const [handleFlaggedResponse] = useMutation(HandleFlaggedResponseDocument, {
    refetchQueries(result) {
      const updatedActionItem = result.data?.handleFlaggedResponse;
      // If the action item updated by the mutation matches the given action
      // item and the updated action item's state is archived, we assumed that
      // its state may have changed so we refetch all active queries.
      if (
        updatedActionItem &&
        updatedActionItem.id === actionItemId &&
        updatedActionItem.state === ActionItemStateEnum.Archived
      ) {
        return [NotificationsCountDocument, ActionItemsDocument];
      }

      // Something likely went wrong if:
      // - there's no updated action item returned by the mutation
      // - the updated action item returned by the mutation doesn't match the ID
      //   we've been given
      // - the state is not archived so we should _probably_ still be showing it
      //   as is
      // In any of the above cases, it doesn't seem like anything changed so
      // don't bother refetching anything.
      return [];
    },
    onCompleted(data) {
      if (data.handleFlaggedResponse?.state !== ActionItemStateEnum.Archived)
        return;

      dispatch(
        pushSnack({
          message: 'Notification archived!',
          severity: 'success',
        })
      );
    },
    onError: (e) => {
      console.log(e);
    },
  });

  /**
   * Accept the short answer response that was flagged as possibly being
   * correct. This adds the flagged response as an acceptable answer to the
   * relevant short answer question.
   */
  const acceptTheFlaggedResponse = useCallback(async () => {
    handleFlaggedResponse({
      variables: {
        action: true,
        flaggedResponseId: response.id,
        addStudentAnswerAsAcceptable: true,
      },
    });
  }, [handleFlaggedResponse, response.id]);

  const rejectTheFlaggedResponse = useCallback(async () => {
    handleFlaggedResponse({
      variables: {
        action: true,
        flaggedResponseId: response.id,
        addStudentAnswerAsAcceptable: false,
      },
    });
  }, [handleFlaggedResponse, response.id]);

  if (response.response.__typename !== 'ShortAnswerResponse') {
    return null;
  }

  return (
    <Stack
      direction="column"
      flexGrow={1}
      justifyContent="space-between"
      useFlexGap
    >
      <Typography variant="body1" color="primary" className={classes.label}>
        Student&apos;s answer is:
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={4}
        useFlexGap
      >
        <Button
          key="reject-button"
          onClick={rejectTheFlaggedResponse}
          style={{ flexGrow: 1 }}
          variant="outlined"
        >
          Incorrect
        </Button>
        <Button
          key="accept-button"
          onClick={acceptTheFlaggedResponse}
          style={{ flexGrow: 1 }}
          variant="contained"
        >
          Acceptable
        </Button>
      </Stack>
    </Stack>
  );
}
