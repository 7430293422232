import React, { useEffect, useState } from 'react';

const QuestionsBreakdownContext = React.createContext<{
  questionsBreakdown: any;
  setQuestionsBreakdown: React.Dispatch<React.SetStateAction<any>>;
  hideAnswers?: boolean;
  hideStats?: boolean;
}>({
  questionsBreakdown: {},
  setQuestionsBreakdown: () => null,
  hideAnswers: undefined,
  hideStats: undefined,
});

type QuestionsBreakdownProviderProps = {
  children: React.ReactElement;
  preloadedState?: any;
  hideAnswers?: boolean;
  hideStats?: boolean;
};

const initialState = {
  mcBreakdown: {},
  shortAnswerBreakdown: {},
  freeResponseBreakdown: {},
};

function QuestionsBreakdownProvider({
  children,
  hideAnswers,
  hideStats,
  preloadedState = initialState,
}: QuestionsBreakdownProviderProps) {
  const [questionsBreakdown, setQuestionsBreakdown] = useState(preloadedState);
  useEffect(() => {
    setQuestionsBreakdown(preloadedState);
  }, [preloadedState, setQuestionsBreakdown]);
  return (
    <QuestionsBreakdownContext.Provider
      value={{
        questionsBreakdown,
        setQuestionsBreakdown,
        hideAnswers,
        hideStats,
      }}
    >
      {children}
    </QuestionsBreakdownContext.Provider>
  );
}
export { QuestionsBreakdownContext, QuestionsBreakdownProvider };
