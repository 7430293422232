import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StudentsAssignmentsQuestionsQueryVariables = Types.Exact<{
  studentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  groupsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  enrollmentsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type StudentsAssignmentsQuestionsQuery = {
  __typename?: 'Query';
  studentsAssignmentsQuestions: Array<{
    __typename?: 'StudentsAssignmentsQuestion';
    id: string;
    question: {
      __typename?: 'Question';
      richText: any;
      numAttemptsUntilAnswerShown: number;
      id: string;
      state: Types.QuestionStateEnum;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      copiedFromId?: string | null;
      variations?: Array<{ __typename?: 'Question'; id: string }> | null;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        plainText: string;
        richText: any;
        comparable: boolean;
        fraRequiredKeywords: Array<{
          __typename?: 'FraRequiredKeyword';
          id: string;
          keyword: string;
        }>;
      } | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        id: string;
        isCaseSensitive: boolean;
        ignoreWhitespaces: boolean;
        text: string;
        plainText: string;
        richText: any;
        hidden: boolean;
      }> | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        text: string;
        plainText: string;
        richText: any;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        id: string;
        title: string;
        description: string;
      }> | null;
    };
    freeResponseResponses?: Array<{
      __typename?: 'FreeResponseResponse';
      createdAt: any;
      id: string;
      selfAssessment?: Types.AssessmentValueEnum | null;
      teacherAssessment?: Types.AssessmentValueEnum | null;
      answer: string;
      richText: any;
      autograded: boolean;
    }> | null;
    shortAnswerResponses?: Array<{
      __typename?: 'ShortAnswerResponse';
      createdAt: any;
      id: string;
      answer: string;
      richText: any;
      isCorrect: boolean;
    }> | null;
    multipleChoiceResponses?: Array<{
      __typename?: 'MultipleChoiceResponse';
      createdAt: any;
      id: string;
      isCorrect: boolean;
      selectedMultipleChoiceAnswerChoices: Array<{
        __typename?: 'SelectedMultipleChoiceAnswerChoice';
        id: string;
        multipleChoiceAnswerChoice: {
          __typename?: 'MultipleChoiceAnswerChoice';
          id: string;
          text: string;
          richText: any;
        };
      }>;
    }> | null;
  }>;
};

export type StudentsAssignmentsQuestionQueryVariables = Types.Exact<{
  studentId: Types.Scalars['ID']['input'];
  questionId: Types.Scalars['ID']['input'];
  groupsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  enrollmentsAssignmentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type StudentsAssignmentsQuestionQuery = {
  __typename?: 'Query';
  studentsAssignmentsQuestion: {
    __typename?: 'StudentsAssignmentsQuestion';
    question: {
      __typename?: 'Question';
      richText: any;
      numAttemptsUntilAnswerShown: number;
      id: string;
      state: Types.QuestionStateEnum;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      copiedFromId?: string | null;
      variations?: Array<{ __typename?: 'Question'; id: string }> | null;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        plainText: string;
        richText: any;
        comparable: boolean;
        fraRequiredKeywords: Array<{
          __typename?: 'FraRequiredKeyword';
          id: string;
          keyword: string;
        }>;
      } | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        id: string;
        isCaseSensitive: boolean;
        ignoreWhitespaces: boolean;
        text: string;
        plainText: string;
        richText: any;
        hidden: boolean;
      }> | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        text: string;
        plainText: string;
        richText: any;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        id: string;
        title: string;
        description: string;
      }> | null;
    };
    freeResponseResponses?: Array<{
      __typename?: 'FreeResponseResponse';
      createdAt: any;
      id: string;
      selfAssessment?: Types.AssessmentValueEnum | null;
      teacherAssessment?: Types.AssessmentValueEnum | null;
      answer: string;
      richText: any;
      autograded: boolean;
    }> | null;
    shortAnswerResponses?: Array<{
      __typename?: 'ShortAnswerResponse';
      createdAt: any;
      id: string;
      answer: string;
      richText: any;
      isCorrect: boolean;
    }> | null;
    multipleChoiceResponses?: Array<{
      __typename?: 'MultipleChoiceResponse';
      createdAt: any;
      id: string;
      isCorrect: boolean;
      selectedMultipleChoiceAnswerChoices: Array<{
        __typename?: 'SelectedMultipleChoiceAnswerChoice';
        id: string;
        multipleChoiceAnswerChoice: {
          __typename?: 'MultipleChoiceAnswerChoice';
          id: string;
          text: string;
          richText: any;
        };
      }>;
    }> | null;
  };
};

export const StudentsAssignmentsQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'studentsAssignmentsQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'studentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentsAssignmentsQuestions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'studentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'studentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'questionAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeResponseResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'freeResponseAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shortAnswerResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'shortAnswerResponseAttributes',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'multipleChoiceResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'multipleChoiceResponseAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'freeResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FreeResponseResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selfAssessment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teacherAssessment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autograded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'shortAnswerResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShortAnswerResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'multipleChoiceResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MultipleChoiceResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'selectedMultipleChoiceAnswerChoices',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'multipleChoiceAnswerChoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'richText' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StudentsAssignmentsQuestionsQuery,
  StudentsAssignmentsQuestionsQueryVariables
>;
export const StudentsAssignmentsQuestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'studentsAssignmentsQuestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'studentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'questionId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'studentsAssignmentsQuestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'studentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'studentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'questionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'questionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enrollmentsAssignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'question' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'questionAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeResponseResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'freeResponseAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shortAnswerResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'shortAnswerResponseAttributes',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'multipleChoiceResponses' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'multipleChoiceResponseAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'freeResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FreeResponseResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'selfAssessment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'teacherAssessment' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'autograded' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'shortAnswerResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ShortAnswerResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'answer' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'multipleChoiceResponseAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MultipleChoiceResponse' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'selectedMultipleChoiceAnswerChoices',
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'multipleChoiceAnswerChoice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'richText' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StudentsAssignmentsQuestionQuery,
  StudentsAssignmentsQuestionQueryVariables
>;
