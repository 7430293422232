import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HelpKitInviteCode from '../HelpKitArticles/HelpKitInviteCode';
import HelpKitSubjectGroup from '../HelpKitArticles/HelpKitSubjectGroup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  codeContainer: {
    padding: theme.spacing(2),
  },
  code: {
    borderRadius: 4,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.mint.light,
  },
  header: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
}));

type InviteCodeProps = {
  groupCode: string;
};

export function InviteCode({ groupCode }: InviteCodeProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div>
        <HelpKitSubjectGroup />
      </div>
      <div>
        <HelpKitInviteCode />
      </div>
      <Typography variant="h4" color="primary" className={classes.header}>
        Invite teachers with this code:
      </Typography>
      <div className={classes.codeContainer}>
        <Typography variant="h2" color="primary" className={classes.code}>
          {groupCode}
        </Typography>
      </div>
    </div>
  );
}

export default InviteCode;
