import { useMutation } from '@apollo/client';
import { AutoFixHighOutlined } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Sources } from 'quill';
import React, { useContext, useRef, useState } from 'react';
import ReactQuill, { UnprivilegedEditor, Value } from 'react-quill';
import { useDebouncedCallback } from 'use-debounce';
import { UpdateQuestionDocument } from '../../../../gql/mutations/__generated__/question.generated';
import { GeneratedQuestionsQuery } from '../../../../gql/queries/__generated__/question.generated';
import theme from '../../../../theme';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { questionAnswerString } from '../../../../utils/question';
import { containsImages } from '../../../../utils/quillHelper';
import { streamTokens } from '../../../../utils/stream';
import Editor from '../../../shared/Editor';
import { AlertsContext } from '../../Alerts/context';
import { updateQuestionFields } from './questions';

export function GeneratedQuestionExplanation({
  question,
}: {
  question: GeneratedQuestionsQuery['generatedQuestions'][0];
}) {
  const [explanationDelta, setExplanationDelta] = React.useState(
    question.supplement?.richText.ops
  );
  const [isStreamingExplanation, setIsStreamingExplanation] =
    useState<boolean>(false);
  const [streamingAbortController, setStreamingAbortController] =
    useState<AbortController>(new AbortController());
  const editorRef = useRef<ReactQuill>(null);
  const cancelStream = () => {
    setIsStreamingExplanation(false);
    streamingAbortController.abort();
    editorRef.current?.editor?.enable(true);
  };
  const { dispatch } = useContext(AlertsContext);
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });
  const updateExplanation = (richText: Value, plainText: string) => {
    const changes: Partial<
      GeneratedQuestionsQuery['generatedQuestions'][number]
    > = {
      supplement: {
        ...question.supplement,
        id: question.supplement?.id || '',
        richText: richText,
        plainText: plainText,
      },
    };
    // if plainText is empty, remove the supplement by setting it to null:
    if (!plainText.trim()) {
      changes.supplement = null;
    }

    const questionInput = updateQuestionFields(question, changes);

    updateQuestion({ variables: { question: questionInput } });
  };
  const beginStream = async () => {
    const editor = editorRef.current?.editor;
    if (!editor) return;
    const abortController = new AbortController();
    setStreamingAbortController(abortController);
    setExplanationDelta('');
    setIsStreamingExplanation(true);
    editor.enable(false);
    await streamTokens({
      url: '/question_creation/explain',
      updaterFunc: (token) => {
        setExplanationDelta((prevExplanation: string) => {
          const updated = prevExplanation + token;
          return updated;
        });
      },
      params: {
        question: question.plainText,
        answer: questionAnswerString(question),
        questionType: question.questionType,
      },
      signal: abortController.signal,
    });
    updateExplanation(editor.getContents(), editor.getText());
    setIsStreamingExplanation(false);
    editor.enable(true);
  };
  // Debounce server update calls
  const debouncedServerUpdate = useDebouncedCallback(updateExplanation);
  const handleExplanationChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    if (source !== 'user') return;
    const plainText = editor.getText();
    const richText = editor.getContents();
    setExplanationDelta(richText);
    debouncedServerUpdate(richText, plainText);
  };
  const hasImages = containsImages(question.richText.ops);
  const disabled = hasImages || !question.plainText;
  return (
    <Box sx={{ mt: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontWeight: theme.typography.fontWeightBold, mb: 1 }}>
          Explanation
        </Typography>
        <Button
          startIcon={
            isStreamingExplanation ? (
              <CircularProgress size={14} />
            ) : (
              <AutoFixHighOutlined />
            )
          }
          sx={{ ml: 2, mb: 0, color: theme.palette.green.dark }}
          size="small"
          onClick={isStreamingExplanation ? cancelStream : beginStream}
          variant="text"
          color="primary"
          disabled={disabled}
        >
          {isStreamingExplanation ? 'Cancel' : 'AI Generate'}
        </Button>
      </Box>
      <Editor
        value={explanationDelta}
        onChange={handleExplanationChange}
        customRef={editorRef}
      />
    </Box>
  );
}
