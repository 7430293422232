import ct from 'countries-and-timezones';

const countriesObj = ct.getAllCountries();
export const US = {
  key: 'US',
  name: 'United States',
};
export const countries = [
  { name: 'International', key: 'INTL' },
  ...Object.keys(countriesObj)
    .map((key) => {
      return {
        name: countriesObj[key].name,
        key,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name)),
];
