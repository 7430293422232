import { useLazyQuery, useQuery } from '@apollo/client';
import {
  Button,
  LinearProgress,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { StandardsChartDocument } from '../../../gql/queries/__generated__/standardsChart.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import StyledDialog from '../../shared/Layout/StyledDialog';
import { AlertsContext } from '../Alerts/context';
import HelpKitLearningStandards from '../HelpKitArticles/HelpKitLearningStandards';
import HelpKitSubjectGroup from '../HelpKitArticles/HelpKitSubjectGroup';
import GroupPreviewAndJoin from './GroupPreviewAndJoin';
import InviteCode from './InviteCode';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  rightButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  form: {
    marginTop: theme.spacing(3),
  },
  caption: {},
  textField: {
    marginTop: theme.spacing(2),
  },
  tooltipContainer: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
  cancelButton: {
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fieldContainer: {
    padding: theme.spacing(1),
  },
  loader: {
    height: 4,
    width: 100,
  },
  rightButtonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column',
    },
  },
}));

export function GroupsManagement() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [error, setError] = useState(false);
  const [showInviteCode, setShowInviteCode] = useState(false);
  const { dispatch } = useContext(AlertsContext);
  const { data: teacherData, refetch } = useQuery(TeacherDocument);
  const [getStandardsChart, { loading, data: standardsChartData }] =
    useLazyQuery(StandardsChartDocument, {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setShowPreview(true),
      onError: onError(dispatch),
    });

  const handleOpenJoinGroup = () => {
    setShowInviteCode(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setShowInviteCode(false);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (code.toUpperCase() === groupCode.toUpperCase()) {
      setError(true);
      return;
    }
    // refetch to ensure that we have activeCourseId available:
    refetch().then(() => {
      getStandardsChart({
        variables: {
          standardsChartGroupCode: code,
        },
      });
    });
  };

  const openInviteCode = () => {
    setOpen(true);
    setShowInviteCode(true);
  };

  const groupCode = teacherData?.teacher.activeCourse?.team?.groupCode || '';
  const disabled = !code;

  const form = () => {
    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        <Typography variant="h4" color="primary">
          Use <strong>Invite Code</strong> to join a different Subject Group
        </Typography>
        <HelpKitSubjectGroup />
        <p>
          <Typography variant="caption" className={classes.caption}>
            When you join a different Subject Group, the following happens:
          </Typography>
        </p>
        <ol>
          <li>
            You will be able to access any questions that they have created for
            that Subject Group.
          </li>
          <li>
            All of YOUR questions under this course will become available to the
            teachers in your newly-joined Subject Group.
          </li>
          <li>
            You and the teachers in your newly joined Subject Group will now
            share a set of Learning Standards that you can use to tag your
            questions. ( <HelpKitLearningStandards />)
          </li>
          <li>
            <strong>
              WARNING: Once you join, all of your questions will LOSE their
              current standards tag, and you will have to retag them using the
              new set of Learning Standards.
            </strong>
          </li>
        </ol>
        <div className={classes.fieldContainer}>
          <TextField
            error={error}
            className={classes.textField}
            fullWidth
            value={code}
            onChange={handleChange}
            size="small"
            helperText={
              error ? 'You are already part of this Subject Group!' : ''
            }
            required={true}
            InputProps={{
              name: 'subject group code',
            }}
            variant="outlined"
            label="Enter Invite Code"
          />
        </div>
        <div className={classes.buttonContainer}>
          {loading ? (
            <LinearProgress className={classes.loader} />
          ) : (
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              type="submit"
            >
              Preview Subject Group
            </Button>
          )}
        </div>
      </form>
    );
  };

  const preview = () => {
    if (!standardsChartData) {
      return <div>Something went wrong. Please refresh and try again.</div>;
    }

    return (
      <GroupPreviewAndJoin
        groupCode={code}
        standardsChart={standardsChartData.standardsChart}
        setShowPreview={setShowPreview}
      />
    );
  };

  const dialogContent = () => {
    if (showPreview) {
      return preview();
    }

    if (showInviteCode) {
      return <InviteCode groupCode={groupCode} />;
    }

    return form();
  };
  const title = 'Join Different Subject Group';

  return (
    <div className={classes.root}>
      <div className={classes.rightButtonsContainer}>
        <Button
          variant="contained"
          className={classes.rightButton}
          onClick={handleOpenJoinGroup}
        >
          Join Different Group
        </Button>
        <Button
          variant="contained"
          className={classes.rightButton}
          onClick={openInviteCode}
        >
          Invite
        </Button>
      </div>
      <StyledDialog open={open} title={title} handleClose={handleClose}>
        {dialogContent()}
      </StyledDialog>
    </div>
  );
}

export default GroupsManagement;
