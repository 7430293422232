import { useMutation, useQuery } from '@apollo/client';
import { RemoveCircleOutline } from '@mui/icons-material';
import { IconButton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DeleteAssignmentsQuestionDocument } from '../../../../../../gql/mutations/__generated__/assignmentsQuestion.generated';
import { AssignmentDocument } from '../../../../../../gql/queries/__generated__/assignment.generated';
import { onError } from '../../../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../../../Alerts/context';
import { AssignmentEditorContext } from '../../context';
import { removeAssignmentQuestion } from '../../context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  iconButtons: {
    height: 30,
    padding: 0,
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

type DeleteButtonProps = {
  questionId: string;
};

export function RemoveFromAssignmentButton({ questionId }: DeleteButtonProps) {
  const classes = useStyles();
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const { dispatch: assignmentDispatch } = useContext(AssignmentEditorContext);
  const { dispatch } = useContext(AlertsContext);
  const { refetch } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });

  const [deleteAssignmentsQuestion, { loading: deleteLoading }] = useMutation(
    DeleteAssignmentsQuestionDocument,
    {
      onError: onError(dispatch),
      onCompleted: () => {
        assignmentDispatch(
          removeAssignmentQuestion({
            questionId,
          })
        );
        refetch();
      },
    }
  );
  const handleRemove = () => {
    deleteAssignmentsQuestion({ variables: { assignmentId, questionId } });
  };

  return (
    <IconButton
      aria-label="Remove question from assignment"
      title="Remove Question"
      onClick={handleRemove}
      disabled={deleteLoading}
      className={classes.iconButtons}
      size="large"
    >
      <RemoveCircleOutline />
    </IconButton>
  );
}

export default RemoveFromAssignmentButton;
