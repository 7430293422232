import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Button, Collapse, Divider } from '@mui/material';
import React from 'react';
import { GeneratedQuestionsQuery } from '../../../../gql/queries/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../gql/types';
import theme from '../../../../theme';
import { FreeResponseAnswerAdvancedSetting } from './Answers/FreeResponseAnswerAdvancedSetting';
import { GeneratedQuestionExplanation } from './GeneratedQuestionExplanation';

export function GeneratedQuestionAdvancedSettings({
  question,
}: {
  question: GeneratedQuestionsQuery['generatedQuestions'][0];
}) {
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen((prev) => !prev);
  const settings = () => {
    switch (question.questionType) {
      case QuestionTypeEnum.FreeResponse:
        return (
          <Box sx={{ mt: 2 }}>
            <FreeResponseAnswerAdvancedSetting question={question} />
          </Box>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Divider
          sx={{
            position: 'absolute',
            width: '100%',
            height: '2px',
            top: '50%',
          }}
        />
        <Button
          endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          variant="text"
          onClick={toggleOpen}
          sx={{
            backgroundColor: theme.palette.background.paper,
            '&:hover': {
              backgroundColor: theme.palette.grays.light,
            },
            textTransform: 'capitalize',
            color: theme.palette.grays.dark,
            fontSize: 15,
          }}
        >
          Advanced Settings
        </Button>
      </Box>
      <Collapse in={open}>
        <GeneratedQuestionExplanation question={question} />
        {settings()}
      </Collapse>
    </>
  );
}
