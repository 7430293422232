import { useMutation, useQuery } from '@apollo/client';
import { Close, Edit, ExpandMore } from '@mui/icons-material';
import {
  Button,
  Card,
  Collapse,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import { AlertsContext } from '../../components/application/Alerts/context';
import HelpKitButton from '../../components/application/HelpKitArticles/HelpKitButton';
import HelpKitCorrectSchool from '../../components/application/HelpKitArticles/HelpKitCorrectSchool';
import HelpKitStudentEmailNotifications from '../../components/application/HelpKitArticles/HelpKitStudentEmailNotifications';
import SchoolSetup from '../../components/application/Setup/SchoolSetup';
import SchoolDisplay from '../../components/application/Setup/SchoolSetup/SchoolDisplay';
import { UpdateStudentAiFeaturesForCourseDocument } from '../../gql/mutations/__generated__/course.generated';
import { UpdateGroupDocument } from '../../gql/mutations/__generated__/group.generated';
import { TeacherSettingsDocument } from '../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../utils/apollo/apolloHelper';
import LeaderboardOptions from './LeaderboardOptions';
import { SettingsBody } from './SettingsBody';
import { StudentNotificationsOptions } from './StudentNotificationsOptions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
  card: {
    marginBottom: '20px',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    width: '100%',
  },
  subtitle: {
    marginTop: '8px',
  },
  columns: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-end',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.secondary.main,
  },
  expandButton: {
    height: '30px',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  schoolDisplay: {
    backgroundColor: theme.palette.mint.main,
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    marginTop: theme.spacing(1),
    borderRadius: 4,
    width: '90%',
  },
  schoolSettingsContainer: {},
  leftColumn: {
    width: '70%',
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  header: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 15px',
  },
  title: {
    color: theme.palette.common.white,
  },
  content: {
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2.5),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  message: {
    marginBottom: theme.spacing(3),
  },
}));

type ArticleReference = { articleId: string; label: string };
// TODO: Insert a valid help kit article here!
const studentFacingAiArticle: ArticleReference | undefined = undefined;

export function GeneralSettings() {
  const classes = useStyles();
  const [emailSettingsOpen, setEmailSettingsOpen] = useState(false);
  const { dispatch } = useContext(AlertsContext);
  const [changeSchool, setChangeSchool] = useState(false);
  const teacherSettingsQuery = useQuery(TeacherSettingsDocument, {
    onError: onError(dispatch),
  });
  const school = teacherSettingsQuery.data?.teacher.school;
  const course = teacherSettingsQuery.data?.teacher.activeCourse;
  const groups =
    [...(course?.groups ?? [])].sort((first, second) =>
      first.name.localeCompare(second.name, 'en', { numeric: true })
    ) || [];

  const [updateGroup] = useMutation(UpdateGroupDocument, {
    onError: onError(dispatch),
  });

  const [updateStudentAIFeatures] = useMutation(
    UpdateStudentAiFeaturesForCourseDocument,
    {
      awaitRefetchQueries: true,
      refetchQueries: (result) =>
        result.data && !result.errors ? [TeacherSettingsDocument] : [],
    }
  );

  const toggleStudentFacingAI = () => {
    if (!course) return;

    updateStudentAIFeatures({
      variables: {
        id: course.id,
        studentFacingAiEnabled: !course.studentFacingAiEnabled,
      },
    });
  };

  const closeChangeSchool = () => {
    teacherSettingsQuery.refetch();
    setChangeSchool(false);
  };
  const handleChangeReceivesEmails = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateGroup({
      variables: {
        groupId: event.target.name,
        receivesEmails: event.target.checked,
      },
    });
  };

  return (
    <SettingsBody
      title="General Settings"
      description="Configure your general Podsie settings."
    >
      <>
        <Card className={classes.card}>
          <div className={classes.columns}>
            <div className={classes.leftColumn}>
              <Typography variant="h5">Selected School</Typography>
              <Typography className={classes.subtitle} variant="body2">
                School that your account is connected to.
              </Typography>
              <div>
                <HelpKitCorrectSchool />
              </div>
              {school && (
                <div className={classes.schoolDisplay}>
                  <SchoolDisplay school={school} />
                </div>
              )}
            </div>
            <Button
              onClick={() => {
                setChangeSchool(true);
              }}
              endIcon={<Edit />}
              className={classes.expandButton}
            >
              Change
            </Button>
          </div>
        </Card>
        <Card className={classes.card}>
          <div className={classes.columns}>
            <div>
              <Typography variant="h5">
                Send Students Email Notifications
              </Typography>
              <Typography className={classes.subtitle} variant="body2">
                If enabled, students will receive email notifications.{' '}
                <HelpKitStudentEmailNotifications />
              </Typography>
              <FormGroup row>
                {groups.map((group) => {
                  return (
                    <div key={`group-settings-${group.id}`}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={group.receivesEmails}
                            onChange={handleChangeReceivesEmails}
                            name={group.id}
                          />
                        }
                        label={group.name}
                      />
                    </div>
                  );
                })}
              </FormGroup>
            </div>
            <Button
              onClick={() => setEmailSettingsOpen(!emailSettingsOpen)}
              endIcon={
                <ExpandMore
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: emailSettingsOpen,
                  })}
                />
              }
              className={classes.expandButton}
            >
              Options
            </Button>
          </div>
          <Collapse in={emailSettingsOpen}>
            <StudentNotificationsOptions />
          </Collapse>
        </Card>
        <Card className={classes.card}>
          <div className={classes.columns}>
            <LeaderboardOptions />
          </div>
        </Card>
        {course && (
          <Card className={classes.card}>
            <Typography variant="h5">Student-facing AI features</Typography>
            <div className={classes.columns}>
              <span>
                <Typography className={classes.subtitle} variant="body2">
                  If enabled, students will be able to leverage AI features such
                  as autograder explanations.
                </Typography>
                {studentFacingAiArticle && (
                  <HelpKitButton {...studentFacingAiArticle} />
                )}
              </span>
              <Switch
                checked={course.studentFacingAiEnabled}
                onChange={toggleStudentFacingAI}
              />
            </div>
          </Card>
        )}
        <Dialog open={changeSchool} keepMounted fullScreen>
          <DialogTitle className={classes.header} variant="h2">
            Edit School
            <IconButton
              className={classes.closeButton}
              aria-label="close"
              onClick={closeChangeSchool}
              size="large"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <SchoolSetup onCompletedCallback={closeChangeSchool} />
        </Dialog>
      </>
    </SettingsBody>
  );
}
