import React, { useContext, useState } from 'react';
import RequiredKeywords from './RequiredKeywords';
import { QuestionFormContext } from './context';
import {
  addRequiredKeyword,
  deleteRequiredKeyword,
  updateRequiredKeyword,
} from './context/actions';

export function RequiredKeywordsContainer() {
  const { questionForm, dispatch } = useContext(QuestionFormContext);
  const { freeResponseAnswer } = questionForm;
  const [validationError, setValidationError] = useState(new Set());
  const updateRequiredKeywordHandler = (index: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const errors = new Set(validationError);
      if (value.endsWith(' ')) {
        errors.add(index);
        setValidationError(errors);
        return;
      }
      dispatch(
        updateRequiredKeyword({
          text: value,
          index,
        })
      );
      errors.delete(index);
      setValidationError(errors);
    };
  };

  const deleteRequiredKeywordHandler = (index: number) => {
    return () => {
      dispatch(
        deleteRequiredKeyword({
          index,
        })
      );
    };
  };

  const addRequiredKeywordHandler = () => {
    dispatch(addRequiredKeyword());
  };

  return (
    <RequiredKeywords
      requiredKeywords={freeResponseAnswer.requiredKeywords}
      addRequiredKeyword={addRequiredKeywordHandler}
      updateRequiredKeywordHandler={updateRequiredKeywordHandler}
      deleteRequiredKeywordHandler={deleteRequiredKeywordHandler}
    />
  );
}

export default RequiredKeywordsContainer;
