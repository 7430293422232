import { QuestionAttributesFragment } from '../../../../../gql/fragments/__generated__/question.generated';
import { AssignmentMethodEnum, Standard } from '../../../../../gql/types';

type GroupsAssignment = {
  launchDate?: Date;
  dueDate?: Date;
  id?: string;
};

export type GroupsAssignmentHash = {
  [id: string]: GroupsAssignment;
};

type EnrollmentsAssignment = {
  dueDate: Date;
  enrollmentId: string;
  id: string;
  studentName?: string | null;
  studentEmail: string;
  class: string;
};

export type EnrollmentsAssignmentHash = {
  [id: string]: EnrollmentsAssignment;
};

export type SpiraledStandard = {
  id: string;
  title: string;
  description: string;
};

export type AssignmentEditorState = {
  ui: {
    loading: boolean;
    saved?: boolean;
  };
  id: string;
  name: string;
  createdAt: string;
  // using a hash to make it easy to diff between unassigned and assigned classes:
  groupsAssignments: GroupsAssignmentHash;
  enrollmentsAssignments: EnrollmentsAssignmentHash;
  questions: QuestionAttributesFragment[];
  standards: Standard[];
  numSpiraledQuestions: number;
  targetDistributedPoints: number | null;
  assignmentType: string;
  assignmentMethod: AssignmentMethodEnum;
  launchAt: null | Date;
  shareable: boolean;
  optional: boolean;
};

export const initialState: AssignmentEditorState = {
  ui: {
    loading: false,
    saved: undefined,
  },
  id: '',
  name: '',
  createdAt: '',
  groupsAssignments: {},
  enrollmentsAssignments: {},
  questions: [],
  standards: [],
  numSpiraledQuestions: 0,
  targetDistributedPoints: 0,
  launchAt: null,
  assignmentType: '',
  assignmentMethod: AssignmentMethodEnum.Group,
  shareable: true,
  optional: false,
};
