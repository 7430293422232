import { useMutation, useQuery } from '@apollo/client';
import { Alert, Button, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { asError } from '@podsie/utils/error.js';
import React, { useContext, useMemo, useState } from 'react';
import { BulkUploadStandardsDocument } from '../../../gql/mutations/__generated__/standard.generated';
import { StandardsChartAdminsDocument } from '../../../gql/queries/__generated__/standardsChart.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { BulkStandardsInput } from '../../../gql/types';
import { onError } from '../../../utils/apollo/apolloHelper';
import { parseRows } from '../../../utils/questions/bulkUpload';
import { AlertsContext } from '../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  description: {
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
  fileInput: {
    border: `1px solid ${theme.palette.mint.dark}`,
    borderRadius: 4,
    padding: theme.spacing(2),
  },
  buttonsContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  backButtonContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
  },
  backButton: {
    color: theme.palette.common.white,
  },
  clarification: {
    marginTop: theme.spacing(2),
  },
  errorContainer: {
    marginTop: theme.spacing(2),
  },
}));

const parseBulkStandardsUpload = (
  csv: string | Buffer
): BulkStandardsInput[] => {
  const rows = parseRows(csv);
  const standardsList = [];
  for (let i = 1; i < rows.length; i++) {
    const attrs = rows[i];
    const category = attrs[0];
    const rowNum = i + 1;
    const standard = attrs[1];
    const description = attrs[2];
    if (!category) {
      throw new Error(
        `Error: Standards Category is missing from Row #${rowNum}`
      );
    }

    if (!standard) {
      throw new Error(`Error: Standard is missing from Row #${rowNum}`);
    }
    if (!description) {
      throw new Error(
        `Error: Standard Description is missing from Row #${rowNum}`
      );
    }
    standardsList.push({
      category,
      standard,
      description,
    });
  }
  return standardsList;
};

type StandardsBulkUploadProps = {
  resetModalContent: () => void;
};

export function StandardsBulkUpload({
  resetModalContent,
}: StandardsBulkUploadProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data: teacherData } = useQuery(TeacherDocument);
  const activeCourse = teacherData?.teacher.activeCourse;
  const activeCourseId = activeCourse?.id || '';
  const standardsChartId = activeCourse?.standardsChart?.id || '';
  const { data: adminData } = useQuery(StandardsChartAdminsDocument, {
    skip: !standardsChartId,
    variables: { standardsChartId },
  });
  const cache: { [key: string]: boolean } = useMemo(() => {
    const obj: { [key: string]: boolean } = {};
    if (!adminData) {
      return obj;
    }

    adminData.standardsChart.admins.forEach((admin) => {
      obj[admin.id] = true;
    });

    return obj;
  }, [adminData]);

  const amAdmin = !!(teacherData && cache[teacherData.teacher.id]);

  const [errorMessage, setErrorMessage] = useState('');
  const [uploaded, setUploaded] = useState(false);
  const [bulkUploadStandards] = useMutation(BulkUploadStandardsDocument, {
    onCompleted: () => setUploaded(true),
    onError: onError(dispatch),
  });

  const parseAndDoBulkUpload = (csv: string | Buffer) => {
    try {
      const standards = parseBulkStandardsUpload(csv);
      bulkUploadStandards({
        variables: {
          courseId: activeCourseId,
          standards,
        },
      });
    } catch (e) {
      setErrorMessage(asError(e).message);
    }
  };

  const handleFileChosen = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    setErrorMessage('');
    fileReader.onloadend = () => {
      if (fileReader.result) {
        parseAndDoBulkUpload(fileReader.result as Buffer);
      }
    };
    const files = e?.target?.files;
    if (files && files[0]) {
      fileReader.readAsText(files[0]);
    }

    e.target.value = '';
  };
  const backButton = () => {
    return (
      <div className={classes.backButtonContainer}>
        <Button
          className={classes.backButton}
          onClick={resetModalContent}
          variant="contained"
          color="secondary"
        >
          Back
        </Button>
      </div>
    );
  };
  if (!amAdmin) {
    return (
      <div className={classes.root}>
        {backButton()}
        <Typography>
          Only Subject Group Admins are allowed to upload standards for this
          subject. The Subject Group Admin is typically the teacher who created
          this subject group. If this Podsie course is using an official Podsie
          Standards Chart, please reach out to hello@podsie.org to request edits
          to the standards.
        </Typography>
      </div>
    );
  }
  if (uploaded) {
    return (
      <div className={classes.root}>
        <Typography variant="h6" color="primary">
          Your standards are being uploaded right now. Come back to this page
          and refresh in a couple minutes to see your uploaded standards.
        </Typography>
        <Typography variant="body2" className={classes.clarification}>
          If your standards are not showing up in the &lsquo;Learning
          Standards&rsquo; section after 5 minutes, please contact
          hello@podsie.org for support.
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      {backButton()}
      <Typography variant="body1" className={classes.description}>
        You can bulk upload your standards to this Subject Group using a Google
        Sheet file. To do this, please make a copy of the Google Sheet link
        below. Then, when you are done adding your standards, please download
        the sheet as a CSV file and upload it.
      </Typography>
      <a
        href="https://docs.google.com/spreadsheets/d/16RrLmx4NYvaMAKKmIuoeR-cLbVIchVhDVOad2NswmMc/edit?usp=sharing"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        <Typography>
          Here&apos;s an example file that shows you the format that your Google
          Sheet needs to be in.
        </Typography>
      </a>

      <section className={classes.buttonsContainer}>
        <input
          className={classes.fileInput}
          type="file"
          onChange={handleFileChosen}
          accept="text/plain,.csv"
        />
      </section>
      <div className={classes.errorContainer}>
        {errorMessage && (
          <div>
            <Alert severity="error">{errorMessage}</Alert>
          </div>
        )}
      </div>
    </div>
  );
}

export default StandardsBulkUpload;
