import { useMutation } from '@apollo/client';
import { Box, Typography, useTheme } from '@mui/material';
import { Sources } from 'quill';
import React, { useContext } from 'react';
import { UnprivilegedEditor, Value } from 'react-quill';
import { useDebouncedCallback } from 'use-debounce';
import { UpdateQuestionDocument } from '../../../../../gql/mutations/__generated__/question.generated';
import { GeneratedQuestionsQuery } from '../../../../../gql/queries/__generated__/question.generated';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import Editor from '../../../../shared/Editor';
import { AlertsContext } from '../../../Alerts/context';
import { RequiredFieldAsterisk } from '../RequiredFieldAsterisk';
import { updateQuestionFields } from '../questions';

export function FreeResponseAnswerEdit({
  question,
}: {
  question: GeneratedQuestionsQuery['generatedQuestions'][0];
}) {
  const [freeResponseAnswerDelta, setFreeResponseAnswerDelta] = React.useState(
    question.freeResponseAnswer?.richText.ops
  );
  const { dispatch } = useContext(AlertsContext);
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });
  const theme = useTheme();
  // Debounce server update calls
  const debouncedServerUpdate = useDebouncedCallback(
    (editor: UnprivilegedEditor) => {
      if (!question.freeResponseAnswer?.id) return;
      const changes: Partial<
        GeneratedQuestionsQuery['generatedQuestions'][number]
      > = {
        freeResponseAnswer: {
          ...question.freeResponseAnswer,
          id: question.freeResponseAnswer?.id,
          richText: editor.getContents(),
          // needs to be text instead of plainText bc that's what
          // QuestionInput expects:
          text: editor.getText(),
        },
      };

      const questionInput = updateQuestionFields(question, changes);

      updateQuestion({ variables: { question: questionInput } });
    },
    2000 // delay in ms
  );
  const handleAnswerChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    if (source !== 'user') return;
    setFreeResponseAnswerDelta(editor.getContents());
    debouncedServerUpdate(editor);
  };
  return (
    <Box>
      <Typography sx={{ fontWeight: theme.typography.fontWeightBold, mb: 1 }}>
        Answer
        <RequiredFieldAsterisk />
      </Typography>
      <Editor value={freeResponseAnswerDelta} onChange={handleAnswerChange} />
    </Box>
  );
}
