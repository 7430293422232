import React, { useReducer } from 'react';
import initialState, { AlertsState } from './initialState';
import reducer, { AlertAction } from './reducer';

const AlertsContext = React.createContext<{
  alerts: AlertsState;
  dispatch: React.Dispatch<AlertAction>;
}>({
  alerts: initialState,
  dispatch: () => null,
});

type AlertsProviderProps = {
  children: React.ReactElement;
};

function AlertsProvider({ children }: AlertsProviderProps) {
  const [alerts, dispatch] = useReducer(reducer, initialState);
  return (
    <AlertsContext.Provider value={{ alerts, dispatch }}>
      {children}
    </AlertsContext.Provider>
  );
}
export { AlertsContext, AlertsProvider };
