import React, { useState } from 'react';

const GroupsAssignmentsTableSearchContext = React.createContext<{
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}>({
  value: '',
  setValue: () => null,
});

type GroupsAssignmentTableSearchProviderProps = {
  children: React.ReactElement;
};

function GroupsAssignmentsTableSearchProvider({
  children,
}: GroupsAssignmentTableSearchProviderProps) {
  const [groupsAssignmentsTableSearch, setGroupsAssignmentsTableSearch] =
    useState('');
  return (
    <GroupsAssignmentsTableSearchContext.Provider
      value={{
        value: groupsAssignmentsTableSearch,
        setValue: setGroupsAssignmentsTableSearch,
      }}
    >
      {children}
    </GroupsAssignmentsTableSearchContext.Provider>
  );
}

export {
  GroupsAssignmentsTableSearchContext,
  GroupsAssignmentsTableSearchProvider,
};
