import { useMutation, useQuery } from '@apollo/client';
import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  type DropResult,
} from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import type { QuestionAttributesFragment } from '../../../../../../gql/fragments/__generated__/question.generated';
import { ReorderItemDocument } from '../../../../../../gql/mutations/__generated__/positionable.generated';
import {
  AssignmentDocument,
  type AssignmentQuery,
} from '../../../../../../gql/queries/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../../../../gql/types';
import { onError } from '../../../../../../utils/apollo/apolloHelper';
import { useTrackVisit } from '../../../../../../utils/hooks/useTrackVisit';
import { AlertsContext } from '../../../../Alerts/context';
import { AssignmentEditorModes } from '../NormalAssignment';
import { QuestionCard } from './QuestionCard';
import { UpdateAssignmentQuestionDrawer } from './UpdateAssignmentQuestionDrawer';
import { UpdateAssignmentQuestionVariationDrawer } from './UpdateAssignmentQuestionVariationDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  questionsContainer: {
    background: 'transparent',
    border: `1px solid ${theme.palette.mint.main}`,
  },
  noQuestionContainer: {
    paddingTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
}));

type AssignmentQuestionsProps = {
  mode: AssignmentEditorModes | undefined;
  setMode: React.Dispatch<
    React.SetStateAction<AssignmentEditorModes | undefined>
  >;
  selectedGroupId: string;
  breakdownLoading: boolean;
  questionId: string | undefined;
  setQuestionId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export function AssignmentQuestions({
  mode,
  setMode,
  breakdownLoading,
  selectedGroupId,
  questionId,
  setQuestionId,
}: AssignmentQuestionsProps) {
  useTrackVisit({
    section: 'assignment',
    page: 'normal-assignment-questions-view',
  });
  const classes = useStyles();
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const [questions, setQuestions] = useState<
    AssignmentQuery['assignment']['questions']
  >([]);
  const [triggerUpdateFormSubmit, setTriggerUpdateFormSubmit] = useState(false);
  const { dispatch } = useContext(AlertsContext);
  const { data: assignmentQueryData, refetch } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
    onCompleted: (res) => {
      // items should have come from the backend in the correct position already:
      setQuestions(res.assignment.questions);
    },
  });
  const handleError = onError(dispatch);
  const [reorderItem] = useMutation(ReorderItemDocument, {
    onError: (error) => {
      error.message =
        'Error occurred with reordering. Please refresh your page and try again.';
      handleError(error);
    },
    onCompleted: () => refetch(),
  });

  const assignmentStatus = assignmentQueryData?.assignment.assignmentStatus;
  const draftMode = assignmentStatus === AssignmentStatusEnum.Pending;
  const displayBreakdown = assignmentStatus && !draftMode;

  const handleOpenEditQuestion = (id: string) => {
    setMode(AssignmentEditorModes.UPDATE);
    setQuestionId(id);
  };

  const handleOpenVariations = (id: string) => {
    setMode(AssignmentEditorModes.CREATE_VARIATIONS);
    setQuestionId(id);
  };

  const handleClose = () => {
    setQuestionId(undefined);
    setMode(undefined);
  };

  const handleCloseVariations = () => {
    setQuestionId(undefined);
    setMode(undefined);
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const updated = Array.from(questions || []);
    const insert = updated.find((el) => draggableId === `question-${el.id}`);
    if (!insert) {
      return;
    }
    updated.splice(source.index, 1);
    updated.splice(destination.index, 0, insert);
    reorderItem({
      variables: {
        type: 'Question',
        parentType: 'Assignment',
        parentId: assignmentId,
        reorderedId: questions[source.index].id,
        destinationId: questions[destination.index].id,
      },
    });
    setQuestions(updated);
  };

  const empty = assignmentQueryData?.assignment.questions.length === 0;
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="question-dropzone" type="question">
        {(provided) => (
          <section
            className={classes.questionsContainer}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {empty ? (
              <div className={classes.noQuestionContainer}>
                <Typography variant="h4" color="primary">
                  Click &lsquo;Search&rsquo; or &lsquo;Create&rsquo; to add a
                  question to this assignment!
                </Typography>
              </div>
            ) : (
              assignmentQueryData?.assignment.questions.map(
                (question: QuestionAttributesFragment, i) => {
                  return (
                    <QuestionCard
                      index={i}
                      key={question.id}
                      question={question}
                      draftMode={draftMode}
                      displayBreakdown={displayBreakdown || false}
                      handleOpenEditQuestion={handleOpenEditQuestion}
                      handleOpenVariations={handleOpenVariations}
                      selectedGroupId={selectedGroupId}
                      setMode={setMode}
                      breakdownLoading={breakdownLoading}
                    />
                  );
                }
              )
            )}
            {provided.placeholder}
          </section>
        )}
      </Droppable>

      {questionId && (
        <UpdateAssignmentQuestionDrawer
          editingOpen={mode === AssignmentEditorModes.UPDATE}
          handleClose={handleClose}
          questionId={questionId}
          triggerUpdateFormSubmit={triggerUpdateFormSubmit}
          setTriggerUpdateFormSubmit={setTriggerUpdateFormSubmit}
        />
      )}

      {questionId && (
        <UpdateAssignmentQuestionVariationDrawer
          editingOpen={mode === AssignmentEditorModes.CREATE_VARIATIONS}
          handleClose={handleCloseVariations}
          questionId={questionId}
        />
      )}
    </DragDropContext>
  );
}
