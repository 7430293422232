import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { AssignmentStatusEnum } from '../../../../gql/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'fit-content',
    borderRadius: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    height: '20px',
  },
  blue: {
    color: theme.palette.blue.dark,
    backgroundColor: theme.palette.blue.main,
  },
  green: {
    color: theme.palette.green.dark,
    backgroundColor: theme.palette.green.main,
  },
  yellow: {
    color: theme.palette.yellow.dark,
    backgroundColor: theme.palette.yellow.main,
  },
}));

type AssignmentStatusProps = {
  assignmentStatus: AssignmentStatusEnum;
};
export function AssignmentStatus({ assignmentStatus }: AssignmentStatusProps) {
  const classes = useStyles();
  let label = '';
  let blue = false;
  let green = false;
  let yellow = false;
  switch (assignmentStatus) {
    case AssignmentStatusEnum.Pending:
      label = 'Draft';
      yellow = true;
      break;
    case AssignmentStatusEnum.PastDue:
      label = 'Previous';
      blue = true;
      break;
    case AssignmentStatusEnum.Active:
    case AssignmentStatusEnum.InProgress:
      green = true;
      label = 'Active';
      break;
    default:
      break;
  }
  return (
    <div
      className={clsx(classes.root, {
        [classes.blue]: blue,
        [classes.green]: green,
        [classes.yellow]: yellow,
      })}
    >
      {label}
    </div>
  );
}

export default AssignmentStatus;
