import { useQuery } from '@apollo/client';
import type { Column } from '@material-table/core';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  bubbleGreen,
  bubbleRed,
  bubbleYellow,
} from '../../../assets/shared-styles/Bubble';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { GroupDocument } from '../../../gql/queries/__generated__/group.generated';
import {
  StudentStandardMasteriesDocument,
  StudentStandardsCategoryMasteriesDocument,
} from '../../../gql/queries/__generated__/masteries.generated';
import { StandardsChartDocument } from '../../../gql/queries/__generated__/standardsChart.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { EnrollmentStatusEnum } from '../../../gql/types';
import { onError } from '../../../utils/apollo/apolloHelper';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { useWindowResize } from '../../../utils/hooks/useWindowResize';
import { sortGroups } from '../../../utils/lists';
import { LS_STUDENT_PROGRESS_SELECTED_GROUP_ID } from '../../../utils/localStorageKeysFunction';
import { LastUpdated } from '../../shared/LastUpdated';
import { HeaderWithTooltip } from '../../shared/Table/HeaderWithTooltip';
import { AlertsContext } from '../Alerts/context';
import HelpKitStandardsProgress from '../HelpKitArticles/HelpKitStandardsProgress';
import { CustomTable } from './CustomTable';
import { EnrollmentStandardDetails } from './EnrollmentStandardDetails';
import { StandardsTableToolbar } from './StandardsTableToolbar';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  formControl: {
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(4),
    minWidth: 240,
  },
  buttonLink: {
    ...buttonLink(theme),
  },
  bubbleGreen: {
    ...bubbleGreen(),
  },
  bubbleYellow: {
    ...bubbleYellow(),
  },
  bubbleRed: {
    ...bubbleRed(),
  },
  empty: {},
  clickableButton: {
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
  externalLink: {
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.main,
    fontSize: 18,
  },
  externalLinkContainer: {
    marginBottom: theme.spacing(2),
  },
}));

type Row = {
  student: string;
  enrollmentId: string;
  fullName: string;
  [id: number]: number;
};
const hasData: { [key: string]: boolean } = {};

export function UpdatedStandardsMastery() {
  useTrackVisit({
    section: 'student-progress',
    page: 'standards',
  });
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const [onlyShowNonEmpty, setOnlyShowNonEmpty] = useState(true);
  const [groupId, setGroupId] = useState<string>('');
  const { data: teacherData } = useQuery(TeacherDocument, {
    onError: onError(dispatch),
  });

  const activeCourseId = teacherData?.teacher.activeCourse?.id || '';

  const groups = sortGroups(teacherData?.teacher.activeCourse?.groups);

  useEffect(() => {
    if (groupId) {
      return;
    }
    const storedGroupId = localStorage.getItem(
      LS_STUDENT_PROGRESS_SELECTED_GROUP_ID(activeCourseId)
    );
    if (storedGroupId) {
      setGroupId(storedGroupId);
      return;
    }
    if (groups && groups.length > 0) {
      const tempGroupId = groups[0].id;
      setGroupId(tempGroupId);
      localStorage.setItem(
        LS_STUDENT_PROGRESS_SELECTED_GROUP_ID(activeCourseId),
        tempGroupId
      );
    }
  }, [activeCourseId, groupId, groups]);
  const [standardsCategoryId, setStandardsCategoryId] = useState<string>('');
  const [selectedEnrollmentStandard, setSelectedEnrollmentStandard] = useState({
    enrollmentId: '',
    standardId: '',
    studentName: '',
  });
  const { height } = useWindowResize();
  const toggle = () => setOnlyShowNonEmpty(!onlyShowNonEmpty);
  const { data: groupData } = useQuery(GroupDocument, {
    skip: groupId === '',
    variables: { groupId: groupId },
    onError: onError(dispatch),
  });
  const standardsChartId =
    teacherData?.teacher.activeCourse?.standardsChart?.id || '';
  const activeEnrollments = useMemo(() => {
    return (
      groupData?.group?.enrollments?.filter(
        (enrollment) =>
          enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled
      ) || []
    );
  }, [groupData?.group?.enrollments]);
  const studentIds =
    activeEnrollments.map((enrollment) => enrollment.student.id) || [];
  const { data: standardsChartData } = useQuery(StandardsChartDocument, {
    onError: onError(dispatch),
    fetchPolicy: 'cache-and-network',
    skip: standardsChartId === '',
    variables: { standardsChartId },
  });
  const standardsCategoryIds =
    standardsChartData?.standardsChart.standardsCategories?.map(
      (elem) => elem.id
    ) || [];
  const {
    data: studentStandardsCategoryData,
    refetch: refetchCategoryMasteries,
    loading: categoryLoading,
  } = useQuery(StudentStandardsCategoryMasteriesDocument, {
    onError: onError(dispatch),
    notifyOnNetworkStatusChange: true,
    skip: studentIds.length === 0 || standardsCategoryIds.length === 0,
    variables: {
      groupIds: [groupId],
      standardsCategoryIds,
      studentIds,
    },
  });
  const selectedGroup = groups?.find((elem) => elem.id === groupId);
  const disableStandardsCategories = !selectedGroup;
  const selectedStandardsCategory =
    standardsChartData?.standardsChart.standardsCategories?.find(
      (elem) => elem.id === standardsCategoryId
    );
  const standardIds =
    selectedStandardsCategory?.standards.map((elem) => elem.id) || [];
  const defaultValue = selectedGroup?.id || '';
  const {
    data: studentStandardData,
    refetch,
    loading,
  } = useQuery(StudentStandardMasteriesDocument, {
    notifyOnNetworkStatusChange: true,
    skip: standardsCategoryId === '',
    variables: {
      groupIds: [groupId],
      standardIds,
      studentIds,
    },
  });
  const handleEnrollmentStandardClick = (
    enrollmentId: string,
    standardId: string,
    studentName: string
  ) => {
    return () => {
      setSelectedEnrollmentStandard({
        enrollmentId,
        standardId,
        studentName,
      });
    };
  };
  const tableData = useMemo(() => {
    const formatted: Row[] = [];
    if (!studentStandardsCategoryData) {
      return formatted;
    }

    const hash: {
      [enrollmentId: string]: {
        [id: string]: number;
      };
    } = {};

    if (selectedStandardsCategory) {
      studentStandardData?.studentStandardMasteries.forEach((dataPoint) => {
        const obj: { [id: string]: number } = {};
        dataPoint.data.forEach((standard) => {
          obj[standard.standardId] = standard.average;
          hasData[`standard-${standard.standardId}`] = true;
        });
        hash[dataPoint.enrollmentId] = obj;
      });
    } else {
      studentStandardsCategoryData.studentStandardsCategoryMasteries.forEach(
        (dataPoint) => {
          const obj: { [id: string]: number } = {};
          dataPoint.data.forEach((cat) => {
            obj[cat.standardsCategoryId] = cat.average;
            hasData[`cat-${cat.standardsCategoryId}`] = true;
          });
          hash[dataPoint.enrollmentId] = obj;
        }
      );
    }

    return activeEnrollments.map(({ id: enrollmentId, student }) => {
      return {
        student: student.sortName ?? student.email,
        enrollmentId,
        fullName: student.fullName ?? '',
        ...hash[enrollmentId],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeEnrollments,
    selectedStandardsCategory,
    studentStandardData?.studentStandardMasteries,
    studentStandardsCategoryData,
  ]);
  // Callback functions for handling change
  const handleSelectGroupChange = (event: SelectChangeEvent<string>) => {
    const updatedGroupId = event.target.value as string;
    setGroupId(updatedGroupId);
    if (activeCourseId) {
      localStorage.setItem(
        LS_STUDENT_PROGRESS_SELECTED_GROUP_ID(activeCourseId),
        updatedGroupId
      );
    }
  };
  const handleSelectStandardsCategoryChange = (
    event: SelectChangeEvent<string>
  ) => {
    setStandardsCategoryId(event.target.value as string);
  };

  const closeDetails = () =>
    setSelectedEnrollmentStandard({
      enrollmentId: '',
      standardId: '',
      studentName: '',
    });

  const fetchUpdates = () => {
    refetch();
    refetchCategoryMasteries();
  };
  const columns: Column<Row>[] = [{ title: 'Student', field: 'student' }];

  if (selectedStandardsCategory && selectedStandardsCategory.standards) {
    selectedStandardsCategory.standards.forEach((standard) => {
      if (!hasData[`standard-${standard.id}`] && onlyShowNonEmpty) {
        return;
      }
      const title = `${standard.title} - ${standard.description}`;
      const displayTitle = title.substring(0, 30) + '...';
      columns.push({
        title: (
          <HeaderWithTooltip title={displayTitle} tooltip={title} hideIcon />
        ),
        field: standard.id,
        customSort: (a, b) => {
          const aMastery = a[parseInt(standard.id)];
          const bMastery = b[parseInt(standard.id)];
          if (isNaN(aMastery) || isNaN(bMastery)) {
            return Number.NEGATIVE_INFINITY;
          }
          return bMastery - aMastery;
        },
        render: (row: Row) => {
          const mastery = row[parseInt(standard.id)];
          let style;
          if (mastery > 1.0) {
            style = classes.bubbleGreen;
          } else if (mastery > -1.0) {
            style = classes.bubbleYellow;
          } else if (!isNaN(mastery)) {
            style = classes.bubbleRed;
          } else {
            style = classes.empty;
            return <div></div>;
          }
          return (
            <div>
              <button
                onClick={handleEnrollmentStandardClick(
                  row.enrollmentId,
                  standard.id,
                  row.fullName
                )}
                className={clsx(style, classes.clickableButton)}
              >
                {mastery}
              </button>
            </div>
          );
        },
      });
    });
  } else if (standardsChartData?.standardsChart.standardsCategories) {
    standardsChartData?.standardsChart.standardsCategories?.forEach(
      (category) => {
        if (!hasData[`cat-${category.id}`] && onlyShowNonEmpty) {
          return;
        }
        columns.push({
          title: category.category,
          field: category.id,
          customSort: (a, b) => {
            const aMastery = a[parseInt(category.id)];
            const bMastery = b[parseInt(category.id)];
            if (isNaN(aMastery) || isNaN(bMastery)) {
              return Number.NEGATIVE_INFINITY;
            }
            return bMastery - aMastery;
          },
          render: (row: Row) => {
            const mastery = row[parseInt(category.id)];
            let style;
            if (mastery > 1.0) {
              style = classes.bubbleGreen;
            } else if (mastery > -1.0) {
              style = classes.bubbleYellow;
            } else if (!isNaN(mastery)) {
              style = classes.bubbleRed;
            } else {
              style = classes.empty;
            }
            return (
              <div>
                <span className={style}>{mastery}</span>
              </div>
            );
          },
        });
      }
    );
  }
  return (
    <div className={classes.root}>
      <div>
        <div className={classes.externalLinkContainer}>
          <HelpKitStandardsProgress />
        </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Class</InputLabel>
          <Select
            value={defaultValue}
            onChange={handleSelectGroupChange}
            label="Class"
            autoWidth={true}
          >
            {groups?.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          disabled={disableStandardsCategories}
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel>Standards Categories</InputLabel>
          <Select
            value={selectedStandardsCategory?.category}
            onChange={handleSelectStandardsCategoryChange}
            label="Standards Category"
          >
            <MenuItem value={''}>
              <em>None</em>
            </MenuItem>
            {standardsChartData?.standardsChart.standardsCategories?.map(
              (elem) => (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.category}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </div>
      <LastUpdated refetch={fetchUpdates} />
      <CustomTable
        isLoading={loading || categoryLoading}
        components={{
          Toolbar: (props) => (
            <StandardsTableToolbar
              {...props}
              checked={onlyShowNonEmpty}
              toggleChecked={toggle}
            />
          ),
        }}
        options={{
          headerStyle: { position: 'sticky', top: 0 },
          maxBodyHeight: height / 1.5,
          minBodyHeight: 300,
          draggable: false,
        }}
        title={''}
        columns={columns}
        data={tableData}
      />
      <EnrollmentStandardDetails
        handleClose={closeDetails}
        enrollmentId={selectedEnrollmentStandard.enrollmentId}
        standardId={selectedEnrollmentStandard.standardId}
        studentName={selectedEnrollmentStandard.studentName}
      />
    </div>
  );
}
export default UpdatedStandardsMastery;
