import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Theme,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useParams } from 'react-router-dom';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { AiCreateForm } from './v2/AiCreateForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  container: {
    maxWidth: 1200,
    margin: '0 auto',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  helpkitContainer: {
    marginTop: theme.spacing(2),
  },
}));

export function SmartCreate() {
  useTrackVisit({
    section: 'assignment',
    page: 'smart-create',
  });
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const handleClose = () => history.push(`/assignments/${assignmentId}`);
  return (
    <Dialog fullScreen open={true} className={classes.root} keepMounted>
      <DialogTitle className={classes.dialogTitle} variant="h3">
        AI-Assisted Question Creation
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.background.default }}>
        <section className={classes.container}>
          <AiCreateForm />
        </section>
      </DialogContent>
    </Dialog>
  );
}

export default SmartCreate;
