import HelpKitButton from './HelpKitButton';

export function HelpKitLearningStandards() {
  return (
    <HelpKitButton
      label="What are Learning Standards?"
      articleId="fMdm7u71Gndzzr5VPTSdwb"
    />
  );
}
export default HelpKitLearningStandards;
