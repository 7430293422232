import HelpKitButton from './HelpKitButton';

export function HelpKitBulkQuestionsUpload() {
  return (
    <HelpKitButton
      label="Important: Follow these instructions to bulk upload your questions."
      articleId="aE8DqmGMTxXmQg9495krrD"
    />
  );
}
export default HelpKitBulkQuestionsUpload;
