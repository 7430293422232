import { useQuery } from '@apollo/client';
import { ChevronLeft, ChevronRight, Close, Menu } from '@mui/icons-material';
import {
  AppBar,
  Checkbox,
  Dialog,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  Switch,
  Theme,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useState, type ChangeEvent } from 'react';
import { ReviewSessionsQuestionsDocument } from '../../../../gql/queries/__generated__/reviewSessionsQuestion.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import {
  LS_REVIEW_SESSION_SELECTED_QUESTION_IDS,
  LS_REVIEW_SESSION_SHOW_SINGLE,
} from '../../../../utils/localStorageKeys';
import { questionTypeIcons } from '../../../../utils/renderQuestionType';
import { CustomTooltip } from '../../../shared/CustomTooltip';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import HelpKitReviewQuestions from '../../HelpKitArticles/HelpKitReviewQuestions';
import ReviewModeQuestions from './ReviewModeQuestions';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.grays.light,
    color: theme.palette.primary.main,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  iconContainer: {
    minWidth: '40px',
  },
  icon: {
    color: theme.palette.mint.dark,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  flex: {
    display: 'flex',
    alignItems: 'baseline',
  },
  link: {
    marginLeft: theme.spacing(1),
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightBold,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.grays.light,
  },
  drawerHeaderFiller: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  closeButton: {
    color: theme.palette.primary.main,
    padding: 0,
  },
  dialogContent: {
    display: 'flex',
    flexGrow: 1,
  },
  content: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

type ReviewModeModalProps = {
  onClose: () => void;
};

export function ReviewModeModal({ onClose }: ReviewModeModalProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const showSingle = localStorage.getItem(LS_REVIEW_SESSION_SHOW_SINGLE);
  const [showMultiple, setShowMultiple] = useState(showSingle ? false : true);
  const { data: teacherData } = useQuery(TeacherDocument);
  const courseId = teacherData?.teacher.activeCourse?.id || '';

  const [selectedQuestionIds, setSelectedQuestionIds] = useState(
    new Set<string>()
  );
  const { data } = useQuery(ReviewSessionsQuestionsDocument, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      if (res.reviewSessionsQuestions.length === 0) return;

      const savedQuestionIds = localStorage.getItem(
        LS_REVIEW_SESSION_SELECTED_QUESTION_IDS
      );
      let saved = [res.reviewSessionsQuestions[0].question.id];
      if (savedQuestionIds && showMultiple) {
        const fetchedSet = new Set(
          res.reviewSessionsQuestions.map(({ question: { id } }) => id)
        );
        saved = JSON.parse(savedQuestionIds) as string[];
        saved = saved.filter((questionId) => fetchedSet.has(questionId));
      }
      setSelectedQuestionIds(new Set(saved));
    },
    skip: !courseId,
    variables: { courseId },
  });

  const handleDrawerOpen = () => setOpen(true);
  const theme = useTheme();
  const reviewSessionsQuestions = data?.reviewSessionsQuestions || [];
  const sortingArray = reviewSessionsQuestions.map(
    ({ question: { id } }) => id
  );

  const handleClick = (questionId: string) => {
    return () => {
      let updated = new Set(selectedQuestionIds);
      if (!showMultiple) {
        updated = new Set([questionId]);
      } else {
        if (selectedQuestionIds.has(questionId)) {
          updated.delete(questionId);
        } else {
          updated.add(questionId);
        }
        const arr = Array.from(updated);
        arr.sort((a, b) => sortingArray.indexOf(a) - sortingArray.indexOf(b));
        updated = new Set(arr);
        localStorage.setItem(
          LS_REVIEW_SESSION_SELECTED_QUESTION_IDS,
          JSON.stringify(arr)
        );
      }
      setSelectedQuestionIds(updated);
    };
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleShowMultiple = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!checked) {
      localStorage.setItem(LS_REVIEW_SESSION_SHOW_SINGLE, 'true');
    }
    // if toggling to only show single, remove all elements except first:
    if (!checked && selectedQuestionIds.size > 1) {
      const updated = Array.from(selectedQuestionIds);
      updated.length = 1;
      setSelectedQuestionIds(new Set(updated));
    }

    if (checked && sortingArray.length) {
      const savedQuestionIds = localStorage.getItem(
        LS_REVIEW_SESSION_SELECTED_QUESTION_IDS
      );
      let saved = [sortingArray[0]];
      if (savedQuestionIds) {
        const fetchedSet = new Set(sortingArray);
        saved = JSON.parse(savedQuestionIds) as string[];
        saved = saved.filter((questionId) => fetchedSet.has(questionId));
        localStorage.removeItem(LS_REVIEW_SESSION_SHOW_SINGLE);
      }
      setSelectedQuestionIds(new Set(saved));
    }

    setShowMultiple(checked);
  };

  const renderSelection = (id: string) => {
    if (showMultiple) {
      return (
        <Checkbox
          edge="end"
          checked={selectedQuestionIds.has(id)}
          inputProps={{ 'aria-labelledby': id }}
          disableRipple
        />
      );
    }
    return (
      <Radio checked={selectedQuestionIds.has(id)} disableRipple edge="end" />
    );
  };

  return (
    <Dialog fullScreen open={true}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
            size="large"
          >
            <Menu />
          </IconButton>
          <div className={classes.headerContainer}>
            <div className={classes.flex}>
              <Typography variant="h6" noWrap>
                Questions Review
              </Typography>
              <HelpKitReviewQuestions />
            </div>
            <IconButton
              className={classes.closeButton}
              aria-label="close"
              onClick={onClose}
              size="large"
            >
              <Close />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.dialogContent}>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <FormControlLabel
              control={
                <Switch
                  checked={showMultiple}
                  onChange={toggleShowMultiple}
                  name="show-multiple"
                />
              }
              label="Show Multiple"
            />
            <IconButton onClick={handleDrawerClose} size="large">
              {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {reviewSessionsQuestions.map(
              ({ question: { id, plainText, questionType, richText } }) => {
                const text =
                  plainText.length > 40
                    ? plainText.substring(0, 40).trim() + '...'
                    : plainText;

                return (
                  <ListItem
                    ContainerProps={{
                      onClick: handleClick(id),
                    }}
                    key={`review-mode-question-index-key-${id}`}
                    divider
                    button
                  >
                    <ListItemAvatar className={classes.iconContainer}>
                      <span className={classes.icon}>
                        {questionTypeIcons[questionType]}
                      </span>
                    </ListItemAvatar>
                    <CustomTooltip
                      placement="top-start"
                      title={<QuillDeltaAsHtml delta={richText.ops} />}
                    >
                      <ListItemText primary={text} />
                    </CustomTooltip>
                    <ListItemSecondaryAction>
                      {renderSelection(id)}
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              }
            )}
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeaderFiller} />
          <ReviewModeQuestions selectedQuestionIds={selectedQuestionIds} />
        </main>
      </div>
    </Dialog>
  );
}
