import { useQuery } from '@apollo/client';
import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import type { QuestionWithAuthorAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { QuestionsForStandardDocument } from '../../../../gql/queries/__generated__/question.generated';
import { StandardsDocument } from '../../../../gql/queries/__generated__/standard.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import StyledDialog from '../../../shared/Layout/StyledDialog';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { StandardsQueryStandard } from '../../Questions/Form/MultipleStandardsAutocomplete';
import {
  QuestionStandardsUpdated,
  StandardQuestionsTagger,
} from './StandardQuestionsTagger';
import { UntaggedQuestionCard } from './UntaggedQuestionCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  standardTaggerContainer: {
    top: '0',
    position: 'sticky',
    width: 'auto',
    height: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    zIndex: '1',
    margin: '-24px',
    justifyContent: 'space-around',
    padding: theme.spacing(2),
  },
  autocompleteContainer: {
    width: '80%',
  },

  noQuestionsContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  questionContainer: {
    marginTop: '35px',
  },
}));

type StandardQuestionsModalProps = {
  selectedStandardId: string;
  selectedStandardTitle: string;
  handleClose: () => void;
};
export const STANDARD_QUESTIONS_PAGINATION_COUNT = 10;

export function StandardQuestionsModal({
  selectedStandardId,
  selectedStandardTitle,
  handleClose,
}: StandardQuestionsModalProps) {
  const [selectedStandard, setSelectedStandard] = useState<
    StandardsQueryStandard[]
  >([]);
  const [updatedQuestionStandardIds, setUpdatedQuestionStandardIds] = useState<
    QuestionStandardsUpdated[]
  >([]);
  const classes = useStyles();
  const { data: teacherData } = useQuery(TeacherDocument);
  const teamId = teacherData?.teacher.activeCourse?.team?.id || '';
  const untagged = selectedStandardId === 'untagged';
  const { data, loading, fetchMore, refetch } = useQuery(
    QuestionsForStandardDocument,
    {
      skip: !selectedStandardId || !teamId,
      variables: {
        first: STANDARD_QUESTIONS_PAGINATION_COUNT,
        standardId: untagged ? null : selectedStandardId,
        teamId,
      },
    }
  );
  const standardsChartId =
    teacherData?.teacher.activeCourse?.standardsChart?.id || '';

  const { data: standardsData } = useQuery(StandardsDocument, {
    skip: !standardsChartId,
    variables: { standardsChartId },
  });
  const options = useMemo(() => {
    if (standardsData?.standards) {
      return standardsData.standards;
    }
    return [];
  }, [standardsData]);
  const [selectedQuestion, setSelectedQuestion] = useState<
    QuestionWithAuthorAttributesFragment[]
  >([]);

  useEffect(() => {
    refetch();
    setSelectedQuestion([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStandardId]);
  const fetchNextBatch = () => {
    fetchMore({
      variables: {
        first: STANDARD_QUESTIONS_PAGINATION_COUNT,
        standardId: untagged ? null : selectedStandardId,
        after: data?.questionsForStandard.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        fetchMoreResult.questionsForStandard.edges = [
          ...(previousResult?.questionsForStandard.edges || []),
          ...(fetchMoreResult.questionsForStandard.edges || []),
        ];
        return fetchMoreResult;
      },
    });
  };
  const questions = data?.questionsForStandard.edges || [];
  const totalQuestions = data?.questionsForStandard.totalCount || 0;
  const title = untagged ? 'Untagged Questions' : `${selectedStandardTitle}`;
  const noQuestions = !loading && questions?.length === 0;
  const handleQuestion = (
    question: QuestionWithAuthorAttributesFragment,
    add: boolean
  ) => {
    setSelectedQuestion(
      add
        ? (currentSelection) => [...currentSelection, question]
        : (currentSelection) =>
            currentSelection.filter((q) => q.id !== question.id)
    );
  };

  const resetSelectAndStandards = () => {
    setSelectedQuestion([]);
    setUpdatedQuestionStandardIds([]);
  };
  const selectStandards = (standards: StandardsQueryStandard[]) => {
    setSelectedStandard(standards);
  };
  const updateQuestionStandards = (
    updatedQuestionStandardIds: QuestionStandardsUpdated[]
  ) => {
    setUpdatedQuestionStandardIds(updatedQuestionStandardIds);
  };

  return (
    <StyledDialog
      open={!!selectedStandardId}
      title={title}
      handleClose={() => {
        handleClose();
        resetSelectAndStandards();
      }}
    >
      {loading && <LoadingSkeletons num={40} />}
      <StandardQuestionsTagger
        options={options}
        questions={selectedQuestion}
        onSelect={selectStandards}
        onUpdate={updateQuestionStandards}
      />
      {noQuestions && (
        <div className={classes.noQuestionsContainer}>
          <Typography>No Questions</Typography>
        </div>
      )}
      <div className={classes.questionContainer}>
        <InfiniteScroll
          scrollThreshold={0.7}
          dataLength={questions.length}
          next={fetchNextBatch}
          hasMore={questions.length < totalQuestions}
          scrollableTarget="scrollable-dialog"
          loader={
            loading ? (
              <div>
                <Typography>Loading...</Typography>
              </div>
            ) : null
          }
        >
          {questions?.map((edge) => {
            const q = edge?.node as QuestionWithAuthorAttributesFragment;
            if (!q) {
              return null;
            }
            return (
              <UntaggedQuestionCard
                key={q.id}
                onSelect={handleQuestion}
                question={q}
                selectedStandards={selectedStandard}
                updatedQuestionStandardIds={updatedQuestionStandardIds}
              />
            );
          })}
        </InfiniteScroll>
      </div>
    </StyledDialog>
  );
}
