import { Theme } from '@mui/material';

export const tableRoot = (theme: Theme) => ({
  "& tr[level='1']": {
    background: theme.palette.accent.light,
  },
  '& th': {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '& ::-webkit-scrollbar-thumb': {
    border: '1px solid white !important',
  },
});
