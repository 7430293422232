import HelpKitButton from './HelpKitButton';

export function HelpKitPersonalDeckAssignment() {
  return (
    <HelpKitButton
      label="How do Personal Deck Assignments work?"
      articleId="kgAiuYaRXRYGWjs7TFRUtH"
    />
  );
}
export default HelpKitPersonalDeckAssignment;
