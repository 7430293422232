import { useMutation } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  type FormEvent,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  customList,
  customListItem,
  customListItemWrapper,
} from '../../../assets/shared-styles/CustomList';
import {
  DeleteGroupDocument,
  UpdateGroupDocument,
} from '../../../gql/mutations/__generated__/group.generated';
import { CreateStudentsDocument } from '../../../gql/mutations/__generated__/students.generated';
import { GroupDocument } from '../../../gql/queries/__generated__/group.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import type { StudentRow } from '../../../views/Groups/GroupShow';
import { CustomTooltip } from '../../shared/CustomTooltip';
import CustomDialog from '../../shared/Layout/Dialog';
import { CustomTable } from '../../shared/Table';
import { AlertsContext } from '../Alerts/context';
import { openConfirmation, pushSnack } from '../Alerts/context/actions';
import HelpKitBulkUploadStudents from '../HelpKitArticles/HelpKitBulkUploadStudents';
import HelpKitDeleteClass from '../HelpKitArticles/HelpKitDeleteClass';
import { GroupUpdateHeader } from './GroupUpdateHeader';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    paddingTop: 20,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(2),
    borderRadius: 4,
  },

  list: {
    ...customList(theme),
  },
  listItem: {
    ...customListItem(theme),
  },
  wrapper: {
    ...customListItemWrapper(theme),
  },
  subtitle: {
    marginTop: theme.spacing(2.5),
  },
  actionsContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '0 20px',
    padding: '15px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
  },
  bulkUploadButton: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(2),
  },
  caption: {
    marginBottom: 5,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  saveButton: {
    color: theme.palette.common.white,
  },
  textField: {
    marginTop: theme.spacing(1),
    width: '50%',
  },
  exampleLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
  tooltipTypography: {
    marginBottom: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const columns = [
  {
    title: 'STUDENT NAME',
    field: 'sortName',
  },
  {
    title: 'EMAIL',
    field: 'email',
  },
];

type GroupUpdateModalProps = {
  students: StudentRow[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  groupId: string;
};

export function GroupUpdateModal({
  groupId,
  name,
  students,
  open,
  setOpen,
}: GroupUpdateModalProps) {
  const classes = useStyles();
  const [groupName, setName] = useState(name);
  const { dispatch } = useContext(AlertsContext);
  const history = useHistory();
  const [selectedRows, setSelectedRows] = useState<Array<StudentRow>>([]);
  const [updateGroup, { loading }] = useMutation(UpdateGroupDocument, {
    variables: { groupId, name: groupName },
    onError: onError(dispatch),
    onCompleted: () => dispatch(pushSnack({ message: 'Class name updated.' })),
  });
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [deleteGroup, { loading: deleteLoading }] = useMutation(
    DeleteGroupDocument,
    {
      onCompleted: () => {
        dispatch(pushSnack({ message: 'Group deleted.' }));
        history.push('/');
        window.location.reload();
      },
      onError: onError(dispatch),
      refetchQueries: ['teacher'],
      variables: { groupId },
    }
  );

  const [createStudents, { loading: createLoading }] = useMutation(
    CreateStudentsDocument,
    {
      onError: onError(dispatch),
      update: (cache, res) => {
        const groupQuery = cache.readQuery({
          query: GroupDocument,
          variables: { groupId },
        });

        const response = res.data?.createStudents;

        if (groupQuery) {
          const updatedGroup = produce(groupQuery.group, (groupDraft) => {
            if (!groupDraft.enrollments) {
              groupDraft.enrollments = [];
            }

            if (response?.enrollments) {
              groupDraft.enrollments = groupDraft.enrollments.concat(
                response.enrollments
              );
            }
          });
          cache.writeQuery({
            data: { group: updatedGroup },
            query: GroupDocument,
          });

          const failedStudents = response?.failedStudents;
          const enrollments = response?.enrollments;
          if (
            enrollments &&
            enrollments.length > 0 &&
            failedStudents &&
            failedStudents.length > 0
          ) {
            dispatch(
              pushSnack({
                message: 'Bulk upload completed with some failures.',
                severity: 'warning',
              })
            );
            failedStudents.forEach((student, idx) => {
              dispatch(
                pushSnack({
                  message: `Row #${idx} failed to import: ${student.lastName},${student.firstName},${student.email}`,
                  severity: 'error',
                })
              );
            });
          } else if (failedStudents && failedStudents.length > 0) {
            dispatch(
              pushSnack({
                message: 'Bulk upload failed for all rows.',
                severity: 'error',
              })
            );
          } else {
            dispatch(
              pushSnack({
                message: 'Bulk upload completed successfully!',
                severity: 'success',
              })
            );
          }
        }
      },
    }
  );

  useEffect(() => {
    setName(name);
  }, [name]);

  const handleDelete = () => {
    dispatch(
      openConfirmation({
        confirmFunc: deleteGroup,
        message: (
          <div>
            <p>
              Deleting this class is permanent and cannot be undone. All
              students enrolled in their class will lose access to any progress
              that they&apos;ve made so far, including all their Personal Deck
              Questions.
            </p>
            <p>
              <strong>Are you sure you want to continue?</strong>
            </p>
          </div>
        ),
      })
    );
  };

  const handleUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    updateGroup();
  };

  const onClose = () => setOpen(false);

  const parseAndDoBulkUpload = (csv: string | Buffer) => {
    if (typeof csv === 'string') {
      const rows = csv
        .split('\n')
        .slice(1)
        .filter((row) => row && row.length !== 0);
      const studentList = [];
      let failureParsing = false;
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const attrs = row.split(',').map((elem) => elem.trim());
        if (attrs.length !== 3) {
          dispatch(
            pushSnack({
              message: `Invalid CSV row #${i}: ${row}`,
              severity: 'error',
            })
          );
          failureParsing = true;
          break;
        }
        if (attrs[0] === '' || attrs[1] === '' || attrs[2] === '') {
          dispatch(
            pushSnack({
              message: `Invalid CSV row #${i}: ${row}`,
              severity: 'error',
            })
          );
          failureParsing = true;
          break;
        }
        studentList.push({
          lastName: attrs[0],
          firstName: attrs[1],
          email: attrs[2],
        });
      }

      if (!failureParsing) {
        createStudents({
          variables: {
            groupId,
            students: studentList,
          },
        });
      }
    } else {
      dispatch(
        pushSnack({ message: 'Unexpected error while parsing input file!' })
      );
    }
  };

  const handleFileChosen = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      if (fileReader.result) {
        parseAndDoBulkUpload(fileReader.result as Buffer);
      }
    };
    const files = e?.target?.files;
    if (files && files[0]) {
      fileReader.readAsText(files[0]);
    }

    e.target.value = '';
  };

  const onClickBulkImportStudents = () => hiddenFileInput?.current?.click();

  return (
    <CustomDialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>
        {loading || deleteLoading || createLoading ? <LinearProgress /> : null}
        <div className={classes.titleContainer}>
          <Typography variant="h2" color="primary">
            EDIT CLASS
          </Typography>

          {
            <div className={classes.flex}>
              <Tooltip title="Delete class">
                <span>
                  <IconButton onClick={handleDelete} size="large">
                    <Delete />
                  </IconButton>
                </span>
              </Tooltip>
              <HelpKitDeleteClass />
            </div>
          }
        </div>
        <Typography className={classes.subtitle} variant="body1">
          Edit class name or un-enroll a student from this class.
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <ol className={classes.list}>
          <li className={classes.listItem}>
            <div className={classes.wrapper}>
              <form onSubmit={handleUpdate}>
                <Typography variant="h4" color="primary">
                  Class Name
                </Typography>
                <div className={classes.row}>
                  <TextField
                    className={classes.textField}
                    required
                    variant="outlined"
                    value={groupName}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </form>
            </div>
          </li>
          <li className={classes.listItem}>
            <div className={classes.wrapper}>
              <Typography
                variant="h4"
                color="primary"
                className={classes.caption}
              >
                {`Current Students (${students.length})`}
              </Typography>
              <CustomTable
                components={{
                  Toolbar: () => (
                    <GroupUpdateHeader
                      groupId={groupId}
                      selectedStudents={selectedRows}
                      setSelectedRows={setSelectedRows}
                    />
                  ),
                }}
                options={{
                  selection: true,
                  headerStyle: {
                    borderRadius: 0,
                    position: 'sticky',
                    top: 0,
                  },
                  maxBodyHeight: '45vh',
                }}
                onSelectionChange={(rows) => setSelectedRows(rows)}
                data={students}
                columns={columns}
              />
            </div>
          </li>
        </ol>
      </DialogContent>
      <DialogActions>
        <div className={classes.actionsContainer}>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileChosen}
            style={{ display: 'none' }}
            accept="text/plain,.csv"
          />
          <CustomTooltip
            title={
              <div className={classes.tooltip}>
                <Typography
                  variant="caption"
                  className={classes.tooltipTypography}
                >
                  To bulk import students, create a CSV that matches the format
                  of the example below.
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.tooltipTypography}
                >
                  If a CSV row has an issue, only that row will be skipped, and
                  the rest of the rows will still be processed.
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.tooltipTypography}
                >
                  <strong>
                    Note: The first row with the &lsquo;last name&rsquo;,
                    &lsquo;first name&rsquo;, and &lsquo;email&rsquo; headers is
                    required.
                  </strong>
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.tooltipTypography}
                >
                  <strong>
                    IMPORTANT: When adding students to the CSV, please use the
                    email address that they&apos;ll be logging into Podsie with.
                  </strong>
                </Typography>
                <HelpKitBulkUploadStudents />
              </div>
            }
          >
            <Button
              className={classes.bulkUploadButton}
              variant="outlined"
              onClick={onClickBulkImportStudents}
              color="secondary"
            >
              BULK IMPORT STUDENTS
            </Button>
          </CustomTooltip>
          <Button
            className={classes.cancelButton}
            variant="outlined"
            onClick={onClose}
            color="secondary"
          >
            CLOSE
          </Button>
          <Button
            onClick={() => updateGroup()}
            className={classes.saveButton}
            disabled={groupName === name}
            color="secondary"
            variant="contained"
          >
            SAVE CHANGES
          </Button>
        </div>
      </DialogActions>
    </CustomDialog>
  );
}
