import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import ImportOfficialStandards from './ImportOfficialStandards';
import StandardsBulkUpload from './StandardsBulkUpload';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  buttonsContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

enum ModalContent {
  csv = 'csv',
  official = 'official',
}

export function ImportStandards() {
  const classes = useStyles();
  const [modalContent, setModalContent] = useState<ModalContent>();

  const updateModalContentToStandards = () => {
    setModalContent(ModalContent.csv);
  };

  const updateModalContentToOfficial = () => {
    setModalContent(ModalContent.official);
  };

  const resetModalContent = () => {
    setModalContent(undefined);
  };

  if (modalContent === ModalContent.csv) {
    return <StandardsBulkUpload resetModalContent={resetModalContent} />;
  }

  if (modalContent === ModalContent.official) {
    return <ImportOfficialStandards resetModalContent={resetModalContent} />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={updateModalContentToStandards}
        >
          Bulk Upload from CSV
        </Button>
        <Button
          onClick={updateModalContentToOfficial}
          variant="contained"
          color="primary"
        >
          Import Common Core Math 6-8, Alg I Standards
        </Button>
      </div>
    </div>
  );
}

export default ImportStandards;
