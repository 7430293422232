import { Button, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import HelpKitBulkQuestionsUpload from '../HelpKitArticles/HelpKitBulkQuestionsUpload';
import QuestionTypeBulkUpload from './QuestionTypeBulkUpload';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  buttonsContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
    },
  },
  button: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      maxWidth: '250px',
    },
  },
  helpkitContainer: {
    marginBottom: theme.spacing(2),
  },
}));

export type BulkUploadType =
  | 'free-response'
  | 'multiple-choice'
  | 'short-answer'
  | 'select-all';

type QuestionsBulkUploadProps = {
  handleClose: () => void;
};
export function QuestionsBulkUpload({ handleClose }: QuestionsBulkUploadProps) {
  const classes = useStyles();
  const [bulkUploadType, setBulkUploadType] = React.useState<
    BulkUploadType | undefined
  >();

  const setType = (type: BulkUploadType) => {
    return () => setBulkUploadType(type);
  };

  if (bulkUploadType === undefined) {
    return (
      <div className={classes.root}>
        <div className={classes.helpkitContainer}>
          <HelpKitBulkQuestionsUpload />
        </div>
        <Typography variant="h6" color="primary">
          Which kind of questions would you like to bulk upload?
        </Typography>
        <div className={classes.buttonsContainer}>
          <Button
            className={classes.button}
            onClick={setType('free-response')}
            variant="contained"
          >
            Free Response
          </Button>
          <Button
            className={classes.button}
            onClick={setType('short-answer')}
            variant="contained"
          >
            Short Answer
          </Button>
          <Button
            className={classes.button}
            onClick={setType('multiple-choice')}
            variant="contained"
          >
            Multiple Choice
          </Button>
          <Button
            className={classes.button}
            onClick={setType('select-all')}
            variant="contained"
          >
            Select All
          </Button>
        </div>
      </div>
    );
  }
  return (
    <QuestionTypeBulkUpload
      setQuestionType={setBulkUploadType}
      questionType={bulkUploadType}
      handleClose={handleClose}
      setBulkUploadType={setBulkUploadType}
    />
  );
}

export default QuestionsBulkUpload;
