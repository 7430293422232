import { Divider, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { QuestionAttributesFragment } from '../../../../../../gql/fragments/__generated__/question.generated';
import { GetUpdateQuestionQuery } from '../../../../../../gql/queries/__generated__/question.generated';
import { AnswersBreakdown } from '../../../../../shared/Answers/AnswersBreakdown';
import AiResponseLoading from '../../../../../shared/Loaders/AiResponseLoading';
import { QuillDeltaAsHtml } from '../../../../../shared/QuillDeltaAsHtml';
import HelpKitQuestionVariations from '../../../../HelpKitArticles/HelpKitQuestionVariations';
import { UpdateQuestionVariationCard } from '../../../../Questions/Form/UpdateQuestionVariationCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '82vh',
    overflow: 'scroll',
  },
  baseQuestionContainer: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  variationHeaderContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  baseQuestion: {
    padding: 0,
  },
  explanationHeader: {
    marginBottom: theme.spacing(2),
  },
}));

type ShowQuestionVariationProps = {
  questionId: string;
  postUpdateCallback?: () => void;
  question: GetUpdateQuestionQuery['question'];
  editingOpen: boolean;
  aiResponseLoading: boolean;
  setSelectedVariation: (variationId: string | undefined) => void;
};

export function ShowQuestionVariations({
  postUpdateCallback,
  question,
  aiResponseLoading,
  setSelectedVariation,
}: ShowQuestionVariationProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.baseQuestionContainer}>
        <Typography color="primary" variant="h4" className={classes.title}>
          Base Question
        </Typography>
        <QuillDeltaAsHtml
          delta={question.richText.ops}
          className={classes.baseQuestion}
        />
        <AnswersBreakdown question={question} hideStats />
      </div>

      {question.variations?.map(
        (variation: QuestionAttributesFragment, index: number) => {
          return (
            <UpdateQuestionVariationCard
              index={index + 1}
              key={`${variation.id}-variation`}
              postUpdateCallback={postUpdateCallback}
              question={variation}
              setSelectedVariation={setSelectedVariation}
            />
          );
        }
      )}

      {!question.variations?.length && !aiResponseLoading && (
        <>
          <Divider />
          <div className={classes.variationHeaderContainer}>
            <Typography
              color="primary"
              variant="h5"
              className={classes.explanationHeader}
            >
              There are currently no question variations for this question.
            </Typography>

            <HelpKitQuestionVariations />
          </div>
        </>
      )}

      {aiResponseLoading && (
        <div>
          <AiResponseLoading />
        </div>
      )}
    </div>
  );
}
