import { makeStyles } from '@mui/styles';
import { QuestionSpecificEnrollmentQuestionsMasteriesQuery } from '../../../../gql/queries/__generated__/masteries.generated';
import { EnrollmentsQuestionsMastery } from '../../../../gql/types';
import QuestionCard from './QuestionCard';

const useStyles = makeStyles(() => ({
  root: {},
}));

type DetailsBodyProps = {
  masteries: QuestionSpecificEnrollmentQuestionsMasteriesQuery['paginatedEnrollmentQuestionsMasteries']['edges'];
};

export function DetailsBody({ masteries }: DetailsBodyProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {masteries?.map((edge) => {
        return (
          <QuestionCard
            key={edge?.node?.id}
            mastery={edge?.node as EnrollmentsQuestionsMastery}
          />
        );
      })}
    </div>
  );
}

export default DetailsBody;
