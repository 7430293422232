import { useMutation } from '@apollo/client';
import { Archive, Edit, FileCopy, MoreVert } from '@mui/icons-material';
import {
  IconButton,
  Menu,
  MenuItem,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  CloneAssignmentDocument,
  UpdateAssignmentDocument,
} from '../../../../gql/mutations/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { openConfirmation, pushSnack } from '../../Alerts/context/actions';
import HelpKitArchiveAssignment from '../../HelpKitArticles/HelpKitArchiveAssignment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  icon: {
    marginRight: theme.spacing(2),
  },
}));

type AssignmentActionProps = {
  assignmentId: string;
  assignmentName: string;
};

export function AssignmentAction({
  assignmentId,
  assignmentName,
}: AssignmentActionProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { dispatch } = useContext(AlertsContext);
  const history = useHistory();
  const [cloneAssignment] = useMutation(CloneAssignmentDocument, {
    onError: onError(dispatch),
    variables: { assignmentId },
    onCompleted: (copiedData) => {
      // re-route to the correct page
      history.push(`/assignments/${copiedData.cloneAssignment.id}`);
      dispatch(
        pushSnack({
          message: `A copy of ${assignmentName} has been created!`,
        })
      );
    },
  });
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [archiveAssignment] = useMutation(UpdateAssignmentDocument, {
    onError: onError(dispatch),
    variables: {
      assignmentId,
      assignmentStatus: AssignmentStatusEnum.Archived,
    },
    onCompleted: () => {
      window.location.reload();
      dispatch(
        pushSnack({
          message: 'Assignment has been archived!',
        })
      );
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const clone = () => cloneAssignment();

  const archive = () => {
    dispatch(
      openConfirmation({
        message: (
          <div>
            <p>
              Are you sure you want to archive this assignment? Archiving this
              assignment removes ALL student access to this assignment.
            </p>

            <p>
              <strong>
                However, archiving this assignment DOES NOT delete the
                assignment&apos;s questions or any associated student data.
              </strong>

              <div>
                Learn more about this here: <HelpKitArchiveAssignment /> (Note:
                this does not apply to Personal Deck Assignments.)
              </div>
            </p>
          </div>
        ),
        confirmFunc: () => archiveAssignment(),
        confirmButtonText: 'Archive Assignment',
      })
    );
  };

  return (
    <div className={classes.root}>
      <IconButton onClick={handleClick} size="large">
        <MoreVert />
      </IconButton>
      <Menu
        id="assignment-action"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Link to={`/assignments/${assignmentId}`}>
          <MenuItem>
            <Edit color="primary" className={classes.icon} />
            <Typography>Edit</Typography>
          </MenuItem>
        </Link>
        <MenuItem onClick={clone}>
          <FileCopy color="primary" className={classes.icon} />
          <Typography>Clone Assignment</Typography>
        </MenuItem>
        <MenuItem onClick={archive}>
          <Archive color="primary" className={classes.icon} />
          <Typography>Archive Assignment</Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AssignmentAction;
