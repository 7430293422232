import { useQuery } from '@apollo/client';
import { Close, DeleteForever } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import TrackVisit from '../../../../utils/hooks/TrackVisit';
import { CustomTooltip } from '../../../shared/CustomTooltip';
import { UpdateQuestion } from '../../Questions/Form/UpdateQuestion';
import ActiveToggle from '../../Questions/Settings/ActiveToggle';
import { DeleteQuestionConfirmation } from '../../Questions/Show/DeleteQuestionConfirmation';
import { Mode, type QuestionsStatsFilters } from '../QuestionsStats';
import { QuestionDetails } from './QuestionDetails';
import { QuestionProgress } from './QuestionProgress';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.h4.fontSize,
    marginRight: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  updateContainer: {
    padding: theme.spacing(2),
  },
  content: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 15px',
  },
  iconButtons: {
    height: 30,
    padding: 0,
    marginRight: theme.spacing(1),
  },

  buttonsContainer: {
    marginLeft: theme.spacing(2),
  },
  groupFilter: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(0.5),
    },
  },
  filterContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

export type QuestionStatsModalProps = {
  questionId?: string;
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
  setFilters: React.Dispatch<React.SetStateAction<QuestionsStatsFilters>>;
  filters: QuestionsStatsFilters;
  mode: Mode;
  setMode: React.Dispatch<React.SetStateAction<Mode>>;
  refetchReviewQuestions?: () => void;
};

export function QuestionStatsModal({
  questionId,
  open,
  handleClose,
  refetch,
  filters,
  setFilters,
  mode,
  setMode,
  refetchReviewQuestions,
}: QuestionStatsModalProps) {
  const classes = useStyles();
  const { data: teacherData } = useQuery(TeacherDocument);
  // Filter the active groups
  const groups = teacherData?.teacher.activeCourse?.groups || [];

  const postUpdateCallback = () => refetch();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const onClose = () => {
    handleClose();
    if (refetchReviewQuestions) {
      refetchReviewQuestions();
    }
    setMode(Mode.PROGRESS);
    setDeleteOpen(false);
  };

  const closeDelete = () => setDeleteOpen(false);

  const renderViews = () => {
    if (!questionId) {
      return null;
    }

    if (mode === 'editing') {
      return (
        <div className={classes.updateContainer}>
          <UpdateQuestion
            questionId={questionId}
            postUpdateCallback={postUpdateCallback}
            hideSubmitButton={false}
          />
        </div>
      );
    }

    if (mode === 'progress') {
      return (
        <QuestionProgress questionId={questionId} groupId={filters.groupId} />
      );
    }

    return (
      <QuestionDetails questionId={questionId} groupId={filters.groupId} />
    );
  };

  const handleModeChange = (e: SelectChangeEvent<Mode>) => {
    const v = e.target.value as Mode;
    setMode(v);
  };
  const onChangeGroup = (event: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      groupId:
        event.target.value === 'all'
          ? undefined
          : (event.target.value as string),
    });
  };

  return (
    <Dialog
      className={classes.root}
      maxWidth="md"
      fullWidth
      open={open}
      scroll="body"
    >
      {open && (
        <TrackVisit section="student-progress" page="questions-details" />
      )}
      <DialogTitle className={classes.header}>
        <div className={classes.titleContainer}>
          <div className={classes.filterContainer}>
            <Select
              className={classes.title}
              value={mode}
              onChange={handleModeChange}
              variant="outlined"
            >
              <MenuItem key={Mode.PROGRESS} value={Mode.PROGRESS}>
                Question Progress
              </MenuItem>
              <MenuItem key={Mode.DETAILS} value={Mode.DETAILS}>
                Question Details
              </MenuItem>
              <MenuItem key={Mode.EDITING} value={Mode.EDITING}>
                Question Edit
              </MenuItem>
            </Select>
            <Select
              onChange={onChangeGroup}
              value={filters.groupId === undefined ? 'all' : filters.groupId}
              className={classes.groupFilter}
              variant="outlined"
            >
              <MenuItem key="all-classes-select" value="all">
                All Classes
              </MenuItem>
              {groups.map((elem) => {
                return (
                  <MenuItem key={elem.id} value={elem.id.toString()}>
                    {elem.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <div className={classes.buttonsContainer}>
            <CustomTooltip
              open={deleteOpen}
              title={
                <DeleteQuestionConfirmation
                  postDeleteCallback={postUpdateCallback}
                  parentQuestion
                  questionId={questionId}
                  afterDelete={onClose}
                  closeDelete={closeDelete}
                />
              }
            >
              <IconButton
                aria-label="Delete Question"
                onClick={() => setDeleteOpen(!deleteOpen)}
                className={classes.iconButtons}
                size="large"
              >
                <DeleteForever />
              </IconButton>
            </CustomTooltip>
          </div>
        </div>
        <div>
          <ActiveToggle questionId={questionId} />
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={onClose}
            size="large"
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>{renderViews()}</DialogContent>
    </Dialog>
  );
}
