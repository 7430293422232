import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StandardsChartQueryVariables = Types.Exact<{
  standardsChartId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  standardsChartGroupCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type StandardsChartQuery = {
  __typename?: 'Query';
  standardsChart: {
    __typename?: 'StandardsChart';
    id: string;
    title: string;
    standardsCategories: Array<{
      __typename?: 'StandardsCategory';
      id: string;
      category: string;
      standards: Array<{
        __typename?: 'Standard';
        id: string;
        title: string;
        description: string;
      }>;
    }>;
  };
};

export type StandardsChartAdminsQueryVariables = Types.Exact<{
  standardsChartId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type StandardsChartAdminsQuery = {
  __typename?: 'Query';
  standardsChart: {
    __typename?: 'StandardsChart';
    id: string;
    admins: Array<{
      __typename?: 'Teacher';
      id: string;
      firstName?: string | null;
      fullName?: string | null;
      sortName?: string | null;
      lastName?: string | null;
      email: string;
      school?: { __typename?: 'School'; id: string; name: string } | null;
    }>;
  };
};

export type CollaborativeStandardsChartsQueryVariables = Types.Exact<{
  ownership: Types.OwnershipEnum;
}>;

export type CollaborativeStandardsChartsQuery = {
  __typename?: 'Query';
  collaborativeStandardsCharts: Array<{
    __typename?: 'StandardsChart';
    id: string;
    title: string;
  }>;
};

export const StandardsChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'standardsChart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardsChartId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardsChartGroupCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standardsChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardsChartId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardsChartId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardsChartGroupCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardsChartGroupCode' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'standardsCategories' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'standardsCategoryAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'standardAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Standard' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'standardsCategoryAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'StandardsCategory' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'category' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'standardAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StandardsChartQuery, StandardsChartQueryVariables>;
export const StandardsChartAdminsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'standardsChartAdmins' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardsChartId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standardsChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardsChartId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardsChartId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'admins' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'teacherLightAttributes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'teacherLightAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Teacher' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sortName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'school' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StandardsChartAdminsQuery,
  StandardsChartAdminsQueryVariables
>;
export const CollaborativeStandardsChartsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'collaborativeStandardsCharts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ownership' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OwnershipEnum' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborativeStandardsCharts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ownership' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ownership' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CollaborativeStandardsChartsQuery,
  CollaborativeStandardsChartsQueryVariables
>;
