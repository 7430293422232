import { useMutation } from '@apollo/client';
import { AddOutlined, DeleteOutline } from '@mui/icons-material';
import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { cloneDeep } from 'lodash';
import { Sources } from 'quill';
import React, { useContext } from 'react';
import { UnprivilegedEditor, Value } from 'react-quill';
import { useDebouncedCallback } from 'use-debounce';
import { QuestionAttributesFragment } from '../../../../../gql/fragments/__generated__/question.generated';
import { UpdateQuestionDocument } from '../../../../../gql/mutations/__generated__/question.generated';
import { GeneratedQuestionsQuery } from '../../../../../gql/queries/__generated__/question.generated';
import { ShortAnswerAnswerInput } from '../../../../../gql/types';
import theme from '../../../../../theme';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import InlineEditor from '../../../../shared/Editor/Inline';
import { AlertsContext } from '../../../Alerts/context';
import { RequiredFieldAsterisk } from '../RequiredFieldAsterisk';
import { DeepPartial, updateQuestionFields } from '../questions';
import { ShortAnswerAnswersOptions } from './ShortAnswerAnswersOptions';

type ShortAnswerAnswerEditProps = {
  question: GeneratedQuestionsQuery['generatedQuestions'][0];
};

export function ShortAnswerAnswerEdit({
  question,
}: ShortAnswerAnswerEditProps) {
  const { dispatch } = useContext(AlertsContext);
  const [shortAnswerAnswers, setShortAnswerAnswers] = React.useState<
    ShortAnswerAnswerInput[]
  >(question.shortAnswerAnswers || []);
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });
  const handleAddShortAnswer = () => {
    const updatedShortAnswerAnswers = cloneDeep(shortAnswerAnswers);
    updatedShortAnswerAnswers?.push({
      text: '',
      richText: { ops: [] },
      isCaseSensitive: false,
      ignoreWhitespaces: false,
      hidden: false,
    });
    setShortAnswerAnswers(updatedShortAnswerAnswers);
  };
  const deleteShortAnswer = (index: number) => {
    return () => {
      const updatedShortAnswerAnswers = cloneDeep(shortAnswerAnswers);
      updatedShortAnswerAnswers?.splice(index, 1);
      setShortAnswerAnswers(updatedShortAnswerAnswers);
    };
  };
  return (
    <Box>
      <Typography sx={{ fontWeight: theme.typography.fontWeightBold, mb: 1 }}>
        Acceptable Answer(s)
        <RequiredFieldAsterisk />
      </Typography>
      {shortAnswerAnswers?.map((answer, i) => (
        <IndividualShortAnswer
          question={question}
          setShortAnswerAnswers={setShortAnswerAnswers}
          deleteShortAnswer={deleteShortAnswer(i)}
          shortAnswer={answer}
          key={`gen-short-answer-${i}-${answer.id}`}
        />
      ))}
      <Button startIcon={<AddOutlined />} onClick={handleAddShortAnswer}>
        Add Answer
      </Button>
    </Box>
  );
}

type IndividualShortAnswerProps = {
  shortAnswer: NonNullable<ShortAnswerAnswerInput>;
  question: QuestionAttributesFragment;
  deleteShortAnswer: () => void;
  setShortAnswerAnswers: React.Dispatch<
    React.SetStateAction<ShortAnswerAnswerInput[]>
  >;
};

function IndividualShortAnswer({
  question,
  shortAnswer,
  setShortAnswerAnswers,
  deleteShortAnswer,
}: IndividualShortAnswerProps) {
  const [shortAnswerDelta, setShortAnswerDelta] = React.useState(
    shortAnswer.richText.ops
  );
  const { dispatch } = useContext(AlertsContext);
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });
  const theme = useTheme();
  // Debounce server update calls
  const debouncedServerUpdate = useDebouncedCallback(
    (editor: UnprivilegedEditor) => {
      let index = question.shortAnswerAnswers?.findIndex(
        (answer) => answer.id === shortAnswer.id
      );
      if (!shortAnswer.id) {
        // this means the short answer is new and hasn't been saved to the db yet
        index = question.shortAnswerAnswers?.length;
      }

      if (index === undefined || index === -1) {
        return;
      }

      const updatedShortAnswerAnswers: ShortAnswerAnswerInput[] =
        cloneDeep(question.shortAnswerAnswers) || [];

      if (updatedShortAnswerAnswers) {
        updatedShortAnswerAnswers[index] = {
          ...shortAnswer,
          id: shortAnswer.id,
          text: editor.getText(),
          richText: editor.getContents(),
        };
      }
      const changes: DeepPartial<QuestionAttributesFragment> = {
        shortAnswerAnswers: updatedShortAnswerAnswers as DeepPartial<
          QuestionAttributesFragment['shortAnswerAnswers']
        >,
      };

      const questionInput = updateQuestionFields(question, changes);
      updateQuestion({ variables: { question: questionInput } });
    },
    1000 // delay in ms
  );
  const handleAnswerChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    if (source !== 'user') return;
    setShortAnswerDelta(editor.getContents());
    debouncedServerUpdate(editor);
  };

  const handleDeleteShortAnswer = () => {
    // delete it in local state first:
    deleteShortAnswer();
    // then delete it in the db:
    const index = question.shortAnswerAnswers?.findIndex(
      (answer) => answer.id === shortAnswer.id
    );

    if (index === undefined || index === -1) {
      return;
    }

    const updatedShortAnswerAnswers = cloneDeep(question.shortAnswerAnswers);

    if (updatedShortAnswerAnswers) {
      updatedShortAnswerAnswers.splice(index, 1);
    }

    const changes: DeepPartial<QuestionAttributesFragment> = {
      shortAnswerAnswers: updatedShortAnswerAnswers,
    };

    const questionInput = updateQuestionFields(question, changes);
    updateQuestion({ variables: { question: questionInput } });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
      <Box width="100%">
        <InlineEditor value={shortAnswerDelta} onChange={handleAnswerChange} />
      </Box>
      <ShortAnswerAnswersOptions
        question={question}
        setShortAnswerAnswers={setShortAnswerAnswers}
        shortAnswer={shortAnswer}
      />
      <IconButton onClick={handleDeleteShortAnswer}>
        <DeleteOutline />
      </IconButton>
    </Box>
  );
}
