import { useQuery } from '@apollo/client';
import type { DialogProps } from '@mui/material';
import { useContext } from 'react';
import { StudentsAssignmentsDatumDocument } from '../../../../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import { StudentsAssignmentsQuestionsDocument } from '../../../../../gql/queries/__generated__/studentsAssignmentsQuestion.generated';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../../Alerts/context';
import { ResultsModal } from './ResultsModal';

type AssignmentResultProps = {
  studentId: string;
  groupsAssignmentId?: string;
  enrollmentsAssignmentId?: string;
  handleClose: () => void;
};

export function AssignmentResult({
  studentId,
  groupsAssignmentId,
  enrollmentsAssignmentId,
  handleClose,
  ...dialogProps
}: AssignmentResultProps & DialogProps) {
  const { dispatch } = useContext(AlertsContext);

  // Set up query for students assignment data
  const sadVariables: { [key: string]: string } = {
    studentId,
  };
  if (groupsAssignmentId) {
    sadVariables['groupsAssignmentId'] = groupsAssignmentId;
  }
  if (enrollmentsAssignmentId) {
    sadVariables['enrollmentsAssignmentId'] = enrollmentsAssignmentId;
  }

  const { data: assignmentData, loading: assignmentLoading } = useQuery(
    StudentsAssignmentsDatumDocument,
    {
      onError: onError(dispatch),
      fetchPolicy: 'cache-and-network',
      skip: (!groupsAssignmentId && !enrollmentsAssignmentId) || !studentId,
      variables: sadVariables,
    }
  );

  // Set up query for students assignments questions
  const saqVariables: { [key: string]: string } = {
    studentId,
  };
  if (groupsAssignmentId) {
    saqVariables['groupsAssignmentId'] = groupsAssignmentId;
  }
  if (enrollmentsAssignmentId) {
    saqVariables['enrollmentsAssignmentId'] = enrollmentsAssignmentId;
  }
  const { data, loading } = useQuery(StudentsAssignmentsQuestionsDocument, {
    fetchPolicy: 'cache-and-network',
    onError: onError(dispatch),
    skip: (!groupsAssignmentId && !enrollmentsAssignmentId) || !studentId,
    variables: saqVariables,
  });

  return (
    <ResultsModal
      {...dialogProps}
      data={data}
      loading={loading}
      assignmentData={assignmentData}
      assignmentLoading={assignmentLoading}
      handleClose={handleClose}
    />
  );
}
