import { useMutation, useQuery } from '@apollo/client';
import { Check, Close, Delete, DragHandle, Edit } from '@mui/icons-material';
import {
  Button,
  FormGroup,
  IconButton,
  TextField,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import type { StandardAttributesFragment } from '../../../../gql/fragments/__generated__/standard.generated';
import {
  DeleteStandardDocument,
  EditStandardDocument,
} from '../../../../gql/mutations/__generated__/standard.generated';
import { QuestionsForStandardDocument } from '../../../../gql/queries/__generated__/question.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { openConfirmation } from '../../Alerts/context/actions';
import { STANDARD_QUESTIONS_PAGINATION_COUNT } from './StandardQuestionsModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 4,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  title: {
    marginRight: theme.spacing(1.5),
  },
  editTitleContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
    alignItems: 'center',
  },
  field: {
    color: theme.palette.common.white,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    color: theme.palette.grays.dark,
    padding: 4,
    '&:hover': {
      opacity: 0.8,
    },
  },
  icon: {
    width: 20,
  },
  closeIcon: {
    width: 20,
    color: theme.palette.error.dark,
  },
  checkIcon: {
    width: 20,
    color: theme.palette.secondary.main,
  },
  dragIconContainer: {
    marginRight: theme.spacing(0.5),
    height: theme.spacing(3),
  },
  dragIcon: {
    color: theme.palette.mint.dark,
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  description: {
    padding: theme.spacing(1),
  },
  questionsButton: {
    minWidth: 132,
  },
}));

type StandardDisplayProps = {
  standardsCategoryId: string;
  standard: StandardAttributesFragment;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  setSelectedStandardId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedStandardTitle: React.Dispatch<React.SetStateAction<string>>;
  amAdmin: boolean;
  refetchData: () => void;
};

export function StandardDisplay({
  amAdmin,
  refetchData,
  standardsCategoryId,
  standard,
  dragHandleProps,
  setSelectedStandardId,
  setSelectedStandardTitle,
}: StandardDisplayProps) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const { data: teacherData } = useQuery(TeacherDocument);
  const teamId = teacherData?.teacher.activeCourse?.team?.id || '';
  const {
    data: standardQuestionsData,
    refetch,
    loading,
  } = useQuery(QuestionsForStandardDocument, {
    skip: !teamId,
    variables: {
      first: STANDARD_QUESTIONS_PAGINATION_COUNT,
      standardId: standard.id,
      teamId: teamId,
    },
  });
  const enterEdit = () => setEditMode(true);
  const leaveEdit = () => setEditMode(false);
  const [editedTitle, setEditedTitle] = useState(standard.title);
  const [editedDescription, setEditedDescription] = useState(
    standard.description
  );
  const { dispatch } = useContext(AlertsContext);
  const handleClick = () => {
    refetch();
    setSelectedStandardId(standard.id);
    setSelectedStandardTitle(standard.title);
  };
  const standardsChartId =
    teacherData?.teacher.activeCourse?.standardsChart?.id || '';
  const [deleteStandard] = useMutation(DeleteStandardDocument, {
    variables: { standardId: standard.id },
    onError: onError(dispatch),
    update: () => {
      refetchData();
    },
  });
  const [editStandard] = useMutation(EditStandardDocument, {
    variables: {
      description: editedDescription,
      standardId: standard.id,
      title: editedTitle,
    },
    onError: onError(dispatch),
    onCompleted: leaveEdit,
    update: () => {
      refetchData();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    editStandard();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEditedTitle(e.target.value);
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEditedDescription(e.target.value);

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(
      openConfirmation({
        message: (
          <div>
            <p>Are you sure you want to delete this standard?</p>
            <code>
              <p>
                <strong>{standard.title}</strong>- {standard.description}
              </p>
            </code>
            <p>
              All questions tagged to this standard will no longer be tagged
              with this standard. This <strong>CANNOT</strong> be undone.
            </p>
          </div>
        ),
        confirmButtonText: 'Confirm',
        confirmFunc: deleteStandard,
        maxWidth: 'md',
      })
    );
  };

  const render = () => {
    if (editMode) {
      return (
        <form onSubmit={handleSubmit}>
          <div className={classes.editTitleContainer}>
            <div {...dragHandleProps} className={classes.dragIconContainer}>
              <DragHandle className={classes.dragIcon} />
            </div>
            <FormGroup>
              <TextField
                required
                inputProps={{
                  style: { color: 'white' },
                }}
                value={editedTitle}
                onChange={handleTitleChange}
                className={classes.field}
              />
            </FormGroup>
            <IconButton
              className={classes.iconButton}
              onClick={leaveEdit}
              aria-label="Cancel edit standard"
              size="large"
            >
              <Close className={classes.closeIcon} />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              type="submit"
              aria-label="Save edit standard"
              size="large"
            >
              <Check className={classes.checkIcon} />
            </IconButton>
          </div>
          <FormGroup>
            <TextField
              inputProps={{
                style: { color: 'white' },
              }}
              className={classes.field}
              required
              variant="outlined"
              onChange={handleDescriptionChange}
              value={editedDescription}
              multiline
            />
          </FormGroup>
        </form>
      );
    }
    const numStandardQuestions =
      standardQuestionsData?.questionsForStandard.totalCount || 0;
    let label = `${numStandardQuestions} Question${
      numStandardQuestions === 1 ? '' : 's'
    }`;
    if (loading) {
      label = 'Loading...';
    }
    return (
      <div>
        <div className={classes.row}>
          <div className={classes.flex}>
            {amAdmin && (
              <div {...dragHandleProps} className={classes.dragIconContainer}>
                <DragHandle className={classes.dragIcon} />
              </div>
            )}
            <div className={classes.header}>
              <strong className={classes.title}>{standard.title}</strong>
              {amAdmin && (
                <>
                  <IconButton
                    onClick={enterEdit}
                    aria-label="Update Standard"
                    className={classes.iconButton}
                    size="large"
                  >
                    <Edit className={classes.icon} />
                  </IconButton>
                  <IconButton
                    onClick={handleDelete}
                    aria-label="Delete Standard"
                    className={classes.iconButton}
                    size="large"
                  >
                    <Delete className={classes.icon} />
                  </IconButton>
                </>
              )}
            </div>
          </div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClick}
            className={classes.questionsButton}
          >
            {label}
          </Button>
        </div>
        <Typography className={classes.description}>
          {standard.description}
        </Typography>
      </div>
    );
  };
  return <div className={classes.root}>{render()}</div>;
}

export default StandardDisplay;
