import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { MultipleChoiceAnswerChoice } from '../../../../gql/types';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  },
  bullet: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    fontSize: theme.typography.body1.fontSize,
  },
}));

type QuestionShowMultipleChoiceAnswerProps = {
  answer: MultipleChoiceAnswerChoice;
};

export function QuestionShowMultipleChoiceAnswer({
  answer,
}: QuestionShowMultipleChoiceAnswerProps) {
  const classes = useStyles();
  return (
    <li className={classes.listItem} key={`multiple-choice-${answer.id}`}>
      <span className={classes.bullet}>•</span>
      {answer.richText ? (
        <QuillDeltaAsHtml multipleChoice delta={answer.richText.ops} />
      ) : (
        <div>{answer.text}</div>
      )}
    </li>
  );
}
