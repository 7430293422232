import { Add, Delete } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Sources } from 'quill';
import { useContext, type ChangeEvent } from 'react';
import { UnprivilegedEditor, type Value } from 'react-quill';
import InlineEditor from '../../../../shared/Editor/Inline';
import { QuestionFormContext } from '../context';
import {
  addMultipleChoice,
  deleteMultipleChoice,
  updateMultipleChoiceAnswerChoiceIsCorrect,
  updateMultipleChoiceAnswerChoiceText,
} from '../context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  answerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

export function MultipleChoiceAnswerChoices() {
  const classes = useStyles();
  const { questionForm, dispatch } = useContext(QuestionFormContext);

  const { multipleChoiceAnswerChoices } = questionForm;
  const updateMultipleChoiceText = (multipleChoiceIndex: number) => {
    return (
      value: string,
      delta: Value,
      source: Sources,
      editor: UnprivilegedEditor
    ): void => {
      dispatch(
        updateMultipleChoiceAnswerChoiceText({
          text: editor.getContents(),
          multipleChoiceIndex,
        })
      );
    };
  };

  const updateMultipleChoiceIsCorrect = (multipleChoiceIndex: number) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        updateMultipleChoiceAnswerChoiceIsCorrect({
          isCorrect: e.target.checked,
          multipleChoiceIndex,
        })
      );
    };
  };

  const handleAddMultipleChoice = () => {
    dispatch(addMultipleChoice());
  };

  const handleDeleteMultipleChoice = (multipleChoiceIndex: number) => {
    return () => {
      dispatch(deleteMultipleChoice({ multipleChoiceIndex }));
    };
  };
  return (
    <div>
      {multipleChoiceAnswerChoices.map(
        ({ text, isCorrect }, multipleChoiceIndex) => {
          return (
            <div
              key={`short-answer-${multipleChoiceIndex}`}
              className={classes.answerContainer}
            >
              <FormControlLabel
                label="Is Correct"
                control={
                  <Checkbox
                    checked={isCorrect}
                    onChange={updateMultipleChoiceIsCorrect(
                      multipleChoiceIndex
                    )}
                    name="is-correct"
                    color="primary"
                  />
                }
              />
              <InlineEditor
                value={text}
                onChange={updateMultipleChoiceText(multipleChoiceIndex)}
              />
              <IconButton
                disabled={multipleChoiceAnswerChoices.length <= 2}
                aria-label="delete multiple choice"
                onClick={handleDeleteMultipleChoice(multipleChoiceIndex)}
                size="large"
              >
                <Delete />
              </IconButton>
            </div>
          );
        }
      )}
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={handleAddMultipleChoice}
      >
        ADD ANSWER CHOICE
      </Button>
    </div>
  );
}
