import { isUnknownRecord, type UnknownRecord } from '@podsie/utils/object.js';
import Quill from 'quill';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { DeltaOperation, OptionalAttributes } from 'quill';
import ReactQuill, { type Value } from 'react-quill';

// FIXME: Use a narrower type definition for the `Delta` class.
export const Delta = Quill.import('delta');

export const isQuillEmpty = (text: string, html: string) => {
  return text.trim().length === 0 && html.includes('img') === false;
};

export const containsImages = (delta: Value) =>
  isUnknownRecord(delta) &&
  Array.isArray(delta.ops) &&
  delta.ops.some(
    (operation) =>
      isInsertOperation(operation) && isImageInsertion(operation.insert)
  );

export const parsePlainAndRichText = (quill: ReactQuill) => {
  const editor = quill.getEditor();
  const text = editor.getText().trim();
  const html = editor.root.innerHTML;
  if (isQuillEmpty(text, html)) {
    return {
      plainText: undefined,
      richText: undefined,
    };
  }

  return {
    plainText: text,
    richText: editor.getContents(),
  };
};

export const getPlainTextFromDelta = (delta: Value): string => {
  if (typeof delta === 'string') {
    return delta;
  }
  if (delta === undefined) {
    return '';
  }

  const arr = typeof delta.reduce === 'function' ? delta : delta.ops || [];
  return arr
    .reduce((text, op) => {
      if (!isInsertOperation(op))
        throw new TypeError('only `insert` operations can be transformed!');

      if (isTextInsertion(op.insert)) {
        return text + op.insert;
      } else if (isFormulaInsertion(op.insert)) {
        return text + op.insert.formula;
      } else {
        // If an image insertion is received or any other unrecognized type of
        // insertion...
        return text + ' ';
      }
    }, '')
    .slice(0, -1); // remove extra `\n` line break from end of plain text
};

export const extractPlainAndRichText = <Delta extends Value>(delta: Delta) => ({
  text: getPlainTextFromDelta(delta),
  richText: delta,
});

const isBaseDeltaOperation = (
  value: unknown
): value is UnknownRecord & OptionalAttributes =>
  isUnknownRecord(value) &&
  (value.attributes === undefined || isUnknownRecord(value));

export type DeleteOperation = { delete: number } & OptionalAttributes;
export const isDeleteOperation = (value: unknown): value is DeleteOperation =>
  isBaseDeltaOperation(value) && typeof value.delete === 'number';

export type InsertOperation = {
  insert: TextInsertion | UnknownRecord;
} & OptionalAttributes;

/**
 * Is the given `value` an {@link InsertOperation}?
 *
 * Note that insert operations may be extended by external libraries to include
 * unexpected types. Consider further consider further checking the shape of
 * insert operations by checking the `.insert` property with more specific type
 * guards like {@link isFormulaInsertion} or {@link isImageInsertion}.
 */
export const isInsertOperation = (value: unknown): value is InsertOperation =>
  isBaseDeltaOperation(value) &&
  (typeof value.insert === 'string' || isUnknownRecord(value.insert));

export type TextInsertion = string;
export const isTextInsertion = (
  insertion: unknown
): insertion is TextInsertion => typeof insertion === 'string';

export type FormulaInsertion = { formula: string };
export const isFormulaInsertion = (
  insertion: unknown
): insertion is FormulaInsertion =>
  isUnknownRecord(insertion) && typeof insertion.formula === 'string';

export type ImageInsertion = { image: string };
export const isImageInsertion = (
  insertion: unknown
): insertion is ImageInsertion =>
  isUnknownRecord(insertion) && typeof insertion.image === 'string';

export type RetainOperation = { retain: number } & OptionalAttributes;
export const isRetainOperation = (value: unknown): value is RetainOperation =>
  isBaseDeltaOperation(value) && typeof value.retain === 'number';

export type AnyOperation = DeleteOperation | InsertOperation | RetainOperation;

/**
 * Is the given value a valid {@link DeltaOperation} (as used by Quill)?
 *
 * @note The implementation of this type guard checks that the given `value` is
 *   a union of the 3 known operations ({@link DeleteOperation},
 *   {@link InsertOperation}, {@link RetainOperation}) as this meets the
 *   requirement of a {@link DeltaOperation} but is arguably narrower and safer.
 * @see {@link isDeleteOperation}
 * @see {@link isInsertOperation}
 * @see {@link isRetainOperation}
 */
export const isDeltaOperation = (value: unknown): value is AnyOperation =>
  isDeleteOperation(value) ||
  isInsertOperation(value) ||
  isRetainOperation(value);
