import { Card, Chip, Divider, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuestionTypeEnum } from '../../../../../gql/types';
import { AnswersBreakdown } from '../../../../shared/Answers/AnswersBreakdown';
import { QuillDeltaAsHtml } from '../../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  cardItemContainer: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1),
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  tagsContainer: {
    padding: theme.spacing(1, 0, 0, 0),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  chip: {
    borderRadius: '6px',
    borderWidth: '2px',
    borderColor: theme.palette.primary.main,
  },
  questionText: {
    display: 'flex',
    alignItems: 'baseline',
  },
  questionNum: {
    marginRight: theme.spacing(1),
  },
}));

export function QuestionCard({
  questionNumber,
  question,
}: {
  questionNumber: number;
  question: any;
}) {
  const classes = useStyles();
  const getQuestionTypeLabel = (questionType: QuestionTypeEnum) => {
    switch (questionType) {
      case QuestionTypeEnum.FreeResponse:
        return 'Free Response';
      case QuestionTypeEnum.SelectAllMultipleChoice:
        return 'Select All Multiple Choice';
      case QuestionTypeEnum.SelectOneMultipleChoice:
        return 'Select One Multiple Choice';
      case QuestionTypeEnum.ShortAnswer:
        return 'Short Answer';
      default:
        throw new Error(`Invalid question type: ${questionType}}`);
    }
  };
  return (
    <Card className={classes.cardItemContainer}>
      <Typography>
        <div className={classes.questionText}>
          <span className={classes.questionNum}>{`${questionNumber}.`}</span>
          <span>
            <QuillDeltaAsHtml
              delta={question.richText.ops}
              style={{ padding: 0 }}
            />
            <AnswersBreakdown question={question} />
          </span>
        </div>
      </Typography>
      <Divider className={classes.divider} />
      <div className={classes.tagsContainer}>
        <Chip
          label={getQuestionTypeLabel(question.questionType)}
          variant="outlined"
          className={classes.chip}
        />
        <div>
          {question.standards.map((standard: { id: string; title: string }) => (
            <Chip
              className={classes.chip}
              key={standard.id}
              label={standard.title}
              variant="filled"
            />
          ))}
        </div>
      </div>
    </Card>
  );
}

export default QuestionCard;
