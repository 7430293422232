import { AssignmentStatusEnum } from '../../../../../gql/types';

export function getTabTitleDescription(
  assignmentStatus?: string,
  assignmentType?: string,
  unassignable?: boolean
) {
  if (!assignmentStatus || !assignmentType) {
    return '';
  }

  switch (assignmentStatus) {
    case AssignmentStatusEnum.Active:
      if (unassignable) {
        return 'Assignment is now active. To make changes, please first Un-Assign this assignment.';
      }
      return (
        'Assignment is now active. You cannot Un-Assign and make changes anymore since students have already' +
        ' begun to attempt questions.'
      );
    case AssignmentStatusEnum.InProgress:
      return 'Setup changes can no longer be made since a student has already started this assignment.';
    case AssignmentStatusEnum.PastDue:
      return 'Setup changes can no longer be made since this assignment is now past its due date.';
    default:
  }
  return assignmentType === 'standard'
    ? 'Adjust assignment name & assigned classes'
    : 'Personal Deck Assignments encourage students to review their Personal Decks by ' +
        'providing them with a target number of points to reach by the due date. Students ' +
        'earn points by consistently reviewing their Personal Decks each day.' +
        ' Please click on the explanation above for more details.';
}
