import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Question } from '../utils';

type StyleProps = {
  reviewable?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(1, 0),
  },
  label: {
    textDecoration: 'underline',
  },
  quillDeltaContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export function ShortAnswerBreakdown({ question }: { question: Question }) {
  const classes = useStyles({ reviewable: false });

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.label}>
        Accepted Answer
      </Typography>
      <div className={classes.quillDeltaContainer}>
        <Typography variant="body1">{question.question_answer}</Typography>
      </div>
    </div>
  );
}

export default ShortAnswerBreakdown;
