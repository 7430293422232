import { useMutation, useQuery } from '@apollo/client';
import { Button, TextField, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { CreateStandardsCategoryDocument } from '../../../../gql/mutations/__generated__/standard.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    justifyContent: 'center',
    height: 60,
  },
  addButton: {
    color: theme.palette.common.white,
  },
  createButton: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
  },
  form: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export function CategoryCreator({ refetchData }: { refetchData: () => void }) {
  const classes = useStyles();
  const [createMode, setCreateMode] = useState(false);
  const [category, setCategory] = useState('');
  const { data: teacherData } = useQuery(TeacherDocument);
  const standardsChartId =
    teacherData?.teacher.activeCourse?.standardsChart?.id || '';

  const openForm = () => setCreateMode(true);
  const closeForm = () => setCreateMode(false);
  const { dispatch } = useContext(AlertsContext);

  const [createCategory] = useMutation(CreateStandardsCategoryDocument, {
    variables: { category, standardsChartId },
    onError: onError(dispatch),
    onCompleted: () => {
      setCategory('');
    },
    update: () => {
      refetchData();
    },
  });
  const handleUpdate = (e: React.ChangeEvent<HTMLInputElement>) =>
    setCategory(e.target.value);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (standardsChartId) {
      createCategory();
    }
  };

  const renderContent = () => {
    if (createMode) {
      return (
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
            variant="outlined"
            label="Standards Category"
            value={category}
            required
            name="category-title"
            onChange={handleUpdate}
          />
          <Button
            type="button"
            onClick={closeForm}
            variant="contained"
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            aria-label="Create Category"
            className={classes.createButton}
          >
            Create
          </Button>
        </form>
      );
    }

    return (
      <div>
        <Button
          variant="contained"
          onClick={openForm}
          color="secondary"
          className={classes.addButton}
        >
          Add Category
        </Button>
      </div>
    );
  };
  return <div className={classes.root}>{renderContent()}</div>;
}

export default CategoryCreator;
