import { Alert, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingSkeletons } from './LoadingSkeletons';

const useStyles = makeStyles(() => ({
  loadingMessage: {
    textAlign: 'center',
  },
}));

type AiResponseLoadingProps = {
  numSkeletons?: number;
  skeletonHeight?: number;
};

export function AiResponseLoading({
  numSkeletons = 2,
  skeletonHeight = 45,
}: AiResponseLoadingProps) {
  const classes = useStyles();
  return (
    <Alert color="warning" variant="filled" severity="warning">
      <LoadingSkeletons num={numSkeletons} skeletonHeight={skeletonHeight} />
      <Typography
        color="primary"
        variant="h5"
        className={classes.loadingMessage}
      >
        This may take up to 30 seconds... Once you have generated a variation,
        please be sure to review it! The AI Model is NOT GUARANTEED to be
        accurate.
      </Typography>
    </Alert>
  );
}

export default AiResponseLoading;
