import { useQuery } from '@apollo/client';
import { MoreHoriz } from '@mui/icons-material';
import { IconButton, Tooltip, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format, formatDistance } from 'date-fns';
import { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { GroupDocument } from '../../../gql/queries/__generated__/group.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { EnrollmentStatusEnum } from '../../../gql/types';
import { dateFormat } from '../../../utils/dates';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { LS_PDQ_DATA_TYPE_KEY } from '../../../utils/localStorageKeys';
import { LS_STUDENT_PROGRESS_SELECTED_GROUP_ID } from '../../../utils/localStorageKeysFunction';
import type { StudentRow } from '../../../views/Groups/GroupShow';
import { CustomTable } from '../../shared/Table';
import { HeaderWithTooltip } from '../../shared/Table/HeaderWithTooltip';
import { PersonalDeckViewEnum } from '../StudentProgress/PersonalDeckStudentProgress';
import { LateAssignmentsModal } from './Assignments/LateAssignmentsModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  button: {
    color: theme.palette.common.white,
  },
  link: buttonLink(theme),
  italics: {
    fontStyle: 'italic',
  },
  columnHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    padding: 1,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      opacity: '0.85',
    },
    marginRight: theme.spacing(0.5),
    color: `${theme.palette.secondary.main} !important`,
  },
}));

type StudentsTableProps = { groupId: string };

export function StudentsTable({ groupId }: StudentsTableProps) {
  useTrackVisit({
    section: 'class',
    page: 'roster',
  });
  const classes = useStyles();
  const history = useHistory();
  const { data: teacherData } = useQuery(TeacherDocument);
  const activeCourseId = teacherData?.teacher.activeCourse?.id || '';
  const { data } = useQuery(GroupDocument, {
    variables: { groupId },
    fetchPolicy: 'cache-and-network',
  });
  const [lateAssignmentEnrollmentId, setLateAssignmentEnrollmentId] =
    useState('');

  const students = useMemo(() => {
    if (!data?.group.enrollments) {
      return [];
    }

    const enrolled: StudentRow[] = [];

    data.group.enrollments.forEach(
      ({
        enrollmentStatus,
        unfreezePersonalDeck,
        id,
        lastActivity,
        student: { email, lastName, sortName },
      }) => {
        if (enrollmentStatus !== EnrollmentStatusEnum.Enrolled) return;

        const frozenPersonalDeck =
          (data.group.freezePersonalDeck || data.group.unfreezeInProgress) &&
          !unfreezePersonalDeck;

        enrolled.push({
          email,
          id,
          lastActivity: lastActivity ? new Date(lastActivity) : undefined,
          lastName,
          numCompletedLast7Days:
            data.group.personalDeckCounts[id]?.completed_last_7_days,
          numDuePersonalDecksQuestions: frozenPersonalDeck
            ? 'frozen'
            : data.group.personalDeckCounts[id]?.due,
          numLateAssignments: data.group.lateAssignmentsData[id],
          sortName,
        });
      }
    );

    return enrolled;
  }, [data]);

  const handleClose = () => setLateAssignmentEnrollmentId('');
  const handleLateAssignmentClick = (eid: string) => {
    return () => setLateAssignmentEnrollmentId(eid);
  };

  const seeMorePdqInfo = () => {
    localStorage.setItem(
      LS_PDQ_DATA_TYPE_KEY,
      PersonalDeckViewEnum.DUE_OVER_TIME
    );
    history.push('/personal-deck');
    if (activeCourseId) {
      localStorage.setItem(
        LS_STUDENT_PROGRESS_SELECTED_GROUP_ID(activeCourseId),
        groupId
      );
    }
  };

  return (
    <div className={classes.root}>
      <CustomTable
        isLoading={!data}
        components={{
          Toolbar: () => null,
        }}
        data={students}
        columns={[
          {
            title: 'STUDENT NAME',
            field: 'lastName',
            defaultSort: 'asc',
            customSort: (a, b) =>
              (a.sortName ?? '').localeCompare(b.sortName ?? ''),
            render: (rowData) => (
              <Link
                className={classes.link}
                to={`/classes/${groupId}/enrollments/${rowData.id}`}
              >
                {rowData.sortName ?? rowData.email}
              </Link>
            ),
          },
          {
            title: 'EMAIL',
            field: 'email',
          },
          {
            title: (
              <Typography className={classes.columnHeader}>
                <Tooltip
                  title="Click here to see more info about this class' personal decks."
                  placement="top"
                >
                  <IconButton
                    className={classes.iconButton}
                    size="small"
                    onClick={seeMorePdqInfo}
                  >
                    <MoreHoriz />
                  </IconButton>
                </Tooltip>
                <span>Due in Personal Deck</span>
              </Typography>
            ),
            field: 'numDuePersonalDecksQuestions',
            render: ({ numDuePersonalDecksQuestions }) => {
              if (numDuePersonalDecksQuestions === 'frozen') {
                return <span className={classes.italics}>frozen</span>;
              }
              return numDuePersonalDecksQuestions || 0;
            },
          },
          {
            title: (
              <HeaderWithTooltip
                variant="h4"
                title="RECENTLY COMPLETED"
                tooltip="Number of personal deck questions completed over the last 7 days."
              />
            ),
            field: 'numCompletedLast7Days',
            render: ({ numCompletedLast7Days }) => {
              return numCompletedLast7Days || 0;
            },
          },
          {
            title: 'LATE ASSIGNMENTS',
            field: 'numLateAssignments',
            render: ({ numLateAssignments, id }) => {
              const num = numLateAssignments || 0;

              if (num === 0) {
                return num;
              }

              return (
                <button
                  className={classes.link}
                  onClick={handleLateAssignmentClick(id)}
                >
                  {num}
                </button>
              );
            },
          },
          {
            title: 'LAST ACTIVITY',
            field: 'lastActivity',
            render: ({ lastActivity }) => {
              if (!lastActivity) {
                return '';
              }
              const now = new Date();
              const formattedDistance = formatDistance(now, lastActivity);
              const formatted = format(lastActivity, dateFormat);

              return (
                <Tooltip title={formatted}>
                  <span>{`${formattedDistance} ago`}</span>
                </Tooltip>
              );
            },
          },
        ]}
      />
      <LateAssignmentsModal
        enrollmentId={lateAssignmentEnrollmentId}
        handleClose={handleClose}
      />
    </div>
  );
}
