import { Drawer, Hidden } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { NavList } from './NavList';

export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: drawerWidth,
  },
  drawerPaper: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    width: drawerWidth,
  },
}));

type NavProps = {
  drawerOpen: boolean;
  handleDrawerToggle: () => void;
};

export function Nav({ drawerOpen, handleDrawerToggle }: NavProps) {
  const classes = useStyles();

  return (
    <nav>
      <Hidden lgDown implementation="js">
        <Drawer
          className={classes.root}
          variant="persistent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open={drawerOpen}
        >
          <NavList handleDrawerToggle={() => {}} drawerOpen={drawerOpen} />
        </Drawer>
      </Hidden>
      <Hidden lgUp implementation="js">
        <Drawer
          variant="temporary"
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <NavList
            handleDrawerToggle={handleDrawerToggle}
            drawerOpen={drawerOpen}
          />
        </Drawer>
      </Hidden>
    </nav>
  );
}
