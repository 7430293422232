/**
 * @module utils/string
 *
 * A number of utilities around manipulating strings and/or transforming them.
 */

/**
 * Check if the current global context supports the {@link DOMParser} API.
 *
 * This not only checks for the availability of the {@link DOMParser} class
 * but also attempts to use it to parse a simple string without error.
 *
 * @returns {boolean}
 */
function checkForDOMParserSupport() {
  const DOMParser = globalThis.DOMParser;
  if (!DOMParser) return false;

  try {
    new DOMParser().parseFromString('x', 'text/html');
  } catch (_) {
    return false;
  }

  return true;
}

/**
 * Does the current global context support the {@link DOMParser} API?
 *
 * @type {boolean}
 * @see {@link checkForDOMParserSupport}
 */
let currentGlobalContextSupportsDOMParser = checkForDOMParserSupport();

/**
 * Are both `a` and `b` strings that are equal according to their locale?
 *
 * @example
 * areEqualStrings('hello', 'hello'); // true
 * areEqualStrings('hello', 'Hello'); // false
 * areEqualStrings('hello', 'Hello', { sensitivity: 'base' }); // true
 *
 * @param {unknown} a
 * @param {unknown} b
 * @param {Intl.CollatorOptions} [opts] that you could normally provide to
 *   `String#localeCompare`
 * @returns {boolean}
 */
export function areEqualStrings(a, b, opts) {
  return (
    isString(a) && isString(b) && a.localeCompare(b, undefined, opts) === 0
  );
}

/**
 * Get the human-meaningful text out of the `value` string. If the given
 * `value` string can be _trimmed_ down to an empty string or if the given
 * `value` is not a string, `undefined` is returned.
 *
 * The coercion of strings that aren't human-meaningful into `undefined` lends
 * this utility well into `??` fallback values.
 *
 * @example
 * ```js
 * const displayedIndentifier =
 *   asHumanText(currentUser.fullName) ?? currentUser.email
 * ```
 *
 * This is similar in implementation to ActiveSupport's `#presence` when called
 * on strings.
 * @param {string | null | undefined} value a nullable string that may contain
 *   non-whitespace characters
 * @returns {string | undefined}
 */
export function asHumanText(value) {
  return isString(value) && value.trim().length > 0 ? value.trim() : undefined;
}

/**
 * Find the _difference_ between two strings `a` and `b`, similar to the
 * [Levenshtein distance][levenshtein].
 *
 * [levenshtein]: https://en.wikipedia.org/wiki/Levenshtein_distance
 *
 * @example
 * findDiff('hello', 'hello'); // ''
 * findDiff('hello', 'Hello'); // 'H'
 *
 * @param {string} a
 * @param {string} b
 * @returns {string}
 * @see https://redstapler.co/find-diff-between-2-strings-with-javascript/
 */
export function findDiff(a, b) {
  let diff = '';
  b.split('').forEach(function (val, i) {
    if (val !== a.charAt(i)) diff += val;
  });
  return diff;
}

/**
 * Is the given `value` a string?
 *
 * @example
 * isString('hello'); // true
 * isString(123); // false
 * isString({}); // false
 * isString(null); // false
 *
 * @param {unknown} value to be checked
 * @returns {value is string}
 */
export function isString(value) {
  return typeof value === 'string';
}

/**
 * Convert a template string into HTML DOM nodes.
 *
 * @example
 * const html = toHTML('<div>hello</div>');
 * //    ^- HTMLElement whose innerHTML is '<div>hello</div>'
 *
 * @param  {string} str to be parsed as HTML
 * @returns {HTMLElement} resulting DOM node
 */
export function toHTML(str) {
  if (currentGlobalContextSupportsDOMParser) {
    // If DOMParser is supported, use it
    return new globalThis.DOMParser().parseFromString(str, 'text/html').body;
  } else {
    // Otherwise, fallback to old-school method
    const dom = globalThis.document.createElement('div');
    dom.innerHTML = str;
    return dom;
  }
}

/**
 * Truncate the given string `str` keeping only the given `length` of code units
 * starting from beginning of the string.
 *
 * @example
 * ```js
 * truncate('hello world', 5); // 'hello'
 * truncate('hello world'); // 'hello world'
 * truncate('hello world', 9999); // 'hello world'
 * truncate('hello world', -1); // ''
 * truncate('😀😀���', 2); // '😀';
 * ```
 *
 * String length is not the same as the number of characters in the string as
 * Javascript strings are UTF-16 encoded. This means that some characters are
 * represented by more than one code unit. For example, the character `😀` is
 * represented by two code units. See [MDN's article on the
 * topic][String#length].
 *
 * [String#length]: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/length#strings_with_length_not_equal_to_the_number_of_characters
 *
 * @param {string} str string to be truncated
 * @param {number} [length] number of characters to retain; defaults to `50`
 *   Negative lengths will be lower-bounded to `0` effectively returning an
 * empty string.
 * @param {object} [opts]
 * @param {string} [opts.suffix] string to append to the end of the resulting
 *   string if truncated; defaults to `''`
 * @returns {string} a new string of maximum length `length`
 */
export function truncate(str, length = 50, opts) {
  length = Math.max(length, 0);

  if (str.length > length) {
    return str.substring(0, length) + (opts?.suffix ?? '');
  } else {
    return str;
  }
}
