import { useMutation, useQuery } from '@apollo/client';
import { Info, Launch } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import React, { useContext, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { UpdateAssignmentDocument } from '../../../../../gql/mutations/__generated__/assignment.generated';
import {
  CreateGroupsAssignmentDocument,
  DeleteGroupsAssignmentDocument,
  UpdateGroupsAssignmentDocument,
} from '../../../../../gql/mutations/__generated__/groupsAssignment.generated';
import { AssignmentDocument } from '../../../../../gql/queries/__generated__/assignment.generated';
import { TeacherDocument } from '../../../../../gql/queries/__generated__/teacher.generated';
import { AssignmentStatusEnum } from '../../../../../gql/types';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { getTomorrowAtMidnight } from '../../../../../utils/dates';
import { AlertsContext } from '../../../Alerts/context';
import { openDialog } from '../../../Alerts/context/actions';
import { AssignmentEditorContext } from '../context';
import {
  createGroupsAssignmentAction,
  deleteGroupsAssignmentAction,
  onAssignmentEditorSaveSuccess,
  startAssignmentEditorLoading,
  updateAssignmentLaunchAt,
  updateAssignmentName,
  updateGroupsAssignmentAction,
  updateTargetDistributedPoints,
} from '../context/actions';
import { AssignmentSettings } from '../shared/AssignmentSettings';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    paddingTop: '12px',
  },
  selectAllButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.common.white,
  },
  group: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: theme.spacing(2),
    width: '100%',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      padding: 0,
      border: 'none',
      marginTop: 0,
      marginBottom: 0,
      alignItems: 'flex-end',
      height: 70,
      flexFlow: 'row',
    },
  },
  nameField: {
    width: '70%',
    maxWidth: '300px',
  },
  list: {
    padding: theme.spacing(2),
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
  },
  listItem: {
    fontSize: theme.typography.h6.fontSize,
    marginBottom: theme.spacing(3),
  },
  setUpFormContainer: {
    marginTop: '16px',
  },
  flexed: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  linkContent: {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    textTransform: 'none',
    marginLeft: theme.spacing(1),
  },
  picker: {
    marginTop: theme.spacing(1),
  },
}));

const tomorrowAtMidnight = getTomorrowAtMidnight();

export function PersonalDeckAssignGroups() {
  const classes = useStyles();
  const {
    dispatch: assignmentDispatch,
    assignmentEditor: {
      id: assignmentId,
      groupsAssignments,
      targetDistributedPoints,
      name,
      launchAt,
    },
  } = useContext(AssignmentEditorContext);
  const { dispatch } = useContext(AlertsContext);
  const { data, startPolling } = useQuery(TeacherDocument, {
    onError: onError(dispatch),
  });
  const [dueDate, setDueDate] = useState(tomorrowAtMidnight);
  const startPollingForUpdates = () => {
    startPolling(2500);
  };

  const groups = data?.teacher.activeCourse?.groups || [];

  const { data: assignmentData } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });

  useEffect(() => {
    if (dueDate.getTime() !== tomorrowAtMidnight.getTime()) {
      // if due date has already been set to match groups assignment, return:
      return;
    }
    if (assignmentData?.assignment.groupsAssignments) {
      assignmentData.assignment.groupsAssignments.some((ga) => {
        if (ga.dueDate) {
          // set due date to match groups assignments due date
          setDueDate(new Date(ga.dueDate));
          return true;
        }
        return false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentData]);

  const disabled =
    assignmentData?.assignment.assignmentStatus !==
    AssignmentStatusEnum.Pending;

  const [createGroupsAssignment] = useMutation(CreateGroupsAssignmentDocument, {
    onError: onError(dispatch),
    onCompleted: (data) => {
      const {
        id,
        dueDate,
        group: { id: groupId },
      } = data.createGroupsAssignment;
      assignmentDispatch(
        createGroupsAssignmentAction({
          groupId,
          groupsAssignmentId: id,
          dueDate: dueDate ? new Date(dueDate) : undefined,
        })
      );

      onAssignmentEditorSaveSuccess(assignmentDispatch);
    },
  });

  const [updateGroupsAssignment] = useMutation(UpdateGroupsAssignmentDocument, {
    onError: (error) => {
      const groupsAssignments = assignmentData?.assignment.groupsAssignments;

      const dueDate = groupsAssignments
        ? groupsAssignments[0]?.dueDate
        : undefined;
      if (dueDate) {
        setDueDate(new Date(dueDate));
      }
      onError(dispatch)(error);
    },
    onCompleted: () => onAssignmentEditorSaveSuccess(assignmentDispatch),
  });

  const [deleteGroupsAssignment] = useMutation(DeleteGroupsAssignmentDocument, {
    onError: onError(dispatch),
    onCompleted: (data) => {
      const { groupId } = data.deleteGroupsAssignment;
      assignmentDispatch(deleteGroupsAssignmentAction({ groupId }));
      onAssignmentEditorSaveSuccess(assignmentDispatch);
    },
  });

  const [updateAssignment] = useMutation(UpdateAssignmentDocument, {
    onError: onError(dispatch, true),
    onCompleted: () => onAssignmentEditorSaveSuccess(assignmentDispatch),
  });

  const debouncedUpdateAssignment = useDebouncedCallback(
    (updatedName: string) => {
      if (updatedName) {
        updateAssignment({
          variables: {
            assignmentId: assignmentId,
            name: updatedName,
          },
        });
        startAssignmentEditorLoading(assignmentDispatch);
      }
    },
    750
  );
  const debouncedUpdateAssignmentLaunchAt = useDebouncedCallback(
    (updatedDateTime: Date | null) => {
      updateAssignment({
        variables: {
          assignmentId: assignmentId,
          launchAt: updatedDateTime,
        },
      });
      startAssignmentEditorLoading(assignmentDispatch);
    },
    750
  );

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // local context update:
    assignmentDispatch(updateAssignmentName({ name: e.target.value }));
    // debounced API update:
    debouncedUpdateAssignment(e.target.value);
  };

  const debouncedUpdateGroupsAssignment = useDebouncedCallback(() => {
    Object.keys(groupsAssignments).forEach((groupId) => {
      const groupsAssignmentId = groupsAssignments[groupId].id;
      if (groupsAssignmentId) {
        updateGroupsAssignment({
          variables: {
            groupsAssignmentId: groupsAssignmentId,
            dueDate: dueDate.toISOString(),
          },
        });
      }
    });
  }, 750);

  const handleCheckboxChange =
    (groupId: string, existingGroupsAssignmentId?: string) => () => {
      startAssignmentEditorLoading(assignmentDispatch);
      if (groupsAssignments[groupId] && existingGroupsAssignmentId) {
        deleteGroupsAssignment({
          variables: { groupsAssignmentId: existingGroupsAssignmentId },
        });
      } else {
        createGroupsAssignment({
          variables: {
            groupId,
            assignmentId,
            dueDate: dueDate.toISOString(),
          },
        });
      }
    };

  const handleLaunchAtChange = (launchAt: unknown) => {
    const typedLaunchAt = launchAt as Date | null;
    assignmentDispatch(updateAssignmentLaunchAt({ launchAt: typedLaunchAt }));
    debouncedUpdateAssignmentLaunchAt(typedLaunchAt);
  };

  const handleDateChange = (dueDate: Date | null) => {
    if (!Object.keys(groupsAssignments).length) {
      dispatch(
        openDialog({
          title: 'Class assignment needed',
          message:
            'Please assign to at least one class before setting a due date.',
          error: true,
        })
      );
      return;
    }
    if (!dueDate) {
      return;
    }
    setDueDate(dueDate);
    Object.keys(groupsAssignments).forEach((groupId) => {
      assignmentDispatch(updateGroupsAssignmentAction({ groupId, dueDate }));
    });

    if (isNaN(dueDate.getTime())) {
      return;
    }
    startAssignmentEditorLoading(assignmentDispatch);
    debouncedUpdateGroupsAssignment();
  };

  const handleTargetPointsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const targetPoints = parseInt(e.target.value, 10) || 0;
    const updatePromise = new Promise<void>((resolve) => {
      assignmentDispatch(
        updateTargetDistributedPoints({
          targetDistributedPoints: targetPoints,
        })
      );
      resolve();
    });

    updatePromise.then(() => {
      updateAssignment({
        variables: {
          assignmentId,
          targetDistributedPoints: targetPoints > 0 ? targetPoints : 0,
        },
      });
    });
  };

  const selectAllClasses = async () => {
    const promises: Promise<unknown>[] = [];
    groups.forEach((group) => {
      if (groupsAssignments[group.id]) {
        return;
      }
      promises.push(
        new Promise((resolve) => {
          createGroupsAssignment({
            variables: {
              groupId: group.id,
              assignmentId,
              dueDate: dueDate.toISOString(),
            },
          }).then(() => resolve(true));
        })
      );
    });
    await Promise.all(promises);
  };

  return (
    <form className={classes.setUpFormContainer}>
      <ol className={classes.list}>
        <li className={classes.listItem}>
          <div className={classes.flexed}>
            <Typography variant="h4">Assignment Name</Typography>
          </div>
          <TextField
            onChange={handleNameChange}
            value={name}
            className={classes.nameField}
          />
        </li>
        <li className={classes.listItem}>
          <div className={classes.flexed}>
            <Typography variant="h4">Target Points</Typography>
            <Tooltip
              title="
              Target number of points that students need to reach in order to get a full score for this assignment.
            "
            >
              <Info className={classes.icon} fontSize="small" />
            </Tooltip>
          </div>
          <TextField
            onChange={handleTargetPointsChange}
            disabled={disabled}
            value={
              typeof targetDistributedPoints !== 'number' ||
              targetDistributedPoints < 1
                ? ''
                : targetDistributedPoints
            }
            type="number"
            inputProps={{ min: 1 }}
          />
        </li>
        <li className={classes.listItem}>
          <div className={classes.flexed}>
            <Typography variant="h4">Launch At</Typography>
            <Tooltip
              title="
                Date and time this assignment should be launched at.
                Leave this field blank if you'd like for the assignment to launch 
                right away.
            "
            >
              <Info className={classes.icon} fontSize="small" />
            </Tooltip>
          </div>
          <DateTimePicker
            ampmInClock
            className={classes.picker}
            disabled={disabled}
            label="Launch At"
            value={launchAt}
            disablePast={
              assignmentData?.assignment.assignmentStatus ===
              AssignmentStatusEnum.Pending
            }
            onChange={handleLaunchAtChange}
            slotProps={{
              tabs: { hidden: false },
              textField: {
                size: 'small',
                variant: 'standard',
              },
            }}
          />
        </li>
        <li className={classes.listItem}>
          <Typography variant="h4">
            Assign to Classes
            <Button
              startIcon={<Launch fontSize="small" />}
              className={classes.linkContent}
              color="secondary"
              onClick={startPollingForUpdates}
            >
              <a
                href="/settings/personal-deck-spacing"
                target="_blank"
                rel="noopener noreferrer"
              >
                Change &lsquo;max questions per study session&rsquo;
              </a>
            </Button>
          </Typography>
          <div>
            <Button
              disabled={
                Object.keys(groupsAssignments).length === groups.length ||
                disabled
              }
              variant="contained"
              color="secondary"
              className={classes.selectAllButton}
              onClick={selectAllClasses}
            >
              Select All
            </Button>
          </div>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {groups.map((group) => {
                const existing = groupsAssignments[group.id];
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={disabled}
                        onChange={handleCheckboxChange(group.id, existing?.id)}
                        checked={!!existing}
                        name={group.name}
                      />
                    }
                    key={group.id}
                    label={
                      <span>
                        <strong>{group.name}</strong>
                        <span>
                          {' '}
                          ({group.studySessionMax} questions per study session)
                        </span>
                      </span>
                    }
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </li>
        <li className={classes.listItem}>
          <Typography variant="h4">Due Date</Typography>
          <DateTimePicker
            ampmInClock
            className={classes.picker}
            disablePast={
              assignmentData?.assignment.assignmentStatus !==
              AssignmentStatusEnum.PastDue
            }
            label="Due Date"
            onChange={handleDateChange}
            slotProps={{
              tabs: { hidden: false },
              textField: {
                size: 'small',
                variant: 'standard',
              },
            }}
            value={dueDate}
          />
        </li>
        <li className={classes.listItem}>
          <AssignmentSettings />
        </li>
      </ol>
    </form>
  );
}
