import { useQuery } from '@apollo/client';
import { Grid, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { CurriculumAssignmentsDocument } from '../../../gql/queries/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../gql/types';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { AssignmentDisplay } from './AssignmentDisplay';
import { CurriculumAssignments } from './CurriculumAssignments';

export const curriculumDashboardHeaderHeight = 64;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  container: {
    height: '100%',
  },
  itemsList: {
    borderRight: '1px solid black',
    height: `calc(100vh - ${curriculumDashboardHeaderHeight}px)`,
    overflow: 'auto',
  },
  assignmentCard: {
    borderBottom: `1px solid ${theme.palette.grays.light}`,
    padding: theme.spacing(2.5),
    borderRadius: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: theme.palette.grays.light,
      cursor: 'pointer',
    },
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(2.5),
  },
  assignmentType: {
    color: theme.palette.grays.dark,
  },
  noResultsMessage: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  searchContainer: {
    padding: theme.spacing(2),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
  },
  searchField: {
    backgroundColor: theme.palette.common.white,
  },
}));
const ASSIGNMENTS_INDEX_PAGINATION_COUNT = 12;

export function Curriculum() {
  useTrackVisit({
    section: 'notifications',
    page: 'notifications',
  });
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [searchValue] = useDebounce(searchText, 700);
  const statuses: AssignmentStatusEnum[] = [
    AssignmentStatusEnum.Active,
    AssignmentStatusEnum.Pending,
    AssignmentStatusEnum.PastDue,
  ];
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { data: assignmentsData } = useQuery(CurriculumAssignmentsDocument, {
    variables: {
      statuses,
      filter: searchValue,
      sortByName: true,
      first: ASSIGNMENTS_INDEX_PAGINATION_COUNT,
    },
    fetchPolicy: 'cache-and-network',
  });
  const paginatedResults = assignmentsData?.assignments.edges || [];

  const selectedAssignmentId = paginatedResults[selectedIndex]?.node?.id || '';

  return (
    <div className={classes.root}>
      <Grid container className={classes.container}>
        <CurriculumAssignments
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <AssignmentDisplay selectedAssignmentId={selectedAssignmentId} />
      </Grid>
    </div>
  );
}
