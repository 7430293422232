import { useMutation, useQuery } from '@apollo/client';
import { CheckCircle } from '@mui/icons-material';
import { Button, Tooltip, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import { useContext, useState } from 'react';
import { FinishReviewSessionQuestionDocument } from '../../../../gql/mutations/__generated__/reviewSession.generated';
import {
  LatestFirstResponsesDocument,
  QuestionDocument,
} from '../../../../gql/queries/__generated__/question.generated';
import {
  ReviewSessionsQuestionDocument,
  ReviewSessionsQuestionsDocument,
} from '../../../../gql/queries/__generated__/reviewSessionsQuestion.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import {
  questionTypeIcons,
  renderQuestionType,
} from '../../../../utils/renderQuestionType';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { AlertsContext } from '../../Alerts/context';
import { AnswersBreakdown } from '../../Groups/Assignments/AnswersBreakdown';
import { BreakdownDetails } from '../../Groups/Assignments/BreakdownDetails';
import CompletionStats from '../../Groups/Assignments/CompletionStats';
import { QuestionsBreakdownProvider } from '../../Groups/Assignments/QuestionsBreakdownContext';

type StyleProps = {
  hideAnswers: boolean;
  hideStats: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    padding: theme.spacing(3),
    margin: theme.spacing(2),
    borderRadius: 4,
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    color: theme.palette.mint.dark,
    marginRight: theme.spacing(1),
  },
  questionTypeContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  questionType: {
    marginBottom: theme.spacing(1),
  },
  question: {
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: 'calc(12px + 1.2vw)',
    '& img': {
      minWidth: '25%',
    },
    '& div:first-child': {
      width: '100%',
    },
    lineHeight: 'normal',
  },
  questionNum: {
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold,
  },
  settings: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
  },
  hideAnswersButton: {
    color: ({ hideAnswers }: StyleProps) =>
      hideAnswers ? theme.palette.common.white : theme.palette.common.black,
    width: 140,
    marginRight: theme.spacing(1),
  },
  hideStatsButton: {
    color: ({ hideStats }: StyleProps) =>
      hideStats ? theme.palette.common.white : theme.palette.common.black,
    width: 140,
    marginRight: theme.spacing(1),
  },
}));

type ReviewModeQuestionProps = {
  questionId: string;
};

export function ReviewModeQuestion({ questionId }: ReviewModeQuestionProps) {
  const [hideAnswers, setHideAnswers] = useState(true);
  const [hideStats, setHideStats] = useState(true);
  const { dispatch } = useContext(AlertsContext);
  const { data: teacherData } = useQuery(TeacherDocument);
  const courseId = teacherData?.teacher.activeCourse?.id || '';
  const { data: reviewSessionQuestionData, refetch } = useQuery(
    ReviewSessionsQuestionDocument,
    {
      skip: !courseId,
      variables: { courseId, questionId },
    }
  );
  const classes = useStyles({ hideAnswers, hideStats });
  const { data } = useQuery(QuestionDocument, {
    skip: !questionId,
    variables: { questionId },
  });
  const { data: questionBreakdownData } = useQuery(
    LatestFirstResponsesDocument,
    {
      skip: !questionId,
      variables: { questionId },
    }
  );
  const [finishReviewQuestion, { loading: finishLoading }] = useMutation(
    FinishReviewSessionQuestionDocument,
    {
      onError: onError(dispatch),
      update: (cache, res) => {
        const queryResult = cache.readQuery({
          query: ReviewSessionsQuestionsDocument,
          variables: { courseId },
        });
        if (!res.data || !queryResult) return;

        const updatedReviewSessionsQuestions = produce(
          queryResult.reviewSessionsQuestions,
          (draft) =>
            draft.filter(
              (rq) =>
                rq.id !== res.data?.finishReviewSessionQuestion.id.toString()
            )
        );

        refetch();
        cache.writeQuery({
          data: { reviewSessionsQuestions: updatedReviewSessionsQuestions },
          query: ReviewSessionsQuestionsDocument,
          variables: { courseId },
        });
      },
    }
  );
  const question = data?.question;
  if (!reviewSessionQuestionData?.reviewSessionsQuestion) {
    return null;
  }
  if (!question) {
    return <LoadingSkeletons num={10} />;
  }
  const handleFinish = () =>
    finishReviewQuestion({
      variables: { questionId, courseId },
    });
  const { richText, questionType } = question;
  return (
    <div className={classes.root}>
      <div className={classes.settings}>
        <div>
          <Button
            variant="contained"
            color={hideAnswers ? 'secondary' : 'inherit'}
            className={classes.hideAnswersButton}
            onClick={() => setHideAnswers(!hideAnswers)}
          >
            {hideAnswers ? 'Show Answer' : 'Hide Answer'}
          </Button>
          <Button
            variant="contained"
            color={hideStats ? 'secondary' : 'inherit'}
            className={classes.hideStatsButton}
            onClick={() => setHideStats(!hideStats)}
          >
            {hideStats ? 'Show Stats' : 'Hide Stats'}
          </Button>
        </div>
        <Tooltip title="Mark as 'finished' and remove from review">
          <Button
            onClick={handleFinish}
            disabled={finishLoading}
            color="primary"
            startIcon={<CheckCircle />}
            variant="contained"
          >
            Done
          </Button>
        </Tooltip>
      </div>
      <QuestionsBreakdownProvider
        preloadedState={questionBreakdownData?.latestFirstResponses}
        hideAnswers={hideAnswers}
        hideStats={hideStats}
      >
        <>
          <div className={classes.topContainer}>
            <div className={classes.questionTypeContainer}>
              <span className={classes.icon}>
                {questionTypeIcons[questionType]}
              </span>
              <span className={classes.questionType}>
                <Typography variant="body1">
                  {renderQuestionType(questionType)}
                </Typography>
              </span>
            </div>
            <div>{!hideStats && <CompletionStats question={question} />}</div>
          </div>
          <div className={classes.question}>
            <QuillDeltaAsHtml delta={richText.ops} />
          </div>
          <AnswersBreakdown question={question} reviewable />
          {!hideStats && (
            <BreakdownDetails question={question} defaultOpen={true} />
          )}
        </>
      </QuestionsBreakdownProvider>
    </div>
  );
}

export default ReviewModeQuestion;
