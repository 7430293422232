import { ListItem, ListItemIcon, ListItemText, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { selected } from '../../../../assets/shared-styles/MuiListItem';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.secondary.main,
  },
  root: {
    paddingLeft: theme.spacing(2),
  },
  selected: selected(theme, parseInt(theme.spacing(2))),
}));

type NavListItemProps = {
  text: string;
  route: string;
  Icon: React.FunctionComponent;
  handleDrawerToggle: () => void;
};

export function NavListItem({
  handleDrawerToggle,
  Icon,
  text,
  route,
}: NavListItemProps) {
  const classes = useStyles();
  let match = !!useRouteMatch(route);
  const location = useLocation();
  if (match && route === '/' && location.pathname !== '/') {
    // special case to handle '/' route because it matches with everything:
    match = false;
  }

  return (
    <Link to={route}>
      <ListItem
        button
        onClick={handleDrawerToggle}
        selected={match}
        classes={{ root: classes.root, selected: classes.selected }}
      >
        <ListItemIcon className={classes.icon}>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </Link>
  );
}

export default NavListItem;
