import { Add } from '@mui/icons-material';
import {
  Button,
  MenuItem,
  Select,
  TextField,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { AssignmentStatusEnum } from '../../../../gql/types';
import { localStorageAssignmentTabKey } from '../../../../utils/localStorageKeys';
import { AssignmentCreateForm } from './AssignmentCreateForm';
import AssignmentCopyForm from './AssignmentsCopyForm/AssignmentCopyForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  left: { display: 'flex', alignItems: 'center' },
  right: { display: 'flex', alignItems: 'center' },
  button: {
    marginLeft: theme.spacing(1),
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  title: {
    color: theme.palette.common.white,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    margin: `0 0 ${theme.spacing(2)} 0`,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    },
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    justifyContent: 'space-between',
  },
  adjustedPadding: {
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
  },
  yellowBackground: {
    backgroundColor: theme.palette.yellow.main,
    color: theme.palette.yellow.dark,
  },
  greenBackground: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.green.dark,
  },
  blueBackground: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.blue.dark,
  },
}));

type AssignmentsIndexActionProps = {
  filterValue: AssignmentStatusEnum;
  setFilterValue: React.Dispatch<React.SetStateAction<AssignmentStatusEnum>>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
};

export function AssignmentsIndexAction({
  filterValue,
  setFilterValue,
  searchValue,
  setSearchValue,
}: AssignmentsIndexActionProps) {
  const classes = useStyles();
  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [internalSearch, setInternalSearch] = useState(searchValue);
  const [copyFormOpen, setCopyFormOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<AssignmentStatusEnum>) => {
    const newValue = event.target.value as AssignmentStatusEnum;
    localStorage.setItem(localStorageAssignmentTabKey, newValue.toString());
    setFilterValue(newValue);
  };
  const debouncedUpdateSearch = useDebouncedCallback((value: string) => {
    setSearchValue(value);
  }, 500);
  const onSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternalSearch(event.target.value);
    debouncedUpdateSearch(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.actionsContainer}>
        <div className={classes.left}>
          <TextField
            variant="outlined"
            value={internalSearch}
            onChange={onSearchValueChange}
            InputProps={{
              placeholder: 'Search',
              classes: {
                input: classes.adjustedPadding,
              },
            }}
            classes={{
              root: classes.adjustedPadding,
            }}
          />
          <Button
            aria-label="Create Assignment"
            onClick={() => setCreateFormOpen(true)}
            className={classes.button}
            variant="contained"
            startIcon={<Add />}
          >
            Create
          </Button>
          <Button
            aria-label="Create Assignment"
            onClick={() => setCopyFormOpen(true)}
            className={classes.button}
            variant="contained"
            startIcon={<Add />}
          >
            Copy
          </Button>
        </div>
        <div className={classes.right}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={filterValue}
            onChange={handleChange}
            variant="outlined"
            className={clsx({
              [classes.yellowBackground]:
                filterValue === AssignmentStatusEnum.Pending,
              [classes.greenBackground]:
                filterValue === AssignmentStatusEnum.Active,
              [classes.blueBackground]:
                filterValue === AssignmentStatusEnum.PastDue,
            })}
            classes={{
              select: classes.adjustedPadding,
            }}
          >
            <MenuItem value={AssignmentStatusEnum.Pending}>Drafts</MenuItem>
            <MenuItem value={AssignmentStatusEnum.Active}>Active</MenuItem>
            <MenuItem value={AssignmentStatusEnum.PastDue}>Past Due</MenuItem>
          </Select>
        </div>
      </div>
      <AssignmentCreateForm
        open={createFormOpen}
        handleClose={() => setCreateFormOpen(false)}
      />
      <AssignmentCopyForm
        open={copyFormOpen}
        handleClose={() => setCopyFormOpen(false)}
      />
    </div>
  );
}
