import { Alert, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  root: {},
  link: {
    textDecoration: 'underline',
  },
}));

export function MobileBanner() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [userDismissed, setUserDismissed] = useState(false);
  const [open, setOpen] = useState(matches);
  useEffect(() => {
    if (matches && !userDismissed) {
      setOpen(true);
    }
  }, [matches, userDismissed]);

  const dismissAlert = () => {
    setUserDismissed(true);
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={dismissAlert} severity="info" variant="filled">
        <strong>Note:</strong> Currently, the teacher side is intended for wider
        screens (laptop/desktop). In contrast, the student side (
        <a
          href="https://student.podsie.org"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
        >
          student.podsie.org
        </a>
        ) works well on both mobile devices and laptops!
      </Alert>
    </Snackbar>
  );
}

export default MobileBanner;
