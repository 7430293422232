export const dateFormat = 'MM/dd/yyyy hh:mm a';

export const timeOnlyFormat = 'hh:mm a';

export const monthAndDay = 'MMM dd';

export const dateFormatWithoutYear = 'MM/dd hh:mm a';

export const dateFormatWithoutTime = 'MM/dd/yyyy';

export const postgresDateFormatString = 'yyyy-MM-dd';

export const shortenedDateFormatString = 'MM/dd';

export const secondsPerDay = 86400000;

export const getTomorrowAtMidnight = () =>
  new Date(new Date(Date.now() + secondsPerDay).setHours(23, 59, 59, 999));

export const getNow = () => new Date(Date.now());
