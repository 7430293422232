import { useMutation } from '@apollo/client';
import { Button, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { ResetPointsDocument } from '../../../gql/mutations/__generated__/group.generated';
import { AlertsContext } from '../Alerts/context';
import { openConfirmation, openDialog } from '../Alerts/context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  resetButton: {
    marginLeft: theme.spacing(1),
  },
}));

type ResetButtonProps = {
  groupId: string;
};

export function ResetButton({ groupId }: ResetButtonProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const [resetLeaderboard] = useMutation(ResetPointsDocument, {
    onCompleted: () => {
      dispatch(
        openDialog({
          title: 'Reset in Progress',
          message:
            'Student XP are being reset. Please refresh your page in a few moments to see the updates.',
        })
      );
    },
    variables: { groupId },
  });

  const handleClick = () => {
    dispatch(
      openConfirmation({
        message:
          'Are you sure you want to reset the XP for all students in this class back to 0?',
        confirmFunc: () => resetLeaderboard(),
        confirmButtonText: 'Reset Student XP',
      })
    );
  };

  return (
    <Button
      variant="contained"
      size="small"
      className={classes.resetButton}
      onClick={handleClick}
    >
      Reset
    </Button>
  );
}

export default ResetButton;
