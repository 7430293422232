import makeStyles from '@mui/styles/makeStyles';
import { useContext, useEffect } from 'react';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import { PersonalDeckStudentProgress } from '../../components/application/StudentProgress/PersonalDeckStudentProgress';

const useStyles = makeStyles(() => ({
  root: {},
}));

export function PersonalDeck() {
  const classes = useStyles();
  const { updateTitle } = useContext(HeaderTitleContext);
  useEffect(() => updateTitle('Personal Deck'), [updateTitle]);

  return (
    <div className={classes.root}>
      <PersonalDeckStudentProgress />
    </div>
  );
}
