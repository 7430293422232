import HelpKitButton from './HelpKitButton';

export function HelpKitStudentEmailNotifications() {
  return (
    <HelpKitButton
      label="Understanding student email notifications"
      articleId="3c2xigGrPKnvpqeQBbSsyC"
    />
  );
}
export default HelpKitStudentEmailNotifications;
