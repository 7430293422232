import { Grid, Skeleton } from '@mui/material';
import { aiCreateFormWidth } from './AiCreateForm';

type QuestionsLoadingIndicatorProps = {
  loading: boolean;
};

export function QuestionsLoadingIndicator({
  loading,
}: QuestionsLoadingIndicatorProps) {
  if (!loading) return null;
  return (
    <Grid container sx={{ mt: 8 }}>
      <Grid item xs={12}>
        {Array.from({ length: 3 }, (_, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            width={aiCreateFormWidth}
            sx={{ mb: 4 }}
            height={100}
          />
        ))}
      </Grid>
    </Grid>
  );
}
