import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SetSchoolMutationVariables = Types.Exact<{
  schoolId: Types.Scalars['ID']['input'];
}>;

export type SetSchoolMutation = {
  __typename?: 'Mutation';
  setSchool: { __typename?: 'School'; id: string };
};

export type CreateSchoolMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  country: Types.Scalars['String']['input'];
  address: Types.Scalars['String']['input'];
  city: Types.Scalars['String']['input'];
  stateCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  timezone: Types.Scalars['String']['input'];
  zip?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreateSchoolMutation = {
  __typename?: 'Mutation';
  createSchool: { __typename?: 'School'; id: string };
};

export const SetSchoolDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'setSchool' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'schoolId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'setSchool' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'schoolId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'schoolId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SetSchoolMutation, SetSchoolMutationVariables>;
export const CreateSchoolDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createSchool' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'country' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'address' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'city' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'stateCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'timezone' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSchool' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'country' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'address' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'address' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'city' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'city' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stateCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'stateCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'timezone' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'timezone' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'zip' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'zip' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSchoolMutation,
  CreateSchoolMutationVariables
>;
