import { useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SetSchoolDocument } from '../../../../gql/mutations/__generated__/school.generated';
import { SchoolsQuery } from '../../../../gql/queries/__generated__/school.generated';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import HelpKitCorrectSchool from '../../HelpKitArticles/HelpKitCorrectSchool';
import SchoolDisplay from './SchoolDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    borderBottom: `1px solid ${theme.palette.grays.light}`,
  },
  schoolDisplay: {
    backgroundColor: theme.palette.mint.main,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    borderRadius: 4,
  },
  caption: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1.5),
  },
  cancelButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  actions: {
    marginTop: theme.spacing(2),
  },
  content: {
    paddingTop: theme.spacing(3),
  },
}));

type SchoolConfirmationModalType = {
  school?: SchoolsQuery['schools'][0];
  handleClose: () => void;
  onCompletedCallback?: () => void;
};

export function SchoolConfirmationModal({
  school,
  handleClose,
  onCompletedCallback,
}: SchoolConfirmationModalType) {
  const classes = useStyles();
  const [setSchool, { loading }] = useMutation(SetSchoolDocument, {
    onCompleted: () => {
      // the final step (step 2) is to set up courses
      onCompletedCallback?.();
      handleClose();
    },
  });
  const handleConfirm = () => {
    if (!school) return;

    setSchool({ variables: { schoolId: school.id } });
  };

  return (
    <Dialog open={!!school}>
      <DialogTitle className={classes.title}>Confirm</DialogTitle>
      <DialogContent className={classes.content}>
        {school ? (
          <>
            <Typography variant="body1" className={classes.caption}>
              Review details to confirm that this is your school:
            </Typography>

            <div className={classes.schoolDisplay}>
              <SchoolDisplay school={school} />
            </div>
          </>
        ) : (
          <LoadingSkeletons num={10} />
        )}
        <HelpKitCorrectSchool />
        <DialogActions className={classes.actions}>
          <Button
            variant="contained"
            onClick={handleClose}
            disabled={loading}
            className={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            disabled={loading}
          >
            Confirm
          </Button>
        </DialogActions>
      </DialogContent>
      {loading && <LinearProgress />}
    </Dialog>
  );
}

export default SchoolConfirmationModal;
