import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type TrackEventMutationVariables = Types.Exact<{
  event: Types.Scalars['String']['input'];
  metadata?: Types.InputMaybe<Types.Scalars['JSON']['input']>;
}>;

export type TrackEventMutation = {
  __typename?: 'Mutation';
  trackEvent: boolean;
};

export const TrackEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'trackEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'event' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'metadata' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'JSON' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'event' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'event' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'metadata' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'metadata' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TrackEventMutation, TrackEventMutationVariables>;
