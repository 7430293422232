import { useMutation, useQuery } from '@apollo/client';
import { Button, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import userflow from 'userflow.js';
import {
  CreateReviewSessionQuestionDocument,
  DeleteReviewSessionQuestionDocument,
} from '../../../../gql/mutations/__generated__/reviewSession.generated';
import { ReviewSessionsQuestionDocument } from '../../../../gql/queries/__generated__/reviewSessionsQuestion.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';
import HelpKitReviewQuestions from '../../HelpKitArticles/HelpKitReviewQuestions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: {
    width: 190,
  },
  link: {
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightBold,
  },
}));

type AddToReviewSessionButtonProps = {
  questionId: string;
};

export function AddToReviewSessionButton({
  questionId,
}: AddToReviewSessionButtonProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const teacherQuery = useQuery(TeacherDocument);
  const activeCourseId = teacherQuery.data?.teacher.activeCourse?.id || '';

  const questionQuery = useQuery(ReviewSessionsQuestionDocument, {
    fetchPolicy: 'cache-and-network',
    skip: !activeCourseId,
    variables: {
      courseId: activeCourseId,
      questionId,
    },
  });
  const [addReviewQuestion, reviewQuestionAddition] = useMutation(
    CreateReviewSessionQuestionDocument,
    {
      onCompleted: () => {
        questionQuery.refetch();
        userflow.track('userflow_review_mode_question_added', {
          question_id: questionId,
        });
        dispatch(
          pushSnack({
            message: (
              <span>
                Question added for review!{' '}
                <Link
                  to={'/progress/questions/review'}
                  className={classes.link}
                >
                  Click here to open review.
                </Link>
              </span>
            ),
          })
        );
      },
    }
  );
  const [removeReviewQuestion, reviewQuestionRemoval] = useMutation(
    DeleteReviewSessionQuestionDocument,
    {
      // Refetch review session questions if we successfully removed one.
      refetchQueries: ({ data }) =>
        data ? [ReviewSessionsQuestionDocument] : [],
    }
  );
  const handleAdd = () => {
    if (!activeCourseId) return;

    addReviewQuestion({ variables: { courseId: activeCourseId, questionId } });
  };

  const handleRemove = () => {
    if (!questionQuery.data?.reviewSessionsQuestion) return;

    removeReviewQuestion({
      variables: { courseId: activeCourseId, questionId },
    });
  };
  const loading =
    questionQuery.loading ||
    teacherQuery.loading ||
    reviewQuestionAddition.loading ||
    reviewQuestionRemoval.loading;

  const questionActiveInReviewSession =
    !!questionQuery.data?.reviewSessionsQuestion?.id;

  const handleClick = () => {
    if (questionActiveInReviewSession) {
      // remove from review session
      handleRemove();
      return;
    }
    // if not actively in a review session then add it to review session:
    handleAdd();
  };

  const buttonText = loading
    ? 'Loading...'
    : questionActiveInReviewSession
      ? 'Remove from Review'
      : 'Add to Review';

  return (
    <div>
      <Button
        variant="contained"
        disabled={loading}
        color="primary"
        onClick={handleClick}
        className={classes.button}
      >
        {buttonText}
      </Button>
      <span>
        {'  '}
        <HelpKitReviewQuestions />
      </span>
    </div>
  );
}

export default AddToReviewSessionButton;
