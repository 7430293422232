import { useContext, useEffect } from 'react';
import {
  Route,
  Switch,
  useLocation,
  type RouteComponentProps,
} from 'react-router-dom';
import { NotFound } from '../../components/application/ErrorPages/NotFound';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import { AssignmentEditor } from './AssignmentEditor';
import { AssignmentsIndexPage } from './AssignmentsIndexPage';

export function Assignments({ match }: RouteComponentProps) {
  const location = useLocation();
  const route = location.pathname;
  const { updateTitle } = useContext(HeaderTitleContext);
  useEffect(() => updateTitle('Assignments'), [updateTitle, route]);

  return (
    <Switch>
      <Route
        path={`${match.path}/:assignmentId`}
        component={AssignmentEditor}
      />
      <Route
        path="/"
        render={() => <AssignmentsIndexPage key={location.key} />} // https://github.com/ReactTraining/react-router/issues/7416#issuecomment-656551021
      />
      <Route component={NotFound} />
    </Switch>
  );
}
