import { useContext } from 'react';
import { type Value } from 'react-quill';
import { QuestionTypeEnum } from '../../../../gql/types';
import { getPlainTextFromDelta } from '../../../../utils/quillHelper';
import { renderQuestionType } from '../../../../utils/renderQuestionType';
import Explanation from '../../../shared/Question/Explanation';
import { QuestionFormContext } from './context';
import { updateSupplement } from './context/actions';

type QuestionFormExplanationProps = {
  numAttemptsNumber: number;
};

export function QuestionFormExplanation({
  numAttemptsNumber,
}: QuestionFormExplanationProps) {
  const { questionForm, dispatch } = useContext(QuestionFormContext);
  const {
    supplement,
    id,
    question,
    questionType,
    freeResponseAnswer,
    shortAnswerAnswers,
    multipleChoiceAnswerChoices,
  } = questionForm;
  const onUpdatedExplanation = (value: string | Value) => {
    dispatch(updateSupplement({ supplement: value }));
  };

  const questionTypeString = renderQuestionType(questionType);

  const answer = () => {
    switch (questionType) {
      case QuestionTypeEnum.FreeResponse:
        return `Exemplar Answer: ${getPlainTextFromDelta(
          freeResponseAnswer.text
        )}`;

      case QuestionTypeEnum.ShortAnswer: {
        let answer = 'Acceptable Answer(s):\n';
        shortAnswerAnswers.forEach((shortAnswer) => {
          // split into new lines:
          answer += `${shortAnswer.data.text}\n`;
        });
        return answer;
      }

      case QuestionTypeEnum.SelectAllMultipleChoice:
      case QuestionTypeEnum.SelectOneMultipleChoice: {
        let mcChoices = 'Answer Choices:\n';
        multipleChoiceAnswerChoices.forEach((choice) => {
          mcChoices += `(${
            choice.isCorrect ? 'Correct Choice' : 'Incorrect Choice'
          }) ${getPlainTextFromDelta(choice.text)}\n`;
        });
        return mcChoices;
      }

      default:
        throw new Error('Invalid question type');
    }
  };

  return (
    <Explanation
      answer={answer()}
      numAttemptsNumber={numAttemptsNumber}
      supplement={supplement}
      question={question}
      id={id}
      questionType={questionTypeString}
      onUpdatedExplanation={onUpdatedExplanation}
    />
  );
}

export default QuestionFormExplanation;
