import { useMutation, useQuery } from '@apollo/client';
import { Add, Visibility } from '@mui/icons-material';
import {
  Button,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import userflow from 'userflow.js';
import { buttonLink } from '../../assets/shared-styles/Button-Link';
import { AlertsContext } from '../../components/application/Alerts/context';
import { pushSnack } from '../../components/application/Alerts/context/actions';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import AverageDueChart from '../../components/application/PersonalDeck/AverageDueChart';
import { ReviewModeModal } from '../../components/application/StudentProgress/ReviewSession';
import { QuillDeltaAsHtml } from '../../components/shared/QuillDeltaAsHtml';
import { CreateReviewSessionQuestionDocument } from '../../gql/mutations/__generated__/reviewSession.generated';
import { QuestionsStatsDocument } from '../../gql/queries/__generated__/questionsStats.generated';
import { ReviewSessionsQuestionsDocument } from '../../gql/queries/__generated__/reviewSessionsQuestion.generated';
import { TeacherDocument } from '../../gql/queries/__generated__/teacher.generated';
import { SortDirectionEnum } from '../../gql/types';
import { dateFormatWithoutTime } from '../../utils/dates';

const useStyles = makeStyles((theme: Theme) => ({
  buttonLink: {
    ...buttonLink(theme),
    marginLeft: theme.spacing(1),
  },
  root: {
    paddingTop: theme.spacing(3),
    width: '85vw',
    [theme.breakpoints.up('md')]: {
      width: '75vw',
      padding: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      width: '65vw',
      padding: theme.spacing(5),
    },
    [theme.breakpoints.up('xl')]: {
      width: '50vw',
      padding: theme.spacing(6),
    },
    maxWidth: 1000,
    margin: '0 auto',
  },
  strugglingQuestionsContainer: {
    marginTop: theme.spacing(2),
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: 'rgb(239,249,244, 0.3)',
    maxHeight: '42vh',
    marginBottom: theme.spacing(4),
  },
  titleContainer: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grays.main}`,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
  },
  table: {
    overflowX: 'scroll',
  },
  flex: {
    display: 'flex',
  },
  row: {
    // '&:hover': {
    //   backgroundColor: theme.palette.mint.main,
    //   cursor: 'pointer',
    // },
    '& td': {
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
      borderBottom: `1px solid ${theme.palette.grays.main}`,
      maxWidth: '40vw',
    },
  },
  tableHead: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: theme.palette.primary.main,
    },
    '& th': {
      color: theme.palette.common.white,
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
  },
  noDataContainer: {
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
}));

export function Homepage() {
  const classes = useStyles();
  const { updateTitle } = useContext(HeaderTitleContext);
  useEffect(() => updateTitle('Home'), [updateTitle]);
  const { dispatch } = useContext(AlertsContext);
  const [reviewMode, setReviewMode] = useState(false);
  const history = useHistory();
  const { data: teacherData } = useQuery(TeacherDocument);
  const courseId = teacherData?.teacher.activeCourse?.id || '';
  const { data: reviewQuestionsData, refetch: refetchRQ } = useQuery(
    ReviewSessionsQuestionsDocument,
    {
      skip: !courseId,
      variables: { courseId },
    }
  );
  const { loading, data } = useQuery(QuestionsStatsDocument, {
    notifyOnNetworkStatusChange: true,
    variables: {
      first: 10,
      homepage: true,
      onlyShowActiveQuestions: true,
      onlyShowQuestionsWithData: true,
      sortDirection: SortDirectionEnum.Desc,
      sortField: 'struggling_students',
    },
  });
  const openReviewMode = () => setReviewMode(true);
  const [addReviewQuestion] = useMutation(CreateReviewSessionQuestionDocument, {
    onCompleted: (data) => {
      refetchRQ();
      const questionId = data.createReviewSessionQuestion.id;
      userflow.track('userflow_review_mode_question_added', {
        question_id: questionId,
      });
      dispatch(
        pushSnack({
          message: (
            <span>
              Question to Review Mode!{' '}
              <button onClick={openReviewMode} className={classes.buttonLink}>
                Open Review
              </button>{' '}
            </span>
          ),
          vertical: 'top',
          severity: 'info',
        })
      );
    },
  });
  const navToQuestion = (questionId: string) => {
    return () => history.push(`/progress/questions/${questionId}`);
  };

  const handleAdd = (questionId: string) => {
    return () =>
      addReviewQuestion({
        variables: {
          questionId,
          courseId,
        },
      });
  };
  const handleClose = () => setReviewMode(false);
  const numReviewQuestions =
    reviewQuestionsData?.reviewSessionsQuestions.length;
  const renderNoData = () => {
    if (loading) {
      return (
        <div>
          <LinearProgress />
        </div>
      );
    }
    return (
      <Typography variant="body1" className={classes.noDataContainer}>
        Currently no struggling questions!
      </Typography>
    );
  };

  const strugglingQuestions =
    data?.questionsStats.edges?.filter((edge) => {
      return edge?.node?.strugglingStudents && edge.node.strugglingStudents > 0;
    }) || [];

  return (
    <div className={classes.root}>
      <TableContainer className={classes.strugglingQuestionsContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h6">Struggling Questions</Typography>
          <Button variant="contained" color="primary" onClick={openReviewMode}>
            {`Review Mode${
              numReviewQuestions ? ` (${numReviewQuestions})` : ''
            }`}
          </Button>
        </div>
        {strugglingQuestions.length ? (
          <Table stickyHeader className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Question</TableCell>
                <TableCell>Struggling Students</TableCell>
                <TableCell>Recent Correct</TableCell>
                <TableCell>Overall Correct</TableCell>
                <TableCell>First Attempted</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {strugglingQuestions.map((edge) => {
                const totalAttempts = edge?.node?.totalAttempts || 0;
                const totalCorrect = edge?.node?.totalCorrect || 0;
                const recentCorrect = edge?.node?.numRecentCorrect || 0;
                const numStudents = edge?.node?.numStudentsAttempted || 0;
                const questionId = edge?.node?.id || '';
                const numStrugglingStudents =
                  edge?.node?.strugglingStudents || 0;

                if (numStrugglingStudents === 0) {
                  return null;
                }
                return (
                  <TableRow key={`qkey-${questionId}`} className={classes.row}>
                    <TableCell>
                      <span className={classes.flex}>
                        <Tooltip title="See more question details">
                          <IconButton
                            color="primary"
                            onClick={navToQuestion(questionId)}
                            size="large"
                          >
                            <Visibility />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Add question to Review Mode">
                          <IconButton
                            color="primary"
                            onClick={handleAdd(questionId)}
                            size="large"
                          >
                            <Add />
                          </IconButton>
                        </Tooltip>
                      </span>
                    </TableCell>
                    <TableCell>
                      <QuillDeltaAsHtml delta={edge?.node?.richText.ops} />
                    </TableCell>
                    <TableCell>{edge?.node?.strugglingStudents}</TableCell>
                    <TableCell>{`${recentCorrect} of ${numStudents}`}</TableCell>
                    <TableCell>{`${totalCorrect} of ${totalAttempts}`}</TableCell>
                    <TableCell>
                      {edge?.node?.firstSeen
                        ? format(
                            new Date(edge.node.firstSeen),
                            dateFormatWithoutTime
                          )
                        : ''}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ) : (
          renderNoData()
        )}
      </TableContainer>
      <div>
        <AverageDueChart />
      </div>
      {reviewMode && <ReviewModeModal onClose={handleClose} />}
    </div>
  );
}

export default Homepage;
