import { useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { StudentsAssignmentsQuestionDocument } from '../../../../../../gql/queries/__generated__/studentsAssignmentsQuestion.generated';
import { onError } from '../../../../../../utils/apollo/apolloHelper';
import AttemptsDetails from '../../../../../shared/AttemptsDetails';
import { LoadingSkeletons } from '../../../../../shared/Loaders/LoadingSkeletons';
import { QuillDeltaAsHtml } from '../../../../../shared/QuillDeltaAsHtml';
import { AlertsContext } from '../../../../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  content: {
    padding: 0,
  },
  detailsContainer: {
    padding: theme.spacing(2),
  },
  header: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 15px',
  },
  questionContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(2),
  },
}));

type GroupsAssignmentsAttemptsModalProps = {
  studentId: string;
  groupsAssignmentId?: string;
  enrollmentsAssignmentId?: string;
  questionId: string;
  studentName: string;
  onClose: () => void;
};

export function AttemptsModal({
  studentId,
  groupsAssignmentId,
  enrollmentsAssignmentId,
  studentName,
  questionId,
  onClose,
}: GroupsAssignmentsAttemptsModalProps) {
  const classes = useStyles();
  const open =
    !!studentId &&
    (!!groupsAssignmentId || !!enrollmentsAssignmentId) &&
    !!questionId;
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(StudentsAssignmentsQuestionDocument, {
    onError: onError(dispatch),
    fetchPolicy: 'cache-and-network',
    skip: !open,
    variables: {
      studentId,
      questionId,
      groupsAssignmentId,
      enrollmentsAssignmentId,
    },
  });

  return (
    <Dialog maxWidth="md" fullWidth onClose={onClose} open={open}>
      <DialogTitle className={classes.header} variant="h2">
        {studentName}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {loading || !data ? (
          <LoadingSkeletons num={8} />
        ) : (
          <>
            <div className={classes.questionContainer}>
              <QuillDeltaAsHtml
                delta={data.studentsAssignmentsQuestion.question.richText.ops}
              />
            </div>
            <div className={classes.detailsContainer}>
              <AttemptsDetails
                question={data.studentsAssignmentsQuestion.question}
                freeResponseResponses={
                  data.studentsAssignmentsQuestion.freeResponseResponses
                }
                shortAnswerResponses={
                  data.studentsAssignmentsQuestion.shortAnswerResponses
                }
                multipleChoiceResponses={
                  data.studentsAssignmentsQuestion.multipleChoiceResponses
                }
              />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
