import { useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { subWeeks } from 'date-fns';
import { useEffect, useState } from 'react';
import { TrackEventDocument } from '../../../../gql/mutations/__generated__/event.generated';
import { VISIT_QUESTIONS_MASTERY } from '../../../../utils/events';
import { useTrackVisit } from '../../../../utils/hooks/useTrackVisit';
import QuestionsProgressDetails from './Details';
import ProgressBreakdown from './ProgressBreakdown';

const useStyles = makeStyles(() => ({
  root: {},
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const timeFrames = [
  {
    label: 'Recently Introduced',
    description: 'Questions introduced in the last week',
    firstSeenStart: subWeeks(new Date(), 1),
    firstSeenEnd: null,
  },
  {
    label: '1-3 weeks ago',
    description: 'Questions introduced 1-3 weeks ago',
    firstSeenStart: subWeeks(new Date(), 3),
    firstSeenEnd: subWeeks(new Date(), 1),
  },
  {
    label: '3 - 8 weeks ago',
    description: 'Questions introduced 3-8 weeks ago',
    firstSeenStart: subWeeks(new Date(), 8),
    firstSeenEnd: subWeeks(new Date(), 3),
  },
  {
    label: '8+ weeks ago',
    description: 'Questions introduced more than 2 months ago',
    firstSeenStart: null,
    firstSeenEnd: subWeeks(new Date(), 8),
  },
];

type QuestionsProgressProps = {
  enrollmentId: string;
};

export function QuestionsProgress({ enrollmentId }: QuestionsProgressProps) {
  const classes = useStyles();
  useTrackVisit({
    section: 'student-profile',
    page: 'questions-progress',
  });

  const [selectedQuestionIds, setSelectedQuestionIds] = useState<string[]>([]);
  const handleModalClose = () => setSelectedQuestionIds([]);
  const handleClick = (questionIds: string[]) => {
    console.log('handleClick', questionIds);
    setSelectedQuestionIds(questionIds);
  };

  const [trackEvent] = useMutation(TrackEventDocument, {
    variables: { event: VISIT_QUESTIONS_MASTERY },
  });
  useEffect(() => {
    trackEvent();
  }, [trackEvent]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {timeFrames.map(
          ({ label, description, firstSeenStart, firstSeenEnd }) => {
            return (
              <ProgressBreakdown
                key={label}
                handleClick={handleClick}
                label={label}
                description={description}
                firstSeenStart={firstSeenStart}
                firstSeenEnd={firstSeenEnd}
                enrollmentId={enrollmentId}
              />
            );
          }
        )}
      </div>
      {selectedQuestionIds.length > 0 && (
        <QuestionsProgressDetails
          questionIds={selectedQuestionIds}
          handleClose={handleModalClose}
          enrollmentId={enrollmentId}
        />
      )}
    </div>
  );
}

export default QuestionsProgress;
