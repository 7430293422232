import { useMutation } from '@apollo/client';
import {
  Button,
  LinearProgress,
  Menu,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { UpdateEnrollmentDocument } from '../../../gql/mutations/__generated__/enrollment.generated';
import { EnrollmentStatusEnum } from '../../../gql/types';
import { onError } from '../../../utils/apollo/apolloHelper';
import type { StudentRow } from '../../../views/Groups/GroupShow';
import { AlertsContext } from '../Alerts/context';
import { openConfirmation, pushSnack } from '../Alerts/context/actions';
import { TransferGroup } from './TransferGroup';

const useStyles = makeStyles((theme: Theme) => ({
  selectedInfo: {
    fontWeight: theme.typography.fontWeightBold,
  },
  root: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'rgba(5, 59, 72, 0.85)',
    color: theme.palette.common.white,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  transferItem: {
    margin: theme.spacing(2),
  },
  approveButton: {
    backgroundColor: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
  declineButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.accent.main,
    color: theme.palette.accent.main,
  },
  buttonContainer: {
    display: 'flex',
    borderLeft: `1px solid ${theme.palette.common.white}`,
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
}));

type GroupUpdateHeaderProps = {
  selectedStudents: StudentRow[];
  setSelectedRows: React.Dispatch<React.SetStateAction<StudentRow[]>>;
  groupId: string;
};

export function GroupUpdateHeader({
  selectedStudents,
  groupId,
  setSelectedRows,
}: GroupUpdateHeaderProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const [updateEnrollment, { loading: updateLoading }] = useMutation(
    UpdateEnrollmentDocument,
    {
      onError: onError(dispatch),
    }
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  if (!selectedStudents.length) {
    return null;
  }

  const handleUnenroll = () => {
    const promises: Promise<unknown>[] = [];
    selectedStudents.forEach((enrollment) => {
      promises.push(
        new Promise<void>((resolve) => {
          updateEnrollment({
            variables: {
              enrollmentId: enrollment.id,
              enrollmentStatus: EnrollmentStatusEnum.Inactive,
            },
          }).then(() => {
            resolve();
          });
        })
      );
    });

    Promise.all(promises).then(() => {
      dispatch(
        pushSnack({
          message: 'Student(s) unenrolled.',
        })
      );
      setSelectedRows([]);
    });
  };

  const confirmUnenroll = () => {
    dispatch(
      openConfirmation({
        message: `Are you sure you want to un-enroll ${selectedStudents.length} student(s)?`,
        confirmFunc: handleUnenroll,
      })
    );
  };
  const isLoading = updateLoading;

  return (
    <>
      <div className={classes.root}>
        <Typography
          variant="h4"
          className={classes.selectedInfo}
        >{`${selectedStudents.length} student(s) selected`}</Typography>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.approveButton}
            onClick={handleClick}
          >
            TRANSFER
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <div className={classes.transferItem}>
              <TransferGroup
                setSelectedRows={setSelectedRows}
                selectedStudents={selectedStudents}
                groupId={groupId}
                closeTransfer={handleClose}
              />
            </div>
          </Menu>
          <Button
            variant="outlined"
            className={classes.declineButton}
            onClick={confirmUnenroll}
          >
            UNENROLL
          </Button>
        </div>
      </div>
      {isLoading && <LinearProgress />}
    </>
  );
}
