import { Stack, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { QuestionAttributesFragment } from '../../../../../../gql/fragments/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../../../gql/types';
import { QuillDeltaAsHtml } from '../../../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  freeResponseAnswer: {
    whiteSpace: 'pre-line',
  },
  richTextContainer: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

type AnswerProps = {
  question: QuestionAttributesFragment;
};

export function Answer({ question }: AnswerProps) {
  const classes = useStyles();
  const answers = () => {
    switch (question.questionType) {
      case QuestionTypeEnum.FreeResponse:
        return (
          <div>
            <Typography variant="h4">Exemplar Answer:</Typography>
            <div className={classes.richTextContainer}>
              <QuillDeltaAsHtml
                delta={question.freeResponseAnswer?.richText.ops}
              />
            </div>
          </div>
        );
      case QuestionTypeEnum.ShortAnswer:
        return (
          <div>
            <Typography variant="h4">Acceptable answers:</Typography>
            <ul>
              {question.shortAnswerAnswers &&
                question.shortAnswerAnswers.map(
                  ({ id, isCaseSensitive, richText }) => {
                    const caseSensitivityLabel = `(${
                      !isCaseSensitive ? 'not ' : ''
                    }case sensitive)`;

                    return (
                      <li key={id}>
                        <Stack direction="row" style={{ alignItems: 'center' }}>
                          <QuillDeltaAsHtml
                            delta={richText.ops}
                            style={{ backgroundColor: 'transparent' }}
                          />
                          {caseSensitivityLabel}
                        </Stack>
                      </li>
                    );
                  }
                )}
            </ul>
          </div>
        );
      case QuestionTypeEnum.SelectAllMultipleChoice:
      case QuestionTypeEnum.SelectOneMultipleChoice: {
        let answerText = 'Answer';
        if (
          question.questionType === QuestionTypeEnum.SelectAllMultipleChoice
        ) {
          answerText += '(s)';
        }
        return (
          <div>
            <Typography variant="h4">{`Correct ${answerText}`}</Typography>
            <ul>
              {question.multipleChoiceAnswerChoices &&
                question.multipleChoiceAnswerChoices.map(
                  ({ isCorrect, text, richText, id }) => {
                    if (isCorrect) {
                      return (
                        <li key={text + id}>
                          <QuillDeltaAsHtml
                            delta={richText.ops}
                            multipleChoice
                          />
                        </li>
                      );
                    }
                    return null;
                  }
                )}
            </ul>
          </div>
        );
      }

      default:
        return null;
    }
  };

  return <div className={classes.root}>{answers()}</div>;
}
