import HelpKitButton from './HelpKitButton';

export function HelpKitManageClass() {
  return (
    <HelpKitButton
      label="Managing your class"
      articleId="pBCBMeMSfHNwuzKDVFHRHB"
    />
  );
}
export default HelpKitManageClass;
