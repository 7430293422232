import { useMutation, useQuery } from '@apollo/client';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import HelpKitLeaderboard from '../../components/application/HelpKitArticles/HelpKitLeaderboard';
import { UpdateCourseSettingsDocument } from '../../gql/mutations/__generated__/course.generated';
import { TeacherSettingsDocument } from '../../gql/queries/__generated__/teacher.generated';
import { LeaderboardSettingEnum } from '../../gql/types';
import { renderLeaderboardSetting } from '../../utils/enumMappings';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  subtitle: {
    marginTop: '8px',
  },
}));

export function LeaderboardOptions() {
  const { data } = useQuery(TeacherSettingsDocument);
  const classes = useStyles();
  const course = data?.teacher.activeCourse;
  const courseId = course?.id || '';
  const [updateCourseSettings, { loading }] = useMutation(
    UpdateCourseSettingsDocument
  );

  const handleLeaderboardOptionChange =
    (option: LeaderboardSettingEnum) => () => {
      if (!courseId) return;

      updateCourseSettings({
        variables: {
          courseId,
          leaderboardSetting: option,
        },
      });
    };

  if (!course) {
    return null;
  }

  return (
    <div>
      <Typography variant="h5">Leaderboard Options</Typography>
      <Typography className={classes.subtitle} variant="body2">
        Choose options for how many students to show on the leaderboard.{' '}
        <HelpKitLeaderboard />
      </Typography>
      <RadioGroup defaultValue={course?.leaderboardSetting} row>
        {(
          Object.values(LeaderboardSettingEnum) as Array<LeaderboardSettingEnum>
        ).map((leaderboard) => {
          return (
            <div key={`leaderboard-settings-${leaderboard}`}>
              <FormControlLabel
                control={
                  <Radio
                    value={leaderboard}
                    onChange={handleLeaderboardOptionChange(leaderboard)}
                  />
                }
                disabled={loading}
                label={renderLeaderboardSetting(leaderboard)}
              />
            </div>
          );
        })}
      </RadioGroup>
    </div>
  );
}

export default LeaderboardOptions;
