import { useMutation, useQuery } from '@apollo/client';
import { Add } from '@mui/icons-material';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import userflow from 'userflow.js';
import { CreateAssignmentDocument } from '../../../../gql/mutations/__generated__/assignment.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { AssignmentTypeEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { sortGroups } from '../../../../utils/lists';
import { LS_PDQ_DATA_TYPE_KEY } from '../../../../utils/localStorageKeys';
import { LS_STUDENT_PROGRESS_SELECTED_GROUP_ID } from '../../../../utils/localStorageKeysFunction';
import { AlertsContext } from '../../Alerts/context';
import HelpKitPersonalDeckOverview from '../../HelpKitArticles/HelpKitPersonalDeckOverview';
import { PersonalDeckDailyData } from '../../PersonalDeck/PersonalDeckDailyData';
import { PersonalDeckDueOvertime } from '../../PersonalDeck/PersonalDeckDueOvertime';
import { PersonalDeckOverTime } from '../../PersonalDeck/PersonalDeckOverTime';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  filters: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: theme.spacing(2),
    padding: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(4),
    minWidth: 240,
  },
  createButton: {
    color: theme.palette.common.white,
  },
}));

export enum PersonalDeckViewEnum {
  DUE_OVER_TIME = 'due_over_time',
  DAILY = 'daily',
  PROGRESS_OVER_TIME = 'progress_over_time',
}

export function PersonalDeckStudentProgress() {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data: teacherData } = useQuery(TeacherDocument, {
    onError: onError(dispatch),
  });
  const activeCourseId = teacherData?.teacher.activeCourse?.id || '';
  const groups = sortGroups(teacherData?.teacher.activeCourse?.groups);
  const history = useHistory();

  const [groupId, setGroupId] = useState<string>(
    localStorage.getItem(
      LS_STUDENT_PROGRESS_SELECTED_GROUP_ID(activeCourseId)
    ) || ''
  );
  const storedViewValue =
    (localStorage.getItem(LS_PDQ_DATA_TYPE_KEY) as PersonalDeckViewEnum) || '';
  const [personalDeckView, setPersonalDeckView] =
    useState<PersonalDeckViewEnum>(
      Object.values(PersonalDeckViewEnum).includes(storedViewValue)
        ? storedViewValue
        : PersonalDeckViewEnum.DUE_OVER_TIME
    );
  const [createAssignment] = useMutation(CreateAssignmentDocument, {
    onCompleted: (data) => {
      userflow.track('userflow_assignment_created', {
        assignment_name: 'Personal Deck Assignment',
        from_page: 'personal_deck',
        assignment_type: 'personal_deck',
      });
      history.push(`/assignments/${data.createAssignment.id}`);
    },
    onError: onError(dispatch),
  });

  useEffect(() => {
    if (groupId) {
      return;
    }
    if (groups && groups.length > 0) {
      const tempGroupId = groups[0].id;
      setGroupId(tempGroupId);
      localStorage.setItem(
        LS_STUDENT_PROGRESS_SELECTED_GROUP_ID(activeCourseId),
        tempGroupId
      );
    }
  }, [activeCourseId, groupId, groups]);
  const handleSelectGroupChange = (event: SelectChangeEvent<string>) => {
    const updatedGroupId = event.target.value as string;
    setGroupId(updatedGroupId);
    if (activeCourseId) {
      localStorage.setItem(
        LS_STUDENT_PROGRESS_SELECTED_GROUP_ID(activeCourseId),
        updatedGroupId
      );
    }
  };
  const createPersonalDeckAssignment = () => {
    createAssignment({
      variables: {
        name: 'Personal Deck Assignment',
        assignmentType: AssignmentTypeEnum.Distributed,
      },
    });
  };
  const handlePersonalDeckViewChange = (
    event: SelectChangeEvent<PersonalDeckViewEnum>
  ) => {
    const updatedViewValue = event.target.value as PersonalDeckViewEnum;
    localStorage.setItem(LS_PDQ_DATA_TYPE_KEY, updatedViewValue);
    setPersonalDeckView(updatedViewValue);
  };
  const renderView = () => {
    switch (personalDeckView) {
      case PersonalDeckViewEnum.DAILY:
        return <PersonalDeckDailyData groupId={groupId} />;

      case PersonalDeckViewEnum.PROGRESS_OVER_TIME:
        return <PersonalDeckOverTime groupId={groupId} />;
      case PersonalDeckViewEnum.DUE_OVER_TIME:
        return <PersonalDeckDueOvertime groupId={groupId} />;
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.filters}>
        <div>
          <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>Class</InputLabel>
              <Select
                value={groupId}
                onChange={handleSelectGroupChange}
                label="Class"
                autoWidth={true}
              >
                {groups?.map((group) => {
                  return (
                    <MenuItem key={`group-${group.id}`} value={group.id}>
                      {group.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel>View</InputLabel>
              <Select
                value={personalDeckView}
                onChange={handlePersonalDeckViewChange}
                label="View"
              >
                <MenuItem value={PersonalDeckViewEnum.DAILY}>
                  Daily Data
                </MenuItem>
                <MenuItem value={PersonalDeckViewEnum.DUE_OVER_TIME}>
                  Due Over Time
                </MenuItem>
                <MenuItem value={PersonalDeckViewEnum.PROGRESS_OVER_TIME}>
                  Progress Over Time
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <HelpKitPersonalDeckOverview />
        </div>
        <div>
          <Tooltip title="Create a special assignment type that incentivizes your students to review their Personal Decks consistently.">
            <Button
              variant="contained"
              color="secondary"
              onClick={createPersonalDeckAssignment}
              startIcon={<Add />}
              className={classes.createButton}
            >
              Personal Deck Assignment
            </Button>
          </Tooltip>
        </div>
      </div>
      {renderView()}
    </div>
  );
}
