import { useMutation, useQuery } from '@apollo/client';
import { Button, TextField, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useRef, useState } from 'react';
import { CreateStandardDocument } from '../../../../gql/mutations/__generated__/standard.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  form: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  description: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  createButton: {
    color: theme.palette.common.white,
  },
}));

type StandardCreatorProps = {
  standardsCategoryId: string;
  refetchData: () => void;
};

export function StandardCreator({
  standardsCategoryId,
  refetchData,
}: StandardCreatorProps) {
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const { dispatch } = useContext(AlertsContext);
  const { data: teacherData } = useQuery(TeacherDocument);
  const standardsChartId =
    teacherData?.teacher.activeCourse?.standardsChart?.id || '';
  const titleRef = useRef<undefined | HTMLInputElement>();
  const [createStandard] = useMutation(CreateStandardDocument, {
    variables: {
      description,
      standardsCategoryId,
      title,
    },
    onError: onError(dispatch),
    onCompleted: () => {
      setTitle('');
      setDescription('');
      if (titleRef.current) {
        titleRef.current.focus();
      }
    },
    update: () => {
      refetchData();
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createStandard();
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTitle(e.target.value);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setDescription(e.target.value);

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          label="Standard Title"
          inputRef={titleRef}
          value={title}
          name="standard-title"
          onChange={handleTitleChange}
          className={classes.title}
        />
        <TextField
          variant="outlined"
          label="Standard Description"
          value={description}
          onChange={handleDescriptionChange}
          name="standard-description"
          className={classes.description}
          fullWidth
        />
        <div>
          <Button
            color="secondary"
            className={classes.createButton}
            variant="contained"
            type="submit"
            aria-label="Create Standard"
          >
            Create
          </Button>
        </div>
      </form>
    </div>
  );
}

export default StandardCreator;
