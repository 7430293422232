import {
  Assignment,
  AssignmentStatusEnum,
  AssignmentTypeEnum,
  StudentsAssignmentsDatum,
} from '../gql/types';

export const assignmentTypeNameMapping = {
  standard: 'Regular',
  personal_deck: 'Personal Deck',
  spiraled: 'Regular with Spiraled',
  distributed: 'Personal Deck',
  optional: 'Optional',
  individual: 'Individual',
};

type CalculateAssignmentStatsArgs = {
  studentsAssignmentsDatum: StudentsAssignmentsDatum;
  numStudents: number;
  numFinishedStudents: number;
  numFinishedStudentsNonZeroQuestions: number;
  totalPoints: number;
  assignment: Assignment;
};

type CalculateAssignmentStatsResults = {
  updatedNumStudents: number;
  updatedNumFinishedStudents: number;
  updatedNumFinishedStudentsNonZeroQuestions: number;
  updatedTotalPoints: number;
};

export const calculateAssignmentStats = ({
  studentsAssignmentsDatum,
  numStudents,
  numFinishedStudents,
  numFinishedStudentsNonZeroQuestions,
  totalPoints,
  assignment,
}: CalculateAssignmentStatsArgs): CalculateAssignmentStatsResults => {
  const {
    questionsCompleted,
    distributedPointsEarned,
    questionsCorrect,
    questionsTotal,
  } = studentsAssignmentsDatum;
  let updatedNumStudents = numStudents;
  let updatedNumFinishedStudents = numFinishedStudents;
  let updatedNumFinishedStudentsNonZeroQuestions =
    numFinishedStudentsNonZeroQuestions;
  let updatedTotalPoints = totalPoints;

  updatedNumStudents += 1;
  // for distributed assignments:
  if (assignment.assignmentType === AssignmentTypeEnum.Distributed) {
    if (
      distributedPointsEarned &&
      distributedPointsEarned &&
      distributedPointsEarned >= (assignment.targetDistributedPoints || 0)
    ) {
      updatedNumFinishedStudents += 1;
    }
  } else if (questionsCompleted === questionsTotal) {
    // for other assignment types, only count score towards average
    // if student finished:
    updatedNumFinishedStudents += 1;
    if (questionsTotal > 0) {
      updatedNumFinishedStudentsNonZeroQuestions += 1;
      updatedTotalPoints += Math.round(
        (questionsCorrect / questionsTotal) * 100
      );
    }
  }
  return {
    updatedNumStudents,
    updatedNumFinishedStudents,
    updatedNumFinishedStudentsNonZeroQuestions,
    updatedTotalPoints,
  };
};

export const determineStatuses = (assignmentStatus: AssignmentStatusEnum) => {
  let statuses = [AssignmentStatusEnum.Active, AssignmentStatusEnum.InProgress];
  switch (assignmentStatus) {
    case AssignmentStatusEnum.PastDue:
      statuses = [AssignmentStatusEnum.PastDue];
      break;
    case AssignmentStatusEnum.Pending:
      statuses = [AssignmentStatusEnum.Pending];
      break;
    default:
      break;
  }
  return statuses;
};
