import { grey } from '@mui/material/colors';
import { createTheme, Theme } from '@mui/material/styles';
import { renderTimeViewClock } from '@mui/x-date-pickers';
// Empty type-only import augments MUI `Theme` interface as a side-effect
// https://mui.com/x/react-date-pickers/base-concepts/#typescript
import type {} from '@mui/x-date-pickers/themeAugmentation';

export const secondaryMain = '#30B671';

const customTheme: Theme = createTheme({
  components: {
    MuiDesktopDateTimePicker: {
      defaultProps: {
        /**
         * With the migration from v5 to v6, MUI's `x-date-pickers` stopped
         * rendering clock components in desktop environments by default.
         * Specifying default view renderers as done here returns behavior. The
         * [migration guide][0] mentions the new `DigitalClock` component that
         * might be a good substitute but that needs UI review.
         *
         * [0]: https://mui.com/x/react-date-pickers/digital-clock/
         */
        viewRenderers: {
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        paddingNone: {
          // had to override this for the material-table because the library's
          // usage of this class messes up the border-radius
          padding: '4px',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: `${secondaryMain} !important`,
            '& svg': {
              color: `${secondaryMain} !important`,
            },
          },
          color: 'white',
          '&:hover': {
            color: 'white',
            opacity: 1.1,
          },
          '& svg': {
            opacity: 1,
          },
        },
        icon: {
          color: 'white !important',
        },
        iconDirectionAsc: {
          color: 'white !important',
        },
        iconDirectionDesc: {
          color: 'white !important',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 14,
        },
      },
    },
  },
  palette: {
    common: {
      black: '#434343',
    },
    accent: {
      light: '#F4FBFA',
      main: '#F28775',
    },
    background: {
      default: grey[200],
    },
    beige: {
      main: '#FFFCF0',
    },
    blue: {
      main: '#D9E4F9',
      dark: '#1845A0',
    },
    darks: {
      main: '#343434',
      dark: '#252525',
    },
    error: {
      main: '#ff2500',
    },
    grays: {
      light: '#f1f1f1',
      main: '#e6e6e6',
      dark: '#646464',
    },
    green: {
      main: '#D0EEDF',
      dark: '#135332',
    },
    mint: {
      dark: '#47bda6',
      main: '#def3ef',
      light: '#EFF9F4',
    },
    primary: {
      main: '#053b48',
    },
    secondary: {
      main: secondaryMain,
    },
    warning: {
      main: '#FFE685',
    },
    yellow: {
      main: '#FFE9A0',
      dark: '#5F4B02',
    },
  },
  typography: {
    body1: {
      fontWeight: 'normal',
      fontSize: 16,
      lineHeight: '20px',
    },
    caption: {
      fontWeight: 'normal',
      fontSize: 14,
      lineHeight: '18px',
    },
    h1: {
      fontWeight: 'bold',
      fontSize: 32,
      lineHeight: '36px',
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: 22,
      lineHeight: '24px',
      textTransform: 'uppercase',
    },
    h3: {
      fontWeight: 'bold',
      fontSize: 20,
      lineHeight: '22px',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '24px',
    },
    h5: {
      fontWeight: 'bold',
      fontSize: 16,
      lineHeight: '20px',
    },
    h6: {
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '18px',
    },
    subtitle1: {
      fontWeight: 'bold',
      fontSize: 18,
      lineHeight: '20px',
      textTransform: 'uppercase',
    },
  },
});

export default customTheme;
