import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  IconButton,
  Link,
  Snackbar,
  Typography,
} from '@mui/material';
import { useLocalStorage } from '../../../utils/hooks/useStorage';
import { LS_PODSIE_GOOGLE_SSO_WARNING_KEY } from '../../../utils/localStorageKeys';

export function WarningBanner() {
  const [open, setOpen] = useLocalStorage(
    LS_PODSIE_GOOGLE_SSO_WARNING_KEY,
    'true'
  );
  const openBool = open === 'true';

  const dismissAlert = () => {
    setOpen('false');
  };

  return (
    <>
      <Snackbar
        open={openBool}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{
          top: 0,
        }}
      >
        <Alert
          severity="warning"
          variant="filled"
          sx={{
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography color="primary" sx={{ mr: 1 }}>
              <strong>Final Reminder: </strong>
              Upcoming Google changes will impact your students&apos; ability to
              sign into Podsie after October 23, 2023
            </Typography>
            <Link
              href="https://support.podsie.org/podsie-essentials-for-teachers/vX7KLsB77CURfTNiAWTLV4/add-podsie-as-a-trusted-app-in-google-workspace/atrGWNyMtGuYvEMfk3ptJ3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography color="primary">
                <strong>Learn More (Updated)</strong>
              </Typography>
            </Link>
            <Box sx={{ position: 'absolute', right: '16px' }}>
              <IconButton size="small" color="primary" onClick={dismissAlert}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        </Alert>
      </Snackbar>
    </>
  );
}

export default WarningBanner;
