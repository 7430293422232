import { Route, Switch, useLocation } from 'react-router-dom';
import { CoursesIndex } from './CoursesIndex';

export function Courses() {
  const location = useLocation();

  return (
    <Switch>
      <Route path="/" render={() => <CoursesIndex key={location.key} />} />
    </Switch>
  );
}
