import { Flag as FlagIcon, FlagOutlined } from '@mui/icons-material';
import {
  Alert,
  Button,
  IconButton,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import StyledDialog from '../../shared/Layout/StyledDialog';
import { ENDPOINT } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  editButton: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  actionButton: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 0.5),
    '&:first-child': {
      marginLeft: 0,
    },
  },
  message: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  dialog: {
    marginTop: theme.spacing(1),
  },
  buttons: {
    marginTop: theme.spacing(2),
    float: 'right',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

const postFlag = async (
  message: string,
  questionID: number | undefined,
  conceptID: number | undefined,
  userID: number
) => {
  const response = await fetch(`${ENDPOINT}/flags/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      question_id: questionID,
      concept_id: conceptID,
      message: message,
      user_id: userID,
      cleaned_message: message,
      suggestion: '',
    }),
  });
  const data = await response.json();
  return data;
};

type FlagProps = {
  title: string;
  children: React.ReactNode;
  questionID: number;
  conceptID: number;
  userID: number;
  filled: boolean;
  setFlagged: React.Dispatch<React.SetStateAction<boolean>>;
};

export function Flag({
  title,
  children,
  questionID,
  conceptID,
  userID,
  filled,
  setFlagged,
}: FlagProps) {
  const classes = useStyles({ draftMode: false });
  let [modalOpen, setModalOpen] = useState(false);
  let [message, setMessage] = useState('');

  let title_caps = title.charAt(0).toUpperCase() + title.slice(1);

  const [toastOpen, setToastOpen] = useState(false);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };

  return (
    <div>
      <Tooltip title={`Flag ${title_caps}`} className={classes.actionButton}>
        <IconButton
          className={classes.editButton}
          color="error"
          onClick={() => setModalOpen(true)}
        >
          {filled ? <FlagIcon color="error" /> : <FlagOutlined color="error" />}
        </IconButton>
      </Tooltip>
      <StyledDialog
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        title={`Flag ${title_caps}`}
      >
        <div className={classes.dialog}>
          <Typography variant="h3">{title_caps}:</Typography>
          {children}
          <Typography variant="h3">
            Why are you flagging this {title}?
          </Typography>
          <TextField
            placeholder={'Message'}
            multiline={true}
            minRows={4}
            className={classes.message}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMessage(event.target.value);
            }}
          />
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              color="success"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                postFlag(message, questionID, conceptID, userID);
                setModalOpen(false);
                setToastOpen(true);
                setFlagged(true);
              }}
              className={classes.button}
            >
              Flag
            </Button>
          </div>
        </div>
      </StyledDialog>
      <Snackbar open={toastOpen} autoHideDuration={5000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Flag created!
        </Alert>
      </Snackbar>
    </div>
  );
}
