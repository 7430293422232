import { useMutation } from '@apollo/client';
import { Close, Help, MoreVert } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';
import { cloneDeep } from 'lodash';
import React, { useContext } from 'react';
import { QuestionAttributesFragment } from '../../../../../gql/fragments/__generated__/question.generated';
import { UpdateQuestionDocument } from '../../../../../gql/mutations/__generated__/question.generated';
import { ShortAnswerAnswerInput } from '../../../../../gql/types';
import theme from '../../../../../theme';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { CustomTooltip } from '../../../../shared/CustomTooltip';
import { AlertsContext } from '../../../Alerts/context';
import { DeepPartial, updateQuestionFields } from '../questions';

type ShortAnswerAnswersOptionsProps = {
  shortAnswer: NonNullable<ShortAnswerAnswerInput>;
  question: QuestionAttributesFragment;
  setShortAnswerAnswers: React.Dispatch<
    React.SetStateAction<ShortAnswerAnswerInput[]>
  >;
};

export function ShortAnswerAnswersOptions({
  shortAnswer,
  question,
  setShortAnswerAnswers,
}: ShortAnswerAnswersOptionsProps) {
  const [open, setOpen] = React.useState(false);
  const handleToggle = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const { dispatch } = useContext(AlertsContext);
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });

  const updateShortAnswerProperty =
    (property: 'isCaseSensitive' | 'ignoreWhitespaces') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const index = question.shortAnswerAnswers?.findIndex(
        (answer) => answer.id === shortAnswer.id
      );

      if (index === undefined || index === -1) {
        return;
      }

      const updatedShortAnswerAnswers = cloneDeep(question.shortAnswerAnswers);
      if (!updatedShortAnswerAnswers) {
        return;
      }
      updatedShortAnswerAnswers[index] = {
        ...updatedShortAnswerAnswers[index],
        [property]: event.target.checked,
      };

      const changes: DeepPartial<QuestionAttributesFragment> = {
        shortAnswerAnswers: updatedShortAnswerAnswers,
      };

      const questionInput = updateQuestionFields(question, changes);
      updateQuestion({ variables: { question: questionInput } });
      setShortAnswerAnswers(updatedShortAnswerAnswers);
    };

  const settingsTooltip = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">Settings</Typography>
        <IconButton onClick={handleClose} size="large">
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <FormControlLabel
          label="Case Sensitive"
          control={
            <Checkbox
              checked={shortAnswer.isCaseSensitive}
              onChange={updateShortAnswerProperty('isCaseSensitive')}
              name="case-sensitive"
              color="primary"
            />
          }
        />
        <CustomTooltip
          title={
            <Box
              sx={{
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                }}
              >
                What does this do?
              </Typography>
              If checked, Podsie will take upper and lower case into
              consideration when assessing student responses. For example,{' '}
              <b>&ldquo;answer&rdquo;</b> will not match{' '}
              <b>&ldquo;Answer&rdquo;</b>, but <b>&ldquo;Answer&rdquo;</b> will
              match <b>&ldquo;Answer&rdquo;</b>.
            </Box>
          }
        >
          <Help fontSize="small" />
        </CustomTooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <FormControlLabel
          label="Ignore Whitespaces"
          control={
            <Checkbox
              checked={shortAnswer.ignoreWhitespaces}
              onChange={updateShortAnswerProperty('ignoreWhitespaces')}
              name="ignore-whitespaces"
              color="primary"
            />
          }
        />
        <CustomTooltip
          title={
            <Box sx={{ paddingBottom: theme.spacing(2) }}>
              <Typography
                variant="h4"
                sx={{
                  paddingTop: theme.spacing(1),
                  paddingBottom: theme.spacing(1),
                }}
              >
                What does this do?
              </Typography>
              If checked, Podsie will ignore whitespaces when assessing student
              responses. For example <b>&ldquo;(1 + 1)&rdquo;</b> will match{' '}
              <b>&ldquo;(1+1)&rdquo;</b>, and <b>&ldquo;answer&rdquo;</b> will
              match <b>&ldquo;ans wer&rdquo;</b>.
            </Box>
          }
        >
          <Help fontSize="small" />
        </CustomTooltip>
      </Box>
    </Box>
  );
  return (
    <CustomTooltip open={open} title={settingsTooltip()}>
      <IconButton
        onClick={handleToggle}
        size="medium"
        sx={{
          border: `1px solid ${theme.palette.grays.main}`,
          ml: 0.5,
          borderRadius: 1,
        }}
      >
        <MoreVert />
      </IconButton>
    </CustomTooltip>
  );
}
