import { Tooltip, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    width: 10,
    height: 10,
    animation: '$pulsate 2.5s ease-out',
    animationIterationCount: 'infinite',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    marginRight: theme.spacing(0.5),
  },
  '@keyframes pulsate': {
    '0%': {
      '-webkit-transform': 'scale(0.8, 0.8)',
      opacity: '0.0',
    },
    '50%': {
      opacity: '1.0',
    },
    '100%': {
      '-webkit-transform': 'scale(1.1, 1.1)',
      opacity: '0.0',
    },
  },
}));

export function LiveUpdates() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.circle}></div>
      <Tooltip title="This page continually watches for live updates, so there's no need to manually refresh to pull in new data.">
        <Typography variant="caption">Live Updates</Typography>
      </Tooltip>
    </div>
  );
}
