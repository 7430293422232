import HelpKitButton from './HelpKitButton';

export function HelpKitInviteCode() {
  return (
    <HelpKitButton
      label="How does a teacher use the Invite Code to join a Subject Group?"
      articleId="brRsNYAFDLW6LN8nVoFuTR"
    />
  );
}
export default HelpKitInviteCode;
