import HelpKitButton from './HelpKitButton';

export function HelpKitSubjectGroup() {
  return (
    <HelpKitButton
      label="What's a Collaborative Subject Group?"
      articleId="eCfV3zJ1frrSpVgV6aaEuw"
    />
  );
}
export default HelpKitSubjectGroup;
