import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import userflow from 'userflow.js';
import { TeacherLightDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../Alerts/context';

export * from './ResourceCenterButton';
export * from './hooks';

type UserflowProps = {
  /**
   * Token used to authenticate the Userflow client library with Podsie's
   * account
   *
   * @note This token will only be used once. Changing the token dynamically and
   *   having the library automatically re-authenticate is currently **not**
   *   supported.
   */
  token: string;
};

/**
 * Initialize the Userflow client and identify the logged in teacher.
 *
 * @see https://userflow.com/
 * @see https://userflow.com/docs/userflow-js#userflow-js-reference
 */
export function Userflow(props: UserflowProps) {
  const { token } = props;
  const { dispatch } = useContext(AlertsContext);

  useQuery(TeacherLightDocument, {
    onCompleted: (data) => {
      if (!data || userflow.isIdentified()) return;

      const { acceptedTosAt, createdAt, email, fullName, id } = data.teacher;

      userflow.init(token);
      userflow.identify(id, {
        accepted_tos_at: acceptedTosAt,
        email,
        name: fullName,
        signed_up_at: createdAt,
      });
    },
    onError: onError(dispatch),
    skip: userflow.isIdentified(),
  });

  return null;
}
