import { Tab, Tabs, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useEffect } from 'react';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import { QuestionsStats } from '../../components/application/StudentProgress/QuestionsStats';
import { ReviewModeModal } from '../../components/application/StudentProgress/ReviewSession';
import UpdatedStandardsMastery from '../../components/application/StudentProgress/UpdatedStandardsMastery';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    width: '100%',
    paddingTop: theme.spacing(3),
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  content: {
    backgroundColor: theme.palette.common.white,
    flex: '1 1 auto',
  },
}));

export function StandardsIndex() {
  const classes = useStyles();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const { updateTitle } = useContext(HeaderTitleContext);
  useEffect(() => updateTitle('Student Progress'), [updateTitle]);
  const closeReviewModeModal = () => history.push('/progress/questions');
  // for these tabs, the questions progress dashboard has a route with params
  // which causes for that route to not match with the tab value when it's
  // selected. So, let's chop off everything except for the first 2 parts of the pathname
  // to use as Tabs value:
  const rootPath = location.pathname.split('/').slice(1, 3).join('/');

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        value={`/${rootPath}`}
        variant="scrollable"
        textColor="primary"
        indicatorColor="secondary"
      >
        <Tab
          component={Link}
          to={`${match.url}/questions`}
          label="QUESTIONS"
          value={`${match.url}/questions`}
        />
        <Tab
          component={Link}
          to={`${match.url}/standards`}
          label="STANDARDS"
          value={`${match.url}/standards`}
        />
      </Tabs>
      <div className={classes.content}>
        <Switch>
          <Route path={`${match.url}/standards`}>
            <UpdatedStandardsMastery />
          </Route>
          <Route exact path={`${match.url}/questions/review`}>
            <ReviewModeModal onClose={closeReviewModeModal} />
          </Route>
          <Route path={`${match.url}/questions/:selectedQuestionId?`}>
            <QuestionsStats />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
