import { useMutation, useQuery } from '@apollo/client';
import { Info } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { parse } from 'date-fns';
import { produce } from 'immer';
import React, { useContext, useState } from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { AlertsContext } from '../../components/application/Alerts/context';
import { pushSnack } from '../../components/application/Alerts/context/actions';
import {
  CreateScheduledFreezeDocument,
  UpdateScheduledFreezeDocument,
} from '../../gql/mutations/__generated__/scheduledFreeze.generated';
import {
  ScheduledFreezesDocument,
  type ScheduledFreezesQuery,
} from '../../gql/queries/__generated__/scheduledFreeze.generated';
import { TeacherDocument } from '../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../utils/apollo/apolloHelper';
import { postgresDateFormatString } from '../../utils/dates';
import { sortGroups } from '../../utils/lists';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 500,
    margin: '0 auto',
  },
  columns: {
    display: 'flex',
    flexDirection: 'column',
  },
  nameField: {
    marginBottom: theme.spacing(2),
  },
  column: {},
  selectAllButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.common.white,
  },
  label: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  submitButtonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  errorsContainer: {
    marginTop: theme.spacing(1),
    minHeight: 20,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  infoIcon: {
    marginLeft: theme.spacing(0.5),
    fill: theme.palette.secondary.main,
    width: 20,
  },
}));
const today = new Date();
type FreezeFormProps = {
  editFreeze?: ScheduledFreezesQuery['scheduledFreezes'][0];
};
export function FreezeForm({ editFreeze }: FreezeFormProps) {
  const creating = !editFreeze;
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data: teacherData } = useQuery(TeacherDocument);
  const courseId = teacherData?.teacher.activeCourse?.id || '';
  const groups = sortGroups(teacherData?.teacher.activeCourse?.groups) || [];
  const [name, setName] = useState(editFreeze?.name || '');
  const [start, setStart] = useState<Date | undefined>(
    editFreeze?.start
      ? parse(editFreeze.start, postgresDateFormatString, new Date())
      : undefined
  );
  const [finish, setFinish] = useState<Date | undefined>(
    editFreeze?.finish
      ? parse(editFreeze.finish, postgresDateFormatString, new Date())
      : undefined
  );
  const [groupIds, setGroupIds] = useState<Set<string>>(
    editFreeze ? new Set(editFreeze.groups.map((g) => g.id)) : new Set()
  );
  const [errors, setErrors] = useState(false);
  const [updateScheduledFreeze] = useMutation(UpdateScheduledFreezeDocument, {
    onError: onError(dispatch),
    update: (cache, res) => {
      if (!courseId) {
        return;
      }
      const queryResult = cache.readQuery({
        query: ScheduledFreezesDocument,
        variables: { courseId },
      });

      if (!res.data || !queryResult) {
        return;
      }

      const updated = produce(queryResult.scheduledFreezes, (draft) => {
        if (!res.data?.updateScheduledFreeze) {
          return;
        }
        const editIndex = draft.findIndex(
          (freeze) => freeze.id === editFreeze?.id
        );
        draft[editIndex] = res.data.updateScheduledFreeze;
      });
      cache.writeQuery({
        data: { scheduledFreezes: updated },
        query: ScheduledFreezesDocument,
        variables: { courseId },
      });
      dispatch(
        pushSnack({
          message: 'Scheduled Freeze updated!',
        })
      );
    },
  });
  const [createScheduledFreeze] = useMutation(CreateScheduledFreezeDocument, {
    onError: onError(dispatch),
    update: (cache, res) => {
      if (!courseId) return;

      const queryResult = cache.readQuery({
        query: ScheduledFreezesDocument,
        variables: { courseId },
      });
      if (!res.data || !queryResult) return;

      const updated = produce(queryResult.scheduledFreezes, (draft) => {
        if (res.data?.createScheduledFreeze) {
          draft.push(res.data?.createScheduledFreeze);
        }
        draft.sort((a, b) => {
          return new Date(a.start).getTime() - new Date(b.start).getTime();
        });
      });

      cache.writeQuery({
        data: { scheduledFreezes: updated },
        query: ScheduledFreezesDocument,
        variables: { courseId },
      });
      dispatch(
        pushSnack({
          message: 'Scheduled Freeze created!',
        })
      );
      setName('');
      setStart(undefined);
      setFinish(undefined);
      setGroupIds(new Set());
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors(false);
    if (!courseId || !name || !start || !finish || !groupIds.size) {
      setErrors(true);
      return;
    }
    const groupIdsArr = Array.from(groupIds);
    if (creating) {
      createScheduledFreeze({
        variables: {
          courseId,
          name,
          start,
          finish,
          groupIds: groupIdsArr,
        },
      });
    } else if (editFreeze) {
      updateScheduledFreeze({
        variables: {
          scheduledFreezeId: editFreeze.id,
          name,
          start,
          finish,
          groupIds: groupIdsArr,
        },
      });
    }
  };
  const handleCheckboxChange = (groupId: string) => () => {
    const updated = new Set(groupIds);
    if (groupIds.has(groupId)) {
      updated.delete(groupId);
    } else {
      updated.add(groupId);
    }
    setGroupIds(updated);
  };

  const selectAllClasses = () => {
    const updated = new Set(groups.map((g) => g.id));
    setGroupIds(updated);
  };

  const handleDateChanges = (ranges: RangeKeyDict) => {
    // {
    //   selection: {
    //     startDate: [native Date Object],
    //     endDate: [native Date Object],
    //   }
    // }
    const { startDate, endDate } = ranges.selection;
    setStart(startDate);
    setFinish(endDate);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const selectionRange = {
    startDate: start,
    endDate: finish,
    key: 'selection',
  };

  const renderErrors = () => {
    if (!errors) {
      return null;
    }

    return (
      <div className={classes.errorsContainer}>
        {!name && <Typography color="error">Name is required.</Typography>}
        {!start && (
          <Typography color="error">Valid start date is required.</Typography>
        )}
        {!finish && (
          <Typography color="error">Valid finish date is required.</Typography>
        )}
        {!groupIds.size && (
          <Typography color="error">
            At least one class must be selected.
          </Typography>
        )}
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <div className={classes.columns}>
          <div className={classes.column}>
            <Typography variant="h6" className={classes.label}>
              Name
            </Typography>
            <TextField
              onChange={handleNameChange}
              value={name}
              variant="outlined"
              fullWidth
              className={classes.nameField}
              placeholder="(eg. Thanksgiving Break)"
            />
            <div>
              <div className={classes.labelContainer}>
                <Typography variant="h6" className={classes.label}>
                  Freeze Dates
                </Typography>
                <Tooltip title="Personal Decks will be automatically frozen on the 'Start' date. They will then automatically be unfrozen one day AFTER the 'End' date">
                  <Info className={classes.infoIcon} />
                </Tooltip>
              </div>
              <DateRange
                rangeColors={['#30B671', '#30B671', '#30B671']}
                startDatePlaceholder="Start Freeze"
                endDatePlaceholder="End Freeze"
                minDate={today}
                ranges={[selectionRange]}
                onChange={handleDateChanges}
              />
            </div>
          </div>
          <div className={classes.column}>
            <Typography variant="h6" className={classes.label}>
              Select Classes
            </Typography>
            <Button
              variant="contained"
              className={classes.selectAllButton}
              color="secondary"
              onClick={selectAllClasses}
            >
              Select All
            </Button>
            {groups.map((group) => {
              return (
                <div key={group.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={handleCheckboxChange(group.id)}
                        checked={!!groupIds.has(group.id)}
                        name={group.name}
                      />
                    }
                    label={group.name}
                  />
                </div>
              );
            })}
          </div>
        </div>

        {renderErrors()}
        <div className={classes.submitButtonContainer}>
          <Button color="primary" variant="contained" type="submit">
            {`${creating ? 'Schedule' : 'Update'} Freeze`}
          </Button>
        </div>
      </form>
    </div>
  );
}
export default FreezeForm;
