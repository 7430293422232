import { produce } from 'immer';
import { AssignmentEditorAction, AssignmentEditorActionTypes } from './actions';
import { initialState } from './state';

const reducer = (assignment = initialState, action: AssignmentEditorAction) => {
  switch (action.type) {
    case AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_NAME: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.name = action.payload.name;
        assignmentDraft.ui.saved = false;
      });
    }
    case AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_SHAREABLE: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.shareable = action.payload.shareable;
        assignmentDraft.ui.saved = false;
      });
    }
    case AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_OPTIONAL: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.optional = action.payload.optional;
        assignmentDraft.ui.saved = false;
      });
    }
    case AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_LAUNCH_AT: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.launchAt = action.payload.launchAt;
        assignmentDraft.ui.saved = false;
      });
    }
    case AssignmentEditorActionTypes.START_LOADING: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.ui.loading = true;
      });
    }
    case AssignmentEditorActionTypes.CLEAR_SAVE_UI: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.ui.saved = undefined;
      });
    }
    case AssignmentEditorActionTypes.STOP_LOADING: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.ui.loading = false;
        assignmentDraft.ui.saved = true;
      });
    }
    case AssignmentEditorActionTypes.CREATE_GROUPS_ASSIGNMENT: {
      return produce(assignment, (assignmentDraft) => {
        const { groupsAssignmentId, groupId, dueDate, launchDate } =
          action.payload;
        assignmentDraft.groupsAssignments[groupId] = {
          id: groupsAssignmentId,
          dueDate,
          launchDate,
        };
      });
    }
    case AssignmentEditorActionTypes.DELETE_GROUPS_ASSIGNMENT: {
      return produce(assignment, (assignmentDraft) => {
        const { groupId } = action.payload;
        delete assignmentDraft.groupsAssignments[groupId];
      });
    }
    case AssignmentEditorActionTypes.UPDATE_GROUPS_ASSIGNMENT: {
      return produce(assignment, (assignmentDraft) => {
        const { groupId, id, dueDate, launchDate } = action.payload;
        if (id) {
          assignmentDraft.groupsAssignments[groupId].id = id;
        }
        if (dueDate) {
          assignmentDraft.groupsAssignments[groupId].dueDate = dueDate;
        }
        if (launchDate) {
          assignmentDraft.groupsAssignments[groupId].launchDate = launchDate;
        }
      });
    }
    case AssignmentEditorActionTypes.UPDATE_SPIRALED_STANDARDS: {
      return produce(assignment, (assignmentDraft) => {
        const { standards } = action.payload;
        assignmentDraft.standards = standards;
      });
    }
    case AssignmentEditorActionTypes.UPDATE_NUM_SPIRALED_STANDARDS: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.numSpiraledQuestions =
          action.payload.numSpiraledQuestions;
      });
    }
    case AssignmentEditorActionTypes.UPDATE_TARGET_DISTRIBUTED_POINTS: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.targetDistributedPoints =
          action.payload.targetDistributedPoints;
      });
    }
    case AssignmentEditorActionTypes.UPDATE_ASSIGNMENT_METHOD: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.assignmentMethod = action.payload.method;
      });
    }
    case AssignmentEditorActionTypes.CREATE_ENROLLMENTS_ASSIGNMENT: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.enrollmentsAssignments[action.payload.enrollmentId] = {
          id: action.payload.enrollmentsAssignmentId,
          dueDate: action.payload.dueDate,
          enrollmentId: action.payload.enrollmentId,
          studentName: action.payload.studentName,
          studentEmail: action.payload.studentEmail,
          class: action.payload.class,
        };
      });
    }
    case AssignmentEditorActionTypes.UPDATE_ENROLLMENTS_ASSIGNMENT: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.enrollmentsAssignments[
          action.payload.enrollmentId
        ].dueDate = action.payload.dueDate;
      });
    }
    case AssignmentEditorActionTypes.DELETE_ENROLLMENTS_ASSIGNMENT: {
      return produce(assignment, (assignmentDraft) => {
        delete assignmentDraft.enrollmentsAssignments[
          action.payload.enrollmentId
        ];
      });
    }
    case AssignmentEditorActionTypes.ADD_ASSIGNMENT_QUESTION: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.questions = [
          ...assignmentDraft.questions,
          action.payload.question,
        ];
      });
    }
    case AssignmentEditorActionTypes.REMOVE_ASSIGNMENT_QUESTION: {
      return produce(assignment, (assignmentDraft) => {
        assignmentDraft.questions = assignmentDraft.questions.filter(
          (question) => {
            return (
              question.id.toString() !== action.payload.questionId.toString()
            );
          }
        );
      });
    }
    default:
      return assignment;
  }
};

export default reducer;
