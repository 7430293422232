import { useMutation } from '@apollo/client';
import { type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import {
  UpdateQuestionDocument,
  type UpdateQuestionMutationVariables,
} from '../../../../gql/mutations/__generated__/question.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';
import Form from './Form';
import { QuestionFormProvider } from './context';
import { extractQuestionFormState } from './context/util';

const useStyles = makeStyles((theme: Theme) => ({
  questionCard: {
    width: '100%',
    padding: `${theme.spacing(1)} 0`,
    margin: `${theme.spacing(1)} 0`,
  },
  questionPlainText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    padding: theme.spacing(1),
  },
  icon: {
    color: theme.palette.mint.dark,
    marginRight: theme.spacing(1),
    paddingTop: 4,
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.secondary.main,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandButton: {},
  questionCardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(2)}`,
  },
  questionFormContainer: {
    paddingLeft: theme.spacing(2),
  },
  deleteQuestionButton: {
    backgroundColor: theme.palette.error.dark,
    '&:hover': {
      backgroundColor: '#7a1200',
    },
    color: theme.palette.common.white,
  },
}));

type UpdateQuestionCardProps = {
  question: QuestionAttributesFragment;
  parentQuestion?: boolean;
  postUpdateCallback?: () => void;
  triggerUpdateFormSubmit?: boolean;
  setTriggerUpdateFormSubmit:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  hideSubmitButton?: boolean;
};

export function UpdateQuestionCard({
  question,
  parentQuestion,
  postUpdateCallback,
  triggerUpdateFormSubmit,
  setTriggerUpdateFormSubmit,
  hideSubmitButton,
}: UpdateQuestionCardProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);

  const [updateQuestion, { loading }] = useMutation(UpdateQuestionDocument, {
    onCompleted: () => {
      dispatch(pushSnack({ message: 'Question updated!' }));
      postUpdateCallback?.();
    },
    onError: onError(dispatch),
  });

  const handleSubmit = (
    question: UpdateQuestionMutationVariables
  ): Promise<
    ({ __typename?: 'Question' } & QuestionAttributesFragment) | void
  > => {
    if (setTriggerUpdateFormSubmit) {
      setTriggerUpdateFormSubmit(false);
    }

    return new Promise<QuestionAttributesFragment | void>((resolve) => {
      updateQuestion({ variables: question }).then((data) => {
        resolve(data?.data?.updateQuestion);
      });
    });
  };

  const { richText } = question;
  const preloadedState = extractQuestionFormState(question);

  return (
    <div className={classes.questionCard}>
      <div className={classes.questionCardHeader}></div>

      <div className={classes.questionFormContainer}>
        <QuestionFormProvider preloadedState={preloadedState}>
          <>
            <Form
              triggerSubmit={triggerUpdateFormSubmit}
              hideStandards={!parentQuestion}
              hideSubmitButton={hideSubmitButton}
              existingQuestion={richText}
              submitLoading={loading}
              handleSubmit={handleSubmit}
            />
          </>
        </QuestionFormProvider>
      </div>
    </div>
  );
}
