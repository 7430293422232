import { useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {},
  desktop: {
    width: '100%',
  },
  mobile: {
    width: '100%',
  },
}));

export function NotFound() {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const alt =
    'The pages you were looking for could not be found. Use your browser window to return to the previous page.';
  return (
    <div className={classes.root}>
      {matches ? (
        <img
          alt={alt}
          src="/images/not_found_desktop.svg"
          className={classes.desktop}
        />
      ) : (
        <img
          alt={alt}
          src="/images/not_found_mobile.svg"
          className={classes.mobile}
        />
      )}
    </div>
  );
}
