import { useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import React, { useContext, useState } from 'react';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import { AcceptPoliciesDocument } from '../../../../gql/mutations/__generated__/teacher.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { AlertsContext } from '../../Alerts/context';
import { openDialog } from '../../Alerts/context/actions';
import PolicyModal from './PolicyModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  buttonLink: {
    ...buttonLink(theme),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 16,
  },
  welcomeContainer: {
    padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
    textAlign: 'center',
  },
  welcomeText: {
    marginBottom: theme.spacing(2),
    fontSize: 24,
  },
  getStartedButton: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  labelText: {
    fontSize: 16,
  },
  checkBoxesContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'column',
    maxWidth: 1000,
  },
  loadingContainer: {
    marginTop: theme.spacing(2),
    width: 100,
  },
  acknowledgement: {
    marginLeft: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
}));

export type OpenedContent = 'privacy' | 'tos' | null;

export function Welcome() {
  const [openedContent, setOpenedContent] = useState<OpenedContent>(null);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [acknowledgementAccepted, setAcknowledgementAccepted] = useState(false);
  const classes = useStyles();
  const handlePrivacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacyAccepted(event.target.checked);
  };
  const { dispatch } = useContext(AlertsContext);
  const [acceptPolicies, { loading }] = useMutation(AcceptPoliciesDocument, {
    update: (cache, res) => {
      const cachedTeacher = cache.readQuery({
        query: TeacherDocument,
      });

      const updatedTeacher = produce(cachedTeacher?.teacher, (teacherDraft) => {
        if (teacherDraft && res.data?.acceptPolicies) {
          teacherDraft.acceptedPrivacyAt =
            res.data.acceptPolicies.acceptedPrivacyAt;
          teacherDraft.acceptedTosAt = res.data.acceptPolicies.acceptedTosAt;
        }
      });

      if (!updatedTeacher) {
        return;
      }

      cache.writeQuery({
        query: TeacherDocument,
        data: {
          teacher: updatedTeacher,
        },
      });
    },
  });

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const handleAcknowledgementChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAcknowledgementAccepted(event.target.checked);
  };

  const openContent = (contentType: OpenedContent) => {
    return () => {
      setOpenedContent(contentType);
    };
  };
  const closeContent = () => setOpenedContent(null);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!privacyAccepted || !termsAccepted || !acknowledgementAccepted) {
      dispatch(
        openDialog({
          maxWidth: 'md',
          title: 'Please check all required fields',
          message:
            'You must agree to Privacy Policy, Terms of Service, and Acknowledgement in order to use Podsie.',
        })
      );

      return;
    }
    acceptPolicies();
  };

  return (
    <div className={classes.welcomeContainer}>
      <Typography className={classes.welcomeText} variant="h4" color="primary">
        Welcome to Podsie! This is the teacher side of Podsie. If you are a
        student, please go to{' '}
        <a href="https://student.podsie.org" className={classes.link}>
          student.podsie.org
        </a>
        !
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.checkBoxesContainer}>
          <div>
            <Checkbox
              checked={privacyAccepted}
              onChange={handlePrivacyChange}
              name="Privacy Policy"
              color="primary"
            />
            <span className={classes.labelText}>
              I agree to Podsie&apos;s{' '}
              <button
                type="button"
                className={classes.buttonLink}
                onClick={openContent('privacy')}
              >
                Privacy Policy
              </button>
            </span>
          </div>
          <div>
            <Checkbox
              checked={termsAccepted}
              onChange={handleTermsChange}
              name="Privacy Policy"
              color="primary"
            />
            <span className={classes.labelText}>
              I agree to Podsie&apos;s{' '}
              <button
                type="button"
                className={classes.buttonLink}
                onClick={openContent('tos')}
              >
                Terms of Service
              </button>
            </span>
          </div>
          <FormControlLabel
            className={classes.acknowledgement}
            control={
              <Checkbox
                checked={acknowledgementAccepted}
                onChange={handleAcknowledgementChange}
                name="Acknowledgement"
                color="primary"
              />
            }
            label="
              Podsie is currently intended for
              middle and high school classrooms in the United States. I acknowledge and understand 
              that Podsie will prioritize feature requests and technical support for users within this target group."
          />
        </div>

        {loading ? (
          <div className={classes.loadingContainer}>
            <LinearProgress />
          </div>
        ) : (
          <Button
            className={classes.getStartedButton}
            variant="contained"
            type="submit"
            color="secondary"
            disabled={
              loading ||
              !acknowledgementAccepted ||
              !termsAccepted ||
              !privacyAccepted
            }
          >
            Confirm
          </Button>
        )}
      </form>
      <PolicyModal content={openedContent} handleClose={closeContent} />
    </div>
  );
}
export default Welcome;
