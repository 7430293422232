import { Typography, useMediaQuery, useTheme, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useMemo } from 'react';
import type { StudentsAssignmentsQuestionsQuery } from '../../../../../../gql/queries/__generated__/studentsAssignmentsQuestion.generated';
import type { StudentsAssignmentsQuestion } from '../../../../../../gql/types';
import {
  countTotalAttempts,
  questionComplete,
} from '../../../../../../utils/question';
import { renderQuestionType } from '../../../../../../utils/renderQuestionType';
import { CustomChip } from '../../../../../shared/CustomChip';
import { QuillDeltaAsHtml } from '../../../../../shared/QuillDeltaAsHtml';
import type {
  PersonalDeckQuestionsResult,
  PersonalDeckQuestionsResults,
} from '../../../../PersonalDeck/PersonalDeckDailyData';
import { Answer } from './Answer';
import { Attempts } from './Attempts';
import { TopRepeatedQuestions } from './TopRepeatedQuestions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '20%',
    display: 'flex',
  },
  questionResultContainer: {},
  questionInfoContainer: {
    padding: `0 ${theme.spacing(2)}`,
  },
  odd: {
    backgroundColor: theme.palette.common.white,
  },
  even: {
    backgroundColor: theme.palette.grays.light,
  },
  chipContainer: {
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  questionType: {
    marginBottom: theme.spacing(0.5),
  },
  questionContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    borderBottom: `dotted .5px ${theme.palette.primary.main}`,
  },
  richText: {
    backgroundColor: 'transparent',
  },
  leftSection: {
    width: '40%',
  },
  rightSection: {
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    width: '100%',
  },
  splitRight: {
    width: '60%',
  },
  noData: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
}));

type AssignmentQuestionsResultProps = {
  multidayFilter?: boolean;
  studentsAssignmentQuestionsQuery:
    | StudentsAssignmentsQuestionsQuery
    | PersonalDeckQuestionsResults;
};

export function AssignmentQuestionsResult({
  multidayFilter,
  studentsAssignmentQuestionsQuery,
}: AssignmentQuestionsResultProps) {
  const classes = useStyles();

  const mostRepeated = useMemo(() => {
    return [...studentsAssignmentQuestionsQuery.studentsAssignmentsQuestions]
      .filter(
        ({
          freeResponseResponses,
          multipleChoiceResponses,
          shortAnswerResponses,
        }) => {
          const responses = {
            freeResponseResponses,
            multipleChoiceResponses,
            shortAnswerResponses,
          };
          const numResponses = countTotalAttempts(responses);
          const complete = questionComplete(responses);
          return numResponses > 1 || (numResponses === 1 && !complete);
        }
      )
      .sort((a, b) => {
        if (!a || !b) {
          return 0;
        }

        const numResponsesA = countTotalAttempts({
          freeResponseResponses: a.freeResponseResponses,
          multipleChoiceResponses: a.multipleChoiceResponses,
          shortAnswerResponses: a.shortAnswerResponses,
        });

        const numResponsesB = countTotalAttempts({
          freeResponseResponses: b.freeResponseResponses,
          multipleChoiceResponses: b.multipleChoiceResponses,
          shortAnswerResponses: b.shortAnswerResponses,
        });

        return numResponsesB - numResponsesA;
      })
      .slice(0, 3);
  }, [studentsAssignmentQuestionsQuery]);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const split = mostRepeated.length > 0 && matches;

  return (
    <div className={classes.root}>
      {split && (
        <div className={classes.leftSection}>
          <TopRepeatedQuestions mostRepeated={mostRepeated} />
        </div>
      )}
      <div
        className={clsx(classes.rightSection, {
          [classes.splitRight]: split,
        })}
      >
        {studentsAssignmentQuestionsQuery.studentsAssignmentsQuestions
          .length === 0 && (
          <div>
            <Typography variant="h6" color="primary" className={classes.noData}>
              No Questions Data
            </Typography>
          </div>
        )}
        {(
          studentsAssignmentQuestionsQuery.studentsAssignmentsQuestions as Array<
            PersonalDeckQuestionsResult | StudentsAssignmentsQuestion
          >
        ).map((assignmentQuestion, i) => {
          const {
            question,
            freeResponseResponses,
            shortAnswerResponses,
            multipleChoiceResponses,
          } = assignmentQuestion;
          return (
            <div
              id={`assignment-questions-result-${question.id}`}
              key={`assignmentQuestion-${question.id}`}
              className={clsx(classes.questionResultContainer, {
                [classes.odd]: i % 2 === 1,
                [classes.even]: i % 2 === 0,
              })}
            >
              <div className={classes.questionInfoContainer}>
                <div className={classes.questionContainer}>
                  <QuillDeltaAsHtml
                    className={classes.richText}
                    delta={question.richText.ops}
                  />
                </div>
                <div>
                  <span className={classes.chipContainer}>
                    <CustomChip
                      className={classes.questionType}
                      label={renderQuestionType(question.questionType)}
                    />
                  </span>
                  {question.standards?.map(({ title, description }) => {
                    return (
                      <span
                        key={`${title}-standards-chip`}
                        className={classes.chipContainer}
                      >
                        <CustomChip label={title} tooltip={description} />
                      </span>
                    );
                  })}
                </div>
                <Answer question={question} />
              </div>
              <Attempts
                multidayFilter={multidayFilter}
                question={question}
                freeResponseResponses={freeResponseResponses}
                shortAnswerResponses={shortAnswerResponses}
                multipleChoiceResponses={multipleChoiceResponses}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
