import type { Value } from 'react-quill';
import type { QuestionAttributesFragment } from '../../../../../gql/fragments/__generated__/question.generated';
import type { StandardsQuery } from '../../../../../gql/queries/__generated__/standard.generated';
import type { QuestionTypeEnum } from '../../../../../gql/types';
import type { RichText } from '../../../../../utils/question';

export enum QuestionFormActionTypes {
  RESET_QUESTION_FORM = 'RESET_QUESTION_FORM',
  UPDATE_QUESTION_TYPE = 'UPDATE_QUESTION_TYPE',
  UPDATE_STANDARD = 'UPDATE_STANDARD',
  UPDATE_FREE_RESPONSE_ANSWER = 'UPDATE_FREE_RESPONSE_ANSWER',
  UPDATE_SHORT_ANSWER_ANSWER = 'UPDATE_SHORT_ANSWER_ANSWER',
  DELETE_SHORT_ANSWER_ANSWER = 'DELETE_SHORT_ANSWER_ANSWER',
  DELETE_MULTIPLE_CHOICE = 'DELETE_MULTIPLE_CHOICE',
  ADD_MULTIPLE_CHOICE = 'ADD_MULTIPLE_CHOICE',
  ADD_SHORT_ANSWER_ANSWER = 'ADD_SHORT_ANSWER_ANSWER',
  UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_TEXT = 'UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_TEXT',
  UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_IS_CORRECT = 'UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_IS_CORRECT',
  UPDATE_QUESTION = 'UPDATE_QUESTION',
  UPDATE_NUM_ATTEMPTS = 'UPDATE_NUM_ATTEMPTS',
  UPDATE_SUPPLEMENT = 'UPDATE_SUPPLEMENT',
  ADD_REQUIRED_KEYWORD = 'ADD_REQUIRED_KEYWORD',
  UPDATE_REQUIRED_KEYWORD = 'UPDATE_REQUIRED_KEYWORD',
  DELETE_REQUIRED_KEYWORD = 'DELETE_REQUIRED_KEYWORD',
}

export type UpdateSupplementPayload = {
  supplement: Value;
};

export const updateSupplement = (
  payload: UpdateSupplementPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_SUPPLEMENT,
  payload,
});

export type UpdateQuestionPayload = {
  question: Value;
};

export const updateQuestion = (
  payload: UpdateQuestionPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_QUESTION,
  payload,
});

export type ResetQuestionFormPayload = {
  question?: { __typename?: 'Question' } & QuestionAttributesFragment;
};

export const resetQuestionForm = (
  payload: ResetQuestionFormPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.RESET_QUESTION_FORM,
  payload,
});

export type UpdateQuestionTypePayload = {
  value: QuestionTypeEnum;
};

export const updateQuestionType = (
  payload: UpdateQuestionTypePayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_QUESTION_TYPE,
  payload,
});

export type UpdateNumAttemptsPayload = {
  value: number | string;
};

export const updateNumAttempts = (
  payload: UpdateNumAttemptsPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_NUM_ATTEMPTS,
  payload,
});

export type UpdateStandardPayload = {
  value: StandardsQuery['standards'][0][];
};

export const updateStandard = (
  payload: UpdateStandardPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_STANDARD,
  payload,
});

export type UpdateFreeResponseAnswerPayload = {
  value: Value;
  comparable: boolean;
};

export const updateFreeResponseAnswer = (
  payload: UpdateFreeResponseAnswerPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_FREE_RESPONSE_ANSWER,
  payload,
});

export const addRequiredKeyword = (): QuestionFormAction => ({
  type: QuestionFormActionTypes.ADD_REQUIRED_KEYWORD,
});

export type UpdateRequiredKeywordPayload = {
  index: number;
  text: string;
};

export const updateRequiredKeyword = (
  payload: UpdateRequiredKeywordPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_REQUIRED_KEYWORD,
  payload,
});

export type DeleteRequiredKeywordPayload = {
  index: number;
};

export const deleteRequiredKeyword = (
  payload: DeleteRequiredKeywordPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.DELETE_REQUIRED_KEYWORD,
  payload,
});

export type UpdateShortAnswerAnswerPayload = {
  text?: string;
  richText?: RichText;
  isCaseSensitive?: boolean;
  shortAnswerIndex: number;
  settingsOpen?: boolean;
  ignoreWhitespaces?: boolean;
};

export const updateShortAnswerAnswer = (
  payload: UpdateShortAnswerAnswerPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_SHORT_ANSWER_ANSWER,
  payload,
});

type DeleteShortAnswerAnswerPayload = {
  shortAnswerIndex: number;
};

export const deleteShortAnswerAnswer = (
  payload: DeleteShortAnswerAnswerPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.DELETE_SHORT_ANSWER_ANSWER,
  payload,
});

type AddShortAnswerAnswerPayload = {
  shortAnswerDelta: Value;
};

export const addShortAnswerAnswer = (
  payload: AddShortAnswerAnswerPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.ADD_SHORT_ANSWER_ANSWER,
  payload,
});

export type UpdateMultipleChoiceAnswerChoiceTextPayload = {
  text: Value;
  multipleChoiceIndex: number;
};

type DeleteMultipleChoicePayload = {
  multipleChoiceIndex: number;
};

export const addMultipleChoice = (): QuestionFormAction => ({
  type: QuestionFormActionTypes.ADD_MULTIPLE_CHOICE,
});

export const deleteMultipleChoice = (
  payload: DeleteMultipleChoicePayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.DELETE_MULTIPLE_CHOICE,
  payload,
});

export const updateMultipleChoiceAnswerChoiceText = (
  payload: UpdateMultipleChoiceAnswerChoiceTextPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_TEXT,
  payload,
});

export type UpdateMultipleChoiceAnswerChoiceIsCorrectPayload = {
  isCorrect: boolean;
  multipleChoiceIndex: number;
};

export const updateMultipleChoiceAnswerChoiceIsCorrect = (
  payload: UpdateMultipleChoiceAnswerChoiceIsCorrectPayload
): QuestionFormAction => ({
  type: QuestionFormActionTypes.UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_IS_CORRECT,
  payload,
});

export type QuestionFormAction =
  | {
      type: QuestionFormActionTypes.UPDATE_QUESTION_TYPE;
      payload: UpdateQuestionTypePayload;
    }
  | {
      type: QuestionFormActionTypes.UPDATE_STANDARD;
      payload: UpdateStandardPayload;
    }
  | {
      type: QuestionFormActionTypes.UPDATE_FREE_RESPONSE_ANSWER;
      payload: UpdateFreeResponseAnswerPayload;
    }
  | {
      type: QuestionFormActionTypes.UPDATE_SHORT_ANSWER_ANSWER;
      payload: UpdateShortAnswerAnswerPayload;
    }
  | {
      type: QuestionFormActionTypes.DELETE_SHORT_ANSWER_ANSWER;
      payload: DeleteShortAnswerAnswerPayload;
    }
  | {
      type: QuestionFormActionTypes.ADD_SHORT_ANSWER_ANSWER;
      payload: AddShortAnswerAnswerPayload;
    }
  | {
      type: QuestionFormActionTypes.UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_TEXT;
      payload: UpdateMultipleChoiceAnswerChoiceTextPayload;
    }
  | {
      type: QuestionFormActionTypes.UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_IS_CORRECT;
      payload: UpdateMultipleChoiceAnswerChoiceIsCorrectPayload;
    }
  | {
      type: QuestionFormActionTypes.RESET_QUESTION_FORM;
      payload: ResetQuestionFormPayload;
    }
  | {
      type: QuestionFormActionTypes.DELETE_MULTIPLE_CHOICE;
      payload: DeleteMultipleChoicePayload;
    }
  | { type: QuestionFormActionTypes.ADD_MULTIPLE_CHOICE }
  | {
      type: QuestionFormActionTypes.UPDATE_QUESTION;
      payload: UpdateQuestionPayload;
    }
  | {
      type: QuestionFormActionTypes.UPDATE_SUPPLEMENT;
      payload: UpdateSupplementPayload;
    }
  | {
      type: QuestionFormActionTypes.UPDATE_NUM_ATTEMPTS;
      payload: UpdateNumAttemptsPayload;
    }
  | {
      type: QuestionFormActionTypes.ADD_REQUIRED_KEYWORD;
    }
  | {
      type: QuestionFormActionTypes.UPDATE_REQUIRED_KEYWORD;
      payload: UpdateRequiredKeywordPayload;
    }
  | {
      type: QuestionFormActionTypes.DELETE_REQUIRED_KEYWORD;
      payload: DeleteRequiredKeywordPayload;
    };
