import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import {
  UpdateQuestionDocument,
  type UpdateQuestionMutationVariables,
} from '../../../../gql/mutations/__generated__/question.generated';
import type { AssignmentForCurriculumReviewQuery } from '../../../../gql/queries/__generated__/assignment.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';
import Form from '../../Questions/Form/Form';
import { QuestionFormProvider } from '../../Questions/Form/context';
import { extractQuestionFormState } from '../../Questions/Form/context/util';

type CurriculumQuestionFormProps = {
  question: AssignmentForCurriculumReviewQuery['assignment']['curriculumQuestions'][0];
};

export function CurriculumQuestionForm({
  question,
}: CurriculumQuestionFormProps) {
  const { dispatch } = useContext(AlertsContext);
  const [updateQuestion, { loading }] = useMutation(UpdateQuestionDocument, {
    onCompleted: () => dispatch(pushSnack({ message: 'Question updated!' })),
    onError: onError(dispatch),
  });

  const handleSubmit = (
    question: UpdateQuestionMutationVariables
  ): Promise<
    ({ __typename?: 'Question' } & QuestionAttributesFragment) | void
  > => {
    return new Promise<QuestionAttributesFragment | void>((resolve) => {
      updateQuestion({ variables: question }).then((data) => {
        resolve(data?.data?.updateQuestion);
      });
    });
  };
  const preloadedState = extractQuestionFormState(question);
  return (
    <QuestionFormProvider preloadedState={preloadedState}>
      <Form
        handleSubmit={handleSubmit}
        submitLoading={loading}
        existingQuestion={question.richText}
      />
    </QuestionFormProvider>
  );
}

export default CurriculumQuestionForm;
