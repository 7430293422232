import {
  LibraryAddCheck,
  RadioButtonChecked,
  ShortText,
  ViewHeadline,
} from '@mui/icons-material';
import type { ReactNode } from 'react';
import { QuestionTypeEnum } from '../gql/types';

export const questionTypeIcons: Record<QuestionTypeEnum, ReactNode> = {
  [QuestionTypeEnum.FreeResponse]: <ViewHeadline />,
  [QuestionTypeEnum.ShortAnswer]: <ShortText />,
  [QuestionTypeEnum.SelectOneMultipleChoice]: <RadioButtonChecked />,
  [QuestionTypeEnum.SelectAllMultipleChoice]: <LibraryAddCheck />,
};

export const renderQuestionType = (questionType: QuestionTypeEnum): string => {
  switch (questionType) {
    case QuestionTypeEnum.FreeResponse:
      return 'Free Response';
    case QuestionTypeEnum.ShortAnswer:
      return 'Short Answer';
    case QuestionTypeEnum.SelectAllMultipleChoice:
      return 'Select All';
    case QuestionTypeEnum.SelectOneMultipleChoice:
      return 'Multiple Choice';
  }
};
