import { useMutation, useQuery } from '@apollo/client';
import { Help } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import { useContext, useState } from 'react';
import { OWNER_TYPE, PAGINATION_COUNT } from '..';
import { HandleFlaggedResponseDocument } from '../../../../gql/mutations/__generated__/flaggedResponse.generated';
import {
  ActionItemsDocument,
  NotificationsCountDocument,
  type ActionItemQuery,
} from '../../../../gql/queries/__generated__/actionItem.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import {
  ActionItemStateEnum,
  AssessmentValueEnum,
} from '../../../../gql/types';
import {
  ASSESSMENT_VALUES,
  ASSESSMENT_VALUE_TO_EMOJI,
} from '../../../../utils/assessmentValueMappings';
import Emoji from '../../../shared/Emoji';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  keepContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(2),
    alignItems: 'center',
  },
  radioGroup: {
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    alignItems: 'flex-start',
  },
  keepButton: {
    color: theme.palette.common.white,
  },
  overrideContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  submitButton: {
    margin: '0 auto',
    display: 'block',
    marginTop: theme.spacing(1),
  },
  dropdown: {
    width: 60,
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  item: {
    fontSize: 24,
  },
  emojiContainer: {
    fontSize: 24,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginRight: theme.spacing(1),
  },
  teacherRating: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  helpIcon: {
    fontSize: 20,
    fill: theme.palette.primary.main,
    marginTop: 1,
  },
  overrideText: {
    marginRight: theme.spacing(0.5),
  },
}));

export type HandleFlaggedFreeResponseProps = {
  response: ActionItemQuery['actionItem']['actionable'];
  actionItemId: string;
};

export function HandleFlaggedFreeResponse({
  response,
}: HandleFlaggedFreeResponseProps) {
  const classes = useStyles();
  const { data: teacherData } = useQuery(TeacherDocument);
  const { dispatch } = useContext(AlertsContext);
  const [addStudentAnswer, setAddStudentAnswer] = useState(true);
  const courseId = teacherData?.teacher.activeCourse?.id || '';
  const [handleFlaggedResponse] = useMutation(HandleFlaggedResponseDocument, {
    onError: (e) => {
      console.log(e);
    },
    update: (cache, res) => {
      // only remove from current list when item has been archived
      if (res.data?.handleFlaggedResponse?.state === ActionItemStateEnum.Open) {
        return;
      }
      const variables = {
        ownerId: courseId,
        ownerType: OWNER_TYPE,
        first: PAGINATION_COUNT,
      };

      const removedVariables = {
        ...variables,
        open: true,
      };
      const addedVariables = { ...variables, open: false };

      const removeFromResults = cache.readQuery({
        query: ActionItemsDocument,
        variables: removedVariables,
      });
      const addedToResults = cache.readQuery({
        query: ActionItemsDocument,
        variables: addedVariables,
      });
      if (res.data?.handleFlaggedResponse && removeFromResults) {
        const index = removeFromResults.actionItems.edges?.findIndex(
          (actionItem) =>
            actionItem?.node?.id === res.data?.handleFlaggedResponse?.id
        );
        if (addedToResults) {
          const addedTo = produce(
            addedToResults.actionItems,
            (actionItemsDraft) => {
              const edge =
                removeFromResults.actionItems.edges &&
                index !== undefined &&
                index !== -1
                  ? removeFromResults.actionItems.edges[index]
                  : null;
              if (edge) {
                actionItemsDraft.edges?.unshift(edge);
                actionItemsDraft.totalCount += 1;
              }
            }
          );
          cache.writeQuery({
            data: { actionItems: addedTo },
            query: ActionItemsDocument,
            variables: addedVariables,
          });
        }
        const removeFrom = produce(
          removeFromResults.actionItems,
          (actionItemsDraft) => {
            if (index !== undefined && index !== -1) {
              actionItemsDraft.edges?.splice(index, 1);
              actionItemsDraft.totalCount -= 1;
              dispatch(
                pushSnack({
                  message: 'Notification archived!',
                  severity: 'success',
                })
              );
            }
          }
        );
        // when something was just archived, check if the open list still has
        // items, and if not, then turn off notification badge:
        if (removeFrom.edges?.length === 0 && teacherData) {
          cache.writeQuery({
            data: { notificationsCount: 0 },
            query: NotificationsCountDocument,
            variables: { ownerId: courseId, ownerType: OWNER_TYPE },
          });
        }
        cache.writeQuery({
          data: { actionItems: removeFrom },
          query: ActionItemsDocument,
          variables: removedVariables,
        });
      }
    },
  });

  const handleAddStudentAnswerToggle = () => {
    setAddStudentAnswer(!addStudentAnswer);
  };

  const handleOverrideValue = (event: SelectChangeEvent<unknown>) => {
    if (event.target.value !== undefined) {
      handleFlaggedResponse({
        variables: {
          action: true,
          flaggedResponseId: response.id,
          teacherAssessment: event.target.value as AssessmentValueEnum,
        },
      });
    }
  };

  const keepRating = () => {
    handleFlaggedResponse({
      variables: {
        action: false,
        flaggedResponseId: response.id,
        addStudentAnswerAsAcceptable: addStudentAnswer,
      },
    });
  };

  // typescript needs this discriminator in order to know that we're working
  // with a free response response here:
  if (response.response.__typename !== 'FreeResponseResponse') {
    return null;
  }

  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.title} variant="h4">
          Select a Response
        </Typography>
        <span className={classes.teacherRating}>
          {' '}
          (Teacher Rating -{' '}
          {response.response.teacherAssessment !== null &&
          response.response.teacherAssessment !== undefined ? (
            <span className={classes.emojiContainer}>
              <Emoji
                symbol={
                  ASSESSMENT_VALUE_TO_EMOJI(
                    response.response.teacherAssessment as AssessmentValueEnum
                  ).emoji
                }
              />
            </span>
          ) : (
            'None'
          )}
          )
        </span>
      </div>
      <div className={classes.actionsContainer}>
        <div className={classes.keepContainer}>
          <div className={classes.checkboxContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={addStudentAnswer}
                  onChange={handleAddStudentAnswerToggle}
                  name="add-student-answer"
                />
              }
              label="Answer is Acceptable"
            />
            <Tooltip title="Checking this checkbox will add the student answer to the list of acceptable answers, and future answers that are similar will no longer be flagged.">
              <Help className={classes.helpIcon} />
            </Tooltip>
          </div>
          <Button
            variant="contained"
            onClick={keepRating}
            color="secondary"
            className={classes.keepButton}
          >
            Keep Student Rating
          </Button>
        </div>
        <div>
          <div className={classes.checkboxContainer}>
            <Typography variant="body1" className={classes.overrideText}>
              Override Student:
            </Typography>
            <Tooltip title="Your rating overrides the student's rating, which updates when the question shows up in the personal deck again.">
              <Help className={classes.helpIcon} />
            </Tooltip>
          </div>
          <Select
            size="small"
            className={classes.dropdown}
            id="demo-simple-select"
            onChange={handleOverrideValue}
          >
            <MenuItem key="undefined-option" value={undefined}></MenuItem>
            {ASSESSMENT_VALUES.map(([assessmentEnum, emoji]) => {
              return (
                <MenuItem
                  className={classes.item}
                  key={assessmentEnum}
                  value={assessmentEnum}
                >
                  <Emoji symbol={emoji.emoji} label={emoji.label} />
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
    </>
  );
}
