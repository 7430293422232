import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ReviewModeQuestion from './ReviewModeQuestion';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  questionContainer: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
    borderRadius: 4,
  },
}));

type ReviewModeQuestionsProps = {
  selectedQuestionIds: Set<string>;
};

export function ReviewModeQuestions({
  selectedQuestionIds,
}: ReviewModeQuestionsProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {Array.from(selectedQuestionIds).map((questionId) => {
        return (
          <div
            key={`${questionId}-review-session-question-key`}
            className={classes.questionContainer}
          >
            <ReviewModeQuestion questionId={questionId} />
          </div>
        );
      })}
    </div>
  );
}

export default ReviewModeQuestions;
