import { useMutation } from '@apollo/client';
import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ct from 'countries-and-timezones';
import React, { useContext, useState } from 'react';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import { CreateSchoolDocument } from '../../../../gql/mutations/__generated__/school.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { US, countries } from '../../../../utils/data/countries';
import { FIFTY_STATES } from '../../../../utils/data/fiftyStates';
import { AlertsContext } from '../../Alerts/context';
import HelpKitCorrectSchool from '../../HelpKitArticles/HelpKitCorrectSchool';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  buttonLink: {
    ...buttonLink(theme),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  field: {
    marginBottom: theme.spacing(1.5),
  },
  form: {
    margin: '0 auto',
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: 4,
    maxWidth: 1500,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

type StateType = {
  name: string;
  abbreviation: string;
};

type CreateSchoolProps = {
  toggleMode: () => void;
  onCompletedCallback?: () => void;
};

export function CreateSchool({
  toggleMode,
  onCompletedCallback,
}: CreateSchoolProps) {
  const classes = useStyles();
  const [country, setCountry] = useState(US);
  const { dispatch } = useContext(AlertsContext);
  const [createSchool] = useMutation(CreateSchoolDocument, {
    onError: onError(dispatch),
    onCompleted: () => {
      if (onCompletedCallback) {
        onCompletedCallback();
      }
    },
  });
  const [textFields, setTextFields] = useState({
    name: '',
    address: '',
    city: '',
    zip: '',
  });
  const [stateCode, setStateCode] = useState<undefined | StateType>();
  const [timezone, setTimezone] = useState('');

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createSchool({
      variables: {
        ...textFields,
        timezone,
        country: country.key,
        zip: country.key === 'US' ? textFields.zip : null,
        stateCode: country.key === 'US' ? stateCode?.abbreviation : null,
      },
    });
  };

  const updateTextField = (fieldName: string) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setTextFields({
        ...textFields,
        [fieldName]: e.target.value,
      });
    };
  };

  const handleTimezoneChange = (event: SelectChangeEvent<unknown>) => {
    setTimezone(event.target.value as string);
  };
  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.header}>
          <Typography variant="h4" className={classes.title}>
            Create Your School
          </Typography>
          <button onClick={toggleMode} className={classes.buttonLink}>
            Click here to return to school search
          </button>
        </div>
        <Autocomplete
          value={country}
          className={classes.field}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          id="countries"
          onChange={(e, newValue) => {
            if (newValue) {
              setCountry(newValue);
            }
          }}
          fullWidth
          options={countries}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              variant="outlined"
              fullWidth
            />
          )}
        />
        <TextField
          required
          variant="outlined"
          onChange={updateTextField('name')}
          value={textFields.name}
          className={classes.field}
          fullWidth
          label="School Name"
        />
        <TextField
          variant="outlined"
          required
          onChange={updateTextField('address')}
          value={textFields.address}
          className={classes.field}
          fullWidth
          label="Address"
        />
        <TextField
          variant="outlined"
          required
          onChange={updateTextField('city')}
          value={textFields.city}
          className={classes.field}
          fullWidth
          label="City"
        />
        {country.key === 'US' && (
          <>
            <Autocomplete
              value={stateCode}
              className={classes.field}
              isOptionEqualToValue={(option, value) =>
                option.abbreviation === value.abbreviation
              }
              id="states"
              onChange={(e, newValue) => {
                if (newValue) {
                  setStateCode(newValue);
                }
              }}
              fullWidth
              options={FIFTY_STATES}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="State"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <TextField
              value={textFields.zip}
              onChange={updateTextField('zip')}
              className={classes.field}
              variant="outlined"
              required
              fullWidth
              label="Zip Code"
            />
          </>
        )}
        <FormControl
          variant="outlined"
          className={classes.field}
          fullWidth
          required
        >
          <InputLabel id="timezone">Time Zone</InputLabel>
          <Select
            labelId="timezone"
            label="Time Zone"
            required
            onChange={handleTimezoneChange}
          >
            {ct
              .getTimezonesForCountry(country.key)
              .filter((timezone) => {
                if (country.key !== 'US') {
                  return true;
                }
                // for US, only show the aliased timezones that starts
                // with 'US/' in order to avoid confusion when selecting timezones:
                return timezone.name.startsWith('US');
              })
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((timezone) => {
                return (
                  <MenuItem value={timezone.name} key={timezone.name}>
                    {timezone.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <HelpKitCorrectSchool />
        <div className={classes.buttonContainer}>
          <Button variant="contained" color="primary" type="submit">
            Create Your School
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CreateSchool;
