import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { useContext, useMemo } from 'react';
import {
  Route,
  Switch,
  useParams,
  type RouteComponentProps,
} from 'react-router-dom';
import { AlertsContext } from '../../components/application/Alerts/context';
import ReworkedEditor from '../../components/application/Assignments/AssignmentEditor/Reworked';
import { AssignmentEditorProvider } from '../../components/application/Assignments/AssignmentEditor/context';
import {
  initialState,
  type AssignmentEditorState,
  type EnrollmentsAssignmentHash,
  type GroupsAssignmentHash,
} from '../../components/application/Assignments/AssignmentEditor/context/state';
import GroupsAssignmentsDetailsContainer from '../../components/application/Groups/Assignments/DetailsContainer';
import { AssignmentDocument } from '../../gql/queries/__generated__/assignment.generated';
import { onError } from '../../utils/apollo/apolloHelper';

export function AssignmentEditor({ match }: RouteComponentProps) {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(AssignmentDocument, {
    fetchPolicy: 'network-only',
    onError: onError(dispatch),
    variables: { assignmentId },
  });

  const preloadedState = useMemo((): AssignmentEditorState | undefined => {
    if (!data) {
      return;
    }
    const {
      assignment: { groupsAssignments, enrollmentsAssignments, launchAt },
    } = data;

    const processedGroupsAssignments: GroupsAssignmentHash = {};
    groupsAssignments?.forEach((ga) => {
      processedGroupsAssignments[ga.group.id] = {
        id: ga.id,
        dueDate: ga.dueDate ? new Date(ga.dueDate) : undefined,
        launchDate: ga.launchDate ? new Date(ga.launchDate) : undefined,
      };
    });

    const processedEnrollmentsAssignments: EnrollmentsAssignmentHash = {};
    enrollmentsAssignments?.forEach((ea) => {
      processedEnrollmentsAssignments[ea.enrollment.id] = {
        id: ea.id,
        enrollmentId: ea.enrollment.id,
        dueDate: new Date(ea.dueDate),
        studentName: ea.enrollment.student.fullName,
        studentEmail: ea.enrollment.student.email,
        class: ea.enrollment.group?.name || '',
      };
    });
    return {
      ...initialState,
      ...data.assignment,
      launchAt: launchAt ? new Date(launchAt) : null,
      groupsAssignments: processedGroupsAssignments,
      enrollmentsAssignments: processedEnrollmentsAssignments,
    };
  }, [data]);

  if (loading || !preloadedState) {
    return <LinearProgress />;
  }

  return (
    <AssignmentEditorProvider preloadedState={preloadedState}>
      <Switch>
        <Route
          path={`${match.path}/results/:groupId`}
          component={GroupsAssignmentsDetailsContainer}
        />
        <Route path={`${match.path}`} component={ReworkedEditor} />
      </Switch>
    </AssignmentEditorProvider>
  );
}
