import { MenuItem, Select, SelectChangeEvent, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import HelpKitEditingCourse from '../../components/application/HelpKitArticles/HelpKitEditingCourse';
import HelpKitNewSchoolYear from '../../components/application/HelpKitArticles/HelpKitNewSchoolYear';
import { SettingsBody } from '../Settings/SettingsBody';
import { CourseListActive } from './CourseListActive';
import { CourseListArchived } from './CourseListArchived';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  headerContent: {
    margin: '20px',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0.5),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  caption: {
    marginTop: theme.spacing(1),
    maxWidth: 1000,
  },
  select: {
    backgroundColor: theme.palette.common.white,
  },
  legendContainer: {
    marginTop: theme.spacing(3),
  },
}));

export enum CourseEditorEnum {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export function CoursesIndex() {
  const classes = useStyles();
  const [value, setValue] = useState<CourseEditorEnum>(CourseEditorEnum.ACTIVE);

  const handleChange = (event: SelectChangeEvent<CourseEditorEnum>) => {
    const updated = event.target.value as CourseEditorEnum;
    setValue(updated);
  };

  return (
    <SettingsBody title="Your Courses" description="">
      <div className={classes.root}>
        <Select
          variant="outlined"
          value={value}
          onChange={handleChange}
          className={classes.select}
        >
          <MenuItem value={CourseEditorEnum.ACTIVE}>Active</MenuItem>
          <MenuItem value={CourseEditorEnum.ARCHIVED}>Archived</MenuItem>
        </Select>
        <div className={classes.legendContainer}>
          <div>
            <HelpKitEditingCourse />
          </div>
          <div>
            <HelpKitNewSchoolYear />
          </div>
        </div>
        <div className={classes.content}>
          {value === CourseEditorEnum.ACTIVE ? (
            <CourseListActive />
          ) : (
            <CourseListArchived />
          )}
        </div>
      </div>
    </SettingsBody>
  );
}
