import { useQuery } from '@apollo/client';
import {
  FormControl,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useMemo } from 'react';
import { StandardsDocument } from '../../../../gql/queries/__generated__/standard.generated';
import { CollaborativeStandardsChartsDocument } from '../../../../gql/queries/__generated__/standardsChart.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { OwnershipEnum, QuestionTypeEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { LS_QUESTION_OWNERSHIP_KEY } from '../../../../utils/localStorageKeys';
import { questionTypeOptions } from '../../../../utils/options/questionTypeOptions';
import { AlertsContext } from '../../Alerts/context';
import {
  MultipleStandardsAutocomplete,
  StandardsQueryStandard,
} from '../Form/MultipleStandardsAutocomplete';
import {
  QuestionsSearchContext,
  type QuestionsSearchQuestionType,
} from './context/QuestionsSearchContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  subtitle: {
    marginBottom: 14,
  },
  searchFormContainer: {
    display: 'flex',
    position: 'relative',
  },
  standardsFilter: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  questionTypeContainer: {
    width: '25%',
    marginRight: theme.spacing(1),
  },
  ownershipContainer: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  questionTextFilter: {
    flexGrow: 2,
  },
  questionFilterContainer: {
    width: '35%',
    marginTop: 14,
  },
  standardsContainer: {
    marginBottom: theme.spacing(1),
  },
  subjectContainer: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25%',
  },
}));

type QuestionsSearchProps = {
  setResultsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export function QuestionsSearch({ setResultsLoading }: QuestionsSearchProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);

  const { questionsSearchParams, setQuestionsSearchParams } = useContext(
    QuestionsSearchContext
  );
  const { text, questionType, standards, standardsChartId, ownership, unused } =
    questionsSearchParams;
  const { data } = useQuery(CollaborativeStandardsChartsDocument, {
    onError: onError(dispatch),
    variables: { ownership },
  });
  const { data: teacherData, loading: teacherLoading } = useQuery(
    TeacherDocument,
    { onError: onError(dispatch) }
  );

  const { data: standardsData, loading: standardsLoading } = useQuery(
    StandardsDocument,
    {
      skip: !standardsChartId,
      variables: { standardsChartId },
      onError: onError(dispatch),
    }
  );

  useEffect(() => {
    const sid = teacherData?.teacher?.activeCourse?.standardsChart?.id || '';
    if (!sid) {
      return;
    }

    setQuestionsSearchParams({
      ...questionsSearchParams,
      standardsChartId: sid,
    });
    // disabling to prevent infnite loop:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setQuestionsSearchParams,
    teacherData?.teacher?.activeCourse?.standardsChart?.id,
  ]);
  const setText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionsSearchParams({
      ...questionsSearchParams,
      text: e.target.value,
    });
  };

  const setType = (e: SelectChangeEvent<QuestionTypeEnum>) => {
    setQuestionsSearchParams({
      ...questionsSearchParams,
      questionType: e.target.value as QuestionsSearchQuestionType,
    });
  };

  const handleStandardsChartChange = (e: SelectChangeEvent<string>) => {
    setQuestionsSearchParams({
      ...questionsSearchParams,
      standardsChartId: e.target.value as string,
    });
  };

  const handleOwnershipChange = (e: SelectChangeEvent<OwnershipEnum>) => {
    setQuestionsSearchParams({
      ...questionsSearchParams,
      ownership: e.target.value as OwnershipEnum,
    });
    localStorage.setItem(LS_QUESTION_OWNERSHIP_KEY, e.target.value as string);
  };
  const handleUnusedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionsSearchParams({
      ...questionsSearchParams,
      unused: e.target.checked as boolean,
    });
  };

  const handleStandardUpdate = (
    _: unknown,
    newValue: StandardsQueryStandard[] | null
  ) => {
    const update = newValue || [];
    setQuestionsSearchParams({
      ...questionsSearchParams,
      standards: update,
    });
  };

  const options = useMemo(() => {
    if (standardsData?.standards) {
      return standardsData.standards;
    }
    return [];
  }, [standardsData]);

  useEffect(() => {
    setResultsLoading(teacherLoading || standardsLoading);
  }, [setResultsLoading, teacherLoading, standardsLoading]);

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.subtitle}>
        Use filters or search to locate existing questions.
      </Typography>
      <div className={classes.searchFormContainer}>
        <FormControl variant="outlined" className={classes.ownershipContainer}>
          <Select
            fullWidth
            value={ownership}
            onChange={handleOwnershipChange}
            displayEmpty
          >
            <MenuItem value={OwnershipEnum.Others}>
              Other Teachers&apos; Questions
            </MenuItem>
            <MenuItem value={OwnershipEnum.Own}>My Own Questions</MenuItem>
            <MenuItem value={OwnershipEnum.Podsie}>Podsie</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={classes.searchFormContainer}>
        <FormControl variant="outlined" className={classes.subjectContainer}>
          <Select
            fullWidth
            value={standardsChartId}
            onChange={handleStandardsChartChange}
            displayEmpty
          >
            {data?.collaborativeStandardsCharts.map((standardsChart) => {
              return (
                <MenuItem
                  key={standardsChart.id}
                  value={standardsChart.id || ''}
                >
                  {standardsChart.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <div style={{ width: '100%' }}>
          <MultipleStandardsAutocomplete
            limitTags={3}
            value={standards}
            onChange={(event, values) => handleStandardUpdate(event, values)}
            options={options}
          />
        </div>
      </div>
      <div className={classes.searchFormContainer}>
        <FormControl
          variant="outlined"
          className={classes.questionTypeContainer}
        >
          <Select value={questionType} onChange={setType} displayEmpty>
            <MenuItem value="">Question Type</MenuItem>
            {questionTypeOptions.map(({ value, text }) => (
              <MenuItem key={`option-${value}`} value={value}>
                {text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          className={classes.questionTextFilter}
          fullWidth
          variant="outlined"
          label="Search by Question Text"
          value={text}
          onChange={setText}
        />
      </div>
      <div className={classes.searchFormContainer}>
        <FormControl
          variant="outlined"
          className={classes.questionFilterContainer}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch
              onChange={(e) => {
                handleUnusedChange(e);
              }}
              checked={unused}
              color="success"
            />
            <Typography>Unused Questions</Typography>
          </Stack>
        </FormControl>
      </div>
    </div>
  );
}

export default QuestionsSearch;
