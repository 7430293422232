import AlertsConfirmation from './AlertsConfirmation';
import AlertsDialog from './AlertsDialog';
import AlertsSnackbar from './AlertsSnackbar';

export function Alerts() {
  return (
    <>
      <AlertsDialog />
      <AlertsConfirmation />
      <AlertsSnackbar />
    </>
  );
}

export default Alerts;
