import HelpKitButton from './HelpKitButton';

export function HelpKitRequiredKeywords() {
  return (
    <HelpKitButton
      label="What are Required Keywords?"
      articleId="edkRsin72dqcfhV8ZDMoBd"
    />
  );
}
export default HelpKitRequiredKeywords;
