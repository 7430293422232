/**
 * Is the given `value` a plain empty object (e.g.: `{}`)?
 * Non-enumerable properties are ignored.
 *
 * @example
 * isEmptyObject({}) // true
 * isEmptyObject(Object.create(
 *   Object.prototype,
 *   { hidden: { enumerable: false, value: 42 } }
 * )) // true
 * isEmptyObject({ answer: 42 }) // false
 * isEmptyObject([]) // false
 *
 * @param {unknown} value
 * @returns {value is {}}
 */
export function isEmptyObject(value) {
  return (
    typeof value === 'object' &&
    value !== null &&
    Object.keys(value).length === 0 &&
    value.constructor === Object
  );
}

/**
 * @typedef {Record<string, unknown>} UnknownRecord
 * A convenience type for a value we know to be an object with properties that
 * can be safely accessed via `.` operator but which value types are unknown to
 * us.
 *
 * Having an object with this type allows us to safely access its properties
 * while still requiring that we check the type of property at runtime (or
 * unsafely use type assertions) to make good use of them.
 *
 * The justification for this is that `{}` and `object` are too permissive as
 * types because they are treated as practically any non-nullish value and
 * `{ [key: string]: any }` regards all values types as `any` eliminating all
 * type safety for all deeply nested properties.
 */

/**
 * Is the given `value` an {@link UnknownRecord}?
 *
 * This type guard is primarily useful for narrowing a value from `unknown` (the
 * most cautious possible type) to an object whose properties are `unknown`.
 * This is not likely to be used directly in business domain code but more
 * likely in other more specific type guards.
 *
 * @example
 *
 * ```ts
 * // example unsafe user payload decoded from a JWT
 * const userFromToken: unknown = { ... }
 * type User = { email: string }
 *
 * const isUser =
 *   (tokenPayload: unknown): tokenPayload is User =>
 *     isUnknownRecord(tokenPayload) &&
 *     isString(tokenPayload.email)
 * ```
 *
 * @param {unknown} value
 * @returns {value is UnknownRecord}
 */
export function isUnknownRecord(value) {
  return typeof value === 'object' && !Array.isArray(value) && value !== null;
}
