import { useQuery } from '@apollo/client';
import { ActionItemDocument } from '../../../gql/queries/__generated__/actionItem.generated';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import { Details } from './Details';

type DetailPanelProps = {
  selectedActionItemId: string;
};

export function DetailPanel({ selectedActionItemId }: DetailPanelProps) {
  const { data, loading } = useQuery(ActionItemDocument, {
    skip: !selectedActionItemId,
    variables: { actionItemId: selectedActionItemId },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <LoadingSkeletons num={10} />;
  }

  if (!data || !selectedActionItemId) {
    return null;
  }

  return <Details actionItem={data.actionItem} />;
}
