import { useQuery } from '@apollo/client';
import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  AssignmentDocument,
  QuestionsBreakdownDocument,
} from '../../../../../gql/queries/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../../../gql/types';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../../Alerts/context';
import { QuestionsBreakdownProvider } from '../../../Groups/Assignments/QuestionsBreakdownContext';
import { HeaderTitleContext } from '../../../Layout/Header/HeaderTitle';
import { QuestionFormProvider } from '../../../Questions/Form/context';
import { OptionsButton } from '../OptionsButton';
import { AttemptsBreakdown } from './AttemptsBreakdown';
import GroupFilter from './GroupFilter';
import { LaunchButton } from './LaunchButton';
import { AssignmentQuestions } from './Questions';
import { SearchOrCreate } from './Questions/SearchOrCreate';
import ResultsToggleButton from './ResultsToggleButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 1000,
    margin: '20px auto',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(4),
    position: 'sticky',
    top: theme.spacing(8),
    zIndex: 100,
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.mint.light,
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    border: `1px solid ${theme.palette.mint.dark}`,
    borderRadius: 4,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
    },
  },
  optionsButtonContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export enum AssignmentEditorModes {
  SEARCH = 'SEARCH',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  CREATE_VARIATIONS = 'CREATE_VARIATIONS',
}

export enum AssignmentResultsModes {
  QUESTIONS = 'QUESTIONS',
  GRADES = 'GRADES',
}
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQueryParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function ReworkedEditor() {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const { dispatch } = useContext(AlertsContext);
  const [mode, setMode] = useState<AssignmentEditorModes | undefined>();
  const [resultsMode, setResultsMode] = useState<AssignmentResultsModes>(
    AssignmentResultsModes.QUESTIONS
  );
  const query = useQueryParams();
  const prefilledGroupId = query.get('groupId');
  const [selectedGroupId, setSelectedGroupId] = useState<string>(
    prefilledGroupId || 'all'
  );
  const [questionId, setQuestionId] = useState<string | undefined>();

  const { updateTitle } = useContext(HeaderTitleContext);
  const classes = useStyles();
  const { data: assignmentQueryData } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });
  const groupsAssignments =
    assignmentQueryData?.assignment.groupsAssignments || [];
  const assignmentStatus = assignmentQueryData?.assignment.assignmentStatus;
  const { data: questionsBreakdownData, loading } = useQuery(
    QuestionsBreakdownDocument,
    {
      skip: !selectedGroupId,
      variables: {
        assignmentId,
        groupId: selectedGroupId,
        showAllGroups: selectedGroupId === 'all',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const assignmentName = assignmentQueryData?.assignment.name || '';
  useEffect(() => {
    if (assignmentName) {
      let trimmed = assignmentName;
      if (trimmed.length > 20) {
        trimmed = assignmentName.substring(0, 20).trim() + '...';
      }
      updateTitle(trimmed);
    }
  }, [assignmentName, updateTitle]);

  const view = () => {
    switch (resultsMode) {
      case AssignmentResultsModes.QUESTIONS:
        return (
          <AssignmentQuestions
            mode={mode}
            breakdownLoading={loading}
            selectedGroupId={selectedGroupId}
            setMode={setMode}
            questionId={questionId}
            setQuestionId={setQuestionId}
          />
        );
      case AssignmentResultsModes.GRADES:
        if (selectedGroupId === 'all') {
          return (
            <div>
              Please select one specific class in order to see this view!
            </div>
          );
        }
        return (
          <AttemptsBreakdown
            assignmentId={assignmentId}
            groupId={selectedGroupId}
          />
        );
      default:
        return <div></div>;
    }
  };

  return (
    <QuestionFormProvider>
      <div className={classes.root}>
        <section className={classes.buttonsContainer}>
          <div>
            {assignmentStatus === AssignmentStatusEnum.Pending ? (
              <SearchOrCreate
                mode={mode}
                setMode={setMode}
                setQuestionId={setQuestionId}
              />
            ) : (
              <div className={classes.flex}>
                <GroupFilter
                  selectedGroupId={selectedGroupId}
                  setSelectedGroupId={setSelectedGroupId}
                  groupsAssignments={groupsAssignments}
                />
                <div>
                  <ResultsToggleButton
                    resultsMode={resultsMode}
                    setResultsMode={setResultsMode}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={classes.flex}>
            <div className={classes.optionsButtonContainer}>
              <OptionsButton
                resetLaunchProgressStates={() => {}}
                launchInProgress={false}
              />
            </div>
            <LaunchButton />
          </div>
        </section>
        <QuestionsBreakdownProvider
          preloadedState={questionsBreakdownData?.questionsBreakdown}
        >
          {view()}
        </QuestionsBreakdownProvider>
      </div>
    </QuestionFormProvider>
  );
}

export { ReworkedEditor as NormalAssignment };
