import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AssignmentQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type AssignmentQuery = {
  __typename?: 'Query';
  assignment: {
    __typename?: 'Assignment';
    id: string;
    createdAt: any;
    name: string;
    description?: string | null;
    numSpiraledQuestions: number;
    targetDistributedPoints?: number | null;
    assignmentType: Types.AssignmentTypeEnum;
    assignmentMethod: Types.AssignmentMethodEnum;
    assignmentStatus: Types.AssignmentStatusEnum;
    unassignable: boolean;
    shareable: boolean;
    optional: boolean;
    launchAt?: any | null;
    standards: Array<{
      __typename?: 'Standard';
      title: string;
      description: string;
      id: string;
    }>;
    enrollmentsAssignments?: Array<{
      __typename?: 'EnrollmentsAssignment';
      id: string;
      dueDate: any;
      enrollment: {
        __typename?: 'Enrollment';
        id: string;
        enrollmentStatus: Types.EnrollmentStatusEnum;
        student: {
          __typename?: 'Student';
          id: string;
          fullName?: string | null;
          sortName?: string | null;
          email: string;
        };
        group?: { __typename?: 'Group'; name: string } | null;
      };
      studentsAssignmentsDatum?: {
        __typename?: 'StudentsAssignmentsDatum';
        id: string;
        groupId: string;
        questionsCompleted: number;
        completedAt?: any | null;
        questionsCorrect: number;
        questionsTotal: number;
      } | null;
    }> | null;
    groupsAssignments?: Array<{
      __typename?: 'GroupsAssignment';
      id: string;
      dueDate?: any | null;
      launchDate?: any | null;
      studentsAssignmentsData?: Array<{
        __typename?: 'StudentsAssignmentsDatum';
        id: string;
        groupId: string;
        questionsCompleted: number;
        distributedPointsEarned?: number | null;
        completedAt?: any | null;
        questionsCorrect: number;
        questionsTotal: number;
        student: {
          __typename?: 'Student';
          id: string;
          fullName?: string | null;
          sortName?: string | null;
        };
        enrollment: {
          __typename?: 'Enrollment';
          enrollmentStatus: Types.EnrollmentStatusEnum;
        };
      }> | null;
      group: { __typename?: 'Group'; name: string; id: string };
    }> | null;
    questions: Array<{
      __typename?: 'Question';
      richText: any;
      numAttemptsUntilAnswerShown: number;
      id: string;
      state: Types.QuestionStateEnum;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      copiedFromId?: string | null;
      variations?: Array<{ __typename?: 'Question'; id: string }> | null;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        plainText: string;
        richText: any;
        comparable: boolean;
        fraRequiredKeywords: Array<{
          __typename?: 'FraRequiredKeyword';
          id: string;
          keyword: string;
        }>;
      } | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        id: string;
        isCaseSensitive: boolean;
        ignoreWhitespaces: boolean;
        text: string;
        plainText: string;
        richText: any;
        hidden: boolean;
      }> | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        text: string;
        plainText: string;
        richText: any;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        id: string;
        title: string;
        description: string;
      }> | null;
    }>;
  };
};

export type CollaborationAssignmentsQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CollaborationAssignmentsQuery = {
  __typename?: 'Query';
  collaborationAssignments: {
    __typename?: 'AssignmentConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'AssignmentEdge';
      cursor: string;
      node?: {
        __typename?: 'Assignment';
        createdAt: any;
        id: string;
        name: string;
        numQuestions: number;
        course: { __typename?: 'Course'; name: string };
        teacher: {
          __typename?: 'Teacher';
          email: string;
          firstName?: string | null;
          fullName?: string | null;
          lastName?: string | null;
        };
      } | null;
    } | null> | null;
  };
};

export type AssignmentQuestionsQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type AssignmentQuestionsQuery = {
  __typename?: 'Query';
  assignment: {
    __typename?: 'Assignment';
    id: string;
    questions: Array<{
      __typename?: 'Question';
      id: string;
      plainText: string;
      richText: any;
    }>;
  };
};

export type AssignmentCollaborationQuestionsQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type AssignmentCollaborationQuestionsQuery = {
  __typename?: 'Query';
  assignmentCollaboration: {
    __typename?: 'Assignment';
    id: string;
    questions: Array<{
      __typename?: 'Question';
      id: string;
      plainText: string;
      richText: any;
    }>;
  };
};

export type AssignmentDetailedQuestionsQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type AssignmentDetailedQuestionsQuery = {
  __typename?: 'Query';
  assignment: {
    __typename?: 'Assignment';
    id: string;
    questions: Array<{
      __typename?: 'Question';
      richText: any;
      numAttemptsUntilAnswerShown: number;
      id: string;
      state: Types.QuestionStateEnum;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      copiedFromId?: string | null;
      variations?: Array<{ __typename?: 'Question'; id: string }> | null;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        plainText: string;
        richText: any;
        comparable: boolean;
        fraRequiredKeywords: Array<{
          __typename?: 'FraRequiredKeyword';
          id: string;
          keyword: string;
        }>;
      } | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        id: string;
        isCaseSensitive: boolean;
        ignoreWhitespaces: boolean;
        text: string;
        plainText: string;
        richText: any;
        hidden: boolean;
      }> | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        text: string;
        plainText: string;
        richText: any;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        id: string;
        title: string;
        description: string;
      }> | null;
    }>;
  };
};

export type AssignmentLaunchProgressQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type AssignmentLaunchProgressQuery = {
  __typename?: 'Query';
  assignment: {
    __typename?: 'Assignment';
    id: string;
    numStudentsAssigned?: number | null;
    numStudentsAssignmentsData?: number | null;
  };
};

export type AssignmentsQueryVariables = Types.Exact<{
  statuses?: Types.InputMaybe<
    Array<Types.AssignmentStatusEnum> | Types.AssignmentStatusEnum
  >;
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AssignmentsQuery = {
  __typename?: 'Query';
  assignments: {
    __typename?: 'AssignmentConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'AssignmentEdge';
      cursor: string;
      node?: {
        __typename?: 'Assignment';
        id: string;
        name: string;
        numSpiraledQuestions: number;
        assignmentType: Types.AssignmentTypeEnum;
        assignmentMethod: Types.AssignmentMethodEnum;
        assignmentStatus: Types.AssignmentStatusEnum;
        targetDistributedPoints?: number | null;
        description?: string | null;
        optional: boolean;
        launchAt?: any | null;
        numQuestions: number;
        updatedAt: any;
        aggregatedInfo: {
          __typename?: 'AssignmentAggregatedInfo';
          averageScore?: number | null;
          totalStudents: number;
          totalStudentsFinished: number;
        };
        standards: Array<{
          __typename?: 'Standard';
          title: string;
          description: string;
          meta?: any | null;
        }>;
        groupsAssignments?: Array<{
          __typename?: 'GroupsAssignment';
          id: string;
          dueDate?: any | null;
          launchDate?: any | null;
          enrolledStudentsAssignmentsData?: Array<{
            __typename?: 'StudentsAssignmentsDatum';
            id: string;
            questionsCompleted: number;
            distributedPointsEarned?: number | null;
            questionsCorrect: number;
            questionsTotal: number;
          }> | null;
          group: { __typename?: 'Group'; id: string; name: string };
          assignment: { __typename?: 'Assignment'; id: string };
        }> | null;
        enrollmentsAssignments?: Array<{
          __typename?: 'EnrollmentsAssignment';
          id: string;
          dueDate: any;
          enrollment: {
            __typename?: 'Enrollment';
            id: string;
            enrollmentStatus: Types.EnrollmentStatusEnum;
            student: {
              __typename?: 'Student';
              id: string;
              fullName?: string | null;
              sortName?: string | null;
              email: string;
            };
            group?: { __typename?: 'Group'; name: string } | null;
          };
          studentsAssignmentsDatum?: {
            __typename?: 'StudentsAssignmentsDatum';
            id: string;
            groupId: string;
            questionsCompleted: number;
            completedAt?: any | null;
            questionsCorrect: number;
            questionsTotal: number;
          } | null;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export type AssignmentResultsQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
  groupId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  showAllGroups?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type AssignmentResultsQuery = {
  __typename?: 'Query';
  assignmentResults: any;
};

export type AssignmentNumResponsesQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
  groupId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type AssignmentNumResponsesQuery = {
  __typename?: 'Query';
  assignmentNumResponses: {
    __typename?: 'AssignmentNumResponses';
    numResponses: number;
    groupsAssignmentId?: string | null;
  };
};

export type QuestionsBreakdownQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
  groupId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  showAllGroups?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type QuestionsBreakdownQuery = {
  __typename?: 'Query';
  questionsBreakdown: any;
};

export type CollaborativeAssignmentsCountQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
}>;

export type CollaborativeAssignmentsCountQuery = {
  __typename?: 'Query';
  collaborativeAssignmentsCount: number;
};

export type AssignmentForCurriculumReviewQueryVariables = Types.Exact<{
  assignmentId: Types.Scalars['ID']['input'];
}>;

export type AssignmentForCurriculumReviewQuery = {
  __typename?: 'Query';
  assignment: {
    __typename?: 'Assignment';
    id: string;
    name: string;
    numQuestionsNeedReview: number;
    numQuestionsNeedRevisit: number;
    curriculumQuestions: Array<{
      __typename?: 'Question';
      importId?: string | null;
      reviewSeverity: number;
      questionPart: number;
      richText: any;
      numAttemptsUntilAnswerShown: number;
      id: string;
      state: Types.QuestionStateEnum;
      plainText: string;
      questionType: Types.QuestionTypeEnum;
      copiedFromId?: string | null;
      variations?: Array<{ __typename?: 'Question'; id: string }> | null;
      freeResponseAnswer?: {
        __typename?: 'FreeResponseAnswer';
        id: string;
        text: string;
        plainText: string;
        richText: any;
        comparable: boolean;
        fraRequiredKeywords: Array<{
          __typename?: 'FraRequiredKeyword';
          id: string;
          keyword: string;
        }>;
      } | null;
      shortAnswerAnswers?: Array<{
        __typename?: 'ShortAnswerAnswer';
        id: string;
        isCaseSensitive: boolean;
        ignoreWhitespaces: boolean;
        text: string;
        plainText: string;
        richText: any;
        hidden: boolean;
      }> | null;
      multipleChoiceAnswerChoices?: Array<{
        __typename?: 'MultipleChoiceAnswerChoice';
        id: string;
        isCorrect: boolean;
        text: string;
        plainText: string;
        richText: any;
      }> | null;
      supplement?: {
        __typename?: 'Supplement';
        id: string;
        plainText: string;
        richText: any;
      } | null;
      standards?: Array<{
        __typename?: 'Standard';
        id: string;
        title: string;
        description: string;
      }> | null;
    }>;
  };
};

export type CurriculumAssignmentsQueryVariables = Types.Exact<{
  statuses?: Types.InputMaybe<
    Array<Types.AssignmentStatusEnum> | Types.AssignmentStatusEnum
  >;
  filter?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortByName?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CurriculumAssignmentsQuery = {
  __typename?: 'Query';
  assignments: {
    __typename?: 'AssignmentConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'AssignmentEdge';
      cursor: string;
      node?: {
        __typename?: 'Assignment';
        id: string;
        name: string;
        numCurriculumQuestions: number;
        numQuestionsNeedReview: number;
        numQuestionsNeedRevisit: number;
      } | null;
    } | null> | null;
  };
};

export type AssignmentsConnectionQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
  ownership: Types.OwnershipEnum;
  standardsChartId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  standardIds?: Types.InputMaybe<
    Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
  >;
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type AssignmentsConnectionQuery = {
  __typename?: 'Query';
  assignmentsConnection: {
    __typename?: 'AssignmentConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
      hasNextPage: boolean;
    };
    edges?: Array<{
      __typename?: 'AssignmentEdge';
      cursor: string;
      node?: {
        __typename?: 'Assignment';
        updatedAt: any;
        id: string;
        name: string;
        numQuestions: number;
        course: {
          __typename?: 'Course';
          name: string;
          standardsChart?: {
            __typename?: 'StandardsChart';
            id: string;
            standards: Array<{
              __typename?: 'Standard';
              id: string;
              title: string;
            }>;
          } | null;
        };
        questions: Array<{
          __typename?: 'Question';
          id: string;
          richText: any;
          questionType: Types.QuestionTypeEnum;
          standards?: Array<{
            __typename?: 'Standard';
            id: string;
            title: string;
          }> | null;
          freeResponseAnswer?: {
            __typename?: 'FreeResponseAnswer';
            id: string;
            richText: any;
            text: string;
          } | null;
          multipleChoiceAnswerChoices?: Array<{
            __typename?: 'MultipleChoiceAnswerChoice';
            id: string;
            isCorrect: boolean;
            richText: any;
            text: string;
          }> | null;
          shortAnswerAnswers?: Array<{
            __typename?: 'ShortAnswerAnswer';
            id: string;
            isCaseSensitive: boolean;
            richText: any;
            text: string;
            hidden: boolean;
          }> | null;
        }>;
      } | null;
    } | null> | null;
    nodes?: Array<{ __typename?: 'Assignment'; id: string } | null> | null;
  };
};

export const AssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'assignmentShowAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'assignmentShowAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Assignment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numSpiraledQuestions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetDistributedPoints' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'unassignable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareable' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentsAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentsAssignmentsDatum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCorrect' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupsAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentsAssignmentsData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'distributedPointsEarned',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCorrect' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortName' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'enrollmentStatus' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'questionAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssignmentQuery, AssignmentQueryVariables>;
export const CollaborationAssignmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'collaborationAssignments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborationAssignments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'course' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'teacher' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'firstName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'fullName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lastName' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'numQuestions' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CollaborationAssignmentsQuery,
  CollaborationAssignmentsQueryVariables
>;
export const AssignmentQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignmentQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plainText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'richText' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignmentQuestionsQuery,
  AssignmentQuestionsQueryVariables
>;
export const AssignmentCollaborationQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignmentCollaborationQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentCollaboration' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plainText' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'richText' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignmentCollaborationQuestionsQuery,
  AssignmentCollaborationQuestionsQueryVariables
>;
export const AssignmentDetailedQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignmentDetailedQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'questions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'questionAttributes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignmentDetailedQuestionsQuery,
  AssignmentDetailedQuestionsQueryVariables
>;
export const AssignmentLaunchProgressDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignmentLaunchProgress' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numStudentsAssigned' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numStudentsAssignmentsData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignmentLaunchProgressQuery,
  AssignmentLaunchProgressQueryVariables
>;
export const AssignmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'statuses' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AssignmentStatusEnum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'statuses' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'assignmentIndexAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'assignmentIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Assignment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numSpiraledQuestions' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentMethod' } },
          { kind: 'Field', name: { kind: 'Name', value: 'assignmentStatus' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'targetDistributedPoints' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'optional' } },
          { kind: 'Field', name: { kind: 'Name', value: 'launchAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numQuestions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'aggregatedInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalStudents' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'totalStudentsFinished' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'meta' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groupsAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'enrolledStudentsAssignmentsData',
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'distributedPointsEarned',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCorrect' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentsAssignments' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollment' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sortName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'group' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentsAssignmentsDatum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'completedAt' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCorrect' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AssignmentsQuery, AssignmentsQueryVariables>;
export const AssignmentResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignmentResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showAllGroups' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showAllGroups' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showAllGroups' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignmentResultsQuery,
  AssignmentResultsQueryVariables
>;
export const AssignmentNumResponsesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignmentNumResponses' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentNumResponses' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numResponses' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignmentNumResponsesQuery,
  AssignmentNumResponsesQueryVariables
>;
export const QuestionsBreakdownDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'questionsBreakdown' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'showAllGroups' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionsBreakdown' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'showAllGroups' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'showAllGroups' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  QuestionsBreakdownQuery,
  QuestionsBreakdownQueryVariables
>;
export const CollaborativeAssignmentsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'collaborativeAssignmentsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collaborativeAssignmentsCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CollaborativeAssignmentsCountQuery,
  CollaborativeAssignmentsCountQueryVariables
>;
export const AssignmentForCurriculumReviewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignmentForCurriculumReview' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numQuestionsNeedReview' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numQuestionsNeedRevisit' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curriculumQuestions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'questionWithImportAttributes',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionWithImportAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'importId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reviewSeverity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionPart' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignmentForCurriculumReviewQuery,
  AssignmentForCurriculumReviewQueryVariables
>;
export const CurriculumAssignmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'curriculumAssignments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'statuses' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'AssignmentStatusEnum' },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'filter' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortByName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'statuses' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'statuses' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'filter' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortByName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortByName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'numCurriculumQuestions',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'numQuestionsNeedReview',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'numQuestionsNeedRevisit',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CurriculumAssignmentsQuery,
  CurriculumAssignmentsQueryVariables
>;
export const AssignmentsConnectionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'assignmentsConnection' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'ownership' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'OwnershipEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardsChartId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'assignmentsConnection' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ownership' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'ownership' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardsChartId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardsChartId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardIds' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hasNextPage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'updatedAt' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'numQuestions' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'course' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'standardsChart',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'standards',
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'id',
                                                },
                                              },
                                              {
                                                kind: 'Field',
                                                name: {
                                                  kind: 'Name',
                                                  value: 'title',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'questions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'richText' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'questionType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'standards' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'title',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'freeResponseAnswer',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'richText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'multipleChoiceAnswerChoices',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'isCorrect',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'richText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'shortAnswerAnswers',
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'id' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'isCaseSensitive',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'richText',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'text' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'hidden',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AssignmentsConnectionQuery,
  AssignmentsConnectionQueryVariables
>;
