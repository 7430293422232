import { FormControl, TextField, Typography } from '@mui/material';
import { ChangeEvent } from 'react';

type AiCreateFormGradeLevelProps = {
  gradeLevel: string;
  handleGradeLevel: (event: ChangeEvent<HTMLInputElement>) => void;
};

export function AiCreateFormGradeLevel({
  gradeLevel,
  handleGradeLevel,
}: AiCreateFormGradeLevelProps) {
  return (
    <FormControl fullWidth>
      <Typography id="grade-level" variant="h5" sx={{ mb: 0.5 }}>
        Grade level:
      </Typography>
      <TextField value={gradeLevel} onChange={handleGradeLevel} />
    </FormControl>
  );
}
