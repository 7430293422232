import { useMutation, useQuery } from '@apollo/client';
import {
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ChangeEvent } from 'react';
import { UpdateCourseSettingsDocument } from '../../gql/mutations/__generated__/course.generated';
import { TeacherSettingsDocument } from '../../gql/queries/__generated__/teacher.generated';
import { SettingsOptionContainer } from './SettingsOptionContainer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  textContainer: {},
  daysOfWeek: {},
  columnsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftColumn: {
    maxWidth: '80%',
  },
  switchLabel: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: 0,
    textAlign: 'right',
  },
  infoControlContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(0.5),
    borderRadius: 4,
    border: `1px dotted ${theme.palette.secondary.main}`,
    padding: `${theme.spacing(1)} 0`,
  },
}));

const dayOfWeek = {
  0: 'Su',
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'Th',
  5: 'F',
  6: 'Sa',
} as const;

export function StudentNotificationsOptions() {
  const { data } = useQuery(TeacherSettingsDocument);
  const classes = useStyles();
  const course = data?.teacher.activeCourse;
  const courseId = course?.id || '';
  const [updateCourseSettings, { loading }] = useMutation(
    UpdateCourseSettingsDocument
  );
  const handleWeekdayToggle = (weekDay: number) => {
    return (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (!courseId) {
        return;
      }
      updateCourseSettings({
        variables: {
          courseId,
          recurringWday: weekDay,
          recurringWdayOn: checked,
        },
      });
    };
  };

  const handleNotifyPdqToggle = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!courseId) {
      return;
    }
    updateCourseSettings({
      variables: {
        courseId,
        recurringPdqNotification: checked,
      },
    });
  };

  const weekdaysDisabled =
    !course?.recurringLateAssignmentNotification &&
    !course?.recurringPdqNotification;

  const handleLateAssignmentToggle = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!courseId) {
      return;
    }
    updateCourseSettings({
      variables: {
        courseId,
        recurringLateAssignmentNotification: checked,
      },
    });
  };
  const handleAssignmentAlmostDueToggle = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!courseId) {
      return;
    }
    updateCourseSettings({
      variables: {
        courseId,
        assignmentAlmostDueNotification: checked,
      },
    });
  };
  if (!course) {
    return null;
  }

  const {
    recurring0,
    recurring1,
    recurring2,
    recurring3,
    recurring4,
    recurring5,
    recurring6,
  } = course;

  const daysObj = {
    0: recurring0,
    1: recurring1,
    2: recurring2,
    3: recurring3,
    4: recurring4,
    5: recurring5,
    6: recurring6,
  } as const;

  return (
    <div className={classes.root}>
      <SettingsOptionContainer title="Recurring Report">
        <div className={classes.columnsContainer}>
          <div className={classes.leftColumn}>
            <div className={classes.textContainer}>
              <Typography variant="caption">
                A recurring report that is sent at 7am (of school&apos;s
                timezone) to inform students of important info. You can adjust
                which day(s) to send the report and the info that&apos;s
                included.
              </Typography>
            </div>
            <div className={classes.daysOfWeek}>
              {([1, 2, 3, 4, 5, 6, 0] as const).map((day) => {
                return (
                  <FormControlLabel
                    key={`${day}-day-key`}
                    disabled={loading || weekdaysDisabled}
                    control={
                      <Checkbox
                        checked={daysObj[day]}
                        onChange={handleWeekdayToggle(day)}
                        name={dayOfWeek[day]}
                      />
                    }
                    label={dayOfWeek[day]}
                  />
                );
              })}
            </div>
          </div>
          <div className={classes.infoControlContainer}>
            <FormControlLabel
              className={classes.switchLabel}
              disabled={loading}
              labelPlacement="start"
              control={
                <Switch
                  checked={course.recurringPdqNotification}
                  onChange={handleNotifyPdqToggle}
                  name="Personal Deck Questions Due"
                />
              }
              label="Personal Deck Questions Due"
            />
            <FormControlLabel
              className={classes.switchLabel}
              disabled={loading}
              labelPlacement="start"
              control={
                <Switch
                  checked={course.recurringLateAssignmentNotification}
                  onChange={handleLateAssignmentToggle}
                  name="Late Assignments"
                />
              }
              label="Late Assignments"
            />
          </div>
        </div>
      </SettingsOptionContainer>
      <SettingsOptionContainer title="Assignment Almost Due">
        <div className={classes.columnsContainer}>
          <div className={classes.leftColumn}>
            <Typography variant="caption">
              If a student has not completed an assignment, then notify that
              student 24 hours before the assignment is due.
            </Typography>
          </div>
          <div>
            <Switch
              disabled={loading}
              checked={course.assignmentAlmostDueNotification}
              onChange={handleAssignmentAlmostDueToggle}
              name="Assignment Almost Due"
            />
          </div>
        </div>
      </SettingsOptionContainer>
    </div>
  );
}
