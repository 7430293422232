import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { TrackEventDocument } from '../../gql/mutations/__generated__/event.generated';

export const useTrackVisit = (metadata: { section: string; page: string }) => {
  const [trackEvent] = useMutation(TrackEventDocument);

  useEffect(() => {
    trackEvent({
      variables: { event: 'Teacher_Visit', metadata: JSON.stringify(metadata) },
    });
    // we only need to track the visit when the component is mounted:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
