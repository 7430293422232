import { useEffect, useState } from 'react';
import { default as userflow, type ResourceCenterState } from 'userflow.js';

/**
 * Use the current state of the Userflow resource center, automatically updated
 * when the resource center is updated imperatively (e.g.:
 * `userflow.setResourceCenterState()`)
 */
export function useUserflowResourceCenterState(): ResourceCenterState | null {
  const [state, setState] = useState(() => userflow.getResourceCenterState());

  useEffect(() => {
    const syncUserflowState = () => setState(userflow.getResourceCenterState());

    userflow.on('resourceCenterChanged', syncUserflowState);
    return () => userflow.off('resourceCenterChanged', syncUserflowState);
  }, [setState]);

  return state;
}
