import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import type { MultipleChoiceAnswerChoice } from '../../../../gql/types';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';

type StyleProps = { disableHover?: boolean };

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  answerChoiceInput: {
    position: 'absolute',
    opacity: 0,
  },
  label: {
    border: `1px solid ${theme.palette.mint.dark}`,
    backgroundColor: theme.palette.mint.light,
    borderRadius: 5,
    width: '100%',
    display: 'block',
    padding: theme.spacing(2),
    '&:hover': {
      cursor: ({ disableHover }: StyleProps) =>
        disableHover ? 'default' : 'pointer',
      opacity: ({ disableHover }: StyleProps) => (disableHover ? 1 : 0.7),
    },
  },
  selected: {
    backgroundColor: theme.palette.mint.dark,
    color: theme.palette.common.white,
  },
  answerChoice: {
    display: 'flex',
    margin: theme.spacing(2),
  },
}));

type ChoiceProps = {
  isChecked: boolean;
  handleInputChange: () => void;
  inputId: string;
  answerSubmitted: boolean;
  answerChoice?: MultipleChoiceAnswerChoice;
  text?: string;
  disableHover?: boolean;
};

export function MultipleChoice({
  answerChoice,
  answerSubmitted,
  disableHover,
  handleInputChange,
  inputId,
  isChecked,
  text,
}: ChoiceProps) {
  const classes = useStyles({ disableHover });

  return (
    <div className={classes.answerChoice}>
      <label
        className={clsx(classes.label, {
          [classes.selected]: isChecked,
        })}
        htmlFor={inputId}
      >
        {answerChoice?.richText ? (
          <QuillDeltaAsHtml multipleChoice delta={answerChoice.richText.ops} />
        ) : (
          text
        )}
      </label>
      <input
        disabled={answerSubmitted}
        checked={isChecked}
        onChange={handleInputChange}
        type="checkbox"
        id={inputId}
        className={classes.answerChoiceInput}
      />
    </div>
  );
}
