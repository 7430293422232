import HelpKitButton from './HelpKitButton';

export function HelpKitFlaggedResponses() {
  return (
    <HelpKitButton
      label="How do Flagged Responses work?"
      articleId="3eA9z7DVzqVNRGRX1PJXy6"
    />
  );
}
export default HelpKitFlaggedResponses;
