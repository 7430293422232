import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateStudentsMutationVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
  students: Array<Types.CreateStudentsInput> | Types.CreateStudentsInput;
}>;

export type CreateStudentsMutation = {
  __typename?: 'Mutation';
  createStudents: {
    __typename?: 'CreateStudentsResponse';
    enrollments: Array<{
      __typename?: 'Enrollment';
      id: string;
      enrollmentStatus: Types.EnrollmentStatusEnum;
      lastActivity?: any | null;
      numDuePersonalDecksQuestions?: number | null;
      unfreezePersonalDeck: boolean;
      student: {
        __typename?: 'Student';
        id: string;
        fullName?: string | null;
        sortName?: string | null;
        email: string;
      };
    }>;
    failedStudents: Array<{
      __typename?: 'FailedStudent';
      firstName: string;
      lastName: string;
      email: string;
    }>;
  };
};

export type UpdateStudentMutationVariables = Types.Exact<{
  firstName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  lastName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  studentId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  allowNameChange?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;

export type UpdateStudentMutation = {
  __typename?: 'Mutation';
  updateStudent: {
    __typename?: 'Student';
    id: string;
    email: string;
    firstName?: string | null;
    fullName?: string | null;
    lastName?: string | null;
    allowNameChange: boolean;
  };
};

export const CreateStudentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createStudents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'students' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'CreateStudentsInput' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createStudents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'students' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'students' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enrollments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'enrollmentAttributes' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'failedStudents' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'enrollmentAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Enrollment' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'enrollmentStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastActivity' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numDuePersonalDecksQuestions' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'unfreezePersonalDeck' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'student' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sortName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateStudentsMutation,
  CreateStudentsMutationVariables
>;
export const UpdateStudentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStudent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'studentId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'allowNameChange' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateStudent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'studentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'studentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'allowNameChange' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'allowNameChange' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fullName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'allowNameChange' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStudentMutation,
  UpdateStudentMutationVariables
>;
