import { useMutation, useQuery } from '@apollo/client';
import { Create, Settings, Visibility } from '@mui/icons-material';
import { Alert, Button, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isEmptyObject } from '@podsie/utils/object.js';
import { ChartData, ChartOptions } from 'chart.js';
import { startOfDay, subDays } from 'date-fns';
import { useContext, useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { useHistory } from 'react-router-dom';
import { CreateAssignmentDocument } from '../../../../gql/mutations/__generated__/assignment.generated';
import { CourseAverageDuePdqDocument } from '../../../../gql/queries/__generated__/course.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { AssignmentTypeEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { LS_PDQ_DATA_TYPE_KEY } from '../../../../utils/localStorageKeys';
import { LoadingOverlay } from '../../../shared/Layout/LoadingOverlay';
import { AlertsContext } from '../../Alerts/context';
import HelpKitPersonalDeckAssignment from '../../HelpKitArticles/HelpKitPersonalDeckAssignment';
import { PersonalDeckViewEnum } from '../../StudentProgress/PersonalDeckStudentProgress';

const useStyles = makeStyles((theme: Theme) => ({
  lineGraphContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    backgroundColor: 'rgb(239,249,244, 0.3)',
    padding: theme.spacing(2),
  },
  loader: {
    marginBottom: theme.spacing(2),
  },
  lineGraphHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  graphTitle: {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
  currentlyDueText: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  alert: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  alertIcon: {
    color: `${theme.palette.common.white} !important`,
  },
  message: {
    maxWidth: '70%',
  },
  action: {
    flexDirection: 'column',
  },
  actionButton: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    width: '185px',
  },
  alertMessageOne: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  alertMessageTwo: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function AverageDueChart() {
  const classes = useStyles();
  const history = useHistory();
  const { data: teacherData, loading: teacherLoading } =
    useQuery(TeacherDocument);
  const courseId = teacherData?.teacher.activeCourse?.id || '';
  const { data, loading: dueLoading } = useQuery(CourseAverageDuePdqDocument, {
    skip: !courseId,
    variables: { courseId },
  });
  const pdqData = data?.courseAverageDuePdq;
  const { dispatch } = useContext(AlertsContext);
  const chartRef = useRef<Line>(null);
  const [createAssignment, { loading }] = useMutation(
    CreateAssignmentDocument,
    {
      onError: onError(dispatch),
      onCompleted: (data) => {
        history.push(`/assignments/${data.createAssignment.id}`);
      },
    }
  );
  const lineData: ChartData = useMemo(() => {
    if (isEmptyObject(pdqData)) {
      return {};
    }
    const averages = pdqData || {};
    return {
      labels: [startOfDay(subDays(new Date(), 7))],
      datasets: [
        {
          label: 'Average Due',
          data: Object.keys(averages)
            .filter((d) => d !== 'current')
            .map((date) => ({
              x: date,
              y: averages[date],
            })),
          fill: false,
          backgroundColor: '#def3ef',
          borderColor: '#30B671',
        },
      ],
    };
  }, [pdqData]);
  const options: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Average Questions Due (All Classes)',
          },
          ticks: {
            stepSize: 10,
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          type: 'time',
          time: {
            unit: 'day' as const,
            tooltipFormat: 'MMM DD',
          },
        },
      ],
    },
  };
  const handleSeeMoreClick = () => {
    localStorage.setItem(
      LS_PDQ_DATA_TYPE_KEY,
      PersonalDeckViewEnum.PROGRESS_OVER_TIME
    );
    history.push('/personal-deck');
  };

  const createPDAssignment = () => {
    createAssignment({
      variables: {
        name: 'Personal Deck Assignment',
        assignmentType: AssignmentTypeEnum.Distributed,
      },
    });
  };

  const goToSettings = () => {
    history.push('/settings/personal-deck-spacing');
  };
  const renderAlert = () => {
    if (!currentDue || currentDue < 40) {
      return null;
    }
    return (
      <Alert
        className={classes.alert}
        severity="info"
        classes={{
          icon: classes.alertIcon,
          message: classes.message,
          action: classes.action,
        }}
        action={
          <>
            <Button
              startIcon={<Create />}
              variant="contained"
              disabled={loading}
              onClick={createPDAssignment}
              className={classes.actionButton}
            >
              PD Assignment
            </Button>
            <Button
              startIcon={<Settings />}
              variant="contained"
              onClick={goToSettings}
              className={classes.actionButton}
            >
              Settings
            </Button>
          </>
        }
      >
        <Typography variant="body1" className={classes.alertMessageOne}>
          Your students&apos; average personal deck questions due are currently
          high, but don&apos;t fret! Consistent review is much more important
          than the number of questions due.
        </Typography>
        <Typography variant="body1" className={classes.alertMessageTwo}>
          Consider creating a &lsquo;Personal Deck Assignment&rsquo; to
          incentivize your students to consistently review their personal deck
          questions! <HelpKitPersonalDeckAssignment />
        </Typography>
      </Alert>
    );
  };

  const currentDue = pdqData?.current;
  return (
    <div>
      {dueLoading || teacherLoading ? null : renderAlert()}
      <div className={classes.lineGraphContainer}>
        <div className={classes.lineGraphHeader}>
          <div>
            <Typography className={classes.graphTitle} variant="h6">
              Average Personal Deck Questions Due
            </Typography>
            {currentDue !== undefined ? (
              <Typography variant="h6" className={classes.currentlyDueText}>
                {`${currentDue} Currently Due`}
              </Typography>
            ) : (
              <span>Loading...</span>
            )}
          </div>
          <div>
            <Button
              startIcon={<Visibility />}
              variant="contained"
              color="primary"
              onClick={handleSeeMoreClick}
            >
              See More
            </Button>
          </div>
        </div>
        <LoadingOverlay loading={false}>
          <Line
            datasetKeyProvider={(d) => d.key}
            data={lineData}
            options={options}
            ref={chartRef}
          />
        </LoadingOverlay>
      </div>
    </div>
  );
}

export default AverageDueChart;
