type Group = {
  id: string;
  name: string;
};

export const sortGroups = (groups?: Group[]) => {
  return (
    groups
      ?.slice()
      .sort((a, b) =>
        a.name.trim().localeCompare(b.name.trim(), 'en', { numeric: true })
      ) || []
  );
};
