import { useQuery } from '@apollo/client';
import {
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { findDiff } from '@podsie/utils/string.js';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { selected } from '../../../../assets/shared-styles/MuiListItem';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { sortGroups } from '../../../../utils/lists';
import { AlertsContext } from '../../Alerts/context';
import { AddGroup } from './AddGroup';

const useStyles = makeStyles((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: selected(theme, parseInt(theme.spacing(4))),
}));

type ClassLinkProps = {
  name: string;
  id: string;
  handleDrawerToggle: () => void;
};

// https://stackoverflow.com/a/61393448
type MatchParams = {
  groupId: string;
};

export function ClassLink({ name, id, handleDrawerToggle }: ClassLinkProps) {
  const classes = useStyles();
  const match = useRouteMatch<MatchParams>('/classes/:groupId');
  const [classTabPath, setClassTabPath] = useState('');
  const location = useLocation();
  let to = `/classes/${id}`;
  // match.url only provides the base path, location.pathname provides full path
  const pathDiff = findDiff(match?.url || location.pathname, location.pathname);
  useEffect(() => {
    // because the class show page has tabs where the selected tab is determined
    // by the pathname, this logic here helps the teacher navigate to the same
    // tab when clicking to another tab
    if (
      match !== null &&
      location.pathname.startsWith(match.url) &&
      (pathDiff === '/assignments' ||
        pathDiff === '/personal-deck' ||
        pathDiff === '')
    ) {
      setClassTabPath(pathDiff);
    }
  }, [location.pathname, match, pathDiff]);

  if (classTabPath.length) {
    to = `${to}${classTabPath}`;
  }

  let selected = false;
  if (match && match.params) {
    selected = match.params.groupId === id;
  }

  return (
    <Link to={to}>
      <ListItem
        id="class-navigation"
        button
        onClick={handleDrawerToggle}
        classes={{ selected: classes.selected, root: classes.nested }}
        selected={selected}
      >
        <ListItemIcon>
          <Radio
            checked={selected}
            inputProps={{ 'aria-label': 'class link' }}
          />
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Link>
  );
}

export function GroupList({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void;
}) {
  const { dispatch } = useContext(AlertsContext);
  const { loading, data } = useQuery(TeacherDocument, {
    onError: onError(dispatch),
  });
  if (loading || !data) return <LinearProgress />;

  const activeCourse = data?.teacher.activeCourse;
  const groups = sortGroups(activeCourse?.groups);

  return (
    <List component="div" disablePadding>
      {(groups || []).map(({ name, id }) => {
        return (
          <ClassLink
            key={`class-${id}`}
            id={id}
            name={name}
            handleDrawerToggle={handleDrawerToggle}
          />
        );
      })}
      <AddGroup
        activeCourseId={activeCourse?.id || ''}
        handleDrawerToggle={handleDrawerToggle}
      />
    </List>
  );
}
