import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StrugglingQuestionsQueryVariables = Types.Exact<{
  sortField: Types.Scalars['String']['input'];
  sortDirection: Types.SortDirectionEnum;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type StrugglingQuestionsQuery = {
  __typename?: 'Query';
  questionsStats: {
    __typename?: 'QuestionsStatConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'QuestionsStatEdge';
      cursor: string;
      node?: {
        __typename?: 'QuestionsStat';
        numRecentCorrect?: number | null;
        numStudentsAttempted?: number | null;
        totalCorrect?: number | null;
        totalAttempts?: number | null;
        strugglingStudents: number;
        id: string;
        plainText: string;
        richText: any;
        questionType: Types.QuestionTypeEnum;
        variations?: Array<{
          __typename?: 'Question';
          richText: any;
          numAttemptsUntilAnswerShown: number;
          id: string;
          state: Types.QuestionStateEnum;
          plainText: string;
          questionType: Types.QuestionTypeEnum;
          copiedFromId?: string | null;
          variations?: Array<{ __typename?: 'Question'; id: string }> | null;
          freeResponseAnswer?: {
            __typename?: 'FreeResponseAnswer';
            id: string;
            text: string;
            plainText: string;
            richText: any;
            comparable: boolean;
            fraRequiredKeywords: Array<{
              __typename?: 'FraRequiredKeyword';
              id: string;
              keyword: string;
            }>;
          } | null;
          shortAnswerAnswers?: Array<{
            __typename?: 'ShortAnswerAnswer';
            id: string;
            isCaseSensitive: boolean;
            ignoreWhitespaces: boolean;
            text: string;
            plainText: string;
            richText: any;
            hidden: boolean;
          }> | null;
          multipleChoiceAnswerChoices?: Array<{
            __typename?: 'MultipleChoiceAnswerChoice';
            id: string;
            isCorrect: boolean;
            text: string;
            plainText: string;
            richText: any;
          }> | null;
          supplement?: {
            __typename?: 'Supplement';
            id: string;
            plainText: string;
            richText: any;
          } | null;
          standards?: Array<{
            __typename?: 'Standard';
            id: string;
            title: string;
            description: string;
          }> | null;
        }> | null;
        freeResponseAnswer?: {
          __typename?: 'FreeResponseAnswer';
          id: string;
          text: string;
          richText: any;
          comparable: boolean;
          fraRequiredKeywords: Array<{
            __typename?: 'FraRequiredKeyword';
            id: string;
            keyword: string;
          }>;
        } | null;
        shortAnswerAnswers?: Array<{
          __typename?: 'ShortAnswerAnswer';
          id: string;
          isCaseSensitive: boolean;
          ignoreWhitespaces: boolean;
          text: string;
        }> | null;
        multipleChoiceAnswerChoices?: Array<{
          __typename?: 'MultipleChoiceAnswerChoice';
          id: string;
          isCorrect: boolean;
          text: string;
          richText: any;
        }> | null;
        supplement?: {
          __typename?: 'Supplement';
          id: string;
          plainText: string;
          richText: any;
        } | null;
      } | null;
    } | null> | null;
  };
};

export type QuestionsStatsQueryVariables = Types.Exact<{
  plainText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sortDirection: Types.SortDirectionEnum;
  onlyShowQuestionsWithData?: Types.InputMaybe<
    Types.Scalars['Boolean']['input']
  >;
  onlyShowActiveQuestions?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  homepage?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  sortField: Types.Scalars['String']['input'];
  groupId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  last?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  before?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type QuestionsStatsQuery = {
  __typename?: 'Query';
  questionsStats: {
    __typename?: 'QuestionsStatConnection';
    totalCount: number;
    pageInfo: {
      __typename?: 'PageInfo';
      endCursor?: string | null;
      startCursor?: string | null;
    };
    edges?: Array<{
      __typename?: 'QuestionsStatEdge';
      cursor: string;
      node?: {
        __typename?: 'QuestionsStat';
        id: string;
        state: Types.QuestionStateEnum;
        plainText: string;
        richText: any;
        questionType: Types.QuestionTypeEnum;
        firstSeen?: any | null;
        totalCorrect?: number | null;
        totalAttempts?: number | null;
        numRecentCorrect?: number | null;
        numStudentsAttempted?: number | null;
        strugglingStudents: number;
        standards?: Array<{
          __typename?: 'Standard';
          id: string;
          title: string;
          description: string;
        }> | null;
      } | null;
    } | null> | null;
  };
};

export const StrugglingQuestionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'strugglingQuestions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortField' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortDirection' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SortDirectionEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionsStats' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortDirection' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortField' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortField' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'strugglingQuestionStatAttributes',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionStatQuestionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionsStat' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionIndexAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'questionType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'copiedFromId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'questionAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Question' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionIndexAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numAttemptsUntilAnswerShown' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'freeResponseAnswer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'comparable' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fraRequiredKeywords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'keyword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shortAnswerAnswers' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isCaseSensitive' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ignoreWhitespaces' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hidden' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'multipleChoiceAnswerChoices' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCorrect' } },
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supplement' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plainText' } },
                { kind: 'Field', name: { kind: 'Name', value: 'richText' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'strugglingQuestionStatAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'QuestionsStat' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'questionStatQuestionAttributes' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'numRecentCorrect' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'numStudentsAttempted' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'totalCorrect' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalAttempts' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'strugglingStudents' },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'questionAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StrugglingQuestionsQuery,
  StrugglingQuestionsQueryVariables
>;
export const QuestionsStatsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'questionsStats' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'plainText' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortDirection' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'SortDirectionEnum' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'onlyShowQuestionsWithData' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'onlyShowActiveQuestions' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'homepage' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sortField' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'last' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'before' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'questionsStats' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'plainText' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'plainText' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortDirection' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortDirection' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortField' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'sortField' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyShowQuestionsWithData' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'onlyShowQuestionsWithData' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'onlyShowActiveQuestions' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'onlyShowActiveQuestions' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'homepage' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'homepage' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'first' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'last' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'last' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'after' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'before' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'before' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endCursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startCursor' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cursor' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'state' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'plainText' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'richText' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'questionType' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'standards' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'title' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'description',
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'firstSeen' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalCorrect' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalAttempts' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'numRecentCorrect' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'numStudentsAttempted',
                              },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'strugglingStudents',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuestionsStatsQuery, QuestionsStatsQueryVariables>;
