import { fetchEventSource } from '@microsoft/fetch-event-source';
import { config } from '../podsie-config.js';

const API_URL = config.serverRaw;

function isAbortError(error: unknown): error is { name: string } {
  return (
    typeof error === 'object' &&
    error !== null &&
    (error as { name: string }).name === 'AbortError'
  );
}
export async function* readTextStream(
  endpoint: string,
  params?: Record<string, string>,
  signal?: AbortSignal
) {
  if (!API_URL) throw new Error(`fetchStream: REACT_APP_SERVER is not set`);
  const url = new URL(endpoint, API_URL);
  for (const [key, value] of Object.entries(params || {})) {
    url.searchParams.append(key, value);
  }
  let response: Response;
  try {
    response = await fetch(url.toString(), { signal });
  } catch (err) {
    if (isAbortError(err)) {
      return;
    }
    throw err; // Unhandled Exceptions
  }
  if (!response.ok)
    throw new Error(
      `fetchStream: http error: ${response.status}, ${response.statusText}`
    );
  if (!response.body) throw new Error(`fetchStream: response has no body`);
  const reader = response.body.getReader();
  const decoder = new TextDecoder('utf-8');

  while (true) {
    try {
      const { done, value } = await reader.read();
      if (done) break;
      yield decoder.decode(value, { stream: true });
    } catch (err) {
      if (isAbortError(err)) {
        return;
      }
      throw err;
    }
  }
}

// remove the "/graphql" from end of the url:
const serverUrl = API_URL?.replace(/\/graphql$/, '') || '';

export async function streamTokens({
  url,
  updaterFunc,
  params,
  signal,
}: {
  url: string;
  updaterFunc: (text: string) => void;
  params?: unknown;
  signal?: AbortSignal;
}) {
  if (!serverUrl) throw new Error(`fetchStream: server url is not set`);

  await fetchEventSource(`${serverUrl}/api/v1${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    openWhenHidden: true,
    body: JSON.stringify(params),
    onmessage(ev) {
      let token = ev.data;
      console.log(token);
      switch (ev.data) {
        case '':
          token = '\n';
          break;
        default:
          break;
      }
      updaterFunc(token);
    },
    onerror(err) {
      console.error(err);
    },
    signal,
  });
}
