/*global: global, process, window*/
import { isUnknownRecord } from '@podsie/utils/object.js';

/**
 * @typedef PodsieConfig
 * @property {Auth0Config} auth0
 * @property {string} env
 *   ```
 *   "development" | "production" | "test"
 *   ```
 *
 * @property {string} officialStandardsChartId
 * @property {string} [publicURL]
 * @property {SentryConfig} [sentry]
 * @property {string} [server]
 * @property {string} [serverBackup]
 * @property {string} serverRaw
 * @property {string} serverBackupRaw
 * @property {UserflowConfig} userflow
 * @property {string} [websocketServer]
 * @property {string} [websocketServerBackup]
 */

/**
 * @typedef Auth0Config
 * @property {string} audience
 * @property {string} clientId
 * @property {string} domain
 */

/**
 * @typedef SentryConfig
 * @property {string} dsn
 *   [Data Source Name (DSN)](https://docs.sentry.io/product/sentry-basics/concepts/dsn-explainer/)
 * that allows Sentry to associate reported events with the appropriate project.
 */

/**
 * @typedef UserflowConfig
 * @property {string} token
 *   Token used for authentication with Userflow
 */

/**
 * Infer a {@link PodsieConfig} from the `Bun` global
 *
 * @returns {PodsieConfig}
 * @throws {Error} if `Bun.env` is falsy
 * @see {@link PodsieConfig}
 */
function fromBunEnv() {
  const Bun = /** @type {any} */ (global).Bun;
  if (!Bun || !Bun.env) {
    throw new Error(`No env available in Bun.env. Are you using Bun?`);
  }

  return {
    auth0: {
      audience: Bun.env.AUTH_0_AUDIENCE ?? '',
      clientId: Bun.env.AUTH_0_CLIENT_ID ?? '',
      domain: Bun.env.AUTH_0_DOMAIN ?? '',
    },
    // TODO: Find some other way to infer if needed or have it specified
    env: 'development',
    officialStandardsChartId: Bun.env.OFFICIAL_STANDARDS_CHART_ID ?? '',
    sentry: Bun.env.SENTRY_DSN ? { dsn: Bun.env.SENTRY_DSN } : undefined,
    server: Bun.env.SERVER,
    serverBackup: Bun.env.SERVER_BACKUP,
    serverBackupRaw: Bun.env.SERVER_BACKUP_RAW ?? '',
    serverRaw: Bun.env.SERVER_RAW ?? '',
    userflow: { token: Bun.env.USERFLOW_TOKEN ?? '' },
    websocketServer: Bun.env.WEBSOCKET_SERVER ?? '',
    websocketServerBackup: Bun.env.WEBSOCKET_BACKUP_SERVER ?? '',
  };
}

/**
 * Infer a {@link PodsieConfig} from the `process` global
 *
 * @returns {PodsieConfig}
 * @throws {Error} if `process.env` is falsy
 * @see {@link PodsieConfig}
 */
function fromNodeEnv() {
  if (!process || !process.env) {
    throw new Error(
      `No env available process.env. Are you using Node under CommonJS?`
    );
  }

  return {
    auth0: {
      audience: process.env.REACT_APP_AUTH_0_AUDIENCE ?? '',
      clientId: process.env.REACT_APP_AUTH_0_CLIENT_ID ?? '',
      domain: process.env.REACT_APP_AUTH_0_DOMAIN ?? '',
    },
    env: process.env.NODE_ENV ?? 'development',
    officialStandardsChartId:
      process.env.REACT_APP_OFFICIAL_STANDARDS_CHART_ID ?? '',
    publicURL: process.env.PUBLIC_URL,
    sentry: process.env.REACT_APP_SENTRY_DSN
      ? { dsn: process.env.REACT_APP_SENTRY_DSN }
      : undefined,
    server: process.env.REACT_APP_SERVER,
    serverBackup: process.env.REACT_APP_SERVER_BACKUP,
    serverBackupRaw: process.env.REACT_APP_SERVER_BACKUP_RAW ?? '',
    serverRaw: process.env.REACT_APP_SERVER_RAW ?? '',
    userflow: { token: process.env.REACT_APP_USERFLOW_TOKEN ?? '' },
    websocketServer: process.env.REACT_APP_WEBSOCKET_SERVER ?? '',
    websocketServerBackup: process.env.REACT_APP_WEBSOCKET_BACKUP_SERVER ?? '',
  };
}

/**
 * Infer a {@link PodsieConfig} from the `import.meta` global
 *
 * @returns {PodsieConfig}
 * @throws {Error} if `import.meta.env` is falsy
 * @see {@link PodsieConfig}
 */
function fromViteEnv() {
  if (!isUnknownRecord(import.meta) || !isUnknownRecord(import.meta.env)) {
    throw new Error(`No env available in import.meta. Are you using Vite?`);
  }

  return {
    auth0: {
      audience: import.meta.env.VITE_AUTH_0_AUDIENCE ?? '',
      clientId: import.meta.env.VITE_AUTH_0_CLIENT_ID ?? '',
      domain: import.meta.env.VITE_AUTH_0_DOMAIN ?? '',
    },
    env: import.meta.env.MODE?.toLocaleLowerCase() ?? 'development',
    officialStandardsChartId:
      import.meta.env.VITE_OFFICIAL_STANDARDS_CHART_ID ?? '',
    sentry: import.meta.env.VITE_SENTRY_DSN
      ? { dsn: import.meta.env.VITE_SENTRY_DSN }
      : undefined,
    server: import.meta.env.VITE_SERVER,
    serverBackup: import.meta.env.VITE_SERVER_BACKUP,
    serverBackupRaw: import.meta.env.VITE_SERVER_BACKUP_RAW ?? '',
    serverRaw: import.meta.env.VITE_SERVER_RAW ?? '',
    userflow: { token: import.meta.env.VITE_USERFLOW_TOKEN ?? '' },
    websocketServer: import.meta.env.VITE_WEBSOCKET_SERVER ?? '',
    websocketServerBackup: import.meta.env.VITE_WEBSOCKET_BACKUP_SERVER ?? '',
  };
}

/**
 *
 * @returns {PodsieConfig}
 */
function inferFromGlobals() {
  /** @type {string | undefined} */
  let source;
  /** @type {PodsieConfig | undefined} */
  let inferred;

  const Bun = /** @type {any} */ (global).Bun;

  if (import.meta && import.meta.env) {
    source = ' from Vite global env';
    inferred = fromViteEnv();
  } else if (Bun && Bun.env) {
    source = ' from Bun global env';
    inferred = fromBunEnv();
  } else if (process && process.env) {
    source = ' from Node global env';
    inferred = fromNodeEnv();
  } else {
    throw new Error(
      `Could not infer configuration from globals available in the current runtime.`
    );
  }

  inferred.env === 'development' &&
    console.debug(`Inferred PodsieConfig${source}: `, inferred);
  return inferred;
}

/** @satisfies {PodsieConfig} */
export const config = inferFromGlobals();
