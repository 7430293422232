import { useQuery } from '@apollo/client';
import { Settings } from '@mui/icons-material';
import { Button, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssignmentDocument } from '../../../../../../gql/queries/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../../../../gql/types';
import { onError } from '../../../../../../utils/apollo/apolloHelper';
import { RocketIcon } from '../../../../../shared/CustomIcons/RocketIcon';
import { AlertsContext } from '../../../../Alerts/context';
import { LaunchDialog } from './LaunchDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    fill: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
  },
}));

export function LaunchButton() {
  const { assignmentId } = useParams<{ assignmentId: string }>();
  const { dispatch } = useContext(AlertsContext);
  const classes = useStyles();
  const { data: assignmentQueryData } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });

  const [open, setOpen] = useState(false);
  const assignmentStatus = assignmentQueryData?.assignment.assignmentStatus;
  const pending = assignmentStatus === AssignmentStatusEnum.Pending;
  const handleOpen = () => setOpen(true);
  const button = () => {
    if (pending) {
      return (
        <Button
          startIcon={<RocketIcon className={classes.icon} />}
          variant="contained"
          color="secondary"
          aria-label="Launch Assignment"
          className={classes.button}
          onClick={handleOpen}
        >
          Launch Settings
        </Button>
      );
    }
    return (
      <Button
        startIcon={<Settings className={classes.icon} />}
        variant="contained"
        color="secondary"
        aria-label="Assignment Settings"
        className={classes.button}
        onClick={handleOpen}
      >
        Settings
      </Button>
    );
  };
  return (
    <div className={classes.root}>
      {button()}
      <LaunchDialog open={open} setOpen={setOpen} />
    </div>
  );
}
