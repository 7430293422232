import { HelpOutlineOutlined, RocketLaunch } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useEffect, type ComponentPropsWithRef } from 'react';
import { default as userflow } from 'userflow.js';
import { useUserflowResourceCenterState } from './hooks';

type ResourceCenterButtonProps = ComponentPropsWithRef<typeof Button>;

/**
 * This button displays a 'Getting Started' or 'Help' button to show or hide the
 * appropriate modal of Userflow.
 *
 * This component inherits from {@link Button}.
 *
 * @note This automatically provides an `onClick` handler to the underlying
 *   `Button` to toggle the display of the resource center. Providing your own
 *   `onClick` will require **YOU** to handle the resource center display on
 *   your own.
 */
export function ResourceCenterButton(props: ResourceCenterButtonProps) {
  // Monitor resource center state so we can display number of uncompleted tasks
  const state = useUserflowResourceCenterState();

  useEffect(() => {
    // We assume here that state being `null` means the Userflow has not been
    // initialized or otherwise can't be used since the documentation[0] is
    // ambiguous (e.g.: "there's no active resource center")
    // [0]: https://userflow.com/docs/userflow-js#getresourcecenterstate
    if (!state) return;

    userflow.setResourceCenterLauncherHidden(true);
  }, [state]);

  // Don't render the button if there's no state available because we can't make
  // a determination of what to render here in such a case.
  if (!state) return null;

  return state.uncompletedChecklistTaskCount > 0 ? (
    <Button
      onClick={userflow.toggleResourceCenter}
      startIcon={<RocketLaunch />}
      variant="outlined"
      {...props}
    >
      Getting Started
    </Button>
  ) : (
    <Button
      onClick={userflow.toggleResourceCenter}
      startIcon={<HelpOutlineOutlined />}
      variant="outlined"
      {...props}
    >
      Help
    </Button>
  );
}
