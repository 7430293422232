import { HTMLProps } from 'react';

type EmojiProps = {
  symbol: string;
  label?: string;
};

export function Emoji({
  symbol,
  label,
  ...props
}: EmojiProps & HTMLProps<HTMLSpanElement>) {
  return (
    <span
      {...props}
      role="img"
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
    >
      {symbol}
    </span>
  );
}

export default Emoji;
