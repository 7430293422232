import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateScheduledFreezeMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  start: Types.Scalars['ISO8601DateTime']['input'];
  finish: Types.Scalars['ISO8601DateTime']['input'];
  groupIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;

export type CreateScheduledFreezeMutation = {
  __typename?: 'Mutation';
  createScheduledFreeze: {
    __typename?: 'ScheduledFreeze';
    id: string;
    name?: string | null;
    start: any;
    finish: any;
    groups: Array<{ __typename?: 'Group'; id: string; name: string }>;
  };
};

export type UpdateScheduledFreezeMutationVariables = Types.Exact<{
  scheduledFreezeId: Types.Scalars['ID']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  start?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
  finish?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
  groupIds?: Types.InputMaybe<
    Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
  >;
}>;

export type UpdateScheduledFreezeMutation = {
  __typename?: 'Mutation';
  updateScheduledFreeze: {
    __typename?: 'ScheduledFreeze';
    id: string;
    name?: string | null;
    start: any;
    finish: any;
    groups: Array<{ __typename?: 'Group'; id: string; name: string }>;
  };
};

export type DeleteScheduledFreezeMutationVariables = Types.Exact<{
  scheduledFreezeId: Types.Scalars['ID']['input'];
}>;

export type DeleteScheduledFreezeMutation = {
  __typename?: 'Mutation';
  deleteScheduledFreeze: number;
};

export const CreateScheduledFreezeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createScheduledFreeze' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'finish' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ISO8601DateTime' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ID' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createScheduledFreeze' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'finish' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'finish' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'scheduledFreezeAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'scheduledFreezeAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ScheduledFreeze' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finish' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateScheduledFreezeMutation,
  CreateScheduledFreezeMutationVariables
>;
export const UpdateScheduledFreezeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateScheduledFreeze' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'scheduledFreezeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'finish' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupIds' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateScheduledFreeze' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scheduledFreezeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'scheduledFreezeId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'finish' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'finish' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'scheduledFreezeAttributes' },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'scheduledFreezeAttributes' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ScheduledFreeze' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'start' } },
          { kind: 'Field', name: { kind: 'Name', value: 'finish' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'groups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateScheduledFreezeMutation,
  UpdateScheduledFreezeMutationVariables
>;
export const DeleteScheduledFreezeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteScheduledFreeze' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'scheduledFreezeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteScheduledFreeze' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scheduledFreezeId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'scheduledFreezeId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteScheduledFreezeMutation,
  DeleteScheduledFreezeMutationVariables
>;
