import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { QuestionAttributesFragment } from '../../../../../../gql/fragments/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../../../gql/types';
import { BreakdownDetailsResponses } from '../../../../Groups/Assignments/BreakdownDetailsResponses';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px dotted ${theme.palette.secondary.main}`,
    marginTop: theme.spacing(1),
  },
  expandedSection: {
    padding: theme.spacing(2, 0, 1, 0),
  },
  expandedSectionTitle: {
    textDecoration: 'underline',
  },
}));

type BreakdownDetailsProps = {
  question: QuestionAttributesFragment;
  open: boolean;
};

export function BreakdownDetails({ question, open }: BreakdownDetailsProps) {
  const classes = useStyles();
  const content =
    question.questionType === QuestionTypeEnum.FreeResponse ||
    question.questionType === QuestionTypeEnum.ShortAnswer ? (
      <>
        <Typography variant="h5" className={classes.expandedSectionTitle}>
          Student Responses
        </Typography>
        <BreakdownDetailsResponses question={question} />
      </>
    ) : (
      <div>
        This is only available for Free Response and Short Answer questions.
      </div>
    );

  if (open) {
    return (
      <div className={classes.root}>
        <div className={classes.expandedSection}>{content}</div>
      </div>
    );
  }

  return null;
}
