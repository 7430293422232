import { useMutation } from '@apollo/client';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useContext } from 'react';
import { QuestionAttributesFragment } from '../../../../../gql/fragments/__generated__/question.generated';
import { UpdateQuestionDocument } from '../../../../../gql/mutations/__generated__/question.generated';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { CustomTooltip } from '../../../../shared/CustomTooltip';
import { AlertsContext } from '../../../Alerts/context';
import { DeepPartial, updateQuestionFields } from '../questions';

export function FreeResponseAnswerAdvancedSetting({
  question,
}: {
  question: QuestionAttributesFragment;
}) {
  const { dispatch } = useContext(AlertsContext);
  const [updateQuestion] = useMutation(UpdateQuestionDocument, {
    onError: onError(dispatch),
  });
  const toggleFreeResponseCompare = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const changes: DeepPartial<QuestionAttributesFragment> = {
      freeResponseAnswer: {
        comparable: event.target.checked,
      },
    };

    const questionInput = updateQuestionFields(question, changes);
    updateQuestion({ variables: { question: questionInput } });
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        control={
          <Checkbox
            checked={question.freeResponseAnswer?.comparable}
            color="secondary"
            onChange={toggleFreeResponseCompare}
          />
        }
        sx={{ mr: 1 }}
        label="Flag responses"
      />
      <CustomTooltip title="Use AI to flag when student may have inaccurately self-assessed their free response answers.">
        <InfoOutlined />
      </CustomTooltip>
    </Box>
  );
}
