import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { toHTML } from '@podsie/utils/string.js';
import type { KatexOptions } from 'katex';
import type { DeltaOperation } from 'quill';
import { QuillDeltaToHtmlConverter, type GroupType } from 'quill-delta-to-html';
import { forwardRef, type ComponentPropsWithoutRef } from 'react';

type StyleProps = { multipleChoice?: boolean };

const defaultKatexOptions: KatexOptions = { throwOnError: false };
function renderKatexFromHTML(_: GroupType, html: string): string {
  const containerElement = toHTML(html);

  // properly render katex formulas:
  const formulaNodes = containerElement.querySelectorAll('.ql-formula');
  const nodeListLength = formulaNodes.length;
  for (let i = 0; i < nodeListLength; i += 1) {
    // rendering formula.textContent instead of formula.innerHTML
    // because innerHTML displays inequality symbols as html entities:
    // NOTE: Asserting the formula node is an `HTMLElement` is acceptable here
    //       because the `render` imlpementation[0] only requires a `Node` for
    //       `appendChild()` and `textContent` which an `Element` has.
    // [0]: https://github.com/KaTeX/KaTeX/blob/4f1d9166749ca4bd669381b84b45589f1500a476/katex.js#L41C11-L41C12
    window.katex.render(
      formulaNodes[i].textContent ?? '',
      formulaNodes[i] as HTMLElement,
      defaultKatexOptions
    );
  }

  return containerElement.innerHTML;
}

/**
 * Convert the given `operations` to an HTML string supporting KaTeX.
 *
 * @see {@link DeltaOperation}
 */
// TODO: Move this to Quill helpers to facilitate independent unit testing given
//       how core this is to the application.
function quillDeltaOperationsToHTMLString(
  operations: DeltaOperation[]
): string {
  const converter = new QuillDeltaToHtmlConverter(operations);
  converter.afterRender(renderKatexFromHTML);
  return converter.convert();
}

const useStyles = makeStyles((theme: Theme) => ({
  html: {
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    borderRadius: 4,
    padding: theme.spacing(1),
    color: 'black',
    whiteSpace: 'pre-wrap',
    background: ({ multipleChoice }: StyleProps) =>
      multipleChoice ? 'inherit' : theme.palette.common.white,
    '& img': {
      margin: '0 auto',
      display: 'inherit',
      maxWidth: '100%',
    },
    '& pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
    },
    '& .katex': {
      position: 'relative',
    },
    '& p': {
      margin: ({ multipleChoice }: StyleProps) => (multipleChoice ? 0 : 'auto'),
    },
  },
}));

type QuillDeltaAsHtmlProps = {
  delta: DeltaOperation[];
  multipleChoice?: boolean;
} & ComponentPropsWithoutRef<'div'>;

export const QuillDeltaAsHtml = forwardRef<
  HTMLDivElement,
  QuillDeltaAsHtmlProps
>(({ delta, multipleChoice, ...props }: QuillDeltaAsHtmlProps, ref) => {
  const classes = useStyles({ multipleChoice });

  // TODO: If the given `delta` props are are treated _immutably_ and not
  //       modified via methods like `push` or `splice`, we should cache this
  //       computation with `useMemo` since the conversion is expensive by
  //       converting a potentially large payload to HTML elements, serializing
  //       that to a string and then passing it back to React only to convert it
  //       back to HTML elements again.
  const deltaHTMLString = quillDeltaOperationsToHTMLString(delta);

  return (
    <div
      {...props}
      ref={ref}
      style={
        multipleChoice ? { padding: '2px 4px', ...props.style } : props.style
      }
      className={`${classes.html} ${props.className ? props.className : ''}`}
      dangerouslySetInnerHTML={{ __html: deltaHTMLString }}
    />
  );
});

QuillDeltaAsHtml.displayName = 'QuillDeltaAsHtml';
