import { useContext } from 'react';
import { AssignmentEditorContext } from './context';

export function SaveStatus() {
  const {
    assignmentEditor: { ui },
  } = useContext(AssignmentEditorContext);

  let status = '';

  if (ui.loading) {
    status = 'Saving Changes...';
  } else if (ui.saved === false) {
    status = 'Unsaved Changes.';
  } else if (ui.saved) {
    status = 'Changes Saved.';
  }

  return <span>{status}</span>;
}
