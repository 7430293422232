import { useState } from 'react';

const useStorage = <T>(
  storageType: 'localStorage' | 'sessionStorage',
  key: string,
  initialValue: T
) => {
  const storage =
    storageType === 'localStorage'
      ? window.localStorage
      : window.sessionStorage;

  // use a function to set the initial value so that it is only called once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // get from local storage by key
      const item = storage.getItem(key);
      // parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  // return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      storage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
};

// useLocalStorage persists state in localStorage, which is cleared
// only when the user clears their browser cache:
export const useLocalStorage = <T>(key: string, initialValue: T) => {
  return useStorage('localStorage', key, initialValue);
};

// useSessionStorage persists state in sessionStorage, which is cleared
// when the browser tab is closed.
export const useSessionStorage = <T>(key: string, initialValue: T) => {
  return useStorage('sessionStorage', key, initialValue);
};
