import { Error } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Sources } from 'quill';
import { useRef, useState } from 'react';
import ReactQuill, { UnprivilegedEditor, type Value } from 'react-quill';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import {
  containsImages,
  getPlainTextFromDelta,
} from '../../../utils/quillHelper';
import { streamTokens } from '../../../utils/stream';
import HelpKitExplanation from '../../application/HelpKitArticles/HelpKitExplanation';
import Editor from '../Editor';

const useStyles = makeStyles((theme: Theme) => ({
  required: {
    color: theme.palette.accent.main,
    marginLeft: theme.spacing(0.5),
  },
  fieldList: {
    overflow: 'auto',
    height: '85%',
    position: 'relative',
    listStyle: 'none',
    counterReset: 'custom-counter',
    paddingLeft: 0,
    paddingRight: theme.spacing(1),
  },
  wrapper: {
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  buttonLink: {
    ...buttonLink(theme),
    marginLeft: theme.spacing(1),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  label: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  },
  caption: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  inlineProgressSuffix: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  warningIcon: {
    fill: theme.palette.error.dark,
    marginLeft: theme.spacing(0.5),
  },
  explanationContainer: {
    marginLeft: theme.spacing(0.5),
  },
}));

type ExplanationProps = {
  numAttemptsNumber: number;
  supplement: ReactQuill.Value;
  question: ReactQuill.Value;
  answer: string;
  questionType: string;
  onUpdatedExplanation: (value: string | ReactQuill.Value) => void;
  id?: string;
};

export function Explanation({
  numAttemptsNumber,
  supplement,
  question,
  answer,
  questionType,
  onUpdatedExplanation,
}: ExplanationProps) {
  const classes = useStyles();
  const editorRef = useRef<ReactQuill>(null);
  // using setSuggestedExplanation to keep track of explanation as it streams
  // but don't actually need to use the state itself:
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSuggestedExplanation] = useState<string>('');
  const [isStreamingExplanation, setIsStreamingExplanation] =
    useState<boolean>(false);
  const [streamingAbortController, setStreamingAbortController] =
    useState<AbortController>(new AbortController());
  const cancelStream = () => {
    setIsStreamingExplanation(false);
    streamingAbortController.abort();
    editorRef.current?.editor?.enable(true);
  };

  const questionText = getPlainTextFromDelta(question).trim();

  const beginStream = async () => {
    const editor = editorRef.current?.editor;
    if (!editor) return;
    const abortController = new AbortController();
    setStreamingAbortController(abortController);
    setSuggestedExplanation('');
    setIsStreamingExplanation(true);
    editor.enable(false);
    await streamTokens({
      url: '/question_creation/explain',
      updaterFunc: (token) => {
        setSuggestedExplanation((prevExplanation) => {
          const updated = prevExplanation + token;
          onUpdatedExplanation(updated);
          return updated;
        });
      },
      params: {
        question: questionText,
        answer: answer,
        questionType: questionType,
      },
      signal: abortController.signal,
    });
    setIsStreamingExplanation(false);
    editor.enable(true);
  };
  const handleSupplementChange = (
    value: string,
    delta: Value,
    source: Sources,
    editor: UnprivilegedEditor
  ) => {
    onUpdatedExplanation(editor.getContents());
  };

  const hasImages = containsImages(question);
  const disabled = hasImages || !questionText;

  const generateButton = () => {
    return (
      <>
        <Button
          className={classes.marginLeft}
          onClick={isStreamingExplanation ? cancelStream : beginStream}
          variant="contained"
          size="small"
          disabled={disabled}
          color="primary"
          endIcon={
            isStreamingExplanation ? (
              <CircularProgress
                color="secondary"
                className={classes.inlineProgressSuffix}
                size={16}
              />
            ) : null
          }
        >
          {isStreamingExplanation ? 'Cancel' : 'AI Generate'}
        </Button>
        {hasImages && (
          <Tooltip title="The AI Generate feature cannot process images, so it has been disabled because this question has an image.">
            <Error className={classes.warningIcon} />
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <div className={classes.wrapper}>
      <label className={classes.label}>
        <div className={classes.flex}>
          <Typography variant="h4" color="primary">
            Explanation
            {numAttemptsNumber <= 1 && <span>(optional)</span>}
          </Typography>
          {generateButton()}

          <div className={classes.explanationContainer}>
            <HelpKitExplanation />
          </div>
        </div>
        {numAttemptsNumber > 1 && <span className={classes.required}>*</span>}
      </label>
      <Typography variant="caption" className={classes.caption}>
        Enter additional notes around the question topic for students to learn
        deeper insights after they attempt the question.
      </Typography>
      <Editor
        customRef={editorRef}
        value={supplement}
        onChange={handleSupplementChange}
      />
    </div>
  );
}

export default Explanation;
