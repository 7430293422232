import HelpKitButton from './HelpKitButton';

export function HelpKitStandardsProgress() {
  return (
    <HelpKitButton
      label="Understanding the Standard Progress table"
      articleId="aFBJTHoDpRWkqUHBatUw8z"
    />
  );
}
export default HelpKitStandardsProgress;
