import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { RouteComponentProps } from 'react-router-dom';
import { useTrackVisit } from '../../../../utils/hooks/useTrackVisit';
import { CustomGroupsAssignmentsTableSearch } from './CustomGroupsAssignmentsTableSearch';
import { GroupsAssignmentsTable } from './GroupsAssgnmentsTable';
import { GroupsAssignmentsTableSearchProvider } from './GroupsAssignmentsTableSearchContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  search: {
    alignSelf: 'flex-end',
    paddingRight: theme.spacing(2),
  },
}));

export const PAGE_SIZE = 15;

type GroupAssignmentsProps = {
  groupId: string;
};

export function GroupAssignments(
  props: GroupAssignmentsProps & RouteComponentProps
) {
  useTrackVisit({
    section: 'class',
    page: 'assignments',
  });
  const classes = useStyles();

  return (
    <div>
      <GroupsAssignmentsTableSearchProvider>
        <div className={classes.root}>
          <div className={classes.search}>
            <CustomGroupsAssignmentsTableSearch />
          </div>
          <GroupsAssignmentsTable {...props} />
        </div>
      </GroupsAssignmentsTableSearchProvider>
    </div>
  );
}

export default GroupAssignments;
