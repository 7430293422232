import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useContext, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  CreateGroupsAssignmentDocument,
  DeleteGroupsAssignmentDocument,
  UpdateGroupsAssignmentDocument,
} from '../../../../../gql/mutations/__generated__/groupsAssignment.generated';
import { AssignmentDocument } from '../../../../../gql/queries/__generated__/assignment.generated';
import { TeacherDocument } from '../../../../../gql/queries/__generated__/teacher.generated';
import { AssignmentStatusEnum } from '../../../../../gql/types';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { getNow, getTomorrowAtMidnight } from '../../../../../utils/dates';
import { CustomTooltip } from '../../../../shared/CustomTooltip';
import { AlertsContext } from '../../../Alerts/context';
import { AssignmentEditorContext } from '../context';
import {
  createGroupsAssignmentAction,
  deleteGroupsAssignmentAction,
  onAssignmentEditorSaveSuccess,
  startAssignmentEditorLoading,
  updateGroupsAssignmentAction,
} from '../context/actions';

const sharedStyles = (theme: Theme) => ({
  display: 'flex',
  flexFlow: 'column',
  marginBottom: theme.spacing(2),
  width: '100%',
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    padding: 0,
    border: 'none',
    marginTop: 0,
    marginBottom: 0,
    alignItems: 'flex-end',
    height: 70,
    flexFlow: 'row',
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  buttonsGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  individualAssignmentToggle: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  confirmAssignButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
  },
  customTooltipPopper: {
    // zIndex: 2000,
  },
  selectAllPopup: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2),
  },
  selectAllButton: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  selectAllContainer: {
    ...sharedStyles(theme),
    alignItems: 'center',
  },
  group: {
    ...sharedStyles(theme),
  },
  timeFilter: {
    marginRight: '16px',
  },
  setAllPicker: {
    marginBottom: theme.spacing(2),
  },
  setAllPickerPopper: {
    zIndex: 1501,
  },
  launchDatePicker: {
    marginRight: theme.spacing(1),
  },
}));

const tomorrowAtMidnight = getTomorrowAtMidnight();
const nowDate = getNow();

export function StandardAssignGroups() {
  const classes = useStyles();
  const [dueDate, setDueDate] = useState(tomorrowAtMidnight);
  const [launchDate, setLaunchDate] = useState(nowDate);
  const [selectAllOpen, setSelectAllOpen] = useState(false);
  const {
    dispatch: assignmentDispatch,
    assignmentEditor: { id: assignmentId, groupsAssignments },
  } = useContext(AssignmentEditorContext);

  const { dispatch } = useContext(AlertsContext);
  const { data } = useQuery(TeacherDocument, {
    onError: onError(dispatch),
  });
  const [updateGroupsAssignment] = useMutation(UpdateGroupsAssignmentDocument, {
    onError: onError(dispatch),
    onCompleted: () => {
      onAssignmentEditorSaveSuccess(assignmentDispatch);
    },
  });
  const [createGroupsAssignment, { loading: createLoading }] = useMutation(
    CreateGroupsAssignmentDocument,
    {
      onError: onError(dispatch),
      onCompleted: (data) => {
        const {
          id,
          dueDate,
          launchDate,
          group: { id: groupId },
        } = data.createGroupsAssignment;
        assignmentDispatch(
          createGroupsAssignmentAction({
            groupsAssignmentId: id,
            groupId,
            dueDate: dueDate ? new Date(dueDate) : undefined,
            launchDate: launchDate ? new Date(launchDate) : undefined,
          })
        );

        onAssignmentEditorSaveSuccess(assignmentDispatch);
      },
    }
  );
  const { data: assignmentData } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });

  const [deleteGroupsAssignment, { loading: deleteLoading }] = useMutation(
    DeleteGroupsAssignmentDocument,
    {
      onError: onError(dispatch),
      onCompleted: (data) => {
        const groupId = data.deleteGroupsAssignment.groupId;
        assignmentDispatch(deleteGroupsAssignmentAction({ groupId }));
        onAssignmentEditorSaveSuccess(assignmentDispatch);
      },
    }
  );

  const debouncedUpdateGroupsAssignment = useDebouncedCallback(
    (
      groupsAssignmentId: string,
      dueDate: Date | null,
      launchDate: Date | null
    ) => {
      if (groupsAssignmentId)
        updateGroupsAssignment({
          variables: {
            groupsAssignmentId,
            dueDate: dueDate ? dueDate.toISOString() : null,
            launchDate: launchDate ? launchDate.toISOString() : null,
          },
        });
    },
    750
  );

  const groups = data?.teacher.activeCourse?.groups || [];

  const handleCheckboxChange =
    (groupId: string, groupsAssignmentId?: string) => () => {
      startAssignmentEditorLoading(assignmentDispatch);
      if (groupsAssignments[groupId] && groupsAssignmentId) {
        deleteGroupsAssignment({
          variables: { groupsAssignmentId },
        });
      } else {
        createGroupsAssignment({
          variables: {
            groupId,
            assignmentId,
            dueDate: dueDate,
            launchDate: launchDate,
          },
        });
      }
    };

  const handleSingleDueDateChange = (
    groupId: string,
    groupsAssignmentId?: string,
    bulk?: boolean
  ) => {
    return (due: Date | null) => {
      const updateDate = handleDateChange(groupId, groupsAssignmentId, bulk);
      const ga = groupsAssignments[groupId];
      updateDate(due, ga.launchDate || null);
    };
  };

  const handleSingleLaunchDateChange = (
    groupId: string,
    groupsAssignmentId?: string,
    bulk?: boolean
  ) => {
    return (launch: Date | null) => {
      const updateDate = handleDateChange(groupId, groupsAssignmentId, bulk);
      const ga = groupsAssignments[groupId];
      updateDate(ga?.dueDate || null, launch);
    };
  };

  const handleDateChange = (
    groupId: string,
    groupsAssignmentId?: string,
    bulk?: boolean
  ) => {
    return (due: Date | null, launch: Date | null) => {
      if (!groupsAssignmentId) {
        return;
      }
      due = !due || isNaN(due.getTime()) ? null : due;
      launch = !launch || isNaN(launch.getTime()) ? null : launch;
      // https://www.geeksforgeeks.org/how-to-check-a-date-is-valid-or-not-using-javascript/
      if (!launch || isNaN(launch.getTime()) || !due || isNaN(due.getTime())) {
        handleNilUpdate(groupId, groupsAssignmentId, due, launch);
        return;
      }

      assignmentDispatch(
        updateGroupsAssignmentAction({
          groupId,
          dueDate: due,
          launchDate: launch,
        })
      );

      startAssignmentEditorLoading(assignmentDispatch);
      if (bulk) {
        // don't want to debounce on bulk updates because then some of the
        // classes' updates will get debounced and not happen:
        updateGroupsAssignment({
          variables: {
            groupsAssignmentId,
            dueDate: dueDate.toISOString(),
            launchDate: launchDate.toISOString(),
          },
        });
      } else {
        debouncedUpdateGroupsAssignment(groupsAssignmentId, due, launch);
      }
    };
  };
  const handleNilUpdate = (
    groupId: string,
    groupsAssignmentId: string,
    dueDate: Date | null,
    launchDate: Date | null
  ) => {
    assignmentDispatch(
      updateGroupsAssignmentAction({
        groupId,
        dueDate: dueDate === null ? undefined : dueDate,
        launchDate: launchDate === null ? undefined : launchDate,
      })
    );
    startAssignmentEditorLoading(assignmentDispatch);
    debouncedUpdateGroupsAssignment(groupsAssignmentId, dueDate, launchDate);
  };

  const selectAllClasses = async () => {
    const promises: Promise<unknown>[] = [];
    groups.forEach((group) => {
      if (groupsAssignments[group.id]) {
        const updateDate = handleDateChange(
          group.id,
          groupsAssignments[group.id].id,
          true
        );
        updateDate(dueDate, launchDate);
      } else {
        promises.push(
          new Promise<void>((resolve) => {
            createGroupsAssignment({
              variables: {
                groupId: group.id,
                assignmentId,
                dueDate: dueDate,
                launchDate: launchDate,
              },
            }).then(() => resolve());
          })
        );
      }
    });
    if (promises.length) {
      await Promise.all(promises);
    }
    closeSelectAll();
  };
  const closeSelectAll = () => setSelectAllOpen(false);
  const openSelectAll = () => setSelectAllOpen(true);
  const handleDueDateChange = (dueDate: Date | null) => {
    if (!dueDate) {
      return;
    }
    setDueDate(dueDate);
  };

  const handleLaunchDateChange = (
    value: unknown,
    keyboardInputValue?: unknown
  ) => {
    const launchDate = value;

    console.log('launchDate: ', launchDate);
    console.log('keyboardInputValue: ', keyboardInputValue);
    // check that launchDate exists and is a Date object:
    if (!launchDate || !(launchDate instanceof Date)) {
      return;
    }
    setLaunchDate(launchDate);
  };

  const disabled = createLoading || deleteLoading;
  const assignmentNotPending =
    assignmentData?.assignment.assignmentStatus !==
    AssignmentStatusEnum.Pending;

  return (
    <div>
      <Typography variant="body1">
        Select a Class to assign to and select launch and due dates for each
        selected Class
      </Typography>
      <div className={classes.selectAllContainer}>
        <CustomTooltip
          placement="right"
          classes={{
            popper: classes.customTooltipPopper,
          }}
          open={selectAllOpen}
          title={
            <div className={`${classes.selectAllPopup} select-all-popup`}>
              <DateTimePicker
                ampmInClock
                className={classes.setAllPicker}
                label="Set All Launch Dates"
                onChange={handleLaunchDateChange}
                slotProps={{
                  popper: { className: classes.setAllPickerPopper },
                  tabs: { hidden: false },
                  textField: {
                    size: 'small',
                    variant: 'standard',
                  },
                }}
                value={launchDate}
              />
              <DateTimePicker
                ampmInClock
                className={classes.setAllPicker}
                label="Set All Due Dates"
                onChange={handleDueDateChange}
                slotProps={{
                  popper: { className: classes.setAllPickerPopper },
                  tabs: { hidden: false },
                  textField: {
                    size: 'small',
                    variant: 'standard',
                  },
                }}
                value={dueDate}
              />
              <div className={classes.buttonsGroup}>
                <Button
                  onClick={closeSelectAll}
                  variant="outlined"
                  color="secondary"
                >
                  Close
                </Button>
                <Button
                  className={classes.confirmAssignButton}
                  variant="contained"
                  color="secondary"
                  aria-label="Assign To All"
                  onClick={selectAllClasses}
                >
                  Assign
                </Button>
              </div>
            </div>
          }
        >
          <Button
            variant="contained"
            color="secondary"
            className={classes.selectAllButton}
            aria-label="Select All Classes"
            onClick={openSelectAll}
            disabled={disabled || assignmentNotPending}
          >
            Select All
          </Button>
        </CustomTooltip>
      </div>
      <FormControl component="fieldset">
        <FormGroup>
          {groups.map((group) => {
            const existing = groupsAssignments[group.id];
            const disabledPicker = !existing || disabled;
            return (
              <div key={group.id} className={classes.group}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={disabled || assignmentNotPending}
                      onChange={handleCheckboxChange(group.id, existing?.id)}
                      checked={!!existing}
                      name={group.name}
                    />
                  }
                  label={group.name}
                />
                <DateTimePicker
                  ampmInClock
                  className={classes.launchDatePicker}
                  disabled={disabledPicker || assignmentNotPending}
                  label="Launch Date"
                  onChange={handleSingleLaunchDateChange(
                    group.id,
                    existing?.id
                  )}
                  slotProps={{
                    tabs: { hidden: false },
                    textField: {
                      size: 'small',
                      variant: 'standard',
                    },
                  }}
                  value={existing?.launchDate ? existing.launchDate : null}
                />
                <DateTimePicker
                  ampmInClock
                  disabled={disabledPicker}
                  label="Due Date"
                  onChange={handleSingleDueDateChange(group.id, existing?.id)}
                  slotProps={{
                    tabs: { hidden: false },
                    textField: {
                      size: 'small',
                      variant: 'standard',
                    },
                  }}
                  value={existing?.dueDate ? existing.dueDate : null}
                />
              </div>
            );
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
}
