/**
 * This has side-effects and is needed to patch the browser runtime environment
 */

import { Buffer } from 'buffer';

// eslint-disable-next-line no-native-reassign
window.global = globalThis;

window.global.Buffer = Buffer;
globalThis.Buffer = Buffer;
(window as any).Buffer = Buffer;
