import { CloudUpload, ImportExport } from '@mui/icons-material';
import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import StyledDialog from '../../shared/Layout/StyledDialog';
import GroupsManagement from './GroupsManagement';
import ImportStandards from './ImportStandards';
import QuestionsBulkUpload from './QuestionsBulkUpload';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
    maxWidth: 1280,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  uploadQuestionsButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  buttonsContainer: {
    maxWidth: 1232,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingBottom: theme.spacing(3),
  },
  bulkUploadContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
  },
  uploadButtonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexFlow: 'column',
    },
  },
  uploadStandardsButton: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

type ModalContent = 'standards' | 'questions';

export function TeacherCollaboration() {
  const classes = useStyles();
  const [modalContent, setModalContent] = useState<ModalContent | undefined>();
  const handleClose = () => setModalContent(undefined);
  const handleOpenStandards = () => setModalContent('standards');
  const handleOpenQuestions = () => setModalContent('questions');

  const dialogContent = () => {
    if (modalContent === 'standards') {
      return (
        <div className={classes.bulkUploadContainer}>
          <ImportStandards />
          {/* <StandardsBulkUpload /> */}
        </div>
      );
    }

    return (
      <div className={classes.bulkUploadContainer}>
        <QuestionsBulkUpload handleClose={handleClose} />
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.buttonsContainer}>
        <div className={classes.uploadButtonsContainer}>
          <Button
            className={classes.uploadStandardsButton}
            startIcon={<ImportExport />}
            variant="contained"
            onClick={handleOpenStandards}
          >
            Import Standards
          </Button>
          <Button
            startIcon={<CloudUpload />}
            className={classes.uploadQuestionsButton}
            variant="contained"
            onClick={handleOpenQuestions}
          >
            Upload Questions
          </Button>
        </div>
        <GroupsManagement />
      </div>
      <StyledDialog
        open={modalContent !== undefined}
        title={`Bulk Upload ${
          modalContent === 'standards' ? 'Standards' : 'Questions'
        }`}
        handleClose={handleClose}
      >
        {dialogContent()}
      </StyledDialog>
    </div>
  );
}
export default TeacherCollaboration;
