// This function takes a string and replaces all dashes (-) with a space ( ).
// It also upcases the first letter of every word and downcases the rest of the letters.
// The function takes a string as a parameter and returns a string.
export const replaceDashWithSpaceAndUpcaseFirstLetter = (str: string) => {
  return str
    .replace(/-/g, ' ')
    .replace(/\w\S*/g, function (txt: string) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
    .replace(/\s+/g, ' ');
};
