import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type StandardsQueryVariables = Types.Exact<{
  standardsChartId: Types.Scalars['ID']['input'];
}>;

export type StandardsQuery = {
  __typename?: 'Query';
  standards: Array<{
    __typename?: 'Standard';
    id: string;
    title: string;
    description: string;
    standardsCategory?: {
      __typename?: 'StandardsCategory';
      id: string;
      category: string;
    } | null;
  }>;
};

export type SpiralableStandardsQueryVariables = Types.Exact<{
  standardsChartId: Types.Scalars['ID']['input'];
}>;

export type SpiralableStandardsQuery = {
  __typename?: 'Query';
  spiralableStandards: Array<{
    __typename?: 'Standard';
    id: string;
    title: string;
    description: string;
  }>;
};

export const StandardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'standards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardsChartId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'standards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardsChartId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardsChartId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'standardsCategory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'category' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StandardsQuery, StandardsQueryVariables>;
export const SpiralableStandardsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'spiralableStandards' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'standardsChartId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'spiralableStandards' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'standardsChartId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'standardsChartId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SpiralableStandardsQuery,
  SpiralableStandardsQueryVariables
>;
