import { DeleteForever, Edit } from '@mui/icons-material';
import { Divider, IconButton, Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { AnswersBreakdown } from '../../../shared/Answers/AnswersBreakdown';
import { CustomTooltip } from '../../../shared/CustomTooltip';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { DeleteQuestionConfirmation } from '../Show/DeleteQuestionConfirmation';

const useStyles = makeStyles((theme: Theme) => ({
  questionCard: {
    width: '100%',
    margin: `${theme.spacing(1)} 0 ${theme.spacing(2)} 0`,
  },
  questionPlainText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    marginTop: theme.spacing(2),
  },
  questionCardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  questionFormContainer: {},
  deleteQuestionButton: {
    marginRight: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
}));

type UpdateQuestionVariationCardProps = {
  question: QuestionAttributesFragment;
  parentQuestion?: boolean;
  postUpdateCallback?: () => void;
  setSelectedVariation: (variationId: string | undefined) => void;
  index: number;
};

export function UpdateQuestionVariationCard({
  question,
  parentQuestion,
  postUpdateCallback,
  setSelectedVariation,
  index,
}: UpdateQuestionVariationCardProps) {
  const { richText } = question;
  const questionId = question.id;

  const classes = useStyles();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const closeDelete = () => setDeleteOpen(false);

  return (
    <div className={classes.questionCard}>
      <Divider className={classes.divider} />
      <div className={classes.questionCardHeader}>
        <Typography
          variant="h4"
          color="primary"
        >{`Variation #${index}`}</Typography>
        <div>
          <Tooltip title="Edit Variation">
            <IconButton
              aria-label="Edit Variation"
              size="small"
              onClick={() => {
                if (setSelectedVariation) {
                  setSelectedVariation(questionId);
                }
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <CustomTooltip
            open={deleteOpen}
            title={
              <DeleteQuestionConfirmation
                postDeleteCallback={postUpdateCallback}
                parentQuestion={parentQuestion || false}
                questionId={questionId}
                afterDelete={() => {}}
                closeDelete={closeDelete}
              />
            }
          >
            <IconButton
              aria-label="Delete Question"
              size="small"
              className={classes.deleteQuestionButton}
              onClick={() => setDeleteOpen(!deleteOpen)}
            >
              <DeleteForever />
            </IconButton>
          </CustomTooltip>
        </div>
      </div>

      <div className={classes.questionFormContainer}>
        <div className={classes.questionPlainText}>
          <QuillDeltaAsHtml delta={richText.ops} style={{ padding: 0 }} />
          <AnswersBreakdown question={question} hideStats />
        </div>
      </div>
    </div>
  );
}
