import { Box } from '@mui/material';
import { format } from 'date-fns';
import { shortenedDateFormatString } from '../../utils/dates';

export function ResponseTimestamp({ timestamp }: { timestamp: string }) {
  return (
    <Box sx={{ display: 'inline-block' }}>
      {format(new Date(timestamp), shortenedDateFormatString)}
    </Box>
  );
}
