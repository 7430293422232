import {
  Dialog,
  useMediaQuery,
  useTheme,
  type DialogProps,
} from '@mui/material';
import type { ReactNode } from 'react';

type CustomDialogProps = {
  children: ReactNode;
};

export function CustomDialog({
  children,
  ...props
}: CustomDialogProps & DialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Dialog fullScreen={fullScreen} {...props}>
      {children}
    </Dialog>
  );
}

export default CustomDialog;
