import MaterialTable, {
  MTableHeader,
  type MaterialTableProps,
} from '@material-table/core';
import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { tableRoot } from '../../../assets/shared-styles/Table';
import { icons } from './icons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    ...tableRoot(theme),
  },
  headerStyles: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.h5.fontWeight,
    lineHeight: theme.typography.h5.lineHeight,
    textTransform: 'uppercase',
    '&:first-child': {
      borderRadius: '8px 0 0 0',
    },
    '&:last-child': {
      borderRadius: '0 8px 0 0',
    },
  },
}));

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_PAGE_SIZE_OPTIONS = [10, 25, 50];

export function CustomTable<RowData extends object>(
  props: MaterialTableProps<RowData>
) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MaterialTable
        {...props}
        components={{
          Header: (headerProps) => (
            <MTableHeader
              {...headerProps}
              classes={{ header: classes.headerStyles }}
            />
          ),
          ...props.components,
        }}
        icons={icons}
        options={{
          emptyRowsWhenPaging: false,
          headerStyle: { position: 'sticky', top: 0 },
          maxBodyHeight: '85vh',
          pageSize: DEFAULT_PAGE_SIZE,
          pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
          ...props.options,
        }}
        style={{
          borderRadius: '0 0 8px 8px',
        }}
      />
    </div>
  );
}
