import { useMutation, useQuery } from '@apollo/client';
import {
  FormControlLabel,
  Switch,
  Tooltip,
  type SwitchProps,
} from '@mui/material';
import { ToggleQuestionActiveDocument } from '../../../../gql/mutations/__generated__/question.generated';
import { GetUpdateQuestionDocument } from '../../../../gql/queries/__generated__/question.generated';
import { QuestionStateEnum } from '../../../../gql/types';

type ActiveToggleProps = {
  questionId?: string;
  size?: SwitchProps['size'];
};

export function ActiveToggle({
  questionId,
  size = 'small',
}: ActiveToggleProps) {
  const [toggleQuestionActive, { loading: toggleLoading }] = useMutation(
    ToggleQuestionActiveDocument
  );
  const { data } = useQuery(GetUpdateQuestionDocument, {
    skip: !questionId,
    variables: { questionId: questionId || '' },
  });
  const active = data?.question.state === QuestionStateEnum.Active;
  const handleActiveToggle = () => {
    if (questionId && active !== undefined) {
      toggleQuestionActive({
        variables: {
          questionId,
        },
      });
    }
  };

  if (active === undefined) {
    return null;
  }

  return (
    <Tooltip title="Assign this question as part of an assignment to enable in the personal deck">
      <FormControlLabel
        control={
          <Switch
            disabled={toggleLoading}
            checked={active}
            onChange={handleActiveToggle}
            name="questionActive"
            color="primary"
            size={size}
          />
        }
        label={`${active ? 'Active' : 'Inactive'} In Personal Deck`}
        style={{ textTransform: 'none', marginRight: 0 }}
      />
    </Tooltip>
  );
}

export default ActiveToggle;
