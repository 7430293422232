import { useTrackVisit } from './useTrackVisit';

type TrackVisitProps = {
  section: string;
  page: string;
};

export function TrackVisit({ section, page }: TrackVisitProps) {
  useTrackVisit({
    section,
    page,
  });
  return null;
}

export default TrackVisit;
