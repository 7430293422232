import { useMutation, useQuery } from '@apollo/client';
import { Button, LinearProgress, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { DeleteQuestionDocument } from '../../../../gql/mutations/__generated__/question.generated';
import { QuestionDocument } from '../../../../gql/queries/__generated__/question.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
  title: {},
  caption: {
    marginTop: theme.spacing(1.5),
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  loadContainer: {
    marginTop: theme.spacing(3),
  },
  loadingText: {
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.accent.main,
    color: theme.palette.accent.main,
    marginLeft: theme.spacing(1),
  },
  cancelButton: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.grays.dark,
    color: theme.palette.grays.dark,
  },
}));

type DeleteQuestionConfirmationProps = {
  questionId?: string;
  closeDelete: () => void;
  afterDelete: () => void;
  parentQuestion?: boolean;
  postDeleteCallback?: () => void;
};

export function DeleteQuestionConfirmation({
  questionId,
  parentQuestion,
  closeDelete,
  afterDelete,
  postDeleteCallback,
}: DeleteQuestionConfirmationProps) {
  const classes = useStyles();
  const { dispatch: dispatchAlert } = useContext(AlertsContext);
  const { data } = useQuery(QuestionDocument, {
    skip: !questionId,
    variables: { questionId: questionId || '' },
  });
  const [deleteQuestion, { loading: deleteLoading }] = useMutation(
    DeleteQuestionDocument,
    {
      onError: onError(dispatchAlert),
      onCompleted: () => {
        if (postDeleteCallback && parentQuestion) {
          postDeleteCallback();
        }
      },
      refetchQueries: ['assignment'],
      update: (cache) => {
        if (!data?.question) {
          return;
        }

        cache.evict({
          id: cache.identify(data.question),
        });

        cache.gc();
        dispatchAlert(
          pushSnack({
            message: 'Question Deleted!',
          })
        );
        closeDelete();
        afterDelete();
      },
    }
  );
  const handleQuestionDelete = () => {
    if (questionId) {
      deleteQuestion({ variables: { questionId } });
    }
  };
  let text = `Are you sure you want to delete this question variation and ALL of its 
  associated data (e.g. student responses)?`;

  if (parentQuestion) {
    text = `Are you sure you want to delete this question, all of its 
    variations (if there are any), and ALL of its associated data (e.g. mastery 
    data, student responses)?`;
  }
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5" color="error">
        WARNING: This CANNOT be undone!
      </Typography>
      <Typography className={classes.caption} variant="body1">
        {text}
      </Typography>
      {deleteLoading ? (
        <div className={classes.loadContainer}>
          <Typography className={classes.loadingText}>
            Deleting question and its associated data (this could take a little
            bit of time)...
          </Typography>
          <LinearProgress />
        </div>
      ) : (
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={closeDelete}
          >
            NO, CLOSE
          </Button>
          <Button
            variant="outlined"
            disabled={deleteLoading}
            className={classes.deleteButton}
            onClick={handleQuestionDelete}
          >
            YES, DELETE
          </Button>
        </div>
      )}
    </div>
  );
}
