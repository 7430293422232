import React, { useReducer } from 'react';
import { AssignmentEditorAction } from './actions';
import reducer from './reducer';
import { AssignmentEditorState, initialState } from './state';

export const AssignmentEditorContext = React.createContext<{
  assignmentEditor: AssignmentEditorState;
  dispatch: React.Dispatch<AssignmentEditorAction>;
}>({
  assignmentEditor: initialState,
  dispatch: () => null,
});

type AssignmentEditorProviderProps = {
  children: React.ReactNode;
  preloadedState: AssignmentEditorState;
};

export function AssignmentEditorProvider({
  children,
  preloadedState,
}: AssignmentEditorProviderProps) {
  const [assignmentEditor, dispatch] = useReducer(reducer, preloadedState);
  return (
    <AssignmentEditorContext.Provider value={{ assignmentEditor, dispatch }}>
      {children}
    </AssignmentEditorContext.Provider>
  );
}
