import { useMutation, useQuery } from '@apollo/client';
import { Archive, Cancel, FileCopy } from '@mui/icons-material';
import {
  Button,
  LinearProgress,
  Menu,
  MenuItem,
  Tooltip,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CloneAssignmentDocument,
  UpdateAssignmentDocument,
} from '../../../../gql/mutations/__generated__/assignment.generated';
import { AssignmentDocument } from '../../../../gql/queries/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { openConfirmation, pushSnack } from '../../Alerts/context/actions';
import HelpKitArchiveAssignment from '../../HelpKitArticles/HelpKitArchiveAssignment';
import { DiscardButton } from './DiscardButton';
import { AssignmentEditorContext } from './context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  rocketIcon: {
    fill: theme.palette.common.white,
  },
  icon: {
    marginRight: theme.spacing(1),
    paddingTop: '4px',
  },
  launchButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  optionsButton: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
  },
  progressBarContainer: {
    height: theme.spacing(1),
    width: 150,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type OptionsButtonProps = {
  resetLaunchProgressStates: () => void;
  launchInProgress: boolean;
};

export function OptionsButton({
  launchInProgress,
  resetLaunchProgressStates,
}: OptionsButtonProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    assignmentEditor: { id: assignmentId },
  } = useContext(AssignmentEditorContext);
  const history = useHistory();
  const { dispatch } = useContext(AlertsContext);
  const { data } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });
  const [cloneAssignment, { loading: cloneLoading }] = useMutation(
    CloneAssignmentDocument,
    {
      onError: onError(dispatch),
      variables: { assignmentId },
      onCompleted: (copiedData) => {
        // re-route to the correct page
        history.push(`/assignments/${copiedData.cloneAssignment.id}`);
        dispatch(
          pushSnack({
            message: `A copy of ${data?.assignment.name} has been created!`,
          })
        );
      },
    }
  );
  const [launchAssignment, { loading }] = useMutation(
    UpdateAssignmentDocument,
    {
      onError: onError(dispatch),
      variables: {
        assignmentId,
        assignmentStatus: AssignmentStatusEnum.Active,
      },
      onCompleted: () => {
        dispatch(
          pushSnack({
            message: 'Assignment status updated!',
          })
        );
      },
    }
  );

  const [archiveAssignment] = useMutation(UpdateAssignmentDocument, {
    onError: onError(dispatch),
    variables: {
      assignmentId,
      assignmentStatus: AssignmentStatusEnum.Archived,
    },
    onCompleted: () => {
      history.push('/assignments');
      dispatch(
        pushSnack({
          message: 'Assignment has been archived!',
        })
      );
    },
  });

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const clone = () => {
    cloneAssignment();
  };
  const archive = () => {
    dispatch(
      openConfirmation({
        message: (
          <div>
            <p>
              Are you sure you want to archive this assignment? Archiving this
              assignment removes ALL student access to this assignment.
            </p>

            <p>
              <strong>
                However, archiving this assignment DOES NOT delete the
                assignment&apos;s questions or any associated student data.
              </strong>

              <div>
                Learn more about this here: <HelpKitArchiveAssignment /> (Note:
                this does not apply to Personal Deck Assignments.)
              </div>
            </p>
          </div>
        ),
        confirmFunc: () => archiveAssignment(),
        confirmButtonText: 'Archive Assignment',
      })
    );
  };

  if (!data) {
    return null;
  }

  const { assignmentStatus, unassignable } = data.assignment;

  const disabled = !unassignable;
  let tooltipText = 'Un-assign your assignment';
  if (disabled) {
    tooltipText =
      'You can no longer un-assign since students already made progress on the assignment';
  }

  if (loading || cloneLoading) {
    return (
      <div className={classes.progressBarContainer}>{<LinearProgress />}</div>
    );
  }

  return (
    <div className={classes.root}>
      <Button
        aria-label="assignment options"
        aria-controls="assignment-options"
        aria-haspopup="true"
        onClick={handleMenu}
        variant="outlined"
        className={classes.optionsButton}
      >
        Options
      </Button>
      <Menu
        id="assignment-options"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {assignmentStatus === AssignmentStatusEnum.Pending && (
          <DiscardButton assignmentStatus={assignmentStatus} />
        )}
        {assignmentStatus === AssignmentStatusEnum.Active && (
          <Tooltip title={tooltipText}>
            <span>
              <MenuItem
                disabled={disabled || launchInProgress}
                onClick={() => {
                  resetLaunchProgressStates();
                  launchAssignment({
                    variables: {
                      assignmentId,
                      assignmentStatus: AssignmentStatusEnum.Pending,
                    },
                  });
                }}
              >
                <div className={classes.container}>
                  <span className={classes.icon}>
                    <Cancel />
                  </span>
                  <span>UN-ASSIGN</span>
                </div>
              </MenuItem>
            </span>
          </Tooltip>
        )}
        <MenuItem onClick={clone}>
          <div className={classes.container}>
            <span className={classes.icon}>
              <FileCopy />
            </span>
            <span>CLONE ASSIGNMENT</span>
          </div>
        </MenuItem>
        {assignmentStatus !== AssignmentStatusEnum.Pending && (
          <MenuItem onClick={archive}>
            <div className={classes.container}>
              <span className={classes.icon}>
                <Archive />
              </span>
              <span>ARCHIVE ASSIGNMENT</span>
            </div>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
