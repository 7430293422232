import { useMutation, useQuery } from '@apollo/client';
import { Check, Close, Edit } from '@mui/icons-material';
import {
  IconButton,
  TextField,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import { StandardsSetup } from '../../components/application/Setup/StandardsSetup';
import TeacherCollaboration from '../../components/application/Subject/TeacherCollaboration';
import { LoadingSkeletons } from '../../components/shared/Loaders/LoadingSkeletons';
import { EditStandardsChartDocument } from '../../gql/mutations/__generated__/standardsChart.generated';
import { StandardsChartAdminsDocument } from '../../gql/queries/__generated__/standardsChart.generated';
import { TeacherDocument } from '../../gql/queries/__generated__/teacher.generated';
import { useTrackVisit } from '../../utils/hooks/useTrackVisit';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    textAlign: 'center',
  },
  loadingContainer: {
    width: 180,
  },
  titleContainer: {
    marginTop: theme.spacing(5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editField: {},
  editInput: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 22,
    color: theme.palette.primary.main,
  },
  checkIcon: {
    color: theme.palette.secondary.main,
  },
  closeIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  },
  editIconButton: {
    marginLeft: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

export function Subject() {
  useTrackVisit({
    section: 'collaborative-subject',
    page: 'collaborative-subject',
  });
  const classes = useStyles();
  const { updateTitle } = useContext(HeaderTitleContext);
  const [editMode, setEditMode] = useState(false);
  const [standardsChartName, setStandardsChartName] = useState('');
  const { data: teacherData, loading: teacherLoading } =
    useQuery(TeacherDocument);
  const standardsChartId =
    teacherData?.teacher.activeCourse?.standardsChart?.id || '';
  const { data: adminData } = useQuery(StandardsChartAdminsDocument, {
    skip: !standardsChartId,
    variables: { standardsChartId: standardsChartId },
  });
  const cache: { [key: string]: boolean } = useMemo(() => {
    const obj: { [key: string]: boolean } = {};
    if (!adminData) {
      return obj;
    }

    adminData.standardsChart.admins.forEach((admin) => {
      obj[admin.id] = true;
    });

    return obj;
  }, [adminData]);

  const amAdmin = !!(teacherData && cache[teacherData.teacher.id]);
  useEffect(() => {
    updateTitle('Collaborative Subject');
  }, [updateTitle]);
  const subjectName = teacherData?.teacher.activeCourse?.standardsChart?.title;
  useEffect(() => {
    if (subjectName) {
      setStandardsChartName(subjectName);
    }
  }, [subjectName]);
  const [editStandardsChart, { loading }] = useMutation(
    EditStandardsChartDocument,
    {
      update: (cache, { data }) => {
        const query = cache.readQuery({ query: TeacherDocument });

        if (query && data) {
          const updated = produce(query, (draft) => {
            if (draft?.teacher?.activeCourse?.standardsChart) {
              draft.teacher.activeCourse.standardsChart.title =
                data.editStandardsChart.title;
            }
          });
          cache.writeQuery({
            data: updated,
            query: TeacherDocument,
          });
        }
      },
    }
  );
  const isLoading = loading || teacherLoading;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!standardsChartId) {
      return;
    }
    editStandardsChart({
      variables: {
        standardsChartId,
        title: standardsChartName,
      },
    });
    setEditMode(false);
  };

  const leaveEditMode = () => setEditMode(false);
  const editStandardsChartName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStandardsChartName(e.target.value);
  };
  const startEditMode = () => setEditMode(true);
  const title = () => {
    if (editMode) {
      return (
        <form className={classes.titleContainer} onSubmit={handleSubmit}>
          <TextField
            className={classes.editField}
            value={standardsChartName}
            InputProps={{
              className: classes.editInput,
            }}
            onChange={editStandardsChartName}
            size="medium"
          />
          <IconButton
            onClick={leaveEditMode}
            size="small"
            onFocus={(e) => e.stopPropagation()}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
          <IconButton
            type="submit"
            size="small"
            onClick={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
          >
            <Check className={classes.checkIcon} />
          </IconButton>
        </form>
      );
    }

    return (
      <div className={classes.titleContainer}>
        {isLoading ? (
          <div className={classes.loadingContainer}>
            <LoadingSkeletons num={2} />
          </div>
        ) : (
          <Typography variant="h2" className={classes.title} color="primary">
            {subjectName}
          </Typography>
        )}
        <div>
          {amAdmin && (
            <Tooltip title="Edit Subject Group Name">
              <IconButton
                onClick={startEditMode}
                size="small"
                className={classes.editIconButton}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };
  return (
    <div className={classes.root}>
      {title()}
      <TeacherCollaboration />

      <StandardsSetup />
    </div>
  );
}
