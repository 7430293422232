import { useQuery } from '@apollo/client';
import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AssignmentCollaborationQuestionsDocument } from '../../../../gql/queries/__generated__/assignment.generated';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  questionContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

type SharedAssignmentsQuestionsType = {
  assignmentId: string;
};

export function SharedAssignmentsQuestions({
  assignmentId,
}: SharedAssignmentsQuestionsType) {
  const classes = useStyles();
  const { data, loading } = useQuery(AssignmentCollaborationQuestionsDocument, {
    variables: { assignmentId },
  });

  if (loading) {
    return <LoadingSkeletons num={12} />;
  }

  return (
    <div className={classes.root}>
      {data?.assignmentCollaboration.questions.map((question) => {
        return (
          <div
            key={`collaborative-assignment-question-${question.id}`}
            className={classes.questionContainer}
          >
            <QuillDeltaAsHtml delta={question.richText.ops} />
          </div>
        );
      })}
    </div>
  );
}

export default SharedAssignmentsQuestions;
