import { useMutation, useQuery } from '@apollo/client';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  ListItemText,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { AlertsContext } from '../../components/application/Alerts/context';
import { UpdateCourseDocument } from '../../gql/mutations/__generated__/course.generated';
import { TeacherCoursesDocument } from '../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../utils/apollo/apolloHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    minWidth: '80%',
    marginTop: theme.spacing(3),
  },
  unarchive: {
    marginLeft: theme.spacing(1),
  },
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export function CourseListArchived() {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);

  const { data, refetch } = useQuery(TeacherCoursesDocument, {
    onError: onError(dispatch),
  });

  const [updateCourse] = useMutation(UpdateCourseDocument, {
    onError: onError(dispatch),
  });

  const handleUnArchive = (courseId: string) => {
    updateCourse({ variables: { archived: false, courseId } }).then(
      (result) => {
        refetch();
        return result;
      }
    );
  };

  return (
    <div className={classes.root}>
      {data?.teacher.coursesTeachers
        .filter((coursesTeacher) => coursesTeacher.archived)
        .sort((elem, otherElem) => {
          if (elem.createdAt < otherElem.createdAt) {
            return -1;
          }
          if (elem.createdAt === otherElem.createdAt) {
            return 0;
          }
          return 1;
        })
        .map(({ course }) => (
          <Card key={`archived-course-${course.id}`} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <ListItemText
                primary={course.name}
                secondary={`${course.level.name} - ${course.subject.name}`}
              />
              <CardActions>
                <Button
                  className={classes.unarchive}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  startIcon={<UnarchiveIcon />}
                  onClick={() => handleUnArchive(course.id)}
                >
                  Un-Archive
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        ))}
    </div>
  );
}
