import HelpKitButton from './HelpKitButton';

export function HelpKitOptionalAssignment() {
  return (
    <HelpKitButton
      label="How do Optional Assignments work?"
      articleId="ctMrhJn2oTvWbbwzNLcsb8"
    />
  );
}
export default HelpKitOptionalAssignment;
