import HelpKitButton from './HelpKitButton';

export function HelpKitSharedAssignments() {
  return (
    <HelpKitButton
      label="How to use Shared Assignments"
      articleId="hT4dLKejar24qo2gHsEPX7"
    />
  );
}
export default HelpKitSharedAssignments;
