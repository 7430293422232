import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { QuestionAttributesFragment } from '../../../../../../gql/fragments/__generated__/question.generated';
import { CustomChip } from '../../../../../shared/CustomChip';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: theme.spacing(2.5),
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
}));

type QuestionStandardsProps = {
  standards: QuestionAttributesFragment['standards'];
};

export function QuestionStandards({ standards }: QuestionStandardsProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {standards?.length ? (
        standards?.map((standard) => {
          let truncatedTitle = standard.title.substring(0, 20).trim();
          if (standard.title.length > 20) {
            truncatedTitle += '...';
          }
          return (
            <CustomChip
              className={classes.chip}
              color="primary"
              key={standard.id}
              label={truncatedTitle}
              size="small"
              tooltip={`${standard.title} - ${standard.description}`}
            />
          );
        })
      ) : (
        <CustomChip
          label="No Standard Tagged"
          className={classes.chip}
          color="primary"
          size="small"
        />
      )}
    </div>
  );
}
export default QuestionStandards;
