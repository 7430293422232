import { Skeleton, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { HTMLProps } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));

export type LoadingSkeletonsProps = {
  /**
   * Number of skeletons to render
   *
   * @default 1
   */
  num?: number;

  /**
   * Optional height of the grey skeleton seemingly in pixels
   */
  skeletonHeight?: number;
};

export function LoadingSkeletons({
  num = 1,
  skeletonHeight,
  ...props
}: LoadingSkeletonsProps & HTMLProps<HTMLDivElement>) {
  const classes = useStyles();

  return (
    <div className={classes.root} {...props}>
      {new Array(num).fill(true).map((_, index) => (
        <Skeleton key={`skeleton-${index}`} height={skeletonHeight} />
      ))}
    </div>
  );
}
