import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateGroupsAssignmentMutationVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
  assignmentId: Types.Scalars['ID']['input'];
  dueDate?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
  launchDate?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
}>;

export type CreateGroupsAssignmentMutation = {
  __typename?: 'Mutation';
  createGroupsAssignment: {
    __typename?: 'GroupsAssignment';
    id: string;
    dueDate?: any | null;
    launchDate?: any | null;
    group: { __typename?: 'Group'; id: string };
  };
};

export type DeleteGroupsAssignmentMutationVariables = Types.Exact<{
  groupsAssignmentId: Types.Scalars['ID']['input'];
}>;

export type DeleteGroupsAssignmentMutation = {
  __typename?: 'Mutation';
  deleteGroupsAssignment: {
    __typename?: 'GroupsAssignment';
    id: string;
    groupId: string;
  };
};

export type UpdateGroupsAssignmentMutationVariables = Types.Exact<{
  groupsAssignmentId: Types.Scalars['ID']['input'];
  dueDate?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
  launchDate?: Types.InputMaybe<Types.Scalars['ISO8601DateTime']['input']>;
}>;

export type UpdateGroupsAssignmentMutation = {
  __typename?: 'Mutation';
  updateGroupsAssignment: {
    __typename?: 'GroupsAssignment';
    id: string;
    dueDate?: any | null;
    launchDate?: any | null;
    groupId: string;
  };
};

export const CreateGroupsAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createGroupsAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'launchDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGroupsAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'assignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'assignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dueDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dueDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'launchDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'launchDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateGroupsAssignmentMutation,
  CreateGroupsAssignmentMutationVariables
>;
export const DeleteGroupsAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGroupsAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGroupsAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteGroupsAssignmentMutation,
  DeleteGroupsAssignmentMutationVariables
>;
export const UpdateGroupsAssignmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateGroupsAssignment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupsAssignmentId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dueDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'launchDate' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'ISO8601DateTime' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGroupsAssignment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupsAssignmentId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupsAssignmentId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'dueDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'dueDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'launchDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'launchDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dueDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'launchDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGroupsAssignmentMutation,
  UpdateGroupsAssignmentMutationVariables
>;
