import { useQuery } from '@apollo/client';
import { TablePagination, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { useContext, useEffect, useMemo } from 'react';
import { Link, type RouteComponentProps } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import {
  GroupsAssignmentsConnectionDocument,
  type GroupsAssignmentsQuery,
} from '../../../../gql/queries/__generated__/groupsAssignment.generated';
import {
  AssignmentStatusEnum,
  type Assignment,
  type StudentsAssignmentsDatum,
} from '../../../../gql/types';
import {
  assignmentTypeNameMapping,
  calculateAssignmentStats,
} from '../../../../utils/assignmentHelper';
import { dateFormat } from '../../../../utils/dates';
import { usePagination } from '../../../../utils/hooks/usePagination';
import { CustomTable } from '../../../shared/Table';
import { GroupsAssignmentsTableSearchContext } from './GroupsAssignmentsTableSearchContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  linkButton: {
    ...buttonLink(theme),
  },
}));

export const PAGE_SIZE = 15;

type GroupsAssignmentsTableProps = {
  groupId: string;
};

type GroupsAssignmentsData = {
  groupsAssignmentId: string;
  // Note: couldn't figure out how to access an optional type of in an
  // autogenerated type
  assignment: { __typename?: 'Assignment' } & Pick<
    Assignment,
    'targetDistributedPoints' | 'id' | 'name' | 'assignmentType' | 'launchAt'
  >;
  completion: {
    numFinishedStudents: number;
    numStudents: number;
  };
  averageScore: number;
  due: Date;
}[];

export function GroupsAssignmentsTable({
  groupId,
  match,
}: GroupsAssignmentsTableProps & RouteComponentProps) {
  const classes = useStyles();

  const { value: filterValue } = useContext(
    GroupsAssignmentsTableSearchContext
  );
  const [debouncedSearchFilter] = useDebounce(filterValue, 500);

  const { setPageInfo, args, onPageChange, currentPage, pageInfo } =
    usePagination({
      pageSize: PAGE_SIZE,
    });

  const { data: paginatedData, loading: paginatedLoading } = useQuery(
    GroupsAssignmentsConnectionDocument,
    {
      variables: {
        ...args,
        groupId,
        statuses: [
          AssignmentStatusEnum.Active,
          AssignmentStatusEnum.InProgress,
          AssignmentStatusEnum.PastDue,
        ],
        filter: debouncedSearchFilter,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (!paginatedData) {
      return;
    }

    setPageInfo({
      ...paginatedData.groupsAssignmentsConnection.pageInfo,
      totalCount: paginatedData.groupsAssignmentsConnection.totalCount,
    });
  }, [paginatedData, setPageInfo]);

  const processGroupsAssignmentData = (
    id: string,
    dueDate: Date,
    enrolledStudentsAssignmentsData: GroupsAssignmentsQuery['groupsAssignments'][0]['enrolledStudentsAssignmentsData'],
    assignment: GroupsAssignmentsQuery['groupsAssignments'][0]['assignment']
  ) => {
    let numStudents = 0;
    let numFinishedStudents = 0;
    let numFinishedStudentsNonZeroQuestions = 0;
    let totalPoints = 0;
    const due = new Date(dueDate);
    enrolledStudentsAssignmentsData?.forEach((studentsAssignmentsDatum) => {
      if (!studentsAssignmentsDatum) {
        return;
      }
      const {
        updatedNumStudents,
        updatedNumFinishedStudents,
        updatedNumFinishedStudentsNonZeroQuestions,
        updatedTotalPoints,
      } = calculateAssignmentStats({
        studentsAssignmentsDatum:
          studentsAssignmentsDatum as StudentsAssignmentsDatum,
        numStudents,
        numFinishedStudents,
        numFinishedStudentsNonZeroQuestions,
        totalPoints,
        assignment: assignment as Assignment,
      });
      numStudents = updatedNumStudents;
      numFinishedStudents = updatedNumFinishedStudents;
      numFinishedStudentsNonZeroQuestions =
        updatedNumFinishedStudentsNonZeroQuestions;
      totalPoints = updatedTotalPoints;
    });
    const averageScore = Math.round(
      totalPoints / numFinishedStudentsNonZeroQuestions
    );
    return {
      groupsAssignmentId: id,
      assignment: assignment,
      completion: { numFinishedStudents, numStudents },
      averageScore,
      due,
    };
  };

  const assignmentsDataPaginated = useMemo(() => {
    if (!paginatedData || !paginatedData.groupsAssignmentsConnection.edges) {
      return [];
    }

    const result: GroupsAssignmentsData = [];
    paginatedData?.groupsAssignmentsConnection.edges.forEach((edge) => {
      if (!edge || !edge.node) {
        return;
      }
      const { id, dueDate, enrolledStudentsAssignmentsData, assignment } =
        edge.node;
      result.push(
        processGroupsAssignmentData(
          id,
          dueDate,
          enrolledStudentsAssignmentsData,
          assignment
        )
      );
    });
    return result;
  }, [paginatedData]);

  return (
    <div className={classes.root}>
      <CustomTable
        title="Assignments"
        isLoading={paginatedLoading}
        data={assignmentsDataPaginated}
        options={{
          pageSize: PAGE_SIZE,
          pageSizeOptions: [PAGE_SIZE],
        }}
        columns={[
          {
            title: 'ASSIGNMENT TYPE',
            field: 'assignment.assignmentType',
            sorting: false,
            lookup: assignmentTypeNameMapping,
          },
          {
            title: 'ASSIGNMENT',
            field: 'assignment.name',
            sorting: false,
            customSort: (a, b) => {
              return a.assignment.name.localeCompare(b.assignment.name);
            },
            render: ({ assignment }) => {
              const routeId = assignment?.id || 'individuals';
              const href =
                assignment.assignmentType === 'distributed'
                  ? `${match.url}/${routeId}`
                  : `/assignments/${assignment.id}?groupId=${groupId}`;

              return (
                <Link to={href}>
                  <button className={classes.linkButton}>
                    {assignment.name}
                  </button>
                </Link>
              );
            },
          },
          {
            title: 'COMPLETION RATE',
            field: 'completion',
            sorting: false,
            render: ({
              assignment,
              completion: { numFinishedStudents, numStudents },
            }) => {
              const launchDate = assignment.launchAt
                ? new Date(assignment.launchAt)
                : undefined;
              const notLaunchedYet = launchDate && launchDate > new Date();
              if (notLaunchedYet) {
                return 'Not Launched Yet';
              }
              return `${numFinishedStudents} of ${numStudents} students`;
            },
            customSort: (a, b) => {
              // doing checks for 0 to account for divide by 0 issues:
              const completionRateA =
                a.completion.numStudents === 0
                  ? 0
                  : a.completion.numFinishedStudents / a.completion.numStudents;
              const completionRateB =
                b.completion.numStudents === 0
                  ? 0
                  : b.completion.numFinishedStudents / b.completion.numStudents;
              return completionRateB - completionRateA;
            },
          },
          {
            title: 'AVERAGE SCORE',
            field: 'averageScore',
            sorting: false,
            render: ({ averageScore }) => {
              if (isNaN(averageScore)) {
                return '';
              }
              return `${averageScore}%`;
            },
          },
          {
            title: 'DUE DATE',
            field: 'due',
            sorting: false,
            render: ({ due }) => {
              if (!due) {
                return '';
              }
              return format(due, dateFormat);
            },
            defaultSort: 'desc',
          },
        ]}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              count={pageInfo.totalCount}
              rowsPerPageOptions={[]}
              page={currentPage}
              onPageChange={onPageChange}
            />
          ),
          Toolbar: () => null,
        }}
      />
    </div>
  );
}
