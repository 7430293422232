import { ArrowForward } from '@mui/icons-material';
import {
  Card,
  Chip,
  Divider,
  IconButton,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  checkbox: {
    width: '30px',
    minWidth: '30px',
    height: '30px',
    borderRadius: '50%',
    border: `3px solid ${theme.palette.divider}`,
    margin: theme.spacing(0, 1, 0, 1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checked: {
    backgroundColor: theme.palette.primary.main,
  },
  innerChecked: {
    display: 'block !important',
  },
  innerCheck: {
    backgroundColor: theme.palette.primary.main,
    width: '22px',
    height: '22px',
    borderRadius: '50%',
    border: `3px solid white`,
    display: 'none',
  },
  cardItemContainer: {
    '&:first-child': {
      margin: theme.spacing(0.5, 0),
    },
    '&:not(:first-child)': {
      margin: theme.spacing(1.5, 0),
    },
    padding: theme.spacing(1),
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  assignmentItemHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  assignmentHeaderDetails: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    padding: theme.spacing(0, 1),
    maxWidth: '100%',
  },
  assignmentItemHeaderTitle: {
    color: '#666666',
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  tagsContainer: {
    padding: theme.spacing(1, 0, 0, 0),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  chip: {
    borderRadius: '6px',
    borderWidth: '2px',
    borderColor: theme.palette.primary.main,
  },
  assignmentTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  left: {
    display: 'flex',
    flexDirection: 'row',
    flex: '1 0 auto',
    alignItems: 'center',
    maxWidth: '80%',
  },
}));

export function AssignmentItem({
  assignment,
  checked,
  onClickCopy,
  onClickMore,
}: {
  assignment: any;
  checked: boolean;
  onClickCopy: () => void;
  onClickMore: () => void;
}) {
  const classes = useStyles();
  return (
    <Card className={classes.cardItemContainer} onClick={onClickMore}>
      <div className={classes.assignmentItemHeader}>
        <div className={classes.left}>
          <div
            className={clsx(classes.checkbox, {
              [classes.checked]: checked,
            })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClickCopy();
            }}
          >
            <div
              className={clsx(classes.innerCheck, {
                [classes.innerChecked]: checked,
              })}
            ></div>
          </div>
          <div className={classes.assignmentHeaderDetails}>
            <Typography className={classes.assignmentItemHeaderTitle}>
              Last updated on{' '}
              {format(parseISO(assignment.updatedAt), 'MM/dd/yyyy HH:mm')}
            </Typography>

            <Typography variant="h4" className={classes.assignmentTitle}>
              {assignment.name}
            </Typography>
          </div>
        </div>
        <IconButton onClick={onClickMore}>
          <ArrowForward />
        </IconButton>
      </div>
      <Divider className={classes.divider} />

      <div className={classes.tagsContainer}>
        <Chip
          label={
            assignment.questions.length +
            ` Question${assignment.questions.length === 1 ? '' : 's'}`
          }
          variant="outlined"
          className={classes.chip}
        />
      </div>
    </Card>
  );
}

export default AssignmentItem;
