import { assign, cloneDeep } from 'lodash';
import { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { GeneratedQuestionsQuery } from '../../../../gql/queries/__generated__/question.generated';
import { QuestionInput } from '../../../../gql/types';
import { mapQuestionToQuestionInput } from '../../../../utils/question';

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer X>
      ? ReadonlyArray<DeepPartial<X>>
      : DeepPartial<T[P]>;
};

type QuestionType = GeneratedQuestionsQuery['generatedQuestions'][number];

export function updateQuestionFields(
  question: QuestionType,
  changes: DeepPartial<QuestionAttributesFragment>
): QuestionInput {
  // Clone deep to avoid mutating the original question object
  const clonedQuestion = cloneDeep(question);
  // Directly assign changes to the cloned question, replacing existing properties
  const updatedQuestion = assign(clonedQuestion, changes);
  return mapQuestionToQuestionInput(updatedQuestion);
}
