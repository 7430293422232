import { useMutation, useQuery } from '@apollo/client';
import { EventNote, HelpOutline } from '@mui/icons-material';
import {
  Button,
  Card,
  Chip,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  Switch,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { parse, startOfToday } from 'date-fns';
import React, { useContext, useState } from 'react';
import { AlertsContext } from '../../components/application/Alerts/context';
import {
  openConfirmation,
  pushSnack,
} from '../../components/application/Alerts/context/actions';
import { UpdateGroupDocument } from '../../gql/mutations/__generated__/group.generated';
import { DeleteScheduledFreezeDocument } from '../../gql/mutations/__generated__/scheduledFreeze.generated';
import {
  ScheduledFreezesDocument,
  type ScheduledFreezesQuery,
} from '../../gql/queries/__generated__/scheduledFreeze.generated';
import { TeacherSettingsDocument } from '../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../utils/apollo/apolloHelper';
import { postgresDateFormatString } from '../../utils/dates';
import { sortGroups } from '../../utils/lists';
import FreezeScheduler from './FreezeScheduler';
import { SettingsBody } from './SettingsBody';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  loading: {
    padding: '12px',
    maxWidth: '360px',
  },
  card: {
    marginBottom: '20px',
  },
  columns: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'flex-end',
  },
  subtitle: {
    marginTop: '8px',
  },
  option: {
    padding: '12px',
  },
  expandButton: {
    height: '30px',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.85',
    },
  },
  scheduledDisplay: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  freezeContainer: {
    border: `1px solid ${theme.palette.secondary.main}`,
    padding: theme.spacing(2),
    borderRadius: '4px',
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 202,
  },
  datesContainer: {
    display: 'flex',
  },
  label: {
    marginRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
  chipsContainer: {
    width: '80%',
    marginTop: theme.spacing(2),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  leftColumn: {
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rightColumn: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  freezeButton: {
    marginBottom: theme.spacing(1.5),
    width: 120,
  },
  loaderContainer: {
    height: theme.spacing(0.5),
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  editButton: {
    color: theme.palette.common.white,
  },
  deleteButton: {},
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 120,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}));

const today = startOfToday();

export function FreezePersonalDeck() {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);

  const [scheduleFreezeOpen, setScheduleFreezeOpen] = useState(false);
  const [editFreeze, setEditFreeze] = useState<
    ScheduledFreezesQuery['scheduledFreezes'][0] | undefined
  >();
  const { data: teacherData } = useQuery(TeacherSettingsDocument, {
    onError: onError(dispatch),
    // we need to poll for the unfreeze personal deck progress bar
    // teachers don't spend a lot of time on this page so it should be ok
    pollInterval: 500,
  });
  const courseId = teacherData?.teacher.activeCourse?.id || '';
  const { data, loading } = useQuery(ScheduledFreezesDocument, {
    skip: !courseId,
    variables: { courseId },
  });

  const [updateGroup] = useMutation(UpdateGroupDocument, {
    onError: onError(dispatch),
  });
  const [deleteFreeze, { loading: deleteLoading }] = useMutation(
    DeleteScheduledFreezeDocument,
    {
      update: (cache, res) => {
        if (!courseId) return;

        const queryResult = cache.readQuery({
          query: ScheduledFreezesDocument,
          variables: { courseId },
        });
        if (!res.data || !queryResult) return;

        const updated = queryResult.scheduledFreezes.filter(
          (freeze) => freeze.id !== res.data?.deleteScheduledFreeze.toString()
        );
        cache.writeQuery({
          data: { scheduledFreezes: updated },
          query: ScheduledFreezesDocument,
          variables: { courseId },
        });

        dispatch(pushSnack({ message: 'Scheduled Freeze deleted!' }));
      },
    }
  );

  const groups =
    [...(teacherData?.teacher.activeCourse?.groups || [])].sort(
      (first, second) => {
        return first.name.localeCompare(second.name, 'en', { numeric: true });
      }
    ) || [];

  const openFreezeScheduler = () => setScheduleFreezeOpen(true);
  const closeFreezeScheduler = () => {
    setEditFreeze(undefined);
    setScheduleFreezeOpen(false);
  };

  const handleChangeFreeze = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateGroup({
      variables: {
        groupId: event.target.name,
        freezePersonalDeck: event.target.checked,
      },
    });
  };

  const handleEdit = (freeze: ScheduledFreezesQuery['scheduledFreezes'][0]) => {
    return () => {
      setEditFreeze(freeze);
      openFreezeScheduler();
    };
  };

  const handleDelete = (
    freeze: ScheduledFreezesQuery['scheduledFreezes'][0]
  ) => {
    const inProgress =
      parse(freeze.start, postgresDateFormatString, new Date()) <= today;
    return () => {
      const message = inProgress
        ? 'IMPORTANT: this freeze is already currently in progress. Deleting it will not unfreeze your classes, and you must manually use the toggles above to unfreeze them. Are you sure you want to delete this scheduled freeze? '
        : 'Are you sure you want to delete this scheduled freeze? This cannot be undone.';
      dispatch(
        openConfirmation({
          confirmFunc: () =>
            deleteFreeze({
              variables: {
                scheduledFreezeId: freeze.id,
              },
            }),
          maxWidth: 'md',
          confirmButtonText: 'Delete Scheduled Freeze',
          message,
        })
      );
    };
  };

  const info = (finished: boolean, inProgress: boolean) => {
    let label = 'Pending';
    let title =
      "Personal decks will be automatically frozen on the 'Start' date.";
    if (finished) {
      label = 'Finished';

      title = "Personal decks are unfrozen the day after the 'Finish' date.";
    }
    if (inProgress) {
      label = 'In Progress';
      title =
        "Although this freeze is 'In Progress', you can still manually toggle the freeze off for any your classes above.";
    }
    return (
      <div className={classes.infoContainer}>
        <Chip label={label} variant="outlined" color="primary" size="small" />
        <Tooltip title={title}>
          <HelpOutline />
        </Tooltip>
      </div>
    );
  };
  return (
    <SettingsBody
      title="Freeze Personal Deck"
      description="Determine when your students' Personal Decks should be frozen."
    >
      <>
        <Card className={classes.card}>
          <div className={classes.option}>
            <div className={classes.columns}>
              <div>
                <Typography variant="h5">Freeze Personal Deck</Typography>
                <Typography className={classes.subtitle} variant="body2">
                  If enabled, questions cannot be due in students&apos; personal
                  deck. Enable this option over holidays or long weekends to
                  prevent student personal decks from piling up. You can either
                  manually toggle it for each class below, or you can schedule
                  freezes ahead of time using the &lsquo;Schedule&rsquo; button.
                  <br />
                  <strong>
                    Note: if individual students still want to study, then they
                    can unfreeze their own Personal Deck from the student side.
                  </strong>
                </Typography>
                <FormGroup row>
                  {groups.map((group) => {
                    return (
                      <div key={`settings-freeze-${group.id}`}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={group.freezePersonalDeck}
                              onChange={handleChangeFreeze}
                              name={group.id}
                            />
                          }
                          label={group.name}
                        />
                      </div>
                    );
                  })}
                </FormGroup>
              </div>
              <div>
                <Button
                  onClick={openFreezeScheduler}
                  endIcon={<EventNote />}
                  className={classes.expandButton}
                >
                  Schedule
                </Button>
              </div>
            </div>
            {groups.map((group) => {
              if (group.unfreezeInProgress) {
                const progress =
                  (group.numShifted / group.numPersonalDecksQuestions) * 100;
                return (
                  <div
                    className={classes.loading}
                    key={`unfreeze-in-progress-${group.id}`}
                  >
                    <Typography>
                      {group.name} unfreezing {progress.toFixed(2)}% complete
                    </Typography>
                    <LinearProgress variant="determinate" value={progress} />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </Card>
        <div className={classes.loaderContainer}>
          {loading && <LinearProgress />}
        </div>
        {(data?.scheduledFreezes.length || 0) > 0 && (
          <section className={classes.scheduledDisplay}>
            <Typography variant="h3">Scheduled Freezes</Typography>
            <div>
              {data?.scheduledFreezes.map((freeze) => {
                const finished =
                  parse(freeze.finish, postgresDateFormatString, new Date()) <
                  today;
                const inProgress =
                  !finished &&
                  parse(freeze.start, postgresDateFormatString, new Date()) <=
                    today;
                return (
                  <div key={freeze.id} className={classes.freezeContainer}>
                    <div className={classes.leftColumn}>
                      <div>
                        <Typography variant="h6" color="primary">
                          {freeze.name}
                        </Typography>
                        <div className={classes.datesContainer}>
                          <Typography className={classes.label}>
                            Start:{' '}
                          </Typography>
                          <Typography>{freeze.start}</Typography>
                        </div>
                        <div className={classes.datesContainer}>
                          <Typography className={classes.label}>
                            Finish:{' '}
                          </Typography>
                          <Typography>{freeze.finish}</Typography>
                        </div>
                      </div>
                      <div className={classes.chipsContainer}>
                        {sortGroups(freeze.groups).map((group) => {
                          return (
                            <Chip
                              className={classes.chip}
                              key={`group-${group.id}`}
                              label={group.name}
                              color="primary"
                            />
                          );
                        })}
                      </div>
                    </div>
                    <div className={classes.rightColumn}>
                      <div>{info(finished, inProgress)}</div>
                      <div className={classes.buttonsContainer}>
                        <Button
                          className={clsx(
                            classes.freezeButton,
                            classes.editButton
                          )}
                          disabled={finished || deleteLoading}
                          variant="contained"
                          onClick={handleEdit(freeze)}
                          color="secondary"
                        >
                          Edit
                        </Button>
                        <Button
                          className={clsx(
                            classes.freezeButton,
                            classes.deleteButton
                          )}
                          disabled={finished || deleteLoading}
                          variant="contained"
                          onClick={handleDelete(freeze)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        )}
        <FreezeScheduler
          editFreeze={editFreeze}
          open={scheduleFreezeOpen}
          handleClose={closeFreezeScheduler}
        />
      </>
    </SettingsBody>
  );
}
