import { Add, Delete } from '@mui/icons-material';
import { Button, IconButton, TextField, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  keywordContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
  },
}));

type RequiredKeywordsProps = {
  requiredKeywords: string[];
  updateRequiredKeywordHandler: (
    index: number
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  deleteRequiredKeywordHandler: (index: number) => () => void;
  addRequiredKeyword: () => void;
};

// Standalone component that allows user to configure required keywords for
// free response questions:
export function RequiredKeywords({
  requiredKeywords,
  updateRequiredKeywordHandler,
  deleteRequiredKeywordHandler,
  addRequiredKeyword,
}: RequiredKeywordsProps) {
  const classes = useStyles();
  const [validationError, setValidationError] = useState(new Set());

  return (
    <>
      {requiredKeywords.map((keyword, i) => {
        return (
          <div key={`keyword-${i}`} className={classes.keywordContainer}>
            <TextField
              required
              error={validationError.has(i)}
              helperText={
                validationError.has(i) ? 'Keyword can only be 1 word.' : ''
              }
              placeholder="Required Keyword"
              value={keyword}
              onBlur={() => setValidationError(new Set())}
              onChange={updateRequiredKeywordHandler(i)}
              InputLabelProps={{ shrink: false }}
            />
            <IconButton
              aria-label="delete required keyword"
              onClick={deleteRequiredKeywordHandler(i)}
              size="large"
            >
              <Delete />
            </IconButton>
          </div>
        );
      })}
      <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={addRequiredKeyword}
      >
        Add Required Keyword
      </Button>
    </>
  );
}

export default RequiredKeywords;
