import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { LeaderboardQuery } from '../../../gql/queries/__generated__/leaderboard.generated';
import StyledDialog from '../../shared/Layout/StyledDialog';
import XpDetails from './XpDetails';

const useStyles = makeStyles((theme: Theme) => ({
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

type XpDetailsModalProps = {
  selectedEnrollmentIndex?: number;
  setSelectedEnrollmentIndex: (
    value: React.SetStateAction<number | undefined>
  ) => void;
  leaderboardEnrollments: LeaderboardQuery['leaderboard'];
};

export function XpDetailsModal({
  selectedEnrollmentIndex,
  setSelectedEnrollmentIndex,
  leaderboardEnrollments,
}: XpDetailsModalProps) {
  const classes = useStyles();
  const handleClose = () => setSelectedEnrollmentIndex(undefined);

  if (!leaderboardEnrollments || selectedEnrollmentIndex === undefined)
    return null;

  const selectedEnrollment = leaderboardEnrollments[selectedEnrollmentIndex];
  const previousIndex = selectedEnrollmentIndex - 1;
  const nextIndex = selectedEnrollmentIndex + 1;

  const goToPrevious = () => setSelectedEnrollmentIndex(previousIndex);
  const goToNext = () => {
    setSelectedEnrollmentIndex(nextIndex);
  };
  return (
    <StyledDialog
      open={!!selectedEnrollment}
      title={`${selectedEnrollment.student.fullName}: ${selectedEnrollment.points} Total XP`}
      handleClose={handleClose}
    >
      <div className={classes.buttonsContainer}>
        <Button
          disabled={previousIndex < 0}
          onClick={goToPrevious}
          variant="contained"
          size="small"
        >
          Previous Student
        </Button>
        <Button
          variant="contained"
          disabled={nextIndex === leaderboardEnrollments.length}
          onClick={goToNext}
          size="small"
        >
          Next Student
        </Button>
      </div>
      <div>
        <XpDetails
          enrollmentId={selectedEnrollment.id}
          key={selectedEnrollment.id}
        />
      </div>
    </StyledDialog>
  );
}

export default XpDetailsModal;
