import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Quill } from 'react-quill';
import type { QuestionAttributesFragment } from '../../../gql/fragments/__generated__/question.generated';
import type { RichText } from '../../../utils/question';
import { QuillDeltaAsHtml } from '../QuillDeltaAsHtml';

const Delta = Quill.import('delta');

type StyleProps = {
  reviewable?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(1, 0),
  },
  shortAnswersList: {
    margin: 0,
    paddingLeft: theme.spacing(3),
    lineHeight: 'normal',
    fontSize: ({ reviewable }: StyleProps) =>
      reviewable ? 'calc(12px + 1.1vw)' : 'inherit',
  },
  label: {
    textDecoration: 'underline',
  },
  quillDeltaContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type ShortAnswerBreakdownProps = {
  question: QuestionAttributesFragment;
  reviewable?: boolean;
  hideAnswers?: boolean;
};

const hiddenRichText = new Delta({ ops: [{ insert: 'Answer Hidden' }] });

export function ShortAnswerBreakdown({
  question,
  reviewable,
  hideAnswers,
}: ShortAnswerBreakdownProps) {
  const classes = useStyles({ reviewable });
  if (hideAnswers) {
    return null;
  }

  const adjustRichText = (richText: RichText, text: string) => {
    if (richText.ops.length) {
      return richText.ops;
    }
    return new Delta({ ops: [{ insert: text }] }).ops;
  };

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.label}>
        Accepted Answers
      </Typography>
      <ul className={classes.shortAnswersList}>
        {question.shortAnswerAnswers
          ?.filter((answer) => !answer.hidden)
          .map((answer) => {
            return (
              <li key={answer.id}>
                <div className={classes.quillDeltaContainer}>
                  <QuillDeltaAsHtml
                    delta={
                      hideAnswers
                        ? hiddenRichText.ops
                        : adjustRichText(answer.richText, answer.text)
                    }
                  />
                  {hideAnswers ? null : (
                    <span>
                      {answer.isCaseSensitive
                        ? ' (case sensitive)'
                        : ' (not case sensitive)'}
                    </span>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

export default ShortAnswerBreakdown;
