import { useMutation } from '@apollo/client';
import { Button, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useEffect, useState } from 'react';
import { QuestionWithAuthorAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { UpdateQuestionStandardsDocument } from '../../../../gql/mutations/__generated__/question.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import {
  MultipleStandardsAutocomplete,
  StandardsQueryStandard,
} from '../../Questions/Form/MultipleStandardsAutocomplete';
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  standardTaggerContainer: {
    top: '0',
    position: 'sticky',
    width: 'auto',
    height: 'auto',
    backgroundColor: 'white',
    display: 'flex',
    zIndex: '1',
    margin: '-24px',
    justifyContent: 'space-around',
    padding: theme.spacing(2),
  },
  autocompleteContainer: {
    width: '75%',
  },
  noQuestionsContainer: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  questionContainer: {
    marginTop: '35px',
  },
  loaderContainer: {
    marginLeft: theme.spacing(0.5),
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

/** An update to the standards that a given question relates to */
export type QuestionStandardsUpdated = {
  /** The question whose standards were updated */
  questionId: string;
  /** The standards related to this question with this update */
  standardIds: string[];
};
type StandardQuestionsTaggerProps = {
  options: StandardsQueryStandard[];
  questions: QuestionWithAuthorAttributesFragment[];
  onSelect: (standards: StandardsQueryStandard[]) => void;
  onUpdate: (updatedQuestionStandardIds: QuestionStandardsUpdated[]) => void;
};

export function StandardQuestionsTagger({
  options,
  questions,
  onSelect,
  onUpdate,
}: StandardQuestionsTaggerProps) {
  const classes = useStyles();
  const [selectedStandard, setSelectedStandard] = useState<
    StandardsQueryStandard[]
  >([]);
  const { dispatch } = useContext(AlertsContext);
  const [updatedQuestionStandardIds, setUpdatedQuestionStandardIds] = useState<
    QuestionStandardsUpdated[]
  >([]);
  const [updateStandards] = useMutation(UpdateQuestionStandardsDocument, {
    onCompleted: (e, res) => {
      setUpdatedQuestionStandardIds([
        ...updatedQuestionStandardIds,
        {
          questionId: res?.variables?.questionId,
          standardIds: res?.variables?.standardIds,
        },
      ]);
    },
    update: (cache, res) => {
      questions.forEach((q: QuestionWithAuthorAttributesFragment) => {
        cache.modify({
          id: cache.identify(q),
          fields: {
            standards() {
              const updated = res.data?.updateQuestionStandards || [];
              return updated;
            },
          },
        });
      });
    },
    onError: onError(dispatch),
  });
  const successCheck = () => {
    if (
      updatedQuestionStandardIds.length !== 0 &&
      updatedQuestionStandardIds.length === questions.length
    ) {
      onUpdate(updatedQuestionStandardIds);
    }
  };
  useEffect(() => {}, [updatedQuestionStandardIds]);

  const handleChange =
    () => (_event: unknown, values: StandardsQueryStandard[]) => {
      setSelectedStandard(values);
      if (updatedQuestionStandardIds.length === 0) {
        onSelect(values);
      }
    };

  const handleUpdate = () => {
    setUpdatedQuestionStandardIds([]);
    questions.forEach((q: QuestionWithAuthorAttributesFragment) => {
      const questionId = q.id;
      updateStandards({
        variables: {
          questionId,
          standardIds: selectedStandard.map((standard) => standard.id),
        },
      });
    });
  };

  return (
    <div className={classes.standardTaggerContainer}>
      <div className={classes.autocompleteContainer}>
        <MultipleStandardsAutocomplete
          options={options}
          value={selectedStandard || []}
          onChange={handleChange()}
        />
      </div>
      <Button
        variant="contained"
        onClick={() => {
          handleUpdate();
        }}
        disabled={questions.length === 0 || selectedStandard.length === 0}
      >
        {' '}
        BULK UPDATE{' '}
      </Button>
      {successCheck()}
    </div>
  );
}
