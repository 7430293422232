import HelpKitButton from './HelpKitButton';

export function HelpKitExplanation() {
  return (
    <HelpKitButton
      label="How do 'Explanations' work?"
      articleId="jMuRWgdNohNYwYNsVGUcgu"
    />
  );
}
export default HelpKitExplanation;
