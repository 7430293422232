import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type EnrollmentQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type EnrollmentQuery = {
  __typename?: 'Query';
  enrollment: {
    __typename?: 'Enrollment';
    id: string;
    numDuePersonalDecksQuestions?: number | null;
    student: {
      __typename?: 'Student';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      fullName?: string | null;
      allowNameChange: boolean;
    };
    group?: { __typename?: 'Group'; id: string; name: string } | null;
    assignmentsData?: Array<{
      __typename?: 'StudentsAssignmentsDatum';
      id: string;
      questionsCompleted: number;
      questionsCorrect: number;
      questionsTotal: number;
      distributedPointsEarned?: number | null;
      groupsAssignment?: {
        __typename?: 'GroupsAssignment';
        id: string;
        dueDate?: any | null;
        assignment: {
          __typename?: 'Assignment';
          targetDistributedPoints?: number | null;
          assignmentType: Types.AssignmentTypeEnum;
          id: string;
          name: string;
          assignmentStatus: Types.AssignmentStatusEnum;
          assignmentMethod: Types.AssignmentMethodEnum;
        };
      } | null;
      enrollmentsAssignment?: {
        __typename?: 'EnrollmentsAssignment';
        id: string;
        dueDate: any;
        assignment: {
          __typename?: 'Assignment';
          assignmentType: Types.AssignmentTypeEnum;
          id: string;
          name: string;
          assignmentStatus: Types.AssignmentStatusEnum;
          assignmentMethod: Types.AssignmentMethodEnum;
        };
      } | null;
    }> | null;
  };
};

export type EnrollmentLateAssignmentsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;

export type EnrollmentLateAssignmentsQuery = {
  __typename?: 'Query';
  enrollmentLateAssignments: {
    __typename?: 'Enrollment';
    id: string;
    lateAssignments?: Array<{
      __typename?: 'StudentsAssignmentsDatum';
      id: string;
      questionsCompleted: number;
      questionsTotal: number;
      distributedPointsEarned?: number | null;
      groupsAssignment?: {
        __typename?: 'GroupsAssignment';
        id: string;
        dueDate?: any | null;
        assignment: {
          __typename?: 'Assignment';
          id: string;
          name: string;
          targetDistributedPoints?: number | null;
        };
      } | null;
      enrollmentsAssignment?: {
        __typename?: 'EnrollmentsAssignment';
        id: string;
        dueDate: any;
        assignment: { __typename?: 'Assignment'; id: string; name: string };
      } | null;
      student: { __typename?: 'Student'; id: string; fullName?: string | null };
    }> | null;
  };
};

export const EnrollmentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'enrollment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numDuePersonalDecksQuestions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'student' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'fullName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'allowNameChange' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'group' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'assignmentsData' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCorrect' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'distributedPointsEarned',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupsAssignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'targetDistributedPoints',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignmentType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignmentStatus',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignmentMethod',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentsAssignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignmentType',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignmentStatus',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'assignmentMethod',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnrollmentQuery, EnrollmentQueryVariables>;
export const EnrollmentLateAssignmentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'enrollmentLateAssignments' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enrollmentLateAssignments' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'lateAssignments' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsCompleted' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'questionsTotal' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'distributedPointsEarned',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'groupsAssignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'targetDistributedPoints',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enrollmentsAssignment' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'dueDate' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'assignment' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'student' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'fullName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  EnrollmentLateAssignmentsQuery,
  EnrollmentLateAssignmentsQueryVariables
>;
