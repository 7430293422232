import { useQuery } from '@apollo/client';
import { Chip, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { GroupDocument } from '../../../gql/queries/__generated__/group.generated';
import { NumPdqOverTimeForGroupDocument } from '../../../gql/queries/__generated__/personalDeck.generated';
import { EnrollmentStatusEnum } from '../../../gql/types';
import {
  postgresDateFormatString,
  shortenedDateFormatString,
} from '../../../utils/dates';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { LastUpdated } from '../../shared/LastUpdated';
import { CustomTable } from '../../shared/Table';
import { PersonalDeckDueOverTimeDetails } from './PersonalDeckDueOverTimeDetails';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonLink: {
    ...buttonLink(theme),
  },
  link: {
    fontSize: '14px !important',
  },
  frozenChip: {
    backgroundColor: theme.palette.warning.main,
  },
  refreshContainer: {},
}));

const nextSixDays = [...Array(6)].map((_, i) => {
  const d = new Date();
  d.setDate(d.getDate() + i + 1);
  return {
    key: format(d, postgresDateFormatString),
    render: format(d, shortenedDateFormatString),
  };
});

type PersonalDeckDueOvertimeProps = {
  groupId: string;
};

type DueOverTimeRow = {
  [key: string]: number | string;
};

export function PersonalDeckDueOvertime({
  groupId,
}: PersonalDeckDueOvertimeProps) {
  useTrackVisit({
    section: 'personal-deck',
    page: 'due-over-time',
  });
  const classes = useStyles();
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState('');
  const [selectedDueString, setSelectedDueString] = useState('');
  const [selectedStudentName, setSelectedStudentName] = useState('');
  const { data: groupData } = useQuery(GroupDocument, {
    skip: !groupId,
    variables: { groupId },
  });
  const { data, loading, refetch } = useQuery(NumPdqOverTimeForGroupDocument, {
    skip: !groupId,
    notifyOnNetworkStatusChange: true,
    nextFetchPolicy: 'cache-and-network',
    variables: { groupId },
  });

  const pullNewData = () => refetch();

  const personalDeckFrozen =
    groupData?.group.freezePersonalDeck || groupData?.group.unfreezeInProgress;

  const tableData = useMemo((): DueOverTimeRow[] => {
    if (personalDeckFrozen || !groupData || !data) {
      return [];
    }
    const dueObj = data.numPdqOverTimeForGroup;
    return (
      groupData.group.enrollments
        ?.filter(
          (enrollment) =>
            enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled
        )
        .map<DueOverTimeRow>((enrollment): DueOverTimeRow => {
          const { id: enrollmentId, student } = enrollment;
          const obj = dueObj[enrollmentId] || {};
          const { due, future, ...otherDates } = obj;

          return {
            due: due || 0,
            enrollmentId,
            student: student.sortName ?? student.email,
            ...otherDates,
            future: future || 0,
          };
        }) ?? []
    );
  }, [personalDeckFrozen, groupData, data]);

  const handleClick = (
    enrollmentId: string,
    dueString: string,
    studentName: string
  ) => {
    return () => {
      setSelectedDueString(dueString);
      setSelectedEnrollmentId(enrollmentId);
      setSelectedStudentName(studentName);
    };
  };

  const handleClose = () => {
    setSelectedDueString('');
    setSelectedEnrollmentId('');
    setSelectedStudentName('');
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Number of Personal Deck Questions Due Over Time
      </Typography>
      <div className={classes.refreshContainer}>
        <LastUpdated refetch={pullNewData} />
      </div>
      <CustomTable
        localization={{
          body: {
            emptyDataSourceMessage: personalDeckFrozen ? (
              <Chip
                size="small"
                variant="outlined"
                className={classes.frozenChip}
                label="Personal Decks Frozen"
              />
            ) : (
              <Typography>No records found</Typography>
            ),
          },
        }}
        isLoading={loading}
        data={tableData}
        options={{
          draggable: false,
          thirdSortClick: false,
          minBodyHeight: '68vh',
          maxBodyHeight: '68vh',
        }}
        columns={[
          {
            title: 'STUDENT',
            field: 'student',
            render: (rowData: DueOverTimeRow) => {
              return (
                <Link
                  className={clsx(classes.link, 'link')}
                  to={`/classes/${groupId}/enrollments/${rowData['enrollmentId']}`}
                >
                  {rowData['student']}
                </Link>
              );
            },
          },
          {
            title: 'CURRENTLY DUE',
            field: 'due',
            render: (rowData: DueOverTimeRow) => {
              if (!rowData['due']) {
                return 0;
              }

              return (
                <button
                  onClick={handleClick(
                    rowData['enrollmentId'].toString(),
                    'due',
                    rowData['student'].toString()
                  )}
                  className={classes.buttonLink}
                >
                  {rowData['due']}
                </button>
              );
            },
          },
          ...nextSixDays.map(({ key, render }) => ({
            title: render,
            field: key,
            render: (rowData: DueOverTimeRow) => {
              if (!rowData[key]) {
                return 0;
              }
              return (
                <button
                  onClick={handleClick(
                    rowData['enrollmentId'].toString(),
                    key,
                    rowData['student'].toString()
                  )}
                  className={classes.buttonLink}
                >
                  {rowData[key]}
                </button>
              );
            },
          })),
          {
            title: '>= 1 WEEK',
            field: 'future',
            render: (rowData: DueOverTimeRow) => {
              if (!rowData['future']) {
                return 0;
              }
              return (
                <button
                  onClick={handleClick(
                    rowData['enrollmentId'].toString(),
                    'future',
                    rowData['student'].toString()
                  )}
                  className={classes.buttonLink}
                >
                  {rowData['future']}
                </button>
              );
            },
          },
        ]}
        components={{
          Toolbar: () => null,
        }}
      />
      <PersonalDeckDueOverTimeDetails
        handleClose={handleClose}
        enrollmentId={selectedEnrollmentId}
        due={selectedDueString}
        studentName={selectedStudentName}
      />
    </div>
  );
}
