import { useQuery } from '@apollo/client';
import {
  Autocomplete,
  LinearProgress,
  TextField,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import {
  SchoolsDocument,
  SchoolsQuery,
} from '../../../../gql/queries/__generated__/school.generated';
import { US, countries } from '../../../../utils/data/countries';
import CreateSchool from './CreateSchool';
import SchoolConfirmationModal from './SchoolConfirmationModal';
import SchoolDisplay from './SchoolDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    width: '95%',
    maxWidth: 1600,
    margin: '0 auto',
  },
  buttonLink: {
    ...buttonLink(theme),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: '0 1 40px',
    paddingBottom: theme.spacing(3),
  },
  formLine: {
    display: 'flex',
  },
  zipField: {
    marginLeft: theme.spacing(1.5),
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  searchResults: {
    flex: '1 1 auto',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'column',
    minHeight: 500,
    borderTop: `1px solid ${theme.palette.common.black}`,
  },
  messageContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  searchTitle: {},
  form: {
    flex: '0 1 auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    width: '100%',
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2.5),
  },
  result: {
    backgroundColor: theme.palette.background.paper,
    padding: `${theme.spacing(3)} ${theme.spacing(1)}`,
    borderColor: theme.palette.primary.main,
    borderTop: 'none',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.mint.light,
    },
  },
}));

type SchoolSetupProps = {
  onCompletedCallback?: () => void;
};

export function SchoolSetup({ onCompletedCallback }: SchoolSetupProps) {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [queryName] = useDebounce(name, 500);
  const [zip, setZip] = useState('');
  const [queryZip] = useDebounce(zip, 500);
  const [country, setCountry] = useState(US);
  const [createSchool, setCreateSchool] = useState(false);

  const [selectedSchool, setSelectedSchool] = useState<
    undefined | SchoolsQuery['schools'][0]
  >();
  const { data, loading } = useQuery(SchoolsDocument, {
    variables: {
      name: queryName,
      country: country.key,
      zip: queryZip,
    },
  });
  const closeModal = () => setSelectedSchool(undefined);
  const switchToCreateSchool = () => setCreateSchool(true);
  const selectSchool = (school: SchoolsQuery['schools'][0]) => {
    return () => {
      setSelectedSchool(school);
    };
  };
  const toggleMode = () => setCreateSchool(false);

  if (createSchool) {
    return (
      <CreateSchool
        toggleMode={toggleMode}
        onCompletedCallback={onCompletedCallback}
      />
    );
  }

  return (
    <div className={classes.root}>
      <form className={classes.form}>
        <Typography variant="h4" className={classes.title}>
          School Search
        </Typography>

        <div className={classes.formLine}>
          <Autocomplete
            value={country}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            id="countries"
            onChange={(e, newValue) => {
              if (newValue) {
                setCountry(newValue);
              }
            }}
            options={countries}
            style={{ width: '50%' }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                label="Country"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <TextField
            className={classes.zipField}
            label="Zip Code (Optional)"
            variant="outlined"
            style={{ width: '50%' }}
            value={zip}
            onChange={(e) => {
              setZip(e.target.value);
            }}
          />
        </div>
        <TextField
          label="School Name (Required)"
          className={classes.textField}
          fullWidth
          variant="outlined"
          placeholder="Search for your school by name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </form>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.searchTitle}>
          {`Search Results (${data?.schools ? data.schools.length : 0})`}
        </Typography>
        {queryName && (
          <button className={classes.buttonLink} onClick={switchToCreateSchool}>
            Don&apos;t see your school? Add your school here!
          </button>
        )}
      </div>
      <div className={classes.searchResults}>
        {loading && <LinearProgress />}
        {!queryName && (
          <div className={classes.messageContainer}>
            <Typography>
              Type in your school name to search for your school.
            </Typography>
          </div>
        )}
        {data?.schools.map((school) => (
          <button
            onClick={selectSchool(school)}
            key={`${school.id}-school`}
            className={classes.result}
          >
            <SchoolDisplay school={school} />
          </button>
        ))}
      </div>
      <SchoolConfirmationModal
        school={selectedSchool}
        handleClose={closeModal}
        onCompletedCallback={onCompletedCallback}
      />
    </div>
  );
}

export default SchoolSetup;
