export type Question = {
  id: number;
  question_type: string;
  question_text: string;
  question_answer: string;
  options: string[];
};

export type Concept = {
  text: string;
  id: number;
  approved: boolean;
  flagged: boolean;
};

export type ConceptHolder = {
  concept: Concept;
  question: Question;
};

export type Standard = {
  id: number;
  name: string;
};

export type StandardHolder = {
  standard: Standard;
  concepts: ConceptHolder[];
};

export type Topic = {
  id: number;
  name: string;
};

export type TopicHolder = {
  topic: Topic;
  standards: StandardHolder[];
};

export const ENDPOINT = 'https://spacing-in-stem-5a4602ce425b.herokuapp.com';
