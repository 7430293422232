import { Route, useLocation } from 'react-router-dom';
import { StandardsIndex } from './StandardsIndex';

export function Standards() {
  const location = useLocation();

  return (
    <Route path="/progress">
      <StandardsIndex key={location.key} />
    </Route>
  );
}
