import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StandardsChartQuery } from '../../../gql/queries/__generated__/standardsChart.generated';
import ListDisplay from './ListDisplay';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  standardsContainer: {
    padding: theme.spacing(2),
  },
  categoryContainer: {
    marginBottom: theme.spacing(2),
  },
  standard: {
    paddingLeft: theme.spacing(2),
  },
  categoryTitle: {
    textDecoration: 'underline',
  },
  category: {
    marginBottom: theme.spacing(1),
  },
}));

type StandardsChartPreviewProps = {
  standardsChart: StandardsChartQuery['standardsChart'];
};

export function StandardsChartPreview({
  standardsChart,
}: StandardsChartPreviewProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ListDisplay>
        <div className={classes.standardsContainer}>
          {standardsChart.standardsCategories.map((category) => {
            return (
              <div
                key={`cate-${category.id}`}
                className={classes.categoryContainer}
              >
                <Typography variant="body1" className={classes.category}>
                  <strong className={classes.categoryTitle}>
                    {category.category}
                  </strong>
                </Typography>
                {category.standards.map((standard) => {
                  return (
                    <li
                      className={classes.standard}
                      key={`standard-${standard.id}`}
                    >
                      <Typography variant="caption">
                        <strong>{standard.title}</strong>
                        {` - ${standard.description}`}
                      </Typography>
                    </li>
                  );
                })}
              </div>
            );
          })}
        </div>
      </ListDisplay>
    </div>
  );
}

export default StandardsChartPreview;
