import { Route, Switch, type RouteComponentProps } from 'react-router-dom';
import { NotFound } from '../../components/application/ErrorPages/NotFound';
import GroupsAssignmentsDetailsContainer from '../../components/application/Groups/Assignments/DetailsContainer';
import { StudentProfile } from '../../components/application/StudentProfile';
import { GroupShow } from './GroupShow';

export function Groups({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route
        path={`${match.path}/:groupId/enrollments/:enrollmentId`}
        component={StudentProfile}
      />
      <Route
        path={`${match.path}/:groupId/assignments/:assignmentId`}
        component={GroupsAssignmentsDetailsContainer}
      />
      <Route path={`${match.path}/:groupId`}>
        <GroupShow />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
}
