import { useMutation, useQuery } from '@apollo/client';
import { Button, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SwitchStandardsChartDocument } from '../../../gql/mutations/__generated__/team.generated';
import { StandardsChartDocument } from '../../../gql/queries/__generated__/standardsChart.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { config } from '../../../podsie-config.js';
import StandardsChartPreview from './StandardsChartPreview';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  backButtonContainer: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
  },
  backButton: {
    color: theme.palette.common.white,
  },
  main: {
    marginTop: theme.spacing(2),
  },
  container: {
    maxHeight: '50vh',
    overflow: 'auto',
  },
  cancelButton: {
    marginRight: theme.spacing(1),
    backGroundColor: theme.palette.grays.main,
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}));

type ImportOfficialStandardsProps = {
  resetModalContent: () => void;
};

const officialStandardsChartId = config.officialStandardsChartId.toString();

export function ImportOfficialStandards({
  resetModalContent,
}: ImportOfficialStandardsProps) {
  const classes = useStyles();

  const [switchStandardsChart] = useMutation(SwitchStandardsChartDocument, {
    onCompleted: () => window.location.reload(),
  });

  const teacherQuery = useQuery(TeacherDocument);
  const activeCourse = teacherQuery.data?.teacher?.activeCourse;

  const standardsChartQuery = useQuery(StandardsChartDocument, {
    variables: { standardsChartId: officialStandardsChartId },
  });
  const standardsChart = standardsChartQuery.data?.standardsChart;

  const handleSwitchStandardsChart = () => {
    if (!standardsChart || !activeCourse) return;

    switchStandardsChart({
      variables: {
        courseId: activeCourse.id,
        standardsChartId: standardsChart.id,
      },
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.backButtonContainer}>
        <Button
          className={classes.backButton}
          onClick={resetModalContent}
          variant="contained"
          color="secondary"
        >
          Back
        </Button>
      </div>
      <div>
        <Typography>
          Are you sure you want to import and use the 6-8, AlgI Common Core
          Standards for this course?
        </Typography>

        <div className={classes.buttonsContainer}>
          <Button
            variant="contained"
            className={classes.cancelButton}
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSwitchStandardsChart}
          >
            Confirm
          </Button>
        </div>
      </div>
      <div className={classes.main}>
        <Typography color="primary" variant="h4" onClick={resetModalContent}>
          6-8, AlgI Common Core Standards
        </Typography>
        <div className={classes.container}>
          {!!standardsChart && (
            <StandardsChartPreview standardsChart={standardsChart} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ImportOfficialStandards;
