import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateCourseMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  level: Types.Scalars['String']['input'];
  subject: Types.Scalars['String']['input'];
  jurisdictionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  jurisdictionTitle?: Types.InputMaybe<Types.Scalars['String']['input']>;
  jurisdictionCountry?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreateCourseMutation = {
  __typename?: 'Mutation';
  createCourse: { __typename?: 'Course'; id: string };
};

export type CreateFromOldCourseMutationVariables = Types.Exact<{
  courseId: Types.Scalars['Int']['input'];
}>;

export type CreateFromOldCourseMutation = {
  __typename?: 'Mutation';
  createFromOldCourse: { __typename?: 'Course'; id: string; name: string };
};

export type UpdateCourseSettingsMutationVariables = Types.Exact<{
  courseId: Types.Scalars['ID']['input'];
  recurringWday?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  recurringWdayOn?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  recurringLateAssignmentNotification?: Types.InputMaybe<
    Types.Scalars['Boolean']['input']
  >;
  recurringPdqNotification?: Types.InputMaybe<
    Types.Scalars['Boolean']['input']
  >;
  assignmentAlmostDueNotification?: Types.InputMaybe<
    Types.Scalars['Boolean']['input']
  >;
  leaderboardSetting?: Types.InputMaybe<Types.LeaderboardSettingEnum>;
}>;

export type UpdateCourseSettingsMutation = {
  __typename?: 'Mutation';
  updateCourseSettings: {
    __typename?: 'Course';
    id: string;
    recurring1: boolean;
    recurring2: boolean;
    recurring3: boolean;
    recurring4: boolean;
    recurring5: boolean;
    recurring6: boolean;
    recurring0: boolean;
    recurringLateAssignmentNotification: boolean;
    recurringPdqNotification: boolean;
    assignmentAlmostDueNotification: boolean;
    leaderboardSetting: Types.LeaderboardSettingEnum;
  };
};

export type UpdateStudentAiFeaturesForCourseMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  studentFacingAiEnabled: Types.Scalars['Boolean']['input'];
}>;

export type UpdateStudentAiFeaturesForCourseMutation = {
  __typename?: 'Mutation';
  updateCourseSettings: {
    __typename?: 'Course';
    id: string;
    studentFacingAiEnabled: boolean;
  };
};

export type UpdateCourseMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  archived?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  active?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  level?: Types.InputMaybe<Types.Scalars['String']['input']>;
  subject?: Types.InputMaybe<Types.Scalars['String']['input']>;
  courseId: Types.Scalars['ID']['input'];
  jurisdictionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  jurisdictionTitle?: Types.InputMaybe<Types.Scalars['String']['input']>;
  jurisdictionCountry?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type UpdateCourseMutation = {
  __typename?: 'Mutation';
  updateCourse: {
    __typename?: 'Course';
    name: string;
    id: string;
    level: {
      __typename?: 'Level';
      id: string;
      name: string;
      visibility: Types.VisibilityEnum;
    };
    groups: Array<{
      __typename?: 'Group';
      id: string;
      name: string;
      enableRelaxedSpacing: boolean;
      enableImmediatelyDue: boolean;
      freezePersonalDeck: boolean;
      freezeDate?: any | null;
      unfreezeInProgress: boolean;
      numEnrollments: number;
      numShifted: number;
    }>;
  };
};

export const CreateCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'level' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subject' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionCountry' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'level' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'level' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subject' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subject' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionTitle' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionTitle' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionCountry' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionCountry' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;
export const CreateFromOldCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createFromOldCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFromOldCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateFromOldCourseMutation,
  CreateFromOldCourseMutationVariables
>;
export const UpdateCourseSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCourseSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringWday' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringWdayOn' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'recurringLateAssignmentNotification',
            },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'recurringPdqNotification' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'assignmentAlmostDueNotification' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'leaderboardSetting' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'LeaderboardSettingEnum' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCourseSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recurringWday' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'recurringWday' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recurringWdayOn' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'recurringWdayOn' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'recurringLateAssignmentNotification',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'recurringLateAssignmentNotification',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recurringPdqNotification' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'recurringPdqNotification' },
                },
              },
              {
                kind: 'Argument',
                name: {
                  kind: 'Name',
                  value: 'assignmentAlmostDueNotification',
                },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'assignmentAlmostDueNotification',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'leaderboardSetting' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'leaderboardSetting' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurring1' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurring2' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurring3' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurring4' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurring5' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurring6' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurring0' } },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'recurringLateAssignmentNotification',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recurringPdqNotification' },
                },
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'assignmentAlmostDueNotification',
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'leaderboardSetting' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCourseSettingsMutation,
  UpdateCourseSettingsMutationVariables
>;
export const UpdateStudentAiFeaturesForCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateStudentAIFeaturesForCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'studentFacingAiEnabled' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Boolean' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCourseSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'studentFacingAiEnabled' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'studentFacingAiEnabled' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studentFacingAiEnabled' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateStudentAiFeaturesForCourseMutation,
  UpdateStudentAiFeaturesForCourseMutationVariables
>;
export const UpdateCourseDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCourse' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'archived' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'active' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'level' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'subject' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionTitle' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jurisdictionCountry' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCourse' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'archived' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'archived' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'active' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'active' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'level' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'level' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'subject' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'subject' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionTitle' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionTitle' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jurisdictionCountry' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jurisdictionCountry' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'level' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'visibility' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'groups' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enableRelaxedSpacing' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enableImmediatelyDue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freezePersonalDeck' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'freezeDate' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'unfreezeInProgress' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numEnrollments' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numShifted' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;
