import { Cancel, CheckCircle } from '@mui/icons-material';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { AssessmentValueEnum, QuestionTypeEnum } from '../../../../gql/types';
import {
  ASSESSMENT_VALUE_TO_EMOJI,
  ASSESSMENT_VALUE_TO_NUM,
} from '../../../../utils/assessmentValueMappings';
import Emoji from '../../../shared/Emoji';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { QuestionsBreakdownContext } from './QuestionsBreakdownContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  freeResponseContainer: {
    maxHeight: '40vh',
    overflowY: 'auto',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    maxHeight: '40vh',
    overflowY: 'auto',
  },
  emoji: {
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
  responseRow: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  answer: {
    marginLeft: theme.spacing(1),
  },
  x: {
    marginRight: theme.spacing(1),
    color: theme.palette.accent.main,
  },
  check: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  noResponses: {
    marginTop: theme.spacing(1),
  },
  row: {
    height: 63,
  },
}));

type BreakdownDetailsResponsesProps = {
  question: QuestionAttributesFragment;
};

export function BreakdownDetailsResponses({
  question,
}: BreakdownDetailsResponsesProps) {
  const { questionsBreakdown, hideAnswers } = useContext(
    QuestionsBreakdownContext
  );
  const anonymized = hideAnswers !== undefined;
  const classes = useStyles({ anonymized });
  const { shortAnswerBreakdown = {}, freeResponseBreakdown = {} } =
    questionsBreakdown || {};
  const responses: {
    student: string;
    answer: any;
    selfAssessment?: string;
    isCorrect?: boolean;
  }[] = [];
  const anonymizedResponses: {
    [answer: string]: {
      count: number;
      isCorrect: boolean;
    };
  } = {};
  if (question.questionType === QuestionTypeEnum.FreeResponse) {
    freeResponseBreakdown[question.id] &&
      Object.keys(freeResponseBreakdown[question.id]).forEach(
        (selfAssessment) => {
          Object.keys(
            freeResponseBreakdown[question.id][selfAssessment]
          ).forEach((studentId) => {
            responses.push({
              ...freeResponseBreakdown[question.id][selfAssessment][studentId],
              selfAssessment,
            });
          });
        }
      );
  }

  if (question.questionType === QuestionTypeEnum.ShortAnswer) {
    shortAnswerBreakdown[question.id] &&
      Object.keys(shortAnswerBreakdown[question.id]).forEach((answer) => {
        Object.keys(shortAnswerBreakdown[question.id][answer]).forEach(
          (studentId) => {
            const { student, isCorrect } =
              shortAnswerBreakdown[question.id][answer][studentId];
            if (anonymizedResponses[answer.trim()]) {
              anonymizedResponses[answer.trim()].count += 1;
            } else {
              anonymizedResponses[answer.trim()] = {
                count: 1,
                isCorrect,
              };
            }
            responses.push({
              student,
              isCorrect,
              answer,
            });
          }
        );
      });
  }

  const shortAnswerIcon = (isCorrect?: boolean) => {
    if (question.questionType !== QuestionTypeEnum.ShortAnswer) {
      return null;
    }

    if (isCorrect) {
      return <CheckCircle className={classes.check} />;
    }

    return <Cancel className={classes.x} />;
  };

  if (responses.length === 0) {
    return <div className={classes.noResponses}>No responses yet</div>;
  }

  const renderShortAnswerResponses = () => {
    if (anonymized) {
      return (
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table aria-label="short answer responses table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Answer</TableCell>
                <TableCell>Student Responses</TableCell>
                <TableCell>Correct</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(anonymizedResponses)
                .sort((a, b) => {
                  const { count: countA } = anonymizedResponses[a];
                  const { count: countB } = anonymizedResponses[b];
                  return countB - countA;
                })
                .map((answer) => {
                  const { isCorrect, count } = anonymizedResponses[answer];
                  return (
                    <TableRow key={answer} className={classes.row}>
                      <TableCell>{answer}</TableCell>
                      <TableCell>{count}</TableCell>
                      <TableCell>
                        {hideAnswers === false
                          ? shortAnswerIcon(isCorrect)
                          : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return responses
      .sort(({ answer: a }, { answer: b }) => a.localeCompare(b))
      .map(({ answer, isCorrect, selfAssessment, student }) => {
        return (
          <div className={classes.responseRow} key={`${student}-${answer}`}>
            {selfAssessment && (
              <Emoji
                className={classes.emoji}
                symbol={
                  ASSESSMENT_VALUE_TO_EMOJI(
                    selfAssessment as AssessmentValueEnum
                  ).emoji
                }
              />
            )}
            {shortAnswerIcon(isCorrect)}
            <strong>{student}:</strong>
            <span className={classes.answer}>{answer}</span>
          </div>
        );
      });
  };

  switch (question.questionType) {
    case QuestionTypeEnum.ShortAnswer:
      return <div className={classes.root}>{renderShortAnswerResponses()}</div>;
    case QuestionTypeEnum.FreeResponse:
      return (
        <div className={classes.freeResponseContainer}>
          {responses
            .sort(({ selfAssessment: a }, { selfAssessment: b }) =>
              a === undefined || b === undefined
                ? 0
                : ASSESSMENT_VALUE_TO_NUM(a as AssessmentValueEnum) -
                  ASSESSMENT_VALUE_TO_NUM(b as AssessmentValueEnum)
            )
            .map(({ answer, selfAssessment, student }) => {
              return (
                <div
                  className={classes.responseRow}
                  key={`${student}-${answer}`}
                >
                  {selfAssessment && (
                    <Emoji
                      className={classes.emoji}
                      symbol={
                        ASSESSMENT_VALUE_TO_EMOJI(
                          selfAssessment as AssessmentValueEnum
                        ).emoji
                      }
                    />
                  )}
                  {!anonymized && <strong>{student}:</strong>}
                  <div>
                    <QuillDeltaAsHtml delta={answer.ops} />
                  </div>
                </div>
              );
            })}
        </div>
      );
    default:
      return null;
  }
}
