import { useMutation } from '@apollo/client';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import {
  UpdateQuestionDocument,
  type UpdateQuestionMutationVariables,
} from '../../../../gql/mutations/__generated__/question.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';
import Form from './Form';
import { QuestionFormProvider } from './context';
import { extractQuestionFormState } from './context/util';

const useStyles = makeStyles(() => ({
  questionFormContainer: {
    overflow: 'scroll',
    height: '100%',
  },
}));

type UpdateQuestionVariationProps = {
  question: QuestionAttributesFragment;
  parentQuestion?: boolean;
  postUpdateCallback?: () => void;
  setTriggerUpdateFormSubmit?: (trigger: boolean) => void;
  triggerUpdateFormSubmit: boolean;
};

export function UpdateQuestionVariation({
  question,
  parentQuestion,
  postUpdateCallback,
  setTriggerUpdateFormSubmit,
  triggerUpdateFormSubmit,
}: UpdateQuestionVariationProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { richText } = question;
  const preloadedState = extractQuestionFormState(question);

  const [updateQuestion, { loading }] = useMutation(UpdateQuestionDocument, {
    onCompleted: () => {
      dispatch(pushSnack({ message: 'Question updated!' }));
      postUpdateCallback?.();
    },
    onError: onError(dispatch),
  });

  const handleSubmit = (
    question: UpdateQuestionMutationVariables
  ): Promise<
    ({ __typename?: 'Question' } & QuestionAttributesFragment) | void
  > => {
    if (setTriggerUpdateFormSubmit) {
      setTriggerUpdateFormSubmit(false);
    }

    return new Promise<QuestionAttributesFragment | void>((resolve) => {
      updateQuestion({ variables: question }).then((data) => {
        resolve(data?.data?.updateQuestion);
      });
    });
  };

  return (
    <div className={classes.questionFormContainer}>
      <QuestionFormProvider preloadedState={preloadedState}>
        <Form
          hideStandards={!parentQuestion}
          existingQuestion={richText}
          submitLoading={loading}
          handleSubmit={handleSubmit}
          triggerSubmit={triggerUpdateFormSubmit}
          hideSubmitButton
        />
      </QuestionFormProvider>
    </div>
  );
}
