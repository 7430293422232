import { useQuery } from '@apollo/client';
import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  convertDateToTime,
  findTimeZone,
  getUnixTime,
  setTimeZone,
} from 'timezone-support';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { PersonalDeckDataDocument } from '../../../gql/queries/__generated__/enrollmentPersonalDeck.generated';
import {
  EnrollmentResponsesByDateDocument,
  type EnrollmentResponsesByDateQuery,
} from '../../../gql/queries/__generated__/personalDecksQuestion.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import type {
  FreeResponseResponse,
  MultipleChoiceResponse,
  Question,
  ShortAnswerResponse,
} from '../../../gql/types';
import { onError } from '../../../utils/apollo/apolloHelper';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { LiveUpdates } from '../../shared/LiveUpdates';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import { CustomTable } from '../../shared/Table';
import { AlertsContext } from '../Alerts/context';
import { ResultsModal } from '../Assignments/AssignmentEditor/Results/ResultsModal';
import InfoAboutFrozenPersonalDeck from './InfoAboutFrozenPersonalDeck';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  link: buttonLink(theme),
  tableContainer: {
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
  header: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    marginRight: theme.spacing(1),
  },
  updatesContainer: {
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
  },
}));

export type PersonalDeckQuestionsResult = {
  question: Question;
  freeResponseResponses: Array<FreeResponseResponse>;
  shortAnswerResponses: Array<ShortAnswerResponse>;
  multipleChoiceResponses: Array<MultipleChoiceResponse>;
};

export type PersonalDeckQuestionsResults = {
  studentsAssignmentsQuestions: EnrollmentResponsesByDateQuery['enrollmentResponsesByDate'];
};

export type PersonalDeckQuestionsInfo = {
  studentsAssignmentsDatum: {
    student: {
      fullName: string;
    };
    groupsAssignment: {
      assignment: { name: string; targetDistributedPoints?: number };
      dueDate: Date;
      group: { name: string };
    };
    questionsTotal: number;
    distributedPointsEarned?: number;
    questionsCompleted: number;
    questionsAttempted?: number;
    completedAt?: Date;
    questionsCorrect: number;
  };
};
type SelectedInfo = {
  studentName: string;
  groupName: string;
  numCompleted: number;
  numCorrect: number;
  numAttempted: number;
  numDue: number;
};

type DateFilter = {
  start: Date;
  end: Date;
};

type PersonalDeckDailyDataProps = {
  groupId: string;
};

export function PersonalDeckDailyData({ groupId }: PersonalDeckDailyDataProps) {
  useTrackVisit({
    section: 'personal-deck',
    page: 'daily-data',
  });
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const [maxDate, setMaxDate] = useState<Date | undefined>();
  const [dateFilter, setDateFilter] = useState<DateFilter | undefined>();
  const { data: teacherData, loading: teacherLoading } = useQuery(
    TeacherDocument,
    {
      onError: onError(dispatch),
      onCompleted: (data) => {
        const start = new Date(
          new Date().toLocaleDateString('en-US', {
            timeZone: data.teacher.school?.timezone,
          })
        );
        setDateFilter({ start, end: start });
        setMaxDate(start);
      },
    }
  );
  // true if dateFilter.start is today:
  const filteringForToday =
    dateFilter?.start.toDateString() === new Date().toDateString();

  // The purpose of this function is to change the dateFilter to be 00:00:00 of
  // the teacher's configured timezone, so we fetch all responses for that date.
  // It's passed down to the server as a ISO timestamp and we compare it to the
  // created_at field of responses
  const updateFilterTimezone = () => {
    const timezone = teacherData?.teacher.school?.timezone;
    const date = dateFilter?.start;
    if (timezone && date) {
      const inputTimeObj = convertDateToTime(date);
      const zonedTimeObj = setTimeZone(inputTimeObj, findTimeZone(timezone));
      // This is already the start of the date, since the original date
      // object had HH:MM:SS of 0:0:0 and we replaced the timezone
      const start = new Date(getUnixTime(zonedTimeObj));
      return start;
    }
    return date;
  };
  const [enrollmentId, setEnrollmentId] = useState<undefined | string>();
  const [selectedInfo, setSelectedInfo] = useState<undefined | SelectedInfo>();
  const { data: responseData, loading: responseLoading } = useQuery(
    EnrollmentResponsesByDateDocument,
    {
      skip: !dateFilter || !enrollmentId,
      variables: {
        startDate: dateFilter?.start || '',
        endDate: dateFilter?.end || '',
        enrollmentId: enrollmentId || '',
      },
      fetchPolicy: 'cache-and-network',
    }
  );
  const { data, loading } = useQuery(PersonalDeckDataDocument, {
    skip: !dateFilter?.start || !dateFilter?.end,
    variables: {
      groupId,
      startDay: dateFilter?.start.toDateString() || '',
      endDay: dateFilter?.end.toDateString() || '',
    },
    fetchPolicy: 'cache-and-network',
    pollInterval: 7000,
  });
  const handleClose = () => setEnrollmentId(undefined);
  const handleClick = (eid: string, info: SelectedInfo) => {
    return () => {
      setEnrollmentId(eid);
      setSelectedInfo(info);
    };
  };

  const tableData = useMemo(() => {
    const result =
      data?.personalDeckData.map((dataPoint) => {
        return {
          enrollment: dataPoint.enrollment,
          numAttempted: dataPoint.numAttempted,
          numCorrect: dataPoint.numCorrect,
          numCompleted: dataPoint.numCompleted,
          remaining:
            dataPoint.numDueAtStartOfDay - dataPoint.numCompleted < 0
              ? 0
              : dataPoint.numDueAtStartOfDay - dataPoint.numCompleted,
          numDue: dataPoint.numDueAtStartOfDay,
        };
      }) || [];

    return result;
  }, [data]);

  if (!dateFilter || teacherLoading) {
    return <LoadingSkeletons num={20} />;
  }

  return (
    <div className={classes.root}>
      <InfoAboutFrozenPersonalDeck groupId={groupId} />
      <div className={classes.header}>
        <Typography variant="h4" className={classes.heading}>
          Start Date
        </Typography>
        <DatePicker
          maxDate={maxDate}
          value={dateFilter.start}
          onChange={(date) => {
            if (date) {
              setDateFilter({ start: date, end: dateFilter.end });
            }
          }}
          slotProps={{
            textField: {
              size: 'small',
              variant: 'outlined',
            },
          }}
        />
        <Typography variant="h4" className={classes.heading} sx={{ ml: 2 }}>
          End Date
        </Typography>
        <DatePicker
          maxDate={maxDate}
          value={dateFilter.end}
          onChange={(date) => {
            if (date) {
              setDateFilter({ start: dateFilter.start, end: date });
            }
          }}
          slotProps={{
            textField: {
              size: 'small',
              variant: 'outlined',
            },
          }}
        />
      </div>
      <div className={classes.tableContainer}>
        <div className={classes.updatesContainer}>
          <LiveUpdates />
        </div>
        <CustomTable
          isLoading={loading && !data} // only show loading indicator for first load
          components={{
            Toolbar: () => null,
          }}
          data={tableData}
          columns={[
            {
              title: 'STUDENT',
              defaultSort: 'asc',
              field: 'enrollment.student.lastName',
              render: ({ enrollment: { id: enrollmentId, student } }) => (
                <Link
                  to={`/classes/${groupId}/enrollments/${enrollmentId}/personal-deck`}
                >
                  <button className={classes.link}>
                    {student.sortName ?? student.email}
                  </button>
                </Link>
              ),
            },
            // only show this column if we're filtering for only today:
            ...(filteringForToday
              ? [
                  {
                    title: 'Due at Start of Day',
                    field: 'numDue',
                  },
                ]
              : []),

            {
              title: 'Questions Completed',
              field: 'numCompleted',
            },
            // only show this column if we're filtering for only today:
            ...(filteringForToday
              ? [
                  {
                    title: 'Remaining (Due at Start of Day - Completed)',
                    field: 'remaining',
                  },
                ]
              : []),
            {
              title: 'Questions Correct / Questions Attempted',
              field: 'numCorrect',
              customSort: (a, b) => {
                if (a.numAttempted === 0 || b.numAttempted === 0) {
                  return b.numAttempted - a.numAttempted;
                }
                if (a.numAttempted === b.numAttempted) {
                  return b.numAttempted - a.numAttempted;
                }
                const gradeA = a.numCorrect / a.numAttempted;
                const gradeB = b.numCorrect / b.numAttempted;
                return gradeB - gradeA;
              },
              render: ({
                numAttempted,
                numDue,
                numCorrect,
                numCompleted,
                enrollment,
              }) => {
                const text = `${numCorrect} / ${numAttempted}`;
                if (numAttempted === 0) {
                  return text;
                }
                return (
                  <button
                    className={classes.link}
                    onClick={handleClick(enrollment.id, {
                      studentName: enrollment.student.fullName || '',
                      groupName: enrollment.group?.name || '',
                      numCompleted,
                      numCorrect,
                      numDue,
                      numAttempted,
                    })}
                  >
                    {`${text} ${
                      numAttempted
                        ? `(${Math.round((numCorrect / numAttempted) * 100)}%)`
                        : ''
                    }`}
                  </button>
                );
              },
            },
          ]}
        />
      </div>
      <ResultsModal
        multidayFilter={!filteringForToday}
        open={!!enrollmentId}
        loading={responseLoading}
        assignmentLoading={responseLoading}
        handleClose={handleClose}
        data={
          responseData
            ? {
                studentsAssignmentsQuestions:
                  responseData?.enrollmentResponsesByDate,
              }
            : undefined
        }
        assignmentData={
          selectedInfo
            ? {
                studentsAssignmentsDatum: {
                  student: { fullName: selectedInfo.studentName },
                  groupsAssignment: {
                    assignment: { name: 'Personal Deck' },
                    dueDate: dateFilter.start,
                    group: { name: selectedInfo.groupName },
                  },
                  questionsTotal: filteringForToday ? selectedInfo.numDue : 0,
                  questionsCompleted: selectedInfo.numCompleted,
                  questionsCorrect: selectedInfo.numCorrect,
                  questionsAttempted: selectedInfo.numAttempted,
                },
              }
            : undefined
        }
      />
    </div>
  );
}
