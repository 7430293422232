import { AssessmentValueEnum } from '../gql/types';

const dismayed = {
  emoji: '😩',
  label: 'dismayed',
  title: 'Incorrect Response',
  description: 'and the correct answer is not familiar',
};

const slightFrown = {
  emoji: '😕',
  label: 'slight-frown',
  title: 'Incorrect Response',
  description: 'but the correct answer is familiar',
};

const smile = {
  emoji: '🙂',
  label: 'smile',
  title: 'Correct Response',
  description: 'with difficulty recalling',
};

const starStruck = {
  emoji: '🤩',
  label: 'star-struck',
  title: 'Correct Response',
  description: 'with no difficulty recalling',
};

type Emoji = {
  emoji: string;
  label: string;
  title: string;
  description: string;
};

export const ASSESSMENT_VALUES: Array<[AssessmentValueEnum, Emoji]> = [
  [AssessmentValueEnum.Zero, dismayed],
  [AssessmentValueEnum.One, slightFrown],
  [AssessmentValueEnum.Four, smile],
  [AssessmentValueEnum.Five, starStruck],
];

export const ASSESSMENT_VALUE_TO_EMOJI = (value: AssessmentValueEnum) =>
  ({
    [AssessmentValueEnum.Zero]: dismayed,
    [AssessmentValueEnum.One]: slightFrown,
    [AssessmentValueEnum.Two]: slightFrown,
    [AssessmentValueEnum.Three]: smile,
    [AssessmentValueEnum.Four]: smile,
    [AssessmentValueEnum.Five]: starStruck,
  })[value];

export const ASSESSMENT_VALUE_IS_CORRECT = (value: AssessmentValueEnum) =>
  ({
    [AssessmentValueEnum.Zero]: false,
    [AssessmentValueEnum.One]: false,
    [AssessmentValueEnum.Two]: false,
    [AssessmentValueEnum.Three]: false,
    [AssessmentValueEnum.Four]: true,
    [AssessmentValueEnum.Five]: true,
  })[value];

export const ASSESSMENT_VALUE_TO_NUM = (value: AssessmentValueEnum) =>
  ({
    [AssessmentValueEnum.Zero]: 0,
    [AssessmentValueEnum.One]: 1,
    [AssessmentValueEnum.Two]: 2,
    [AssessmentValueEnum.Three]: 3,
    [AssessmentValueEnum.Four]: 4,
    [AssessmentValueEnum.Five]: 5,
  })[value];
