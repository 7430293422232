import { parse } from 'csv-parse/sync';
import {
  BulkFreeResponseInput,
  BulkMultipleChoiceInput,
  BulkSelectAllInput,
  BulkShortAnswerInput,
} from '../../gql/types';

export const parseRows = parse;

function containsOnlyCommasNumbers(text: string) {
  return /^[0-9,.]*$/.test(text);
}

export const parseBulkMultipleChoiceUpload = (
  csv: string | Buffer
): BulkMultipleChoiceInput[] => {
  const rows = parseRows(csv);
  const questionsList: BulkMultipleChoiceInput[] = [];
  for (let i = 1; i < rows.length; i++) {
    const attrs = rows[i];
    const questionNum = i;
    const questionText = attrs[0];
    const deeperLearning = attrs[1];
    const answerChoices = attrs
      .slice(2)
      .filter((elem: string) => elem.trim() !== '');

    if (!questionText) {
      throw new Error(
        `Error: Question text is missing from Question #${questionNum}`
      );
    }

    if (!answerChoices || answerChoices.length < 2) {
      throw new Error(
        `Error: Not enough Answer Choices for Question #${questionNum} (You need at least 2)`
      );
    }

    questionsList.push({
      questionText,
      deeperLearning,
      answerChoices,
    });
  }
  return questionsList;
};

export const parseBulkShortAnswerUpload = (
  csv: string | Buffer
): BulkShortAnswerInput[] => {
  const rows = parseRows(csv);
  const questionsList: BulkShortAnswerInput[] = [];
  for (let i = 1; i < rows.length; i++) {
    const attrs = rows[i];
    const questionNum = i;
    const questionText = attrs[0];
    const deeperLearning = attrs[1];
    const acceptableAnswers = attrs
      .slice(2)
      .filter((elem: string) => elem.trim() !== '');
    if (!questionText) {
      throw new Error(
        `Error: Question text is missing from Question #${questionNum}`
      );
    }

    if (!acceptableAnswers || acceptableAnswers.length === 0) {
      throw new Error(
        `Error: No acceptable answers found for Question #${questionNum}`
      );
    }

    questionsList.push({
      questionText,
      deeperLearning,
      acceptableAnswers,
    });
  }
  return questionsList;
};

export const parseBulkFreeResponseUpload = (
  csv: string | Buffer
): BulkFreeResponseInput[] => {
  const rows = parseRows(csv);
  const questionsList = [];
  for (let i = 1; i < rows.length; i++) {
    const attrs = rows[i];
    const questionText = attrs[0];
    const questionNum = i;
    const deeperLearning = attrs[1];
    const exemplar = attrs[2];
    if (!questionText) {
      throw new Error(
        `Error: Question text is missing from Question #${questionNum}`
      );
    }

    if (!exemplar) {
      throw new Error(
        `Error: Free Response Exemplar Answer is missing from Question #${questionNum}`
      );
    }
    questionsList.push({
      questionText,
      exemplar,
      deeperLearning,
    });
  }
  return questionsList;
};

export const parseBulkSelectAllUpload = (
  csv: string | Buffer
): BulkSelectAllInput[] => {
  const rows = parseRows(csv);
  const questionsList: BulkSelectAllInput[] = [];
  for (let i = 1; i < rows.length; i++) {
    const attrs = rows[i];
    const questionNum = i;
    const questionText = attrs[0];
    const deeperLearning = attrs[1];
    const answerChoices = attrs
      .slice(3)
      .filter((elem: string) => elem.trim() !== '');

    // To handle people who are still using the old multiple choice template
    if (!containsOnlyCommasNumbers(attrs[2])) {
      throw new Error(
        `Error: Question #${questionNum} does not list the correct answer options (see updated mult. choice template)`
      );
    }
    const correctChoiceRefs = attrs[2]
      .trim()
      .split(',')
      .map((x: string) => Number(x) - 1);

    if (!questionText) {
      throw new Error(
        `Error: Question text is missing from Question #${questionNum}`
      );
    }

    if (!answerChoices || answerChoices.length < 2) {
      throw new Error(
        `Error: Not enough Answer Choices for Question #${questionNum} (You need at least 2)`
      );
    }

    if (!correctChoiceRefs.every((e: string) => e < answerChoices.length)) {
      throw new Error(
        `Error: Correct options for Question #${questionNum} references non-existent option`
      );
    }

    questionsList.push({
      questionText,
      deeperLearning,
      answerChoices,
      correctChoiceRefs,
    });
  }
  return questionsList;
};
