import {
  Card,
  CardContent,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, formatDistance } from 'date-fns';
import {
  QuestionTypeEnum,
  type EnrollmentsQuestionsMastery,
  type MultipleChoiceAnswerChoice,
} from '../../../../gql/types';
import { dateFormatWithoutTime } from '../../../../utils/dates';
import {
  questionTypeIcons,
  renderQuestionType,
} from '../../../../utils/renderQuestionType';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { MultipleChoice } from './MultipleChoice';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(2)} ${theme.spacing(0.5)} ${theme.spacing(4)}`,
  },
  richText: {
    border: `1px solid ${theme.palette.grays.dark}`,
    borderRadius: 4,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  questionTypeContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
  },
  icon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    height: 24,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  statsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  innerStatsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  stats: {
    display: 'flex',
    border: `1px solid ${theme.palette.mint.dark}`,
    borderRadius: 4,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  label: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(0.5),
  },
}));

type QuestionCardProps = {
  mastery: EnrollmentsQuestionsMastery;
};

export function QuestionCard({ mastery }: QuestionCardProps) {
  const classes = useStyles();
  const now = new Date();
  const firstSeen = new Date(mastery.firstSeen);
  const pdqDue = new Date(mastery.pdqDue);
  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.header}>
          <div className={classes.questionTypeContainer}>
            <span className={classes.icon}>
              {questionTypeIcons[mastery.question.questionType]}
            </span>
            <span>{renderQuestionType(mastery.question.questionType)}</span>
          </div>
          <div className={classes.statsContainer}>
            <div className={classes.innerStatsContainer}>
              <div className={classes.stats}>
                <Typography className={classes.label} variant="body2">
                  First Seen:
                </Typography>
                <Tooltip title={format(firstSeen, dateFormatWithoutTime)}>
                  <Typography variant="body2">
                    {`${formatDistance(firstSeen, now)} ago`}
                  </Typography>
                </Tooltip>
              </div>
              <div className={classes.stats}>
                <Typography className={classes.label} variant="body2">
                  Due:
                </Typography>
                <Tooltip title={format(pdqDue, dateFormatWithoutTime)}>
                  <Typography variant="body2">
                    {`${formatDistance(pdqDue, now, {
                      addSuffix: true,
                      includeSeconds: false,
                    })}`}
                  </Typography>
                </Tooltip>
              </div>
            </div>
            <div className={classes.innerStatsContainer}>
              <div className={classes.stats}>
                <Typography className={classes.label} variant="body2">
                  Mastery:
                </Typography>
                <Typography variant="body2">{`${mastery.masteryScore}%`}</Typography>
              </div>
              <div className={classes.stats}>
                <Typography className={classes.label} variant="body2">
                  Streak:
                </Typography>
                <Typography variant="body2">{mastery.streak}</Typography>
              </div>
              <div className={classes.stats}>
                <Typography className={classes.label} variant="body2">
                  Overall:
                </Typography>
                <Typography variant="body2">
                  {`${mastery.totalCorrect} / ${mastery.totalAttempts}`}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.richText}>
          <QuillDeltaAsHtml delta={mastery.question.richText.ops} />
        </div>
        {mastery.question.questionType ===
          QuestionTypeEnum.SelectAllMultipleChoice ||
        mastery.question.questionType ===
          QuestionTypeEnum.SelectOneMultipleChoice ? (
          <div>
            <Typography
              className={classes.label}
              color="primary"
              variant="body2"
            >
              Answer Choices:
            </Typography>
            {mastery.question.multipleChoiceAnswerChoices?.map(
              (answerChoice) => {
                return (
                  <MultipleChoice
                    key={answerChoice.id}
                    answerChoice={answerChoice as MultipleChoiceAnswerChoice}
                    answerSubmitted
                    inputId={''}
                    handleInputChange={() => {}}
                    isChecked={false}
                    disableHover
                  />
                );
              }
            )}
            {mastery.question.questionType ===
              QuestionTypeEnum.SelectAllMultipleChoice && (
              <MultipleChoice
                key="none-of-the-above"
                text="None of the above"
                answerSubmitted
                inputId={'none-of-the-above'}
                handleInputChange={() => {}}
                isChecked={false}
                disableHover
              />
            )}
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
}

export default QuestionCard;
