import type { ApolloCache, FetchResult } from '@apollo/client';
import { produce } from 'immer';
import type { CreateAssignmentsQuestionMutation } from '../../gql/mutations/__generated__/assignmentsQuestion.generated';
import { AssignmentDocument } from '../../gql/queries/__generated__/assignment.generated';

export const updateGetAssignmentCache = (assignmentId: string) => {
  return (
    cache: ApolloCache<CreateAssignmentsQuestionMutation>,
    {
      data,
    }: FetchResult<
      CreateAssignmentsQuestionMutation,
      Record<string, unknown>,
      Record<string, unknown>
    >
  ) => {
    const assignmentQuery = cache.readQuery({
      query: AssignmentDocument,
      variables: { assignmentId },
    });
    if (!data || !assignmentQuery) return;

    const updatedAssignment = produce(
      assignmentQuery.assignment,
      (assignmentDraft) => {
        assignmentDraft.questions.push(data.createAssignmentsQuestion.question);
      }
    );

    cache.writeQuery({
      data: { assignment: updatedAssignment },
      query: AssignmentDocument,
      variables: { assignmentId },
    });
  };
};
