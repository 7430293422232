import { useMutation, useQuery } from '@apollo/client';
import {
  Archive,
  RadioButtonChecked,
  ShortText,
  ViewHeadline,
} from '@mui/icons-material';
import {
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import {
  useContext,
  useRef,
  useState,
  type MouseEvent,
  type ReactElement,
} from 'react';
import { OWNER_TYPE, PAGINATION_COUNT } from '..';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import {
  DisableFlaggableDocument,
  ToggleActionItemStateDocument,
} from '../../../../gql/mutations/__generated__/actionItem.generated';
import {
  ActionItemDocument,
  ActionItemsDocument,
  NotificationsCountDocument,
  type ActionItemQuery,
  type ActionItemsQuery,
} from '../../../../gql/queries/__generated__/actionItem.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { ActionItemStateEnum, QuestionTypeEnum } from '../../../../gql/types';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { AlertsContext } from '../../Alerts/context';
import { openConfirmation, pushSnack } from '../../Alerts/context/actions';
import HelpKitFlaggedResponses from '../../HelpKitArticles/HelpKitFlaggedResponses';
import { QuestionShowModal } from '../../Questions/Show/QuestionShowModal';
import { FlaggedResponseAnswers } from './FlaggedResponseAnswers';
import { HandleFlaggedResponse } from './HandleFlaggedResponse';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  drag: {
    width: '100%',
    height: 3,
    position: 'absolute',
    top: 0,
    '&:hover': {
      cursor: 'grab',
    },
  },
  questionType: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    justifyContent: 'space-between',
  },
  icon: {
    color: theme.palette.secondary.main,
    height: 25,
    marginRight: theme.spacing(1),
  },
  info: {
    flexGrow: 1,
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    overflow: 'auto',
  },
  action: {
    background: theme.palette.mint.light,
    borderTop: `1px dashed ${theme.palette.primary.main}`,
    flexShrink: 0,
    padding: theme.spacing(4),
  },
  keyContainer: {
    ...buttonLink(theme),
    padding: 0,
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  explanation: {
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    color: theme.palette.primary.main,
  },
  editQuestionButton: {
    ...buttonLink(theme),
    marginBottom: theme.spacing(1),
    padding: 0,
    fontSize: 16,
  },
  questionTypeText: {
    marginRight: theme.spacing(1),
  },
  responsiveFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(1),
    },
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type FlaggedResponseProps = {
  response: ActionItemQuery['actionItem']['actionable'];
  actionItemId: string;
};

export function FlaggedResponse({
  actionItemId,
  response,
}: FlaggedResponseProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data: teacherData } = useQuery(TeacherDocument);
  const { data } = useQuery(ActionItemDocument, {
    skip: !actionItemId,
    variables: { actionItemId },
  });

  const courseId = teacherData?.teacher.activeCourse?.id || '';
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [disableFlaggable] = useMutation(DisableFlaggableDocument, {
    variables: {
      actionItemId,
    },
    update: (cache, res) => {
      const variables = {
        ownerId: courseId,
        first: PAGINATION_COUNT,
        ownerType: OWNER_TYPE,
        open: true,
      };
      const removeFromResults = cache.readQuery({
        query: ActionItemsDocument,
        variables,
      });
      const addedToResults = cache.readQuery({
        query: ActionItemsDocument,
        variables: { ...variables, open: false },
      });
      if (!removeFromResults) {
        return;
      }
      const removeHash: { [key: string]: boolean } = {};
      res.data?.disableFlaggable.forEach(
        (disabled) => (removeHash[disabled.id] = true)
      );
      const removedFrom = produce(
        removeFromResults.actionItems,
        (actionItemsDraft) => {
          actionItemsDraft.edges = actionItemsDraft.edges?.filter((edge) => {
            if (!edge?.node?.id || removeHash[edge.node.id]) {
              return false;
            }
            return true;
          });
        }
      );
      const added: ActionItemsQuery['actionItems']['edges'] = [];
      removeFromResults.actionItems.edges?.forEach((edge) => {
        if (edge?.node?.id && removeHash[edge.node.id]) {
          const updated = produce(edge, (edgeDraft) => {
            if (!edgeDraft?.node?.state) {
              return;
            }
            edgeDraft.node.state = ActionItemStateEnum.Archived;
          });
          added.push(updated);
        }
      });
      if (addedToResults) {
        const addedTo = produce(
          addedToResults.actionItems,
          (actionItemsDraft) => {
            actionItemsDraft.edges = [
              ...(added || []),
              ...(actionItemsDraft.edges || []),
            ];
            actionItemsDraft.totalCount +=
              res.data?.disableFlaggable?.length || 0;
          }
        );
        cache.writeQuery({
          data: { actionItems: addedTo },
          query: ActionItemsDocument,
          variables: { ...variables, open: false },
        });
      }

      cache.writeQuery({
        data: { actionItems: removedFrom },
        query: ActionItemsDocument,
        variables,
      });
      const numArchived = Object.keys(removeHash).length;
      dispatch(
        pushSnack({ message: `Archived ${numArchived} notification(s)!` })
      );
    },
  });
  const [toggleState, { loading: toggleLoading }] = useMutation(
    ToggleActionItemStateDocument,
    {
      update: (cache, res) => {
        // remove recently toggled item from the list it was toggled away from:
        const variables = {
          ownerId: courseId,
          first: PAGINATION_COUNT,
          ownerType: OWNER_TYPE,
        };
        const open =
          res.data?.toggleActionItemState.state !== ActionItemStateEnum.Open;
        const removedVariables = {
          ...variables,
          open,
        };
        const addedVariables = { ...variables, open: !open };

        const removeFromResults = cache.readQuery({
          query: ActionItemsDocument,
          variables: removedVariables,
        });

        const addedToResults = cache.readQuery({
          query: ActionItemsDocument,
          variables: addedVariables,
        });

        if (res.data?.toggleActionItemState && removeFromResults) {
          const index = removeFromResults.actionItems.edges?.findIndex(
            (actionItem) =>
              actionItem?.node?.id === res.data?.toggleActionItemState.id
          );
          if (addedToResults) {
            const addedTo = produce(
              addedToResults.actionItems,
              (actionItemsDraft) => {
                const edge =
                  removeFromResults.actionItems.edges &&
                  index !== undefined &&
                  index !== -1
                    ? removeFromResults.actionItems.edges[index]
                    : null;
                if (edge) {
                  actionItemsDraft.edges?.unshift(edge);
                  actionItemsDraft.totalCount += 1;
                }
              }
            );
            cache.writeQuery({
              data: { actionItems: addedTo },
              query: ActionItemsDocument,
              variables: addedVariables,
            });
          }
          const removedFrom = produce(
            removeFromResults.actionItems,
            (actionItemsDraft) => {
              if (index !== undefined && index !== -1) {
                actionItemsDraft.edges?.splice(index, 1);
                actionItemsDraft.totalCount -= 1;
              }
            }
          );

          // when something was just archived, check if the open list still has
          // items, and if not, then turn off notification badge:
          if (
            res.data.toggleActionItemState.state ===
              ActionItemStateEnum.Archived &&
            removedFrom.edges?.length === 0 &&
            teacherData
          ) {
            cache.writeQuery({
              data: { notificationsCount: 0 },
              query: NotificationsCountDocument,
              variables: { ownerId: courseId, ownerType: OWNER_TYPE },
            });
          }

          cache.writeQuery({
            data: { actionItems: removedFrom },
            query: ActionItemsDocument,
            variables: removedVariables,
          });
        }
      },
    }
  );

  let questionType = '';
  const question = response.response.question;
  const type = question.questionType;
  let icon: ReactElement | null = null;
  switch (type) {
    case QuestionTypeEnum.FreeResponse:
      questionType = 'Free Response';
      icon = <ViewHeadline />;
      break;
    case QuestionTypeEnum.ShortAnswer:
      questionType = 'Short Answer';
      icon = <ShortText />;
      break;
    case QuestionTypeEnum.SelectAllMultipleChoice:
      questionType = 'Select All';
      icon = <Checkbox />;
      break;
    case QuestionTypeEnum.SelectOneMultipleChoice:
      questionType = 'Multiple Choice';
      icon = <RadioButtonChecked />;
      break;
    default:
      break;
  }

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const handleDisableFlagging = () => {
    dispatch(
      openConfirmation({
        confirmFunc: disableFlaggable,
        confirmButtonText: 'Disable & Archive',
        maxWidth: 'md',
        message:
          'Are you sure you want to disable all future response flagging for this question and auto-archive ALL currently flagged responses for this question?',
      })
    );
  };

  const handleArchive = () => {
    if (!data) {
      return;
    }
    const {
      actionItem: { state, id },
    } = data;
    toggleState({
      variables: {
        actionItemId: id,
        state:
          state === ActionItemStateEnum.Archived
            ? ActionItemStateEnum.Open
            : ActionItemStateEnum.Archived,
      },
    });
  };

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div className={classes.root} ref={rootRef}>
      <div className={classes.info} ref={infoRef}>
        <div className={classes.questionType}>
          <div className={classes.responsiveFlex}>
            <div className={classes.flex}>
              <span className={classes.icon}>{icon}</span>
              <Typography className={classes.questionTypeText}>
                {questionType}
              </Typography>
            </div>
            {type === QuestionTypeEnum.FreeResponse && (
              <HelpKitFlaggedResponses />
            )}
          </div>
          <div>
            <Tooltip title="Archive Options">
              <IconButton
                aria-label="archive options"
                aria-controls="archive-options"
                aria-haspopup="true"
                onClick={handleMenu}
                color="primary"
                size="large"
              >
                <Archive />
              </IconButton>
            </Tooltip>
            <Menu
              id="archive-options"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
              onClick={handleClose}
            >
              <MenuItem onClick={handleArchive} disabled={toggleLoading}>
                {`${
                  data?.actionItem.state === ActionItemStateEnum.Open
                    ? 'Archive This Notification'
                    : 'Unarchive'
                }`}
              </MenuItem>
              <Tooltip title="This option disables all future flagging for this question, and then archives all responses that are currently flagged for this question in your notification">
                <MenuItem onClick={handleDisableFlagging}>
                  Disable Flagging & Archive All
                </MenuItem>
              </Tooltip>
            </Menu>
          </div>
        </div>
        <div className={classes.header}>
          <Typography variant="h5">Question:</Typography>
          <Button className={classes.editQuestionButton} onClick={openModal}>
            <span
              style={{
                marginRight: '1px',
                color: 'black',
              }}
            >
              (
            </span>
            Edit
            <span style={{ color: 'black', marginLeft: '1px' }}>)</span>
          </Button>
        </div>
        <QuillDeltaAsHtml delta={question.richText.ops} />
        <FlaggedResponseAnswers response={response.response} />
      </div>
      <QuestionShowModal
        editable
        open={modalOpen}
        handleClose={closeModal}
        questionId={question.id}
      />
      <div className={classes.action} ref={panelRef}>
        <HandleFlaggedResponse
          response={response}
          actionItemId={actionItemId}
        />
      </div>
    </div>
  );
}
