import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FREE_RESPONSE_CHARACTER_LIMIT } from './FreeResponseAnswer';

const useStyles = makeStyles(() => ({
  limit: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

type TextLimitProps = {
  limitExceeded?: boolean;
  textLength: number;
  maxLength?: number;
};

export function TextLimit({
  limitExceeded,
  textLength,
  maxLength = FREE_RESPONSE_CHARACTER_LIMIT,
}: TextLimitProps) {
  const classes = useStyles();
  return (
    <div className={classes.limit}>
      <Typography
        variant="caption"
        style={{
          color: limitExceeded || textLength >= maxLength ? 'red' : 'inherit',
        }}
      >
        {`${textLength}/${maxLength} characters used`}
      </Typography>
    </div>
  );
}

export default TextLimit;
