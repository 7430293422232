import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    maxWidth: 1200,
    margin: '0 auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
}));

export type SettingsBodyProps = {
  children: ReactElement;
  description: string;
  title: string;
};

export function SettingsBody({
  children,
  description,
  title,
}: SettingsBodyProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h2" color="primary">
            {title}
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </div>
        {children}
      </div>
    </div>
  );
}
