import HelpKitButton from './HelpKitButton';

export function HelpKitDeleteClass() {
  return (
    <HelpKitButton
      label="When should I delete a class?"
      articleId="oaaMMPU8eGmf6pU4Jwoho3"
    />
  );
}
export default HelpKitDeleteClass;
