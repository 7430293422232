import { Info } from '@mui/icons-material';
import { Tooltip, TooltipProps, Typography, type Theme } from '@mui/material';
import type { Variant } from '@mui/material/styles/createTypography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  typography: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
  },
  icon: {
    marginRight: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    fontSize: theme.typography.caption.fontSize,
    maxWidth: 350,
    color: theme.palette.common.black,
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.3)',
  },
}));

type HeaderWithTooltipProps = {
  title: string;
  tooltip: TooltipProps['title'];
  placement?: TooltipProps['placement'];
  variant?: Variant;
  hideIcon?: boolean;
};

export function HeaderWithTooltip({
  hideIcon,
  title,
  tooltip,
  placement,
}: HeaderWithTooltipProps) {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      title={tooltip}
      placement={placement || 'top'}
    >
      <Typography className={classes.typography}>
        {!hideIcon && <Info fontSize="small" className={classes.icon} />}
        <span>{title}</span>
      </Typography>
    </Tooltip>
  );
}
