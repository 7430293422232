import { useQuery } from '@apollo/client';
import { LinearProgress, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { GetUpdateQuestionDocument } from '../../../../gql/queries/__generated__/question.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { UpdateQuestionCard } from './UpdateQuestionCard';

const FOOTER_HEIGHT = 90;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '90vh',
    overflow: 'hidden',
  },
  rootContentWrapper: {
    maxHeight: `calc(90vh - ${FOOTER_HEIGHT}px)`,
    overflow: 'scroll',
  },
  missingData: {
    padding: theme.spacing(3),
  },
  baseQuestionContainer: {
    marginBottom: theme.spacing(4),
  },
}));

type UpdateQuestionProps = {
  questionId: string;
  editingOpen?: boolean;
  triggerUpdateFormSubmit?: boolean;
  postUpdateCallback?: () => void;
  setTriggerUpdateFormSubmit?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
  hideSubmitButton?: boolean;
};

export function UpdateQuestion({
  questionId,
  postUpdateCallback,
  triggerUpdateFormSubmit,
  setTriggerUpdateFormSubmit,
  hideSubmitButton = true,
}: UpdateQuestionProps) {
  const classes = useStyles();

  const { dispatch } = useContext(AlertsContext);
  const { data, loading: questionQueryLoading } = useQuery(
    GetUpdateQuestionDocument,
    {
      pollInterval: 10000,
      variables: { questionId },
      onError: onError(dispatch),
    }
  );

  if (!data && questionQueryLoading) {
    return <LinearProgress />;
  }

  if (!data?.question) {
    return (
      <div className={classes.missingData}>
        <Typography>Missing Data: Question could not be found.</Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.rootContentWrapper}>
        <div className={classes.baseQuestionContainer}>
          <UpdateQuestionCard
            parentQuestion
            postUpdateCallback={postUpdateCallback}
            question={data.question}
            triggerUpdateFormSubmit={triggerUpdateFormSubmit}
            setTriggerUpdateFormSubmit={setTriggerUpdateFormSubmit}
            hideSubmitButton={hideSubmitButton}
          />
        </div>
      </div>
    </div>
  );
}
