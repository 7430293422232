import { useQuery } from '@apollo/client';
import { LinearProgress, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GroupDocument } from '../../../gql/queries/__generated__/group.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import CustomTabs from '../../shared/Layout/Tabs';
import { AlertsContext } from '../Alerts/context';
import { PersonalDeckProgress } from './PersonalDeckProgress';
import QuestionsProgress from './QuestionsProgress';
import { StudentAssignmentTable } from './StudentAssignmentTable';
import { StudentInfo } from './StudentInfo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    backgroundColor: theme.palette.common.white,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

export function StudentProfile() {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { groupId, enrollmentId } = useParams<{
    groupId: string;
    enrollmentId: string;
  }>();
  const { data, loading } = useQuery(GroupDocument, {
    variables: { groupId },
    onError: onError(dispatch),
  });

  if (loading) {
    return <LinearProgress />;
  }

  const basePath = `/classes/${groupId}/enrollments/${enrollmentId}`;
  const questionsPath = `${basePath}/questions-progress`;
  const personalDeckPath = `${basePath}/personal-deck`;

  if (!data?.group) {
    return null;
  }

  return (
    <div className={classes.root}>
      <StudentInfo enrollmentId={enrollmentId} groupId={groupId} />
      <CustomTabs
        tabs={[
          {
            label: 'ASSIGNMENTS',
            path: basePath,
            component: () => (
              <StudentAssignmentTable enrollmentId={enrollmentId} />
            ),
          },
          {
            label: 'QUESTIONS PROGRESS',
            path: questionsPath,
            component: () => <QuestionsProgress enrollmentId={enrollmentId} />,
          },
          {
            label: 'PERSONAL DECK',
            path: personalDeckPath,
            component: () => (
              <PersonalDeckProgress enrollmentId={enrollmentId} />
            ),
          },
        ]}
      />
    </div>
  );
}
