import HelpKitButton from './HelpKitButton';

export function HelpKitQuestionVariations() {
  return (
    <HelpKitButton
      label="What's a Question Variation?"
      articleId="3tzrh7ZuRSnk8bdngyv6NU"
    />
  );
}
export default HelpKitQuestionVariations;
