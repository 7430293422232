import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  footerContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    gap: '1em',
  },
}));

export function FooterContent({
  page,
  onCopyAssignment,
  onCancel,
}: {
  page: number;
  onCopyAssignment: () => void;
  onCancel: () => void;
}) {
  const classes = useStyles();
  return (
    <div className={classes.footerContainer}>
      <Button variant="contained" color="primary" onClick={onCopyAssignment}>
        {page === 1
          ? 'Copy and Add Assignment'
          : 'Copy and Add this Assignment'}
      </Button>

      <Button variant="text" color="primary" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  );
}

export default FooterContent;
