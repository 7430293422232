import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import AssignmentsIndex from '../../components/application/Assignments/AssignmentsIndex';
import { AssignmentsIndexAction } from '../../components/application/Assignments/AssignmentsIndexAction';
import { APP_HEADER_HEIGHT } from '../../components/application/Layout/Header';
import { AssignmentStatusEnum } from '../../gql/types';
import { localStorageAssignmentTabKey } from '../../utils/localStorageKeys';

const ACTIONS_HEIGHT = 100;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: `calc(100vh - ${APP_HEADER_HEIGHT}px)`,
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
    },
  },
  content: {
    width: '100%',
    height: `calc(100vh - ${ACTIONS_HEIGHT + APP_HEADER_HEIGHT}px)`,
    overflow: 'auto',
    flex: '1 1 auto',
    background: theme.palette.background.default,
  },
}));

const stringToAssignmentTabEnum = (val: null | string) => {
  if (!val) {
    return null;
  }
  switch (val) {
    case AssignmentStatusEnum.Active.toString():
      return AssignmentStatusEnum.Active;
    case AssignmentStatusEnum.Pending.toString():
      return AssignmentStatusEnum.Pending;
    case AssignmentStatusEnum.PastDue.toString():
      return AssignmentStatusEnum.PastDue;
    default:
      return null;
  }
};

export function AssignmentsIndexPage() {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const initialValue: AssignmentStatusEnum =
    stringToAssignmentTabEnum(
      localStorage.getItem(localStorageAssignmentTabKey)
    ) || AssignmentStatusEnum.Pending;
  const [value, setValue] = useState<AssignmentStatusEnum>(initialValue);

  return (
    <div className={classes.root}>
      <AssignmentsIndexAction
        filterValue={value}
        setFilterValue={setValue}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <div className={classes.content} id="assignments-index-scroll">
        <AssignmentsIndex assignmentStatus={value} searchValue={searchValue} />
      </div>
    </div>
  );
}
