import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AssignmentStatusEnum } from '../../../../gql/types';
import CustomTabs from '../../../shared/Layout/Tabs';
import { Results } from './Results';
import { PersonalDeckSetup } from './Setup/PersonalDeckSetup';
import { AssignmentEditorContext } from './context';

type PersonalDeckAssignmentDetailsProps = {
  assignmentStatus: AssignmentStatusEnum;
};

export function PersonalDeckAssignmentDetails({
  assignmentStatus,
}: PersonalDeckAssignmentDetailsProps) {
  const {
    assignmentEditor: { id },
  } = useContext(AssignmentEditorContext);
  const history = useHistory();

  const basePath = `/assignments/${id}`;
  const resultsPath = `${basePath}/results`;
  useEffect(() => {
    if (assignmentStatus !== AssignmentStatusEnum.Pending) {
      history.push(resultsPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomTabs
      tabs={[
        { path: basePath, label: 'SETUP', component: PersonalDeckSetup },
        { path: resultsPath, label: 'RESULTS', component: Results },
      ]}
    />
  );
}
