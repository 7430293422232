import { useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  TeacherDocument,
  TeacherLightDocument,
} from '../../gql/queries/__generated__/teacher.generated';
import HelpKitCorrectSchool from './HelpKitArticles/HelpKitCorrectSchool';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  header: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px 15px',
  },
  content: {
    marginTop: theme.spacing(2.5),
    padding: theme.spacing(2.5),
  },
  message: {
    marginBottom: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  container: {
    backgroundColor: theme.palette.mint.main,
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  closeAction: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeActionButton: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(2),
  },
  helpKitContainer: {
    marginTop: theme.spacing(2),
  },
}));

export function DemoConfigWarning() {
  const classes = useStyles();
  const [needsSetup, setNeedsSetup] = useState(false);
  const { data } = useQuery(TeacherDocument);
  const { data: teacherLightData } = useQuery(TeacherLightDocument);
  const demoSchool = data?.teacher.school?.name.startsWith('Demo-Only');
  const activeCourse = teacherLightData?.teacher.activeCourse;
  const demoCourse =
    activeCourse?.level.name.startsWith('Demo-Only') ||
    activeCourse?.subject.name.startsWith('Demo-Only');
  useEffect(() => {
    if (!demoSchool && !demoCourse) {
      return;
    }
    let numActiveEnrollments = 0;
    data?.teacher.activeCourse?.groups.forEach((group) => {
      numActiveEnrollments += group.numEnrollments;
    });
    if (numActiveEnrollments > 1) {
      setNeedsSetup(true);
    }
  }, [
    data?.teacher.activeCourse?.groups,
    data?.teacher.school?.name,
    demoCourse,
    demoSchool,
  ]);
  const handleClose = () => setNeedsSetup(false);
  const renderedComponent = () => {
    return (
      <div className={classes.content}>
        {demoSchool && (
          <div className={classes.container}>
            <Typography
              variant="body1"
              color="primary"
              className={classes.message}
            >
              Your account is currently set to a &lsquo;Demo-Only School&rsquo;.
              In order to use your account, we highly recommend that you
              configure your account to be tied to the correct school.
            </Typography>
            <div className={classes.helpKitContainer}>
              <HelpKitCorrectSchool />
            </div>
            <DialogActions className={classes.actions}>
              <Link to="/settings">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Go to edit school
                </Button>
              </Link>
            </DialogActions>
          </div>
        )}
        {demoCourse && (
          <div className={classes.container}>
            <Typography
              variant="body1"
              color="primary"
              className={classes.message}
            >
              Your currently active course is set to a &lsquo;Demo-Only
              Course&rsquo;. We highly recommend that you edit it with the
              correct info (e.g. name, grade level).
            </Typography>
            <DialogActions className={classes.actions}>
              <Link to="/settings/courses">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                >
                  Go to edit course
                </Button>
              </Link>
            </DialogActions>
          </div>
        )}
      </div>
    );
  };

  return (
    <Dialog open={needsSetup} maxWidth="md" className={classes.root}>
      <DialogTitle className={classes.header} variant="h2">
        Head&apos;s Up!
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>{renderedComponent()}</DialogContent>
      <DialogActions className={classes.closeAction}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.closeActionButton}
          onClick={handleClose}
        >
          I&apos;ll fix this next time!
        </Button>
      </DialogActions>
    </Dialog>
  );
}
