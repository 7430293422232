import { useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format, parse } from 'date-fns';
import { useContext, useMemo } from 'react';
import {
  bubbleGreen,
  bubbleRed,
  bubbleYellow,
} from '../../../assets/shared-styles/Bubble';
import { MasteriesByDueAndEnrollmentDocument } from '../../../gql/queries/__generated__/masteries.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import {
  dateFormatWithoutTime,
  postgresDateFormatString,
} from '../../../utils/dates';
import { QuillDeltaAsHtml } from '../../shared/QuillDeltaAsHtml';
import { CustomTable } from '../../shared/Table';
import { AlertsContext } from '../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 15px',
  },
  bubbleGreen: {
    ...bubbleGreen(),
  },
  bubbleYellow: {
    ...bubbleYellow(),
  },
  bubbleRed: {
    ...bubbleRed(),
  },
  empty: {},
  content: {
    paddingTop: theme.spacing(1.5),
  },
}));

type PersonalDeckDueOverTimeDetailsProps = {
  enrollmentId: string;
  due: string;
  studentName: string;
  handleClose: () => void;
};

const renderHeader = (due: string) => {
  switch (due) {
    case 'due':
      return 'Currently Due';
    case 'future':
      return 'Due in >= 1 Week';
    default:
      return due;
  }
};

export function PersonalDeckDueOverTimeDetails({
  enrollmentId,
  due,
  handleClose,
  studentName,
}: PersonalDeckDueOverTimeDetailsProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(MasteriesByDueAndEnrollmentDocument, {
    onError: onError(dispatch),
    skip: !enrollmentId,
    variables: { due, enrollmentId },
  });
  const tableData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.masteriesByDueAndEnrollment.map((mastery) => ({
      question: mastery.question.richText,
      streak: mastery.streak,
      attempts: mastery.totalAttempts,
      correct: mastery.totalCorrect,
      latestAttempt: mastery.latestAttempt
        ? new Date(mastery.latestAttempt)
        : null,
      due: mastery.due
        ? parse(mastery.due, postgresDateFormatString, new Date())
        : null,
    }));
  }, [data]);
  return (
    <Dialog
      className={classes.root}
      maxWidth="md"
      fullWidth
      open={!!enrollmentId}
      onClose={handleClose}
      scroll="body"
    >
      <DialogTitle className={classes.header} variant="h4">
        {`${studentName} (${renderHeader(due)})`}
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <CustomTable
          isLoading={loading}
          options={{
            thirdSortClick: false,
            minBodyHeight: '68vh',
            maxBodyHeight: '68vh',
          }}
          components={{
            Toolbar: () => null,
          }}
          columns={[
            {
              title: 'Question',
              field: 'question',
              render: (row) => {
                return <QuillDeltaAsHtml delta={row.question.ops} />;
              },
              sorting: false,
            },
            {
              title: 'Due',
              defaultSort: 'asc',
              field: 'due',
              render: ({ due }) => {
                if (!due) {
                  return '';
                }

                return format(due, dateFormatWithoutTime);
              },
            },
            {
              title: 'Streak',
              field: 'streak',
              render: ({ streak }) => {
                let style;
                if (streak > 1.0) {
                  style = classes.bubbleGreen;
                } else if (streak > 0) {
                  style = classes.bubbleYellow;
                } else if (!isNaN(streak)) {
                  style = classes.bubbleRed;
                } else {
                  style = classes.empty;
                }
                return (
                  <div>
                    <span className={style}>{streak}</span>
                  </div>
                );
              },
            },
            {
              title: 'Latest Attempt',
              field: 'latestAttempt',
              render: ({ latestAttempt }) => {
                if (!latestAttempt) {
                  return '';
                }

                return format(latestAttempt, dateFormatWithoutTime);
              },
            },
            { title: 'Total Correct', field: 'correct' },
            { title: 'Total Attempts', field: 'attempts' },
          ]}
          data={tableData}
        />
      </DialogContent>
    </Dialog>
  );
}
