import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Cached, Edit } from '@mui/icons-material';
import {
  Button,
  Chip,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import { useContext, useMemo, useState } from 'react';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import CopyToClipboard from '../../../components/shared/CopyToClipboard';
import { UpdateGroupDocument } from '../../../gql/mutations/__generated__/group.generated';
import { GroupLiteDocument } from '../../../gql/queries/__generated__/group.generated';
import { NewEnrollmentDocument } from '../../../gql/subscriptions/__generated__/enrollment.generated';
import { EnrollmentStatusEnum } from '../../../gql/types';
import { onError } from '../../../utils/apollo/apolloHelper';
import type { StudentRow } from '../../../views/Groups/GroupShow';
import { AlertsContext } from '../Alerts/context';
import { openConfirmation, pushSnack } from '../Alerts/context/actions';
import HelpKitEnrollInClass from '../HelpKitArticles/HelpKitEnrollInClass';
import HelpKitManageClass from '../HelpKitArticles/HelpKitManageClass';
import { GroupUpdateModal } from './GroupUpdateModal';

const useStyles = makeStyles((theme: Theme) => ({
  buttonLink: {
    padding: 0,
    ...buttonLink(theme),
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
  },
  allText: {
    display: 'flex',
    flexFlow: 'column',
  },
  textContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginRight: theme.spacing(1),
  },
  button: {
    color: theme.palette.common.white,
  },
  classCode: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  typographyContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  infoIcon: {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexFlow: 'column',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      alignItems: 'flex-start',
    },
  },
  editButtonContainer: {
    marginBottom: theme.spacing(1),
  },
  innerButtonContainer: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRight: `1px solid ${theme.palette.common.black}`,
  },
  frozenChip: {
    backgroundColor: theme.palette.warning.main,
    marginBottom: theme.spacing(1),
  },
}));

type GroupUpdateDeleteProps = {
  groupId: string;
};

export function GroupUpdateDelete({ groupId }: GroupUpdateDeleteProps) {
  const classes = useStyles();
  const [editMode, setEditMode] = useState(false);
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(GroupLiteDocument, {
    variables: { groupId },
    onError: onError(dispatch),
  });

  const [updateClassCode, { loading: groupUpdateLoading }] = useMutation(
    UpdateGroupDocument,
    {
      variables: { generateNewClassCode: true, groupId },
      onError: onError(dispatch),
      onCompleted: () => {
        dispatch(pushSnack({ message: 'New class code generated.' }));
      },
    }
  );
  useSubscription(NewEnrollmentDocument, {
    variables: { groupId },
    onSubscriptionData: ({ client, subscriptionData }) => {
      if (!subscriptionData.data?.newEnrollment) {
        return;
      }
      const cachedGroup = client.cache.readQuery({
        query: GroupLiteDocument,
        variables: { groupId },
      });
      const updatedGroup = produce(cachedGroup?.group, (draft) => {
        if (!draft || !subscriptionData.data?.newEnrollment.enrollment) {
          return;
        }
        draft.enrollments = [
          subscriptionData.data.newEnrollment.enrollment,
          ...(draft.enrollments || []),
        ];
      });
      if (!updatedGroup) {
        return;
      }

      client.cache.writeQuery({
        data: { group: updatedGroup },
        query: GroupLiteDocument,
        variables: { groupId },
      });
    },
  });

  const students = useMemo(() => {
    if (!data?.group.enrollments) {
      return [];
    }

    const enrolled: StudentRow[] = [];

    data.group.enrollments.forEach(
      ({
        enrollmentStatus,
        id,
        lastActivity,
        student: { email, lastName, sortName },
      }) => {
        if (enrollmentStatus !== EnrollmentStatusEnum.Enrolled) return;

        enrolled.push({
          email,
          id,
          lastActivity: lastActivity ? new Date(lastActivity) : undefined,
          lastName,
          sortName,
        });
      }
    );

    return enrolled;
  }, [data]);

  if (loading) {
    return <LinearProgress />;
  }

  const regenerateClassCode = () => {
    dispatch(
      openConfirmation({
        message:
          'Are you sure you want to generate a new class code? Doing this WILL DEACTIVATE the existing class code.',
        confirmFunc: () => updateClassCode(),
        confirmButtonText: 'GENERATE NEW CLASS CODE',
      })
    );
  };

  const frozenPersonalDeckChip =
    data?.group.freezePersonalDeck || data?.group.unfreezeInProgress ? (
      <Chip
        className={classes.frozenChip}
        variant="outlined"
        size="small"
        label="Personal Decks Frozen"
      />
    ) : null;

  return (
    <>
      <section className={classes.root}>
        <div className={classes.allText}>
          <div className={classes.textContainer}>
            <Typography variant="h4" color="primary" className={classes.label}>
              Class:
            </Typography>
            <Typography variant="body1">{data?.group.name}</Typography>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.typographyContainer}>
              <Typography
                variant="h4"
                color="primary"
                className={classes.label}
              >
                Class Code (
                <HelpKitEnrollInClass />
                ):
              </Typography>
            </span>
            {groupUpdateLoading ? (
              <Typography variant="body1">Loading...</Typography>
            ) : (
              <CopyToClipboard
                copiedText={data?.group.groupCode || ''}
                tooltipPlacement="top"
              >
                <Typography className={classes.classCode} variant="h3">
                  {data?.group.groupCode}
                </Typography>
              </CopyToClipboard>
            )}
            <Tooltip title="Generate a new class code">
              <IconButton
                onClick={regenerateClassCode}
                color="primary"
                size="large"
              >
                <Cached />
              </IconButton>
            </Tooltip>
          </div>
          <div>{frozenPersonalDeckChip}</div>
        </div>
        <div className={classes.buttonsContainer}>
          <div className={classes.editButtonContainer}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<Edit />}
              aria-label="Edit Class "
              title="edit class"
              onClick={() => setEditMode(true)}
              className={classes.button}
            >
              EDIT CLASS
            </Button>
          </div>
          <div>
            <HelpKitManageClass />
          </div>
        </div>
      </section>
      <GroupUpdateModal
        name={data?.group.name || ''}
        groupId={groupId}
        open={editMode}
        setOpen={setEditMode}
        students={students}
      />
    </>
  );
}
