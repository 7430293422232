import { ReactQuillProps } from 'react-quill';
import Editor from '.';

type EditorProps = {
  errorMessage?: string;
  style?: React.CSSProperties;
};

export function InlineEditor(props: EditorProps & ReactQuillProps) {
  const style = props.style || {};
  return (
    <div
      className="inline-react-quill-editor"
      style={{
        minWidth: '200px',
        ...style,
      }}
    >
      <Editor
        value={props.value}
        onChange={props.onChange}
        modules={{
          formula: true,
          toolbar: [['formula']],
        }}
        theme={props.theme}
        defaultValue={''}
        errorMessage={props.errorMessage}
      />
    </div>
  );
}

export default InlineEditor;
