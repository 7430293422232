import { useMutation, useQuery } from '@apollo/client';
import { Button, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { JoinTeamDocument } from '../../../gql/mutations/__generated__/team.generated';
import type { StandardsChartQuery } from '../../../gql/queries/__generated__/standardsChart.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../Alerts/context';
import { openDialog } from '../Alerts/context/actions';
import StandardsChartPreview from './StandardsChartPreview';
import SubjectTeachersList from './SubjectTeachersList';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  container: {
    maxHeight: '30vh',
    overflow: 'auto',
  },
  buttonContainers: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  joinButton: {
    color: theme.palette.common.white,
  },
}));

type GroupPreviewAndJoinProps = {
  standardsChart: StandardsChartQuery['standardsChart'];
  setShowPreview: React.Dispatch<React.SetStateAction<boolean>>;
  groupCode: string;
};

export function GroupPreviewAndJoin({
  groupCode,
  standardsChart,
  setShowPreview,
}: GroupPreviewAndJoinProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data } = useQuery(TeacherDocument, {
    // cache first to prevent weird situations where teacher has another tab
    // open but is currently looking at a different course:
    fetchPolicy: 'cache-first',
  });
  const activeCourseId = data?.teacher.activeCourse?.id || '';
  const [joinTeam] = useMutation(JoinTeamDocument, {
    // refresh the page
    onCompleted: () => window.location.reload(),
    onError: onError(dispatch),
  });

  const handleSubmit = () => {
    if (!activeCourseId) {
      // account for edge cases where we didn't pull down activeCourseId yet:
      dispatch(
        openDialog({
          title: 'Error occurred.',
          message: 'Please try to join the group again!',
          error: true,
          reloadOnClose: true,
        })
      );
      return;
    }

    joinTeam({
      variables: {
        courseId: activeCourseId,
        groupCode,
      },
    });
  };

  const goBack = () => {
    setShowPreview(false);
  };

  const name = standardsChart.title;
  return (
    <div className={classes.root}>
      <section className={classes.section}>
        <Typography>
          Joining this Subject Group will cause for ALL your questions to lose
          their standards tags. Please review the information below to confirm
          that you want to join the <strong>{name}</strong> subject group.
        </Typography>
        <div className={classes.buttonContainers}>
          <Button
            variant="contained"
            color="primary"
            onClick={goBack}
            className={classes.button}
          >
            Go Back
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            className={classes.joinButton}
            color="secondary"
          >
            Join Group
          </Button>
        </div>
      </section>
      <section className={classes.section}>
        <Typography className={classes.title}>
          If you join the <strong>{name}</strong> Subject Group, you will
          collaborate with the following teachers:
        </Typography>
        <div className={classes.container}>
          <SubjectTeachersList groupCode={groupCode} />
        </div>
      </section>
      <section className={classes.section}>
        <Typography className={classes.title}>
          After joining the <strong>{name}</strong> Subject Group, ALL questions
          in your current course will lose their standards tags. You will have
          to retag them with the following standards:
        </Typography>
        <div className={classes.container}>
          <StandardsChartPreview standardsChart={standardsChart} />
        </div>
      </section>
    </div>
  );
}

export default GroupPreviewAndJoin;
