import { useContext, useEffect } from 'react';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import { Notifications } from '../../components/application/Notifications';

export function NotificationsView() {
  const { updateTitle } = useContext(HeaderTitleContext);
  useEffect(() => updateTitle('Notifications'), [updateTitle]);

  return <Notifications />;
}
