import HelpKitButton from './HelpKitButton';

export function HelpKitCorrectSchool() {
  return (
    <HelpKitButton
      label="Why is it so important to set the correct school & timezone for my account?"
      articleId="6DTGhDHsKgNcBWLhAMSnSE"
    />
  );
}
export default HelpKitCorrectSchool;
