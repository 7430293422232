import {
  AccountCircle,
  FormatListBulleted,
  PlaylistAddCheck,
  TrendingUp,
} from '@mui/icons-material';
import {
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AssignmentIndexAttributesFragment } from '../../../../gql/fragments/__generated__/assignment.generated';
import {
  AssignmentMethodEnum,
  AssignmentStatusEnum,
  AssignmentTypeEnum,
} from '../../../../gql/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  listIcon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(0.5),
  },
  groupsList: {
    display: 'flex',
    maxWidth: '30vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  typography: {
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  groupName: {
    marginRight: theme.spacing(0.5),
  },
}));

type AssignmentsIndexInfoProps = {
  assignment: AssignmentIndexAttributesFragment;
};

export function AssignmentsIndexInfo({
  assignment,
}: AssignmentsIndexInfoProps) {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const isPersonalDeck =
    assignment.assignmentType === AssignmentTypeEnum.Distributed;
  const { totalStudents, totalStudentsFinished } = assignment.aggregatedInfo;
  const completionRate = totalStudents
    ? Math.round((totalStudentsFinished / totalStudents) * 100)
    : undefined;
  let groupsListString = '';
  assignment.groupsAssignments?.forEach((ga, i) => {
    const isLastItem = i === (assignment.groupsAssignments?.length || 0) - 1;

    groupsListString += `${ga.group.name}${isLastItem ? '' : ', '}`;
  });

  const hideClassesAssigned =
    assignment.assignmentStatus === AssignmentStatusEnum.Pending ||
    assignment.assignmentMethod === AssignmentMethodEnum.Individual;
  if (matches) {
    return null;
  }

  return (
    <div className={classes.root}>
      {!hideClassesAssigned && (
        <div className={classes.infoContainer}>
          <AccountCircle fontSize="small" className={classes.listIcon} />
          <Tooltip title={groupsListString}>
            <Typography
              color="primary"
              variant="body2"
              className={classes.groupsList}
            >
              {groupsListString}
            </Typography>
          </Tooltip>
        </div>
      )}
      {!isPersonalDeck && (
        <div className={classes.infoContainer}>
          <FormatListBulleted fontSize="small" className={classes.listIcon} />
          <Typography
            color="primary"
            variant="body2"
            className={classes.typography}
          >
            {`${assignment.numQuestions} questions`}
          </Typography>
        </div>
      )}
      {completionRate !== undefined && (
        <div className={classes.infoContainer}>
          <TrendingUp fontSize="small" className={classes.listIcon} />
          <Tooltip
            title={`${totalStudentsFinished} of ${totalStudents} Finished`}
          >
            <Typography
              color="primary"
              variant="body2"
              className={classes.groupsList}
            >
              {`${completionRate}% Completion`}
            </Typography>
          </Tooltip>
        </div>
      )}
      {!!assignment.aggregatedInfo.averageScore && (
        <div className={classes.infoContainer}>
          <PlaylistAddCheck fontSize="small" className={classes.listIcon} />
          <Typography
            color="primary"
            variant="body2"
            className={classes.groupsList}
          >
            {`${assignment.aggregatedInfo.averageScore} Average Score`}
          </Typography>
        </div>
      )}
    </div>
  );
}

export default AssignmentsIndexInfo;
