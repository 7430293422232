import { useContext } from 'react';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { QuestionsBreakdownContext } from '../../../application/Groups/Assignments/QuestionsBreakdownContext';
import { AnswersBreakdown as AnswersBreakdownPresentation } from '../../../shared/Answers/AnswersBreakdown';

type AnswersBreakdownProps = {
  question: QuestionAttributesFragment;
  reviewable?: boolean;
};

export function AnswersBreakdown({
  question,
  reviewable,
}: AnswersBreakdownProps) {
  const { questionsBreakdown, hideAnswers, hideStats } = useContext(
    QuestionsBreakdownContext
  );

  return (
    <AnswersBreakdownPresentation
      question={question}
      questionsBreakdown={questionsBreakdown}
      hideAnswers={hideAnswers}
      hideStats={hideStats}
      reviewable={reviewable}
    />
  );
}
