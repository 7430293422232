import { useQuery } from '@apollo/client';
import { GetApp } from '@mui/icons-material';
import {
  Button,
  DialogContent,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { buttonLink } from '../../../../../../assets/shared-styles/Button-Link';
import {
  AssignmentNumResponsesDocument,
  AssignmentQuestionsDocument,
  AssignmentResultsDocument,
} from '../../../../../../gql/queries/__generated__/assignment.generated';
import { StudentsAssignmentsDataForAssignmentDocument } from '../../../../../../gql/queries/__generated__/studentsAssignmentsDatum.generated';
import { EnrollmentStatusEnum } from '../../../../../../gql/types';
import { onError } from '../../../../../../utils/apollo/apolloHelper';
import { dateFormatWithoutYear } from '../../../../../../utils/dates';
import { useTrackVisit } from '../../../../../../utils/hooks/useTrackVisit';
import { isRichText } from '../../../../../../utils/question';
import { LiveUpdates } from '../../../../../shared/LiveUpdates';
import { QuillDeltaAsHtml } from '../../../../../shared/QuillDeltaAsHtml';
import { CustomTable } from '../../../../../shared/Table';
import { HeaderWithTooltip } from '../../../../../shared/Table/HeaderWithTooltip';
import { AlertsContext } from '../../../../Alerts/context';
import HelpKitAssignmentsGradesView from '../../../../HelpKitArticles/HelpKitAssignmentsGradesView';
import { QuestionShowModal } from '../../../../Questions/Show/QuestionShowModal';
import { AssignmentResult } from '../../Results/AssignmentResult';
import { AttemptsModal } from './AttemptsModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  updatesContainer: {
    marginLeft: theme.spacing(0.5),
  },
  exportButton: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  timestamp: {
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  headerContainer: {
    display: 'flex',
  },
  questionText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
  },
  questionHeaderTooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(1.5),
  },
  buttonLink: {
    ...buttonLink(theme),
  },
  numAttempts: {
    display: 'flex',
    alignItems: 'center',
  },
  doneIcon: {
    marginLeft: theme.spacing(0.5),
  },
}));

interface ResultRow {
  [key: string]: {
    id?: string;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    sortName?: string | null;
    email?: string | null;
    questionsCorrect?: number;
    questionsTotal?: number;
    questionsCompleted?: number;
    questionId?: string;
    completedAt?: Date;
    dueDate?: Date;
    numAttempts?: number;
    complete?: boolean;
  };
}

type AssignmentsDetailsProps = {
  assignmentId: string;
  groupId?: string;
};

const NUMBER_OF_COLUMNS_BEFORE_QUESTIONS_COLUMNS = 3;

type Direction = 'asc' | 'desc' | undefined;

function getRowScore(row: ResultRow) {
  if (row.score.questionsTotal === undefined) {
    return 0;
  } else if (row.score.questionsTotal === 0) {
    return 100;
  } else if (row.score.questionsCorrect === undefined) {
    return 0;
  } else {
    return (row.score.questionsCorrect / row.score.questionsTotal) * 100;
  }
}

export function AttemptsBreakdown({
  assignmentId,
  groupId,
}: AssignmentsDetailsProps) {
  useTrackVisit({
    section: 'assignment',
    page: 'normal-assignment-breakdown-view',
  });
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data: assignmentData, loading } = useQuery(
    AssignmentQuestionsDocument,
    {
      skip: !assignmentId,
      variables: { assignmentId },
      onError: onError(dispatch),
    }
  );

  const {
    data: numResponsesData,
    startPolling,
    stopPolling,
  } = useQuery(AssignmentNumResponsesDocument, {
    skip: !assignmentId,
    variables: { assignmentId, groupId },
  });

  const { data, refetch } = useQuery(AssignmentResultsDocument, {
    skip: !assignmentId,
    variables: { assignmentId, groupId },
    onError: onError(dispatch),
  });

  const [questionModalId, setQuestionModalId] = useState('');
  const [studentModalId, setStudentModalId] = useState('');
  const [enrollmentsAssignmentModalId, setEnrollmentsAssignmentModalId] =
    useState('');
  const [attemptsStudentId, setAttemptsStudentId] = useState('');
  const [attemptsQuestionId, setAttemptsQuestionId] = useState('');
  const [attemptsStudentName, setAttemptsStudentName] = useState('');
  const [sortDirections, setSortDirections] = useState<Direction[]>(['asc']);

  const handleSortUpdate = (colIndex: number) => {
    const updatedSortDirections = new Array(sortDirections.length).fill(
      undefined
    );
    switch (sortDirections[colIndex]) {
      case 'asc':
        updatedSortDirections[colIndex] = 'desc';
        break;
      case 'desc':
        updatedSortDirections[colIndex] = undefined;
        break;
      case undefined:
        updatedSortDirections[colIndex] = 'asc';
        break;
      default:
        break;
    }
    setSortDirections(updatedSortDirections);
  };

  const questions = useMemo(
    () => assignmentData?.assignment.questions || [],
    [assignmentData]
  );
  const questionNumber: { [key: string]: number } = {};
  questions.forEach((question, i) => {
    questionNumber[question.id] = i + 1;
  });

  const handleClick = (e: any) => {
    const sortLabel = e.target.closest('.MuiTableSortLabel-root');
    if (!sortLabel) {
      return;
    }

    const th = sortLabel.parentElement;
    const tr = th.parentElement;
    const index = [...tr.children].indexOf(th);
    handleSortUpdate(index);
  };

  const openStudentModal = (
    studentId: string | undefined,
    enrollmentsAssignmentId: string | undefined
  ) => {
    return () => {
      if (studentId === undefined) {
        return;
      }
      if (enrollmentsAssignmentId) {
        setEnrollmentsAssignmentModalId(enrollmentsAssignmentId);
      }
      setStudentModalId(studentId);
    };
  };

  const openQuestionDetails = (questionId: string) => {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setQuestionModalId(questionId);
    };
  };

  const openAttemptsModal = (
    questionId: string,
    studentId: string,
    studentName: string
  ) => {
    return () => {
      setAttemptsQuestionId(questionId);
      setAttemptsStudentId(studentId);
      setAttemptsStudentName(studentName);
    };
  };
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    // start polling every time location path changes:
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [path, startPolling, stopPolling]);

  const groupsAssignmentId =
    numResponsesData?.assignmentNumResponses.groupsAssignmentId || '';

  const {
    data: studentsAssignmentsData,
    loading: studentsAssignmentsDataLoading,
    refetch: refetchStudentsAssignmentsData,
  } = useQuery(StudentsAssignmentsDataForAssignmentDocument, {
    skip: !assignmentId,
    variables: { assignmentId, groupId },
    onError: onError(dispatch),
    fetchPolicy: 'cache-and-network',
  });

  const [numResponses, setNumResponses] = useState(0);

  useEffect(() => {
    if (
      numResponsesData?.assignmentNumResponses.numResponses === undefined ||
      numResponsesData.assignmentNumResponses.numResponses === null
    ) {
      return;
    }
    const { numResponses: updatedNumResponses } =
      numResponsesData.assignmentNumResponses;
    if (updatedNumResponses !== numResponses) {
      refetch();
      refetchStudentsAssignmentsData();
      setNumResponses(updatedNumResponses);
    }
  }, [
    numResponsesData,
    setNumResponses,
    numResponses,
    refetch,
    refetchStudentsAssignmentsData,
  ]);

  const closeQuestionModal = () => setQuestionModalId('');
  const closeStudentModal = () => {
    setStudentModalId('');
    setEnrollmentsAssignmentModalId('');
  };

  const questionPerformance: { [key: string]: number } = useMemo(
    () => ({}),
    []
  );
  const alreadyCounted: { [key: string]: number } = useMemo(() => ({}), []);
  const detailsData = useMemo(() => {
    if (!data || !studentsAssignmentsData) {
      return [];
    }
    const studentsData =
      studentsAssignmentsData.studentsAssignmentsDataForAssignment.filter(
        (elem) =>
          elem.enrollment.enrollmentStatus === EnrollmentStatusEnum.Enrolled
      );

    const { assignmentResults } = data;
    return studentsData.map((studentsDatum): ResultRow => {
      const {
        student: {
          id: studentId,
          fullName,
          firstName,
          lastName,
          email,
          sortName,
        },
        questionsCorrect,
        questionsCompleted,
        questionsTotal,
        completedAt,
      } = studentsDatum;
      const dueDate = studentsDatum.groupsAssignment?.dueDate;
      const studentPerformance = assignmentResults[studentId] || {};
      const enrollmentsAssignmentId = studentsDatum?.enrollmentsAssignment?.id;
      const studentQuestions: {
        [key: string]: {
          numAttempts: number;
          questionId: string;
          complete: boolean;
        };
      } = {};
      questions.forEach((q) => {
        const { num_attempts: numAttempts, complete } = studentPerformance[
          q.id
        ] || { num_attempts: 0, complete: false };
        const key = `${studentId}-${q.id}`;
        if (alreadyCounted[key] !== numAttempts) {
          if (questionPerformance[q.id]) {
            const diff = numAttempts - (alreadyCounted[key] || 0);
            questionPerformance[q.id] += diff;
          } else {
            questionPerformance[q.id] = numAttempts;
          }
          alreadyCounted[key] = numAttempts;
        }
        studentQuestions[q.id] = {
          numAttempts,
          questionId: q.id,
          complete,
        };
      });
      return {
        student: {
          id: studentId,
          sortName,
          name: fullName,
          firstName,
          lastName,
          email,
        },
        enrollmentsAssignment: {
          id: enrollmentsAssignmentId,
        },
        score: {
          dueDate: new Date(dueDate),
          completedAt: completedAt ? new Date(completedAt) : undefined,
          questionsCorrect,
          questionsTotal,
          questionsCompleted,
        },
        ...studentQuestions,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    studentsAssignmentsData,
    questions,
    questionPerformance,
    alreadyCounted,
  ]);

  const columns = [
    {
      title: 'STUDENT',
      field: 'student',
      render: (row: ResultRow) => {
        return row.student.sortName;
      },
      defaultSort: sortDirections[0],
      customSort: (a: ResultRow, b: ResultRow) =>
        (a.student.sortName ?? '').localeCompare(b.student.sortName ?? ''),
    },
    {
      defaultSort: sortDirections[1],
      title: (
        <HeaderWithTooltip
          variant="body1"
          title="QUESTIONS COMPLETED"
          tooltip="Number of questions completed. A question is completed only when a student has answered the question correctly at least once."
        />
      ),
      field: 'score',
      render: (row: ResultRow) => {
        let color = 'inherit';
        const finished =
          row.score.questionsCompleted === row.score.questionsTotal;
        if (
          row.score.completedAt &&
          row.score.dueDate &&
          row.score.completedAt > row.score.dueDate
        ) {
          color = 'red';
        }

        return (
          <Tooltip
            title={finished ? 'Assignment Finished' : 'Assignment Incomplete'}
          >
            <span style={{ color }}>
              <span>{row.score.questionsCompleted}</span>
              <span className={classes.timestamp}>
                {row.score.completedAt &&
                  `(${format(
                    row.score.completedAt || new Date(),
                    dateFormatWithoutYear
                  )})`}
              </span>
            </span>
          </Tooltip>
        );
      },
      customSort: (a: ResultRow, b: ResultRow) => {
        const numCompletedA = a.score.questionsCompleted || 0;
        const numCompletedB = b.score.questionsCompleted || 0;
        if (numCompletedA === numCompletedB) {
          const updatedAtA = a.score.completedAt?.getTime() || 0;
          const updatedAtB = b.score.completedAt?.getTime() || 0;
          return updatedAtB - updatedAtA;
        }

        return numCompletedB - numCompletedA;
      },
    },
    {
      defaultSort: sortDirections[2],
      title: (
        <HeaderWithTooltip
          variant="body1"
          title="CORRECT / TOTAL"
          tooltip="Number of questions answered correctly out of total number of questions. Question is counted correct only if student answers it correctly on the first attempt."
        />
      ),
      field: 'score',
      render: (row: ResultRow) => {
        const percentage = Math.round(getRowScore(row));
        const score = `${row.score.questionsCorrect} / ${row.score.questionsTotal} (${percentage}%)`;
        return (
          <button
            className={classes.buttonLink}
            onClick={openStudentModal(
              row.student.id,
              row.enrollmentsAssignment.id
            )}
          >
            {score}
          </button>
        );
      },
      customSort: (a: ResultRow, b: ResultRow) => {
        const scoreA = getRowScore(a);
        const scoreB = getRowScore(b);

        return scoreB - scoreA;
      },
    },
    ...questions.map((question, i) => {
      const tooltip =
        isRichText(question.richText) && question.richText.ops.length > 0 ? (
          <QuillDeltaAsHtml
            delta={question.richText.ops}
            style={{ padding: 0 }}
          />
        ) : (
          <Typography className={classes.questionText}>
            {question.plainText}
          </Typography>
        );

      return {
        defaultSort:
          sortDirections[NUMBER_OF_COLUMNS_BEFORE_QUESTIONS_COLUMNS + i],
        title: (
          <HeaderWithTooltip
            hideIcon
            title={`Q${questionNumber[question.id]}${
              questionPerformance[question.id]
                ? `(${questionPerformance[question.id]})`
                : ''
            }`}
            tooltip={
              <div className={classes.questionHeaderTooltip}>
                {tooltip}
                <button
                  onClick={openQuestionDetails(question.id)}
                  className={classes.buttonLink}
                >
                  See Details
                </button>
              </div>
            }
          />
        ),
        field: question.id,
        render: (row: ResultRow) => {
          const { numAttempts, complete } = row[question.id];
          if (numAttempts === 0) {
            return (
              <span
                style={{
                  width: '25px',
                  textAlign: 'center',
                  display: 'inline-block',
                }}
              >
                {`[${numAttempts}]`}
              </span>
            );
          }

          let color = 'black';

          if (numAttempts === 1 && complete) {
            color = 'green';
          } else {
            color = 'red';
          }
          return (
            <button
              onClick={openAttemptsModal(
                question.id,
                row.student.id || '',
                row.student.name || ''
              )}
              style={{ color }}
              className={`${classes.buttonLink} ${classes.numAttempts}`}
            >
              <span
                style={{
                  textAlign: 'center',
                  width: '25px',
                }}
              >
                {complete ? numAttempts : `[${numAttempts}]`}
              </span>
            </button>
          );
        },
        customSort: (a: ResultRow, b: ResultRow) => {
          if (
            a[question.id] &&
            b[question.id] &&
            a[question.id].numAttempts !== undefined &&
            b[question.id].numAttempts !== undefined
          ) {
            return (
              (b[question.id].numAttempts || 0) -
              (a[question.id].numAttempts || 0)
            );
          }
          return 0;
        },
      };
    }),
  ];

  const closeAttemptsModal = () => {
    setAttemptsStudentId('');
    setAttemptsQuestionId('');
    setAttemptsStudentName('');
    setEnrollmentsAssignmentModalId('');
  };

  const exportAsCsv = () => {
    const prepared = detailsData
      .sort((studentA, studentB) =>
        (studentA.student.lastName || '').localeCompare(
          studentB.student.lastName || ''
        )
      )
      .map((student) => {
        const attempts: { [key: string]: string } = {};
        Object.keys(student).forEach((key) => {
          if (
            key === 'student' ||
            key === 'score' ||
            key === 'enrollmentsAssignment'
          ) {
            return;
          }
          const forced = student as any;
          const attemptData = forced[key];
          const numAttempts = attemptData.numAttempts;
          const questionNum = questionNumber[attemptData.questionId];
          const done = attemptData.complete;
          attempts[`Question ${questionNum.toString()}`] = done
            ? numAttempts
            : `[${numAttempts}]`;
        });

        const score =
          typeof student.score.questionsCorrect === 'number' &&
          typeof student.score.questionsTotal === 'number'
            ? `${Math.round(
                (student.score.questionsCorrect /
                  student.score.questionsTotal) *
                  100
              )}%`
            : undefined;

        return {
          'First Name': student.student.firstName || '',
          'Last Name': student.student.lastName || '',
          Email: student.student.email || '',
          score,
          ...attempts,
        };
      });
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(prepared);
  };

  const isLoading = loading || studentsAssignmentsDataLoading;
  return (
    <>
      <DialogContent onClick={handleClick}>
        <div className={classes.buttonsContainer}>
          <div>
            <HelpKitAssignmentsGradesView />
            <div className={classes.updatesContainer}>
              <LiveUpdates />
            </div>
          </div>
          <Button
            startIcon={<GetApp />}
            variant="contained"
            className={classes.exportButton}
            color="secondary"
            onClick={exportAsCsv}
          >
            CSV
          </Button>
        </div>
        <CustomTable
          isLoading={isLoading}
          components={{ Toolbar: () => null, Pagination: () => null }}
          data={detailsData}
          columns={columns}
          options={{
            draggable: false,
            pageSize: 1000,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '85vh',
          }}
        />
      </DialogContent>
      <QuestionShowModal
        questionId={questionModalId}
        open={!!questionModalId}
        handleClose={closeQuestionModal}
      />
      <AssignmentResult
        studentId={studentModalId}
        open={!!studentModalId}
        groupsAssignmentId={groupsAssignmentId}
        enrollmentsAssignmentId={enrollmentsAssignmentModalId}
        handleClose={closeStudentModal}
      />
      <AttemptsModal
        studentId={attemptsStudentId}
        questionId={attemptsQuestionId}
        groupsAssignmentId={groupsAssignmentId}
        studentName={attemptsStudentName}
        onClose={closeAttemptsModal}
      />
    </>
  );
}
