import { Theme } from '@mui/material';

export const customList = (theme: Theme) => ({
  listStyle: 'none',
  counterReset: 'custom-counter',
  paddingLeft: 0,
  '& li': {
    marginBottom: theme.spacing(3),
  },
});

export const customListItem = (theme: Theme) => ({
  display: 'flex',
  marginBottom: theme.spacing(4),
  counterIncrement: 'custom-counter',
  '&:before': {
    content: 'counter(custom-counter) ". "',
    color: theme.palette.primary.main,
    fontSize: theme.typography.h1.fontSize,
    fontWeight: theme.typography.fontWeightLight,
  },
});

export const customListItemWrapper = (theme: Theme) => ({
  marginLeft: theme.spacing(1),
  paddingTop: theme.spacing(2),
  width: '100%',
  display: 'flex',
  flexFlow: 'column',
});
