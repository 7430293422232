import { useQuery } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  EnrollmentLateAssignmentsDocument,
  type EnrollmentLateAssignmentsQuery,
} from '../../../../gql/queries/__generated__/enrollment.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { dateFormatWithoutTime } from '../../../../utils/dates';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { AlertsContext } from '../../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: theme.palette.common.white,
  },
  link: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
  },
}));

type LateAssignmentsModalProps = {
  enrollmentId: string;
  handleClose: () => void;
};

export function LateAssignmentsModal({
  enrollmentId,
  handleClose,
}: LateAssignmentsModalProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(EnrollmentLateAssignmentsDocument, {
    skip: !enrollmentId,
    variables: {
      id: enrollmentId,
    },
    onError: onError(dispatch),
  });

  const lateAssignments: EnrollmentLateAssignmentsQuery['enrollmentLateAssignments']['lateAssignments'] =
    data?.enrollmentLateAssignments.lateAssignments || [];
  const studentName =
    lateAssignments.length > 0 ? lateAssignments[0].student.fullName : '';
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={!!enrollmentId}
    >
      <DialogTitle>{studentName}&apos;s Late Assignments</DialogTitle>
      {loading ? (
        <LoadingSkeletons num={5} />
      ) : (
        <List>
          {lateAssignments.map((datum) => {
            let assignmentName = 'Unknown';
            const assignmentId =
              datum.groupsAssignment?.assignment.id ||
              datum.enrollmentsAssignment?.assignment.id;
            let dueDate = null;
            if (datum.groupsAssignment) {
              assignmentName = datum.groupsAssignment.assignment.name;
              dueDate = datum.groupsAssignment.dueDate;
            } else if (datum.enrollmentsAssignment) {
              assignmentName = datum.enrollmentsAssignment.assignment.name;
              dueDate = datum.enrollmentsAssignment.dueDate;
            }
            let completed = datum.questionsCompleted;
            let total = datum.questionsTotal;
            let label = 'questions completed';

            if (typeof datum.distributedPointsEarned === 'number') {
              completed = datum.distributedPointsEarned;
              total =
                datum.groupsAssignment?.assignment.targetDistributedPoints || 0;
              label = 'points earned';
            }
            return (
              <ListItem key={`late-assignment-${datum.id}`}>
                <ListItemText
                  primary={
                    <>
                      <Link
                        className={classes.link}
                        to={`/assignments/${assignmentId}/results`}
                      >
                        <strong>{assignmentName}</strong>
                      </Link>
                    </>
                  }
                  secondary={
                    <>
                      <Typography>
                        Due: {format(new Date(dueDate), dateFormatWithoutTime)}
                      </Typography>
                      <Typography>
                        {completed} of {total} {label}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      )}
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          className={classes.button}
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
