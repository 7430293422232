import {
  CheckBox,
  CheckBoxOutlineBlank,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import { Avatar, Tooltip, useTheme, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Quill } from 'react-quill';
import type { QuestionAttributesFragment } from '../../../gql/fragments/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../gql/types';
import { stringToColor } from '../../../utils/color';
import type { RichText } from '../../../utils/question';
import { QuillDeltaAsHtml } from '../QuillDeltaAsHtml';

const Delta = Quill.import('delta');
type StyleProps = {
  reviewable: boolean | undefined;
  anonymized: boolean;
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  answerRow: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: ({ reviewable }: StyleProps) =>
      reviewable ? 'calc(12px + 1.1vw)' : 'inherit',
    lineHeight: 'normal',
  },
  avatarContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mc: {
    fontSize: 'inherit',
  },
}));

type StudentAvatarProps = {
  studentId: string;
  studentName: string;
  hideStats?: boolean;
};

function StudentAvatar({
  studentId,
  studentName,
  hideStats,
}: StudentAvatarProps) {
  const theme = useTheme();
  const initialsArr = studentName.match(/\b\w/g) || [];
  const initials = (
    (initialsArr.shift() || '') + (initialsArr.pop() || '')
  ).toUpperCase();

  const backgroundColor = stringToColor(studentName + studentId);
  const color = theme.palette.getContrastText(backgroundColor);

  return (
    <Tooltip title={studentName}>
      <Avatar
        style={{
          height: '25px',
          width: '25px',
          color,
          backgroundColor,
          fontSize: '12px',
          fontWeight: 'bold',
          marginTop: '4px',
          marginRight: '4px',
        }}
      >
        {hideStats !== undefined ? 'S' : initials}
      </Avatar>
    </Tooltip>
  );
}

type IconProps = {
  hideAnswers?: boolean;
  question: QuestionAttributesFragment;
  choice: Pick<ChoiceType, 'isCorrect'>;
  selectAllThatApply: boolean;
  reviewable: boolean | undefined;
};

function Icon({
  hideAnswers,
  choice,
  selectAllThatApply,
  reviewable,
}: IconProps) {
  const theme = useTheme();

  const style = {
    fontSize: reviewable ? 'calc(12px + 1.1vw)' : 'small',
    marginRight: theme.spacing(1),
    fill:
      choice.isCorrect && !hideAnswers
        ? theme.palette.secondary.main
        : theme.palette.grays.dark,
  };

  if (hideAnswers && selectAllThatApply) {
    return <CheckBoxOutlineBlank fontSize="small" style={style} />;
  }
  if (hideAnswers && !selectAllThatApply) {
    return <RadioButtonUnchecked fontSize="small" style={style} />;
  }
  if (selectAllThatApply && choice.isCorrect) {
    return <CheckBox fontSize="small" style={style} />;
  }
  if (selectAllThatApply && !choice.isCorrect) {
    return <CheckBoxOutlineBlank fontSize="small" style={style} />;
  }
  if (!selectAllThatApply && choice.isCorrect) {
    return <RadioButtonChecked fontSize="small" style={style} />;
  }
  if (!selectAllThatApply && !choice.isCorrect) {
    return <RadioButtonUnchecked fontSize="small" style={style} />;
  }
  return null;
}

type ChoiceType = {
  isCorrect: boolean;
  text: string;
  id: string;
  richText?: RichText;
};

type AnswerRowProps = {
  choice: ChoiceType;
  selectAllThatApply: boolean;
  breakdown: any;
  hideAnswers?: boolean;
  question: QuestionAttributesFragment;
  anonymized: boolean;
  hideStats?: boolean;
  reviewable?: boolean;
};

function AnswerRow({
  choice,
  selectAllThatApply,
  breakdown,
  hideAnswers,
  question,
  anonymized,
  hideStats,
  reviewable,
}: AnswerRowProps) {
  const classes = useStyles({ anonymized, reviewable });
  const questionBreakdown = breakdown[question.id]
    ? breakdown[question.id][choice.id]
    : {};
  const choiceText = choice.richText
    ? choice.richText.ops
    : new Delta({ ops: [{ insert: choice.text }] }).ops;

  return (
    <div key={`select-all${choice.id}`} className={classes.answerRow}>
      <Icon
        hideAnswers={hideAnswers}
        question={question}
        choice={choice}
        selectAllThatApply={selectAllThatApply}
        reviewable={reviewable}
      />

      <div className={classes.mc}>
        <QuillDeltaAsHtml delta={choiceText} style={{ padding: 0 }} />

        <div className={classes.avatarContainer}>
          {questionBreakdown &&
            !hideStats &&
            Object.keys(questionBreakdown).map((studentId) => {
              const studentName = questionBreakdown[studentId].student;
              if (anonymized) {
                return (
                  <StudentAvatar
                    hideStats={hideStats}
                    key={studentId}
                    studentId={studentId}
                    studentName={studentName}
                  />
                );
              }
              return (
                <Tooltip
                  key={`mc-breakdown-${studentId}`}
                  title={studentName}
                  enterDelay={0}
                >
                  <StudentAvatar
                    studentId={studentId}
                    studentName={studentName}
                    hideStats={hideStats}
                  />
                </Tooltip>
              );
            })}
        </div>
      </div>
    </div>
  );
}

type MultipleChoiceBreakdownProps = {
  question: QuestionAttributesFragment;
  questionsBreakdown?: any;
  hideStats?: boolean;
  hideAnswers?: boolean;
  reviewable?: boolean;
};

function MultipleChoiceBreakdown({
  question,
  questionsBreakdown,
  hideStats,
  hideAnswers,
  reviewable,
}: MultipleChoiceBreakdownProps) {
  const anonymized = hideStats !== undefined;
  const classes = useStyles({ anonymized, reviewable });
  const breakdown =
    (questionsBreakdown && questionsBreakdown.mcBreakdown) || {};
  const selectAllThatApply =
    question.questionType === QuestionTypeEnum.SelectAllMultipleChoice;

  const noneOfTheAbove = question.multipleChoiceAnswerChoices
    ? question.multipleChoiceAnswerChoices.every((choice) => {
        return !choice.isCorrect;
      })
    : false;

  return (
    <div className={classes.root}>
      {question.multipleChoiceAnswerChoices?.map((choice) => {
        return (
          <AnswerRow
            key={choice.id}
            choice={{
              isCorrect: choice.isCorrect,
              text: choice.text,
              id: choice.id,
              richText: choice.richText,
            }}
            breakdown={breakdown}
            hideAnswers={hideAnswers}
            hideStats={hideStats}
            anonymized={anonymized}
            selectAllThatApply={selectAllThatApply}
            question={question}
            reviewable={reviewable}
          />
        );
      })}
      {selectAllThatApply && (
        <AnswerRow
          choice={{
            isCorrect: noneOfTheAbove,
            id: 'none-of-the-above',
            text: 'None of the Above',
          }}
          breakdown={breakdown}
          hideAnswers={hideAnswers}
          hideStats={hideStats}
          anonymized={anonymized}
          selectAllThatApply={selectAllThatApply}
          question={question}
          reviewable={reviewable}
        />
      )}
    </div>
  );
}

export default MultipleChoiceBreakdown;
