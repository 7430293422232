import { useMutation } from '@apollo/client';
import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteAssignmentDocument } from '../../../../gql/mutations/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';
import { pushSnack } from '../../Alerts/context/actions';
import { AssignmentEditorContext } from './context';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingTop: 24,
  },
  root: {
    paddingRight: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
  warning: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  info: {
    marginBottom: theme.spacing(7),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    paddingTop: '4px',
  },
  button: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
  },
  actionsContainer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: '0 20px',
    padding: '15px 0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  discardButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(2),
  },
}));

type DiscardButtonProps = {
  assignmentStatus: AssignmentStatusEnum;
};

export function DiscardButton({ assignmentStatus }: DiscardButtonProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const {
    assignmentEditor: { id: assignmentId },
  } = useContext(AssignmentEditorContext);
  const history = useHistory();
  const [deleteAssignment] = useMutation(DeleteAssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
    onCompleted: () => {
      history.push('/assignments');
      dispatch(
        pushSnack({
          message: 'Draft assignment discarded.',
        })
      );
    },
  });
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  if (assignmentStatus !== AssignmentStatusEnum.Pending) {
    return null;
  }

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const handleDiscard = () => {
    if (clicked) {
      return;
    }
    setClicked(true);
    deleteAssignment();
  };

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <div className={classes.container}>
          <span className={classes.icon}>
            <Delete />
          </span>
          <span>DISCARD DRAFT</span>
        </div>
      </MenuItem>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle className={classes.title} color="primary" variant="h2">
          DISCARD DRAFT
        </DialogTitle>
        <DialogContent>
          <Typography color="primary" variant="h4">
            Are you sure you want to discard your draft assignment?
          </Typography>
          <Typography variant="body1" className={classes.warning}>
            This action cannot be undone.
          </Typography>
          <Typography variant="body1" className={classes.info}>
            Any new questions you&apos;ve created in this draft will be saved
            and can be accessed in other assignments.
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.actionsContainer}>
            <Button
              onClick={handleClose}
              variant="outlined"
              className={classes.button}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={handleDiscard}
              className={classes.discardButton}
            >
              DISCARD DRAFT
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
