import { Button, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  image: {
    display: 'block',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: "url('/images/internal_server_error_mobile.svg')",
    [theme.breakpoints.up('md')]: {
      backgroundImage: "url('/images/internal_server_error_desktop.svg')",
    },
    [theme.breakpoints.up('lg')]: {
      backgroundPosition: 'center bottom',
    },
    width: '100%',
    height: '100%',
  },
  button: {
    color: theme.palette.common.white,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    bottom: '23%',
    [theme.breakpoints.up('sm')]: {
      bottom: '15%',
    },
    [theme.breakpoints.up('md')]: {
      bottom: '18%',
    },
  },
}));

type ErrorPageProps = {
  resetError: () => void;
};

export function ErrorPage({ resetError }: ErrorPageProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <span
        role="img"
        aria-label="Internal Server Error. It's not you, it's us. Please try again later."
        className={classes.image}
      ></span>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={() => resetError()}
        >
          Refresh Page
        </Button>
      </div>
    </div>
  );
}
