import { useQuery } from '@apollo/client';
import { ExpandMore } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
  Avatar,
  Card,
  Checkbox,
  Chip,
  Collapse,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { asHumanText } from '@podsie/utils/string.js';
import clsx from 'clsx';
import React, { useState } from 'react';
import type { QuestionWithAuthorAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { TeacherLightDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { AnswersBreakdown } from '../../../shared/Answers/AnswersBreakdown';
import { CustomTooltip } from '../../../shared/CustomTooltip';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { DeleteForeverAssignmentButton } from '../../Assignments/AssignmentEditor/Reworked/Questions/DeleteForeverAssignmentButton';
import { StandardsQueryStandard } from '../../Questions/Form/MultipleStandardsAutocomplete';
import { QuestionStandardsUpdated } from './StandardQuestionsTagger';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
    padding: theme.spacing(2.5),
  },
  questionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  autocompleteContainer: {
    width: '95%',
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  loaderContainer: {
    marginLeft: theme.spacing(0.5),
    width: '5%',
    display: 'flex',
    justifyContent: 'center',
  },
  expandRoot: {
    borderTop: `1px dotted ${theme.palette.secondary.main}`,
    marginTop: theme.spacing(2.5),
  },
  expandButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    marginTop: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    color: theme.palette.secondary.main,
  },
  expandText: {
    color: '#666666',
  },
  expandedSection: {},
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.secondary.main,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  editButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8,
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  tags: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
  tooltip: {},
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type UntaggedQuestionCardProps = {
  question: QuestionWithAuthorAttributesFragment;
  selectedStandards: StandardsQueryStandard[];
  updatedQuestionStandardIds: QuestionStandardsUpdated[];
  onSelect: (
    question: QuestionWithAuthorAttributesFragment,
    add: boolean
  ) => void;
};

export function UntaggedQuestionCard({
  question,
  onSelect,
  selectedStandards,
  updatedQuestionStandardIds,
}: UntaggedQuestionCardProps) {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((isOpen) => !isOpen);
  const { data: teacherData } = useQuery(TeacherLightDocument);

  const avatar = (teacher: QuestionWithAuthorAttributesFragment['teacher']) => {
    const identifier = asHumanText(teacher.fullName) ?? teacher.email;
    const abbrev = asHumanText(teacher.initials) ?? teacher.email.charAt(0);

    return (
      <CustomTooltip
        title={<div className={classes.tooltip}>{identifier}</div>}
      >
        <Avatar className={classes.avatar}>{abbrev}</Avatar>
      </CustomTooltip>
    );
  };

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked === true) {
      onSelect(question, true);
      setClicked(true);
    } else {
      onSelect(question, false);
      setClicked(false);
    }
  };

  const standardIdsUpdateForQuestion = updatedQuestionStandardIds.find(
    (q: QuestionStandardsUpdated) => q.questionId === question.id
  );

  const selectedStandardsInUpdatedQuestion =
    [
      ...selectedStandards.filter(
        (selectedStandard) =>
          standardIdsUpdateForQuestion?.standardIds.includes(
            selectedStandard.id
          )
      ),
    ] ?? [];

  return (
    <Card className={classes.root}>
      <div className={classes.questionContainer}>
        <div className={classes.fieldContainer}>
          <Checkbox onChange={handleClick} />
          <QuillDeltaAsHtml delta={question.richText.ops} key={question.id} />
        </div>
        <div className={classes.flex}>
          {teacherData?.teacher.id === question.teacher.id && (
            <DeleteForeverAssignmentButton questionId={question.id} />
          )}
          <div>{avatar(question.teacher)}</div>
        </div>
      </div>
      {clicked &&
        standardIdsUpdateForQuestion &&
        selectedStandardsInUpdatedQuestion.map((s) => (
          <Chip
            className={classes.tags}
            icon={<CheckIcon color="success" />}
            key={s.id}
            label={s.title}
          />
        ))}
      <div className={classes.expandRoot}>
        <button className={classes.expandButton} onClick={toggle}>
          <Typography className={classes.expandText} variant="caption">
            See Answer
          </Typography>
          <ExpandMore
            className={clsx(classes.expand, {
              [classes.expandOpen]: open,
            })}
          />
        </button>
      </div>
      <Collapse in={open} className={classes.expandedSection}>
        <AnswersBreakdown question={question} />
      </Collapse>
    </Card>
  );
}
