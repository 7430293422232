import HelpKitButton from './HelpKitButton';

export function HelpKitBulkUploadStudents() {
  return (
    <HelpKitButton
      label="Learn how to bulk upload students via CSV"
      articleId="s3SUdsoVTawxrsv8AEj5kG"
    />
  );
}
export default HelpKitBulkUploadStudents;
