import { useMutation } from '@apollo/client';
import { Button, Typography, useMediaQuery, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AlertsContext } from '../../components/application/Alerts/context';
import { GenerateDemoDataDocument } from '../../gql/mutations/__generated__/teacher.generated';
import { onError } from '../../utils/apollo/apolloHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(4),
    fontSize: 24,
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: theme.palette.mint.main,
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.palette.mint.dark}`,
    borderRadius: '4px',
  },
  contentContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  infoContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  success: {
    fontWeight: theme.typography.fontWeightBold,
  },
  createDemoDataButton: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(4),
  },
}));

export function DemoSetup() {
  const classes = useStyles();
  const [done, setDone] = useState(false);
  const { dispatch } = useContext(AlertsContext);
  const history = useHistory();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  let width = '800';
  let height = '453';

  if (isSmall) {
    width = '300';
    height = '169';
  } else if (isMedium) {
    width = '500';
    height = '282';
  }

  const [generateDemoData, { loading }] = useMutation(
    GenerateDemoDataDocument,
    {
      onCompleted: () => {
        setDone(true);
        history.push('/assignments');
        window.location.reload();
      },
      onError: onError(dispatch),
    }
  );
  const handleClick = () => generateDemoData();

  const renderComponent = () => {
    if (loading) {
      return <Typography>Creating demo data...</Typography>;
    }

    if (done) {
      return (
        <Typography color="primary" className={classes.success}>
          Demo data created! You can now close this tab and return to the guided
          walkthrough. (Reminder: refresh your other tab to see the newly
          created data!)
        </Typography>
      );
    }

    return (
      <div className={classes.contentContainer}>
        <Typography variant="h4" color="primary" className={classes.title}>
          {'In \u003C 1 min, learn how Podsie works:'}
        </Typography>
        <div>
          <iframe
            title="how-podsie-works"
            width={width}
            height={height}
            src="https://www.loom.com/embed/d4d690ef9d0e4424b41abf77765f8080?sid=abafb9ce-7ae2-4fc9-8eb0-cca5d2fb3e02"
            allowFullScreen
          ></iframe>
        </div>
        <Button
          onClick={handleClick}
          variant="contained"
          color="secondary"
          className={classes.createDemoDataButton}
        >
          Get Started
        </Button>
      </div>
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.buttonContainer}>{renderComponent()}</div>
      </div>
    </div>
  );
}
