import { DeleteOutlined, EditOutlined } from '@mui/icons-material';
import { Box, Card, Checkbox, IconButton, useTheme } from '@mui/material';
import React from 'react';
import { GeneratedQuestionsQuery } from '../../../../gql/queries/__generated__/question.generated';
import { GeneratedQuestionDisplay } from './GeneratedQuestionDisplay';
import { GeneratedQuestionEdit } from './GeneratedQuestionEdit';

type GeneratedQuestionProps = {
  question: GeneratedQuestionsQuery['generatedQuestions'][0];
  questionIdsToAdd: string[];
  setQuestionIdsToAdd: React.Dispatch<React.SetStateAction<string[]>>;
  discardQuestions: (questionIds: string[]) => void;
};

export function GeneratedQuestion({
  question,
  questionIdsToAdd,
  setQuestionIdsToAdd,
  discardQuestions,
}: GeneratedQuestionProps) {
  const theme = useTheme();
  const [editMode, setEditMode] = React.useState(false);
  // check if this specific question was actually marked as deleted
  // in localStorage:
  const handleDiscard = () => {
    discardQuestions([question.id]);
  };

  const openEditMode = () => setEditMode(true);
  const questionIsChecked = () => questionIdsToAdd.includes(question.id);
  const toggleQuestionIsChecked = () => {
    if (questionIsChecked()) {
      setQuestionIdsToAdd(questionIdsToAdd.filter((id) => id !== question.id));
    } else {
      setQuestionIdsToAdd([...questionIdsToAdd, question.id]);
    }
  };

  return (
    <Box>
      <Card
        sx={{
          padding: 3,
          mb: 3,
          width: '100%',
          overflow: 'visible',
          position: 'relative',
        }}
      >
        <Checkbox
          sx={{ left: -40, top: 4, position: 'absolute' }}
          checked={questionIsChecked()}
          onClick={toggleQuestionIsChecked}
          color="secondary"
        />
        <Box>
          {editMode ? (
            <GeneratedQuestionEdit question={question} />
          ) : (
            <GeneratedQuestionDisplay question={question} />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column',
            position: 'absolute',
            right: -40,
            top: 0,
          }}
        >
          <IconButton onClick={openEditMode}>
            <EditOutlined />
          </IconButton>
          <IconButton onClick={handleDiscard}>
            <DeleteOutlined />
          </IconButton>
        </Box>
      </Card>
    </Box>
  );
}
