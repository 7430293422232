import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { truncate } from '@podsie/utils/string.js';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

interface HeaderTitleInterface {
  title: string;
  updateTitle: Dispatch<SetStateAction<string>>;
}

export const HeaderTitleContext = React.createContext<HeaderTitleInterface>({
  title: '',
  updateTitle: () => {},
});

export function HeaderTitleProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [title, updateTitle] = useState('');
  return (
    <HeaderTitleContext.Provider value={{ title, updateTitle }}>
      {children}
    </HeaderTitleContext.Provider>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  pageTitle: {
    margin: 0,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
}));

export function HeaderTitle() {
  const classes = useStyles();
  const { title } = useContext(HeaderTitleContext);
  return (
    <h1 className={classes.pageTitle}>{`${truncate(title, 30, {
      suffix: '...',
    })}`}</h1>
  );
}
