import { useQuery } from '@apollo/client';
import { Edit } from '@mui/icons-material';
import { Button, IconButton, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import { EnrollmentDocument } from '../../../gql/queries/__generated__/enrollment.generated';
import { onError } from '../../../utils/apollo/apolloHelper';
import { LoadingSkeletons } from '../../shared/Loaders/LoadingSkeletons';
import { AlertsContext } from '../Alerts/context';
import { HeaderTitleContext } from '../Layout/Header/HeaderTitle';
import { EditProfileModal } from './EditProfileModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  personalDeckStats: {
    display: 'flex',
    alignItems: 'baseline',
  },
  personalDeckLabel: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonLink: {
    ...buttonLink(theme),
  },
}));

type StudentInfoProps = {
  enrollmentId: string;
  groupId: string;
};

export function StudentInfo({ enrollmentId, groupId }: StudentInfoProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(EnrollmentDocument, {
    variables: { id: enrollmentId },
    onError: onError(dispatch),
  });
  const [editOpen, setEditOpen] = useState(false);
  const history = useHistory();

  const fullName = data?.enrollment.student.fullName || '';
  const numDuePersonalDecksQuestions =
    data?.enrollment.numDuePersonalDecksQuestions || 0;
  const { updateTitle } = React.useContext(HeaderTitleContext);
  useEffect(() => updateTitle(fullName), [updateTitle, fullName]);
  if (loading || !data?.enrollment) {
    return <LoadingSkeletons num={3} />;
  }

  const openEdit = () => setEditOpen(true);
  const closeEdit = () => setEditOpen(false);
  const navBackToClass = () => {
    history.push(`/classes/${groupId}`);
  };

  return (
    <div className={classes.root}>
      <Button className={classes.buttonLink} onClick={navBackToClass}>
        Back to roster
      </Button>
      <div className={classes.nameContainer}>
        <Typography variant="h2" color="primary">
          {fullName}
        </Typography>
        <IconButton onClick={openEdit} size="large">
          <Edit />
        </IconButton>
      </div>
      <span className={classes.personalDeckStats}>
        <Typography className={classes.personalDeckLabel} variant="h4">
          Personal Deck Cards Due:
        </Typography>
        <Typography variant="body1">{numDuePersonalDecksQuestions}</Typography>
      </span>
      <EditProfileModal
        open={editOpen}
        handleClose={closeEdit}
        enrollment={data.enrollment}
      />
    </div>
  );
}
