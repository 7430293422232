import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import type { TeacherCoursesQuery } from '../../../../gql/queries/__generated__/teacher.generated';
import EditCourseForm from './EditCourseForm';

type EditCourseModalProps = {
  coursesTeacher: TeacherCoursesQuery['teacher']['coursesTeachers'][0];
  refetch: () => void;
  handleModalClose: () => void;
};

export function EditCourseModal({
  coursesTeacher,
  refetch,
  handleModalClose,
}: EditCourseModalProps) {
  return (
    <Dialog maxWidth="md" open={true} onClose={handleModalClose} scroll="body">
      <DialogTitle id="customized-dialog-title">Update Your Course</DialogTitle>
      <DialogContent>
        <EditCourseForm
          coursesTeacher={coursesTeacher}
          refetch={refetch}
          handleModalClose={handleModalClose}
        />
      </DialogContent>
    </Dialog>
  );
}
