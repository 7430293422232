import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename';
import { LinearProgress } from '@mui/material';
import { isString } from '@podsie/utils/string.js';
import * as Sentry from '@sentry/react';
import ActionCable from 'actioncable';
import { persistCache, SessionStorageWrapper } from 'apollo3-cache-persist';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from './Auth0Context';
import { config } from './podsie-config.js';

const httpLink = createHttpLink({
  uri: config.server,
  credentials: 'include',
});

const httpLinkBackup = createHttpLink({
  uri: config.serverBackup,
  credentials: 'include',
});

const checkVersionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response: any) => {
    if (response['PODSIE_TEACHER_VERSION']) {
      const savedVersion = localStorage.getItem('PODSIE_TEACHER_VERSION');
      if (savedVersion !== response['PODSIE_TEACHER_VERSION'].toString()) {
        localStorage.setItem(
          'PODSIE_TEACHER_VERSION',
          response['PODSIE_TEACHER_VERSION']
        );
        // if there was previously a saved version, then reload the page:
        if (savedVersion) {
          window.location.reload();
        }
      }
    }
    return response;
  });
});

const removeTypenameLink = removeTypenameFromVariables();

const errorLink = onError(({ networkError }) => {
  if (networkError) {
    Sentry.captureMessage(`Network error: ${networkError}`);
    if ('statusCode' in networkError && networkError.statusCode === 503) {
      networkError.message =
        'Podsie is currently not available due to scheduled maintenance. This should only take a few minutes, but please email our team at hello@podsie.org if you have any questions.';
    } else {
      networkError.message =
        'A network error occurred. Please refresh your page. If this issue persists, please email our team at hello@podsie.org.';
    }
  }
});

const hasSubscriptionOperation = ({ query: { definitions } }: Operation) => {
  return definitions.some(
    (node) =>
      node.kind === 'OperationDefinition' && node.operation === 'subscription'
  );
};

type CustomApolloProviderProps = {
  children: React.ReactElement;
};

export function CustomApolloProvider({ children }: CustomApolloProviderProps) {
  const { getTokenSilently, user } = useAuth0();
  const [client, setClient] = useState<ApolloClient<unknown> | null>(null);

  useEffect(() => {
    getTokenSilently().then(async (token) => {
      const setAuthLink = setContext((_, { headers }) => ({
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : null,
        },
      }));
      let userLink = httpLink;
      let cable = ActionCable.createConsumer(config.websocketServer ?? '');
      if (
        user &&
        isString(user.email) &&
        (user.email.endsWith('lexrich5.org') ||
          user.email === 'kyobs@hotmail.com' ||
          user.email.indexOf('collierschools') !== -1)
      ) {
        userLink = httpLinkBackup;
        cable = ActionCable.createConsumer(config.websocketServerBackup ?? '');
      }
      const httpAndAuthLink = ApolloLink.from([
        removeTypenameLink,
        errorLink,
        checkVersionLink,
        setAuthLink,
        userLink,
      ]);
      const link = ApolloLink.split(
        hasSubscriptionOperation,
        new ActionCableLink({
          cable,
        }) as unknown as ApolloLink,
        httpAndAuthLink
      );
      const cache = new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              // explicitly delete dangling reference for QuestionShow component
              question(existingQuestion, { canRead }) {
                return canRead(existingQuestion) ? existingQuestion : undefined;
              },
            },
          },
        },
      });
      await persistCache({
        cache,
        storage: new SessionStorageWrapper(window.sessionStorage),
      });

      const client = new ApolloClient({
        link,
        cache,
        connectToDevTools: config.env === 'development',
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
          },
        },
      });

      setClient(client);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!client) {
    return <LinearProgress />;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
export default CustomApolloProvider;
