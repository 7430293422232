import { useContext } from 'react';
import { QuestionTypeEnum } from '../../../../../gql/types';
import { QuestionFormContext } from '../context';
import { FreeResponseAnswer } from './FreeResponseAnswer';
import { MultipleChoiceAnswerChoices } from './MultipleChoiceAnswerChoices';
import { ShortAnswerAnswers } from './ShortAnswerAnswers';

export function Answers() {
  const { questionForm } = useContext(QuestionFormContext);

  switch (questionForm.questionType) {
    case QuestionTypeEnum.FreeResponse:
      return <FreeResponseAnswer />;
    case QuestionTypeEnum.ShortAnswer:
      return <ShortAnswerAnswers />;
    case QuestionTypeEnum.SelectOneMultipleChoice:
    case QuestionTypeEnum.SelectAllMultipleChoice:
      return <MultipleChoiceAnswerChoices />;
    default:
      return null;
  }
}
