import { useQuery } from '@apollo/client';
import {
  Autocomplete,
  LinearProgress,
  Radio,
  TextField,
  type Theme,
} from '@mui/material';
import type { StyledComponentProps } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { truncate } from '@podsie/utils/string.js';
import { useContext, useMemo, type SyntheticEvent } from 'react';
import {
  StandardsDocument,
  type StandardsQuery,
} from '../../../../gql/queries/__generated__/standard.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '4px',
    '& .MuiChip-deleteIcon': {
      color: theme.palette.secondary.main,
    },
  },
}));

type StandardsAutocompleteProps = {
  disabled?: boolean;
  handleStandardUpdate: (
    event: SyntheticEvent,
    newValue: StandardsQuery['standards'][0][] | null
  ) => void;
  standards: StandardsQuery['standards'][0][];
  className?: string;
  requiredStandards: boolean;
  error?: boolean;
};

export function StandardsAutocomplete({
  handleStandardUpdate,
  standards,
  disabled,
  className,
  requiredStandards,
  error,
}: StandardsAutocompleteProps & StyledComponentProps) {
  const classes = useStyles();
  const { dispatch: alertsDispatch } = useContext(AlertsContext);
  const { data: teacherData, loading: teacherLoading } = useQuery(
    TeacherDocument,
    { onError: onError(alertsDispatch) }
  );
  const standardsChartId =
    teacherData?.teacher?.activeCourse?.standardsChart?.id || '';
  const { data, loading, refetch } = useQuery(StandardsDocument, {
    skip: !standardsChartId,
    variables: { standardsChartId },
    onError: onError(alertsDispatch),
    fetchPolicy: 'cache-first',
  });

  const options = useMemo(() => {
    if (data?.standards) {
      return data.standards;
    }
    return [];
  }, [data]);

  const onOpen = () => refetch();

  if (loading || teacherLoading) {
    return <LinearProgress />;
  }

  return (
    <Autocomplete
      disabled={disabled}
      className={className}
      multiple
      selectOnFocus
      openOnFocus
      fullWidth
      onOpen={onOpen}
      classes={{
        tag: classes.tag,
      }}
      ListboxProps={{
        style: { width: '100%' },
      }}
      options={options}
      getOptionLabel={(option) =>
        truncate(`${option.title}: ${option.description}`)
      }
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      id="controlled-demo"
      value={standards}
      groupBy={(option) => {
        return option.standardsCategory
          ? option.standardsCategory.category
          : '';
      }}
      onChange={handleStandardUpdate}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props}>
            <Radio checked={selected} />
            {`${option.title} - ${option.description}`}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          error={error}
          required={requiredStandards}
          {...params}
          inputProps={{
            ...params.inputProps,
            required: requiredStandards,
          }}
          label="Select a Standard"
          variant="outlined"
          name="standard-autocomplete"
        />
      )}
    />
  );
}
