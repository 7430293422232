import makeStyles from '@mui/styles/makeStyles';
import CustomTabs from '../../components/shared/Layout/Tabs';
import { CoursesIndex } from '../Courses/CoursesIndex';
import { FreezePersonalDeck } from './FreezePersonalDeck';
import { GeneralSettings } from './GeneralSettings';
import PersonalDeckSpacing from './PersonalDeckSpacing';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
  },
}));

export function SettingsIndex() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomTabs
        tabs={[
          {
            path: '/settings',
            label: 'General',
            component: () => <GeneralSettings />,
          },
          {
            path: '/settings/courses',
            label: 'Courses',
            component: () => <CoursesIndex />,
          },
          {
            path: '/settings/personal-deck-spacing',
            label: 'Personal Deck',
            component: () => <PersonalDeckSpacing />,
          },
          {
            path: '/settings/personal-deck-freeze',
            label: 'Freeze Personal Deck',
            component: () => <FreezePersonalDeck />,
          },
        ]}
      />
    </div>
  );
}
