import { Tooltip, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { buttonLink } from '../../../../../../assets/shared-styles/Button-Link';
import type { QuestionAttributesFragment } from '../../../../../../gql/fragments/__generated__/question.generated';
import type {
  FreeResponseResponse,
  Maybe,
  MultipleChoiceAnswerChoice,
  MultipleChoiceResponse,
  SelectedMultipleChoiceAnswerChoice,
  ShortAnswerResponse,
} from '../../../../../../gql/types';
import { secondaryMain } from '../../../../../../theme';
import { isRichText } from '../../../../../../utils/question';
import { CustomTooltip } from '../../../../../shared/CustomTooltip';
import { QuillDeltaAsHtml } from '../../../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.darks.main,
    height: '100%',
    padding: '20px 23px',
  },
  questionContainer: {
    padding: `${theme.spacing(3)} 0`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  seeMoreButton: {
    ...buttonLink(theme),
    padding: 0,
    marginTop: '20px',
  },
}));
const scrollToRef = (id: string) => {
  const el: HTMLDivElement | null = document.querySelector(
    `#assignment-questions-result-${id}`
  );
  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const originalBackground = el.style.backgroundColor;
    el.style.backgroundColor = secondaryMain;
    setTimeout(() => {
      el.style.backgroundColor = originalBackground;
    }, 1000);
  }
};

type TopRepeatedQuestionsProps = {
  mostRepeated: Array<{
    question: { __typename?: 'Question' } & QuestionAttributesFragment;
    freeResponseResponses?: Maybe<
      Array<
        { __typename?: 'FreeResponseResponse' } & Pick<
          FreeResponseResponse,
          'id' | 'selfAssessment' | 'answer'
        >
      >
    >;
    shortAnswerResponses?: Maybe<
      Array<
        { __typename?: 'ShortAnswerResponse' } & Pick<
          ShortAnswerResponse,
          'id' | 'answer' | 'isCorrect'
        >
      >
    >;
    multipleChoiceResponses?: Maybe<
      Array<
        { __typename?: 'MultipleChoiceResponse' } & Pick<
          MultipleChoiceResponse,
          'id' | 'isCorrect'
        > & {
            selectedMultipleChoiceAnswerChoices: Array<
              { __typename?: 'SelectedMultipleChoiceAnswerChoice' } & Pick<
                SelectedMultipleChoiceAnswerChoice,
                'id'
              > & {
                  multipleChoiceAnswerChoice: {
                    __typename?: 'MultipleChoiceAnswerChoice';
                  } & Pick<MultipleChoiceAnswerChoice, 'id' | 'text'>;
                }
            >;
          }
      >
    >;
  }>;
};

export function TopRepeatedQuestions({
  mostRepeated,
}: TopRepeatedQuestionsProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5">
        {`TOP ${mostRepeated.length} MISSED QUESTION(S):`}
      </Typography>

      {mostRepeated.map(({ question }) => {
        const truncatedQuestionLine = (
          <Typography variant="body1" noWrap>
            {question.plainText}
          </Typography>
        );

        return (
          <div
            key={`most-repeated-${question.id}`}
            className={classes.questionContainer}
          >
            {isRichText(question.richText) &&
            question.richText.ops.length > 0 ? (
              <CustomTooltip
                placement="top"
                title={<QuillDeltaAsHtml delta={question.richText.ops} />}
              >
                {truncatedQuestionLine}
              </CustomTooltip>
            ) : (
              <Tooltip title={question.plainText}>
                {truncatedQuestionLine}
              </Tooltip>
            )}
            <button
              onClick={() => scrollToRef(question.id)}
              className={classes.seeMoreButton}
            >
              <Typography variant="body1" color="secondary">
                See More
              </Typography>
            </button>
          </div>
        );
      })}
    </div>
  );
}
