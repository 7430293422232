import HelpKitButton from './HelpKitButton';

export function HelpKitPersonalDeckOverview() {
  return (
    <HelpKitButton
      label="Understanding the Personal Deck (and what you need to do)"
      articleId="nog8MHD4MCDdDzVNQhkopY"
    />
  );
}
export default HelpKitPersonalDeckOverview;
