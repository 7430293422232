import { produce } from 'immer';
import Quill from 'quill';
import { QuestionTypeEnum } from '../../../../../gql/types';
import { getPlainTextFromDelta } from '../../../../../utils/quillHelper';
import { QuestionFormAction, QuestionFormActionTypes } from './actions';
import initialState, {
  FreeResponseAnswer,
  MultipleChoiceAnswerChoices,
  QuestionFormState,
  ShortAnswerAnswers,
} from './initialState';

const Delta = Quill.import('delta');

const reducer = (
  questionForm = initialState,
  action: QuestionFormAction
): QuestionFormState => {
  switch (action.type) {
    case QuestionFormActionTypes.RESET_QUESTION_FORM: {
      if (action.payload.question) {
        const {
          richText,
          supplement,
          numAttemptsUntilAnswerShown,
          shortAnswerAnswers,
          multipleChoiceAnswerChoices,
          freeResponseAnswer,
          questionType,
          standards,
        } = action.payload.question;
        const frAnswer: FreeResponseAnswer = freeResponseAnswer
          ? {
              text: freeResponseAnswer.richText,
              comparable: freeResponseAnswer.comparable,
              requiredKeywords: freeResponseAnswer.fraRequiredKeywords.map(
                (w) => w.keyword
              ),
            }
          : initialState.freeResponseAnswer;

        const shAnswers: ShortAnswerAnswers = shortAnswerAnswers
          ? shortAnswerAnswers.map((sar) => ({
              data: {
                text: sar.text,
                richText: sar.richText,
                isCaseSensitive: sar.isCaseSensitive,
                ignoreWhitespaces: sar.ignoreWhitespaces,
                hidden: sar.hidden,
              },
              settingsOpen: false,
            }))
          : initialState.shortAnswerAnswers;
        const mcAnswers: MultipleChoiceAnswerChoices =
          multipleChoiceAnswerChoices
            ? multipleChoiceAnswerChoices.map((mc) => ({
                text: mc.richText,
                isCorrect: mc.isCorrect,
              }))
            : initialState.multipleChoiceAnswerChoices;
        const supplementRichText = supplement?.richText || '';
        const clone = {
          question: richText,
          numAttemptsUntilAnswerShown,
          supplement: supplementRichText,
          standards: standards || [],
          questionType,
          freeResponseAnswer: frAnswer,
          shortAnswerAnswers: shAnswers.filter((sa) => !sa.data.hidden),
          hiddenShortAnswers: shAnswers.filter((sa) => sa.data.hidden),
          shortAnswerSettingsOpen: {},
          multipleChoiceAnswerChoices: mcAnswers,
        };
        return clone;
      }
      const { questionType, standards } = questionForm;
      return {
        ...initialState,
        questionType,
        standards,
      };
    }
    case QuestionFormActionTypes.UPDATE_QUESTION_TYPE: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.questionType = action.payload.value;
      });
    }
    case QuestionFormActionTypes.UPDATE_STANDARD: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.standards = action.payload.value;
      });
    }
    case QuestionFormActionTypes.UPDATE_FREE_RESPONSE_ANSWER: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.freeResponseAnswer.text = action.payload.value;
        questionFormDraft.freeResponseAnswer.comparable =
          action.payload.comparable;
      });
    }
    case QuestionFormActionTypes.ADD_REQUIRED_KEYWORD: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.freeResponseAnswer.requiredKeywords = [
          ...questionFormDraft.freeResponseAnswer.requiredKeywords,
          '',
        ];
      });
    }
    case QuestionFormActionTypes.UPDATE_REQUIRED_KEYWORD: {
      return produce(questionForm, (questionFormDraft) => {
        const { index, text } = action.payload;
        if (
          index >= questionFormDraft.freeResponseAnswer.requiredKeywords.length
        ) {
          return;
        }
        questionFormDraft.freeResponseAnswer.requiredKeywords[index] = text;
      });
    }
    case QuestionFormActionTypes.DELETE_REQUIRED_KEYWORD: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.freeResponseAnswer.requiredKeywords.splice(
          action.payload.index,
          1
        );
      });
    }
    case QuestionFormActionTypes.UPDATE_SHORT_ANSWER_ANSWER: {
      return produce(questionForm, (questionFormDraft) => {
        const { shortAnswerIndex, settingsOpen, ...shortAnswerUpdate } =
          action.payload;
        if (!questionFormDraft.shortAnswerAnswers[shortAnswerIndex]) {
          return;
        }
        questionFormDraft.shortAnswerAnswers[shortAnswerIndex].data = {
          ...questionForm.shortAnswerAnswers[shortAnswerIndex].data,
          ...shortAnswerUpdate,
        };
        if (settingsOpen !== undefined) {
          questionFormDraft.shortAnswerAnswers[shortAnswerIndex].settingsOpen =
            settingsOpen;
        }
      });
    }
    case QuestionFormActionTypes.DELETE_SHORT_ANSWER_ANSWER: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.shortAnswerAnswers.splice(
          action.payload.shortAnswerIndex,
          1
        );
      });
    }
    case QuestionFormActionTypes.ADD_SHORT_ANSWER_ANSWER: {
      return produce(questionForm, (questionFormDraft) => {
        const delta = action.payload.shortAnswerDelta;
        const text = getPlainTextFromDelta(delta);
        questionFormDraft.shortAnswerAnswers.push({
          data: {
            text: text,
            richText: new Delta(delta),
            isCaseSensitive: false,
            ignoreWhitespaces: true,
            hidden: false,
          },
          settingsOpen: false,
        });
      });
    }
    case QuestionFormActionTypes.DELETE_MULTIPLE_CHOICE: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.multipleChoiceAnswerChoices.splice(
          action.payload.multipleChoiceIndex,
          1
        );
      });
    }
    case QuestionFormActionTypes.ADD_MULTIPLE_CHOICE: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.multipleChoiceAnswerChoices.push({
          text: '',
          isCorrect: false,
        });
      });
    }
    case QuestionFormActionTypes.UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_TEXT: {
      return produce(questionForm, (questionFormDraft) => {
        const { multipleChoiceIndex, text } = action.payload;
        questionFormDraft.multipleChoiceAnswerChoices[
          multipleChoiceIndex
        ].text = text;
      });
    }
    case QuestionFormActionTypes.UPDATE_QUESTION: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.question = action.payload.question;
      });
    }
    case QuestionFormActionTypes.UPDATE_NUM_ATTEMPTS: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.numAttemptsUntilAnswerShown = action.payload.value;
      });
    }
    case QuestionFormActionTypes.UPDATE_SUPPLEMENT: {
      return produce(questionForm, (questionFormDraft) => {
        questionFormDraft.supplement = action.payload.supplement;
      });
    }
    case QuestionFormActionTypes.UPDATE_MULTIPLE_CHOICE_ANSWER_CHOICE_IS_CORRECT: {
      return produce(questionForm, (questionFormDraft) => {
        const { questionType } = questionFormDraft;
        const { multipleChoiceIndex, isCorrect } = action.payload;
        if (questionType === QuestionTypeEnum.SelectOneMultipleChoice) {
          // when type is select_one, toggle all other answer choices to false
          // to implement radio group behavior:
          if (isCorrect) {
            questionFormDraft.multipleChoiceAnswerChoices.forEach((_, i) => {
              if (i !== multipleChoiceIndex) {
                questionFormDraft.multipleChoiceAnswerChoices[i].isCorrect =
                  false;
              }
            });
          }
        }

        // only select_all question types are allowes to toggle a choice to
        // incorrect because select_one should act like a radio button:
        if (
          isCorrect ||
          questionType === QuestionTypeEnum.SelectAllMultipleChoice
        ) {
          questionFormDraft.multipleChoiceAnswerChoices[
            multipleChoiceIndex
          ].isCorrect = isCorrect;
        }
      });
    }
    default:
      return questionForm;
  }
};

export default reducer;
