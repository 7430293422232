import { Theme, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  label: {
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  const classes = useStyles();
  const { value } = props;
  const theme = useTheme();

  if (isNaN(Math.round(value))) {
    return null;
  }

  let color = theme.palette.accent.main;
  if (value > 85) {
    color = theme.palette.secondary.main;
  } else if (value > 70) {
    color = theme.palette.warning.main;
  }
  return (
    <Box className={classes.root} position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        style={{ color, width: '100%', height: '100%' }}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          className={classes.label}
        >{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
