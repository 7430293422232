import { Theme, Tooltip, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../gql/types';
import { ASSESSMENT_VALUES } from '../../../../utils/assessmentValueMappings';
import Emoji from '../../../shared/Emoji';
import { QuestionsBreakdownContext } from './QuestionsBreakdownContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  barContainer: {
    display: 'flex',
    width: 200,
  },
  labelsContainer: {
    width: 200,
    display: 'flex',
    marginTop: theme.spacing(0.5),
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
  },
  emoji: {
    fontSize: 24,
  },
  freeResponseStatsContainer: {
    display: 'flex',
  },
  freeResponseStat: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  frCount: {
    color: theme.palette.primary.main,
  },
}));

type CompletionStatsProps = {
  question: QuestionAttributesFragment;
};

export function CompletionStats({ question }: CompletionStatsProps) {
  const classes = useStyles();
  const { questionsBreakdown } = useContext(QuestionsBreakdownContext);
  const theme = useTheme();

  const {
    shortAnswerBreakdown = {},
    mcBreakdown = {},
    freeResponseBreakdown = {},
  } = questionsBreakdown || {};
  if (question.questionType === QuestionTypeEnum.FreeResponse) {
    const freeResponseStats: { [key: string]: Array<string> } = {};
    let numResponses = 0;
    freeResponseBreakdown[question.id] &&
      Object.keys(freeResponseBreakdown[question.id]).forEach(
        (selfAssessment) => {
          Object.keys(
            freeResponseBreakdown[question.id][selfAssessment]
          ).forEach((studentId) => {
            const studentName =
              freeResponseBreakdown[question.id][selfAssessment][studentId]
                .student;
            if (freeResponseStats[selfAssessment]) {
              freeResponseStats[selfAssessment].push(studentName);
            } else {
              freeResponseStats[selfAssessment] = [studentName];
            }
            numResponses += 1;
          });
        }
      );

    if (numResponses === 0) {
      return null;
    }

    return (
      <div className={classes.freeResponseStatsContainer}>
        {ASSESSMENT_VALUES.map(([assessment, { emoji, label }]) => {
          const frStudents = freeResponseStats[assessment] || [];
          const num = frStudents.length;
          return (
            <Tooltip
              key={assessment}
              title={
                num ? (
                  <div>
                    {frStudents.map((name) => (
                      <div key={name}>{name}</div>
                    ))}
                  </div>
                ) : (
                  ''
                )
              }
            >
              <span className={classes.freeResponseStat}>
                <Emoji symbol={emoji} label={label} className={classes.emoji} />
                <Typography variant="h5" className={classes.frCount}>
                  {num}
                </Typography>
              </span>
            </Tooltip>
          );
        })}
      </div>
    );
  }

  const incorrect: Array<string> = [];
  const correct: Array<string> = [];

  if (question.questionType === QuestionTypeEnum.ShortAnswer) {
    shortAnswerBreakdown[question.id] &&
      Object.keys(shortAnswerBreakdown[question.id]).forEach((answerText) => {
        Object.keys(shortAnswerBreakdown[question.id][answerText]).forEach(
          (studentId) => {
            const { student, isCorrect } =
              shortAnswerBreakdown[question.id][answerText][studentId];
            if (isCorrect) {
              correct.push(student);
            } else {
              incorrect.push(student);
            }
          }
        );
      });
  }
  const correctMcIds: { [key: string]: boolean } = {};
  (question.multipleChoiceAnswerChoices || []).forEach((choice) => {
    if (choice.isCorrect) {
      correctMcIds[choice.id] = true;
    } else {
      correctMcIds[choice.id] = false;
    }
  });
  if (
    question.questionType === QuestionTypeEnum.SelectOneMultipleChoice ||
    question.questionType === QuestionTypeEnum.SelectAllMultipleChoice
  ) {
    // using an object to not double count students for 'Select All' questions
    const students: {
      [key: string]: { isCorrect: boolean; student: string };
    } = {};
    mcBreakdown[question.id] &&
      Object.keys(mcBreakdown[question.id]).forEach((choiceId) => {
        Object.keys(mcBreakdown[question.id][choiceId]).forEach((studentId) => {
          const { student, isCorrect } =
            mcBreakdown[question.id][choiceId][studentId];
          students[studentId] = { student, isCorrect };
        });
      });
    Object.keys(students).forEach((studentId) => {
      const { student, isCorrect } = students[studentId];
      if (isCorrect) {
        correct.push(student);
      } else {
        incorrect.push(student);
      }
    });
  }

  const incorrectCount = incorrect.length;
  const correctCount = correct.length;
  const total = incorrectCount + correctCount;
  const incorrectPercentage = (incorrectCount / total) * 100;
  const correctPercentage = (correctCount / total) * 100;
  if (incorrectCount + correctCount === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.barContainer}>
        <Tooltip
          title={
            correctCount ? (
              <div>
                {correct.map((student) => (
                  <div key={student}>{student}</div>
                ))}
              </div>
            ) : (
              ''
            )
          }
        >
          <div
            style={{
              width: `${correctPercentage}%`,
              backgroundColor: theme.palette.secondary.main,
              height: '20px',
              borderTopLeftRadius: 4,
              borderBottomLeftRadius: 4,
              borderTopRightRadius: incorrectCount === 0 ? 4 : undefined,
              borderBottomRightRadius: incorrectCount === 0 ? 4 : undefined,
            }}
          ></div>
        </Tooltip>
        <Tooltip
          title={
            incorrectCount ? (
              <div>
                {incorrect.map((student) => (
                  <div key={student}>{student}</div>
                ))}
              </div>
            ) : (
              ''
            )
          }
        >
          <div
            style={{
              width: `${incorrectPercentage}%`,
              backgroundColor: theme.palette.accent.main,
              height: '20px',
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              borderTopLeftRadius: correctCount === 0 ? 4 : undefined,
              borderBottomLeftRadius: correctCount === 0 ? 4 : undefined,
            }}
          ></div>
        </Tooltip>
      </div>
      <div className={classes.labelsContainer}>
        <span>
          <Typography variant="h5">{correctCount} correct </Typography>
        </span>
        <span>
          <Typography variant="h5">{incorrectCount} incorrect </Typography>
        </span>
      </div>
    </div>
  );
}

export default CompletionStats;
