export const SS_SMART_CREATE_KEY: string = '__PODSIE_SMART_CREATE_KEY__';

export const SS_PODSIE_AI_CREATE_FORM_TEXT: string =
  '__PODSIE_AI_CREATE_FORM_TEXT__';

export const SS_SMART_CREATE_SELECTED_QUESTIONS_KEY: string =
  '__PODSIE_SMART_CREATE_SELECTED_QUESTIONS_KEY__';

export const SS_PODSIE_AI_CREATE_FORM_QUESTION_TYPE =
  '__PODSIE_AI_CREATE_FORM_QUESTION_TYPE__';
