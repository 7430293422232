import { produce } from 'immer';
import {
  AlertsConfirmation,
  AlertsDialog,
  AlertsSnack,
  ConfirmationActionTypes,
  DialogActionTypes,
  SnackActionTypes,
} from './actions';
import initialState from './initialState';

export type AlertAction =
  | { type: DialogActionTypes.OPEN_DIALOG; payload: AlertsDialog }
  | { type: DialogActionTypes.CLOSE_DIALOG }
  | {
      type: ConfirmationActionTypes.OPEN_CONFIRMATION;
      payload: AlertsConfirmation;
    }
  | { type: ConfirmationActionTypes.CLOSE_CONFIRMATION }
  | { type: SnackActionTypes.PUSH_SNACK; payload: AlertsSnack }
  | { type: SnackActionTypes.CLOSE_SNACK }
  | { type: SnackActionTypes.UPDATE_SNACK_QUEUE };

const reducer = (alerts = initialState, action: AlertAction) => {
  switch (action.type) {
    case DialogActionTypes.OPEN_DIALOG: {
      return produce(alerts, (alertsDraft) => {
        alertsDraft.dialog = action.payload;
      });
    }
    case DialogActionTypes.CLOSE_DIALOG: {
      return produce(alerts, (alertsDraft) => {
        alertsDraft.dialog = initialState.dialog;
      });
    }
    case ConfirmationActionTypes.OPEN_CONFIRMATION: {
      return produce(alerts, (alertsDraft) => {
        alertsDraft.confirmation = action.payload;
      });
    }
    case ConfirmationActionTypes.CLOSE_CONFIRMATION: {
      return produce(alerts, (alertsDraft) => {
        alertsDraft.confirmation = initialState.confirmation;
      });
    }
    case SnackActionTypes.PUSH_SNACK: {
      return produce(alerts, (alertsDraft) => {
        const { snacks } = alertsDraft;
        // push new snack into the queue:
        snacks.queue.push({
          ...action.payload,
          key: new Date().toDateString(),
        });

        // if another snack is already open, close it:
        if (snacks.open) {
          snacks.open = false;
        } else if (snacks.queue.length > 0) {
          // show the next snack:
          snacks.snack = snacks.queue.shift();
          snacks.open = true;
        }
      });
    }
    case SnackActionTypes.CLOSE_SNACK: {
      return produce(alerts, (alertsDraft) => {
        alertsDraft.snacks.open = false;
      });
    }
    case SnackActionTypes.UPDATE_SNACK_QUEUE: {
      return produce(alerts, (alertsDraft) => {
        const { snacks } = alertsDraft;
        if (snacks.queue.length > 0) {
          snacks.snack = snacks.queue.shift();
          snacks.open = true;
        }
      });
    }
    default:
      return alerts;
  }
};

export default reducer;
