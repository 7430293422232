import HelpKitButton from './HelpKitButton';

export function HelpKitQuestionTypes() {
  return (
    <HelpKitButton
      label="Learn about the different Question Types"
      articleId="8jNAzKMrVfxcxoqBeK7TSD"
    />
  );
}
export default HelpKitQuestionTypes;
