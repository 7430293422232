import { ExpandMore } from '@mui/icons-material';
import { Collapse, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useState } from 'react';
import type { QuestionAttributesFragment } from '../../../../gql/fragments/__generated__/question.generated';
import { QuestionTypeEnum } from '../../../../gql/types';
import { BreakdownDetailsResponses } from './BreakdownDetailsResponses';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px dotted ${theme.palette.secondary.main}`,
    marginTop: theme.spacing(1),
  },
  expandButton: {
    '&:hover': {
      cursor: 'pointer',
    },
    marginTop: theme.spacing(1),
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    color: theme.palette.secondary.main,
  },
  expandText: {
    color: '#666666',
  },
  expandedSection: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    color: theme.palette.secondary.main,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

type BreakdownDetailsProps = {
  question: QuestionAttributesFragment;
  defaultOpen?: boolean;
};

export function BreakdownDetails({
  question,
  defaultOpen,
}: BreakdownDetailsProps) {
  const classes = useStyles();
  const [open, setOpen] = useState(!!defaultOpen);
  const toggle = () => setOpen(!open);
  if (
    question.questionType === QuestionTypeEnum.FreeResponse ||
    question.questionType === QuestionTypeEnum.ShortAnswer
  ) {
    return (
      <>
        <div className={classes.root}>
          <button className={classes.expandButton} onClick={toggle}>
            <Typography className={classes.expandText} variant="caption">
              {open ? 'Collapse' : 'Expand'} Student Responses
            </Typography>
            <ExpandMore
              className={clsx(classes.expand, {
                [classes.expandOpen]: open,
              })}
            />
          </button>
        </div>
        <Collapse in={open} className={classes.expandedSection}>
          <Typography variant="h5">Student Responses</Typography>
          <BreakdownDetailsResponses question={question} />
        </Collapse>
      </>
    );
  }
  return null;
}
