import { useQuery } from '@apollo/client';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import { CssBaseline, Toolbar } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import GraphiQL from 'graphiql';
import { useEffect, useState } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from 'react-router-dom';
import { useAuth0 } from './Auth0Context';
import Alerts from './components/application/Alerts';
import { DemoConfigWarning } from './components/application/DemoConfigWarning';
import { EmailNotVerified } from './components/application/ErrorPages/EmailNotVerified';
import { NotFound } from './components/application/ErrorPages/NotFound';
import MobileBanner from './components/application/Layout/MobileBanner';
// relative components
import Header from './components/application/Layout/Header';
import { HeaderTitleProvider } from './components/application/Layout/Header/HeaderTitle';
import { drawerWidth, Nav } from './components/application/Layout/Nav';
import { QuestionsSearchProvider } from './components/application/Questions/Search/context/QuestionsSearchContext';
import { Userflow } from './components/application/Userflow';
import { TeacherDocument } from './gql/queries/__generated__/teacher.generated';
import { LS_DRAWER_OPEN_KEY } from './utils/localStorageKeys';
import { Assignments } from './views/Assignments';
import { Courses } from './views/Courses';
import { CurriculumView } from './views/Curriculum';
// views
import WarningBanner from './components/application/Layout/WarningBanner';
import { config } from './podsie-config.js';
import { Generate } from './views/Generate';
import { Groups } from './views/Groups';
import Homepage from './views/Homepage';
import { NotificationsView } from './views/Notifications';
import { PersonalDeck } from './views/PersonalDeck';
import { ProfileView } from './views/Profile';
import { Settings } from './views/Settings';
import { Setup } from './views/Setup';
import { DemoSetup } from './views/Setup/Demo';
import { Standards } from './views/StudentProgress';
import { Subject } from './views/Subject';

type StyleProps = {
  drawerOpen: boolean;
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    '& .link': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  sideNav: {
    // [theme.breakpoints.up('lg')]: {
    //   flexShrink: 0,
    // },
  },
  content: {
    flexGrow: 1,
    flexDirection: 'column',
    height: '100%',
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      marginLeft: ({ drawerOpen }: StyleProps) =>
        drawerOpen ? 0 : `-${drawerWidth}px`,
    },
  },
  header: {
    flex: '0 1 auto',
  },
  main: {
    flex: '1 1 auto',
  },
}));

const fetcher = createGraphiQLFetcher({
  url: 'http://localhost:3000/graphql',
});

function App() {
  const defaultOpen = localStorage.getItem(LS_DRAWER_OPEN_KEY) === 'true';
  const [drawerOpen, setDrawerOpen] = useState(defaultOpen);
  const { data: teacherData } = useQuery(TeacherDocument);
  const classes = useStyles({ drawerOpen });
  const history = useHistory();

  /* This logic enforces the user go to the setup if they haven't finished
      all of the setup steps */
  useEffect(() => {
    if (
      teacherData &&
      (!teacherData?.teacher.acceptedTosAt ||
        !teacherData?.teacher.acceptedPrivacyAt ||
        !teacherData.teacher.assignments.length)
    ) {
      history.push('/setup');
    }
  }, [teacherData, history]);

  /*  This logic prevents the user from backspacing out of the setup flow */
  useEffect(() => {
    window.onpopstate = () => {
      if (
        teacherData &&
        (!teacherData?.teacher.acceptedTosAt ||
          !teacherData?.teacher.acceptedPrivacyAt ||
          !teacherData.teacher.assignments.length)
      ) {
        history.push('/setup');
      }
    };
  });

  const handleDrawerToggle = () => {
    localStorage.setItem(LS_DRAWER_OPEN_KEY, (!drawerOpen).toString());
    setDrawerOpen(!drawerOpen);
  };

  return (
    <main className={classes.root}>
      <WarningBanner />
      <Header handleDrawerToggle={handleDrawerToggle} drawerOpen={drawerOpen} />
      <div className={classes.sideNav}>
        <Nav handleDrawerToggle={handleDrawerToggle} drawerOpen={drawerOpen} />
      </div>
      <div className={classes.content}>
        <header className={classes.header}>
          <Toolbar />
        </header>
        <section className={classes.main}>
          <QuestionsSearchProvider>
            <Switch>
              <Route exact path="/" component={Homepage} />
              <Route path="/settings" component={Settings} />
              <Route path="/classes" component={Groups} />
              <Route path="/assignments" component={Assignments} />
              <Route path="/progress">
                <Standards />
              </Route>
              <Route path="/settings/courses" component={Courses} />
              <Route path="/notifications" component={NotificationsView} />
              <Route path="/personal-deck" component={PersonalDeck} />
              <Route path="/subject" component={Subject} />
              <Route path="/generate" component={Generate} />
              <Route path="/profile" component={ProfileView} />
              <Route path="/demo-setup" component={DemoSetup} />
              <Route path="/curriculum" component={CurriculumView} />
              <Route component={NotFound} />
            </Switch>
          </QuestionsSearchProvider>
        </section>
      </div>
      <MobileBanner />
      <DemoConfigWarning />
    </main>
  );
}

// RoutedApp exists because
// 1) we want some logic in our main app to modify the history
// 2) We don't want the /setup page to show the side nav bar nor the header
function RoutedApp() {
  const { user } = useAuth0();
  const unverified =
    user && !(user.email_verified || user['https://podsie.org/microsoft-auth']);
  if (unverified) {
    return <EmailNotVerified email={user.email} />;
  }

  return (
    <Router>
      <Userflow token={config.userflow.token} />
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Switch>
          <Route
            path="/graphiql"
            render={() => <GraphiQL shouldPersistHeaders fetcher={fetcher} />}
          />
          <Route path="/setup" component={Setup} />
          <HeaderTitleProvider>
            <Route component={App} />
          </HeaderTitleProvider>
        </Switch>
      </LocalizationProvider>
      <Alerts />
    </Router>
  );
}
export default RoutedApp;
