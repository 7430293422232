import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import theme from '../../../theme';
import { AnswersBreakdown } from './Answers/AnswersBreakdown';
import { Flag } from './Flag';
import { Dict } from './StandardDisplay';
import { ConceptHolder, ENDPOINT } from './utils';

const approveConcept = async (conceptID: number, userID: number) => {
  const response = await fetch(`${ENDPOINT}/approvals/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ concept_id: conceptID, user_id: userID }),
  });
  if (!response.ok) {
    throw new Error('Failed to create approval');
  }
  const data = await response.json();
  return data;
};

const removeApprovalFromConcept = async (conceptID: number, userID: number) => {
  const response = await fetch(`${ENDPOINT}/approvals/${conceptID}/${userID}`, {
    method: 'DELETE',
  });
  if (!response.ok) {
    throw new Error('Failed to remove approval');
  }
  const data = await response.json();
  return data;
};

export function FlaggableQuestion({
  concept,
  userID,
  setApprovals,
  isApproved,
  isFlagged,
  setFlags,
  conceptID,
}: {
  concept: ConceptHolder;
  userID: number;
  isApproved: boolean;
  setApprovals: React.Dispatch<React.SetStateAction<Dict>>;
  isFlagged: boolean;
  setFlags: React.Dispatch<React.SetStateAction<Dict>>;
  conceptID: number;
}) {
  const question = concept.question;
  const [collapsed, setCollapsed] = useState(isApproved || isFlagged);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const handleApproval = async () => {
    if (isApproved) {
      await removeApprovalFromConcept(conceptID, userID);
      setApprovals((prev) => ({ ...prev, [conceptID]: false }));
      setCollapsed(false);
    } else {
      await approveConcept(conceptID, userID);
      setApprovals((prev) => ({ ...prev, [conceptID]: true }));
      setCollapsed(true);
    }
  };
  const setFlagged = () => {
    setFlags((prev) => ({ ...prev, [conceptID]: true }));
    setCollapsed(true);
  };
  let backgroundColor = theme.palette.background.paper;
  if (isApproved) {
    backgroundColor = theme.palette.mint.light;
  } else if (isFlagged) {
    backgroundColor = '#FFCCCC';
  }
  return (
    <Box
      sx={{
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        marginTop: 1,
        marginBottom: 1,
      }}
    >
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        expanded={!collapsed}
        sx={{
          width: '100%',
          marginBottom: 3,
          background: backgroundColor,
        }}
      >
        <AccordionSummary
          sx={{
            color: 'primary.main',
            padding: 3,
            borderRadius: '4px',
            background: backgroundColor,
            flex: 1,
            flexBasis: '50%',
            '& .MuiAccordionSummary-expandIconWrapper': {
              color: 'secondary.main',
            },
          }}
          aria-controls="standards-category"
          id="standards-category"
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography
                variant="body1"
                sx={{ fontWeight: theme.typography.fontWeightBold }}
              >
                <span style={{ textDecoration: 'underline' }}>
                  Concept (not shown to students):
                </span>{' '}
                {concept.concept.text}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Flag
                  title="question"
                  questionID={question.id}
                  conceptID={conceptID}
                  userID={userID}
                  filled={isFlagged}
                  setFlagged={setFlagged}
                >
                  <Card
                    sx={{
                      marginBottom: 1,
                      marginTop: 1,
                      padding: 2,
                      maxWidth: 800,
                    }}
                  >
                    <CardContent>
                      <Typography variant="body1">
                        {question.question_text}
                      </Typography>
                      <Box sx={{ paddingTop: 2 }}>
                        <AnswersBreakdown question={question} />
                      </Box>
                    </CardContent>
                  </Card>
                </Flag>
                <Tooltip title="Complete question">
                  <Checkbox
                    size="medium"
                    color="success"
                    onClick={handleApproval}
                    checked={isApproved}
                  />
                </Tooltip>
                <IconButton onClick={toggle}>
                  {collapsed ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
              </Box>
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: theme.palette.common.black,
                textDecoration: 'underline',
                fontWeight: theme.typography.fontWeightBold,
                mt: 2,
              }}
            >
              Question
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {question.question_text}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ flex: 1, maxWidth: 800, margin: 1 }}>
          <AnswersBreakdown question={question} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
