import { useQuery } from '@apollo/client';
import {
  Close,
  DeleteForever,
  Edit,
  FileCopy,
  NavigateNext,
} from '@mui/icons-material';
import {
  Badge,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import QuestionShow from '.';
import { GetUpdateQuestionDocument } from '../../../../gql/queries/__generated__/question.generated';
import { CustomTooltip } from '../../../shared/CustomTooltip';
import { UpdateQuestion } from '../Form/UpdateQuestion';
import { QuestionFormContext } from '../Form/context';
import { resetQuestionForm } from '../Form/context/actions';
import ActiveToggle from '../Settings/ActiveToggle';
import { DeleteQuestionConfirmation } from './DeleteQuestionConfirmation';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  updateContainer: {
    padding: theme.spacing(2),
  },
  content: {
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      overflow: 'auto',
    },
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  header: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '14px 15px',
  },
  iconButtons: {
    height: 30,
    padding: 0,
    marginRight: theme.spacing(1),
  },

  buttonsContainer: {
    marginLeft: theme.spacing(2),
  },
  prevButton: {
    position: 'absolute',
    bottom: '50%',
    '&:hover': {
      opacity: '.8',
    },
  },
  nextButton: {
    position: 'absolute',
    bottom: '50%',
    right: 0,
    '&:hover': {
      opacity: '.8',
    },
  },
  next: {
    color: theme.palette.primary.main,
  },
  back: {
    transform: 'rotate(180deg)',
    color: theme.palette.common.white,
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
}));

type QuestionShowModalProps = {
  questionId?: string;
  open: boolean;
  handleClose: () => void;
  copyable?: boolean;
  editable?: boolean;
  assignmentId?: string;
  selectNext?: () => void;
  loading?: boolean;
  selectPrev?: () => void;
  addQuestionToAssignment?: () => void;
  selectedQuestionAdded?: boolean;
};

export function QuestionShowModal({
  questionId,
  open,
  handleClose,
  copyable,
  editable,
  assignmentId,
  selectPrev,
  selectNext,
  loading,
  selectedQuestionAdded,
  addQuestionToAssignment,
}: QuestionShowModalProps) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const { dispatch } = useContext(QuestionFormContext);
  const location = useLocation();
  const { data } = useQuery(GetUpdateQuestionDocument, {
    skip: !questionId,
    variables: { questionId: questionId || '' },
  });
  const [deleteOpen, setDeleteOpen] = useState(false);
  const history = useHistory();
  const onClose = () => {
    handleClose();
    setIsEditing(false);
    setDeleteOpen(false);
  };

  const toggleEdit = () => setIsEditing(!isEditing);

  const handleCopy = () => {
    if (dispatch && data) {
      dispatch(resetQuestionForm({ question: data.question }));
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
    if (assignmentId) {
      history.push(`/assignments/${assignmentId}/questions/update`);
    }
    handleClose();
  };

  const handlePrev = () => {
    if (selectPrev) {
      selectPrev();
    }
  };
  const handleNext = () => {
    if (selectNext) {
      selectNext();
    }
  };

  // only allow copying from the questions editor page because otherwise it
  // is not nested under the QuestionFormContext:
  const canCopy = copyable && location.pathname.includes('update');

  const closeDelete = () => setDeleteOpen(false);
  const numQuestion = data?.question?.variations?.length || 0;

  const renderViews = () => {
    if (!questionId) {
      return null;
    }

    if (isEditing) {
      return (
        <div className={classes.updateContainer}>
          <UpdateQuestion questionId={questionId} />
        </div>
      );
    }

    return (
      <QuestionShow
        questionId={questionId}
        afterDelete={onClose}
        showStandards={true}
      />
    );
  };

  const renderAction = () => {
    if (addQuestionToAssignment) {
      return (
        <Tooltip
          title={
            selectedQuestionAdded ? 'Question already added to assignment.' : ''
          }
        >
          <Button
            onClick={addQuestionToAssignment}
            variant="contained"
            color="primary"
            disabled={selectedQuestionAdded || loading}
            className={classes.addButton}
          >
            {editable ? 'Add to Assignment' : 'Copy & Add to Assignment'}
          </Button>
        </Tooltip>
      );
    }
    return <ActiveToggle questionId={questionId} />;
  };
  return (
    <Dialog maxWidth="md" fullWidth open={open} scroll="body">
      <DialogTitle className={classes.header}>
        <div className={classes.titleContainer}>
          <Typography variant="h2" className={classes.title}>
            QUESTION PREVIEW/DETAILS
          </Typography>
          {editable && (
            <div className={classes.buttonsContainer}>
              <IconButton
                className={classes.iconButtons}
                aria-label="Edit Question"
                title="Edit Question"
                onClick={toggleEdit}
                size="large"
              >
                {isEditing ? (
                  <Tooltip title="Close 'edit question' mode">
                    <Close />
                  </Tooltip>
                ) : (
                  <Badge
                    color="primary"
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    badgeContent={numQuestion}
                    showZero
                  >
                    <Edit />
                  </Badge>
                )}
              </IconButton>
              {canCopy && (
                <IconButton
                  aria-label="Copy Question"
                  title="Copy Question"
                  onClick={handleCopy}
                  className={classes.iconButtons}
                  size="large"
                >
                  <FileCopy />
                </IconButton>
              )}
              <CustomTooltip
                open={deleteOpen}
                title={
                  <DeleteQuestionConfirmation
                    parentQuestion
                    questionId={questionId}
                    afterDelete={onClose}
                    closeDelete={closeDelete}
                  />
                }
              >
                <IconButton
                  aria-label="Delete Question"
                  title="Delete Question"
                  onClick={() => setDeleteOpen(!deleteOpen)}
                  className={classes.iconButtons}
                  size="large"
                >
                  <DeleteForever />
                </IconButton>
              </CustomTooltip>
            </div>
          )}
        </div>
        <div>
          {renderAction()}
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={onClose}
            size="large"
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.content}>{renderViews()}</DialogContent>
      {selectPrev && (
        <IconButton
          size="small"
          className={classes.prevButton}
          onClick={handlePrev}
        >
          <NavigateNext className={classes.back} />
        </IconButton>
      )}
      {selectNext && (
        <IconButton
          size="small"
          className={classes.nextButton}
          onClick={handleNext}
        >
          <NavigateNext className={classes.next} />
        </IconButton>
      )}
    </Dialog>
  );
}
