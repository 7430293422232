import HelpKitButton from './HelpKitButton';

export function HelpKitPersonalDeckStudySession() {
  return (
    <HelpKitButton
      label="Learn more about Personal Deck Study Sessions."
      articleId="cPzmZ4sx4PWyT3NdWZeGgo"
    />
  );
}
export default HelpKitPersonalDeckStudySession;
