import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { QuestionAttributesFragment } from '../../../gql/fragments/__generated__/question.generated';
import { QuillDeltaAsHtml } from '../QuillDeltaAsHtml';

type StyleProps = {
  reviewable?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  freeResponseAnswer: {
    marginTop: theme.spacing(1),
    lineHeight: 'normal',
    fontSize: ({ reviewable }: StyleProps) =>
      reviewable ? 'calc(12px + 1.1vw)' : 'inherit',
  },
  label: {
    textDecoration: 'underline',
  },
}));

type FreeResponseBreakdownProps = {
  question: QuestionAttributesFragment;
  hideAnswers?: boolean;
  reviewable?: boolean;
};

const hiddenRichText = [{ insert: 'Answer Hidden' }];

export function FreeResponseBreakdown({
  question,
  hideAnswers,
  reviewable,
}: FreeResponseBreakdownProps) {
  const classes = useStyles({ reviewable });

  if (hideAnswers) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.label}>
        Exemplar Answer
      </Typography>
      <div className={classes.freeResponseAnswer}>
        <QuillDeltaAsHtml
          delta={
            hideAnswers
              ? hiddenRichText
              : question.freeResponseAnswer?.richText.ops
          }
          style={{ padding: 0 }}
        />
      </div>
    </div>
  );
}
