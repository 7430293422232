import { ChevronLeft } from '@mui/icons-material';
import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  backButton: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    '&:hover': {
      color: '#fff',
    },
  },
  chevron: {
    marginLeft: theme.spacing(-1),
  },
}));

export function HeaderBackButton({ onClickBack }: { onClickBack: () => void }) {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="secondary"
      size="medium"
      className={classes.backButton}
      onClick={onClickBack}
    >
      <ChevronLeft className={classes.chevron} /> Back
    </Button>
  );
}
