import { useContext, useEffect } from 'react';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import { Profile } from '../../components/application/Profile';

export function ProfileView() {
  const { updateTitle } = useContext(HeaderTitleContext);
  useEffect(() => updateTitle('Profile'), [updateTitle]);

  return <Profile />;
}
