import { useQuery } from '@apollo/client';
import type { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useEffect } from 'react';
import { useParams, type RouteComponentProps } from 'react-router-dom';
import { AlertsContext } from '../../components/application/Alerts/context';
import GroupAssignments from '../../components/application/Groups/Assignments';
import { GroupUpdateDelete } from '../../components/application/Groups/GroupUpdateDelete';
import { StudentsTable } from '../../components/application/Groups/StudentsTable';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import Leaderboard from '../../components/application/Leaderboard/Leaderboard';
import CustomTabs from '../../components/shared/Layout/Tabs';
import { GroupLiteDocument } from '../../gql/queries/__generated__/group.generated';
import { EnrollmentStatusEnum } from '../../gql/types';
import { onError } from '../../utils/apollo/apolloHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  content: {
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    backgroundColor: theme.palette.common.white,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

export type StudentRow = {
  email: string;
  id: string;
  lastActivity?: Date;
  lastName?: string | null;
  numCompletedLast7Days?: number | string;
  numDuePersonalDecksQuestions?: number | string;
  numLateAssignments?: number;
  sortName?: string | null;
};

export function GroupShow() {
  const classes = useStyles();
  const { groupId } = useParams<{ groupId: string }>();
  const basePath = `/classes/:groupId`;
  const baseValuePath = `/classes/${groupId}`;
  const assignmentsPath = `${basePath}/assignments`;
  const assignmentsValuePath = `${baseValuePath}/assignments`;
  const leaderboardPath = `${basePath}/leaderboard`;
  const leaderboardValuePath = `${baseValuePath}/leaderboard`;
  const { dispatch } = useContext(AlertsContext);
  const { data } = useQuery(GroupLiteDocument, {
    fetchPolicy: 'cache-and-network',
    onError: onError(dispatch),
    variables: { groupId },
  });
  const groupName = data?.group.name || '';
  const { updateTitle } = useContext(HeaderTitleContext);
  useEffect(() => updateTitle(groupName), [updateTitle, groupName]);

  const enrollments = data?.group.enrollments || [];
  const numEnrolled = enrollments.filter(({ enrollmentStatus }) => {
    return enrollmentStatus === EnrollmentStatusEnum.Enrolled;
  }).length;

  return (
    <div className={classes.root}>
      <GroupUpdateDelete groupId={groupId} />
      <CustomTabs
        tabs={[
          {
            path: basePath,
            label: `ROSTER${numEnrolled ? ` (${numEnrolled})` : ''}`,
            pathValue: baseValuePath,
            component: () => <StudentsTable groupId={groupId} />,
          },
          {
            path: assignmentsPath,
            pathValue: assignmentsValuePath,
            label: 'ASSIGNMENTS',
            component: (props: RouteComponentProps) => (
              <GroupAssignments groupId={groupId} {...props} />
            ),
          },
          {
            path: leaderboardPath,
            pathValue: leaderboardValuePath,
            label: 'LEADERBOARD',
            component: (props: RouteComponentProps) => (
              <Leaderboard groupId={groupId} {...props} />
            ),
          },
        ]}
      />
    </div>
  );
}
