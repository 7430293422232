import { useLazyQuery } from '@apollo/client';
import { Add, ArrowDropDown, Close, Delete, Help } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Quill, { Sources } from 'quill';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { UnprivilegedEditor, type Value } from 'react-quill';
import { ShortAnswerVariationsDocument } from '../../../../../gql/queries/__generated__/shortAnswer.generated';
import { isRichText } from '../../../../../utils/question';
import {
  containsImages,
  extractPlainAndRichText,
} from '../../../../../utils/quillHelper';
import { CustomTooltip } from '../../../../shared/CustomTooltip';
import InlineEditor from '../../../../shared/Editor/Inline';
import AiResponseLoading from '../../../../shared/Loaders/AiResponseLoading';
import { AlertsContext } from '../../../Alerts/context';
import { pushSnack } from '../../../Alerts/context/actions';
import { QuestionFormContext } from '../context';
import {
  addShortAnswerAnswer,
  deleteShortAnswerAnswer,
  updateShortAnswerAnswer,
} from '../context/actions';

const Delta = Quill.import('delta');

const useStyles = makeStyles((theme: Theme) => ({
  answerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(0.5),
  },
  settingsTooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
  settingsTooltipHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  settingsContainers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tooltipHeaderSpacing: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tooltipFooterSpacing: {
    paddingBottom: theme.spacing(2),
  },
  loadingContainer: {
    marginTop: theme.spacing(1),
  },
}));

export function ShortAnswerAnswers() {
  const classes = useStyles();
  const MAX_ANSWER_LENGTH = 40;
  const MAX_ANSWERS = 10;
  const { questionForm, dispatch } = useContext(QuestionFormContext);
  const [error, setError] = useState(false);
  const { dispatch: alertsDispatch } = useContext(AlertsContext);

  const { question, shortAnswerAnswers } = questionForm;

  const questionContainsImages =
    questionForm &&
    questionForm.question &&
    containsImages(questionForm.question);

  const updateShortAnswer = (shortAnswerIndex: number, field: string) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let value;
      if (field === 'isCaseSensitive' || field === 'ignoreWhitespaces') {
        value = e.target.checked;
      } else {
        value = e.target.value;
        if (value.length > MAX_ANSWER_LENGTH) {
          setError(true);
          return;
        }
      }

      dispatch(
        updateShortAnswerAnswer({
          [field]: value,
          shortAnswerIndex,
        })
      );
      setError(false);
    };
  };

  const deleteShortAnswer = (shortAnswerIndex: number) => {
    return () => {
      dispatch(deleteShortAnswerAnswer({ shortAnswerIndex }));
    };
  };

  const [fetchVariations, { loading }] = useLazyQuery(
    ShortAnswerVariationsDocument
  );
  const handleAutomatedVariations = () => {
    const { text } = extractPlainAndRichText(question);
    const serializedShortAnswers = shortAnswerAnswers.map(
      ({ data: { richText } }) => JSON.stringify(richText)
    );

    fetchVariations({
      variables: {
        question: text,
        currentAnswers: serializedShortAnswers,
      },
    })
      .then((res) => {
        console.log(res, 'res');
        res.data?.shortAnswerVariations?.forEach((variation) => {
          dispatch(
            addShortAnswerAnswer({
              shortAnswerDelta: variation,
            })
          );
        });
      })
      .catch((err) => console.error('HELP', err));
  };
  const generateVariationFunctionality = () => {
    return (
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleAutomatedVariations}
        startIcon={<Add />}
        disabled={questionContainsImages || loading}
      >
        AI Generate
      </Button>
    );
  };

  const addShortAnswer = () => {
    if (shortAnswerAnswers.length === MAX_ANSWERS) {
      alertsDispatch(
        pushSnack({
          message: 'You cannot have more than 10 acceptable answers.',
          severity: 'error',
        })
      );
    } else {
      dispatch(
        addShortAnswerAnswer({ shortAnswerDelta: new Delta({ ops: [] }) })
      );
    }
  };

  const refs = useRef(Array.of<HTMLDivElement | null>());
  const wrapperRefs = useRef(Array.of<HTMLButtonElement | null>());

  const content = (
    <div>
      {shortAnswerAnswers.map(
        (
          {
            data: { text, richText, isCaseSensitive, ignoreWhitespaces },
            settingsOpen,
          },
          shortAnswerIndex
        ) => {
          const settingsTooltip = (
            <div
              className={classes.settingsTooltip}
              ref={(elem) => refs.current.push(elem)}
            >
              <div className={classes.settingsTooltipHeader}>
                <Typography variant="h4">Settings</Typography>
                <IconButton
                  onClick={() => {
                    dispatch(
                      updateShortAnswerAnswer({
                        settingsOpen: false,
                        shortAnswerIndex,
                      })
                    );
                  }}
                  size="large"
                >
                  <Close />
                </IconButton>
              </div>
              <div className={classes.settingsContainers}>
                <FormControlLabel
                  label="Case Sensitive"
                  control={
                    <Checkbox
                      checked={isCaseSensitive}
                      onChange={updateShortAnswer(
                        shortAnswerIndex,
                        'isCaseSensitive'
                      )}
                      name="case-sensitive"
                      color="primary"
                    />
                  }
                />
                <CustomTooltip
                  title={
                    <div className={classes.tooltipHeaderSpacing}>
                      <Typography
                        variant="h4"
                        className={classes.tooltipHeaderSpacing}
                      >
                        What does this do?
                      </Typography>
                      If checked, Podsie will take upper and lower case into
                      consideration when assessing student responses. For
                      example, <b>&ldquo;answer&rdquo;</b> will not match{' '}
                      <b>&ldquo;Answer&rdquo;</b>, but{' '}
                      <b>&ldquo;Answer&rdquo;</b> will match{' '}
                      <b>&ldquo;Answer&rdquo;</b>.
                    </div>
                  }
                >
                  <Help fontSize="small" />
                </CustomTooltip>
              </div>
              <div className={classes.settingsContainers}>
                <FormControlLabel
                  label="Ignore Whitespaces"
                  control={
                    <Checkbox
                      checked={ignoreWhitespaces}
                      onChange={updateShortAnswer(
                        shortAnswerIndex,
                        'ignoreWhitespaces'
                      )}
                      name="ignore-whitespaces"
                      color="primary"
                    />
                  }
                />
                <CustomTooltip
                  title={
                    <div className={classes.tooltipFooterSpacing}>
                      <Typography
                        variant="h4"
                        className={classes.tooltipHeaderSpacing}
                      >
                        What does this do?
                      </Typography>
                      If checked, Podsie will ignore whitespaces when assessing
                      student responses. For example{' '}
                      <b>&ldquo;(1 + 1)&rdquo;</b> will match{' '}
                      <b>&ldquo;(1+1)&rdquo;</b>, and{' '}
                      <b>&ldquo;answer&rdquo;</b> will match{' '}
                      <b>&ldquo;ans wer&rdquo;</b>.
                    </div>
                  }
                >
                  <Help fontSize="small" />
                </CustomTooltip>
              </div>
            </div>
          );

          if (!richText?.ops?.length) {
            // This is the case where backfill job has not run yet
            // we cannot pass the backfill Delta directly to the editor
            // because it creates an infinite loop or rerendering
            // so dispatching a state change here
            // FIXME: This synchronously dispatches an action that triggers a
            //        re-render for a parent component if the short answer has
            //        no rich text representation.
            dispatch(
              updateShortAnswerAnswer({
                text,
                richText: new Delta({ ops: [{ insert: text }] }),
                shortAnswerIndex,
              })
            );
            return null;
          }

          return (
            <div
              key={`short-answer-${shortAnswerIndex}`}
              className={classes.answerContainer}
            >
              <InlineEditor
                placeholder="Accepted Answer"
                value={richText}
                errorMessage={error ? '40 character limit exceeded!' : ''}
                onChange={(
                  value: string,
                  delta: Value,
                  source: Sources,
                  editor: UnprivilegedEditor
                ) => {
                  const editorContents = editor.getContents();
                  const { text, richText } =
                    extractPlainAndRichText(editorContents);

                  if (text.length > MAX_ANSWER_LENGTH) {
                    setError(true);
                    return;
                  }

                  dispatch(
                    updateShortAnswerAnswer({
                      text,
                      richText: isRichText(richText) ? richText : undefined,
                      shortAnswerIndex,
                    })
                  );
                  setError(false);
                }}
              />
              <CustomTooltip open={settingsOpen} title={settingsTooltip}>
                <IconButton
                  ref={(elem) => wrapperRefs.current.push(elem)}
                  onClick={() => {
                    dispatch(
                      updateShortAnswerAnswer({
                        settingsOpen: !settingsOpen,
                        shortAnswerIndex,
                      })
                    );
                  }}
                  size="large"
                >
                  <ArrowDropDown />
                </IconButton>
              </CustomTooltip>
              <IconButton
                disabled={shortAnswerAnswers.length <= 1}
                aria-label="delete short answer"
                onClick={deleteShortAnswer(shortAnswerIndex)}
                size="large"
              >
                <Delete />
              </IconButton>
            </div>
          );
        }
      )}
      {loading ? (
        <div className={classes.loadingContainer}>
          <AiResponseLoading numSkeletons={2} skeletonHeight={10} />
        </div>
      ) : null}
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={addShortAnswer}
        startIcon={<Add />}
      >
        Manually add answer
      </Button>
      {questionContainsImages ? (
        <Tooltip
          title="Your question contains images, which this feature does not support."
          placement="bottom-start"
        >
          <span>{generateVariationFunctionality()}</span>
        </Tooltip>
      ) : (
        generateVariationFunctionality()
      )}
    </div>
  );

  useEffect(() => {
    refs.current.forEach((elem, idx) => {
      const cb = (event: MouseEvent) => {
        if (
          elem &&
          // @ts-expect-error because `event.target` is not known to be a Node
          !elem.contains(event.target)
        ) {
          dispatch(
            updateShortAnswerAnswer({
              settingsOpen: false,
              shortAnswerIndex: idx,
            })
          );
        }
      };
      document.addEventListener('mouseup', cb, false);
      return () => {
        document.removeEventListener('mouseup', cb, false);
      };
    });

    wrapperRefs.current.forEach((elem, idx) => {
      const cb = (event: MouseEvent) => {
        if (
          elem &&
          // @ts-expect-error because `event.target` is not known to be a Node
          !elem.contains(event.target)
        ) {
          dispatch(
            updateShortAnswerAnswer({
              settingsOpen: false,
              shortAnswerIndex: idx,
            })
          );
        }
      };
      document.addEventListener('mouseup', cb, false);
      return () => {
        document.removeEventListener('mouseup', cb, false);
      };
    });
  }, [refs, wrapperRefs, dispatch]);

  return content;
}
