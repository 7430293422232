import { useMutation, useQuery } from '@apollo/client';
import { DragIndicator, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { produce } from 'immer';
import { useContext, useMemo, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from 'react-beautiful-dnd';
import { ReorderItemDocument } from '../../../../gql/mutations/__generated__/positionable.generated';
import { QuestionsForStandardDocument } from '../../../../gql/queries/__generated__/question.generated';
import {
  StandardsChartAdminsDocument,
  StandardsChartDocument,
  type StandardsChartQuery,
} from '../../../../gql/queries/__generated__/standardsChart.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { Team } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { AlertsContext } from '../../Alerts/context';
import HelpKitLearningStandards from '../../HelpKitArticles/HelpKitLearningStandards';
import CategoryCreator from './CategoryCreator';
import CategoryUpdater from './CategoryUpdater';
import StandardCreator from './StandardCreator';
import StandardDisplay from './StandardDisplay';
import {
  STANDARD_QUESTIONS_PAGINATION_COUNT,
  StandardQuestionsModal,
} from './StandardQuestionsModal';
import SubjectGroupInfo from './SubjectGroupInfo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    paddingTop: theme.spacing(3),
  },
  expandIcon: {
    color: theme.palette.secondary.main,
  },
  summary: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: '4px',
  },
  expandedContainer: {
    display: 'flex',
    flexFlow: 'column',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  closeButton: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  accordionContainer: {
    maxWidth: 1600,
    margin: '0 auto',
    marginBottom: theme.spacing(3),
  },
  accordion: {
    border: `1px solid ${theme.palette.mint.main}`,
  },
  dragIconContainer: {
    position: 'relative',
    width: theme.spacing(3.5),
  },
  dragIcon: {
    position: 'absolute',
    top: theme.spacing(0.5),
    color: theme.palette.mint.dark,
  },
  loadingContainer: {
    margin: '0 auto',
    maxWidth: 1232,
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  innerFlex: {
    display: 'flex',
  },
  topContainer: {
    maxWidth: 1280,
    margin: '0 auto',
    [theme.breakpoints.up('xs')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  // standardsCard: {
  //   marginBottom: theme.spacing(2),
  // },
  title: {
    textDecoration: 'underline',
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(1.5),
  },
  textFlex: {
    display: 'flex',
    alignItems: 'baseline',
  },
}));

export function StandardsSetup() {
  const classes = useStyles();
  const { data: teacherData } = useQuery(TeacherDocument);
  const [selectedStandardId, setSelectedStandardId] = useState('');
  const [selectedStandardTitle, setSelectedStandardTitle] = useState('');
  const activeCourse = teacherData?.teacher.activeCourse;
  const standardsChartId = activeCourse?.standardsChart?.id || '';
  const { refetch: refetchUntagged } = useQuery(QuestionsForStandardDocument, {
    skip: !activeCourse?.team?.id,
    variables: {
      first: STANDARD_QUESTIONS_PAGINATION_COUNT,
      teamId: activeCourse?.team?.id ?? '',
    },
  });
  const team = activeCourse?.team;
  const [categories, setCategories] = useState<
    StandardsChartQuery['standardsChart']['standardsCategories']
  >([]);
  const { data: adminData } = useQuery(StandardsChartAdminsDocument, {
    skip: !standardsChartId,
    variables: { standardsChartId: standardsChartId },
  });
  const cache: { [key: string]: boolean } = useMemo(() => {
    const obj: { [key: string]: boolean } = {};
    if (!adminData) {
      return obj;
    }

    adminData.standardsChart.admins.forEach((admin) => {
      obj[admin.id] = true;
    });

    return obj;
  }, [adminData]);

  const amAdmin = !!(teacherData && cache[teacherData.teacher.id]);
  const { loading, refetch } = useQuery(StandardsChartDocument, {
    // items should have come from the backend in the correct position already:
    onCompleted: (res) => setCategories(res.standardsChart.standardsCategories),
    skip: !standardsChartId,
    variables: { standardsChartId },
  });
  const { dispatch } = useContext(AlertsContext);
  const handleError = onError(dispatch);
  const [reorderItem] = useMutation(ReorderItemDocument, {
    onError: (error) => {
      error.message =
        'Error occurred with reordering. Please refresh your page and try again.';
      handleError(error);
    },
    onCompleted: () => refetch(),
  });

  const handleClose = () => {
    refetchUntagged();
    setSelectedStandardId('');
    setSelectedStandardTitle('');
  };

  const refetchData = () => refetch();

  const handleDragEnd = (result: DropResult) => {
    if (!categories) {
      return;
    }
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (type === 'category') {
      const updated = Array.from(categories || []);
      const insert = updated.find((el) => draggableId === `cat-${el.id}`);
      if (!insert) {
        return;
      }
      updated.splice(source.index, 1);
      updated.splice(destination.index, 0, insert);
      reorderItem({
        variables: {
          type: 'StandardsCategory',
          parentType: 'StandardsChart',
          parentId: standardsChartId,
          reorderedId: categories[source.index].id,
          destinationId: categories[destination.index].id,
        },
      });
      setCategories(updated);
    } else {
      const updated = produce(categories, (draft) => {
        const updating = draft?.find(
          (el) => type === `standards-for-cat-${el.id}`
        );
        const insert = updating?.standards.find(
          (el) => draggableId === `standard-${el.id}`
        );
        if (!updating?.standards || !insert) {
          return;
        }
        updating?.standards.splice(source.index, 1);
        updating?.standards.splice(destination.index, 0, insert);
      });
      const categoryId = type.split('standards-for-cat-')[1];
      const standardId = draggableId.split('standard-')[1];
      const destinationId = categories.find(
        (el) => el.id.toString() === categoryId
      )?.standards[destination.index].id;
      if (!destinationId) {
        return;
      }
      reorderItem({
        variables: {
          type: 'Standard',
          parentType: 'StandardsCategory',
          parentId: categoryId,
          reorderedId: standardId,
          destinationId: destinationId,
        },
      });
      setCategories(updated);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <section className={classes.content}>
        <div className={classes.topContainer}>
          <SubjectGroupInfo
            setSelectedStandardId={setSelectedStandardId}
            team={team as Team}
          />
          <div className={classes.textFlex}>
            <Typography variant="h2" color="primary" className={classes.title}>
              Learning Standards
            </Typography>
            (
            <HelpKitLearningStandards />)
          </div>
          {loading && (
            <div className={classes.loadingContainer}>
              <LoadingSkeletons num={20} />
            </div>
          )}
        </div>
        <Droppable droppableId="category-dropzone" type="category">
          {(provided) => (
            <Container {...provided.droppableProps} ref={provided.innerRef}>
              {categories?.map((category, i) => {
                return (
                  <Draggable
                    draggableId={`cat-${category.id}`}
                    key={`cat-${category.id}`}
                    index={i}
                  >
                    {(draggableProvided) => (
                      <div
                        className={classes.accordionContainer}
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                      >
                        <Accordion
                          TransitionProps={{ unmountOnExit: true }}
                          className={classes.accordion}
                        >
                          <AccordionSummary
                            className={classes.summary}
                            expandIcon={
                              <ExpandMore className={classes.expandIcon} />
                            }
                            aria-controls="standards-category"
                            id="standards-category"
                          >
                            <div className={classes.flex}>
                              <div className={classes.innerFlex}>
                                {amAdmin && (
                                  <div
                                    className={classes.dragIconContainer}
                                    {...draggableProvided.dragHandleProps}
                                  >
                                    <DragIndicator
                                      className={classes.dragIcon}
                                    />
                                  </div>
                                )}
                                <CategoryUpdater
                                  refetchData={refetchData}
                                  category={category}
                                  amAdmin={amAdmin}
                                />
                              </div>
                              <Typography>{`${category.standards.length} ${
                                category.standards.length === 1
                                  ? 'Standard'
                                  : 'Standards'
                              }`}</Typography>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails
                            className={classes.expandedContainer}
                          >
                            <Droppable
                              droppableId={`standards-for-cat-${category.id}-dropzone`}
                              type={`standards-for-cat-${category.id}`}
                            >
                              {(innerProvided) => (
                                <div
                                  {...innerProvided.droppableProps}
                                  ref={innerProvided.innerRef}
                                >
                                  {category.standards.map(
                                    (standard, innerIndex) => {
                                      return (
                                        <Draggable
                                          index={innerIndex}
                                          draggableId={`standard-${standard.id}`}
                                          key={`standard-${standard.id}`}
                                        >
                                          {(innerDraggableProvided) => (
                                            <div
                                              {...innerDraggableProvided.draggableProps}
                                              ref={
                                                innerDraggableProvided.innerRef
                                              }
                                            >
                                              <StandardDisplay
                                                refetchData={refetchData}
                                                amAdmin={amAdmin}
                                                dragHandleProps={
                                                  innerDraggableProvided.dragHandleProps
                                                }
                                                key={standard.id}
                                                setSelectedStandardId={
                                                  setSelectedStandardId
                                                }
                                                setSelectedStandardTitle={
                                                  setSelectedStandardTitle
                                                }
                                                standard={standard}
                                                standardsCategoryId={
                                                  category.id
                                                }
                                              />
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    }
                                  )}
                                  {innerProvided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            {amAdmin && (
                              <StandardCreator
                                refetchData={refetchData}
                                standardsCategoryId={category.id}
                              />
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Container>
          )}
        </Droppable>
        {amAdmin && <CategoryCreator refetchData={refetchData} />}
        <StandardQuestionsModal
          selectedStandardId={selectedStandardId}
          handleClose={handleClose}
          selectedStandardTitle={selectedStandardTitle}
        />
      </section>
    </DragDropContext>
  );
}
