import { useQuery } from '@apollo/client';
import {
  AccountCircleOutlined,
  AssignmentOutlined,
  ExpandLess,
  ExpandMore,
  Group,
  Home,
  Notifications,
  Repeat,
  TrendingUpOutlined,
} from '@mui/icons-material';
import {
  Badge,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { default as Logo } from '../../../../assets/PodsieLogo.svg?react';
import { NotificationsCountDocument } from '../../../../gql/queries/__generated__/actionItem.generated';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { LS_GROUP_OPEN_NAME } from '../../../../utils/localStorageKeys';
import { OWNER_TYPE } from '../../Notifications';
import { ActiveSubjectSelector } from './ActiveSubjectSelector';
import { GroupList } from './GroupList';
import NavListItem from './NavListItem';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
  },
  navList: {
    width: '100%',
    maxWidth: 360,
    marginTop: theme.spacing(2),
  },
  listContainer: {
    overflow: 'auto',
    overflowX: 'hidden',
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  logo: {
    fill: theme.palette.secondary.main,
    width: 160,
    padding: 3,
  },
  activeSubjectContainer: {},
  closeDrawerButton: {
    position: 'absolute',
    right: '4px',
    backgroundColor: theme.palette.primary.light,
  },
}));

const defaultSettings = localStorage.getItem(LS_GROUP_OPEN_NAME);

export function NavList({
  handleDrawerToggle,
}: {
  handleDrawerToggle: () => void;
  drawerOpen: boolean;
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(
    defaultSettings !== null ? JSON.parse(defaultSettings) : true
  );
  const { data: teacherData } = useQuery(TeacherDocument, {});
  const courseId = teacherData?.teacher.activeCourse?.id || '';
  const { data: actionItemsCount } = useQuery(NotificationsCountDocument, {
    skip: !courseId,
    pollInterval: 60000,
    variables: {
      ownerId: courseId,
      ownerType: OWNER_TYPE,
    },
  });
  const notificationsCount = actionItemsCount?.notificationsCount || 0;

  const handleClick = () => {
    const updated = !open;
    localStorage.setItem(LS_GROUP_OPEN_NAME, `${updated}`);
    setOpen(updated);
  };

  return (
    <div className={classes.root}>
      <div>
        <Toolbar>
          <Link to={'/'} onClick={handleDrawerToggle}>
            <Logo className={classes.logo} />
          </Link>
        </Toolbar>
        <div className={classes.listContainer}>
          <List
            component="nav"
            aria-labelledby="navigation"
            className={classes.navList}
          >
            <NavListItem
              handleDrawerToggle={handleDrawerToggle}
              Icon={Home}
              text="Home"
              route="/"
            />
            <ListItem button onClick={handleClick}>
              <ListItemIcon className={classes.icon}>
                <AccountCircleOutlined />
              </ListItemIcon>
              <ListItemText primary="Classes" />
              {open ? (
                <ExpandLess className={classes.icon} />
              ) : (
                <ExpandMore className={classes.icon} />
              )}
            </ListItem>
            <Collapse in={open} timeout="auto">
              <GroupList handleDrawerToggle={handleDrawerToggle} />
            </Collapse>
            <NavListItem
              handleDrawerToggle={handleDrawerToggle}
              Icon={AssignmentOutlined}
              text="Assignments"
              route="/assignments"
            />
            <NavListItem
              handleDrawerToggle={handleDrawerToggle}
              Icon={Repeat}
              text="Personal Deck"
              route="/personal-deck"
            />
            <NavListItem
              handleDrawerToggle={handleDrawerToggle}
              Icon={TrendingUpOutlined}
              text="Student Progress"
              route="/progress/questions"
            />
            <NavListItem
              handleDrawerToggle={handleDrawerToggle}
              Icon={() => (
                <Badge
                  color="error"
                  variant="dot"
                  invisible={!notificationsCount}
                >
                  <Notifications />
                </Badge>
              )}
              text="Notifications"
              route="/notifications"
            />
            <NavListItem
              handleDrawerToggle={handleDrawerToggle}
              Icon={Group}
              text="Collaborative Subject"
              route="/subject"
            />
          </List>
        </div>
      </div>
      <section className={classes.activeSubjectContainer}>
        <ActiveSubjectSelector />
      </section>
    </div>
  );
}
