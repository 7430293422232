export const LS_PDQ_DATA_TYPE_KEY: string = '__PODSIE_PDQ_DATA_TYPE_KEY__';

export const PODSIE_TEACHER_VERSION_LS_KEY: string =
  'PODSIE_TEACHER_VERSION_LS_KEY';

export const LS_GROUP_OPEN_NAME: string = '__PODSIE_GROUPS_LIST_OPEN__';

export const LS_PDQ_DATA_OVER_TIME_TYPE_KEY: string =
  '__PODSIE_PDQ_DATA_OVER_TIME_TYPE_KEY__';

export const LS_REVIEW_SESSION_SELECTED_QUESTION_IDS: string =
  '__PODSIE_REVIEW_SESSION_SELECTED_QUESTION_IDS__';

export const LS_REVIEW_SESSION_SHOW_SINGLE: string =
  '__PODSIE_REVIEW_SESSION_SHOW_SINGLE__';

export const LS_QUESTION_OWNERSHIP_KEY: string =
  '__PODSIE_QUESTION_OWNERSHIP_KEY__';

export const localStorageAssignmentTabKey: string =
  '__PODSIE_ASSIGNMENT_TAB_KEY';

export const LS_DRAWER_OPEN_KEY: string = '__PODSIE_MOBILE_OPEN_KEY__';

export const LS_PODSIE_GOOGLE_SSO_WARNING_KEY: string =
  '__PODSIE_GOOGLE_SSO_WARNING_KEY__V2__';

export const LS_PODSIE_AI_CREATE_FORM_LEVEL: string =
  '__PODSIE_AI_CREATE_FORM_LEVEL__';

export const LS_PODSIE_AI_CREATE_FORM_INSTRUCTIONS =
  '__PODSIE_AI_CREATE_FORM_INSTRUCTIONS__';
