import {
  DragHandle,
  Edit,
  ExpandCircleDownOutlined,
} from '@mui/icons-material';
import {
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Tooltip,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { QuestionAttributesFragment } from '../../../../../../gql/fragments/__generated__/question.generated';
import { QuillDeltaAsHtml } from '../../../../../shared/QuillDeltaAsHtml';
import { AnswersBreakdown } from '../../../../Groups/Assignments/AnswersBreakdown';
import CompletionStats from '../../../../Groups/Assignments/CompletionStats';
import ActiveToggle from '../../../../Questions/Settings/ActiveToggle';
import { AssignmentEditorModes } from '../NormalAssignment';
import { BreakdownDetails } from './BreakdownDetails';
import CopyButton from './CopyButton';
import { DeleteForeverAssignmentButton } from './DeleteForeverAssignmentButton';
import QuestionStandards from './QuestionStandards';
import { QuestionsBreakdownSubscription } from './QuestionsBreakdownSubscription';
import RemoveFromAssignmentButton from './RemoveFromAssignmentButton';

type StyleProps = {
  draftMode: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  divider: {
    margin: theme.spacing(3, 0, 2, 0),
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  questionContainer: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    maxWidth: 1000,
  },
  question: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  questionNum: {
    paddingTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightBold,
  },
  editButton: {
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
  actionButton: {
    color: theme.palette.primary.main,
    margin: theme.spacing(0, 0.5),
    '&:first-child': {
      marginLeft: 0,
    },
  },
  activeToggle: {
    margin: theme.spacing(0, 2, 0, 1),
  },
  variationsButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    fontWeight: theme.typography.fontWeightBold,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: 'white',
    },
    fontSize: 14,
    width: 24,
    height: 24,
  },
  questionActionsContainer: {
    display: 'flex',
    flexDirection: ({ draftMode }: StyleProps) =>
      draftMode ? 'row' : 'column',
    alignItems: ({ draftMode }) => (draftMode ? 'center' : 'flex-start'),
    justifyContent: 'space-between',
    width: '100%',
  },
  loaderContainer: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'flex-end',
  },
  linearProgress: {
    height: theme.spacing(1),
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  draggable: {
    display: 'flex',
    justifyContent: 'center',
  },
  questionOverview: {},
  questionActions: {},
  actionButtonsFirstSection: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButtonsSecondSection: {
    display: 'flex',
    alignItems: 'center',
  },
  expand: {
    transform: 'rotate(0deg)',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

type QuestionCardProps = {
  index: number;
  question: QuestionAttributesFragment;
  draftMode: boolean;
  displayBreakdown: boolean;
  handleOpenEditQuestion: (questionId: string) => void;
  handleOpenVariations: (questionId: string) => void;
  selectedGroupId: string;
  setMode: React.Dispatch<
    React.SetStateAction<AssignmentEditorModes | undefined>
  >;
  breakdownLoading: boolean;
};

export function QuestionCard({
  index,
  question,
  draftMode,
  displayBreakdown,
  handleOpenEditQuestion,
  handleOpenVariations,
  selectedGroupId,
  setMode,
  breakdownLoading,
}: QuestionCardProps) {
  const classes = useStyles({ draftMode });
  const questionNumber = index + 1;
  const { id, richText, variations } = question;
  const numVariations = variations?.length || 0;

  const [isStudentBreakdownOpen, setIsStudentBreakdownOpen] = useState(false);

  return (
    <Draggable
      isDragDisabled={!draftMode}
      draggableId={`question-${id}`}
      key={`question-${id}`}
      index={index}
    >
      {(draggableProvided) => (
        <div
          {...draggableProvided.draggableProps}
          ref={draggableProvided.innerRef}
        >
          <Card className={classes.questionContainer} key={`qkey-${id}`}>
            {draftMode && (
              <div
                className={classes.draggable}
                {...draggableProvided.dragHandleProps}
              >
                <DragHandle color="primary" />
              </div>
            )}
            <div className={classes.questionOverview}>
              <QuestionStandards standards={question.standards} />
              <div className={classes.question}>
                <span className={classes.questionNum}>{questionNumber}.</span>
                <QuillDeltaAsHtml delta={richText.ops} />
              </div>
              <QuestionsBreakdownSubscription
                selectedGroupId={selectedGroupId}
              />
              <AnswersBreakdown question={question} />
            </div>
            <Divider className={classes.divider} />
            <div className={classes.questionActions}>
              <div className={classes.headerContainer}>
                <div className={classes.questionActionsContainer}>
                  <div className={classes.actionButtonsFirstSection}>
                    <Tooltip
                      title="Edit Question"
                      className={classes.actionButton}
                    >
                      <IconButton
                        className={classes.editButton}
                        color="secondary"
                        onClick={() => handleOpenEditQuestion(id)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Number of Question Variations"
                      className={classes.actionButton}
                    >
                      <IconButton
                        onClick={() => handleOpenVariations(id)}
                        size="small"
                        className={classes.variationsButton}
                      >
                        {numVariations}
                      </IconButton>
                    </Tooltip>
                    {draftMode && (
                      <Tooltip
                        title="Removes question from assignment. Note: To fully delete the question, click the 'Trash' icon to the right."
                        className={classes.actionButton}
                      >
                        <span>
                          <RemoveFromAssignmentButton questionId={id} />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className={classes.actionButtonsSecondSection}>
                    {draftMode && (
                      <>
                        <Tooltip
                          title="Use this question as a template to create a new question."
                          className={classes.actionButton}
                        >
                          <span>
                            <CopyButton questionId={id} setMode={setMode} />
                          </span>
                        </Tooltip>
                      </>
                    )}
                    <Tooltip
                      title="Permanently deletes question. Note: To remove question from assignment without permanently deleting, click the 'Remove' icon to the left."
                      className={classes.actionButton}
                    >
                      <span>
                        <DeleteForeverAssignmentButton questionId={id} />
                      </span>
                    </Tooltip>
                    <div className={classes.activeToggle}>
                      <ActiveToggle questionId={id} />
                    </div>
                  </div>
                </div>
                {breakdownLoading && (
                  <div className={classes.loaderContainer}>
                    <LinearProgress className={classes.linearProgress} />
                    <div>loading...</div>
                  </div>
                )}
                {displayBreakdown && (
                  <div>
                    <CompletionStats question={question} />
                    <Tooltip
                      title={
                        isStudentBreakdownOpen
                          ? 'Hide Student Responses'
                          : 'Show Student Responses'
                      }
                      className={classes.actionButton}
                    >
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: isStudentBreakdownOpen,
                        })}
                        onClick={() =>
                          setIsStudentBreakdownOpen(
                            (isStudentBreakdownOpen) => !isStudentBreakdownOpen
                          )
                        }
                      >
                        <ExpandCircleDownOutlined />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
            <BreakdownDetails
              question={question}
              open={isStudentBreakdownOpen}
            />
          </Card>
        </div>
      )}
    </Draggable>
  );
}
