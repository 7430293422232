import { Quill, Value } from 'react-quill';
import { StandardsQuery } from '../../../../../gql/queries/__generated__/standard.generated';
import {
  QuestionTypeEnum,
  ShortAnswerAnswerInput,
} from '../../../../../gql/types';

const Delta = Quill.import('delta');

export type FreeResponseAnswer = {
  text: Value;
  comparable: boolean;
  requiredKeywords: string[];
};

export type ShortAnswerAnswer = {
  settingsOpen: boolean;
  data: ShortAnswerAnswerInput;
};

export type ShortAnswerAnswers = ShortAnswerAnswer[];

export type MultipleChoiceAnswerChoices = {
  id?: string;
  text: Value;
  isCorrect: boolean;
}[];

export type QuestionFormState = {
  id?: string;
  question: Value;
  numAttemptsUntilAnswerShown: number | string;
  supplement: Value;
  questionType: QuestionTypeEnum;
  standards: StandardsQuery['standards'][0][];
  freeResponseAnswer: FreeResponseAnswer;
  shortAnswerAnswers: ShortAnswerAnswers;
  hiddenShortAnswers: ShortAnswerAnswers;
  multipleChoiceAnswerChoices: MultipleChoiceAnswerChoices;
};

const initialState: QuestionFormState = {
  question: '',
  numAttemptsUntilAnswerShown: 1,
  supplement: '',
  questionType: QuestionTypeEnum.FreeResponse,
  standards: [],
  freeResponseAnswer: {
    text: '',
    comparable: true,
    requiredKeywords: [],
  },
  hiddenShortAnswers: [],
  shortAnswerAnswers: [
    {
      data: {
        text: '',
        richText: new Delta({ ops: [] }),
        isCaseSensitive: false,
        ignoreWhitespaces: true,
        hidden: false,
      },
      settingsOpen: false,
    },
  ],
  multipleChoiceAnswerChoices: [
    {
      text: '',
      isCorrect: false,
    },
    {
      text: '',
      isCorrect: false,
    },
    {
      text: '',
      isCorrect: false,
    },
    {
      text: '',
      isCorrect: false,
    },
  ],
};

export default initialState;
