import { useQuery } from '@apollo/client';
import {
  Card,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  type SelectChangeEvent,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import type { QuestionsStatsFilters } from './QuestionsStats';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grays.light,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  switchContainer: {
    width: 'fit-content',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginTop: theme.spacing(1),
  },
  classFilter: {
    minWidth: 240,
    marginBottom: theme.spacing(1),
  },
}));

type QuestionsStatsFilterProps = {
  filters: QuestionsStatsFilters;
  setFilters: Dispatch<SetStateAction<QuestionsStatsFilters>>;
};

export function QuestionsStatsFilter({
  filters,
  setFilters,
}: QuestionsStatsFilterProps) {
  const classes = useStyles();
  const [text, setText] = useState('');
  const teacherQuery = useQuery(TeacherDocument);
  const debounced = useDebouncedCallback(
    // function
    (value: string) => {
      setFilters({
        ...filters,
        plainText: value,
      });
    },
    // delay in ms
    500
  );

  // Filter the active groups
  const groups = teacherQuery.data?.teacher.activeCourse?.groups || [];

  const toggleOnlyShowQuestionsWithData = () => {
    setFilters({
      ...filters,
      onlyShowQuestionsWithData: !filters.onlyShowQuestionsWithData,
    });
  };

  const toggleOnlyShowActiveQuestions = () => {
    setFilters({
      ...filters,
      onlyShowActiveQuestions: !filters.onlyShowActiveQuestions,
    });
  };

  const onChangeGroup = (event: SelectChangeEvent<string>) => {
    setFilters({
      ...filters,
      groupId:
        event.target.value === 'all'
          ? undefined
          : (event.target.value as string),
    });
  };
  return (
    <Card className={classes.root}>
      <Typography variant="h3" color="primary" className={classes.title}>
        Filters
      </Typography>
      <div className={classes.formContainer}>
        <FormControl variant="outlined" className={classes.classFilter}>
          <Select
            onChange={onChangeGroup}
            autoWidth={true}
            value={filters.groupId === undefined ? 'all' : filters.groupId}
          >
            <MenuItem key="all-classes-select" value="all">
              All Classes
            </MenuItem>
            {groups.map((elem) => {
              return (
                <MenuItem key={elem.id} value={elem.id.toString()}>
                  {elem.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControlLabel
          className={classes.switchContainer}
          control={
            <Switch
              checked={filters.onlyShowQuestionsWithData}
              onChange={toggleOnlyShowQuestionsWithData}
              name="showQuestionsWithData"
            />
          }
          label="Only show questions with data"
        />
        <FormControlLabel
          className={classes.switchContainer}
          control={
            <Switch
              checked={filters.onlyShowActiveQuestions}
              onChange={toggleOnlyShowActiveQuestions}
              name="showActiveQuestions"
            />
          }
          label="Only questions that are active for personal deck"
        />
        <TextField
          className={classes.textField}
          variant="outlined"
          label="Filter by Question Text"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            debounced(e.target.value);
          }}
        />
      </div>
    </Card>
  );
}
