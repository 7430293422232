import { Button, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { AssignmentResultsModes } from './NormalAssignment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    width: '158px',
  },
}));

type ResultsToggleButtonProps = {
  resultsMode: AssignmentResultsModes;
  setResultsMode: React.Dispatch<React.SetStateAction<AssignmentResultsModes>>;
};

export function ResultsToggleButton({
  resultsMode,
  setResultsMode,
}: ResultsToggleButtonProps) {
  const classes = useStyles();
  let text = 'SHOW GRADES';
  if (resultsMode === AssignmentResultsModes.GRADES) {
    text = 'SHOW QUESTIONS';
  }
  const handleClick = () => {
    if (resultsMode === AssignmentResultsModes.GRADES) {
      setResultsMode(AssignmentResultsModes.QUESTIONS);
    } else {
      setResultsMode(AssignmentResultsModes.GRADES);
    }
  };
  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.root}
      onClick={handleClick}
    >
      {text}
    </Button>
  );
}

export default ResultsToggleButton;
