import { useMutation } from '@apollo/client';
import { TextField, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { UpdateAssignmentDocument } from '../../../../../../gql/mutations/__generated__/assignment.generated';
import { onError } from '../../../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../../../Alerts/context';
import { StandardAssign } from '../../Setup/StandardAssign';
import { AssignmentEditorContext } from '../../context';
import {
  onAssignmentEditorSaveSuccess,
  startAssignmentEditorLoading,
  updateAssignmentName,
  updateAssignmentShareable,
} from '../../context/actions';
import { AssignmentSettings } from '../../shared/AssignmentSettings';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
  list: {
    padding: theme.spacing(2),
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '80%',
    },
  },
  listItem: {
    fontSize: theme.typography.h6.fontSize,
    marginBottom: theme.spacing(3),
  },
  setUpFormContainer: {},
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
}));

export function LaunchForm() {
  const {
    assignmentEditor: { name, id },
    dispatch: dispatchUpdate,
  } = useContext(AssignmentEditorContext);
  const { dispatch } = useContext(AlertsContext);
  const [updateAssignment] = useMutation(UpdateAssignmentDocument, {
    onCompleted: () => onAssignmentEditorSaveSuccess(dispatchUpdate),
    onError: onError(dispatch),
  });
  const debouncedUpdateAssignment = useDebouncedCallback(
    (updatedName: string) => {
      if (!updatedName) return;

      updateAssignment({
        variables: {
          assignmentId: id,
          name: updatedName,
        },
      });

      startAssignmentEditorLoading(dispatchUpdate);
    },
    750
  );

  const debouncedUpdateAssignmentShareable = useDebouncedCallback(
    (updatedShareable: boolean) => {
      updateAssignment({
        variables: {
          assignmentId: id,
          shareable: updatedShareable,
        },
      });
      startAssignmentEditorLoading(dispatchUpdate);
    },
    750
  );

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // local context update:
    dispatchUpdate(updateAssignmentName({ name: e.target.value }));
    // debounced API update:
    debouncedUpdateAssignment(e.target.value);
  };
  const classes = useStyles();

  const handleShareableChange = (event: unknown, checked: boolean) => {
    dispatchUpdate(updateAssignmentShareable({ shareable: checked }));
    debouncedUpdateAssignmentShareable(checked);
  };

  return (
    <div className={classes.root}>
      <form className={classes.setUpFormContainer}>
        <ol className={classes.list}>
          <li className={classes.listItem}>
            <TextField
              onChange={handleNameChange}
              fullWidth
              value={name}
              variant="outlined"
              label="Assignment Name"
            />
          </li>
          <li className={classes.listItem}>
            <StandardAssign />
          </li>
          <li className={classes.listItem}>
            <AssignmentSettings handleShareableChange={handleShareableChange} />
          </li>
        </ol>
      </form>
    </div>
  );
}
