import { Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { QuestionTypeEnum } from '../../../../../gql/types';
import { QuestionFormContext } from '../context';
import { FREE_RESPONSE_CHARACTER_LIMIT } from './FreeResponseAnswer';

const useStyles = makeStyles((theme: Theme) => ({
  required: {
    color: theme.palette.accent.main,
    marginLeft: theme.spacing(0.5),
  },
  label: {
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    flexFlow: 'column',
  },
  wrapper: {
    display: 'flex',
  },
}));

function TitleSubtitle({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  const classes = useStyles();
  return (
    <label className={classes.label}>
      <span className={classes.wrapper}>
        <Typography variant="h4" color="primary">
          {title}
        </Typography>
        <span className={classes.required}>*</span>
      </span>
      <Typography variant="caption">{subtitle}</Typography>
    </label>
  );
}

export function AnswerTitleSubtitle() {
  const { questionForm } = useContext(QuestionFormContext);
  let title = 'Enter Answer';
  let subtitle = '';
  switch (questionForm.questionType) {
    case QuestionTypeEnum.FreeResponse:
      subtitle = `Exemplar answer that your students will compare their answers to (${FREE_RESPONSE_CHARACTER_LIMIT} character limit).`;
      break;
    case QuestionTypeEnum.ShortAnswer:
      subtitle = 'Short Answers have a maximum of 40 characters.';
      break;
    case QuestionTypeEnum.SelectOneMultipleChoice:
      title = 'Enter Answer Options';
      subtitle =
        'Multiple choice questions require AT LEAST 2 answer choices. Answer choices are always shuffled for students.';
      break;
    case QuestionTypeEnum.SelectAllMultipleChoice:
      title = 'Enter Answer Options';
      subtitle =
        'By default, "None of the Above" is shown as an answer choice for students on "Select All That Apply" questions, so you DO NOT need to include that as an answer choice. If none of the options are marked as a "correct answer", then "None of the Above" is considered to be the correct answer.';
      break;
    default:
      break;
  }
  return <TitleSubtitle title={title} subtitle={subtitle} />;
}
