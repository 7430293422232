import { useQuery } from '@apollo/client';
import {
  Box,
  Grid,
  LinearProgress,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useEffect } from 'react';
import {
  AssignmentDocument,
  AssignmentLaunchProgressDocument,
} from '../../../../gql/queries/__generated__/assignment.generated';
import { AssignmentStatusEnum } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { useTrackVisit } from '../../../../utils/hooks/useTrackVisit';
import { CustomChip } from '../../../shared/CustomChip';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { AlertsContext } from '../../Alerts/context';
import { OptionsButton } from './OptionsButton';
import { PersonalDeckAssignmentDetails } from './PersonalDeckAssignmentDetails';
import { PrimaryActionsButton } from './PrimaryActionsButton';
import { SaveStatus } from './SaveStatus';
import { AssignmentEditorContext } from './context';

const LABEL_MAP = {
  [AssignmentStatusEnum.Active]: 'Active',
  [AssignmentStatusEnum.Pending]: 'Draft',
  [AssignmentStatusEnum.InProgress]: 'In Progress',
  [AssignmentStatusEnum.PastDue]: 'Previous',
  [AssignmentStatusEnum.Archived]: 'Archived',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  launchProgressContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(3)} ${theme.spacing(5)}`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  header: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  name: {
    margin: 0,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(3),
    flexWrap: 'nowrap',
  },
  saveStatus: {},
  metaContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  divider: {
    margin: `0 ${theme.spacing(2)}`,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightLight,
  },
  timestampLabel: {
    marginRight: theme.spacing(1),
  },
  assignmentDetailsContainer: {
    marginTop: theme.spacing(6),
    flex: '1 1 auto',
  },
  actionButtonsContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  actionButtonsInnerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  shareContainer: {
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  statusContainer: {},
}));

export function PersonalDeckAssignmentEditor() {
  useTrackVisit({
    section: 'assignment',
    page: 'personal-deck-assignment-editor',
  });
  const classes = useStyles();
  const {
    assignmentEditor: { id: assignmentId, createdAt, name, launchAt },
  } = useContext(AssignmentEditorContext);
  const { dispatch } = useContext(AlertsContext);
  const { data } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
    fetchPolicy: 'cache-and-network',
  });
  const {
    data: progressData,
    startPolling,
    stopPolling,
  } = useQuery(AssignmentLaunchProgressDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });

  const numStudentsAssignmentData =
    progressData?.assignment.numStudentsAssignmentsData;
  const totalAssigned = progressData?.assignment.numStudentsAssigned || 0;
  let launchProgress =
    totalAssigned && numStudentsAssignmentData
      ? Math.round((numStudentsAssignmentData / totalAssigned) * 100)
      : 0;
  if (launchProgress > 100) {
    launchProgress = 100;
  }
  useEffect(() => {
    if (!numStudentsAssignmentData || !totalAssigned) {
      return;
    }

    if (launchProgress > 0 && numStudentsAssignmentData < totalAssigned) {
      startPolling(1000);
    }

    if (numStudentsAssignmentData >= totalAssigned) {
      stopPolling();
    }
  }, [
    launchProgress,
    startPolling,
    stopPolling,
    numStudentsAssignmentData,
    totalAssigned,
  ]);
  if (!data) {
    return <LoadingSkeletons num={6} />;
  }

  const startLaunch = () => {
    startPolling(1000);
  };

  const resetLaunchProgressStates = () => {
    stopPolling();
  };

  const { assignmentStatus } = data.assignment;
  const showLaunchProgress =
    AssignmentStatusEnum.Active === assignmentStatus ||
    AssignmentStatusEnum.InProgress === assignmentStatus;
  const launchInFuture = launchAt !== null && launchAt > new Date();
  const launchInProgress =
    !launchInFuture &&
    totalAssigned > 0 &&
    [AssignmentStatusEnum.Active, AssignmentStatusEnum.InProgress].includes(
      assignmentStatus
    ) &&
    launchProgress < 100;

  const displayName = name.length > 50 ? name.substring(0, 50) + '...' : name;
  return (
    <div className={classes.root}>
      <Grid container className={classes.section}>
        <Grid item>
          <div className={classes.header}>
            <Typography variant="h1" className={classes.name}>
              {displayName}
            </Typography>
            <div>
              <SaveStatus />
            </div>
          </div>
          <div className={classes.metaContainer}>
            <div>
              <CustomChip size="small" label={LABEL_MAP[assignmentStatus]} />
            </div>
            <span className={classes.divider}>|</span>
            <div>
              <span className={classes.timestampLabel}>Created On:</span>
              <span></span>
              {createdAt}
            </div>
          </div>
        </Grid>
        <Grid item className={classes.actionButtonsContainer}>
          <div className={classes.actionButtonsInnerContainer}>
            <div className={classes.statusContainer}>
              <OptionsButton
                resetLaunchProgressStates={resetLaunchProgressStates}
                launchInProgress={launchInProgress}
              />
            </div>
            <div className={classes.shareContainer}>
              <PrimaryActionsButton
                startLaunch={startLaunch}
                resetLaunchProgressStates={resetLaunchProgressStates}
                launchProgress={launchProgress}
                totalAssigned={totalAssigned}
              />
            </div>
          </div>
          {showLaunchProgress && launchProgress ? (
            <Tooltip title="Launch Progress">
              <span>
                <Box className={classes.launchProgressContainer}>
                  <Box width="100%" mr={1}>
                    <LinearProgress
                      variant="determinate"
                      value={launchProgress}
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                    >{`${launchProgress}%`}</Typography>
                  </Box>
                </Box>
                <Typography style={{ textAlign: 'center' }}>
                  {launchProgress >= 100
                    ? 'Assignment fully launched.'
                    : 'In progress...'}
                </Typography>
              </span>
            </Tooltip>
          ) : null}
        </Grid>
      </Grid>

      <div className={classes.assignmentDetailsContainer}>
        <PersonalDeckAssignmentDetails assignmentStatus={assignmentStatus} />
      </div>
    </div>
  );
}

export default PersonalDeckAssignmentEditor;
