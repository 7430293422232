import { Theme, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { format } from 'date-fns';
import { AssignmentIndexAttributesFragment } from '../../../../gql/fragments/__generated__/assignment.generated';
import {
  AssignmentStatusEnum,
  AssignmentTypeEnum,
} from '../../../../gql/types';
import { dateFormat } from '../../../../utils/dates';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'fit-content',
    borderRadius: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    height: '20px',
    marginLeft: theme.spacing(1),
  },
  scheduled: {
    color: theme.palette.yellow.dark,
    backgroundColor: theme.palette.yellow.main,
  },
  launched: {
    color: theme.palette.green.dark,
    backgroundColor: theme.palette.green.main,
  },
}));

type AsssignmentLaunchStateProps = {
  assignment: AssignmentIndexAttributesFragment;
};

export function AssignmentLaunchState({
  assignment,
}: AsssignmentLaunchStateProps) {
  const classes = useStyles();
  if (assignment.assignmentStatus === AssignmentStatusEnum.Pending) {
    return null;
  }
  const launchAtDate = new Date(assignment.launchAt);
  const launchPending =
    assignment.assignmentStatus === AssignmentStatusEnum.Active &&
    assignment.launchAt &&
    launchAtDate > new Date();

  const launchPendingChip = () => {
    return (
      <Tooltip title={format(launchAtDate, dateFormat)}>
        <div
          className={clsx(classes.root, {
            [classes.scheduled]: launchPending,
          })}
        >
          Launch Scheduled
        </div>
      </Tooltip>
    );
  };

  if (assignment.assignmentType === AssignmentTypeEnum.Standard) {
    if (
      assignment.enrollmentsAssignments?.length &&
      launchPending &&
      assignment.launchAt
    ) {
      return launchPendingChip();
    }
    const totalClasses = assignment.groupsAssignments?.length ?? 0;
    const launchedClasses =
      assignment.groupsAssignments?.filter(
        (ga) => new Date(ga.launchDate) < new Date()
      ) ?? [];
    const numLaunched = launchedClasses.length ?? 0;
    if (totalClasses === numLaunched) {
      return (
        <div
          className={clsx(classes.root, {
            [classes.launched]: true,
          })}
        >
          Launched
        </div>
      );
    } else {
      const launchedClassesNames = launchedClasses
        .map((lc) => lc.group.name)
        .join(', ');
      const tooltipText = launchedClassesNames
        ? `Launched to: ${launchedClassesNames}`
        : 'Not Launched';
      return (
        <Tooltip title={tooltipText} placement="top">
          <div
            className={clsx(classes.root, {
              [classes.scheduled]: true,
            })}
          >
            {numLaunched} of {totalClasses} classes
          </div>
        </Tooltip>
      );
    }
  }

  if (launchPending) {
    return launchPendingChip();
  }
  return (
    <div
      className={clsx(classes.root, {
        [classes.launched]: true,
      })}
    >
      Launched
    </div>
  );
}

export default AssignmentLaunchState;
