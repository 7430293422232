import { useMutation, useQuery } from '@apollo/client';
import { Close } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { areEqualStrings } from '@podsie/utils/string.js';
import { useContext, useEffect, useState } from 'react';
import { UpdateTeacherDocument } from '../../../gql/mutations/__generated__/teacher.generated';
import { TeacherLightDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { config } from '../../../podsie-config.js';
import { onError } from '../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../Alerts/context';
import { openDialog } from '../Alerts/context/actions';
import SchoolSetup from '../Setup/SchoolSetup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  nameContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  firstName: {
    marginRight: theme.spacing(4),
    flexBasis: '20%',
    minWidth: 180,
    maxWidth: 360,
  },
  lastName: {
    marginRight: theme.spacing(4),
    flexBasis: '20%',
    minWidth: 180,
    maxWidth: 360,
  },
  emailContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  email: {
    flexBasis: '20%',
    minWidth: 180,
    maxWidth: 360,
    marginRight: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  school: {
    flexBasis: '40%',
    minWidth: 180,
    maxWidth: 540,
  },
  schoolContainer: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  schoolHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export function Profile() {
  const classes = useStyles();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [createSchool, setCreateSchool] = useState(false);
  const { dispatch } = useContext(AlertsContext);

  const { data: teacherData, refetch: refetchTeacherData } = useQuery(
    TeacherLightDocument,
    {
      onError: onError(dispatch),
    }
  );

  const [updateTeacherMutation] = useMutation(UpdateTeacherDocument, {
    onCompleted: () => refetchTeacherData(),
    onError: onError(dispatch),
  });

  useEffect(() => {
    if (teacherData) {
      setFirstName(teacherData.teacher.firstName ?? '');
      setLastName(teacherData.teacher.lastName ?? '');
    }
  }, [teacherData]);

  const updateTeacherInfo = () => {
    updateTeacherMutation({ variables: { firstName, lastName } });
  };

  const schoolName =
    teacherData?.teacher.school?.name || '(No school selected)';

  const resetPassword = () => {
    const url = 'https://podsie.auth0.com/dbconnections/change_password';
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        client_id: config.auth0.clientId,
        email: teacherData?.teacher.email,
        connection: 'Username-Password-Authentication',
      }),
    };
    fetch(url, options).then(() => {
      dispatch(
        openDialog({
          title: 'Password Reset Complete',
          message: 'Check your email for a password reset link.',
          error: false,
        })
      );
    });
  };

  return (
    <div className={classes.root}>
      <Card className={classes.section}>
        <CardContent>
          <Typography variant="h3">Personal Information</Typography>
          <div className={classes.nameContainer}>
            <TextField
              className={classes.firstName}
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName ?? ''}
              size="small"
              variant="outlined"
              label="First Name"
            />
            <TextField
              className={classes.lastName}
              value={lastName ?? ''}
              onChange={(e) => setLastName(e.target.value)}
              size="small"
              variant="outlined"
              label="Last Name"
            />
          </div>
          <div className={classes.emailContainer}>
            <TextField
              className={classes.email}
              value={teacherData?.teacher.email ?? ''}
              disabled
              size="small"
              variant="outlined"
              label="Email"
            />
            <Tooltip title="Email hello@podsie.org if you'd like to update your email">
              <HelpIcon />
            </Tooltip>
          </div>
          <Button
            disabled={
              (areEqualStrings(firstName, teacherData?.teacher.firstName) &&
                areEqualStrings(lastName, teacherData?.teacher.lastName)) ||
              firstName === '' ||
              lastName === ''
            }
            className={classes.button}
            onClick={() => updateTeacherInfo()}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={() => resetPassword()}
          >
            Reset Password
          </Button>
        </CardContent>
      </Card>
      <Card className={classes.section}>
        <CardContent>
          <Typography variant="h3">School Information</Typography>
          <div className={classes.schoolContainer}>
            <TextField
              className={classes.school}
              value={schoolName}
              disabled
              size="small"
              variant="outlined"
              label="School"
            />
          </div>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={() => setCreateSchool(true)}
          >
            Update School
          </Button>
        </CardContent>
      </Card>
      <Dialog
        fullWidth
        maxWidth="md"
        open={createSchool}
        onClose={() => setCreateSchool(false)}
      >
        <DialogContent>
          <div className={classes.schoolHeader}>
            <IconButton onClick={() => setCreateSchool(false)} size="large">
              <Close />
            </IconButton>
          </div>
          <SchoolSetup
            onCompletedCallback={() => {
              refetchTeacherData();
              setCreateSchool(false);
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
