import { useQuery, useSubscription } from '@apollo/client';
import {
  Button,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { buttonLink } from '../../../assets/shared-styles/Button-Link';
import type { ActionItemAttributesFragment } from '../../../gql/fragments/__generated__/actionItem.generated';
import { ActionItemsDocument } from '../../../gql/queries/__generated__/actionItem.generated';
import { TeacherDocument } from '../../../gql/queries/__generated__/teacher.generated';
import { ActionItemsCountDocument } from '../../../gql/subscriptions/__generated__/actionItem.generated';
import {
  ActionItemStateEnum,
  type ActionItem,
  type ActionItemEdge,
  type Maybe,
} from '../../../gql/types';
import { useTrackVisit } from '../../../utils/hooks/useTrackVisit';
import { LiveUpdates } from '../../shared/LiveUpdates';
import { DetailPanel } from './DetailPanel';
import { ItemsList } from './ItemsList';

const headerHeight = 104;
// 64 is height of app bar
export const totalAppBarHeights = headerHeight + 64;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  liveUpdatesContainer: {
    paddingRight: theme.spacing(1),
  },
  container: {
    height: '100%',
    paddingTop: headerHeight,
  },
  itemsList: {
    borderRight: '1px solid black',
    height: `calc(100vh - ${totalAppBarHeights}px)`,
    overflow: 'auto',
  },
  detailsPanel: {
    height: `calc(100vh - ${totalAppBarHeights}px)`,
  },
  header: {
    height: headerHeight,
    flex: '0 1 auto',
    position: 'fixed',
    width: '100%',
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  refetchButton: {
    marginRight: theme.spacing(1.5),
    ...buttonLink(theme),
  },
  loadingPlaceholder: {
    height: 4,
    backgroundColor: theme.palette.primary.main,
  },
  loadContainer: {
    paddingLeft: theme.spacing(2.5),
    paddingBottom: theme.spacing(2),
  },
  loadingText: {},
  buttonLink: {
    ...buttonLink(theme),
    marginLeft: theme.spacing(1.5),
    fontSize: 16,
  },
  loadButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export type TabValues = 'open' | 'archived';
export const OWNER_TYPE = 'Course';
export const PAGINATION_COUNT = 10;
type Edge = Maybe<
  { __typename?: 'ActionItemEdge' } & Pick<ActionItemEdge, 'cursor'> & {
      node?: Maybe<
        { __typename?: 'ActionItem' } & Pick<ActionItem, 'updatedAt'> &
          ActionItemAttributesFragment
      >;
    }
>;

export function Notifications() {
  useTrackVisit({
    section: 'notifications',
    page: 'notifications',
  });
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [numNewItems, setNumNewItems] = useState(0);
  const { data: teacherData } = useQuery(TeacherDocument, {});
  const [tabValue, setTabValue] = useState<TabValues>('open');
  const { data: actionItemsCount } = useSubscription(ActionItemsCountDocument, {
    skip: !teacherData?.teacher.activeCourse?.id,
    variables: {
      ownerId: teacherData?.teacher.activeCourse?.id || '',
      ownerType: OWNER_TYPE,
    },
  });
  const { data, refetch, loading, fetchMore } = useQuery(ActionItemsDocument, {
    skip: !teacherData?.teacher.activeCourse?.id,
    variables: {
      open: tabValue === 'open',
      ownerId: teacherData?.teacher.activeCourse?.id || '',
      ownerType: OWNER_TYPE,
      first: PAGINATION_COUNT,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (
        data.actionItems.edges?.length &&
        data.actionItems.edges[0] &&
        data.actionItems.edges[0].node?.state === ActionItemStateEnum.Open
      ) {
        setTabValue('open');
        setNumNewItems(0);
      }
    },
  });
  const fetchNewItems = () => {
    refetch({ open: true });
  };
  const fetchNextBatch = () => {
    fetchMore({
      variables: {
        first: PAGINATION_COUNT,
        after: data?.actionItems.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        const obj: { [key: string]: Edge } = {};
        const addEdgeToObj = (edge: Edge) => {
          if (edge?.node?.id) {
            obj[edge?.node?.id] = edge;
          }
        };
        previousResult.actionItems.edges?.forEach(addEdgeToObj);
        fetchMoreResult.actionItems.edges?.forEach(addEdgeToObj);
        // keep the list sorted by updatedAt timestamp:
        const edges = Object.keys(obj)
          .map((id) => obj[id])
          .sort(
            (a, b) =>
              new Date(b?.node?.updatedAt).getTime() -
              new Date(a?.node?.updatedAt).getTime()
          );

        fetchMoreResult.actionItems.edges = edges;

        return { ...fetchMoreResult };
      },
    });
  };
  const currentCount = actionItemsCount?.actionItemsCount?.count;
  const numItems = data?.actionItems?.edges?.length || 0;
  const totalItems = data?.actionItems.totalCount || 0;
  useEffect(() => {
    const actionItems = data?.actionItems;
    if (!actionItems || !currentCount) {
      return;
    }

    if (currentCount > totalItems) {
      setNumNewItems(currentCount - totalItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCount]);

  useEffect(() => {
    if (numItems === 0 && totalItems > 0) {
      fetchNextBatch();
    }
  });

  const handleTabChange = (_event: unknown, newValue: TabValues) => {
    setTabValue(newValue);
    setSelectedIndex(0);
  };
  const displayedTotalItems =
    tabValue === 'open' ? currentCount || '0' : totalItems || '0';
  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.tabsContainer}>
          <Tabs
            TabIndicatorProps={{
              style: {
                height: '4px',
              },
            }}
            value={tabValue}
            onChange={handleTabChange}
            aria-label="notifications tabs"
          >
            <Tab label="Current" value="open" />
            <Tab label="Archive" value="archived" />
          </Tabs>
          <div>
            {numNewItems > 0 ? (
              <Button
                className={classes.refetchButton}
                onClick={fetchNewItems}
              >{`${numNewItems} New Notification(s)`}</Button>
            ) : (
              <div className={classes.liveUpdatesContainer}>
                <LiveUpdates />
              </div>
            )}
          </div>
        </div>
        <div className={classes.appBar}>
          <Typography variant="h2">{`Items (${displayedTotalItems})`}</Typography>
        </div>
      </header>
      <Grid container className={classes.container}>
        <Grid xs={6} item className={classes.itemsList} id="scrollable-items">
          {loading && <LinearProgress />}
          <InfiniteScroll
            dataLength={numItems}
            scrollableTarget="scrollable-items"
            next={fetchNextBatch}
            scrollThreshold={0.95}
            hasMore={numItems < totalItems}
            loader={
              <div className={classes.loadContainer}>
                <h4 className={classes.loadingText}>Loading...</h4>
                <div className={classes.loadButtonContainer}>
                  <Typography>Data not loading?</Typography>
                  <button
                    className={classes.buttonLink}
                    onClick={fetchNextBatch}
                  >
                    Click here to manually load more
                  </button>
                </div>
              </div>
            }
          >
            <ItemsList
              tabValue={tabValue}
              items={data?.actionItems}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
          </InfiniteScroll>
        </Grid>
        <Grid xs={6} item className={classes.detailsPanel}>
          {data?.actionItems.edges &&
            selectedIndex < data.actionItems.edges.length && (
              <DetailPanel
                selectedActionItemId={
                  data.actionItems.edges[selectedIndex]?.node?.id || ''
                }
              />
            )}
        </Grid>
      </Grid>
    </div>
  );
}
