import { useMutation, useQuery } from '@apollo/client';
import { Button, LinearProgress, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce } from 'use-debounce';
import { BulkAddAssignmentsQuestionDocument } from '../../../../../gql/mutations/__generated__/assignmentsQuestion.generated';
import { AssignmentDocument } from '../../../../../gql/queries/__generated__/assignment.generated';
import { QuestionsConnectionDocument } from '../../../../../gql/queries/__generated__/question.generated';
import { OwnershipEnum } from '../../../../../gql/types';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { useTrackVisit } from '../../../../../utils/hooks/useTrackVisit';
import { AlertsContext } from '../../../Alerts/context';
import { pushSnack } from '../../../Alerts/context/actions';
import QuestionsSearch from '../../../Questions/Search';
import {
  QuestionsSearchContext,
  searchParamsToQueryParams,
} from '../../../Questions/Search/context/QuestionsSearchContext';
import { QuestionShowModal } from '../../../Questions/Show/QuestionShowModal';
import { AssignmentEditorContext } from '../context';
import { ExistingQuestionCard } from './ExistingQuestionCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  searchFormContainer: {
    padding: theme.spacing(2),
  },
  searchResultsContainer: {
    marginTop: theme.spacing(1),
    borderBottom: '1px solid black',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    overflowY: 'auto',
    height: '40vh',
    padding: `${theme.spacing(1)} ${theme.spacing(0.5)} ${theme.spacing(3)}`,
  },
  bottomContainer: {
    flex: '1 1 auto',
  },
  numResultsContainer: {
    paddingBottom: 10,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  addQuestionsContainer: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    paddingTop: theme.spacing(1.5),
    marginBottom: '5%',
  },
  addQuestionsButton: {
    margin: '10px',
    color: theme.palette.common.white,
  },
}));

const PAGINATION_COUNT = 20;

export function ExistingQuestions() {
  useTrackVisit({
    section: 'assignment',
    page: 'normal-assignment-questions-search',
  });
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const {
    assignmentEditor: { id: assignmentId, questions },
  } = useContext(AssignmentEditorContext);

  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [resultsLoading, setResultsLoading] = useState(false);

  const handleClose = () => setSelectedQuestionId('');

  const { questionsSearchParams } = useContext(QuestionsSearchContext);
  const { text, standardsChartId } = questionsSearchParams;
  const [debouncedText] = useDebounce(text, 500);
  const [refetching, setRefetching] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [allQuestionsChecked, setAllQuestionsChecked] =
    useState<boolean>(false);

  const {
    data: paginatedData,
    loading: paginatedLoading,
    fetchMore,
  } = useQuery(QuestionsConnectionDocument, {
    notifyOnNetworkStatusChange: true,
    onError: onError(dispatch),
    skip: !standardsChartId,
    variables: {
      first: PAGINATION_COUNT,
      assignmentId: assignmentId,
      standardsChartId,
      ...searchParamsToQueryParams({
        ...questionsSearchParams,
        text: debouncedText,
      }),
    },
  });
  useEffect(() => {}, [selectedQuestions]);

  const updateSelectedQuestions = (
    selectedQuestion: string,
    checked: boolean
  ) => {
    setSelectedQuestions(
      checked
        ? (currentQuestionList) => [...currentQuestionList, selectedQuestion]
        : (currentQuestionList) =>
            currentQuestionList.filter(
              (question) => question !== selectedQuestion
            )
    );
  };

  const handleAdd = () => {
    const questionIds: string[] = selectedQuestions;
    bulkAddAssignmentsQuestion({
      variables: {
        questionIds,
        assignmentId,
      },
    });
  };
  const [bulkAddAssignmentsQuestion, { loading }] = useMutation(
    BulkAddAssignmentsQuestionDocument,
    {
      onCompleted: (res) => {
        if (!res.bulkAddAssignmentsQuestion) return;
        setSelectedQuestions([]);
        dispatch(pushSnack({ message: 'Question(s) added to assignment!' }));
        fetchMore({});
      },
      onError: onError(dispatch),
      refetchQueries: (result) => (result.data ? [AssignmentDocument] : []),
    }
  );

  const addQuestionToAssignment = () => {
    if (!selectedQuestionId) {
      return;
    }
    const questionIds: string[] = [selectedQuestionId];
    bulkAddAssignmentsQuestion({
      variables: { questionIds, assignmentId },
    });
  };

  const fetchNextBatch = () => {
    setRefetching(true);
    fetchMore({
      variables: {
        first: PAGINATION_COUNT,
        after: paginatedData?.questionsConnection.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        fetchMoreResult.questionsConnection.edges = [
          ...(previousResult?.questionsConnection.edges || []),
          ...(fetchMoreResult.questionsConnection.edges || []),
        ];

        setRefetching(false);
        return fetchMoreResult;
      },
    });
  };

  const assignmentQuestions: { [key: string]: boolean } = useMemo(() => {
    const cache: { [key: string]: boolean } = {};
    questions.forEach((question) => {
      cache[question.id] = true;
      if (question.copiedFromId) {
        cache[question.copiedFromId] = true;
      }
    });
    return cache;
  }, [questions]);

  const paginatedResults = paginatedData?.questionsConnection.edges || [];
  const totalQuestions = paginatedData?.questionsConnection.totalCount || 0;

  const ownQuestion = questionsSearchParams.ownership === OwnershipEnum.Own;
  const selectedQuestionAdded = !!assignmentQuestions[selectedQuestionId];
  const toggleAllQuestionsCheckedOrUnchecked = useCallback(
    () => setAllQuestionsChecked((allChecked) => !allChecked),
    [setAllQuestionsChecked]
  );

  return (
    <div className={classes.root}>
      <div>
        <QuestionsSearch setResultsLoading={setResultsLoading} />
      </div>
      <div className={classes.numResultsContainer}>
        <Typography
          color="primary"
          variant="h4"
        >{`${totalQuestions} result(s)`}</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={toggleAllQuestionsCheckedOrUnchecked}
        >
          {allQuestionsChecked ? 'Deselect All' : 'Select All'}
        </Button>
      </div>

      <div className={classes.bottomContainer}>
        <InfiniteScroll
          scrollThreshold={0.95}
          dataLength={paginatedResults.length}
          next={fetchNextBatch}
          hasMore={paginatedResults.length < totalQuestions}
          scrollableTarget="scrollable-items"
          loader={refetching ? <Typography>Loading...</Typography> : null}
        >
          <div className={classes.searchResultsContainer} id="scrollable-items">
            {paginatedResults.map((edge) => {
              if (!edge || !edge.node) {
                return null;
              }
              const question = edge.node;
              if (assignmentQuestions[question.id]) {
                return null;
              }
              return (
                <ExistingQuestionCard
                  key={question.id}
                  question={question}
                  setSelectedQuestionId={setSelectedQuestionId}
                  onChangeQuestionList={updateSelectedQuestions}
                  allQuestionsChecked={allQuestionsChecked}
                />
              );
            })}
          </div>
        </InfiniteScroll>
        {resultsLoading || paginatedLoading ? <LinearProgress /> : null}

        <Button
          variant="contained"
          color="secondary"
          size="large"
          className={classes.addQuestionsButton}
          onClick={handleAdd}
          disabled={selectedQuestions.length === 0}
        >
          {'Add Question(s)'}
        </Button>

        <QuestionShowModal
          copyable
          assignmentId={assignmentId}
          selectedQuestionAdded={selectedQuestionAdded}
          loading={loading}
          open={!!selectedQuestionId}
          handleClose={handleClose}
          editable={ownQuestion}
          addQuestionToAssignment={addQuestionToAssignment}
          questionId={selectedQuestionId}
        />
      </div>
    </div>
  );
}
