import { type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Question } from '../utils';
import { AnswerRow } from './MultipleChoiceBreakdown';

type StyleProps = {
  reviewable: boolean | undefined;
  anonymized: boolean;
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  answerRow: {
    paddingTop: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: ({ reviewable }: StyleProps) =>
      reviewable ? 'calc(12px + 1.1vw)' : 'inherit',
    lineHeight: 'normal',
  },
  avatarContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mc: {
    fontSize: 'inherit',
  },
}));

function TrueFalseBreakdown({ question }: { question: Question }) {
  const classes = useStyles({ anonymized: true, reviewable: false });

  return (
    <div className={classes.root}>
      {['True', 'False'].map((choice, i) => {
        return (
          <AnswerRow
            id={i}
            choice={choice}
            isCorrect={choice === question.question_answer}
          />
        );
      })}
    </div>
  );
}

export default TrueFalseBreakdown;
