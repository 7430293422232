import {
  StyledEngineProvider,
  ThemeProvider,
  type Theme,
} from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'graphiql/graphiql.css';
import 'mathquill/build/mathquill.css';
import 'mathquill4quill/mathquill4quill.css';
import posthog from 'posthog-js';
import React from 'react';
import ReactDOM from 'react-dom';
import mathquill from 'react-mathquill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import App from './App';
import { Auth0Provider } from './Auth0Context';
import CustomApolloProvider from './CustomApolloProvider';
import { AlertsProvider } from './components/application/Alerts/context';
import { ErrorPage } from './components/application/ErrorPages/ErrorPage';
import './index.css';
import { config } from './podsie-config.js';
import theme from './theme';
import history from './utils/history';

posthog.init('phc_dXpfNDzxgYA3qugn7cjnIWhDjuxWBDCtjYjhlH5v6t9', {
  api_host: 'https://us.posthog.com',
});

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

(window as any).mathquill = mathquill;

if (config.sentry) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.2,
  });
}

const onRedirectCallback = () => {
  const redirect = sessionStorage.getItem('redirect') || '/';
  sessionStorage.removeItem('redirect');
  history.replace(redirect);
};

ReactDOM.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Sentry.ErrorBoundary
          fallback={({ resetError }) => <ErrorPage resetError={resetError} />}
        >
          <Auth0Provider
            authorizationParams={{
              audience: config.auth0.audience,
              redirect_uri: window.location.origin,
            }}
            clientId={config.auth0.clientId}
            domain={config.auth0.domain}
            onRedirectedBackFromAuth={onRedirectCallback}
          >
            <AlertsProvider>
              <CustomApolloProvider>
                <App />
              </CustomApolloProvider>
            </AlertsProvider>
          </Auth0Provider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
