import { useMutation, useQuery } from '@apollo/client';
import {
  Card,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext } from 'react';
import { AlertsContext } from '../../components/application/Alerts/context';
import HelpKitPersonalDeckStudySession from '../../components/application/HelpKitArticles/HelpKitPersonalDeckStudySession';
import { UpdateGroupDocument } from '../../gql/mutations/__generated__/group.generated';
import { TeacherSettingsDocument } from '../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../utils/apollo/apolloHelper';
import { SettingsBody } from './SettingsBody';
import StudySessionMaxSetting from './StudySessionMaxSetting';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginBottom: '20px',
  },
  option: {
    padding: '12px',
  },
  subtitle: {
    marginTop: '8px',
  },
  sessionsMaxContainer: {
    marginLeft: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  helpkitContainer: {
    marginLeft: theme.spacing(0.5),
  },
}));
export function PersonalDeckSpacing() {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data: teacherData } = useQuery(TeacherSettingsDocument, {
    onError: onError(dispatch),
  });

  const [updateGroup] = useMutation(UpdateGroupDocument, {
    onError: onError(dispatch),
  });

  const groups =
    [...(teacherData?.teacher.activeCourse?.groups || [])].sort(
      (first, second) => {
        return first.name.localeCompare(second.name, 'en', { numeric: true });
      }
    ) || [];

  const handleChangeRelaxed = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateGroup({
      variables: {
        groupId: event.target.name,
        enableRelaxedSpacing: event.target.checked,
      },
    });
  };

  const handleChangeImmediately = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateGroup({
      variables: {
        groupId: event.target.name,
        enableImmediatelyDue: event.target.checked,
      },
    });
  };

  return (
    <SettingsBody
      title="Personal Deck Settings"
      description="These settings control your students' Personal Deck experience, including max number of questions per Personal Deck Study Session and how quickly a Personal Deck question is considered 'due for review'."
    >
      <>
        <div>
          <Card className={classes.card}>
            <div className={classes.option}>
              <Typography variant="h5">
                Max Questions Per Study Session
              </Typography>
              <Typography className={classes.subtitle} variant="body2">
                This setting configures the max number of questions per Personal
                Deck Study Session for students in that class.
                <span className={classes.helpkitContainer}>
                  <HelpKitPersonalDeckStudySession />
                </span>
              </Typography>
              <FormGroup row className={classes.sessionsMaxContainer}>
                {groups.map((group) => {
                  return (
                    <StudySessionMaxSetting
                      group={group}
                      key={`study-session-settings-${group.id}`}
                    />
                  );
                })}
              </FormGroup>
            </div>
          </Card>
          <Card className={classes.card}>
            <div className={classes.option}>
              <Typography variant="h5">Less Aggressive Spacing</Typography>
              <Typography className={classes.subtitle} variant="body2">
                If enabled, the spacing algorithm will be relaxed by 1.5x. For
                example, if a question is supposed to be due 10 days from now,
                enabling this option will make it due 10 * 1.5 = 15 days from
                now.
              </Typography>
              <FormGroup row>
                {groups.map((group) => {
                  return (
                    <div key={`group-settings-${group.id}`}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={group.enableRelaxedSpacing}
                            onChange={handleChangeRelaxed}
                            name={group.id}
                          />
                        }
                        label={group.name}
                      />
                    </div>
                  );
                })}
              </FormGroup>
            </div>
          </Card>
          <Card className={classes.card}>
            <div className={classes.option}>
              <Typography variant="h5">Immediately Due Questions</Typography>
              <Typography className={classes.subtitle} variant="body2">
                If enabled, all newly introduced question will be due in the
                personal deck the next day. If disabled, only newly introduced
                questions that were incorrectly answered will be due in the
                personal deck the next day. Correctly answered questions will be
                due further out.
              </Typography>
              <FormGroup row>
                {groups.map((group) => {
                  return (
                    <div key={`settings-immediately-due-${group.id}`}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={group.enableImmediatelyDue}
                            onChange={handleChangeImmediately}
                            name={group.id}
                          />
                        }
                        label={group.name}
                      />
                    </div>
                  );
                })}
              </FormGroup>
            </div>
          </Card>
        </div>
      </>
    </SettingsBody>
  );
}

export default PersonalDeckSpacing;
