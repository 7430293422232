import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import {
  Route,
  Switch,
  useParams,
  type RouteComponentProps,
} from 'react-router-dom';
import { AssignmentDocument } from '../../../../../gql/queries/__generated__/assignment.generated';
import { AssignmentTypeEnum } from '../../../../../gql/types';
import { onError } from '../../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../../Alerts/context';
import SmartCreate from '../../../SmartCreate';
import PersonalDeckAssignmentEditor from '../PersonalDeckAssignmentEditor';
import { NormalAssignment } from './NormalAssignment';

function ReworkedAssignmentEditor() {
  const { assignmentId } = useParams<{ assignmentId: string }>();

  const { dispatch } = useContext(AlertsContext);
  const { data } = useQuery(AssignmentDocument, {
    variables: { assignmentId },
    onError: onError(dispatch),
  });

  switch (data?.assignment.assignmentType) {
    case AssignmentTypeEnum.Standard:
      return <NormalAssignment />;
    case AssignmentTypeEnum.Distributed:
      return <PersonalDeckAssignmentEditor />;
    default:
      return <div>Assignment Type No Longer Supported</div>;
  }
}

function ReworkedAssignmentEditorContainer({ match }: RouteComponentProps) {
  return (
    <Switch>
      <Route exact path={`${match.path}/ai-create`} component={SmartCreate} />
      <Route path={`${match.path}`} component={ReworkedAssignmentEditor} />
    </Switch>
  );
}

export default ReworkedAssignmentEditorContainer;
