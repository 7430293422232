import type { Icons } from '@material-table/core';
import {
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  ExpandMore,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  type SvgIconComponent,
} from '@mui/icons-material';
import { forwardRef, type ComponentProps } from 'react';
import { DetailPanelIcon } from './DetailPanelIcon';

export type IconProps = ComponentProps<SvgIconComponent>;

const ForwardedAddBox = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <AddBox {...props} ref={ref} />
));
ForwardedAddBox.displayName = 'AddBox';

const ForwardedCheck = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Check {...props} ref={ref} />
));
ForwardedCheck.displayName = 'Check';

const ForwardedClear = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Clear {...props} ref={ref} />
));
ForwardedClear.displayName = 'Clear';

const ForwardedDeleteOutline = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <DeleteOutline {...props} ref={ref} />
);
ForwardedDeleteOutline.displayName = 'DeleteOutline';

const ForwardedEdit = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Edit {...props} ref={ref} />
));
ForwardedEdit.displayName = 'Edit';

const ForwardedSaveAlt = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <SaveAlt {...props} ref={ref} />
));
ForwardedSaveAlt.displayName = 'SaveAlt';

const ForwardedFilterList = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <FilterList {...props} ref={ref} />
);
ForwardedFilterList.displayName = 'FilterList';

const ForwardedFirstPage = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <FirstPage {...props} ref={ref} />
);
ForwardedFirstPage.displayName = 'FirstPage';

const ForwardedLastPage = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <LastPage {...props} ref={ref} />
));
ForwardedLastPage.displayName = 'LastPage';

const ForwardedChevronRight = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <ChevronRight {...props} ref={ref} />
);
ForwardedChevronRight.displayName = 'ChevronRight';

const ForwardedChevronLeft = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <ChevronLeft {...props} ref={ref} />
);
ForwardedChevronLeft.displayName = 'ChevronLeft';

const ForwardedSearch = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Search {...props} ref={ref} />
));
ForwardedSearch.displayName = 'Search';

const ForwardedExpandMore = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <ExpandMore {...props} ref={ref} />
);
ForwardedExpandMore.displayName = 'ExpandMore';

const ForwardedRemove = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Remove {...props} ref={ref} />
));
ForwardedRemove.displayName = 'Remove';

const ForwardedViewColumn = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => <ViewColumn {...props} ref={ref} />
);
ForwardedViewColumn.displayName = 'ViewColumn';

export const icons = {
  Add: ForwardedAddBox,
  Check: ForwardedCheck,
  Clear: ForwardedClear,
  Delete: ForwardedDeleteOutline,
  DetailPanel: DetailPanelIcon,
  Edit: ForwardedEdit,
  Export: ForwardedSaveAlt,
  Filter: ForwardedFilterList,
  FirstPage: ForwardedFirstPage,
  LastPage: ForwardedLastPage,
  NextPage: ForwardedChevronRight,
  PreviousPage: ForwardedChevronLeft,
  ResetSearch: ForwardedClear,
  Search: ForwardedSearch,
  SortArrow: ForwardedExpandMore,
  ThirdStateCheck: ForwardedRemove,
  ViewColumn: ForwardedViewColumn,
} satisfies Icons<Record<string, unknown>>;
