import HelpKitButton from './HelpKitButton';

export function HelpKitAssignmentsGradesView() {
  return (
    <HelpKitButton
      label="Explanation of each column in this table"
      articleId="srgFhfrgdizC9WKpoJBsK3"
    />
  );
}
export default HelpKitAssignmentsGradesView;
