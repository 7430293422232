import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Grid, Stack, Typography, useTheme, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ActionItemQuery } from '../../../../gql/queries/__generated__/actionItem.generated';
import { ASSESSMENT_VALUE_TO_EMOJI } from '../../../../utils/assessmentValueMappings';
import Emoji from '../../../shared/Emoji';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  title: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1),
  },
  choice: {
    marginTop: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
  choiceContent: {
    width: '100%',
  },
  breakdown: {
    marginTop: theme.spacing(2),
  },
}));

export type FlaggedResponseAnswersProps = {
  response: ActionItemQuery['actionItem']['actionable']['response'];
};

export function FlaggedResponseAnswers({
  response,
}: FlaggedResponseAnswersProps) {
  const classes = useStyles();
  const theme = useTheme();

  switch (response.__typename) {
    case 'FreeResponseResponse':
      return (
        <div>
          <Typography variant="h5" className={classes.title}>
            Correct Answer:
          </Typography>
          <div>
            <QuillDeltaAsHtml
              delta={response.question.freeResponseAnswer?.richText.ops}
            />
          </div>
          <Typography variant="h5" className={classes.title}>
            Student Answer
            <span>
              {' '}
              (Student Rating -{' '}
              {response.selfAssessment !== null &&
              response.selfAssessment !== undefined ? (
                <Emoji
                  symbol={
                    ASSESSMENT_VALUE_TO_EMOJI(response.selfAssessment).emoji
                  }
                />
              ) : (
                'None'
              )}
              ):
            </span>
          </Typography>
          <div>
            <QuillDeltaAsHtml delta={response.richText.ops} />
          </div>
        </div>
      );
    case 'ShortAnswerResponse':
      return (
        <Grid container>
          <Grid item xs={6}>
            <Typography className={classes.title} variant="h5">
              Correct Answer(s)
            </Typography>
            <ul>
              {response.question.shortAnswerAnswers?.map((answer) => {
                return (
                  <li key={answer.id}>
                    <Stack direction="row" style={{ alignItems: 'center' }}>
                      <QuillDeltaAsHtml
                        delta={answer.richText.ops}
                        style={{ backgroundColor: 'transparent' }}
                      />
                      {answer.isCaseSensitive && (
                        <strong>(case-sensitive)</strong>
                      )}
                    </Stack>
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title} variant="h5">
              Student Answer
            </Typography>
            <Typography>{response.answer}</Typography>
          </Grid>
        </Grid>
      );

    case 'MultipleChoiceResponse': {
      const selectedObj: { [key: string]: boolean } = {};
      response.selectedMultipleChoiceAnswerChoices.forEach((selected) => {
        selectedObj[selected.multipleChoiceAnswerChoice.id] = true;
      });
      return (
        <div>
          {response.question.multipleChoiceAnswerChoices?.map((choice) => {
            const selected = selectedObj[choice.id];
            return (
              <div className={classes.choice} key={choice.id}>
                {choice.isCorrect ? (
                  <CheckBox className={classes.icon} />
                ) : (
                  <CheckBoxOutlineBlank className={classes.icon} />
                )}
                <div
                  className={classes.choiceContent}
                  style={
                    selected
                      ? { border: `3px solid ${theme.palette.primary.main}` }
                      : {}
                  }
                >
                  <QuillDeltaAsHtml delta={choice.richText.ops} />
                </div>
              </div>
            );
          })}
          <Typography
            className={classes.breakdown}
            style={{
              color: response.isCorrect
                ? theme.palette.secondary.main
                : theme.palette.error.main,
            }}
          >{`Student selected the ${
            response.isCorrect ? 'correct' : 'incorrect'
          } answer choice(s). Answer choice(s) that student selected are outlined.`}</Typography>
        </div>
      );
    }

    default:
      return null;
  }
}
