import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ComponentProps } from 'react';
import HelpKitPersonalDeckAssignment from '../../../HelpKitArticles/HelpKitPersonalDeckAssignment';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '80%',
    height: '100%',
  },
  header: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginBottom: '5px',
    marginTop: '15px',
  },
}));

type TabTitleProps = {
  title: string;
  description: string;
  personalDeckAssignment?: boolean;
};

export function TabTitle({
  title,
  description,
  personalDeckAssignment,
  ...props
}: TabTitleProps & ComponentProps<'div'>) {
  const classes = useStyles();

  return (
    <div
      {...props}
      className={`${classes.root} ${props.className ? props.className : ''}`}
    >
      <Typography className={classes.header} variant="h5">
        {title}
      </Typography>
      {personalDeckAssignment && <HelpKitPersonalDeckAssignment />}
      <Typography variant="body1">{description}</Typography>
    </div>
  );
}
