import HelpKitButton from './HelpKitButton';

export function HelpKitLeaderboard() {
  return (
    <HelpKitButton
      label="Understanding the student leaderboard"
      articleId="gERFffoLF8Tm6yBAskDB9b"
    />
  );
}
export default HelpKitLeaderboard;
