import { QuestionAttributesFragment } from '../../../../../gql/fragments/__generated__/question.generated';
import initialState, {
  QuestionFormState,
  ShortAnswerAnswers,
} from './initialState';

export const extractQuestionFormState = (
  question: QuestionAttributesFragment,
  forCreate?: boolean
): QuestionFormState => {
  const {
    id,
    richText,
    supplement,
    questionType,
    standards,
    freeResponseAnswer,
    shortAnswerAnswers,
    multipleChoiceAnswerChoices,
    numAttemptsUntilAnswerShown,
  } = question;
  const visibleShortAnswers: ShortAnswerAnswers = [];
  const hiddenShortAnswers: ShortAnswerAnswers = [];
  shortAnswerAnswers?.forEach((answer) => {
    const processed = {
      settingsOpen: false,
      data: {
        ...answer,
        id: forCreate ? undefined : answer.id,
      },
    };
    if (answer.hidden) {
      hiddenShortAnswers.push(processed);
    } else {
      visibleShortAnswers.push(processed);
    }
  });

  return {
    id: forCreate ? undefined : id,
    question: richText,
    supplement: supplement?.richText || '',
    questionType,
    standards: standards || [],
    numAttemptsUntilAnswerShown,
    freeResponseAnswer: freeResponseAnswer?.richText
      ? {
          text: freeResponseAnswer?.richText,
          comparable: freeResponseAnswer?.comparable,
          requiredKeywords: freeResponseAnswer.fraRequiredKeywords.map(
            (w) => w.keyword
          ),
        }
      : initialState.freeResponseAnswer,
    hiddenShortAnswers,
    shortAnswerAnswers: visibleShortAnswers || initialState.shortAnswerAnswers,
    multipleChoiceAnswerChoices:
      multipleChoiceAnswerChoices?.map((choice) => ({
        ...choice,
        text: choice.richText,
        id: forCreate ? undefined : choice.id,
      })) || initialState.multipleChoiceAnswerChoices,
  };
};
