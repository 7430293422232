import { QuestionTypeEnum } from '../../gql/types';

/**
 * A simple data structure used to present question types as user-facing options
 */
type QuestionTypeOption = {
  text: string;
  value: QuestionTypeEnum;
};

export const questionTypeOptions: ReadonlyArray<QuestionTypeOption> = [
  { value: QuestionTypeEnum.FreeResponse, text: 'Free Response' },
  { value: QuestionTypeEnum.ShortAnswer, text: 'Short Answer' },
  {
    value: QuestionTypeEnum.SelectAllMultipleChoice,
    text: 'Select All That Apply',
  },
  { value: QuestionTypeEnum.SelectOneMultipleChoice, text: 'Multiple Choice' },
];

export const questionTypeEnumToText = (
  questionType: QuestionTypeEnum
): string => {
  switch (questionType) {
    case QuestionTypeEnum.FreeResponse:
      return 'Free Response';
    case QuestionTypeEnum.ShortAnswer:
      return 'Short Answer';
    case QuestionTypeEnum.SelectAllMultipleChoice:
      return 'Select All That Apply';
    case QuestionTypeEnum.SelectOneMultipleChoice:
      return 'Multiple Choice';
    default:
      return '';
  }
};
