import { useQuery } from '@apollo/client';
import { Alert, Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { format } from 'date-fns';
import { useMemo } from 'react';
import {
  bubbleGreen,
  bubbleRed,
  bubbleYellow,
} from '../../../../assets/shared-styles/Bubble';
import { LatestMasteriesByQuestionDocument } from '../../../../gql/queries/__generated__/masteries.generated';
import { QuestionDocument } from '../../../../gql/queries/__generated__/question.generated';
import { dateFormatWithoutTime } from '../../../../utils/dates';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { CustomTable } from '../../../shared/Table';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '80vh',
  },
  title: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  firstPanel: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main} 24%, #0c6d7e 123%)`,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      maxWidth: 'auto',
      flexFlow: 'column',
    },
  },
  questionContainer: {
    maxWidth: '95%',
    maxHeight: '40vh',
    overflow: 'auto',
  },
  tableContainer: {
    padding: theme.spacing(2),
  },
  bubbleGreen: {
    ...bubbleGreen(),
  },
  bubbleYellow: {
    ...bubbleYellow(),
  },
  bubbleRed: {
    ...bubbleRed(),
  },
  empty: {},
  alert: {
    margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
}));

type QuestionDetailsProps = {
  questionId: string;
  groupId?: string;
};

export function QuestionProgress({
  questionId,
  groupId,
}: QuestionDetailsProps) {
  const classes = useStyles();
  const { data, loading } = useQuery(QuestionDocument, {
    variables: { questionId },
  });

  const { data: masteryData, loading: masteryLoading } = useQuery(
    LatestMasteriesByQuestionDocument,
    { variables: { groupId, questionId } }
  );

  const tableData = useMemo(() => {
    if (!masteryData) {
      return [];
    }
    return masteryData.latestMasteriesByQuestion.map((mastery) => {
      return {
        student: mastery.enrollment.student.sortName,
        streak: mastery.streak,
        totalCorrect: mastery.totalCorrect,
        totalAttempts: mastery.totalAttempts,
        latestAttempt: mastery.latestAttempt
          ? new Date(mastery.latestAttempt)
          : null,
      };
    });
  }, [masteryData]);

  if (loading || !data) {
    return <LoadingSkeletons num={10} />;
  }

  return (
    <div className={classes.root}>
      <section className={classes.firstPanel}>
        <Typography className={classes.title} variant="h4">
          Base Question
        </Typography>
        <div className={classes.questionContainer}>
          <QuillDeltaAsHtml delta={data?.question.richText.ops} />
        </div>
      </section>
      <section className={classes.tableContainer}>
        <div className={classes.alert}>
          <Alert severity="info">
            <strong>Note: </strong>The data shown below only counts first
            attempts at a question. In addition, it combines all data points for
            the base question and its variations.
          </Alert>
        </div>
        <CustomTable
          components={{
            Toolbar: () => null,
          }}
          isLoading={masteryLoading}
          title="Student Progress"
          data={tableData}
          options={{
            thirdSortClick: false,
            minBodyHeight: '68vh',
            maxBodyHeight: '68vh',
          }}
          columns={[
            {
              title: 'STUDENT',
              field: 'student',
            },
            {
              title: 'STREAK',
              field: 'streak',
              defaultSort: 'asc',
              render: ({ streak }) => {
                let style;
                if (streak > 1.0) {
                  style = classes.bubbleGreen;
                } else if (streak > 0) {
                  style = classes.bubbleYellow;
                } else if (!isNaN(streak)) {
                  style = classes.bubbleRed;
                } else {
                  style = classes.empty;
                }
                return (
                  <div>
                    <span className={style}>{streak}</span>
                  </div>
                );
              },
            },
            {
              title: 'LATEST ATTEMPT',
              field: 'latestAttempt',
              render: ({ latestAttempt }) => {
                if (!latestAttempt) {
                  return '';
                }

                return format(latestAttempt, dateFormatWithoutTime);
              },
            },
            {
              title: 'TOTAL CORRECT',
              field: 'totalCorrect',
            },
            {
              title: 'TOTAL ATTEMPTS',
              field: 'totalAttempts',
            },
          ]}
        />
      </section>
    </div>
  );
}
