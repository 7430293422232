import {
  Auth0Client,
  Auth0ClientOptions,
  GetTokenSilentlyOptions,
  LogoutOptions,
  createAuth0Client,
  type User as Auth0User,
  type RedirectLoginResult,
} from '@auth0/auth0-spa-js';
import type { Context, ReactElement, ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { LoadingOverlay } from './components/shared/Layout/LoadingOverlay';

interface Auth0ProviderProps extends Auth0ClientOptions {
  children: ReactNode;
  /**
   * If a function is provided, it will be called after has attempted to
   * authenticate with Auth0 and they have just been redirected back to the
   * application with the {@link RedirectLoginResult} `loginResult`.
   */
  onRedirectedBackFromAuth?: (
    loginResult: RedirectLoginResult<unknown>
  ) => void;
}

interface ContextValueType {
  user: any;
  getTokenSilently: (params?: GetTokenSilentlyOptions) => Promise<string>;
  logout: (params?: LogoutOptions) => void;
}

const defaultOptions = {
  user: {},
  getTokenSilently: () => Promise.resolve(''),
  logout: (): void => {},
} satisfies ContextValueType;
export const Auth0Context: Context<ContextValueType> =
  createContext<ContextValueType>(defaultOptions);

export const useAuth0 = (): ContextValueType => useContext(Auth0Context);

export function Auth0Provider({
  children,
  onRedirectedBackFromAuth,
  ...initOptions
}: Auth0ProviderProps): ReactElement {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined
  );

  const [user, setUser] = useState<Auth0User>();
  const [auth0Client, setAuth0] = useState<Auth0Client>();

  useEffect(() => {
    const path = sessionStorage.getItem('redirect');
    if (
      path === null &&
      window.location.pathname !== '/' &&
      !window.location.search.includes('code=') &&
      !window.location.search.includes('state=')
    ) {
      sessionStorage.setItem('redirect', window.location.pathname);
    }
    const initAuth0 = async (): Promise<void> => {
      try {
        const auth0FromHook = await createAuth0Client({
          ...initOptions,
          authorizeTimeoutInSeconds: 5,
        });

        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const loginResult = await auth0FromHook.handleRedirectCallback();
          if (typeof onRedirectedBackFromAuth === 'function')
            onRedirectedBackFromAuth(loginResult);
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();

        if (isAuthenticated) {
          setUser(await auth0FromHook.getUser());
        }
        setIsAuthenticated(isAuthenticated);
        setAuth0(auth0FromHook);
      } catch (error) {
        console.log(error);
      }
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!isAuthenticated && auth0Client) {
      auth0Client.loginWithRedirect();
    }
  }, [auth0Client, isAuthenticated]);

  const getTokenSilently = (
    params?: GetTokenSilentlyOptions
  ): Promise<string> => {
    if (!auth0Client) {
      throw Error('auth0Client not available');
    }

    return auth0Client.getTokenSilently(params);
  };

  const loading = !isAuthenticated || !auth0Client || !user;

  if (loading) {
    return (
      <LoadingOverlay loading={loading}>
        <main
          style={{
            height: '100vh',
          }}
        ></main>
      </LoadingOverlay>
    );
  }

  return (
    <Auth0Context.Provider
      value={{
        user,
        getTokenSilently,
        logout: (params?: LogoutOptions): void => {
          auth0Client?.logout(params);
        },
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
}
