import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 4,
    padding: theme.spacing(1),
    minHeight: 200,
    backgroundColor: theme.palette.mint.light,
    overflow: 'auto',
  },
}));

type ListDisplayProps = {
  children: React.ReactNode;
};

export function ListDisplay({ children }: ListDisplayProps) {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
}

export default ListDisplay;
