import { useMutation } from '@apollo/client';
import { Add, Check, Close } from '@mui/icons-material';
import {
  IconButton,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext, useState, type FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateGroupDocument } from '../../../../gql/mutations/__generated__/group.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  addIcon: {
    color: theme.palette.secondary.main,
    width: '1.65em',
  },
  closeIcon: {
    fill: theme.palette.error.main,
  },
  checkIcon: {
    fill: theme.palette.success.main,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    height: 80,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(1),
  },
  iconButtons: {
    height: 30,
    padding: 0,
    marginRight: 5,
  },
  input: {
    color: theme.palette.common.white,
  },
  field: {
    fontWeight: 'bold',
    fontSize: '2em',
    marginRight: 20,
  },
}));

type AddGroupProps = {
  activeCourseId: string;
  handleDrawerToggle: () => void;
};

export function AddGroup({
  activeCourseId,
  handleDrawerToggle,
}: AddGroupProps) {
  const classes = useStyles();
  const history = useHistory();
  const { dispatch } = useContext(AlertsContext);
  const [editMode, setEditMode] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [createGroup, { loading }] = useMutation(CreateGroupDocument, {
    variables: {
      courseId: activeCourseId,
      name: groupName,
    },
    onError: onError(dispatch),
    refetchQueries: ['teacher'],
    onCompleted: (data) => {
      setEditMode(false);
      setGroupName('');
      // We're refetching instead of updating the cache since this was being
      // buggy some times and the update() method would not call
      history.push(`/classes/${data.createGroup.id}`);
    },
  });

  const handleGroupCreate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleDrawerToggle();
    createGroup();
  };

  const handleCancelAddGroup = () => {
    setEditMode(false);
    setGroupName('');
  };

  if (loading) {
    return <LinearProgress />;
  }

  if (editMode) {
    return (
      <ListItem className={classes.nested}>
        <form onSubmit={handleGroupCreate}>
          <TextField
            InputProps={{
              className: classes.input,
            }}
            className={classes.field}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            name="groupName"
            type="text"
            label="Add Class"
            color="secondary"
            autoFocus
          />
          <div className={classes.buttonsContainer}>
            <IconButton
              className={classes.iconButtons}
              title="cancel add class"
              aria-label="Cancel Add Class"
              onClick={handleCancelAddGroup}
              size="large"
            >
              <Close className={classes.closeIcon} />
            </IconButton>
            <IconButton
              className={classes.iconButtons}
              aria-label="Add Class"
              type="submit"
              title="add class"
              size="large"
            >
              <Check className={classes.checkIcon} />
            </IconButton>
          </div>
        </form>
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      onClick={() => setEditMode(true)}
      className={classes.nested}
      selected={false}
    >
      <ListItemIcon>
        <Add className={classes.addIcon} />
      </ListItemIcon>
      <ListItemText primary="Add a class" />
    </ListItem>
  );
}
