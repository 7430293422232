import { useQuery } from '@apollo/client';
import { ImportExport } from '@mui/icons-material';
import { Box, Button, Input, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { TopicAccordion } from '../../components/application/Generate/TopicsAccordion';
import {
  ENDPOINT,
  TopicHolder,
} from '../../components/application/Generate/utils';
import { HeaderTitleContext } from '../../components/application/Layout/Header/HeaderTitle';
import StyledDialog from '../../components/shared/Layout/StyledDialog';
import { TeacherLightDocument } from '../../gql/queries/__generated__/teacher.generated';

const uploadFile = async (file: File, email: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileName', file.name);

  try {
    const response = await fetch(
      `${ENDPOINT}/csv/import/?` +
        new URLSearchParams({
          user_email: email,
          generator_id: '1',
          topic: 'physics',
        }),
      {
        method: 'POST',
        body: formData,
      }
    );
    const data = await response.json();
    return {
      data,
      error: null,
    };
  } catch (error) {
    console.error('Error:', error);
    return {
      data: null,
      error: {
        message: error,
      },
    };
  }
};

const getUserID = async (email: string) => {
  try {
    const response = await fetch(`${ENDPOINT}/users/name/${email}`, {
      method: 'GET',
    });
    const data = await response.json();
    return {
      data,
      error: null,
    };
  } catch (error) {
    console.error('Error:', error);
    return {
      data: null,
      error: {
        message: error,
      },
    };
  }
};

const getFilehashes = async (userID: number) => {
  try {
    const response = await fetch(`${ENDPOINT}/users/${userID}/hashes`, {
      method: 'GET',
    });
    const data = await response.json();
    return {
      data,
      error: null,
    };
  } catch (error) {
    console.error('Error:', error);
    return {
      data: null,
      error: {
        message: error,
      },
    };
  }
};

export function Generate() {
  const [modalContent, setModalContent] = useState<string | undefined>();
  const handleClose = () => setModalContent(undefined);
  const { updateTitle } = useContext(HeaderTitleContext);
  useEffect(() => {
    updateTitle('Generate Questions');
  }, [updateTitle]);
  const handleOpenStandards = () => setModalContent('showing');
  const [file, setFile] = useState<File>();
  const [showProgress, setShowProgress] = useState(0);
  const [questions, setQuestions] = useState<TopicHolder[]>([]);
  const [userID, setUserID] = useState(0);
  const { data: teacherData, refetch: refetchTeacherData } = useQuery(
    TeacherLightDocument,
    {
      onError: console.log,
    }
  );

  useEffect(() => {
    if (teacherData) {
      const email = teacherData.teacher.email;
      getUserID(email).then((data) => {
        if (!data.data) {
          return;
        }
        setUserID(data.data.id);
        let currentID = data.data.id;
        getFilehashes(data.data.id).then(async (data) => {
          if (!data.data) {
            return;
          }

          await Promise.all(
            data.data.map(async (filehash: string) => {
              return await getQuestions(filehash, currentID);
            })
          ).then((data) => {
            setQuestions(data.flat());
          });
        });
      });
    }
  }, [teacherData]);

  function handleFileChosen(event: React.ChangeEvent<HTMLInputElement>) {
    let files = event.target.files;
    if (files) {
      setFile(files[0]);
    }
  }

  async function getQuestions(filehash: string, userID: number) {
    return await fetch(
      `${ENDPOINT}/questions/get_one_of_each/${filehash}/${userID}`,
      {
        method: 'GET',
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  const dialogContent = () => (
    <Box sx={{ padding: 2, paddingTop: 4 }}>
      <label htmlFor="file">CSV file:</label>
      <Input
        fullWidth
        type="file"
        inputProps={{
          accept: 'text/plain,.csv',
        }}
        onChange={handleFileChosen}
        sx={{
          border: '1px solid',
          borderColor: 'mint.dark',
          borderRadius: 1,
          p: 2,
          mb: 2,
        }}
      />
      <Button
        variant="contained"
        onClick={async () => {
          if (!file) {
            return;
          }
          let email = teacherData?.teacher.email ?? '';
          const { data, error } = await uploadFile(file, email);
          if (error) {
            console.error(error);
          } else {
            setUserID(data.userID);
            handleClose();
            setShowProgress(1);
          }
        }}
      >
        Upload File
      </Button>
    </Box>
  );

  return (
    <Box sx={{ maxWidth: 1280, margin: '0 auto' }}>
      <Box
        sx={{
          mt: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h2"
          sx={{ textAlign: 'center', color: 'primary.main' }}
        >
          Generate Questions
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'primary.main',
          p: 3,
          mx: 5,
        }}
      >
        <Button
          startIcon={<ImportExport />}
          variant="contained"
          onClick={handleOpenStandards}
          sx={{ mb: { md: 1 } }}
        >
          Import Standards
        </Button>
      </Box>
      <StyledDialog
        open={modalContent !== undefined}
        title={`Bulk Upload ${
          modalContent === 'showing' ? 'Topics/Standards' : ''
        }`}
        handleClose={handleClose}
      >
        {dialogContent()}
      </StyledDialog>
      <Box sx={{ mx: 5, mt: 5 }}>
        <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
          Questions
        </Typography>
        {questions.map((topic, i) => (
          <TopicAccordion topic={topic} key={i} userID={userID} />
        ))}
      </Box>
    </Box>
  );
}
