import { useQuery } from '@apollo/client';
import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { QuestionDocument } from '../../../../gql/queries/__generated__/question.generated';
import type { Question } from '../../../../gql/types';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { renderQuestionType } from '../../../../utils/renderQuestionType';
import { CustomChip } from '../../../shared/CustomChip';
import { LoadingSkeletons } from '../../../shared/Loaders/LoadingSkeletons';
import { QuillDeltaAsHtml } from '../../../shared/QuillDeltaAsHtml';
import { AlertsContext } from '../../Alerts/context';
import { QuestionShowAnswers } from './QuestionShowAnswers';

const useStyles = makeStyles((theme: Theme) => ({
  white: {
    color: theme.palette.common.white,
  },
  dark: {
    color: theme.palette.primary.main,
  },
  root: {},
  header: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  buttonsContainer: {
    display: 'flex',
    marginLeft: theme.spacing(1.5),
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
  },
  firstPanel: {
    backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main} 24%, #0c6d7e 123%)`,
    padding: '40px 10% 0',
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      maxWidth: 'auto',
      flexFlow: 'column',
    },
  },
  secondPanel: {
    display: 'flex',
    minHeight: '60vh',
  },
  answer: {
    padding: theme.spacing(2),
    background: theme.palette.background.default,
  },
  supplement: {
    padding: theme.spacing(2),
  },
  chipsContainer: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
  },
  questionTitle: {
    marginBottom: theme.spacing(1),
  },
  questionContainer: {
    width: '45%',
    backgroundColor: theme.palette.darks.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  },
  answerSupplementContainer: {
    width: '55%',
  },
  answersContainer: {},
  supplementContainer: {
    marginTop: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

type QuestionDetailPanelProps = {
  questionId: string;
  afterDelete?: () => void;
  showStandards?: boolean;
};

export function QuestionShow({
  questionId,
  showStandards,
}: QuestionDetailPanelProps) {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const { data, loading } = useQuery(QuestionDocument, {
    onError: onError(dispatch),
    skip: questionId === '',
    variables: { questionId },
  });

  const loadingData = loading || !data;
  const load = loadingData;

  const questionData = data?.question;
  const { standards, questionType, richText, supplement, variations } =
    questionData || {};
  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <section className={classes.firstPanel}>
          {load ? (
            <LoadingSkeletons num={3} />
          ) : (
            <>
              <div className={classes.section}>
                <Typography className={classes.white} variant="h5">
                  QUESTION TYPE
                </Typography>
                <div className={classes.chipsContainer}>
                  {questionType && (
                    <CustomChip
                      className={classes.chip}
                      size="small"
                      label={renderQuestionType(questionType)}
                    />
                  )}
                  <CustomChip
                    size="small"
                    label={`${
                      variations ? variations.length + 1 : 1
                    } variation(s)`}
                  />
                </div>
              </div>
              {showStandards ? (
                <div className={classes.section}>
                  <Typography className={classes.white} variant="h5">
                    STANDARDS COVERED
                  </Typography>
                  <div className={classes.chipsContainer}>
                    {standards &&
                      standards.map(({ title, description, id }) => {
                        return (
                          <CustomChip
                            size="small"
                            className={classes.chip}
                            key={`standard-${id}`}
                            label={title}
                            tooltip={description}
                          />
                        );
                      })}
                    {standards && standards.length === 0 ? (
                      <CustomChip
                        size="small"
                        label={'(No standards tagged)'}
                      />
                    ) : null}
                  </div>
                </div>
              ) : null}
            </>
          )}
        </section>
        <section className={classes.secondPanel}>
          <div className={classes.questionContainer}>
            {load ? (
              <LoadingSkeletons num={12} />
            ) : (
              <>
                <Typography className={classes.questionTitle} variant="h5">
                  QUESTION
                </Typography>
                <QuillDeltaAsHtml delta={richText.ops} />
              </>
            )}
          </div>
          <div className={classes.answerSupplementContainer}>
            <div className={classes.answer}>
              {load ? (
                <LoadingSkeletons num={6} />
              ) : (
                <div className={classes.answersContainer}>
                  <QuestionShowAnswers question={questionData as Question} />
                </div>
              )}
            </div>

            <div className={classes.supplement}>
              {load ? (
                <LoadingSkeletons num={6} />
              ) : (
                <>
                  <Typography
                    className={classes.dark}
                    variant="h5"
                    color="primary"
                  >
                    Explanation
                  </Typography>

                  {supplement ? (
                    <div className={classes.supplementContainer}>
                      <QuillDeltaAsHtml delta={supplement.richText.ops} />
                    </div>
                  ) : (
                    'None'
                  )}
                </>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default QuestionShow;
