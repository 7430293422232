import { useQuery } from '@apollo/client';
import { Typography, type Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import { TeacherDocument } from '../../../../gql/queries/__generated__/teacher.generated';
import { onError } from '../../../../utils/apollo/apolloHelper';
import { AlertsContext } from '../../Alerts/context';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${theme.palette.common.white}`,
    margin: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    padding: theme.spacing(1),
    color: theme.palette.common.white,
  },
  buttonLink: {
    ...buttonLink(theme),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  currentSubject: {
    marginBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function ActiveSubjectSelector() {
  const classes = useStyles();
  const { dispatch } = useContext(AlertsContext);
  const history = useHistory();

  const { data } = useQuery(TeacherDocument, {
    onError: onError(dispatch),
  });

  const handleMenu = () => {
    history.push('/settings/courses');
  };

  const activeCourse = data?.teacher.activeCourse;

  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.label}>
        Current Course:
      </Typography>
      <Typography variant="body1" className={classes.currentSubject}>
        {activeCourse?.name}
      </Typography>
      <button onClick={handleMenu} className={classes.buttonLink}>
        <Typography variant="body1">Edit</Typography>
      </button>
    </div>
  );
}
