import { Close } from '@mui/icons-material';
import {
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  type Theme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useParams, type RouteComponentProps } from 'react-router-dom';
import { buttonLink } from '../../../../assets/shared-styles/Button-Link';
import { useTrackVisit } from '../../../../utils/hooks/useTrackVisit';
import { PersonalDeckAssignmentResults } from '../../Assignments/AssignmentEditor/Results/PersonalDeckAssignmentResults';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  appBar: {
    position: 'sticky',
    top: 0,
  },
  tabs: {
    backgroundColor: theme.palette.mint.main,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1.5),
  },
  buttonLink: {
    ...buttonLink(theme),
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textDecoration: 'none',
  },
  active: {
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    lineHeight: 0.85,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function PersonalDecksAssignmentDetailsContainer({
  match,
}: RouteComponentProps) {
  useTrackVisit({
    section: 'assignment',
    page: 'personal-deck-assignment-results',
  });
  const { assignmentId, groupId } = useParams<{
    assignmentId: string;
    groupId: string;
  }>();
  const classes = useStyles();

  let closePath = `/assignments/${assignmentId}/results`;
  if (match.url.startsWith('/classes')) {
    closePath = `/classes/${groupId}/assignments`;
  }

  return (
    <Dialog open={!!assignmentId} fullScreen>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6">ASSIGNMENT DETAILS</Typography>
          <Link to={`${closePath}`}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              size="large"
            >
              <Close />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
      <PersonalDeckAssignmentResults />
    </Dialog>
  );
}

export default PersonalDecksAssignmentDetailsContainer;
