import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type AcceptPoliciesMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type AcceptPoliciesMutation = {
  __typename?: 'Mutation';
  acceptPolicies: {
    __typename?: 'Teacher';
    id: string;
    acceptedPrivacyAt?: any | null;
    acceptedTosAt?: any | null;
  };
};

export type UpdateTeacherMutationVariables = Types.Exact<{
  firstName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  lastName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  schoolId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;

export type UpdateTeacherMutation = {
  __typename?: 'Mutation';
  updateTeacher: {
    __typename?: 'Teacher';
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    schoolId?: string | null;
  };
};

export type GenerateDemoDataMutationVariables = Types.Exact<{
  [key: string]: never;
}>;

export type GenerateDemoDataMutation = {
  __typename?: 'Mutation';
  generateDemoData: boolean;
};

export const AcceptPoliciesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'acceptPolicies' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptPolicies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptedPrivacyAt' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'acceptedTosAt' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AcceptPoliciesMutation,
  AcceptPoliciesMutationVariables
>;
export const UpdateTeacherDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateTeacher' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'firstName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'lastName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'schoolId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateTeacher' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'firstName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lastName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'lastName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'schoolId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'schoolId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'schoolId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTeacherMutation,
  UpdateTeacherMutationVariables
>;
export const GenerateDemoDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'generateDemoData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'generateDemoData' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GenerateDemoDataMutation,
  GenerateDemoDataMutationVariables
>;
