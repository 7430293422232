import * as Types from '../../types';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type CreateGroupMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  courseId: Types.Scalars['ID']['input'];
}>;

export type CreateGroupMutation = {
  __typename?: 'Mutation';
  createGroup: {
    __typename?: 'Group';
    id: string;
    name: string;
    enableRelaxedSpacing: boolean;
    enableImmediatelyDue: boolean;
    freezePersonalDeck: boolean;
    freezeDate?: any | null;
    numEnrollments: number;
    unfreezeInProgress: boolean;
    numShifted: number;
    course: { __typename?: 'Course'; id: string };
  };
};

export type UpdateGroupMutationVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
  name?: Types.InputMaybe<Types.Scalars['String']['input']>;
  generateNewClassCode?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  enableRelaxedSpacing?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  enableImmediatelyDue?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  freezePersonalDeck?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  receivesEmails?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  studySessionMax?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type UpdateGroupMutation = {
  __typename?: 'Mutation';
  updateGroup: {
    __typename?: 'Group';
    id: string;
    name: string;
    groupCode: string;
    enableRelaxedSpacing: boolean;
    enableImmediatelyDue: boolean;
    freezePersonalDeck: boolean;
    freezeDate?: any | null;
    receivesEmails: boolean;
    studySessionMax: number;
  };
};

export type DeleteGroupMutationVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
}>;

export type DeleteGroupMutation = {
  __typename?: 'Mutation';
  deleteGroup: { __typename?: 'Group'; id: string };
};

export type ResetPointsMutationVariables = Types.Exact<{
  groupId: Types.Scalars['ID']['input'];
}>;

export type ResetPointsMutation = {
  __typename?: 'Mutation';
  resetPoints: boolean;
};

export const CreateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'courseId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'courseId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'courseId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enableRelaxedSpacing' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enableImmediatelyDue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freezePersonalDeck' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'freezeDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'numEnrollments' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'unfreezeInProgress' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'numShifted' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'course' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateGroupMutation, CreateGroupMutationVariables>;
export const UpdateGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'generateNewClassCode' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enableRelaxedSpacing' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'enableImmediatelyDue' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'freezePersonalDeck' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'receivesEmails' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'studySessionMax' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'generateNewClassCode' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'generateNewClassCode' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableRelaxedSpacing' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enableRelaxedSpacing' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'enableImmediatelyDue' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'enableImmediatelyDue' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'freezePersonalDeck' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'freezePersonalDeck' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'receivesEmails' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'receivesEmails' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'studySessionMax' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'studySessionMax' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enableRelaxedSpacing' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'enableImmediatelyDue' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freezePersonalDeck' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'freezeDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'receivesEmails' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'studySessionMax' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const DeleteGroupDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteGroup' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteGroup' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const ResetPointsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetPoints' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPoints' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPointsMutation, ResetPointsMutationVariables>;
